import React from "react";
import { Modal, Spin, Button, Select, Input } from "antd";

import { MAX_LENGTH_FOR_PIN_VERIFICATION } from "../../constants/GlobalConstant";

const { Option } = Select;

const CustomPinModal = (props) => {
    const {
        title,
        onOk,
        onCancel,
        visible,
        onChange,
        pinValue,
        handlePinVerifyClick,
        isPinVerified,
        adminUsers,
        selectedAdminId,
        handleAdminChange,
        findDMsForSelectedAdmin,
        isFetchingAdmins
    } = props;

    return (
        <Modal
            width={"600px"}
            title={title}
            onOk={onOk}
            onCancel={onCancel}
            visible={visible}
        >
            {isPinVerified ? (<>
                {isFetchingAdmins && <Spin />}
                {/* Render all admin users to the admins dropdown once pin is verified */}
                {adminUsers && adminUsers.length ? (
                    <div>
                        <label>Admins List</label>
                        <Select
                            value={selectedAdminId}
                            style={{ width: 180, marginLeft: 10 }}
                            onChange={(value) => handleAdminChange(value)}
                        >
                            {adminUsers.map(({ id, fullName }, index) => (
                                <Option key={index} value={id}
                                    disabled={id === selectedAdminId}
                                >{fullName}</Option>
                            ))}
                        </Select>
                        <Button
                            type="secondary"
                            style={{ width: '20%', marginLeft: '5%' }}
                            disabled={!selectedAdminId}
                            size="large"
                            onClick={findDMsForSelectedAdmin}
                        >Find DMs</Button>
                    </div>
                ) : null}
            </>) :
                (<div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: "auto",
                    justifyContent: 'space-evenly'
                }}>
                    <Input
                        size="large"
                        style={{ width: '75%' }}
                        maxLength={MAX_LENGTH_FOR_PIN_VERIFICATION}
                        onChange={(e) => onChange(e)}
                        value={pinValue}
                        placeholder="Enter the pin..."
                        onPressEnter={handlePinVerifyClick}
                    />
                    <Button
                        type="secondary"
                        style={{ width: '20%' }}
                        disabled={pinValue.length !== MAX_LENGTH_FOR_PIN_VERIFICATION}
                        size="large"
                        onClick={handlePinVerifyClick}
                    >Verify PIN</Button>
                </div>)
            }
        </Modal>
    );
};

export default CustomPinModal;