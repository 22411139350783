import ApiService from "../utils/ApiService";
import DMData from "./DM";
import { types } from "mobx-state-tree";
import DmUser from "./DmUser";
import { cloneDeep } from "lodash";
import { toJS } from "mobx";

export const DMs = types
  .model("DMs", {
    DM: types.array(DMData),
    DmUsers: types.map(types.array(DmUser)),
  })
  .actions((self) => ({
    async fetchDmUsers(channelId) {
      const params = {
        channelId: channelId,
      };
      const response = await ApiService.postRequest("dm-users-by-id", params);
      if (response.success) {
        self.setDmUsers(channelId, cloneDeep(response.data.data));
      }
    },
    setDmUsers(dmId, users) {
      const arr = users.map((user) => {
        return DmUser.create(user);
      });
      if (!self.DmUsers.has(dmId)) {
        self.DmUsers.set(dmId, arr);
      }
    },
    addUserToDm(channelId, userId) {
      ApiService.postRequest("dm-channel-users", {
        channelId,
        userId,
      });
    },
  }));
