import { types } from "mobx-state-tree";

const Admin = types.model("Admin", {
  id: types.identifierNumber,
  fullName: types.string,
  type: types.maybeNull(types.string),
  username: types.maybe(types.string),
  email: types.maybeNull(types.string),
  siteId: types.maybeNull(types.number)
});

export default Admin;
