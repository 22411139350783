import React from "react";
import { inject, observer } from "mobx-react";
import { Select, Spin } from "antd";
import some from "lodash/some";

import info from "../../static/images/info.png";
import { groupMembers, userProfile } from "../../en.json";
import { CustomModal } from "../UI/CustomModal";
import { IS_HP } from "../../utils/getEnvironment";
import { getFormattedDate } from "../../utils/CommonUtils";
import history from "../../utils/history";

const Option = Select.Option;

@inject("store")
@observer
class GroupMembers extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
    };
  }

  changeModalVisible = (value) => {
    this.setState({
      visible: value,
    });
  };

  fetchGroupStatusHistory = (groupId, groupName, userId) => {
    const {
      store: {
        HistoryStore: { fetchStatusHistory, resetHistory, historyLoading },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    const channelType = "GROUP";
    if (historyLoading) {
      return;
    } else {
      resetHistory();
      fetchStatusHistory(groupId, groupName, userId, channelType);
      this.changeModalVisible(true);
    }
  };

  loadMoreStatusHistory = () => {
    const {
      store: {
        HistoryStore: {
          fetchStatusHistory,
          selectedGroupName,
          selectedUserId,
          selectedGroupId,
          historyLoading,
        },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    const channelType = "GROUP";
    fetchStatusHistory(
      selectedGroupId,
      selectedGroupName,
      selectedUserId,
      channelType
    );
  };

  setGroupStatus = async (value, userId, groupId) => {
    const {
      store: {
        GroupStore: { changeStatus },
        GroupListStore: { removeGroupUser, changeStatusInGroup },
      },
    } = this.props;

    const success = await changeStatus(groupId, userId, value);
    if (success) {
      if (value === 4) {
        removeGroupUser(userId, groupId);
      } else {
        changeStatusInGroup(userId, value);
      }
    }
  };

  status = (statusId) => {
    if (statusId === 1) {
      return "Active";
    } else if (statusId === 2) {
      return "Suspended";
    } else if (statusId === 3) {
      return "Paused";
    }
  };

  usernamePress = async (sender) => {
    const {
      store: {
        ProfileStore: {
          setMemberProfileVisibility,
          setMemberData,
          toggleLabel,
          reset,
          getHighPointUsers,
          changeSelectedAction,
          getProviderModeratedGroups,
          fetchGroupsFromForms,
          getContactModeratedGroups,
        },
        DeletedMessagesStore: { setShowDeleted },
        MemberListStore: { getMemberDetails },
      },
    } = this.props;
    reset();
    setShowDeleted(false);
    setMemberProfileVisibility(true);
    const member = await getMemberDetails(sender);
    if (member) {
      setMemberData(member);
      if (IS_HP) {
        getHighPointUsers();
        if (member.providerId) {
          getProviderModeratedGroups(member.providerId);
        }
        if (member.recommendedBy) {
          getContactModeratedGroups(member.recommendedBy);
        }
        fetchGroupsFromForms();
      }
    }
    toggleLabel(false);
    changeSelectedAction("");
  };

  render() {
    const {
      store: {
        GroupListStore: {
          selectedGroup,
          groupEditingLoading,
          groupEdit,
          groupDesc,
        },
        HistoryStore: {
          historyLoading,
          skipStatusInGroup,
          totalStatusInGroup,
          userStatusInGroupHistory,
          resetHistory,
        },
      },
    } = this.props;

    return (
      <div className="profile-groups-container">
        <CustomModal
          title="User Group Status History"
          visible={this.state.visible}
          onOk={this.handleOk}
          loading={historyLoading}
          loadMore={this.loadMoreStatusHistory}
          hasMore={skipStatusInGroup < totalStatusInGroup}
          onCancel={() => {
            resetHistory();
            this.changeModalVisible(false);
          }}
          data={userStatusInGroupHistory ? userStatusInGroupHistory : []}
        />
        <div>
          <div className="group-image-container">
            <img
              className={groupEdit ? "group-image-edit" : "group-image"}
              src={
                selectedGroup.imageUrl
                  ? selectedGroup.imageUrl
                  : "https://www.gardendesign.com/pictures/images/900x705Max/dream-team-s-portland-garden_6/marigold-flowers-orange-pixabay_12708.jpg"
              }
              alt="groupImage"
            />
          </div>
          {groupEdit ? (
            <div className="upload-image">
              <input
                type="file"
                onChange={this.props.changeValue}
                accept="image/*"
                data-field="groupImage"
                className="group-edit-image"
                placeholder="Group Description"
                id="photoupload"
              />
            </div>
          ) : null}
          {selectedGroup.memberCount ? (
            <h3>
              {selectedGroup.memberCount > 1
                ? `${selectedGroup.memberCount} Members`
                : `${selectedGroup.memberCount} Member`}
            </h3>
          ) : null}
          <div>
            <h3 className="member-type-heading">{groupMembers.desc}</h3>
            {groupEdit ? (
              <input
                onChange={this.props.changeValue}
                placeholder="Group Description"
                className="group-edit-input"
                data-field="groupDesc"
                value={groupDesc}
              />
            ) : (
              <h6 className="group-users">{selectedGroup.description}</h6>
            )}
          </div>
        </div>
        {groupEditingLoading ? (
          <div className="group-edit-loading">
            <Spin />
          </div>
        ) : null}
        <div className="group-members">
          {selectedGroup.moderators && selectedGroup.moderators.length ? (
            <>
              <h3 className="member-type-heading">{groupMembers.admins}</h3>
              {selectedGroup.moderators.map((user) => (
                <h6
                  className="group-users"
                  key={user.username}
                  onClick={() => this.usernamePress(user.userId)}
                >
                  {user.username}
                </h6>
              ))}
            </>
          ) : null}
          {selectedGroup.semiModerators &&
            selectedGroup.semiModerators.length ? (
            <>
              <h3 className="member-type-heading">{groupMembers.peerMods}</h3>
              {selectedGroup.semiModerators.map((user) => (
                <h6
                  className="group-users"
                  key={user.username}
                  onClick={() => this.usernamePress(user.userId)}
                >
                  {user.username}
                </h6>
              ))}
            </>
          ) : null}
          {selectedGroup.users && selectedGroup.users.length ? (
            <>
              <h3 className="member-type-heading">{groupMembers.members}</h3>
              {selectedGroup.users.map((user) => {
                const groupStatus = this.status(user.status);
                const statusInGroup = user.status;
                return (
                  <div className="user-group-container" key={user.username}>
                    <div className="user-group">
                      <h6
                        className="group-users-with-status"
                        onClick={() => this.usernamePress(user.userId)}
                      >
                        {user.username}
                      </h6>
                      <div
                        id="group-status-container"
                        style={{ position: "relative" }}
                      >
                        <Select
                          value={groupStatus}
                          style={{ width: 120 }}
                          onChange={(value) =>
                            this.setGroupStatus(
                              value,
                              user.userId,
                              selectedGroup.id
                            )
                          }
                          getPopupContainer={() =>
                            document.getElementById("group-status-container")
                          }
                        >
                          {statusInGroup === 1 ? null : (
                            <Option value={1}>
                              <i className="fa fa-check">
                                {userProfile.activate}
                              </i>
                            </Option>
                          )}
                          {statusInGroup === 2 ? null : (
                            <Option value={2}>
                              <i className="fa fa-minus-circle">
                                {userProfile.suspend}
                              </i>
                            </Option>
                          )}
                          {statusInGroup === 3 ? null : (
                            <Option value={3}>
                              <i className="fa fa-pause">
                                {userProfile.paused}
                              </i>
                            </Option>
                          )}
                          <Option value={4}>
                            <i className="fa fa-ban">{userProfile.denied}</i>
                          </Option>
                        </Select>
                        <img
                          src={info}
                          className="info-icon"
                          alt="info"
                          onClick={() => {
                            this.fetchGroupStatusHistory(
                              selectedGroup.id,
                              selectedGroup.name,
                              user.userId
                            );
                          }}
                        />
                      </div>
                    </div>
                    {user.addedByUsername ? (
                      <div className="Added-by">
                        {userProfile.addedBy}{" "}
                        <span className="added-by-username">
                          {user.addedByUsername}
                        </span>{" "}
                        on {getFormattedDate(user.createdAt)}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
export default GroupMembers;
