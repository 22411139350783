import React from "react";
import moment from "moment";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { map, some, sortBy, get } from "lodash";
import { toJS } from "mobx";
import {
  Button,
  Collapse,
  Dropdown,
  Icon,
  Menu,
  Popover,
  Radio,
  Select,
  Spin,
  Tooltip,
} from "antd";

// Components
import WellnessForm from "./WellnessForm";
import UserInsuranceDetails from "./UserInsuranceDetails";
import Task from "./Task";
import { BarGraph } from "../UI/BarGraph";
import { CustomModal } from "../UI/CustomModal";
import CustomFormModal from "../UI/CustomFormModal";
import EditUserForm from "./EditUserForm";

// Local Imports
import { toolTips, userProfile, members, user } from "../../en.json";
import {
  CALL_OUTREACH_FLAGS,
  SMS_OUTREACH_FLAGS,
  FORM_NAME_BARC10,
  FORM_NAME_BARC10_NOTE_TEMPLATE,
  FORM_NAME_ENCOUNTER_NOTE_V2,
  FORM_NAME_OUTREACH_NOTE,
  SUBMITTED_FROM_TYPE_ADMIN,
  BARC10_NOTE_OPTIONS,
  BARC10_NOTE_TEMPLATE_FORM_TITLE,
  FORM_NAME_APP_ENCOUNTER_TEMPLATE,
  FORM_NAME_ORIENTATION_NOTE_V2,
  FORM_NAME_ENROLLMENT_NOTE,
  FORM_NAME_APP_ENCOUNTER_TEMPLATE_V2,
  FORM_NAME_BARC10_NOTE_TEMPLATE_V2,
  FORM_NAME_ENCOUNTER_NOTE_V3,
} from "../../constants/GlobalConstant";
import { USER_BADGES, USER_BADGE_IMAGES, BADGE_TYPE_REMOVE } from "../../constants/BadgeConstant";
import { SA, NOA, MODERATOR, USER, SEMI_MODERATOR } from "../../constants/UserRolesConstant";
import edit from "../../static/images/edit.svg";
import download from "../../static/images/download.svg";
import info from "../../static/images/info.png";
import ProfileStore from "../../stores/ProfileStore";
import AuthStore from "../../stores/AuthStore";
import ApiService from "../../utils/ApiService";
import { formateDate, getFormattedDate, getFormattedPhoneNumber } from "../../utils/CommonUtils";
import { IS_HP, IS_ISLAND } from "../../utils/getEnvironment";
import { ulid } from "../../utils/getRandomId";
import Rewards from "./Rewards";
import OnBoardingStatus from "../UI/OnBoardingStatus";
import UserAdditionalDetails from "./UserAdditionalDetails";

const mixpanel = require("mixpanel-browser");

const { Panel } = Collapse;
const Option = Select.Option;
@inject("store")
@observer
class UserDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      statusVisible: false,
      userHistoryVisible: false,
      groupHistoryVisible: false,
      userOutreachHistoryVisible: false,
      formListVisible: false
    };
  }

  addToGroup = async (sender) => {
    const {
      store: {
        MemberListStore: { getMemberDetails },
        ProfileStore,
      },
    } = this.props;
    // const member = await getMemberDetails(sender);
    ProfileStore.setVisibility(true);
    // ProfileStore.setMemberData(member);
  };

  downloadConsentPDF = async (userId) => {
    const {
      store: {
        ProfileStore: { downloadConsentPDF },
      },
    } = this.props;
    const res = await downloadConsentPDF(userId);
    if (res) {
      var dlnk = document.getElementById('dwnldLnk');
      dlnk.href = res;
      dlnk.click();
    }
  }

  checkDefault = () => {
    const {
      store: {
        ProfileStore: { color, trainingStatus },
      },
    } = this.props;
    return color === "green" && trainingStatus === "completed";
  };

  changeTypeInGroup = async (type, groupId, userId) => {
    const {
      store: { ProfileStore },
    } = this.props;
    ProfileStore.changeUserType(type, groupId, userId);
  };

  changeOutreachTypeInProfile = (userId, isSmsOutreach, outreachValue) => {
    const {
      store: { ProfileStore },
    } = this.props;
    const data = { userId, isSmsOutreach, outreachValue };
    ProfileStore.changeOutreachType(data);
  };

  cancelPress = (e) => {
    const {
      store: {
        ProfileStore: { toggleEdit, TogglePopOver, updateUserBadgeType },
      },
    } = this.props;
    toggleEdit(e.target.getAttribute("edit"), false);
    TogglePopOver(false);
    updateUserBadgeType(null);
  };

  changeValue = (e) => {
    const {
      store: {
        ProfileStore: { changePreviousValue },
      },
    } = this.props;

    changePreviousValue(e.target.getAttribute("data-field"), e.target.value);
  };

  changeColor = (e) => {
    const {
      store: {
        ProfileStore: { updateUserColor },
      },
    } = this.props;
    updateUserColor(e.target.value);
  };

  changeGroupHistoryVisible = (value) => {
    this.setState({
      groupHistoryVisible: value,
    });
  };

  changeType = (e) => {
    const {
      store: {
        ProfileStore: { updateUserType },
      },
    } = this.props;
    updateUserType(e.target.value);
  };

  changeLatestAnswer = (e) => {
    const {
      store: {
        ProfileStore: { changeLatestAnswer },
      },
    } = this.props;
    changeLatestAnswer(e.target.value);
  };

  changeModalVisible = (value) => {
    this.setState({
      visible: value,
    });
  };

  changeUserHistoryVisible = (value) => {
    this.setState({
      userHistoryVisible: value,
    });
  };

  changeUserOutreachHistoryVisible = (value) => {
    this.setState({
      userOutreachHistoryVisible: value,
    });
  };

  changeTraining = (e) => {
    const {
      store: {
        ProfileStore: { updateUserTraining },
      },
    } = this.props;
    updateUserTraining(e.target.value);
  };

  changeStartGroup = (e) => {
    const {
      store: {
        ProfileStore: { updateStartAGroup },
      },
    } = this.props;
    updateStartAGroup(e.target.value);
  };

  changeStatusVisible = (value) => {
    this.setState({
      statusVisible: value,
    });
  };

  changeFormsListVisible = (value) => {
    this.setState({
      formListVisible: value,
    });
  };

  editPress = (e) => {
    const {
      store: {
        ProfileStore: { userId, toggleEdit, TogglePopOver, setPreviousData },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    setPreviousData();
    toggleEdit(e.target.getAttribute("edit"), true);
    TogglePopOver(false);
  };

  fetchResetPasswordHistory = () => {
    const {
      store: {
        HistoryStore: { fetchPasswordResetHistory },
        ProfileStore: { userId },
      },
    } = this.props;
    if (userId) {
      fetchPasswordResetHistory(userId);
      this.changeModalVisible(true);
    }
  };

  fetchGroupHistory = (groupId, groupName) => {
    const {
      store: {
        HistoryStore: { fetchGroupHistory, historyLoading },
      },
    } = this.props;
    fetchGroupHistory(groupId, groupName);
    this.changeGroupHistoryVisible(true);
  };

  fetchUserOutreachHistory = () => {
    const {
      store: {
        HistoryStore: { fetchUserOutreachHistory },
        ProfileStore: { userId },
      },
    } = this.props;
    if (userId) {
      fetchUserOutreachHistory(userId);
      this.changeUserOutreachHistoryVisible(true);
    }
  };

  fetchUserHistory = () => {
    const {
      store: {
        HistoryStore: { fetchUserHistory, resetHistory, historyLoading },
        ProfileStore: { userId },
      },
    } = this.props;
    if (userId) {
      fetchUserHistory(userId);
      this.changeUserHistoryVisible(true);
    }
  };

  fetchGroupStatusHistory = (groupId, groupName) => {
    const {
      store: {
        HistoryStore: { fetchStatusHistory },
        ProfileStore: { userId },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    const channelType = "GROUP";
    fetchStatusHistory(groupId, groupName, userId, channelType);
    this.changeStatusVisible(true);
  };

  getAnswerToQuestion() {
    const {
      store: {
        ProfileStore: { answers },
      },
    } = this.props;
    if (answers.length > 0) {
      return sortBy(answers, "updatedAt").reverse()[0].answer;
    }
  }

  getProfileQuestionResponseId = (questionId) => {
    const {
      store: {
        ProfileStore: { answers },
      },
    } = this.props;
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].questionId === questionId) {
        return answers[i].id;
      }
    }
  };

  getMemberCount = (groupId) => {
    const {
      store: {
        GroupStore: { getAssignedGroupMemberCount },
      },
    } = this.props;
    const count = getAssignedGroupMemberCount(groupId);
    return count !== null ? count : 0;
  };

  getDmName = (dmName) => {
    const {
      store: {
        ProfileStore: { username },
      },
    } = this.props;
    return dmName.split(",").filter((user) => user !== username);
  };

  getLatestQuestion(questions) {
    const {
      store: {
        ProfileStore: { answers },
      },
    } = this.props;
    if (answers.length > 0) {
      let updatedResponse = sortBy(answers, "updatedAt").reverse()[0];
      for (let i = 0; i < questions.length; i++) {
        if (questions[i].id === updatedResponse.questionId) {
          return questions[i].question;
        }
      }
    }
  }

  getAnswerToFormQuestion = (question, toDisplay = true) => {
    const {
      store: {
        ProfileStore: { formAnswers },
      },
    } = this.props;
    if (question.type === "multiple_choices") {
      const questionId = question.id;
      const options = question.options;

      const answerObjArr = formAnswers.filter(
        (formAnswer) => formAnswer.formQuestionId === questionId
      );

      if (answerObjArr.length) {
        const questionAnswer = answerObjArr[0].answer;
        if (questionAnswer) {
          const optArr = questionAnswer.split(",");
          const ans = [];
          optArr.forEach((opt) => {
            const isOp = options.filter((options) => options.id === Number(opt));
            if (isOp.length) {
              ans.push(isOp[0].option);
            }
          });
          return ans.join(", ");
        }
        return "not found";
      } else {
        return "not found";
      }
    } else if (question.type === "multiple_choices_radio") {
      const questionId = question.id;
      const options = question.options;
      const answer = formAnswers.filter(
        (formAnswer) => formAnswer.formQuestionId === questionId
      );
      if (answer.length) {
        const opt = options.filter(
          (option) => option.id === answer[0].formQuestionOptionId
        );
        if (opt.length) {
          return opt[0].option;
        } else {
          return "no found";
        }
      } else {
        return "no found";
      }
    } else if (question.type === "descriptive") {
      for (let i = 0; i < formAnswers.length; i++) {
        if (question.id === formAnswers[i].formQuestionId) {
          return formAnswers[i].answer;
        }
      }
    } else if (question.type === "datepicker") {
      for (let i = 0; i < formAnswers.length; i++) {
        if (question.id === formAnswers[i].formQuestionId) {
          // format date to MM/DD/YYYY format
          return formateDate(formAnswers[i].answer, toDisplay);
        }
      }
    }
  };

  getAssignedGroups = () => {
    const {
      store: { ProfileStore },
    } = this.props;
    let assignedGroups = [];
    for (let i = 0; i < ProfileStore.assignedGroups.length; i++) {
      if (i === ProfileStore.assignedGroups.length - 1) {
        assignedGroups.push(
          <span key={ProfileStore.assignedGroups[i].id}>
            {ProfileStore.assignedGroups[i].name}
          </span>
        );
      } else {
        assignedGroups.push(
          <span key={ProfileStore.assignedGroups[i].id}>
            {ProfileStore.assignedGroups[i].name + ", "}
          </span>
        );
      }
    }
    return assignedGroups;
  };

  getOptions = (queryResult) => {
    let options = [];
    queryResult.forEach((graphData) => {
      let labels = [],
        data = [],
        backgroundColor = [];
      graphData.forEach((item) => {
        labels.push(item.label.replace("$", ""));
        data.push(item.y);
        backgroundColor.push(item.color);
      });
      options.push({
        labels,
        datasets: [
          {
            label: "Messages",
            data,
            backgroundColor,
          },
        ],
      });
    });
    return options;
  };

  groupSemiMods = (id) => {
    return (
      this.showGroupSemiMods(id)
        .slice(0, this.showGroupSemiMods(id).length === 1 ? 1 : -1)
        .join(", ") +
      (this.showGroupSemiMods(id).length > 1
        ? " and " + this.showGroupSemiMods(id).slice(-1)
        : "")
    );
  };

  handleFormClick = (e) => {
    const {
      store: { ProfileStore },
    } = this.props;
    if (e) {
      const messageId = e.split("+")[0];
      const formId = e.split("+")[1];
      ProfileStore.setIsUserFormEditActive(false);
      ProfileStore.setMetaInfoForUpdateUserForm({});
      ProfileStore.setAnswersForEditForm({});
      ProfileStore.setOriginalAnswersForEditForm({});
      ProfileStore.setMultipleChoiceQuestionIds({});
      ProfileStore.setMultipleChoiceRadioQuestionIds({});
      ProfileStore.setLastEditUserInfo({});
      if (formId && messageId) {
        ProfileStore.getForm(formId, messageId);
      } else return;
    } else return;
  };

  loadMoreGroupHistory = () => {
    const {
      store: {
        HistoryStore: {
          fetchGroupHistory,
          selectedGroupName,
          selectedGroupId,
          historyLoading,
        },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchGroupHistory(selectedGroupId, selectedGroupName);
  };

  loadMoreFormsListing = () => {
    const {
      store: {
        FormMessageStore: { loading, fetchAllFormsInListing }
      },
    } = this.props;
    if (loading) return;
    fetchAllFormsInListing();
  };

  loadMoreStatusHistory = () => {
    const {
      store: {
        HistoryStore: {
          fetchStatusHistory,
          selectedGroupName,
          selectedGroupId,
          historyLoading,
        },
        ProfileStore: { userId },
      },
    } = this.props;
    const channelType = "GROUP";
    if (historyLoading) {
      return;
    }
    fetchStatusHistory(selectedGroupId, selectedGroupName, userId, channelType);
  };
  loadMoreResetPasswordHistory = () => {
    const {
      store: {
        ProfileStore: { userId },
        HistoryStore: { fetchPasswordResetHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchPasswordResetHistory(userId);
  };

  loadMoreUserOutreachHistory = () => {
    const {
      store: {
        ProfileStore: { userId },
        HistoryStore: { fetchUserOutreachHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchUserOutreachHistory(userId);
  }

  loadMoreUserHistory = () => {
    const {
      store: {
        ProfileStore: { userId },
        HistoryStore: { fetchUserHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchUserHistory(userId);
  };

  setGroupStatus = async (value, groupId) => {
    const {
      store: {
        GroupStore: { changeStatus },
        ProfileStore: { userId, isAssigned, updateStatusInGroup },
        MemberListStore: { removeGroup, assignMemberToGroup },
      },
    } = this.props;
    const assigned = isAssigned(groupId);

    const success = await changeStatus(groupId, userId, value, assigned);
    if (success) {
      if (value === 4) {
        await removeGroup(userId, groupId);
      } else {
        if (!assigned) {
          assignMemberToGroup(userId, groupId);
        }
        updateStatusInGroup(groupId, { status: value });
      }
    }
  };

  showGroupSemiMods = (id) => {
    const {
      store: { GroupStore },
    } = this.props;
    const semiiMods = GroupStore.result.get(id);
    return semiiMods ? semiiMods.semiMods : [];
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showType = (type) => {
    if (type === "user") return userProfile.member;
    else if (type === "semi-moderator") return userProfile.semiMod;
    else if (type === "moderator") return userProfile.mod;
    else if (type === "SA") return userProfile.superAdmin;
    else if (type === "FD") return userProfile.frontDesk;
    else if (type === "NOA") return userProfile.NA;
  };

  status = (statusId) => {
    if (statusId === 1) {
      return "Active";
    } else if (statusId === 2) {
      return "Suspended";
    } else if (statusId === 3) {
      return "Paused";
    }
  };

  updateChampion = async (key) => {
    const {
      store: {
        ProfileStore: { updateProviderId },
      },
    } = this.props;
    updateProviderId(key);
  };

  updateProfile = async (e) => {
    const {
      store: {
        ProfileStore: { updateUserProfile, userId },
        RewardStore,
      },
    } = this.props;
    if (!userId) {
      return;
    }
    await updateUserProfile(userId, this.props.allMembers);
    // Fetch wellness plan by user when admin clicks on any user profile
    await RewardStore.fetchRewards(ProfileStore.userId);
  };

  getFormsList = (e) => {
    const { store: { FormMessageStore: { fetchAllFormsInListing } } } = this.props;
    fetchAllFormsInListing();
    this.changeFormsListVisible(true);
  };

  handleFormReview = (userFormId) => {
    const {
      store: {
        ProfileStore: { submitUserFormReviewDetails },
      },
    } = this.props;
    submitUserFormReviewDetails(userFormId);
  };

  updateUserSiteId = async ({ key }) => {
    const {
      store: {
        ProfileStore: { updateUserSiteId },
      },
    } = this.props;
    updateUserSiteId(key);
  };

  updateAssignedPeer = (userId, assignedPeerId) => {
    const { store: { ProfileStore } } = this.props;
    ProfileStore.changeAssignedPeer({ userId, assignedPeerId });
  };
  updateEnrolledPeer = (userId, enrolledPeerId) => {
    const { store: { ProfileStore } } = this.props;
    ProfileStore.changeEnrolledPeer({ userId, enrolledPeerId });
  };

  updateUserBadgeType = (badgeType) => {
    const {
      store: {
        ProfileStore: { updateUserBadgeType },
      },
    } = this.props;
    updateUserBadgeType(badgeType);
  };

  renderUserBadgeWrapper = (badgeType) => {
    return (<div className="user-badge-wrapper">
      <img src={USER_BADGE_IMAGES[badgeType]} className="user-badge-image" alt="user-badge" />
      <span>{USER_BADGES[badgeType]}</span>
    </div>)
  };

  downloadFormPdf = async (data) => {
    const {
      store: {
        ProfileStore: { downloadUserFormPDF, userId },
      },
    } = this.props;
    const response = await downloadUserFormPDF({ ...data, userIdFromAdmin: userId });
    if (response) {
      mixpanel.track('Form PDF Downloaded', {
        from: 'WEB',
        userIdFromAdmin: userId,
        ...data
      });
      const downloadLink = document.getElementById('formDownloadLink');
      downloadLink.href = response;
      downloadLink.click();
    }
  };

  editFormClick = () => {
    const {
      store: {
        ProfileStore: { setIsUserFormEditActive },
      },
    } = this.props;
    setIsUserFormEditActive(true);
  };

  submitFormClick = () => {
    const {
      store: {
        ProfileStore: {
          setIsUserFormEditActive,
          setMetaInfoForUpdateUserForm,
          setAnswersForEditForm,
          setOriginalAnswersForEditForm,
          setMultipleChoiceQuestionIds,
          setMultipleChoiceRadioQuestionIds,
          setOptionalQuestionIds,
          setHiddenQuestionIds,
          answersForEditForm,
          originalAnswersForEditForm,
          multipleChoiceRadioQuestionIds,
          multipleChoiceQuestionIds,
          updateUserForms,
          metaInfoForUpdateUserForm,
          isUserFormSubmitting,
          optionalQuestionIds,
          hiddenQuestionIds
        },
        NotificationStore
      },
    } = this.props;
    if (isUserFormSubmitting) return;

    const copyAnswersForEditForm = { ...answersForEditForm };
    optionalQuestionIds.forEach((id) => delete copyAnswersForEditForm[id]);
    hiddenQuestionIds.forEach((id) => delete copyAnswersForEditForm[id]);

    const isContainUnAnsweredQuestions = Object.values(copyAnswersForEditForm).findIndex(value => !value);
    if (isContainUnAnsweredQuestions > -1) {
      NotificationStore.setNotification("error", 'Please answer all the questions!');
      return;
    }
    const isBothAnswersAreEqual = _.isEqual(answersForEditForm, originalAnswersForEditForm);
    if (isBothAnswersAreEqual) {
      // We will reset MobX state if answers are equal
      setIsUserFormEditActive(false);
      setMetaInfoForUpdateUserForm({});
      setAnswersForEditForm({});
      setOriginalAnswersForEditForm({});
      setMultipleChoiceQuestionIds({});
      setMultipleChoiceRadioQuestionIds({});
      setOptionalQuestionIds([]);
      setHiddenQuestionIds([]);
      return;
    }
    // Add API implementation to update user forms
    const formQuestionHistoryResponses = [];
    const uniqueAnswersResponses = [];
    // Here we are iterating over original answers object
    for (let key in originalAnswersForEditForm) {
      // We are taking original answer by using key
      const answerOfOriginalData = originalAnswersForEditForm[key];
      // Here we are taking updated answer by using key
      const answerOfUpdatedData = answersForEditForm[key];
      // Here we are checking if answer is changed or not
      if (answerOfOriginalData !== answerOfUpdatedData) {
        const objToPush = { formQuestionId: +key };
        const payloadObjForUniqueAnswer = { formQuestionId: +key };
        // Here we are validating if question type is "multiple choice radio" or not by using object of ids
        if (multipleChoiceRadioQuestionIds.hasOwnProperty(key)) {
          const multipleChoiceRadioOptions = multipleChoiceRadioQuestionIds[key];
          const previousResponse = multipleChoiceRadioOptions.find(obj => obj.id === answerOfOriginalData);
          const currentResponse = multipleChoiceRadioOptions.find(obj => obj.id === answerOfUpdatedData);

          // Here we are pushing previous response & current response for user forms history
          objToPush['previousResponse'] = previousResponse.option;
          objToPush['currentResponse'] = currentResponse.option;

          // Here we are pushing formQuestionOptionId if question type is "multiple choice radio"
          payloadObjForUniqueAnswer['formQuestionOptionId'] = answerOfUpdatedData;
        } else if (multipleChoiceQuestionIds.hasOwnProperty(key)) {
          // Here we are validating if question type is "multiple choices" or not by using object of ids
          const multipleChoiceQuestionOptions = multipleChoiceQuestionIds[key];
          const previousResponse = multipleChoiceQuestionOptions
            .filter(obj => answerOfOriginalData.split(',').indexOf(`${obj.id}`) > -1)
            .map(obj => obj.option);
          const currentResponse = multipleChoiceQuestionOptions
            .filter(obj => answerOfUpdatedData.split(',').indexOf(`${obj.id}`) > -1)
            .map(obj => obj.option);

          // Here we are pushing previous response & current response for user forms history
          objToPush['previousResponse'] = previousResponse.join(',');
          objToPush['currentResponse'] = currentResponse.join(',');

          // Here we are pushing answer (formQuestionOptionIds in comma separated string) if question type is "multiple choices"
          payloadObjForUniqueAnswer['answer'] = answerOfUpdatedData;
        } else {
          // If question type is descriptive then we are adding that response here for user form history
          objToPush['previousResponse'] = answerOfOriginalData;
          objToPush['currentResponse'] = answerOfUpdatedData;

          // Here we are pushing answer if question type is descriptive
          payloadObjForUniqueAnswer['answer'] = answerOfUpdatedData;
        }
        formQuestionHistoryResponses.push(objToPush);
        uniqueAnswersResponses.push(payloadObjForUniqueAnswer);
      }
    }
    // Here we are calling update user forms api to update existing answers
    updateUserForms({
      ...metaInfoForUpdateUserForm,
      payload: uniqueAnswersResponses,
      historyPayload: formQuestionHistoryResponses
    });
  };

  generateFileNameFromPersonalInfo = (data) => {
    const { fullname, dob } = data;
    let consentFileName = '';
    if (fullname) {
      consentFileName += `${fullname.split(' ').join('-').toLowerCase()}`;
    }
    if (dob) {
      consentFileName += `-${dob.split('-')[0]}`;
    }
    return consentFileName;
  };

  openBARC10NoteForm = async (form) => {
    const {
      store: {
        FormMessageStore: {
          setLoading,
          getFormQuestionsMod,
          setSelectedForm,
          setIsAdminFillingTheForm,
          changeUserformsVisible,
          resetFormsListData,
          setTotalScore,
          fetchAllFormsInListing,
          formsListData,
          setUserFormId
        }
      },
    } = this.props;

    setLoading(true);

    // Set total scrore from BARC10
    setTotalScore(form.totalScore);

    this.generateCommentForScoringDomain()

    await fetchAllFormsInListing({ name: BARC10_NOTE_TEMPLATE_FORM_TITLE });

    // fetch BARC10 note template form id
    const BARC10NoteTemplateForm = formsListData.filter(form => form.name === BARC10_NOTE_TEMPLATE_FORM_TITLE);
    if (!BARC10NoteTemplateForm.length) {
      setLoading(true);
      return;
    }

    const formId = BARC10NoteTemplateForm[0].id;
    const name = BARC10_NOTE_TEMPLATE_FORM_TITLE;

    const messageId = `${ulid() * 10000}`;
    const params = {
      userId: ProfileStore.userId,
      formId,
      submittedFrom: SUBMITTED_FROM_TYPE_ADMIN,
      submittedFromId: AuthStore.userId,
      messageId,
    };
    const userFormsResponse = await ApiService.postRequest("user-forms", params);
    const { success, data } = userFormsResponse;
    if (success) {
      setUserFormId(data.id);
      await getFormQuestionsMod(formId);
      setSelectedForm(formId, messageId, name);
      setIsAdminFillingTheForm(true);
      changeUserformsVisible(true);
    }

    resetFormsListData();
    setLoading(false);
  }

  generateCommentForScoringDomain = async () => {
    const {
      store: {
        ProfileStore: { formQuestions, formAnswers },
        FormMessageStore: {
          setLowestScoringDomain,
          setHighestScoringDomain
        }
      },
    } = this.props;

    let arr = []
    let unique = []
    await Promise.all(formQuestions.map((question) => {
      const questionId = question.id;
      const options = question.options;
      const answer = formAnswers.filter((formAnswer) => formAnswer.formQuestionId === questionId);
      let obj;
      if (answer.length) {
        const opt = options.filter((option) => option.id === answer[0].formQuestionOptionId);
        if (opt.length) {
          obj = { ...question, rank: opt[0].points }
        } else {
          obj = { ...question, rank: 0 }
        }
      } else {
        obj = { ...question, rank: 0 }
      }
      arr.push(obj)
      unique = [...new Set(arr.map(item => item.rank))];
      unique.sort((a, b) => a - b)
    }))

    let lowestScoringDomainsQueStringOne;
    let lowestScoringDomainsAnsOne = [];
    let lowestScoringDomainsQueArrOne = [];
    lowestScoringDomainsQueArrOne = arr.filter(item => {
      if (unique.length) {
        return (item.rank === unique[0]);
      }
    }).map((item) => lowestScoringDomainsAnsOne.push(item.questionTitle));
    lowestScoringDomainsQueStringOne = BARC10_NOTE_OPTIONS[unique[0]];

    let lowestScoringDomainsQueStringTwo;
    let lowestScoringDomainsAnsTwo = [];
    let lowestScoringDomainsQueArrTwo = [];
    lowestScoringDomainsQueArrTwo = arr.filter(item => {
      if (unique.length > 1) {
        return (item.rank === unique[1]);
      }
    }).map((item) => lowestScoringDomainsAnsTwo.push(item.questionTitle));
    lowestScoringDomainsQueStringTwo = BARC10_NOTE_OPTIONS[unique[1]];

    let highestScoringDomainsQueStringOne;
    let highestScoringDomainsAnsOne = [];
    let highestScoringDomainsQueArrOne = [];
    highestScoringDomainsQueArrOne = arr.filter(item => {
      if (unique.length) {
        return (item.rank === unique[unique.length - 1]);
      }
    }).map((item) => highestScoringDomainsAnsOne.push(item.questionTitle));
    highestScoringDomainsQueStringOne = BARC10_NOTE_OPTIONS[unique[unique.length - 1]];

    let highestScoringDomainsQueStringTwo;
    let highestScoringDomainsAnsTwo = [];
    let highestScoringDomainsQueArrTwo = [];
    highestScoringDomainsQueArrTwo = arr.filter(item => {
      if (unique.length > 1) {
        return (item.rank === unique[unique.length - 2]);
      }
    }).map((item) => highestScoringDomainsAnsTwo.push(item.questionTitle));
    highestScoringDomainsQueStringTwo = BARC10_NOTE_OPTIONS[unique[unique.length - 2]];

    let lowestScoringDomain = `${lowestScoringDomainsQueStringOne && `1) Member selected ${lowestScoringDomainsQueStringOne} for question : ${lowestScoringDomainsAnsOne.toString()}`} \n \n`.split(".,").join(" and "); // replace has some issue with variable string thats why using split join

    if (lowestScoringDomainsQueStringTwo) {
      lowestScoringDomain += `${lowestScoringDomainsQueStringTwo && `2) Member selected ${lowestScoringDomainsQueStringTwo} for question : ${lowestScoringDomainsAnsTwo.toString()}`}`.split(".,").join(" and ");
    }

    let highestScoringDomain = `${highestScoringDomainsQueStringOne && `1) Member selected ${highestScoringDomainsQueStringOne} for question : ${highestScoringDomainsAnsOne.toString()}`} \n \n`.split(".,").join(" and ");

    if (highestScoringDomainsQueStringTwo) {
      highestScoringDomain += `${highestScoringDomainsQueStringTwo && `2) Member selected ${highestScoringDomainsQueStringTwo} for question : ${highestScoringDomainsAnsTwo.toString()}`}`.split(".,").join(" and ");
    }

    // Set lowest scoring domain
    setLowestScoringDomain(lowestScoringDomain);

    // Set highest scoring domain
    setHighestScoringDomain(highestScoringDomain);
  };

  renderEditFormButton = (data = {}) => {
    const {
      store: {
        ProfileStore: { lastEditUserInfo },
        AuthStore: { type }
      } } = this.props;
    const { formName, completedAt } = data;

    const isDevOrSA = [SA].includes(type);

    const editableForms = [
      FORM_NAME_ENCOUNTER_NOTE_V2,
      FORM_NAME_ENROLLMENT_NOTE,
      FORM_NAME_BARC10_NOTE_TEMPLATE,
      FORM_NAME_OUTREACH_NOTE,
      FORM_NAME_APP_ENCOUNTER_TEMPLATE,
      FORM_NAME_ORIENTATION_NOTE_V2,
      FORM_NAME_APP_ENCOUNTER_TEMPLATE_V2,
      FORM_NAME_BARC10_NOTE_TEMPLATE_V2,
      FORM_NAME_ENCOUNTER_NOTE_V3,
    ];
    // We only need to show "Edit Form" button to certain forms
    const isEditableForm = editableForms.includes(formName);

    // Here we are taking form completed date as 'YYYY-MM-DD' format
    const startDate = moment(completedAt).format('YYYY-MM-DD');
    // Here we are taking current date as 'YYYY-MM-DD' format
    const endDate = moment().format('YYYY-MM-DD');

    const completedDate = moment(startDate);
    const currentDate = moment(endDate);
    // Here we are calculating number of days between form submitted date & current date
    const numberOfDays = currentDate.diff(completedDate, 'days');
    const isFormSubmittedWithinLast45Days = numberOfDays <= 45;

    const shouldShowEditButton = isEditableForm && isDevOrSA && isFormSubmittedWithinLast45Days;
    return (<>
      {/* Here we only need to show edit form button if form condition satisfy */}
      {shouldShowEditButton ?
        (
          <div>
            <Button
              type="primary"
              title={'Edit Form'}
              style={{
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                borderRadius: 6,
                color: '#4168CA',
                background: '#fff',
              }}
              size="large"
              onClick={() => this.editFormClick()}
            >
              <img
                src={edit}
                className="edit-form-icon"
                alt="edit"
              />
              Edit Form
            </Button>
            {lastEditUserInfo && Object.keys(lastEditUserInfo).length ?
              <div style={{ marginTop: 10 }}>
                <span>
                  Last Edited By- <strong>{lastEditUserInfo.fullName}</strong>
                </span>
                <br />
                <span>
                  on <strong>{moment(lastEditUserInfo.editDoneOn).format("MMM Do YYYY, h:mm:ss a")}</strong>
                </span>
              </div>
              : null}
          </div>
        )
        : null
      }
    </>);
  };

  renderSubmitFormButton = () => {
    const {
      store: {
        ProfileStore: { isUserFormSubmitting },
      },
    } = this.props;
    return (<>
      <Button
        type="primary"
        title={'Submit Form'}
        style={{
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
          borderRadius: 6,
          color: '#4168CA',
          background: '#fff',
        }}
        size="large"
        onClick={() => this.submitFormClick()}
      >
        {isUserFormSubmitting ? <Spin /> : 'Submit Form'}
      </Button>
    </>);
  };

  renderHeaderForFormPanel = (data = {}) => {
    const { formName, formCompletedAt, submittedByName, isUserFormEdited, lastEditedBy, lastEditedAt } = data;

    return (<>
      <span style={{ width: '80%' }}>
        {formName}
        - {formCompletedAt ? moment(formCompletedAt).format("MMM Do YYYY, h:mm:ss a") : ''}
        {submittedByName ? `- Filled by ${submittedByName} ` : ''}
        {isUserFormEdited && lastEditedBy ? (
          <>
            <br />
            <strong>{` (edited by ${lastEditedBy} on ${moment(lastEditedAt).format("MMM Do YYYY, h:mm:ss a")})`}</strong>
          </>)
          : null}
      </span>
    </>);
  };

  render() {
    const {
      store: {
        ProfileStore,
        ProfileStore: {
          username,
          fullname,
          DOB,
          callOutreach,
          smsOutreach,
          assignedPeerDetails,
          enrolledPeerDetails,
          isUserFormEditActive
        },
        SiteStore: { allSites, getSiteNameFromAllSites },
        NotificationStore,
        AuthStore,
        HistoryStore: {
          historyLoading,
          skipStatusInGroup,
          totalStatusInGroup,
          userStatusInGroupHistory,
          resetPasswordHistory,
          skipInResetPassword,
          totalResetPassword,
          totalUserHistory,
          skipUserHistory,
          userHistory,
          resetHistory,
          skipGroupHistory,
          totalGroupHistory,
          groupHistory,
          skipUserOutreachHistory,
          totalUserOutreachHistory,
          userOutreachHistory,
        },
        MemberListStore: { detailsLoading, deleteUser, adminUsers, isUserAccountDeleting },
        FormMessageStore: { formsListData, loading, skipFormsInListing, totalFormsInListing },
        RewardStore: { onboardingStatus },
      },
    } = this.props;
    const options = this.getOptions(ProfileStore.queryResult);
    // const mixpanelDMs = this.getOptions(ProfileStore.mixpanelDM);
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    const menu = (
      <div className="sites-container">
        <Menu className="menu" onClick={this.updateUserSiteId}>
          {[...allSites.values()].map((site) => (
            <Menu.Item key={site.id} className="menu-item">
              <div>{site.name}</div>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
    const cocCompletion = ProfileStore.codeOfConduct
      ? moment(ProfileStore.codeOfConduct).format("MMM Do YYYY, h:mm:ss a")
      : null;
    const consentDoneAt = ProfileStore.consentDoneAt
      ? moment(ProfileStore.consentDoneAt).format("MMM Do YYYY, h:mm:ss a")
      : null;
    const userConsent = ProfileStore.userConsent;
    const isSuperAdmin = AuthStore.type === SA ? true : false;
    const isAdmin = AuthStore.type === MODERATOR ? true : false;
    const isNA = AuthStore.type === NOA;
    const isUser = ProfileStore.type === USER ? true : false;
    const isPeer = ProfileStore.type === SEMI_MODERATOR ? true : false;

    return detailsLoading ? (
      <div className="details-loading">
        <Spin />
      </div>
    ) : (
      <div>
        {ProfileStore.loading && (
          <div className="approve-spin">
            <Spin />
          </div>
        )}
        <CustomModal
          title="Group History"
          visible={this.state.groupHistoryVisible}
          onOk={this.handleOk}
          loading={historyLoading}
          loadMore={this.loadMoreGroupHistory}
          hasMore={skipGroupHistory < totalGroupHistory}
          onCancel={() => {
            resetHistory();
            this.changeGroupHistoryVisible(false);
          }}
          data={groupHistory ? groupHistory : []}
        />
        <CustomModal
          title="Reset Password History"
          visible={this.state.visible}
          onOk={this.handleOk}
          loadMore={this.loadMoreResetPasswordHistory}
          hasMore={skipInResetPassword < totalResetPassword}
          loading={historyLoading}
          onCancel={() => {
            resetHistory();
            this.changeModalVisible(false);
          }}
          data={resetPasswordHistory ? resetPasswordHistory : []}
        />
        <CustomModal
          title="Outreach History"
          visible={this.state.userOutreachHistoryVisible}
          onOk={this.handleOk}
          loading={historyLoading}
          loadMore={this.loadMoreUserOutreachHistory}
          hasMore={skipUserOutreachHistory < totalUserOutreachHistory}
          onCancel={() => {
            resetHistory();
            this.changeUserOutreachHistoryVisible(false);
          }}
          data={toJS(userOutreachHistory) ? toJS(userOutreachHistory) : []}
          isUserOutreachHistory
        />
        <CustomModal
          title="User History"
          visible={this.state.userHistoryVisible}
          onOk={this.handleOk}
          loading={historyLoading}
          loadMore={this.loadMoreUserHistory}
          hasMore={skipUserHistory < totalUserHistory}
          onCancel={() => {
            resetHistory();
            this.changeUserHistoryVisible(false);
          }}
          data={userHistory ? userHistory : []}
        />
        <CustomModal
          title="User Group Status History"
          visible={this.state.statusVisible}
          onOk={this.handleOk}
          loading={historyLoading}
          loadMore={this.loadMoreStatusHistory}
          hasMore={skipStatusInGroup < totalStatusInGroup}
          onCancel={() => {
            resetHistory();
            this.changeStatusVisible(false);
          }}
          data={userStatusInGroupHistory ? userStatusInGroupHistory : []}
        />
        <CustomFormModal
          title="Forms List"
          visible={this.state.formListVisible}
          onOk={this.handleOk}
          loading={loading}
          loadMore={this.loadMoreFormsListing}
          hasMore={skipFormsInListing < totalFormsInListing}
          onCancel={() => this.changeFormsListVisible(false)}
          data={toJS(formsListData) ? toJS(formsListData) : []}
        />
        <div className="userDetails-container">
          <div className="profile-edit">
            <div className="profile-type">
              {userProfile.memberOverView}
              <img
                src={info}
                className="info-icon"
                alt="info"
                onClick={() => this.fetchUserHistory()}
              />
            </div>
            {ProfileStore.editClinical ? (
              <div className="edit-buttons">
                <div
                  className="cancel-button"
                  edit="editClinical"
                  onClick={this.cancelPress}
                >
                  {userProfile.cancel}
                </div>
                <Button
                  type="primary"
                  title={
                    IS_HP
                      ? userProfile.submitHighpointToolTip
                      : userProfile.submitCleanslateToolTip
                  }
                  className="submit-button"
                  size="large"
                  edit="editClinical"
                  onClick={this.updateProfile}
                >
                  {userProfile.submit}
                </Button>
              </div>
            ) : (isNA &&
              (ProfileStore.userId === AuthStore.userId ||
                ProfileStore.type === "user" ||
                ProfileStore.type === "semi-moderator")) ||
              isAdmin ||
              isSuperAdmin ? (
              <img
                src={edit}
                className="profile-edit-image"
                onClick={this.editPress}
                alt="Edit"
                edit="editClinical"
              />
            ) : null}
          </div>
          <div className="user-bio-container">
            <div className="user-bio">
              <span className="subHeading">{userProfile.username}</span> <br />
              {ProfileStore.editClinical ? (
                <input
                  onChange={this.changeValue}
                  placeholder="Username"
                  className="input"
                  data-field="newUsername"
                  value={ProfileStore.newUsername}
                />
              ) : (
                <span className="mainHeading" id={ProfileStore.username}>{ProfileStore.username}</span>
              )}
            </div>
            <div className="user-bio">
              <span className="subHeading">{userProfile.fullName}</span> <br />
              {ProfileStore.editClinical ? (
                <input
                  onChange={this.changeValue}
                  placeholder="Full Name"
                  className="input"
                  data-field="newFullname"
                  value={ProfileStore.newFullname}
                />
              ) : (
                <span className="mainHeading">{ProfileStore.fullname}</span>
              )}
            </div>
          </div>
          <div className="user-bio-container">
            {IS_HP ? null : isUser || isPeer ? (
              <div className="user-bio">
                <span className="subHeading">{userProfile.mrn}</span> <br />
                {ProfileStore.editClinical ? (
                  <input
                    onChange={this.changeValue}
                    placeholder="MRN"
                    className="input"
                    data-field="newMrn"
                    value={ProfileStore.newMrn ? ProfileStore.newMrn : ""}
                  />
                ) : (
                  <span className="mainHeading">{ProfileStore.mrn}</span>
                )}
              </div>
            ) : null}

            {IS_HP ? (
              <div className="user-bio">
                {ProfileStore.recommendedBy || ProfileStore.editClinical ? (
                  <span className="subHeading">
                    {userProfile.siteHighPoint}
                  </span>
                ) : null}
                <br />
                {ProfileStore.editClinical ? (
                  <div id="hp-users-container" style={{ position: "relative" }}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select HighPoint Contact"
                      optionFilterProp="children"
                      onChange={this.updateChampion}
                      defaultValue={ProfileStore.contactName}
                      filterOption={(input, option) =>
                        get(option, "props.children.props.children", [])
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={() =>
                        document.getElementById("hp-users-container")
                      }
                    >
                      {ProfileStore.HpUsers.map((user) => (
                        <Option value={user.contactId} className="menu-item">
                          <div>{user.contactName}</div>
                        </Option>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <span className="mainHeading">
                    {ProfileStore.recommendedBy
                      ? ProfileStore.contactName
                      : null}{" "}
                    &nbsp;
                    <span className="champion-name">
                      {" "}
                      {ProfileStore.recommendedBy
                        ? ProfileStore.championName
                        : null}
                    </span>
                  </span>
                )}
              </div>
            ) : null}

            <div className="user-bio">
              <span className="subHeading">{userProfile.site}</span>
              <br />
              {ProfileStore.editClinical && (isAdmin || isSuperAdmin) ? (
                <div id="site-menu-container" style={{ position: "relative" }}>
                  <Dropdown
                    trigger={["click"]}
                    overlay={menu}
                    getPopupContainer={() =>
                      document.getElementById("site-menu-container")
                    }
                  >
                    <Button>
                      {getSiteNameFromAllSites(ProfileStore.newSiteId)}{" "}
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </div>
              ) : (
                <span className="mainHeading">
                  {getSiteNameFromAllSites(ProfileStore.siteId)}
                </span>
              )}
            </div>
          </div>
          <div className="user-bio-container">
            <div className="user-bio">
              <span className="subHeading">{userProfile.email}</span> <br />
              {ProfileStore.editClinical ? (
                <input
                  onChange={this.changeValue}
                  placeholder="Email"
                  className="input"
                  value={ProfileStore.newEmail}
                  data-field="newEmail"
                />
              ) : (
                <span className="mainHeading">{ProfileStore.email}</span>
              )}
            </div>
            <div className="user-bio">
              <span className="subHeading">{userProfile.phone}</span> <br />
              {ProfileStore.editClinical ? (
                <input
                  onChange={this.changeValue}
                  placeholder="Phone"
                  className="input"
                  data-field="newPhone"
                  value={ProfileStore.newPhone ? ProfileStore.newPhone : ""}
                />
              ) : (
                <span className="mainHeading">{getFormattedPhoneNumber(ProfileStore.phone)}</span>
              )}
            </div>
          </div>
          <div className="user-bio-container">
            {IS_HP ? (
              <div className="user-bio">
                <span className="subHeading">{userProfile.type}</span> <br />
                {ProfileStore.editClinical ? (
                  <div style={{ display: "flex" }}>
                    <Radio.Group
                      className="radio-group"
                      onChange={this.changeType}
                      defaultValue={ProfileStore.newType}
                    >
                      <Radio value={"user"}>{userProfile.member}</Radio>
                      <Radio value={"semi-moderator"}>
                        {userProfile.semiMod}
                      </Radio>
                      <Radio value={"moderator"}>{userProfile.admin}</Radio>
                    </Radio.Group>
                  </div>
                ) : (
                  <span className="mainHeading">
                    {this.showType(ProfileStore.type)}
                  </span>
                )}
              </div>
            ) : (
              <div className="user-bio">
                <span className="subHeading">{userProfile.type}</span> <br />
                {ProfileStore.editClinical ? (
                  <div style={{ display: "flex" }}>
                    <Radio.Group
                      className="radio-group"
                      onChange={this.changeType}
                      defaultValue={ProfileStore.newType}
                    >
                      <Radio value={"user"}>{userProfile.member}</Radio>
                      <Radio value={"semi-moderator"}>
                        {userProfile.semiMod}
                      </Radio>
                      <div className="types-divider"></div>
                      {isSuperAdmin || isAdmin ? (
                        <Radio value={"moderator"}>{userProfile.admin}</Radio>
                      ) : null}
                      {isSuperAdmin ? (
                        <Radio value={"SA"}>{userProfile.superAdmin}</Radio>
                      ) : null}
                      {isSuperAdmin ||
                        isAdmin ||
                        ProfileStore.type === "NOA" ? (
                        <Radio value={"NOA"}>{userProfile.NA}</Radio>
                      ) : null}
                    </Radio.Group>
                  </div>
                ) : (
                  <span className="mainHeading">
                    {this.showType(ProfileStore.type)}
                  </span>
                )}
              </div>
            )}
            {IS_HP ? (
              isUser || isPeer ? (
                <div className="user-bio">
                  <span className="subHeading">{userProfile.canAMember}</span>
                  <br />
                  {ProfileStore.editClinical ? (
                    <div style={{ display: "flex" }}>
                      <Radio.Group
                        className="radio-group"
                        onChange={this.changeStartGroup}
                        defaultValue={this.checkDefault()}
                      >
                        <Radio value={true}>{userProfile.yes}</Radio>
                        <Radio value={false}>{userProfile.no}</Radio>
                      </Radio.Group>
                    </div>
                  ) : (
                    <span className="mainHeading">
                      {this.checkDefault() ? "Yes" : "No"}
                    </span>
                  )}
                </div>
              ) : null
            ) : null}
          </div>
          <div className="user-bio-container">
            {/* {IS_HP ? null : isUser || isPeer ? (
              <div className="user-bio">
                <span className="subHeading">{userProfile.color}</span> <br />
                {ProfileStore.editClinical ? (
                  <div style={{ display: "flex" }}>
                    <Radio.Group
                      className="radio-group"
                      onChange={this.changeColor}
                      defaultValue={ProfileStore.newColor}
                    >
                      <Radio value={"green"}>{userProfile.green}</Radio>
                      <Radio value={"yellow"}>{userProfile.yellow}</Radio>
                      <Radio value={"red"}>{userProfile.red}</Radio>
                      <Radio value={"orange"}>{userProfile.orange}</Radio>
                    </Radio.Group>
                  </div>
                ) : (
                  <span className="mainHeading">{ProfileStore.color}</span>
                )}
              </div>
            ) : (
              <div className="user-bio"></div>
            )} */}
            {(isNA &&
              (isPeer || isUser || AuthStore.userId === ProfileStore.userId)) ||
              isAdmin ||
              isSuperAdmin ? (
              <div className="user-bio">
                <span className="subHeading">
                  <Popover
                    title={userProfile.popOverTitle}
                    trigger="click"
                    visible={ProfileStore.popOverVisible}
                    content={
                      <div className="reset-password-popover">
                        <input
                          onChange={this.changeValue}
                          placeholder={userProfile.newPassPlaceholder}
                          className="new-password"
                          data-field="password"
                          value={ProfileStore.password}
                        />

                        <div className="popover-buttons">
                          <Button
                            className="confirm-button"
                            disabled={
                              !ProfileStore.passwordIsValid ||
                              !ProfileStore.password ||
                              NotificationStore.active
                            }
                            type="primary"
                            onClick={() =>
                              NotificationStore.setConfirm(
                                () =>
                                  ProfileStore.changeUserPassword(
                                    ProfileStore.userId
                                  ),
                                user.passwordChangeNotification
                              )
                            }
                          >
                            {userProfile.confirm}
                          </Button>
                          <Button
                            disabled={NotificationStore.active}
                            onClick={() => ProfileStore.resetPassword()}
                          >
                            {userProfile.cancel}
                          </Button>
                        </div>
                      </div>
                    }
                  >
                    <div className="info-reset-password">
                      <span
                        className="reset-password"
                        onClick={() => ProfileStore.TogglePopOver(true)}
                      >
                        {userProfile.resetUserPass}
                      </span>
                      <img
                        src={info}
                        className="info-icon"
                        alt="info"
                        onClick={() => this.fetchResetPasswordHistory()}
                      />
                    </div>
                  </Popover>
                </span>
                <br />{" "}
                {ProfileStore.popOverVisible ? (
                  <ul>
                    <li>{userProfile.passwordText1}</li>
                    {/* <li>{userProfile.passwordText2}</li> */}
                    {/* <li>{userProfile.passwordText3}</li> */}
                  </ul>
                ) : null}
              </div>
            ) : null}
            <div></div>
            {(isUser || isPeer) && (
              <div className="user-bio">
                <span className="subHeading">{userProfile.assignedPeer}</span> <br />
                <div id="assigned-peer-container" style={{ position: "relative" }}>
                  {adminUsers && adminUsers.length ? (
                    <Select
                      value={assignedPeerDetails.assignedPeerFullName || ''}
                      style={{ width: "180px" }}
                      onChange={(value) => this.updateAssignedPeer(ProfileStore.userId, value)}
                      getPopupContainer={() =>
                        document.getElementById("assigned-peer-container")
                      }
                    >
                      {adminUsers.map(({ id, username, fullName }, index) => (
                        <Option value={id} key={index} disabled={assignedPeerDetails.assignedPeerId === id} >{fullName}</Option>
                      ))}
                    </Select>
                  ) : null}
                  {assignedPeerDetails && Object.keys(assignedPeerDetails).length ?
                    <p className="mt-1">Assigned Peer : Last updated by - <strong>{assignedPeerDetails.submittedByFullName} on {moment(assignedPeerDetails.submittedAt).format("MMM Do YYYY")}</strong></p>
                    : null
                  }
                </div>
                <br />
                <span className="subHeading">{userProfile.enrolledPeer}</span> <br />
                <div id="enrolled-peer-container" style={{ position: "relative" }}>
                  {adminUsers && adminUsers.length ? (
                    <Select
                      value={enrolledPeerDetails.enrolledPeerFullName || ''}
                      style={{ width: "180px" }}
                      onChange={(value) => this.updateEnrolledPeer(ProfileStore.userId, value)}
                      getPopupContainer={() =>
                        document.getElementById("enrolled-peer-container")
                      }
                    >
                      {adminUsers.map(({ id, username, fullName }, index) => (
                        <Option value={id} key={index} disabled={enrolledPeerDetails.enrolledPeerId === id} >{fullName}</Option>
                      ))}
                    </Select>
                  ) : null}
                  <br />
                  {enrolledPeerDetails && Object.keys(enrolledPeerDetails).length ?
                    <p className="mt-1"> Enrollment Peer : Last updated by - <strong>{enrolledPeerDetails.submittedByFullName} on {moment(enrolledPeerDetails.submittedAt).format("MMM Do YYYY")}</strong></p>
                    : null
                  }
                </div>
                <br />
              </div>
            )}
          </div>

          <div className="user-bio-container">
            <div>
              <span className="subHeading">{userProfile.notes}</span> <br />
              {ProfileStore.editClinical ? (
                <textarea
                  className="notes-textArea"
                  data-field="newNotes"
                  onChange={this.changeValue}
                  value={ProfileStore.newNotes ? ProfileStore.newNotes : ""}
                />
              ) : (
                <div className="notes-mainHeading">
                  <span className="pre-mainHeading">{ProfileStore.notes}</span>
                </div>
              )}
            </div>
          </div>
          <div className="user-bio-container">
            <div className="user-bio">
              {ProfileStore.showDOB || ProfileStore.editClinical ? (
                <span className="subHeading">{userProfile.dob}</span>
              ) : null}
              <br />{" "}
              {ProfileStore.editClinical ? (
                <input
                  type="date"
                  className="form-control dobInput"
                  placeholder="Date Of Birth"
                  value={ProfileStore.newDOB}
                  onChange={this.changeValue}
                  data-field="newDOB"
                />
              ) : ProfileStore.showDOB ? (
                <span className="mainHeading">
                  {getFormattedDate(ProfileStore.DOB)}
                </span>
              ) : null}
            </div>
          </div>
          <div className="user-bio-container">
            <div className="user-bio">
              <span className="subHeading">{userProfile.signUpOn}</span> <br />{" "}
              <span className="mainHeading">
                {moment(ProfileStore.createdAt).format("MMM Do YYYY, h:mm:ss a")}
              </span>
            </div>

            {/* Div container for user badges */}
            <div className="user-bio">
              <span className="subHeading">{userProfile.userBadge}</span> <br />
              {(isSuperAdmin || isAdmin) && ProfileStore.editClinical ? (
                <div id="user-badge-type-container" style={{ display: 'flex', position: "relative" }}>
                  <Select
                    value={ProfileStore.newBadgeType || ProfileStore.badgeType || 'No Badge'}
                    style={{ width: '65%' }}
                    onChange={(value) => this.updateUserBadgeType(value)}
                    getPopupContainer={() =>
                      document.getElementById("user-badge-type-container")
                    }
                  >
                    {Object.keys(USER_BADGES).map((badgeType, index) => (
                      <Option
                        value={badgeType}
                        key={index}
                        disabled={ProfileStore.newBadgeType ? ProfileStore.newBadgeType === badgeType : ProfileStore.badgeType === badgeType}
                      >
                        {this.renderUserBadgeWrapper(badgeType)}
                      </Option>
                    ))}
                    {ProfileStore.badgeType ?
                      (<Option
                        style={{ color: 'red', textAlign: 'center' }}
                        value={BADGE_TYPE_REMOVE}
                        disabled={ProfileStore.newBadgeType ? ProfileStore.newBadgeType === BADGE_TYPE_REMOVE
                          : ProfileStore.badgeType === BADGE_TYPE_REMOVE}
                      >
                        {userProfile.removeBadge}
                      </Option>)
                      : null
                    }
                  </Select>
                </div>
              ) : (
                <span className="mainHeading">
                  {ProfileStore.badgeType ? this.renderUserBadgeWrapper(ProfileStore.badgeType) : 'No Badge'}
                </span>
              )}

            </div>
          </div>
          <div className="user-bio-container">
            {isUser || isPeer ? (
              <div className="user-bio">
                <span className="subHeading">{userProfile.codeOfConduct}</span>{" "}
                <br />{" "}
                <span className="mainHeading">
                  {cocCompletion
                    ?
                    <>
                      {userProfile.completedOnline} {cocCompletion} {userProfile.est}
                    </>
                    : userProfile.incompleted}
                </span>
              </div>
            ) : null}
          </div>
          <div className="user-bio-container">
            {(isUser || isPeer) && consentDoneAt ? (
              <div className="user-bio">
                <span className="subHeading">{userProfile.consentDone}</span>{" "}
                <br />{" "}

                <span className="mainHeading">
                  {userProfile.completedOnline} {consentDoneAt}
                  {ProfileStore.loading ?
                    <div className="approve-spin">
                      <Spin />
                    </div>
                    :
                    <>
                      <a id='dwnldLnk'
                        download={`${this.generateFileNameFromPersonalInfo({ fullname, dob: DOB })}-consent.pdf`}
                        disabled={ProfileStore.loading}
                        style={{ display: "none" }} />
                      <img
                        src={download}
                        className="consentdownload-icon"
                        alt="download"
                        onClick={() => this.downloadConsentPDF(ProfileStore.userId)}
                      />
                      {userConsent && userConsent.type ? (
                        <span>
                          {userConsent.type}
                        </span>) : ""}
                    </>
                  }
                </span>
              </div>
            ) : null}
          </div>
          <div className="user-bio-container">
            {ProfileStore.isApproved ? (
              isUser || isPeer ? (
                <div className="user-bio">
                  <span className="subHeading">{userProfile.approvedBy}</span>{" "}
                  <br />{" "}
                  <span className="mainHeading">
                    {ProfileStore.approvedbyUser} {userProfile.on}{" "}
                    {moment(ProfileStore.approvedAt).format("MMM Do YYYY, h:mm:ss a")}
                  </span>
                </div>
              ) : (
                <div className="user-bio">
                  <span className="subHeading"></span>
                  <br /> <span className="mainHeading"></span>{" "}
                </div>
              )
            ) : isUser || isPeer ? (
              <Tooltip title={IS_HP ? null : toolTips.approve}>
                <Button
                  className="download-button"
                  type="primary"
                  size="large"
                  style={{ width: "145px" }}
                  disabled={NotificationStore.active}
                  onClick={() => {
                    NotificationStore.setConfirm(() => {
                      ProfileStore.changeUserStatus(
                        ProfileStore.userId,
                        this.props.allMembers
                      );
                    }, user.approveNotification);
                  }}
                >
                  {userProfile.approve}
                </Button>
              </Tooltip>
            ) : (
              <div className="user-bio">
                <span className="subHeading"></span>
                <br /> <span className="mainHeading"></span>{" "}
              </div>
            )}
            {isSuperAdmin ||
              isAdmin ||
              (isNA && ProfileStore.userId === AuthStore.userId) ? (
              <div className="delete-account-container">
                <Button
                  className="delete-account-button"
                  loading={isUserAccountDeleting}
                  disabled={NotificationStore.active}
                  onClick={() =>
                    NotificationStore.setConfirm(() => {
                      deleteUser(ProfileStore.userId);
                    }, user.deleteNotification)
                  }
                >
                  {userProfile.deleteAccount}
                </Button>
              </div>
            ) : null}
          </div>

          {/* User Additional Details */}
          {<UserAdditionalDetails />}

          {/** My rewards */}
          <div>
            {ProfileStore.userId && <Rewards />}
          </div>

          {/** My tasks */}
          <div>
            {ProfileStore.userId && <Task userId={ProfileStore.userId} />}
          </div>

          <div>
            {ProfileStore.userId && <WellnessForm userId={ProfileStore.userId} />}
          </div>

          <div className="profile-edit">
            <div className="profile-type">
              {userProfile.outreach}
              <img
                src={info}
                className="info-icon"
                alt="info"
                onClick={() => this.fetchUserOutreachHistory()} />
            </div>
          </div>

          <div className="outreach-info-container">
            <div className="mt-1 d-flex  align-items-center">
              <div className="outreach-info-inner">
                {userProfile.smsOutreach}
              </div>
              <div id="sms-outreach-container" style={{ position: "relative" }}>
                <Select
                  value={SMS_OUTREACH_FLAGS[smsOutreach] || SMS_OUTREACH_FLAGS['SMS_DUE']}
                  style={{ width: "150px" }}
                  onChange={(value) => this.changeOutreachTypeInProfile(ProfileStore.userId, true, value)}
                  getPopupContainer={() =>
                    document.getElementById("sms-outreach-container")
                  }
                >
                  <Option value={SMS_OUTREACH_FLAGS['SMS_DUE']}>{userProfile.smsDue}</Option>
                  <Option value={SMS_OUTREACH_FLAGS['SMS_SENT']}>{userProfile.smsSent}</Option>
                </Select>
              </div>
            </div>
            <br />
            <div className="mt-1 d-flex  align-items-center">
              <div className="outreach-info-inner">
                {userProfile.callOutreach}
              </div>
              <div id="call-outreach-container" style={{ position: "relative" }}>
                <Select
                  value={CALL_OUTREACH_FLAGS[callOutreach] || CALL_OUTREACH_FLAGS['CALL_DUE']}
                  style={{ width: "150px" }}
                  onChange={(value) => this.changeOutreachTypeInProfile(ProfileStore.userId, false, value)}
                  getPopupContainer={() =>
                    document.getElementById("call-outreach-container")
                  }
                >
                  <Option value={CALL_OUTREACH_FLAGS['CALL_DUE']}>{userProfile.callDue}</Option>
                  <Option value={CALL_OUTREACH_FLAGS['CALL_ATTEMPT']}>{userProfile.callAttempt}</Option>
                  <Option value={CALL_OUTREACH_FLAGS['TALKED_TO']}>{userProfile.callTalkedTo}</Option>
                </Select>
              </div>
            </div>
          </div>

          {/* Forms Listing */}
          {<div>
            <div className="profile-edit">
              <div className="profile-type">{userProfile.forms}</div>
              <Button
                type="primary"
                title="Get Forms List"
                className="download-button"
                size="large"
                onClick={this.getFormsList}
              >
                {userProfile.getFormsList}
              </Button>
            </div>
            {ProfileStore.forms.length ? (
              <Collapse onChange={this.handleFormClick} accordion={true}>
                {ProfileStore.forms.map((item) => {
                  const {
                    totalScore,
                    form,
                    completedAt,
                    id,
                    reviewedBy,
                    reviewedAt,
                    reviewedUserDetails,
                    submittedFrom,
                    user,
                    submittedFromDetails,
                    submittedFromId,
                    isUserFormEdited,
                    lastEditedBy,
                    lastEditedAt
                  } = item;
                  const isFormReviewedByAdmin = reviewedBy && reviewedAt && reviewedUserDetails ? true : false;
                  const submittedByName = submittedFrom === SUBMITTED_FROM_TYPE_ADMIN ? `ADMIN ${submittedFromDetails?.fullName}` : user?.fullName;
                  if (item.isSubmitted) {
                    return (
                      <Panel
                        className="form-panel-wrapper"
                        header={this.renderHeaderForFormPanel({
                          formName: form.name,
                          formCompletedAt: completedAt,
                          submittedByName,
                          isUserFormEdited,
                          lastEditedBy,
                          lastEditedAt
                        })}
                        extra={totalScore ? `Form Score- ${totalScore}` : ''}
                        key={`${item.messageId}+${item.formId}`}
                      >
                        {!ProfileStore.formLoading ? (
                          <ol className="form-qa-container">
                            <div className="form-completed-at">
                              Completed: In {item.submittedFrom} on{" "}
                              {moment(item.completedAt).format("MMM Do YYYY, h:mm:ss a")}
                            </div >
                            {
                              isUserFormEditActive ?
                                <EditUserForm
                                  formQuestions={ProfileStore.formQuestions}
                                  getAnswerToFormQuestion={this.getAnswerToFormQuestion}
                                /> :
                                ProfileStore.formQuestions.map(
                                  (question, index) => {
                                    return (
                                      <li className="form-qa-container" key={index}>
                                        <div className="form-question">
                                          {question.questionTitle}
                                        </div>
                                        <div className="form-answer">
                                          <span className="form-question">
                                            Answer.
                                          </span>{" "}
                                          {this.getAnswerToFormQuestion(question)}
                                        </div>
                                      </li>
                                    );
                                  }
                                )
                            }
                          </ol >
                        ) : (
                          <div className="form-loading">
                            <Spin />
                          </div>
                        )}
                        <div className="action-buttons-wrapper">
                          {ProfileStore.formLoading ? null : (
                            form.name.toLowerCase() === FORM_NAME_BARC10.toLowerCase() ?
                              (<>
                                <Button
                                  type="primary"
                                  title={isFormReviewedByAdmin ? `Form Reviewed at ${moment(reviewedAt).format("MMM Do YYYY, h:mm:ss a")}` : 'Submit Review'}
                                  style={isFormReviewedByAdmin ? {} : {
                                    background: '#4168ca',
                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                    borderRadius: 6,
                                  }}
                                  size="large"
                                  onClick={() => this.handleFormReview(id)}
                                  disabled={isFormReviewedByAdmin}
                                >
                                  {isFormReviewedByAdmin ? `Form Reviewed By ${reviewedUserDetails.fullName}` : 'Submit Review'}
                                </Button>
                                {loading ? <Spin indicator={antIcon} /> : <Button
                                  type="primary"
                                  title={`Fill BARC10 Note`}
                                  size="large"
                                  style={{
                                    background: '#4168ca',
                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                    borderRadius: 6,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                  onClick={() => this.openBARC10NoteForm(item)}
                                >
                                  {`Fill BARC10 Note`}
                                </Button>}
                              </>) : null
                          )}
                          {ProfileStore.formLoading ? null :
                            (
                              <>
                                <a id='formDownloadLink'
                                  download={`form-${this.generateFileNameFromPersonalInfo({ fullname, dob: DOB })}-${form.name}.pdf`}
                                  disabled={ProfileStore.formLoading}
                                  style={{ display: "none" }} />
                                <Button
                                  type="primary"
                                  title={'Form Pdf Download'}
                                  style={{
                                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                    borderRadius: 6,
                                    color: '#4168CA',
                                    background: '#fff',
                                    marginLeft: 15
                                  }}
                                  size="large"
                                  onClick={() => this.downloadFormPdf({ userFormId: id, formName: form.name })}
                                >
                                  Form Download
                                  <img
                                    src={download}
                                    className="form-download-icon"
                                    alt="download"
                                  />
                                </Button>

                                {(isUserFormEditActive ? this.renderSubmitFormButton()
                                  : this.renderEditFormButton({ formName: form.name, completedAt, submittedFromId }))
                                }
                              </>
                            )}
                        </div>
                      </Panel >
                    );
                  } else return null;
                })}
              </Collapse>
            ) : null}
          </div>}

          {/* User Insurance Details */}
          {<div className="insurance-wrapper" >
            {ProfileStore.userId && <UserInsuranceDetails />}
          </div>}

          <div className="profile-edit">
            <div className="profile-type">{userProfile.groups}</div>
          </div>

          <div className="user-bio-container">
            <div className="user-bio">
              {ProfileStore.assignedGroups.length > 0 ? (
                <span className="groupHeading over-sized-group-name">
                  {ProfileStore.username} {userProfile.isAPartOf}{" "}
                  {this.getAssignedGroups()}{" "}
                  {ProfileStore.assignedGroups.length === 1
                    ? userProfile.groupLength
                    : userProfile.groupsLength}
                  .
                </span>
              ) : (
                <span className="mainHeading">
                  {ProfileStore.username} {userProfile.notAPart}
                </span>
              )}
              <br />
              <br />
              {IS_HP ? (
                <div>
                  {/* <div>
                    {ProfileStore.contactModeratedGroups.length ? (
                      <span className="subHeading">
                        {userProfile.contactModerated}{" "}
                        {ProfileStore.contactName} {userProfile.isModerating}
                      </span>
                    ) : null}
                    <div className="recruiting-groups">
                      {ProfileStore.groupsString
                        ? ProfileStore.contactModeratedGroups.map(item => (
                            <div className="recruiting-group">
                              <span className="mainHeading">{item}</span>
                            </div>
                          ))
                        : null}
                    </div>
                  </div> */}
                  <div>
                    {ProfileStore.champModeratedGroups.length ? (
                      <span className="subHeading">
                        {userProfile.champModerated} {ProfileStore.championName}{" "}
                        {userProfile.isModerating}
                      </span>
                    ) : null}

                    <div className="recruiting-groups">
                      {ProfileStore.groupsString
                        ? ProfileStore.champModeratedGroups.map((item) => (
                          <div className="recruiting-group">
                            <span className="mainHeading">{item}</span>
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                  {/* <div>
                    {ProfileStore.groupOptions.length ? (
                      <span className="subHeading">
                        {userProfile.recommendedGroups}
                      </span>
                    ) : null}
                    <div className="recruiting-groups">
                      {ProfileStore.groupsString
                        ? ProfileStore.groupOptions.map(item => (
                            <div className="recruiting-group">
                              <span className="mainHeading">{item}</span>
                            </div>
                          ))
                        : null}
                    </div>
                  </div> */}
                </div>
              ) : (
                <div>
                  <span className="subHeading">{ProfileStore.line1}</span>
                  <div className="recruiting-groups">
                    {ProfileStore.groupsString
                      ? ProfileStore.groupsString
                        .split("\n")
                        .map((item, index) => (
                          <div className="recruiting-group" key={index}>
                            <span className="mainHeading">{item}</span>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              )}
              {/* <span className="subHeading">{ProfileStore.line2}</span> */}
            </div>
          </div>
          {
            isNA &&
              (ProfileStore.type === "moderator" ||
                ProfileStore.type === "SA" ||
                ProfileStore.type === "NOA") ? null : (
              <div className="user-bio-container">
                <Button
                  className="download-button"
                  type="primary"
                  size="large"
                  onClick={() => this.addToGroup(ProfileStore.userId)}
                >
                  {detailsLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    userProfile.addToGroups
                  )}
                </Button>
              </div>
            )
          }
          {
            isNA &&
              (ProfileStore.type === "moderator" ||
                ProfileStore.type === "SA" ||
                ProfileStore.type === "NOA") ? null : (
              <div className="user-group-details">
                {map(ProfileStore.assignedGroups, (group, index) => {
                  const groupStatus = group.user_groups
                    ? group.user_groups.status
                    : null;
                  return (
                    <div className="user-groups" key={group.id}>
                      <div>
                        <div className="user-group-name">
                          {group.name.length > 50
                            ? `${group.name.slice(0, 50)}...`
                            : group.name}
                          &nbsp;
                          <div>
                            ( {this.getMemberCount(group.id)}{" "}
                            {members.membersCountText})
                          </div>
                          <img
                            src={info}
                            className="info-icon"
                            alt="info"
                            onClick={() => {
                              this.fetchGroupHistory(group.id, group.name);
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex pr-1">
                        <div style={{ flex: 1 }}>
                          {this.showGroupSemiMods(group.id).length > 0 ? (
                            <div className="user-group-status">
                              {userProfile.peerModeration}{" "}
                              <span className="group-semiMods">
                                {this.groupSemiMods(group.id)}
                              </span>
                            </div>
                          ) : null}
                          <div className="mt-1 d-flex  align-items-center">
                            <div className="user-group-status">
                              {userProfile.badged}
                            </div>
                            <Select
                              value={
                                group &&
                                  group.user_groups &&
                                  group.user_groups.type
                                  ? group.user_groups.type
                                  : ProfileStore.type
                              }
                              getPopupContainer={() =>
                                document.getElementById("menu-container")
                              }
                              style={{ width: "170px" }}
                              onChange={(value) =>
                                this.changeTypeInGroup(
                                  value,
                                  group.id,
                                  ProfileStore.userId
                                )
                              }
                            >
                              <Option value="user">{userProfile.member}</Option>
                              <Option value="moderator">
                                {userProfile.admin}
                              </Option>
                              <Option value="semi-moderator">
                                {userProfile.semiMod}
                              </Option>
                              <Option value="community-moderator">
                                {userProfile.communityMod}
                              </Option>
                            </Select>
                          </div>
                          <div className="mt-1 d-flex  align-items-center">
                            <div className="user-group-status">
                              {userProfile.status}
                            </div>
                            <div
                              id="menu-container"
                              style={{ position: "relative" }}
                            >
                              <Select
                                value={this.status(groupStatus)}
                                style={{ width: 120 }}
                                onChange={(value) =>
                                  this.setGroupStatus(value, group.id)
                                }
                                getPopupContainer={() =>
                                  document.getElementById("menu-container")
                                }
                              >
                                {groupStatus === 1 ? null : (
                                  <Option title="Active" value={1}>
                                    <i className="fa fa-check">
                                      {userProfile.activate}
                                    </i>
                                  </Option>
                                )}
                                {groupStatus === 2 ? null : (
                                  <Option title="Suspended" value={2}>
                                    <i className="fa fa-minus-circle">
                                      {userProfile.suspend}
                                    </i>
                                  </Option>
                                )}
                                {groupStatus === 3 ? null : (
                                  <Option title="Paused" value={3}>
                                    <i className="fa fa-pause">
                                      {userProfile.paused}
                                    </i>
                                  </Option>
                                )}
                                <Option value={4}>
                                  <i className="fa fa-ban">
                                    {userProfile.denied}
                                  </i>
                                </Option>
                              </Select>
                            </div>
                            <img
                              src={info}
                              className="info-icon"
                              alt="info"
                              onClick={() => {
                                this.fetchGroupStatusHistory(
                                  group.id,
                                  group.name
                                );
                              }}
                            />
                          </div>
                          {group.user_groups &&
                            group.user_groups.addedBy &&
                            ProfileStore.addedByUsernames.has(
                              group.user_groups.addedBy
                            ) ? (
                            <div className="Added-by">
                              {userProfile.addedBy}{" "}
                              <span className="added-by-username">
                                {ProfileStore.addedByUsernames.get(
                                  group.user_groups.addedBy
                                )}
                              </span>{" "}
                              on {getFormattedDate(group.user_groups.createdAt)}
                            </div>
                          ) : null}
                        </div>
                        {options.length ? (
                          <div className="graph-container" style={{ flex: 1 }}>
                            <BarGraph data={options[index]} />
                          </div>
                        ) : ProfileStore.groupGraphLoading ? (
                          <div className="delete-account-container">
                            <Spin />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          }
          {
            this.getLatestQuestion(ProfileStore.Questions) ? (
              <div>
                <div className="profile-edit">
                  <div className="profile-type">{userProfile.bio}</div>
                  {ProfileStore.editBio ? (
                    <div className="edit-buttons">
                      <div
                        className="cancel-button"
                        edit="editBio"
                        onClick={this.cancelPress}
                      >
                        {userProfile.cancel}
                      </div>
                      <Button
                        type="primary"
                        className="submit-button"
                        size="large"
                        onClick={() => ProfileStore.updateLatestAnswer()}
                      >
                        {ProfileStore.bioLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  ) : (
                    <img
                      src={edit}
                      className="profile-edit-image"
                      onClick={this.editPress}
                      alt="Edit"
                      edit="editBio"
                    />
                  )}
                </div>

                <div className="user-bio-container">
                  <div className="user-bio">
                    <span className="subHeading">
                      {this.getLatestQuestion(ProfileStore.Questions)}
                    </span>{" "}
                    <br />
                    {ProfileStore.editBio ? (
                      <input
                        onChange={(e) => this.changeLatestAnswer(e)}
                        defaultValue={this.getAnswerToQuestion()}
                        className="questionInput"
                        type="text"
                        placeholder={this.getLatestQuestion(
                          ProfileStore.Questions
                        )}
                      />
                    ) : (
                      <span className="mainHeading">
                        {this.getAnswerToQuestion()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : null
          }

        </div>
      </div >
    );
  }
}

export default UserDetails;
