import React from 'react';

const PopoverAction = (props) => {
  const { actionName, icon, isDisabled } = props;

  return (
    <div 
      className="popover-action"
      style={{
        cursor: isDisabled ? 'not-allowed' : 'cursor',
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <img style={{ height: 20 }} src={icon} alt={actionName}/>
      <span style={{ marginTop: 5 }}>{actionName}</span>
    </div>
  );
};

export default PopoverAction;
