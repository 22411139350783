import React, { useEffect } from 'react'

const EmbeddedWidget = ({ widget, dashboard, sisenseHost }) => {
    let widgetContainer = null;
    useEffect(() => { embeddingBloxWithSisenseJs() }, []);

    const embeddingBloxWithSisenseJs = () => {
        widgetContainer['widgetid'] = widget.id;
        widget.container = widgetContainer

        return dashboard.refresh()
    }

    return (
        <div className='embedded column' style={{ marginBottom: 30 }}>
            <div className="embedded-widget" ref={node => widgetContainer = node}></div>
        </div>
    )
}

{/* <div className='embedded column' style={{ height: '85vh' }}>
    <div className="embedded-widget" style={{ height: '85vh' }} ref={node => widgetContainer = node}></div>
</div> */}

export default EmbeddedWidget;