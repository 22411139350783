import React from "react";
import close from "../../static/images/close.png";


export const ResetPwdModal = (props) => {
  const { onOk, onClose } = props;
  return (
    <div>
      <div>
        <div className="confirm-backdrop" onClick={onClose} />
        <div className="confirm-modal">
          <h1>
             Reset password is recommended
            <img src={close} onClick={onClose} alt="Close" />
          </h1>
          <p> You can reset your password from here itself.</p>
          <div className="modal-buttons">
            <button className="confirm" onClick={onOk}>
              Reset Now 
            </button>
            <button className="cancel" onClick={onClose}>
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
