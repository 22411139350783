import UserGroups from "./UserGroups";
import { types } from "mobx-state-tree";

const Group = types
  .model("Group", {
    id: types.maybe(types.number),
    name: types.maybe(types.string),
    description: types.maybe(types.string),
    siteId: types.maybeNull(types.number),
    imageUrl: types.maybeNull(types.string),
    code: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    updatedAt: types.maybe(types.string),
    memberCount: 0,
    user_groups: types.maybeNull(UserGroups),
    alertCount: types.maybeNull(types.number),
    isIntersiteGroup: types.maybeNull(types.boolean),
    moderators: types.array(
      types.frozen({
        username: types.string,
        type: types.string,
      })
    ),
    checklist: types.maybeNull(types.string),
    semiModerators: types.array(
      types.frozen({
        username: types.string,
        type: types.string,
      })
    ),
    pausedCount: types.maybeNull(types.number),
    unPausedCount: types.maybeNull(types.number),
    users: types.array(
      types.frozen({
        username: types.string,
        userId: types.number,
        status: types.maybeNull(types.number),
      })
    ),
  })
  .volatile((self) => ({
    status: null,
  }));

export default Group;
