import { types } from "mobx-state-tree";
import { cloneDeep } from "lodash";

import ApiService from "../utils/ApiService";
import Event from "./Event";
import UserOutreachHistory from "./UserOutreachHistory";

const History = types
  .model("History", {
    historyLoading: false,
    selectedGroupId: types.maybeNull(types.number),
    selectedAlertId: types.maybeNull(types.number),
    selectedUserId: types.maybeNull(types.number),
    selectedGroupName: types.maybeNull(types.string),
    selectedChannelType: types.maybeNull(types.string),
    skipInMessageHistory: 0,
    skipInResetPassword: 0,
    skipStatusInGroup: 0,
    skipUserHistory: 0,
    skipUserOutreachHistory: 0,
    skipGroupHistory: 0,
    skipAllHistory: 0,
    totalStatusInGroup: 0,
    totalResetPassword: 0,
    totalMessageHistory: 0,
    totalUserHistory: 0,
    totalUserOutreachHistory: 0,
    totalGroupHistory: 0,
    totalAllHistory: 0,
    userStatusInGroupHistory: types.array(Event),
    resetPasswordHistory: types.array(Event),
    messageHistory: types.array(Event),
    userHistory: types.array(Event),
    groupHistory: types.array(Event),
    allHistory: types.array(Event),
    userOutreachHistory: types.array(UserOutreachHistory),
    showAllActivities: false,
    groupNames: types.map(types.frozen({ name: types.string })),
    assignedUsernames: types.map(types.frozen({ name: types.string })),
    showAllNotifications: false,
  })
  .actions((self) => ({
    setShowAllActivities(value) {
      self.showAllActivities = value;
    },
    async getUsernameFromIds(userIds) {
      if (userIds.length) {
        const params = {
          payload: userIds,
        };
        const response = await ApiService.postRequest("username-by-id", params);
        if (response.success) {
          return response.data;
        }
      }
    },
    async getGroupNameFromIds(idArray) {
      if (idArray.length) {
        const query = idArray.reduce((obj, id, index) => {
          const key = `id[${index}]`;
          if (!obj.hasOwnProperty(key)) {
            obj[key] = id;
          }
          return obj;
        }, {});
        const params = {
          "$select[1]": "id",
          "$select[2]": "name",
          ...query,
        };
        const response = await ApiService.getRequest("groups", params);
        return response.data;
      }
    },
    async fetchStatusHistory(channelId, groupName, targetUserId, channelType) {
      if (!targetUserId) {
        return;
      }
      self.setSelectedGroupId(channelId);
      self.setSelectedGroupName(groupName);
      self.setSelectedUserId(targetUserId);
      const endPoint = "history";
      const params = {
        targetUserId,
        channelType,
        channelId,
        $skip: self.skipStatusInGroup,
        "$sort[createdAt]": -1,
      };
      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateStatusInGroupMeta(cloneDeep(historyResponse.meta));
        self.setUserStatusInGroupHistory(
          cloneDeep(historyResponse.data),
          groupName
        );
        self.setHistoryLoading(false);
      }
      self.setHistoryLoading(false);
    },
    async fetchPasswordResetHistory(targetUserId) {
      if (!targetUserId) {
        return;
      }
      const endPoint = "history";
      const params = {
        targetUserId,
        eventType: 9,
        $skip: self.skipInResetPassword,
        "$sort[createdAt]": -1,
      };
      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success && historyResponse.data.length) {
        self.updateResetPasswordMeta(cloneDeep(historyResponse.meta));
        self.setResetPasswordHistory(cloneDeep(historyResponse.data));
        self.setHistoryLoading(false);
      }
      self.setHistoryLoading(false);
    },
    async fetchUserHistory(targetUserId) {
      const endPoint = "history";
      const params = {
        targetUserId,
        $skip: self.skipUserHistory,
        "$sort[createdAt]": -1,
      };

      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateUserHistoryMeta(cloneDeep(historyResponse.meta));
        self.setUserHistory(cloneDeep(historyResponse.data));
        self.setHistoryLoading(false);
      } else {
        self.setHistoryLoading(false);
      }
    },
    async fetchUserOutreachHistory(userId) {
      const endPoint = "user-outreach-history";
      const params = {
        userId,
        $skip: self.skipUserOutreachHistory,
        "$sort[createdAt]": -1,
        include: true,
        includeOnly: 'users',
        isSpecificKeysRequiredForUser: true
      };

      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateUserOutreachHistoryMeta(cloneDeep(historyResponse.meta));
        self.setUserOutreachHistory(cloneDeep(historyResponse.data));
      }
      self.setHistoryLoading(false);
    },
    async fetchGroupHistory(channelId, groupName) {
      const endPoint = "history";
      const params = {
        channelId,
        channelType: "GROUP",
        $skip: self.skipGroupHistory,
        "$sort[createdAt]": -1,
      };
      self.setSelectedGroupId(channelId);
      self.setSelectedGroupName(groupName);
      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateGroupHistoryMeta(cloneDeep(historyResponse.meta));
        self.setGroupHistory(cloneDeep(historyResponse.data), groupName);
        self.setHistoryLoading(false);
      } else {
        self.setHistoryLoading(false);
      }
    },
    resetHistory() {
      self.historyLoading = false;
      self.selectedGroupId = null;
      self.selectedAlertId = null;
      self.selectedUserId = null;
      self.selectedGroupName = null;
      self.skipInMessageHistory = 0;
      self.skipInResetPassword = 0;
      self.skipStatusInGroup = 0;
      self.skipUserHistory = 0;
      self.skipGroupHistory = 0;
      self.skipAllHistory = 0;
      self.skipUserOutreachHistory = 0;
      self.totalStatusInGroup = 0;
      self.totalResetPassword = 0;
      self.totalMessageHistory = 0;
      self.totalUserHistory = 0;
      self.totalGroupHistory = 0;
      self.totalAllHistory = 0;
      self.totalUserOutreachHistory = 0;
      self.userStatusInGroupHistory = [];
      self.resetPasswordHistory = [];
      self.messageHistory = [];
      self.userHistory = [];
      self.groupHistory = [];
      self.allHistory = [];
      self.userOutreachHistory = [];
      self.showAllNotifications = false;
    },
    async fetchMessageHistory(messageReactionId, groupName) {
      self.setSelectedAlertId(messageReactionId);
      self.setSelectedGroupName(groupName);
      const endPoint = "history";
      const params = {
        messageReactionId,
        $skip: self.skipInMessageHistory,
        "$sort[createdAt]": -1,
      };
      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateMessageHistoryMeta(cloneDeep(historyResponse.meta));
        self.setMessageHistory(cloneDeep(historyResponse.data), groupName);
        self.setHistoryLoading(false);
      }
      self.setHistoryLoading(false);
    },
    async fetchAllHistory() {
      const endPoint = "history";
      const params = {
        $skip: self.skipAllHistory,
        "$sort[createdAt]": -1,
      };
      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateAllHistoryMeta(cloneDeep(historyResponse.meta));
        await self.findGroupNames(cloneDeep(historyResponse.data));
        await self.findUserNames(cloneDeep(historyResponse.data));
        self.setAllHistory(cloneDeep(historyResponse.data));
        self.setHistoryLoading(false);
      }
      self.setHistoryLoading(false);
    },
    async findGroupNames(data) {
      let dmIds = [],
        groupIds = [];
      data.forEach((event) => {
        if (event.channelType === "DIRECT") {
          dmIds.push(event.channelId);
        } else if (event.channelType === "GROUP") {
          groupIds.push(event.channelId);
        }
      });
      const groupResponse = await self.getGroupNameFromIds(groupIds);
      if (groupResponse && groupResponse.length) {
        self.setGroupNames(groupResponse);
      }
    },
    async findUserNames(data) {
      let userIDs = [];
      data.forEach((event) => {
        if (event.eventType === 24 || event.eventType === 25) {
          userIDs = [...userIDs, event.meta];
        }
      });
      if (userIDs.length) {
        const userResponse = await self.getUsernameFromIds(userIDs);
        if (userResponse.length) {
          self.setAssignedUsernames(userResponse);
        }
      }
    },
    setSelectedChannelType(type) {
      self.selectedChannelType = type;
    },
    setSelectedGroupName(name) {
      self.selectedGroupName = name;
    },
    setSelectedAlertId(id) {
      self.selectedAlertId = id;
    },
    setSelectedGroupId(id) {
      self.selectedGroupId = id;
    },
    setSelectedUserId(id) {
      self.selectedUserId = id;
    },
    updateMessageHistoryMeta(meta) {
      self.skipInMessageHistory = self.skipInMessageHistory + meta.limit;
      self.totalMessageHistory = meta.total;
    },
    updateResetPasswordMeta(meta) {
      self.skipInResetPassword = self.skipInResetPassword + meta.limit;
      self.totalResetPassword = meta.total;
    },
    updateUserHistoryMeta(meta) {
      self.skipUserHistory = self.skipUserHistory + meta.limit;
      self.totalUserHistory = meta.total;
    },
    updateUserOutreachHistoryMeta(meta) {
      self.skipUserOutreachHistory = self.skipUserOutreachHistory + meta.limit;
      self.totalUserOutreachHistory = meta.total;
    },
    updateGroupHistoryMeta(meta) {
      self.skipGroupHistory = self.skipGroupHistory + meta.limit;
      self.totalGroupHistory = meta.total;
    },
    updateStatusInGroupMeta(meta) {
      self.skipStatusInGroup = self.skipStatusInGroup + meta.limit;
      self.totalStatusInGroup = meta.total;
    },
    updateAllHistoryMeta(meta) {
      self.skipAllHistory = self.skipAllHistory + meta.limit;
      self.totalAllHistory = meta.total;
    },
    setMessageHistory(data, groupName) {
      const history = data.map((event) => {
        event.groupName = groupName;
        return Event.create(event);
      });
      self.messageHistory = [...self.messageHistory, ...history];
    },
    setResetPasswordHistory(data) {
      const history = data.map((event) => {
        return Event.create(event);
      });
      self.resetPasswordHistory = [...self.resetPasswordHistory, ...history];
    },
    setGroupHistory(data, groupName) {
      const history = data.map((event) => {
        event.groupName = groupName;
        return Event.create(event);
      });
      self.groupHistory = [...self.groupHistory, ...history];
    },
    setUserHistory(data) {
      const history = data.map((event) => {
        return Event.create(event);
      });
      self.userHistory = [...self.userHistory, ...history];
    },
    setUserOutreachHistory(data) {
      const history = data.map((outreachHistory) => {
        return UserOutreachHistory.create(outreachHistory);
      });
      self.userOutreachHistory = [...self.userOutreachHistory, ...history];
    },
    setAllHistory(data) {
      const history = data.map((event) => {
        if (event.channelType === "GROUP") {
          event.groupName = self.groupNames.get(event.channelId);
        }
        if (event.eventType === 23) {
          event.metaObj = JSON.parse(event.meta);
        }
        return Event.create(event);
      });
      self.allHistory = [...self.allHistory, ...history];
    },
    setGroupNames(data) {
      data.forEach((group) => {
        if (!self.groupNames.has(group.id)) {
          self.groupNames.set(group.id, group.name);
        }
      });
    },
    setAssignedUsernames(data) {
      data.forEach((user) => {
        if (!self.assignedUsernames.has(user.id)) {
          self.assignedUsernames.set(user.id, user.username);
        }
      });
    },
    getAssignedUsername(userId) {
      if (self.assignedUsernames.has(userId)) {
        return self.assignedUsernames.get(userId);
      }
    },
    setUserStatusInGroupHistory(data, groupName) {
      const history = data.map((event) => {
        event.groupName = groupName;
        return Event.create(event);
      });
      self.userStatusInGroupHistory = [
        ...self.userStatusInGroupHistory,
        ...history,
      ];
    },
    setHistoryLoading(value) {
      self.historyLoading = value;
    },
    setShowAllNotifications(value) {
      self.showAllNotifications = value;
    },
  }));

export default History;
