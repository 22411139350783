import ApiService from "../utils/ApiService";
import AuthStore from "../stores/AuthStore";
import GroupStore from "../stores/Groups";
import Message from "./Message";
import NotificationStore from "../stores/NotificationStore";
import _ from "lodash";
import { getReactionType } from "../utils/getReactionType";
import { commonTrackEvent } from "../utils/Analytics";
import { message } from "../en.json";
import { types } from "mobx-state-tree";

const SelectedGroup = types
  .model("SelectedGroup", {
    id: 0,
    name: types.maybeNull(types.string),
    channel: "",
    messages: types.array(Message),
    loading: false,
    historyLoaded: false,
    userType: types.maybeNull(types.string),
    reactionsLoaded: false,
    start: types.maybeNull(types.string),
    end: types.maybeNull(types.string),
    hasMore: true,
    status: types.maybeNull(types.number),
    isPaused: types.maybeNull(types.boolean),
    isIntersiteGroup: types.maybeNull(types.boolean),
    siteId: types.maybeNull(types.number),
    hasBottomMessages: true,
    lastSeen: types.map(types.string),
    flaggedMessageTimetoken: types.maybeNull(types.string),
    rawReactionsResponse: types.array(
      types.frozen({
        messageId: types.string,
        userReactions: types.array(
          types.frozen({
            messageId: types.string,
            userId: types.string,
            reactionId: types.string,
            reaction: types.frozen({
              reaction: types.string,
            }),
          })
        ),
      })
    ),
    shouldShift: true,
  })
  .actions((self) => ({
    addMessage(message) {
      self.messages.push(Message.create(message));
      self.setShouldShift(false);
    },
    resetSelection() {
      self.id = 0;
      self.name = "";
      self.channel = "";
      self.start = null;
      self.end = null;
      self.flaggedMessageTimetoken = "";
      self.shouldShift = true;
    },
    setFlaggedMessageTimeToken(timeToken) {
      self.flaggedMessageTimetoken = timeToken;
    },
    clearMessages() {
      self.messages = [];
      self.setShouldShift(true);
    },
    addOldMessage(message) {
      self.messages.unshift(Message.create(message));
      self.setShouldShift(true);
    },
    addOldMessages(messages) {
      self.messages.unshift(...messages.map(m => Message.create(m)));
      self.setShouldShift(true);
    },
    addNewerMessages(message) {
      self.messages.push(Message.create(message));
      self.setShouldShift(false);
    },
    setLoading(value) {
      self.loading = value;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setStart(value) {
      self.start = value;
    },
    setEnd(value) {
      self.end = value;
    },
    setName(value) {
      self.name = value;
    },
    setHasBottomMessages(value) {
      self.hasBottomMessages = value;
    },
    saveLastSeen(userId, timestamp) {
      if (!self.lastSeen.has(userId, timestamp)) {
        self.lastSeen.set(userId, timestamp);
      } else {
        const lastSeen = self.lastSeen.get(userId, timestamp);
        if (lastSeen < timestamp) {
          self.lastSeen.set(userId, timestamp);
        }
      }
    },
    updateMessage(updatedMessage) {
      const message = _.find(self.messages, {
        timetoken: updatedMessage.timetoken,
      });
      if (message) {
        message.updateReactions(updatedMessage.type, updatedMessage.userId);
      }
    },
    async fetchReactions(callback = false) {
      if (self.channel) {
        self.setReactionsLoading(true);
        const path = "message-reactions";
        let params = {
          channel: self.channel,
          type: "grouped",
        };
        if (self.start && self.end) {
          params.startTime = self.start;
          params.endTime = self.end;
        } else if (self.start) {
          params.startTime = self.start;
        } else if (self.end) {
          params.endTime = self.end;
        }
        const response = await ApiService.getRequest(path, params);
        if (response.success && response.data) {
          self.setMessagesReactionsResponse(response.data);
        } else {
          NotificationStore.setNotification("error", message.messageFetchError);
        }
        self.setReactionsLoading(false);
        if (!self.loading || !self.reactionsLoaded) {
          self.setMessagesReactions();
        }
        callback && callback();
      }
    },

    async fetchBottomMessagesReactions(start, end) {
      if (self.channel) {
        self.setReactionsLoading(true);
        const path = "message-reactions";
        let params = {
          channel: self.channel,
          type: "grouped",
          startTime: start,
          endTime: end,
        };
        const response = await ApiService.getRequest(path, params);
        if (response.success && response.data) {
          self.setMessagesReactionsResponse(response.data);
        } else {
          NotificationStore.setNotification("error", message.messageFetchError);
        }
        self.setReactionsLoading(false);
        if (!self.loading) {
          self.setMessagesReactions();
        }
      }
    },
    setMessagesReactionsResponse(data) {
      self.rawReactionsResponse = data;
    },
    setMessagesReactions() {
      self.messages.forEach((message) => {
        const reactions = _.find(self.rawReactionsResponse, {
          messageId: message.timetoken,
        });
        if (reactions) {
          reactions.userReactions.forEach((record) => {
            const reactionType = getReactionType(record.reactionId);
            message.updateReactions(reactionType, record.userId);
          });
        }
      });
      self.setMessagesReactionsResponse([]);
    },
    async changeColor(color) {
      const path = "user-groups";
      const id = `${AuthStore.userId}?groupId=${self.id}`;
      const response = await ApiService.patchRequest(path, id, { color });
      if (_.startsWith(self.channel, "GROUP_CHAT")) {
        commonTrackEvent("GROUP", "Open Colors", self.name, self.id);
      }
      return response.success;
    },
    setHistoryLoading(value) {
      self.historyLoaded = value;
      self.setLoading(value || self.reactionsLoaded);
    },
    setReactionsLoading(value) {
      self.reactionsLoaded = value;
      self.setLoading(value || self.historyLoaded);
    },
    setShouldShift(value) {
      self.shouldShift = value;
    },
  }))
  .views((self) => ({
    get groupLastSeen() {
      let lastSeen = "99999999999999999"; // largest timestamp supported by pubnub
      let allUsersFound = true;
      const groupUsers = GroupStore.groupUsers.filter(
        (groupUser) => groupUser.groupId === self.id
      );
      if (groupUsers.length > self.lastSeen.size) {
        return null;
      }
      groupUsers.forEach((groupUser) => {
        if (allUsersFound) {
          if (!self.lastSeen.has(groupUser.userId)) {
            allUsersFound = false;
          } else {
            lastSeen =
              lastSeen > self.lastSeen.get(groupUser.userId)
                ? self.lastSeen.get(groupUser.userId)
                : lastSeen;
          }
        }
      });
      return allUsersFound ? lastSeen : null;
    },
  }));

export default SelectedGroup;
