import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Input, Menu, Spin } from 'antd';

// Components
import LeftSide from './LeftSide';
import MessageBubble from './MessageBubble';
import ModeratorTag from './ModeratorTag';
import UserSelection from './UserSelection';
import { CustomButton } from '../UI/CustomButton';
import { ResetPwdModal } from '../UI/ResetPwdModal';

// Local imports
import { GROUP_NOTIFICATION_TYPE } from '../../constants/GlobalConstant';
import { ALIKE_MODERATOR, DEV, SEMI_MODERATOR } from '../../constants/UserRolesConstant';
import { chat } from '../../en.json';
import MemberListStore from '../../stores/MemberListStore';
import {
  publish,
  history,
  getPubnubInstanceByUserType,
  createModeratorPubnubInstance,
  createUserPubnubInstance,
  getOnlineUsers,
  addListener,
} from '../../utils/PubnubMethods';
import { setPubnubInstance } from '../../utils/pubnubInstance';
import historyForRoute from '../../utils/history';
import {
  IMG_UPLOAD_IMAGE,
  IMG_GIF,
  SELECT_FEED,
} from '../../utils/ImageUtils';
import { IS_ISLAND } from '../../utils/getEnvironment';
import MentionComponent from './MentionChat';
import RepliedMessageBox from "./RepliedMessageBox";
import { isValidReplyingToMessage } from "../../utils/CommonUtils";
import FeedComments from './FeedsComment';
import mixpanel from "mixpanel-browser";
import { VList } from "virtua";
import { FlagOptionsModal } from "../UI/FlagOptionsModal";


@inject("store")
@observer
class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.messageList = React.createRef();
    this.uploadRef = React.createRef();
    this.state = {
      value: "",
      showTagList: false,
      file: {},
      contentData: [],
      isGifModalVisible: false,
      search: '',
      isFetchingGifs: false,
      replyingToMessage: null,
    };
  }

  async componentDidMount() {
    const {
      store: {
        ProfileStore,
        AuthStore: { type, userId, username, getUserEnv,setGetStreamToken, email, siteId },
        MessagesStore: { activeTab, setCurrentNotificationChannel },
        FlaggedMessagesStore,
        GifStore,
        ForumFeedStore: { fetchTags }
      },
    } = this.props;
    if (userId && type) {
      setPubnubInstance({
        personal: await createUserPubnubInstance({ userId }),
        moderator: ["moderator", "SA", DEV].includes(type)
          ? await createModeratorPubnubInstance({ userId })
          : null,
      });
      const pubnub = getPubnubInstanceByUserType(type);
      await addListener(pubnub, () => this.scrollToBottom());
    }
    setTimeout(() => this.scrollToBottom(), 200);
    MemberListStore.emptyAlert();
    ProfileStore.changeSelectedAction("");
    FlaggedMessagesStore.clear();
    GifStore.fetchGiphyKeyFromLocalStorage();

    // reset current notification channel
    setCurrentNotificationChannel("");
    this.openMessages();

    // refresh creds in secure storage
    if (username) {
      getUserEnv();
      await setGetStreamToken();
    }
    /** fetch tags & categories */
    await fetchTags();

    if (activeTab === "feed") {
      mixpanel.track("Forum Open", {
        from: "WEB",
        UserID: userId,
        User: { email, username, siteId }
      });
      mixpanel.time_event("Forum Total Time Spent");
    }
  }

  componentWillUnmount() {
    const {
      store: {
        MessagesStore: { activeTab },
        ForumFeedStore: { selectedTimeFilter },
      },
    } = this.props;

    if (activeTab === "feed") {
      mixpanel.track("Forum Total Time Spent", { from: "WEB" });
      mixpanel.track(`Forum Total Time For ${selectedTimeFilter}`, { from: "WEB" });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.openMessages();
  }

  // open dm or group message listing
  openMessages = () => {
    const {
      store: {
        MessagesStore: {
          messageID,
          messageTitle,
          messageUserRole,
          messageType
        },
        MessagesStore: { setActiveTab, setCurrentNotificationChannel },
        ProfileStore: { setMemberProfileVisibility, changeSelectedAction },
      }
    } = this.props;
    if (messageID && messageTitle && messageUserRole) {
      if (GROUP_NOTIFICATION_TYPE.includes(messageType)) {
        // group notification clicked
        setActiveTab("group");
        setCurrentNotificationChannel("");
        this.changeChannel(messageID, messageTitle, messageUserRole, false, false, 1);
      } else {
        // dm notification clicked
        setActiveTab("dm");
        setCurrentNotificationChannel("");
        changeSelectedAction(""); // copied from previous logic
        setMemberProfileVisibility(false);
        this.changeChannel(messageID, messageTitle, messageUserRole, null, false, 0);
      }
    }
  }

  appendData = () => {
    const {
      store: {
        MessagesStore: { selectedGroup, getMessage },
        AuthStore: { unResolveUser, type },
      },
    } = this.props;
    if (_.startsWith(selectedGroup.channel, "WAITING_ROOM")) {
      unResolveUser(selectedGroup.id);
    }
    const message = getMessage();
    const text = message.text;
    if (text.trim() !== "") {
      const pubnub = getPubnubInstanceByUserType(type);
      message.clearText();
      text.trim() !== "" && publish(pubnub, text);
    }
  };

  handleChange = (e) => {
    const {
      store: {
        MessagesStore,
        GroupStore: { setShowTagList },
      },
    } = this.props;

    const text = e;

    if (text.lastIndexOf("@") > text.lastIndexOf(" ")) {
      setShowTagList(true);
    } else {
      setShowTagList(false);
    }
    const message = MessagesStore.getMessage();

    message.setText(text);
  };

  handleKeyPress = (e, broadcastMessage = false) => {
    const {
      store: {
        BroadcastStore: {
          startDm,
          selectedUsers,
          message,
          broadcastInProgress,
        },
      },
    } = this.props;

    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (broadcastMessage) {
        if (
          selectedUsers.size &&
          message.trim().length &&
          !broadcastInProgress
        ) {
          startDm();
        } else {
          return;
        }
      } else {
        this.appendData();
      }
    }
  };

  changeChannel = (id, name, userType, isPaused, isBot, flag, isIntersiteGroup = false) => {
    const {
      store: {
        MessagesStore: { changeChannel, resetMessageRedirectionData, setCurrentNotificationChannel, setCurrentNotificationTab, setIsFirstPage },
        GroupStore: { fetchGroupUsers },
        DMStore: { fetchDmUsers },
      },
    } = this.props;
    const type = isBot
      ? "waiting"
      : flag === 1
        ? "group"
        : flag === 2
          ? "other-dms"
          : "dm";
    changeChannel(id, name, userType, isPaused, type, isIntersiteGroup, () => this.scrollToBottom());
    flag && fetchGroupUsers(id);
    if (type === "dm") {
      fetchDmUsers(id);
    }
    resetMessageRedirectionData();

    // reset current notification state
    setCurrentNotificationChannel();

    // reset first page on channel change
    setIsFirstPage(true);

    setCurrentNotificationTab();

    this.clearReplyingToMessage();
  };

  scrollToBottom(isAfterImageLoad = false, messageTimetoken = 0) {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { channel, messages } = {},
          currentNotificationChannel,
          isFirstPage
        },
      }
    } = this.props;

    let scroll = true;
    if (currentNotificationChannel && channel) {
      if (currentNotificationChannel !== channel) {
        scroll = false;
      }
    }

    if (!isFirstPage) {
      scroll = false;
    }

    if (this.messageList.current) {
      const { scrollToIndex, scrollOffset, scrollSize, viewportSize } = this.messageList.current;

      if (isAfterImageLoad) {
        const isAtBottom = scrollOffset - scrollSize + viewportSize >= -1.5;
        if (!isAtBottom && messageTimetoken) {
          const lastMessageTimeToken = messages[messages.length - 1].timetoken;
          const isLastMessage = lastMessageTimeToken === messageTimetoken;
          if (!isLastMessage) {
            scroll = false;
          }
        }
      }

      if (scroll) {
        scrollToIndex(messages.length - 1, { align: "end" });
      }
    }
  }

  getDmNames = (users) => {
    const {
      store: {
        AuthStore: { username },
      },
    } = this.props;

    return _.split(users, ",")
      .filter((user) => user !== username)
      .map((name, index) => (
        <React.Fragment key={index}>
          {index !== 0 && ","}
          <span>{name}</span>
          <ModeratorTag username={name} />
        </React.Fragment>
      ));
  };

  imageUploadClick = () => this.uploadRef.click();

  fetchTrendingGifData = async () => {
    const { store: { GifStore: { gifsData }, GifStore } } = this.props;
    try {
      GifStore.setLoading(true);
      const response = await GifStore.getTrendingGifsService();

      const contentGifData = [...this.state.contentData, ...toJS(gifsData)];
      this.setState({
        ...this.state,
        isFetchingGifs: false,
        contentData: contentGifData
      });
      GifStore.setLoading(false);
    } catch (error) {
      GifStore.setLoading(false);
    }
  };

  fetchGifsBySearchData = async (searchValue) => {
    const { store: { GifStore: { gifsData }, GifStore } } = this.props;
    try {
      GifStore.setLoading(true);
      const response = await GifStore.getGifsBySearchService(searchValue);

      const contentGifData = [...toJS(gifsData)];
      this.setState({
        ...this.state,
        isFetchingGifs: false,
        contentData: contentGifData
      });
      GifStore.setLoading(false);
    } catch (error) {
      GifStore.setLoading(false);
    }
  };

  gifUploadClick = async () => {
    this.setState({
      ...this.state,
      isFetchingGifs: true,
      isGifModalVisible: true
    });
    this.fetchTrendingGifData();
  };

  handleGifSearch = async (event) => {
    const searchValue = event.target.value;
    this.setState({
      ...this.state,
      isFetchingGifs: true,
      search: searchValue,
    })
    if (searchValue) {
      this.fetchGifsBySearchData(searchValue);
    } else {
      this.fetchTrendingGifData();
    }
  };

  getUrlExtension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  convertToFileObjectAndUploadToChat = async (imgUrl) => {
    const {
      store: {
        GifStore,
        MessagesStore: { uploadImageInChat },
      },
    } = this.props;
    const imgExt = this.getUrlExtension(imgUrl);
    try {
      GifStore.setLoading(true);
      const response = await fetch(imgUrl);
      const blob = await response.blob();
      const file = new File([blob], `trending-${Date.now()}.${imgExt}`, {
        type: blob.type,
      });
      await uploadImageInChat(file, 'gif', this.state.replyingToMessage);
      this.setState({
        ...this.state,
        search: ''
      });
      GifStore.setLoading(false);
      this.clearReplyingToMessage();
    } catch (error) {
      GifStore.setLoading(false);
    }
  };

  handleGifUpload = (originalUrl) => {
    this.closeModal();
    this.convertToFileObjectAndUploadToChat(originalUrl);
  };

  handleLoadMore = () => {
    const { store: { GifStore: { pagination, loading }, GifStore } } = this.props;
    const searchValue = this.state.search;
    GifStore.setPaginationData({
      ...pagination,
      current: pagination.current + 1
    });
    this.setState({
      ...this.state,
      isFetchingGifs: true
    })
    if (searchValue) {
      this.fetchGifsBySearchData(searchValue);
    } else {
      this.fetchTrendingGifData();
    }
  };

  closeModal = () => {
    const { store: { GifStore } } = this.props;
    GifStore.resetPagination();
    this.setState({
      ...this.state,
      search: '',
      isGifModalVisible: false
    });
  };

  handleConfirmClick = () => {
    this.closeModal();
  };

  handleCancelClick = () => {
    this.closeModal();
  };

  loadMore = async () => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { loading, reactionsLoaded },
        },
        AuthStore: { type },
      },
    } = this.props;
    if (!loading && !reactionsLoaded) {
      let prevScrollHeight;
      const pubnub = getPubnubInstanceByUserType(type);
      if (this.messageList.current) {
        prevScrollHeight = this.messageList.current.scrollHeight;
    }
      prevScrollHeight
        ? history(pubnub, this.scrollToTop, prevScrollHeight)
        : history(pubnub, null, prevScrollHeight);
    }
  };

  scrollToTop = (prevScrollHeight) => {
    this.messageList.current.scrollTop = this.messageList.current.scrollHeight - prevScrollHeight;
  };

  checkMessageType = (message) => {
    return (
      message.entry.type === "text" ||
      message.entry.type === "img" ||
      message.entry.type === "gif"
    );
  };

  resolveUserDM = () => {
    const {
      store: {
        AuthStore: {
          isResolvedDMLoading,
          resolveDMForSpecificUser
        },
        MessagesStore: {
          selectedGroup: { id },
        }
      }
    } = this.props;
    if (isResolvedDMLoading) return;

    resolveDMForSpecificUser({ dmChannelId: id });
  };

  startSession = async () => {
    const {
      store: {
        MessagesStore: {
          setSession,
          selectedGroup: { id },
        },
        AuthStore: { type },
        GroupListStore: {
          sessionLoading,
          setGroupOnlineUsers,
          fetchGroupSession,
        },
      },
    } = this.props;
    if (sessionLoading) {
      return;
    }
    if (!id) {
      return;
    }
    const pubnub = getPubnubInstanceByUserType(type);
    await fetchGroupSession(id);
    const onlineUsers = await getOnlineUsers(pubnub, `GROUP_CHAT_${id}`);
    setGroupOnlineUsers(onlineUsers, id);
    setSession(true);
  };

  uploadImage = async (e) => {
    const {
      store: {
        MessagesStore: { uploadImageInChat },
      },
    } = this.props;
    const fileName = e.target.value.toLowerCase();
    if (
      !(
        fileName.endsWith(".jpg") ||
        fileName.endsWith(".jpeg") ||
        fileName.endsWith(".gif") ||
        fileName.endsWith(".png")
      )
    ) {
      alert("Please upload file types of .jpg, .jpeg, .png and .gif only.");
      return;
    }
    if (e.target.getAttribute("data-field") === "groupImage") {
      const files = e.target.files;
      if (!files.length) {
        return alert("Please choose a file to upload first.");
      } else {
        const file = files[0];
        await uploadImageInChat(file, 'img', this.state.replyingToMessage);
        this.clearReplyingToMessage();
        this.uploadRef.value = "";
      }
    }
  };

  onOkResetPwdModal = async () => {
    const {
      store: {
        AuthStore: { closeResetPwdModal },

      },
    } = this.props;
    await closeResetPwdModal();
    historyForRoute.push("forgot-password");
  };

  addUserMentionToChat  = (value) => {
    const {
      store: {
        GroupStore: { setMentionedUserName },
      },
    } = this.props;

    setMentionedUserName(value);
  }

  clearMentionedUserName  = () => {
    const {
      store: {
        GroupStore: { setMentionedUserName },
      },
    } = this.props;

    setMentionedUserName('');
  }

  shouldReplyToMessage = () => {
    return isValidReplyingToMessage(this.state.replyingToMessage);
  }

  onReply = (replyingToMessage) => {
    this.setState({
      ...this.state,
      replyingToMessage
    })
  }

  clearReplyingToMessage = () => {
    this.setState({
      ...this.state,
      replyingToMessage: null
    })
  }

  onSubmitFlagReaction = async (data) => {
    const { message, reactionId, typeOfNeed, description } = data;
    const {
      store: {
        MessagesStore: { closeFlagOptionPopup },

      },
    } = this.props;

    if (message) {
      message.saveReaction(reactionId, typeOfNeed, description);
    }

    await closeFlagOptionPopup();
  }

  onCloseFlagReactionModal = () => {
    const {
      store: {
        MessagesStore: { closeFlagOptionPopup },
      },
    } = this.props;

    closeFlagOptionPopup();
  }

  render() {
    const { 
      store: {
        BroadcastStore: {
          selectedUsers,
          message,
          setMessage,
          startDm,
          broadcastInProgress,
        },
        ProfileStore: { selectedAction },
        AuthStore: { type, isResolvedDMLoading, showResetPwdPopup, closeResetPwdModal, isUserDMResolved },
        GroupStore: { selectedGroupUsers, mentionedUserName },
        MessagesStore: {
          selectedGroup: {
            id,
            messages,
            loading,
            hasMore,
            name,
            channel,
            groupLastSeen,
            isPaused,
            shouldShift,
          },
          isBroadcasting,
          text,
          sessionStarted,
          activeTab,
          isWaiting,
          imageUploading,
          gifUploading,
          isShowFlagOptionModal,
          flagReactionId,
          messageObj,
        },
        ForumFeedStore: {
          isFeedCommentVisible
        },
      },
      navHeight,
    } = this.props;

    if (!shouldShift) {
      this.scrollToBottom();
    }

    const { isGifModalVisible } = this.state;
    const { TextArea } = Input;
    const isDMTabActive = activeTab === "dm";

    const renderGifData = () => {
      return (
        <div className="gif-data-wrapper">
          <input className="gif-search-bar" placeholder="Search GIF..." value={this.state.search} onChange={this.handleGifSearch} />
          {this.state.contentData.length ?
            (<>
              <div className="gif-img-wrapper">
                {this.state.contentData.map((obj, index) => (
                  <div className="gif-img-inner" key={index}>
                    <em>
                      <img src={obj.images.preview_gif.url} value={obj.images.original.url} onClick={() => this.handleGifUpload(obj.images.original.url)} />
                    </em>
                  </div>
                ))}
              </div>
              {this.state.isFetchingGifs ? <Spin /> : <button className="load-more-btn" onClick={this.handleLoadMore}>Load More...</button>}
            </>) : null}
        </div>
      )
    };

    const setUploadRef = (ref) => (this.uploadRef = ref)

    // Here we have a options of image upload & gif upload in dropdown under "+" icon
    const optionsList = (
      <div>
        <Menu
          className="menu"
          style={{
            border: '1px solid #FF4D00',
            borderRadius: 5
          }}>
          <Menu.Item key={1} onClick={this.imageUploadClick}>
            {imageUploading ? <Spin /> :
              <img
                src={IMG_UPLOAD_IMAGE}
                className="image-upload-img"
                alt="Send"
                onClick={this.imageUploadClick}
              />
            }
            Upload Image
          </Menu.Item>

          {IS_ISLAND && <Menu.Item key={2} onClick={this.gifUploadClick} >
            {gifUploading ? <Spin /> : <img
              src={IMG_GIF}
              className="gif-upload-img"
              alt="Send GIF"
              onClick={this.gifUploadClick}
            />}
            Upload GIF
          </Menu.Item>}
        </Menu>
      </div>
    );

    let prevDate = "";
    const userType = type;
    const isSemiModerator = userType === SEMI_MODERATOR;
    const isDisabled =
      broadcastInProgress || !selectedUsers.size || !message.trim().length;
    return (
      <div className="display-flex">
        <div className={name ? "left-sidebar hide-content" : "left-sidebar "} style={{
          height: `calc(100vh - ${navHeight}px)`,
        }}>
          <LeftSide changeChannel={this.changeChannel} />
        </div>
        {
          (activeTab === 'feed') && (
            isFeedCommentVisible 
              ? (<FeedComments isDeleteOnly={false} />)
              : (
                <div className="select-group-message">
                  <img id="select-feed-img" src={SELECT_FEED} alt={chat.selectFeed}/>
                  <h3>{chat.selectFeed}</h3>
                </div>
              )
          )
        }
        {
          (activeTab !== 'feed') && (
            <>
              {isBroadcasting && (
                <div className="left-sidebar2">
                  <UserSelection />
                </div>
              )}
              {name &&
                !isBroadcasting &&
                selectedAction !== "Dm creation" &&
                !isWaiting ? (
                <div className="right-section">
                  <div className="header-section p-1 over-sized">
                    <h1>
                      <span>
                        {_.startsWith(channel, "DIRECT_MESSAGE")
                          ? this.getDmNames(name)
                          : name}
                      </span>
                    </h1>
                    {
                      isDMTabActive && _.startsWith(channel, 'DIRECT_MESSAGE') && ALIKE_MODERATOR.includes(userType) ?
                        (isResolvedDMLoading ? (<div className="resolve-dm my-btn"><Spin /></div>)
                          : (<CustomButton
                            className="resolve-dm my-btn"
                            caption={isUserDMResolved ? "Unresolve DM" : "Resolve DM"}
                            disabled={isResolvedDMLoading}
                            onClick={() => this.resolveUserDM()}
                          />)) : null
                    }
                    {_.startsWith(channel, "GROUP_CHAT") &&
                      (ALIKE_MODERATOR.includes(userType) || isSemiModerator) ? (
                      <CustomButton
                        className="start-session my-btn"
                        caption={chat.startASession}
                        disabled={sessionStarted}
                        onClick={() => this.startSession()}
                      />
                    ) : null}
                  </div>
                  <div>
                    <div className="p-1" style={{ flex: "1", position: "relative" }}>
                      <div>
                        {isDMTabActive && isUserDMResolved ? null : isPaused ? null : (
                          <div className="input-container">
                            {this.shouldReplyToMessage() && <RepliedMessageBox
                              message={this.state.replyingToMessage.entry}
                              groupChat={_.startsWith(channel, "GROUP_CHAT")}
                              groupId={
                                _.startsWith(channel, "GROUP_CHAT")
                                  ? id
                                  : null
                              }
                              scrollToBottom={() => this.scrollToBottom()}
                              clearReplyingToMessage={this.clearReplyingToMessage}
                            />}

                            <MentionComponent
                              activeTab={activeTab}
                              mentionedUserName={mentionedUserName}
                              clearMentionedUserName={this.clearMentionedUserName}
                              replyingToMessage={this.state.replyingToMessage}
                              clearReplyingToMessage={this.clearReplyingToMessage}
                              isGifModalVisible={isGifModalVisible}
                              closeModal={this.closeModal}
                              renderGifData={renderGifData}
                              setUploadRef={setUploadRef}
                              uploadImage={this.uploadImage}
                              optionsList={optionsList}
                            />
                          </div>
                        )}

                        {loading && (
                          <div className="form-loading">
                            <Spin />
                          </div>
                        )}
                        <div id="display-data-Container" style={{
                          overflowY: "unset"
                        }}>
                          {!loading && !messages.length ? (
                            <div className="no-messages">{chat.noMessages}</div>
                          ) : null}
                          <VList
                            ref={this.messageList}
                            key={id}
                            reverse={true}
                            shift={shouldShift}
                            onRangeChange={async (startIndex) => {
                              if (startIndex === 0) {
                                this.loadMore();
                              }
                            }}
                          >
                              {_.uniqBy(messages, "timetoken").map((message, index) => {
                                const currDate = moment
                                  .unix(parseInt(message.timetoken) / 10000000)
                                  .format("LL");
                                const messageBox = (
                                  <React.Fragment key={index}>
                                    {currDate !== prevDate &&
                                      this.checkMessageType(message) && (
                                        <div className="message-date">
                                          <span>{currDate}</span>
                                        </div>
                                      )}
                                    {message.isDeleted &&
                                      (userType === "user" ||
                                        userType == "semi-moderator") ? null : (
                                      <MessageBubble
                                        addUserMentionToChat={this.addUserMentionToChat}
                                        onReply={this.onReply}
                                        message={message}
                                        groupChat={_.startsWith(channel, "GROUP_CHAT")}
                                        groupId={
                                          _.startsWith(channel, "GROUP_CHAT")
                                            ? id
                                            : null
                                        }
                                        groupLastSeen={groupLastSeen}
                                        scrollToBottom={(...args) => this.scrollToBottom(...args)}
                                      />
                                    )}
                                  </React.Fragment>
                                );

                                prevDate = currDate;
                                return messageBox;
                              })}
                          </VList>
                          {isShowFlagOptionModal &&
                            <FlagOptionsModal
                              onOk={this.onSubmitFlagReaction}
                              visible={isShowFlagOptionModal}
                              flagReactionId={flagReactionId}
                              messageObj={messageObj}
                              onClose={this.onCloseFlagReactionModal}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : isBroadcasting ? (
                <div className="boardcast-chat-section">
                  <h3 className="text-center">{chat.message}</h3>
                  <div className="broadcast-container">
                    <TextArea
                      value={message}
                      onChange={setMessage}
                      onKeyDown={(e) => this.handleKeyPress(e, true)}
                      placeholder="Your message, here."
                    />
                  </div>
                  <Button
                    className={
                      isDisabled
                        ? "custom-btn-disabled float-right"
                        : "custom-btn float-right"
                    }
                    onClick={startDm}
                    disabled={isDisabled}
                  >
                    {chat.send}
                  </Button>
                </div>
              ) : (
                <>
                  <div className="select-group-message">
                    <h3>{chat.selectGroup}</h3>
                  </div>
                  {showResetPwdPopup &&
                    <div className="left-sidebar2">
                      <ResetPwdModal
                        onOk={this.onOkResetPwdModal}
                        visible={showResetPwdPopup}
                        onClose={closeResetPwdModal}
                      />
                    </div>
                  }
                </>
              )}
            </>
          )
        }
      </div>
    );
  }
};

export default Chat;
