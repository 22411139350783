import _ from "lodash";
import { types } from "mobx-state-tree";
import moment from "moment";

import { DEFAULT_PAGE_SIZE, TASK_DATE_FORMAT, TASK_STATUS, TASK_TYPE_FLAGS } from "../constants/GlobalConstant";
import AuthStore from "../stores/AuthStore";
import MemberListStore from "../stores/MemberListStore";
import DeletedMessagesStore from "../stores/DeletedMessagesStore";
import ProfileStore from "../stores/ProfileStore";
import ApiService from "../utils/ApiService";
import { IS_HP } from "../utils/getEnvironment";

const Task = types
  .model("Profile", {
    tasksTabKey: 1,
    description: "",
    status: "",
    addTaskLoading: false,
    fetchTaskLoading: false,
    completeTaskLoading: false,
    updateTaskLoading: false,
    fetchAllInProgressTasksLoading: false,
    fetchAllCompletedTasksLoading: false,
    totalInProgressTasks: 0,
    totalCompletedTasks: 0,
    inProgressTasksPage: 1,
    completedTasksPage: 1,
    tasks: types.optional(types.frozen([])),
    allInProgressTasks: types.optional(types.frozen([])),
    allCompletedTasks: types.optional(types.frozen([])),
  })
  .actions((self) => ({
    reset() {
      self.tasksTabKey = 1;
      self.description = "";
      self.status = "";
      self.addTaskLoading = false;
      self.fetchTaskLoading = false;
      self.completeTaskLoading = false;
      self.updateTaskLoading = false;
      self.fetchAllInProgressTasksLoading = false;
      self.fetchAllCompletedTasksLoading = false;
      self.totalInProgressTasks = 0;
      self.totalCompletedTasks = 0;
      self.inProgressTasksPage = 1;
      self.completedTasksPage = 1;
      self.tasks = [];
      self.allInProgressTasks = [];
      self.allCompletedTasks = [];
    },
    setTasksTabKey(key) {
      self.reset();
      self.tasksTabKey = key;
      if (key === 1) {
        // fetch all tasks| in-progress tasks
        self.fetchAllTasks({
          status: TASK_STATUS.IN_PROGRESS,
          page: self.inProgressTasksPage
        });
        // Fetch completed tasks
        self.fetchAllTasks({
          status: TASK_STATUS.COMPLETED,
          page: self.completedTasksPage
        });
      } else if (key === 2) {
        // fetch my tasks | in-progress tasks
        self.fetchAllTasks({
          userId: AuthStore.userId,
          status: TASK_STATUS.IN_PROGRESS,
          page: self.inProgressTasksPage
        });
        // Fetch completed tasks
        self.fetchAllTasks({
          userId: AuthStore.userId,
          status: TASK_STATUS.COMPLETED,
          page: self.completedTasksPage
        });
      } else if (key === 3) {
        // fetch tasks on my caseloads | in-progress tasks
        self.fetchMyCaseloadsTasks({
          status: TASK_STATUS.IN_PROGRESS,
          page: self.inProgressTasksPage
        });
        // Fetch completed tasks
        self.fetchMyCaseloadsTasks({
          status: TASK_STATUS.COMPLETED,
          page: self.completedTasksPage
        });
      } else {
        // fetch overdue tasks
        self.fetchAllTasks({
          status: TASK_STATUS.IN_PROGRESS,
          type: TASK_TYPE_FLAGS.OVERDUE,
          page: self.inProgressTasksPage
        });

      }
    },
    async addTask(dataToSave) {
      const { description, assignedToUserId, userId, dueAt } = dataToSave
      try {
        self.setAddTaskLoading(true);
        const objToInsert = {
          description,
          assignedToUserId,
          userId,
          dueAt
        };
        const response = await ApiService.postRequest("task", objToInsert)

        if (response.success) {
          let dataToStore = response.data.data
          dataToStore['createdByUserDetails'] = {
            id: AuthStore.userId,
            fullName: AuthStore.fullName
          }
          if (assignedToUserId) {
            let admins = MemberListStore.adminUsers
            let assinedAdmin = admins.filter((admin) => admin.id == assignedToUserId)
            dataToStore['assignedToUserDetails'] = {
              id: assinedAdmin[0].id,
              fullName: assinedAdmin[0].fullName
            }
          }
          const sortArr = self.dateWiseSort([dataToStore, ...self.tasks])
          self.setTasks([...sortArr])
          self.setAddTaskLoading(false);
        }
      } catch (error) {
        self.setAddTaskLoading(false);
      }
    },
    async fetchTask(userId) {
      try {
        self.setFetchTaskLoading(true);
        const path = `task/${userId}`;
        let response = await ApiService.getRequest(path)
        self.setFetchTaskLoading(false);
        if (response.success) {
          const sortArr = self.dateWiseSort(response.data)
          self.setTasks([...sortArr])
        }
      } catch (error) {
        self.setFetchTaskLoading(false);
      }
    },
    async updateTask({ taskId, status, description, dueAt, assignedToUserId }) {
      try {
        const objToUpdate = {}
        if (status) {
          objToUpdate['status'] = status
        }
        if (description) {
          objToUpdate['description'] = description
        }
        // accept dueAt if value is date or null, skip if undefined
        if (dueAt !== undefined) {
          objToUpdate['dueAt'] = dueAt
        }
        if (assignedToUserId) {
          objToUpdate['assignedToUserId'] = assignedToUserId
        }
        status === TASK_STATUS.COMPLETED ? self.setCompleteTaskLoading(true) : self.setUpdateTaskLoading(true);
        let response = await ApiService.patchRequest('task', taskId, objToUpdate)
        if (response.success) {
          // update store object

          // tasks which not to be updated
          const filteredCloneTasksArr = self.tasks.filter(t => t.id !== taskId)

          // find the index of task to be updated
          var foundIndex = self.tasks.findIndex(t => t.id == taskId);
          const taskToBeUpdate = self.tasks[foundIndex]

          if (status === TASK_STATUS.COMPLETED) {
            // add completedByUserDetails to task object
            taskToBeUpdate['completedByUserDetails'] = {
              id: AuthStore.userId,
              fullName: AuthStore.fullName
            }
            taskToBeUpdate['status'] = TASK_STATUS.COMPLETED
            taskToBeUpdate['completedAt'] = moment().toISOString()
          } else {
            // add update description, dueAt, or assignedToUserId to task object
            if (description) {
              // add descrition to task object
              taskToBeUpdate['description'] = description
            }
            // accept dueAt if value is date or null, skip if undefined
            if (dueAt !== undefined) {
              // add dueAt to task object
              taskToBeUpdate['dueAt'] = dueAt ? moment(dueAt).toISOString() : null
            }
            if (assignedToUserId) {
              // add assignedToUserDetails to task object
              let admins = MemberListStore.adminUsers
              let assinedAdmin = admins.filter((admin) => admin.id == assignedToUserId)
              taskToBeUpdate['assignedToUserDetails'] = {
                id: assinedAdmin[0].id,
                fullName: assinedAdmin[0].fullName
              }
            }
          }
          const sortArr = self.dateWiseSort([taskToBeUpdate, ...filteredCloneTasksArr])
          self.setTasks([...sortArr])
        }
        status === TASK_STATUS.COMPLETED ? self.setCompleteTaskLoading(false) : self.setUpdateTaskLoading(false);
      } catch (error) {
        status === TASK_STATUS.COMPLETED ? self.setCompleteTaskLoading(false) : self.setUpdateTaskLoading(false);
      }
    },
    async fetchAllTasks({ userId, status, page = 1, type }) {
      try {
        const limit = DEFAULT_PAGE_SIZE;
        status === TASK_STATUS.IN_PROGRESS ? self.setFetchAllInProgressTasksLoading(true) : self.setFetchAllCompletedTasksLoading(true);
        const skip = (page - 1) * limit; // default page number is 1
        let path = `all-tasks`;
        if (userId) {
          path += `/${userId}`;
        }
        path += `?limit=${limit}&skip=${skip}&status=${status}`;
        if (type === TASK_TYPE_FLAGS.OVERDUE) {
          path += `&type=${TASK_TYPE_FLAGS.OVERDUE}`;
        }
        let response = await ApiService.getRequest(path);
        status === TASK_STATUS.IN_PROGRESS ? self.setFetchAllInProgressTasksLoading(false) : self.setFetchAllCompletedTasksLoading(false);
        if (response.success) {
          if (status === TASK_STATUS.IN_PROGRESS) {
            self.setAllInProgressTasks(response.data);
            self.setTotalInProgressTasks(response.meta.total);
          } else {
            self.setAllCompletedTasks(response.data);
            self.setTotalCompletedTasks(response.meta.total);
          }
        }
      } catch (error) {
        status === TASK_STATUS.IN_PROGRESS ? self.setFetchAllInProgressTasksLoading(false) : self.setFetchAllCompletedTasksLoading(false);
      }
    },
    async fetchMyCaseloadsTasks({ status, page = 1 }) {
      try {
        const limit = DEFAULT_PAGE_SIZE;
        status === TASK_STATUS.IN_PROGRESS ? self.setFetchAllInProgressTasksLoading(true) : self.setFetchAllCompletedTasksLoading(true);
        const skip = (page - 1) * limit; // default page number is 1
        const userId = AuthStore.userId;
        const path = `my-caseloads-tasks/${userId}?limit=${limit}&skip=${skip}&status=${status}`;
        let response = await ApiService.getRequest(path);
        status === TASK_STATUS.IN_PROGRESS ? self.setFetchAllInProgressTasksLoading(false) : self.setFetchAllCompletedTasksLoading(false);
        if (response.success) {
          if (status === TASK_STATUS.IN_PROGRESS) {
            self.setAllInProgressTasks(response.data);
            self.setTotalInProgressTasks(response.meta.total);
          } else {
            self.setAllCompletedTasks(response.data);
            self.setTotalCompletedTasks(response.meta.total);
          }
        }
      } catch (error) {
        status === TASK_STATUS.IN_PROGRESS ? self.setFetchAllInProgressTasksLoading(false) : self.setFetchAllCompletedTasksLoading(false);
      }
    },
    async updateMyTask({ taskId, status, description, dueAt, assignedToUserId, isAssignedPeerChanged }) {
      try {
        const objToUpdate = {}
        if (status) {
          objToUpdate['status'] = status
        }
        if (description) {
          objToUpdate['description'] = description
        }
        // accept dueAt if value is date or null, skip if undefined
        if (dueAt !== undefined) {
          objToUpdate['dueAt'] = dueAt
        }
        if (assignedToUserId) {
          objToUpdate['assignedToUserId'] = assignedToUserId
        }
        // status === TASK_STATUS.COMPLETED ? self.setCompleteTaskLoading(true) : self.setUpdateTaskLoading(true);
        let response = await ApiService.patchRequest('task', taskId, objToUpdate)
        if (response.success) {
          // update store object

          // tasks which not to be updated
          let tasksArr = self.allInProgressTasks
          const filteredCloneTasksArr = tasksArr.filter(t => t.id !== taskId)

          // find the index of task to be updated
          var foundIndex = tasksArr.findIndex(t => t.id == taskId);
          const taskToBeUpdate = tasksArr[foundIndex]

          if (status === TASK_STATUS.COMPLETED) {
            // add completedByUserDetails to task object
            taskToBeUpdate['completedByUserDetails'] = {
              id: AuthStore.userId,
              fullName: AuthStore.fullName
            }
            taskToBeUpdate['status'] = TASK_STATUS.COMPLETED
            taskToBeUpdate['completedAt'] = moment().toISOString()
          } else {
            // add update description, dueAt, or assignedToUserId to task object
            if (description) {
              // add descrition to task object
              taskToBeUpdate['description'] = description
            }
            // accept dueAt if value is date or null, skip if undefined
            if (dueAt !== undefined) {
              // add dueAt to task object
              taskToBeUpdate['dueAt'] = dueAt ? moment(dueAt).toISOString() : null
            }
            if (assignedToUserId) {
              // add assignedToUserDetails to task object
              let admins = MemberListStore.adminUsers
              let assinedAdmin = admins.filter((admin) => admin.id == assignedToUserId)
              taskToBeUpdate['assignedToUserDetails'] = {
                id: assinedAdmin[0].id,
                fullName: assinedAdmin[0].fullName
              }
            }
          }
          if (status === TASK_STATUS.COMPLETED) {
            self.setAllInProgressTasks([...filteredCloneTasksArr])
            self.setAllCompletedTasks([taskToBeUpdate, ...self.allCompletedTasks])
          } else {
            let sortArr = [taskToBeUpdate, ...filteredCloneTasksArr];
            if (self.tasksTabKey === 4) {
              // remove object if date is no due anymore in overdue tab
              const dueDate = taskToBeUpdate.dueAt ? moment(taskToBeUpdate.dueAt).format('YYYY-MM-DD') : null;
              const curDate = moment().format('YYYY-MM-DD');
              if (!dueDate || (curDate < dueDate)) {
                sortArr = [...filteredCloneTasksArr];
              }
            }
            if (self.tasksTabKey === 2) {
              // remove updated object on peer change
              if (isAssignedPeerChanged) {
                sortArr = [...filteredCloneTasksArr];
              }
            }
            sortArr = self.dateWiseSort(sortArr)
            self.setAllInProgressTasks([...sortArr])
          }
        }
      } catch (error) {
      }
    },
    async openUserProfile(userID) {
      try {
        const {
          setMemberProfileVisibility,
          setMemberData,
          setAssignedPeerDetails,
          toggleLabel,
          reset,
          userId,
          getHighPointUsers,
          changeSelectedAction,
          getProviderModeratedGroups,
          fetchGroupsFromForms,
          getContactModeratedGroups,
        } = ProfileStore

        const { setShowDeleted } = DeletedMessagesStore;
        const { getMemberDetails, fetchAdminUsers } = MemberListStore;
        if (userID === userId) {
          return;
        }
        reset();
        setShowDeleted(false);
        setMemberProfileVisibility(true);
        setAssignedPeerDetails({});
        fetchAdminUsers();
        const member = await getMemberDetails(userID);
        if (member) {
          const { providerId, recommendedBy } = member;
          setMemberData(member);
          if (IS_HP) {
            getHighPointUsers();
            if (providerId) {
              getProviderModeratedGroups(providerId);
            }
            if (recommendedBy) {
              getContactModeratedGroups(recommendedBy);
            }
            fetchGroupsFromForms();
          }
        }
        toggleLabel(false);
        changeSelectedAction("");
      } catch (error) {
      }
    },
    setAddTaskLoading(value) {
      self.addTaskLoading = value;
    },
    setFetchTaskLoading(value) {
      self.fetchTaskLoading = value;
    },
    setFetchAllInProgressTasksLoading(value) {
      self.fetchAllInProgressTasksLoading = value;
    },
    setFetchAllCompletedTasksLoading(value) {
      self.fetchAllCompletedTasksLoading = value;
    },
    setCompleteTaskLoading(value) {
      self.completeTaskLoading = value;
    },
    setUpdateTaskLoading(value) {
      self.updateTaskLoading = value;
    },
    setTasks(value) {
      self.tasks = value;
    },
    setAllInProgressTasks(value) {
      self.allInProgressTasks = value;
    },
    setAllCompletedTasks(value) {
      self.allCompletedTasks = value;
    },
    setTotalInProgressTasks(value) {
      self.totalInProgressTasks = value;
    },
    setTotalCompletedTasks(value) {
      self.totalCompletedTasks = value;
    },
    setInProgressTasksPage(value) {
      self.inProgressTasksPage = value;
    },
    setCompletedTasksPage(value) {
      self.completedTasksPage = value;
    },
  }))
  .views((self) => ({
    dateWiseSort(arr) {
      return arr.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
  }));

export default Task;
