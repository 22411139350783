export const getIntakeStatus = (statusId) => {
  switch (statusId) {
    case 100:
      return `Incomplete`;
    case 101:
      return `New Referral`;
    case 102:
      return "Form Reviewed";
    case 103:
      return "Submitted, Pending Auth";
    case 104:
      return "Archive - Unable to contact";
    case 105:
      return "Archive - Pt denied";
    case 106:
      return "Archive - Other";
    case 107:
      return "Assigned";
    case 108:
      return "Approved";
    default:
      return "";
  }
};
