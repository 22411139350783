import "../../styles/global.scss";

import { inject, observer } from "mobx-react";

import ModeratorTag from "./ModeratorTag";
import React from "react";
import { Table, Spin } from "antd";
import _ from "lodash";
import moment from "moment";
import { toJS } from "mobx";
import { withRouter } from "react-router-dom";

class Member extends React.Component {
  async componentDidMount() {
    const {
      store: {
        GroupListStore,
        AuthStore: { type },
        MemberListStore: { emptyAlert },
        SiteStore: { selectedSiteId },
      },
    } = this.props;
    const userType = type;
    let siteId = selectedSiteId;
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
    emptyAlert();
    GroupListStore.fetchGroups();
    GroupListStore.setDetailsVisibility(false);
    GroupListStore.resetSelectedGroup();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (window.scrollY > 100) {
      document.body.classList.add("showChildDiv");
    } else {
      document.body.classList.remove("showChildDiv");
    }
  };
  handleTableChange = (pagination, filters, sorter) => {
    const {
      store: {
        GroupListStore: { setSortParameters, setPaginationData, fetchGroups },
      },
    } = this.props;
    setSortParameters(sorter.columnKey, sorter.order);
    setPaginationData(pagination);
    fetchGroups();
  };

  newGroup = (value) => {
    const {
      store: {
        GroupListStore: { setNewGroup },
      },
    } = this.props;
    setNewGroup(value);
  };

  render() {
    const {
      store: {
        GroupListStore,
        GroupListStore: {
          alertLoading,
          loading,
          groups,
          showFlags,
          pagination,
          getSortOrder,
          selectedGroup,
        },
        DeletedMessagesStore: { setShowGroupDeleted, setShowGroupUnDeleted },
      },
    } = this.props;

    const columns = [
      {
        title: <div className="group-head">Name</div>,
        dataIndex: "name",
        render: (text) => <div className="link-text table-col">{text}</div>,
      },
      {
        title: <div className="group-head">Admins</div>,
        render: (text, row) => {
          return _.map(row.moderators, (moderator) => (
            <div key={moderator.username}>
              <div className="link-text table-col">
                {moderator.username}
                <ModeratorTag badgeType={moderator.type} />
              </div>
            </div>
          ));
        },
        sorter: false,
      },
      {
        title: <div className="group-head">Users</div>,
        dataIndex: "memberCount",
        sorter: false,
      },
      // {
      //   title: "Messages",
      //   dataIndex: "messages",
      //   //   onFilter: (value, record) => record.address.indexOf(value) === 0,
      //   sorter: false,
      //   sortDirections: ["descend", "ascend"]
      // },
      {
        title: <div className="group-head">Alerts (all time)</div>,
        dataIndex: "alert",
        render: (text, record) =>
          alertLoading ? (
            <Spin />
          ) : (
            <div> {record.alertCount ? record.alertCount : "0"}</div>
          ),
      },
      {
        title: <div className="group-head">Created At</div>,
        dataIndex: "createdAt",
        render: (createdAt) =>
          moment(createdAt).format("MMM Do YYYY, h:mm:ss a"),
      },
    ];

    const rowEvents = (record, rowIndex) => {
      return {
        onClick: () => {
          if (selectedGroup && selectedGroup.id === record.id) {
            return;
          }
          GroupListStore.resetSelectedGroup();
          GroupListStore.editGroupDetails(false);
          GroupListStore.setDetailsVisibility(true);
          GroupListStore.setSelectedGroup(record);
          showFlags(false);
          setShowGroupDeleted(false);
          setShowGroupUnDeleted(false);
        },
      };
    };

    return (
      <div className="member-table">
        <div className="search-member-container">
          <h4>Groups</h4>
          <button
            className="referral-form-button"
            onClick={() => this.newGroup(true)}
          >
            Create New Group
          </button>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={toJS(groups)}
          onChange={this.handleTableChange}
          pagination={pagination}
          loading={loading}
          onRow={rowEvents}
        />
      </div>
    );
  }
}

export default withRouter(inject("store")(observer(Member)));
