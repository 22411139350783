import { inject, observer } from "mobx-react";

import { Link } from "react-router-dom";
import React from "react";
import { loginForm } from "../../en.json";
import { NoChromeModal } from "../UI/NoChrome";
import { isChrome } from "../../utils/detectBrowser";

@inject("store")
@observer
class LoginForm extends React.Component {
  componentDidMount() {
    const {
      store: {
        AuthStore: { setShowChromeModal },
      },
    } = this.props;
    setShowChromeModal(true);
  }
  render() {
    const {
      store: {
        AuthStore: {
          setUsernameInput,
          setPasswordInput,
          isValid,
          login,
          loading,
          showNoChromeModal,
          setShowChromeModal,
        },
      },
    } = this.props;

    return (
      <React.Fragment>
        {!isChrome && showNoChromeModal ? (
          <NoChromeModal onConfirm={() => setShowChromeModal(false)} />
        ) : null}
        <h3 className="pt-2">{loginForm.moderatorLogin}</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Your username"
              onChange={setUsernameInput}
              id="username"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="Password"
              onChange={setPasswordInput}
            />
          </div>
          <div>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              id="btLogin"
              disabled={!isValid || loading}
              style={{ position: "relative" }}
            >
              {loading && (
                <div
                  className="spinner-grow"
                  style={{
                    width: "16px",
                    height: "16px",
                    position: "absolute",
                    left: "35%",
                    top: "8px",
                  }}
                  role="status"
                >
                  <span className="sr-only">{loginForm.loading}.</span>
                </div>
              )}
              {loginForm.login}
            </button>
          </div>
          <Link to="/forgot-password">
            <span>{loginForm.forgotPssword}</span>
          </Link>
          <Link to="/sign-up" className="sign-up">
            <span>{loginForm.signUp}</span>
          </Link>
        </form>
      </React.Fragment>
    );
  }
}
export default LoginForm;
