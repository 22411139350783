import React from "react";
import { inject, observer } from "mobx-react";
import { Checkbox, Spin } from "antd";
import { sortBy } from "lodash";
import { toJS } from "mobx";
import { CustomButton } from "../UI/CustomButton";

@inject("store")
@observer
class GroupSites extends React.Component {
  saveGroupSites = async (value) => {
    const {
      store: {
        GroupListStore: { selectedGroup, updateGroupSites, siteGroupsLoading },
      },
    } = this.props;
    if (siteGroupsLoading) {
      return;
    }
    updateGroupSites(selectedGroup.id);
  };

  render() {
    const {
      store: {
        GroupListStore: { updateMultipleSites, groupSites, siteGroupsLoading },
        SiteStore: { allSites },
      },
    } = this.props;
    const siteOptions = sortBy([...allSites.values()], ["name"]).map((site) => {
      return { value: site.id, label: site.name };
    });
    return siteGroupsLoading ? (
      <Spin />
    ) : (
      <div>
        <div className="new-event-label">Sites</div>
        <Checkbox.Group
          options={siteOptions}
          defaultValue={toJS(groupSites)}
          onChange={(e) => {
            updateMultipleSites(e);
          }}
        />
        <button
          className="referral-form-button"
          onClick={() => this.saveGroupSites()}
        >
          Save
        </button>
      </div>
    );
  }
}

export default GroupSites;
