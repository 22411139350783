import React from "react";
import { Spin } from "antd";
import close from "../../static/images/close.png";

export const ConfirmModal = (props) => {
  const { onConfirm, onCancel, onClose, loading } = props;
  return (
    <div>
      <div>
        <div className="confirm-backdrop" onClick={onClose} />
        <div className="confirm-modal">
          <h1>
            Are you sure you want to leave without saving?
            <img src={close} onClick={onClose} alt="Close" />
          </h1>
          <p> All current progress will be lost.</p>
          <div className="modal-buttons">
            <button className="confirm" onClick={onConfirm}>
              Save now
            </button>
            <button className="cancel" onClick={onCancel}>
              Exit without saving
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
