import React from "react";

// Components
// Local Imports
import "../../styles/style.scss";

class ReplyContainer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    let {
      userColor,
      children
    } = this.props;

    return <div
      id="reply-message-container"
    >
      <div
        id="reply-message"
        style={{
          borderRadius: "10px",
          border: "solid",
          borderColor: userColor
        }}
      >
        <div className="reply-border" style={{
          background: userColor
        }}></div>
        {children}
      </div>
    </div>;
  }
}

export default ReplyContainer;
