export const getFormattedResponses = (
  descriptiveResponses,
  multipleChoiceResponse,
  multipleChoiceRadioResponse,
  datePickerResponses
) => {
  let payload = [];
  Object.keys(descriptiveResponses).forEach((key) => {
    payload.push({
      answer: descriptiveResponses[key],
      formQuestionId: parseInt(key, 10),
      formQuestionOptionId: -1,
    });
  });
  Object.keys(datePickerResponses).forEach((key) => {
    payload.push({
      answer: datePickerResponses[key],
      formQuestionId: parseInt(key, 10),
      formQuestionOptionId: -1,
    });
  });
  Object.keys(multipleChoiceResponse).forEach((key) => {
    payload.push({
      answer: multipleChoiceResponse[key].length
        ? multipleChoiceResponse[key].join(",")
        : "",
      formQuestionId: parseInt(key, 10),
      formQuestionOptionId: -1,
    });
  });
  Object.keys(multipleChoiceRadioResponse).forEach((key) => {
    payload.push({
      answer: "",
      formQuestionId: parseInt(key, 10),
      formQuestionOptionId: multipleChoiceRadioResponse[key],
    });
  });
  return payload;
};
