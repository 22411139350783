import React from 'react';
import { inject, observer } from 'mobx-react';

import { userProfile } from '../../en.json';

const CryptoJS = require("crypto-js");

@inject("store")
@observer
class UserInsuranceDetails extends React.Component {
    renderUserInsuranceDetails = (userInsuranceDetails) => {
        const {
            confirm_membership_insurance_number: confirmMembershipInsuranceNumber,
            membership_insurance_number: membershipInsuranceNumber,
            plan_name: planName,
            image: insuranceImage
        } = userInsuranceDetails;
        const isInsuranceDetailsFound = insuranceImage ? true : confirmMembershipInsuranceNumber || membershipInsuranceNumber || planName ? true : false;
        const detailsNotAvailable = 'No Details';

        if (!isInsuranceDetailsFound) {
            return this.renderNoInsuranceDetailsFound();
        }

        if (insuranceImage) {
            const encodedWord = CryptoJS.enc.Base64.parse(insuranceImage);
            const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);
            return (<img className='insurance-image' src={`data:image/png;base64,${decoded}`} />);
        }

        return (<>
            {/* <span className="subHeading">{userProfile.confirmMembershipInsuranceNumber}</span> <br />
            <span className="mainHeading">{confirmMembershipInsuranceNumber || detailsNotAvailable}</span> <br /> */}

            <span className="subHeading">{userProfile.membershipInsuranceNumber}</span> <br />
            <span className="mainHeading">{membershipInsuranceNumber || detailsNotAvailable}</span><br />

            <span className="subHeading">{userProfile.planName}</span> <br />
            <span className="mainHeading">{planName || detailsNotAvailable}</span>
        </>)
    };

    renderNoInsuranceDetailsFound = () => {
        return (<div>
            No insurance details found!
        </div>);
    };

    render() {
        const {
            store: {
                AuthStore,
                ProfileStore: {
                    userInsuranceDetails,
                },
            },
        } = this.props;
        const isSuperAdmin = AuthStore.type === "SA" ? true : false;
        const isAdmin = AuthStore.type === "moderator" ? true : false;

        return (
            <React.Fragment>
                {(isSuperAdmin || isAdmin) ?
                    (<>
                        <div className="profile-edit">
                            <div className="profile-type">{userProfile.insuranceDetails}</div>
                        </div>

                        <div className="wellness-form-container">
                            {userInsuranceDetails ? (this.renderUserInsuranceDetails(userInsuranceDetails)) :
                                (this.renderNoInsuranceDetailsFound())}
                        </div>
                    </>
                    ) : null
                }
            </React.Fragment>
        );
    }
};

export default UserInsuranceDetails;