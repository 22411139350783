import React from "react";
import { inject, observer } from "mobx-react";
import { Spin } from "antd";
import close from "../../static/images/close.png";
import { RSVP } from "../../en.json";

@inject("store")
@observer
class NewReferralModal extends React.Component {
  changeValue = (e) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails(e.target.getAttribute("data-field"), e.target.value);
  };
  render() {
    const { onConfirm, onCancel } = this.props;
    const {
      store: {
        EventStore: {
          canReferAPatient,
          creatingReferral,
          newPatientPhone,
          isNotNumber,
        },
      },
    } = this.props;
    return (
      <div>
        <div className="confirm-backdrop" onClick={onCancel} />
        <div className="new-referral-modal">
          <h1>
            Refer a patient
            <img
              className="close-new-referral"
              src={close}
              onClick={onCancel}
              alt="Close"
            />
          </h1>
          <p className="new-refer-desc">
            During this group session we will learn how to deal with anxiety
          </p>
          <div className="new-refer-input-container">
            <div className="new-referral-label">Name</div>
            <input
              placeholder="Name"
              className="new-referral-input"
              data-field="newPatientName"
              onChange={this.changeValue}
            />
            <div className="new-referral-label">Email</div>
            <input
              placeholder="Email"
              className="new-referral-input"
              data-field="newPatientEmail"
              onChange={this.changeValue}
            />
            <div className="new-referral-label">Phone Number</div>
            <input
              placeholder={RSVP.phonePlaceHolder}
              className="new-referral-input"
              data-field="newPatientPhone"
              onChange={this.changeValue}
              style={
                newPatientPhone.length
                  ? isNotNumber
                    ? { borderBottom: "2px solid red" }
                    : null
                  : null
              }
            />
          </div>
          <div className="send-refer">
            {creatingReferral ? (
              <Spin />
            ) : (
              <button
                className="new-referral-button"
                disabled={canReferAPatient || creatingReferral}
                onClick={onConfirm}
              >
                Send Referral
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NewReferralModal;
