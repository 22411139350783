import { inject, observer } from "mobx-react";

import React from "react";
import SVG from "react-inlinesvg";
import admin from "../../static/images/admin.png";
import adminSVG from "../../static/images/admin.svg";
import mod from "../../static/images/mod.png";
import modSVG from "../../static/images/mod.svg";

@inject("store")
@observer
class ModeratorTag extends React.Component {
  render() {
    const { badgeType } = this.props;

    return (
      <React.Fragment>
        {badgeType === "semi-moderator" && (
          <span className="badge-cmod">PEER</span>
        )}
        {badgeType === "community-moderator" && (
          <SVG src={modSVG}>
            <img src={mod} alt="MOD" />
          </SVG>
        )}
        {badgeType === "moderator" && (
          <SVG src={adminSVG}>
            <img src={admin} alt="ADMIN" />
          </SVG>
        )}
      </React.Fragment>
    );
  }
}

ModeratorTag.defaultProps = {
  type: null,
};

export default ModeratorTag;
