import { types } from "mobx-state-tree";

const DmUser = types.model("DmUser", {
  userId: types.number,
  username: types.maybeNull(types.string),
  userType: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  flair: types.maybeNull(types.string),
});

export default DmUser;
