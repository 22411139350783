import { types } from "mobx-state-tree";

const GroupUser = types.model("GroupUser", {
  userId: types.number,
  groupId: types.number,
  username: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  userType: types.maybeNull(types.string),
  status: types.maybeNull(types.number),
  userTypeInGroup: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  enrolledColor: types.maybeNull(types.string),
  badgeColor: types.maybeNull(types.string),
  flair: types.maybeNull(types.string),
  badgeType: types.maybeNull(types.string),
});

export default GroupUser;
