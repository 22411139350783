import { types } from "mobx-state-tree";

const ReferralItem = types.model("ReferralItem", {
  id: types.identifierNumber,
  sourceUserId: types.number,
  targetUserId: types.number,
  formId: types.maybeNull(types.string),
  status: types.number,
  isUsingApp: types.boolean,
  program: types.number,
  siteId: types.maybeNull(types.number),
  sentAt: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.string,
  hasPhone: types.boolean,
  referAssignedTo: types.array(
    types.frozen({
      id: types.number,
      userId: types.number,
      referId: types.number,
    })
  ),
  alreadyAssigned: types.array(types.number),
  sourceUserInfo: types.frozen({
    fullName: types.maybeNull(types.string),
  }),
  targetUserInfo: types.frozen({
    fullName: types.maybeNull(types.string),
    dob: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
  }),
});

export default ReferralItem;
