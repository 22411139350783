import React from "react";
import { Table, Select, Spin, Checkbox } from "antd";
import { inject, observer } from "mobx-react";

import { forms } from "../../en.json";
import UserBadges from "../UI/UserBadges";
import { getFormattedDate } from '../../utils/CommonUtils';
import { IS_HP } from "../../utils/getEnvironment";
import {
    FILTER_BY_ALL_FORMS_FLAG,
    FILTER_BY_BARC10_FORMS_FLAG,
    FILTER_BY_EXCEPT_BARC10_FORMS_FLAG,
    SUBMITTED_FROM_TYPE_ADMIN,
    SUBMITTED_FROM_TYPE_WAITING
} from "../../constants/GlobalConstant";

const { Option } = Select;

@inject("store")
@observer
class StatsForms extends React.Component {
    componentDidMount() {
        const {
            store: { StatsStore: { formsData } }
        } = this.props;
        if (!formsData.length) {
            this.fetchFormsList();
        }
    };

    componentWillUnmount() {
        const {
            store: { StatsStore }
        } = this.props;
        StatsStore.reset();
    };

    async fetchFormsList() {
        try {
            const {
                store: { StatsStore, StatsStore: { filterBy } }
            } = this.props;
            StatsStore.setLoading(true);
            StatsStore.setFilterBy(filterBy ? filterBy : FILTER_BY_ALL_FORMS_FLAG);
            await StatsStore.getFormsData();
            StatsStore.setLoading(false);
        } catch (error) {
        }
    };

    handleTableChange = (pagination, filters, sorter) => {
        const {
            store: {
                StatsStore,
            },
        } = this.props;
        StatsStore.setPaginationData(pagination);
        this.fetchFormsList();
    };

    handleFullNamePress = async (sender) => {
        try {
            const {
                store: {
                    ProfileStore: {
                        setMemberProfileVisibility,
                        setMemberData,
                        setAssignedPeerDetails,
                        toggleLabel,
                        reset,
                        userId,
                        getHighPointUsers,
                        changeSelectedAction,
                        getProviderModeratedGroups,
                        fetchGroupsFromForms,
                        getContactModeratedGroups,
                    },
                    DeletedMessagesStore: { setShowDeleted },
                    MemberListStore: { getMemberDetails, fetchAdminUsers },
                    MessagesStore: { setIsSmsSectionVisible },
                },
            } = this.props;
            if (sender === userId) {
                return;
            }
            reset();
            setIsSmsSectionVisible(false);
            setShowDeleted(false);
            setMemberProfileVisibility(true);
            setAssignedPeerDetails({});
            fetchAdminUsers();
            const member = await getMemberDetails(sender);
            if (member) {
                const { providerId, recommendedBy } = member;
                setMemberData(member);
                if (IS_HP) {
                    getHighPointUsers();
                    if (providerId) {
                        getProviderModeratedGroups(providerId);
                    }
                    if (recommendedBy) {
                        getContactModeratedGroups(recommendedBy);
                    }
                    fetchGroupsFromForms();
                }
            }
            toggleLabel(false);
            changeSelectedAction("");
        } catch (error) {
        }
    };

    handleFilterByChange = async (value) => {
        try {
            const {
                store: { StatsStore }
            } = this.props;
            StatsStore.setLoading(true);
            StatsStore.setFilterBy(value);
            await StatsStore.getFormsData();
            StatsStore.setLoading(false);
        } catch (error) {
        }
    };

    render() {
        const {
            store: {
                StatsStore: {
                    loading,
                    pagination,
                    formsData,
                    filterBy
                }
            },
        } = this.props;

        const rowEvents = (record, rowIndex) => {
            return {
                onClick: () => {
                    this.handleFullNamePress(record.userId);
                },
            };
        };

        const renderJoinDate = (row) => {
            const { completedAt } = row;
            return <>
                {completedAt ? getFormattedDate(completedAt) : null}
            </>
        };

        const renderResponseStatus = (row) => {
            const { submittedFrom, submittedFromDetails } = row;
            const { fullName: submittedFromName } = submittedFromDetails || {};
            const responseFrom =
                submittedFrom === SUBMITTED_FROM_TYPE_WAITING ? 'MARIGOLD SUPPORT'
                    : submittedFrom === SUBMITTED_FROM_TYPE_ADMIN ? `${submittedFrom} ${submittedFromName ? ` - ${submittedFromName}` : ''}`
                        : submittedFrom;
            return (
                <>
                    <div className="table-col">{responseFrom}</div>
                </>
            );
        };

        const renderUsername = (text, row) => {
            return (
                <>
                    <div className="link-text table-col">
                        {text}
                    </div>
                </>
            );
        };

        const renderUserBadges = (badgeType) => {
            return (<>
                {badgeType && <UserBadges badgeType={badgeType} />}
            </>)
        };

        const renderReviewedUserDetails = (row = {}) => {
            const { reviewedUserDetails, reviewedAt } = row;
            return (<>
                <Checkbox checked={reviewedUserDetails ? true : false}>
                </Checkbox>
                {reviewedUserDetails ? <span className="table-col">- {reviewedUserDetails.fullName} {reviewedAt ? ` on ${getFormattedDate(reviewedAt)}` : ''}</span>
                    : null}
            </>)
        };

        const renderAssignedPeerDetails = (assignedPeersDetails = {}) => {
            const { assignedPeerFullName, } = assignedPeersDetails;
            return (<>
                {assignedPeerFullName ? <span className="table-col">{assignedPeerFullName}</span> : null}
            </>)
        };

        const columns = [
            {
                title: <div className="group-head">{forms.date}</div>,
                render: renderJoinDate
            },
            {
                title: <div className="group-head">{forms.name}</div>,
                render: (text) => <div className="table-col">{text}</div>,
                dataIndex: 'fullName',
                sorter: false,
            },
            {
                title: <div className="group-head">{forms.username}</div>,
                dataIndex: 'username',
                width: '15%',
                render: renderUsername,
                sorter: false,
            },
            {
                title: <div className="group-head">{forms.badge} </div>,
                dataIndex: "badgeType",
                width: '5%',
                render: renderUserBadges
            },
            {
                title: <div className="group-head">{forms.formName} </div>,
                dataIndex: "formName",
            },
            {
                title: <div className="group-head">{forms.responseFrom} </div>,
                render: renderResponseStatus
            },
            {
                title: <div className="group-head">{forms.formScore} </div>,
                dataIndex: "totalScore",
            },
            {
                title: <div className="group-head">{forms.reviewedBy} </div>,
                width: '15%',
                render: renderReviewedUserDetails
            },
            {
                title: <div className="group-head">{forms.assignedPeer} </div>,
                dataIndex: 'assignedPeersDetails',
                width: '10%',
                render: renderAssignedPeerDetails
            },
        ];

        return (
            <React.Fragment>
                <div style={{
                    marginLeft: 10,
                    marginBottom: 10,
                    marginRight: 15
                }}>
                    <Select
                        value={filterBy}
                        style={{ width: 180 }}
                        onChange={this.handleFilterByChange}
                    >
                        <Option value={FILTER_BY_ALL_FORMS_FLAG}>{forms.formFilterAllForms}</Option>
                        <Option value={FILTER_BY_BARC10_FORMS_FLAG}>{forms.formFilterBarc10Forms}</Option>
                        <Option value={FILTER_BY_EXCEPT_BARC10_FORMS_FLAG} >{forms.formFilterExceptBarc10Forms}</Option>
                    </Select>
                </div>

                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={formsData}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    onRow={rowEvents}
                />
            </React.Fragment>
        );
    }
}

export default StatsForms;
