import { Dropdown, Icon, Menu, Spin, Select } from "antd";
import { inject, observer } from "mobx-react";

import React from "react";
import _ from "lodash";
import { userSignUpForm } from "../../en.json";
import { IS_HP } from "../../utils/getEnvironment";

var mixpanel = require("mixpanel-browser");
var CryptoJS = require("crypto-js");

const { REACT_APP_SECRET_KEY: secretKey } = process.env;
const separator = "+";
const Option = Select.Option;
@inject("store")
@observer
class UserSignUpForm extends React.Component {
  state = {
    checked: false,
    showPassword: false,
    showConfirmPassword: false,
    overlayVisible: false,
  };
  componentDidMount() {
    const {
      store: {
        UserSignUpStore: { getHighPointUsers, fetchAllSites },
      },
    } = this.props;
    fetchAllSites();
    this.checkUrl(this.props.url);
    mixpanel.track("Signup Page Loaded", { from: "WEB" });
    mixpanel.track("CSM Loaded Page", {
      encryptedData: this.props.url,
      from: "WEB",
    });
    if (IS_HP) {
      getHighPointUsers();
    }
  }

  checkUrl = (url) => {
    const {
      store: {
        UserSignUpStore: { setNotes, setPhoneNumber },
      },
    } = this.props;
    const decrypedData = this.decrypt(url);
    const decrypedString = decrypedData.split(separator);
    const phoneNumber = decrypedString[0];
    const sitePin = decrypedString[1];
    const color = decrypedString[2];
    const mrn = decrypedString[3];
    const name = decrypedString[4];
    setNotes(sitePin, color, mrn, name);
    setPhoneNumber(phoneNumber);
  };

  encrypt(data) {
    let encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();
    return encrypted;
  }

  decrypt(ciphertext) {
    let bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    let decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  setFormInput = (e) => {
    const {
      store: {
        UserSignUpStore: { setAttribute },
      },
    } = this.props;
    setAttribute(e.target.getAttribute("data-field"), String(e.target.value));
  };
  toggleCheckbox = (e) => {
    this.setState({
      checked: e.target.checked,
    });
  };
  toggleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  };
  toggleShowConfirmPassword = () => {
    const { showConfirmPassword } = this.state;
    this.setState({
      showConfirmPassword: !showConfirmPassword,
    });
  };
  setSite = ({ key }) => {
    const {
      store: {
        UserSignUpStore: { setAttribute, allSites, setSiteName },
      },
    } = this.props;
    this.setState({
      overlayVisible: false,
    });
    const selectedSite = allSites.filter((site) => {
      return site.id === Number(key);
    });
    setAttribute("userSiteId", String(key));
    setSiteName(_.get(selectedSite, "[0].name", ""));
  };

  filterSites = (name) => {
    return name !== "Dev" && name !== "Demo" && name !== "QA Sandbox";
  };

  renderMenu = () => {
    const {
      store: {
        UserSignUpStore: { loading, allSites },
      },
    } = this.props;

    return (
      <div className="sites-container">
        {loading ? (
          <div>
            <Spin />
          </div>
        ) : (
          <Menu onClick={this.setSite} className="menu">
            {allSites.map(
              (site) =>
                this.filterSites(site.name) && (
                  <Menu.Item
                    key={site.id}
                    className="menu-item"
                    // disabled={site.id == userSiteId}
                  >
                    <div>{site.name}</div>
                  </Menu.Item>
                )
            )}
          </Menu>
        )}
      </div>
    );
  };

  updateContact = async (key) => {
    const {
      store: {
        UserSignUpStore: { updateProviderId },
      },
    } = this.props;
    updateProviderId(key);
  };

  render() {
    const {
      store: {
        UserSignUpStore: {
          createUser,
          HpUsers,
          loading,
          siteName,
          phoneNumber,
        },
      },
    } = this.props;
    const {
      checked,
      showPassword,
      showConfirmPassword,
      overlayVisible,
    } = this.state;

    return phoneNumber ? (
      <React.Fragment>
        <div className="d-flex mt-1">
          <div className="info">{userSignUpForm.yourInfo}</div>
          <div className="triangle-shape-info"></div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createUser(checked);
          }}
        >
          <div className="csm-signup-container">
            <div className="input-label pt-1">{userSignUpForm.fullName}</div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="First Name and Last Name"
                onChange={this.setFormInput}
                data-field="fullName"
              />
            </div>
            <div className="input-label">{userSignUpForm.email}</div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                onChange={this.setFormInput}
                data-field="email"
              />
            </div>
            <div className="input-label">{userSignUpForm.dob}</div>
            <div className="form-group">
              <input
                type="date"
                className="form-control"
                placeholder="Enter Date Of Birth"
                onChange={this.setFormInput}
                data-field="DOB"
              />
            </div>

            {IS_HP ? (
              <>
                <div className="input-label">
                  {userSignUpForm.siteHighPoint}
                </div>
                <div id="menu-container" className="contact-dropdown">
                  <Select
                    showSearch
                    placeholder="Select HighPoint Contact"
                    optionFilterProp="children"
                    showArrow={false}
                    onChange={this.updateContact}
                    filterOption={(input, option) =>
                      _.get(option, "props.children.props.children", [])
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={() =>
                      document.getElementById("menu-container")
                    }
                    allowClear={true}
                  >
                    {HpUsers.map((user) => (
                      <Option
                        value={user.contactId}
                        key={user.contactId}
                        className="menu-item"
                      >
                        <div>{user.contactName}</div>
                      </Option>
                    ))}
                  </Select>
                </div>
              </>
            ) : null}
            <div className="input-label">
              {IS_HP
                ? userSignUpForm.yourHighPointLocation
                : userSignUpForm.yourCleanslateLocation}
            </div>
            <div className="form-group">
              <div></div>
              <Dropdown
                overlay={this.renderMenu()}
                trigger={["click"]}
                placement="bottomCenter"
                onVisibleChange={(visible) => {
                  this.setState({
                    overlayVisible: visible,
                  });
                }}
              >
                <div
                  className="site-dropdown nav-item"
                  style={
                    overlayVisible
                      ? {
                          border: "2px solid #c6e0ff",
                          boxShadow: "0 0 5px #c6e0ff",
                        }
                      : {}
                  }
                >
                  <div>{siteName ? siteName : "Choose Your Center"}</div>
                  <Icon type="down" style={{ fontSize: 20 }} />
                </div>
              </Dropdown>
              <div className="caution">{userSignUpForm.neverShare}</div>
            </div>
            <div className="d-flex">
              <div className="info">{userSignUpForm.marigoldInfo}</div>
              <div className="triangle-shape-info"></div>
            </div>
            <div className="input-label pt-1">{userSignUpForm.username}</div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Pick a Username e.g. PopcornCat1"
                onChange={this.setFormInput}
                data-field="username"
              />
              <div className="caution">{userSignUpForm.chooseUsername}</div>
            </div>

            <div className="input-label">{userSignUpForm.password}</div>
            <div className="form-group">
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control password-field"
                  placeholder="Password"
                  onChange={this.setFormInput}
                  data-field="password"
                />
                <div
                  className="input-group-addon eye-container"
                  onClick={() => {
                    this.toggleShowPassword();
                  }}
                >
                  <i
                    className={
                      "fa" + (showPassword ? " fa-eye" : " fa-eye-slash")
                    }
                  ></i>
                </div>
              </div>
              <div className="caution">{userSignUpForm.useAtleast}</div>
            </div>
            <div className="input-label">{userSignUpForm.confirmPassword}</div>
            <div className="form-group">
              <div className="password-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control password-field"
                  placeholder="Confirm Password"
                  onChange={this.setFormInput}
                  data-field="confirmPassword"
                />
                <div
                  className="input-group-addon eye-container"
                  onClick={() => {
                    this.toggleShowConfirmPassword();
                  }}
                >
                  <i
                    className={
                      "fa" + (showConfirmPassword ? " fa-eye" : " fa-eye-slash")
                    }
                  ></i>
                </div>
              </div>
              <div className="terms-container">
                <input
                  className="check-box"
                  type="checkbox"
                  id="terms"
                  checked={checked}
                  onChange={(e) => this.toggleCheckbox(e)}
                />
                <label htmlFor="terms">
                  {userSignUpForm.iAgree}{" "}
                  <a
                    href={userSignUpForm.termsAndCondtitionsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {userSignUpForm.termsAndCondtitions}
                  </a>
                </label>
              </div>
              <div>
                <button
                  className="btn btn-primary btn-block"
                  type="submit"
                  disabled={loading}
                  style={{ position: "relative" }}
                >
                  {loading && (
                    <div
                      className="spinner-grow"
                      style={{
                        width: "16px",
                        height: "16px",
                        position: "absolute",
                        left: "35%",
                        top: "8px",
                      }}
                      role="status"
                    >
                      <span className="sr-only">{userSignUpForm.loading}</span>
                    </div>
                  )}
                  {userSignUpForm.signUpAndGet}
                </button>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <h1>{userSignUpForm.pageNotFound}</h1>
      </React.Fragment>
    );
  }
}
export default UserSignUpForm;
