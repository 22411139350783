import { inject, observer } from "mobx-react";

import React from "react";
import GroupFlags from "./GroupFlags";
import close from "../../static/images/close.png";

@inject("store")
@observer
class GroupFlagsContainer extends React.Component {
  render() {
    const {
      store: {
        GroupListStore: {
          selectedGroup: { name },
          showFlags,
        },
      },
    } = this.props;

    return (
      <React.Fragment>
        <div className="user-flags">
          <span className="username">Flags from {name}</span>
          <img src={close} onClick={() => showFlags(false)} alt="Close" />
        </div>
        <GroupFlags />
      </React.Fragment>
    );
  }
}
export default GroupFlagsContainer;
