import React from "react";
import { Spin } from "antd";
import close from "../../static/images/close.png";

export const NoChromeModal = (props) => {
  const { onConfirm } = props;
  return (
    <div>
      <div>
        <div className="confirm-backdrop" onClick={onConfirm} />
        <div className="no-chrome-modal">
          <div className="modal-heading">
            <h1>We recommend using Chrome</h1>
            <img src={close} onClick={onConfirm} alt="Close" />
          </div>
          <p>
            On this browser, we've seen reports of certain website functionality
            not working smoothly.
          </p>
          <p>
            We apologize for the inconvenience and recommend using Google Chrome
            for the best experience while we fix these issues.
          </p>
          <div className="modal-buttons">
            <button className="confirm" onClick={onConfirm}>
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
