import "../../styles/global.scss";

import { inject, observer } from "mobx-react";

import { Button } from "antd";
import React from "react";
import { some } from "lodash";

@inject("store")
@observer
class ContentList extends React.Component {
  changeChannel = (id, name, flag, timeToken) => {
    const {
      store: {
        MessagesStore: { loadChannelMessages, selectedGroup },
        GroupStore: { fetchGroupUsers },
      },
      messageList,
    } = this.props;
    const type = flag ? "group" : "dm";
    loadChannelMessages(
      id,
      name,
      type,
      timeToken,
      this.scrollToTop,
      messageList
    );
    selectedGroup.setFlaggedMessageTimeToken(timeToken);
    flag && fetchGroupUsers(id);
  };

  scrollToTop = (prevScrollHeight) => {
    const { messageList } = this.props;
    messageList.current.scrollTop =
      messageList.current.scrollHeight -
      prevScrollHeight +
      messageList.current.scrollTop;
  };

  scrollToBottom = () => {
    const { messageList } = this.props;
    if (messageList.current) {
      const scrollHeight = messageList.current.scrollHeight;
      const height = messageList.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messageList.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };
  handleClick = (name) => {
    const {
      store: {
        NotificationStore,
        ProfileStore: { changeSelectedAction, userId },
        BroadcastStore: { createDm },
        MemberListStore: { clearAssignInfo },
      },
      usernamePress,
      message,
    } = this.props;

    clearAssignInfo();

    if (name === "View Profile") {
      if (userId === message.senderId) {
        return;
      } else {
        changeSelectedAction(name);
        usernamePress();
      }
    } else if (name === "Suspend account") {
      changeSelectedAction(name);
      NotificationStore.setConfirm(
        () => this.suspendAccount(name),
        "You Want to Suspend the account?"
      );
    } else if (name === "Direct Message") {
      changeSelectedAction(name);
      createDm(message.sender, message.senderId);
    } else {
      changeSelectedAction(name);
    }
  };

  isUserSuspended = () => {
    const {
      store: {
        FlaggedMessagesStore: { fetchingUserStatus, userSuspendStatus },
      },
      message,
    } = this.props;
    if (!fetchingUserStatus && userSuspendStatus.has(message.id)) {
      const isSuspended = userSuspendStatus.get(message.id);
      return isSuspended ? "Already Suspended" : "Suspend account";
    } else if (!userSuspendStatus.has(message.id)) {
      return "Not a part of group";
    }
  };

  suspendAccount = async () => {
    const {
      store: {
        GroupStore: { changeStatus },
        FlaggedMessagesStore: { setUserStatus },
        NotificationStore: { setNotification },
      },
      message,
    } = this.props;

    const groupId = message.channel.split("_")[2];
    const success = await changeStatus(parseInt(groupId), message.senderId, 2);
    if (success) {
      setUserStatus(message.id, 2);
      setNotification("success", "User has been suspended");
    } else {
      setNotification("error", "User already suspended");
    }
  };

  render() {
    const {
      name,
      highlighted,
      message,
      store: {
        FlaggedMessagesStore: { userSuspendStatus, fetchingUserStatus },
      },
    } = this.props;
    return (
      <li
        style={{
          background: highlighted && "#4168CA",
        }}
        className="list-content-item "
        onClick={(e) => {
          e.stopPropagation();
          this.handleClick(name);
        }}
      >
        <Button
          className="content-item content-button"
          disabled={
            name === "Suspend account" &&
            ((userSuspendStatus.has(message.id) &&
              userSuspendStatus.get(message.id)) ||
              fetchingUserStatus ||
              !userSuspendStatus.has(message.id))
          }
          style={{
            border: "none",
            borderRadius: "0",
            height: "40px",
            fontSize: "18px",
          }}
        >
          {name === "Suspend account" ? this.isUserSuspended() : name}
        </Button>
      </li>
    );
  }
}
export default ContentList;
