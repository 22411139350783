import "../../styles/global.scss";

import { Tabs } from "antd";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import React from "react";
import _ from "lodash";

import template from "../../utils/templates";
import MyTasksAllTasks from "./MyTasksAllTasks";
import MyTasks from "./MyTasks";
import MyTasksMyCaseloads from "./MyTasksMyCaseloads";
import MyTasksOverdue from "./MyTasksOverdue";

const TabPane = Tabs.TabPane;

@withRouter
@inject("store")
@observer
class MyTasksRoot extends React.Component {

  componentDidMount() {
    const {
      store: {
        MemberListStore: { fetchAdminUsers },
      },
    } = this.props;

    fetchAdminUsers()
  }

  setTabKey = (key) => {
    const {
      store: {
        TaskStore: { setTasksTabKey }
      },
    } = this.props;
    setTasksTabKey(Number(key));
  };

  render() {
    return (
      <div>
        <Tabs
          onChange={this.setTabKey}
          size="small"
          tabBarStyle={{ border: 0 }}
        >
          <TabPane className="my-tabs-ink-bar" tab={template.myTasks.allTasks} key="1">
            <MyTasksAllTasks />
          </TabPane>
          <TabPane className="my-tabs-ink-bar" tab={template.myTasks.myTasks} key="2">
            <MyTasks />
          </TabPane>
          <TabPane className="my-tabs-ink-bar" tab={template.myTasks.tasksOnMyCaseloads} key="3">
            <MyTasksMyCaseloads />
          </TabPane>
          <TabPane className="my-tabs-ink-bar" tab={template.myTasks.overdueTasks} key="4">
            <MyTasksOverdue />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default MyTasksRoot;
