export const getReactionType = (reactionId) => {
  switch (reactionId) {
    case 1:
      return "like";
    case 2:
      return "flag";
    case 3:
      return "delete";
    case 4:
      return "hug";
    case 5:
      return "lol";
    case 6:
      return "bulb";
    case 7:
      return "redFlag";
    case 8:
      return "blueFlag";
    default:
      return null;
  }
};
