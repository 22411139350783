var mixpanel = require("mixpanel-browser");

export function commonTrackEvent(channel, eventName, groupName, groupId) {
  const params = getCommonDataForEvents(channel, groupName, groupId);
  return mixpanel.track(eventName, params);
}

const getCommonDataForEvents = (channel, groupName, groupId) => {
  const commonDMParam = {
    DMName: groupName,
    DMId: groupId,
    from: "WEB"
  };
  const commonGroupParam = {
    GroupName: groupName,
    GroupId: groupId,
    from: "WEB"
  };
  return channel === "DIRECT" ? commonDMParam : commonGroupParam;
};
