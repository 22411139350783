import React, { Component } from 'react'
import { Button, Col, Drawer, Row, Spin } from "antd";
import { inject, observer } from "mobx-react";
import moment from 'moment';

import Notification from "./Notification";
import { GROUP_NOTIFICATION_TYPE } from '../../constants/GlobalConstant';


@inject("store")
@observer
class CustomNotificationDrawer extends Component {

  showDay = (d) => {
    const today = moment();
    const date = moment(d);
    const diff = today.diff(date, 'days')
    if(diff === 0) {
      return 'Today';
    } else if (diff === 1) {
      return 'Yesterday';
    } else {
      return moment().format('MM Do YYYY');
    }

  }

  doSetMessageRedirectionData = async ({ data }) => {
    const {
      store: {
        AuthStore: {
          userId
        },
        MessagesStore: {
          setMessageRedirectionData
        },
        HistoryStore: {
          setShowAllNotifications
        },
        GroupListStore: {
          fetchGroupSession,
        },
      }
    } = this.props;
    const channelId = parseInt(data.channel.replace( /^\D+/g, ''));
    let userRole = "SA"; // pass SA if no type exists
    if(GROUP_NOTIFICATION_TYPE.includes(data.notificationType)) {
      const response = await fetchGroupSession(channelId);
      const role = response.members.filter(a => a.id === userId)[0]?.user_groups?.type;
      if(role) {
        userRole = role
      }
    }

    if(data?.actionType === "FEED_REACTED") {
      const {
        MessagesStore: {
          setActiveTab
        },
        ForumFeedStore: {
          setNotificationFeedId,
          setNotificationCommentId,
          setIsFeedCommentVisible,
          setComments
        }
      } = this.props.store;
      setNotificationFeedId(null);
      setNotificationFeedId(data?.activityId);
      if (data?.commentId) {
        setComments([]);
        setIsFeedCommentVisible(false);
        setNotificationCommentId(null);
        setNotificationCommentId(data?.commentId);
      }
      setActiveTab("feed");
    } else {
      const obj = {
        messageID: channelId, 
        messageTitle: data.title, 
        messageUserRole: userRole,
        messageType: data.notificationType
      }
      setMessageRedirectionData(obj);
    }
    setShowAllNotifications(false);
    this.props.navigate();
  }

  render() {
    const {
      title,
      onCancel,
      visible,
    } = this.props;

    const {
      store: {
        NotificationStore: {
          resetData,
          dmNotifications,
          resetDMNotification,
          groupNotifications,
          resetGroupNotifications,
          otherNotifications,
          resetOtherNotifications,
          groupedDMNotifications,
          groupedGroupNotifications,
          groupedOtherNotifications
        }
      }
    } = this.props;

    const totalNotificationCount = dmNotifications.length + groupNotifications.length + otherNotifications.length;
    return (
      <Drawer
        title={title}
        placement="right"
        onClose={onCancel}
        getContainer={false}
        visible={visible}
        width={'60%'}
        size={`large`}
      >
        <div>
          {totalNotificationCount > 0 ? <><Row>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 10 }}>
              <span
                style={{ textDecoration: 'underline', marginRight: 25, fontSize: 15, cursor: 'pointer' }}
                onClick={() => resetData()}
              >
                Clear all notifications
              </span>
            </Col>
          </Row>
            <Row>
              <Col span={8} className={'notification-section'}>
                <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <label className='notification-label'>DM</label>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {dmNotifications.length > 0 && <label
                      className='notification-label-secondary'
                      onClick={() => resetDMNotification()}
                    >Clear</label>}
                  </Col>
                </Row>
                {groupedDMNotifications.length ? groupedDMNotifications.map((d, i) => {
                  return <div key={i} style={{ width: '100%' }}>
                    <label className='notification-date-label'>{this.showDay(d.date)}</label>
                    {d.notifications.map((n, j) => {
                      return <div key={j}><Notification
                        data={n}
                        index={j}
                        navigate={(props) => this.doSetMessageRedirectionData(props)}
                      /></div>
                    })}
                  </div>
                }) : <Row>
                  <Col span={24} className="no-notifications">
                    No DM Notifications
                  </Col>
                </Row>}
              </Col>
              <Col span={8} className={'notification-section'}>
                <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <label className='notification-label'>Group</label>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {groupNotifications.length > 0 && <label
                      className='notification-label-secondary'
                      onClick={() => resetGroupNotifications()}
                    >Clear</label>}
                  </Col>
                </Row>
                {groupedGroupNotifications.length ? groupedGroupNotifications.map((d, k) => {
                  return <div key={k} style={{ width: '100%' }}>
                    <label className='notification-date-label'>{this.showDay(d.date)}</label>
                    {d.notifications.map((n, j) => {
                      return <div key={j}><Notification
                        data={n}
                        index={j}
                        navigate={(props) => this.doSetMessageRedirectionData(props)}
                      /></div>
                    })}
                  </div>
                }) : <Row>
                  <Col span={24} className="no-notifications">
                    No Group Notifications
                  </Col>
                </Row>}
              </Col>
              <Col span={8} className={'notification-section'}>
                <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <label className='notification-label'>Other</label>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {otherNotifications.length > 0 && <label
                      className='notification-label-secondary'
                      onClick={() => resetOtherNotifications()}
                    >Clear</label>}
                  </Col>
                </Row>
                {groupedOtherNotifications.length ? groupedOtherNotifications.map((d, p) => {
                  return <div key={p} style={{ width: '100%' }}>
                    <label className='notification-date-label'>{this.showDay(d.date)}</label>
                    {d.notifications.map((n, j) => {
                      return <div key={j}><Notification
                        data={n}
                        index={j}
                        navigate={(props) => this.doSetMessageRedirectionData(props)}
                      /></div>
                    })}
                  </div>
                }) : <Row>
                  <Col span={24} className="no-notifications">
                    No Other Notifications
                  </Col>
                </Row>}
              </Col>
            </Row></> : <Row>
            <Col span={24} className="no-notifications">
              No Notifications
            </Col>
          </Row>}
        </div>
      </Drawer>)
  }
}

export default CustomNotificationDrawer;