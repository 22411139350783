import React from "react";
import Papa from "papaparse";
import { Spin } from "antd";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class CreateAdmins extends React.Component {
  constructor() {
    super();
    this.filesInput = React.createRef();
    this.state = {
      csvfile: undefined,
    };
    this.updateData = this.updateData.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      csvfile: event.target.files[0],
    });
  };

  importCSV = () => {
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
    });
  };

  async updateData(result) {
    const {
      store: {
        ReferralStore: { CreateAdmins },
      },
    } = this.props;
    const data = result.data;
    await CreateAdmins(data);
    this.filesInput.value = "";
  }

  render() {
    const {
      store: {
        ReferralStore: {
          creatingAdmins,
          failedToCreate,
          createdAdmins,
          inProgressUser,
        },
      },
    } = this.props;

    return (
      <div className="referral-section">
        <h1>Create Multiple Admins</h1>
        <div className="start-referral text-left">Import a CSV File</div>
        <div className="referral-box">
          <div>
            <input
              className="csv-input"
              type="file"
              ref={(input) => {
                this.filesInput = input;
              }}
              name="file"
              onChange={this.handleChange}
            />
          </div>
        </div>
        {creatingAdmins ? (
          <div className="form-loading">
            <Spin />
          </div>
        ) : null}
        <div className="send-referral">
          <button
            disabled={creatingAdmins}
            className="new-referral-button"
            onClick={this.importCSV}
          >
            Upload now!
          </button>
        </div>
        {inProgressUser ? <div>In Progress...{inProgressUser}</div> : null}
        <div>
          {createdAdmins.length ? (
            <div>
              <div>Created Users</div>
              {createdAdmins.map((user) => {
                return <div>{user}</div>;
              })}
            </div>
          ) : null}
          {failedToCreate.length ? (
            <div>
              <div>Failed To Create</div>
              {failedToCreate.map((error) => {
                return (
                  <div>
                    {error.username} : {error.errMsg}{" "}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default CreateAdmins;
