import { Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import Chat from "../components/protected/Chat";
import SessionDetails from "../components/protected/SessionDetails";

// import GroupDetails from "../components/protected/GroupDetails";
// import GroupList from "../components/protected/GroupList";
import Layout from "../components/Layout";

import UserForms from "../components/protected/UserForms";
import AllForms from "../components/protected/AllForms";
import { addListener, getPubnubInstanceByUserType, subscribeChannels, unsubscribeAll } from "../utils/PubnubMethods";
import React from "react";
import { withAuth } from "../components/Authentication";
import { NOTIFICATION_TYPE } from "../constants/GlobalConstant";

@withAuth
@withRouter
@inject("store")
@observer
class UserProtected extends React.Component {
  constructor() {
    super();
    this.state = {
      navHeight: 102,
    };
    this.navBarRef = React.createRef();
    this.resizeObserver = null;
  }

  async componentDidMount() {
    const {
      store: { MemberListStore, GroupStore, AuthStore, ReactionsStore },
    } = this.props;
    const userType = AuthStore.type;
    if (userType !== "moderator") {
      MemberListStore.fetchModerators();
      await AuthStore.fetchGroupsAndDms();
      // GroupStore.fetchPendingUsers();
      // ReactionsStore.fetchAllReactions();
      if(AuthStore.type) {
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        if (pubnub) {
          const subscritptionChannel = `FORUM_PUSH_${AuthStore.userId}`;
          await subscribeChannels(pubnub, [subscritptionChannel]);
          await addListener(pubnub);
        }
      }
    }

    if (this?.navBarRef?.current) {
      this.resizeObserver = new ResizeObserver(() => {
        this.handleResize();
      });
      this.resizeObserver.observe(this.navBarRef.current);
    }
  }

  async componentWillUnmount() {
    const {
      store: {
        AuthStore: { changeUserOnlineStatus },
      },
    } = this.props;
    changeUserOnlineStatus();
    unsubscribeAll();

    if (this?.navBarRef?.current && this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  handleResize = () => {
    this.setState({
      ...this.state,
      navHeight: this.navBarRef?.current?.offsetHeight ?? 102,
    })
  };

  getComponent = (view) => {
    switch (view) {
      case "chat":
        return (
          <div className="chat">
            <Chat navHeight={this.state.navHeight} />
          </div>
        );

      default:
        return <Redirect to="/members" />;
    }
  };

  render() {
    const {
      match: {
        params: { view },
      },
      store: {
        FormMessageStore: { userFormsVisible, formsVisible },
        MessagesStore: { sessionStarted },
      },
    } = this.props;

    const navHeightStyle = {
      top: this.state.navHeight,
    };

    return (
      <Layout title="dashboard" classname="dashboard" navBarRef={this.navBarRef}>
        {this.getComponent(view)}
        {userFormsVisible ? (
          <div className="user-profile-sidebar-2" style={navHeightStyle}>
            <UserForms />
          </div>
        ) : null}

        {sessionStarted ? (
          <div className="session-sidebar p-1" style={navHeightStyle}>
            <SessionDetails />
          </div>
        ) : null}

        {formsVisible ? (
          <div className="user-profile-sidebar-2" style={navHeightStyle}>
            <AllForms />
          </div>
        ) : null}
      </Layout>
    );
  }
}

export default UserProtected;
