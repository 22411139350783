import { types } from "mobx-state-tree";

const EventItem = types.model("EventItem", {
  id: types.number,
  facilitatorId: types.number,
  description: types.maybeNull(types.string),
  outreach: types.maybeNull(types.string),
  endTime: types.maybeNull(types.string),
  zoomInfo: types.maybeNull(types.string),
  recurringType: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  startTime: types.string,
  startHour: types.number,
  startMins: types.number,
  eventDate: types.string,
  endHour: types.number,
  endMins: types.number,
  name: types.maybeNull(types.string),
  siteId: types.maybeNull(types.number),
  facilitatorInfo: types.frozen({
    username: types.string,
    fullName: types.string,
  }),
});

export default EventItem;
