import React from "react";
import { Modal, Button, Row, Col } from "antd";
import { alert } from "../../en.json";

const DeleteRestoreConfirmationModal = (props) => {
    const {
        isConfirmationModalOpen,
        handleOk,
        handleCancel,
        toDelete,
        type
    } = props;
    return (
        <Modal
            title="Alert"
            visible={isConfirmationModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            centered={true}
        >
            <Row>
                <Col span={24}>
                    <p>{`Are you sure you want to ${toDelete ? 'delete' : 'restore'} this ${type}?`}</p>
                </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
                <Col span={24}>
                    <Button key="back" onClick={handleCancel}>
                        {alert.cancel}
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                    >
                        {toDelete ? alert.delete : alert.restore}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default DeleteRestoreConfirmationModal;
