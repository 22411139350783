import React from "react";
import { Modal, Spin } from "antd";
import moment from "moment";
import ProfileStore from "../../stores/ProfileStore";
import HistoryStore from "../../stores/HistoryStore";
import pdfImage from "../../static/images/pdfImage.png";
import InfiniteScroll from "react-infinite-scroller";
import { getIntakeStatus } from "../../utils/getIntakeStatus";

const getTime = (time) => {
  return moment(time).format("MMM Do YYYY, h:mm:ss a");
};

const getReactionType = (reactionId, added) => {
  switch (reactionId) {
    case 1:
      return added ? "liked" : "removed like";
    case 2:
      return added ? "flagged" : "unflagged";
    case 3:
      return added ? "deleted" : "undeleted";
    default:
      return "";
  }
};

const getGroupStatus = (statusId) => {
  switch (statusId) {
    case 1:
      return "activated";
    case 2:
      return "suspended";
    case 3:
      return "paused";
    default:
      return "";
  }
};

const getEvent = (event) => {
  const channel = event.channelType === "GROUP" ? "group" : "direct message";
  switch (event.eventType) {
    case 1:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          added{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          in <span className="channel-name">{event.groupName}</span> {channel} .
        </div>
      );
    case 2:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          removed{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          from <span className="channel-name">{event.groupName}</span> {channel}{" "}
          .
        </div>
      );
    case 3:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          paused{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          in <span className="channel-name">{event.groupName}</span> {channel} .
        </div>
      );
    case 4:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          resumed{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          in <span className="channel-name">{event.groupName}</span> {channel} .
        </div>
      );
    case 5:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          started a {channel} with{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          .
        </div>
      );
    case 6:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          removed{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          from {channel} .
        </div>
      );
    case 7:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          {getReactionType(event.reactionId, true)} the message of{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          in <span className="channel-name">{event.groupName}</span> {channel} .
        </div>
      );
    case 8:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          {getReactionType(event.reactionId, false)} the message of{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          in <span className="channel-name">{event.groupName}</span>
          {channel} .
        </div>
      );
    case 9:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> -</span>
          )}{" "}
          changed the password of{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          .
        </div>
      );
    case 10:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          assigned the alert to{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          .
        </div>
      );
    case 12:
      const { resolvedBy } = event.meta ? JSON.parse(event.meta) : {};
      return (
        <div className="activity-line">
          {resolvedBy && resolvedBy.username ? (
            <span className="source-user">
              {resolvedBy.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          resolved the alert from{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          .
        </div>
      );
    case 13:
      const { unresolvedBy } = event.meta ? JSON.parse(event.meta) : {};
      return (
        <div className="activity-line">
          {unresolvedBy && unresolvedBy.username ? (
            <span className="source-user">
              {unresolvedBy.username}
            </span>
          ) : (
            <span> -</span>
          )}{" "}
          unresolved the alert from{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          .
        </div>
      );
    case 14:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          suspended{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          in <span className="channel-name">{event.groupName}</span> {channel} .
        </div>
      );
    case 15:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          activated{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          in <span className="channel-name">{event.groupName}</span> {channel} .
        </div>
      );
    case 16:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.fullName ? (
            <span className="source-user">
              {event.sourceUserUsername.fullName}
            </span>
          ) : (
            <span> - </span>
          )}
          <span> exported the file</span>
          {/* {event.meta ? (
            <span>
              <img src={pdfImage} className="info-icon" alt="info" />
              <a target="_blank" className="download-link" href={event.meta}>
                download
              </a>
            </span>
          ) : null} */}
        </div>
      );
    case 17:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          updated the form status to{" "}
          {event.meta ? (
            <span className="target-user">
              {getIntakeStatus(Number(event.meta))}
            </span>
          ) : (
            " - "
          )}
        </div>
      );
    case 18:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          deleted{" "}
          {event.meta ? (
            <span className="target-user">{event.meta}</span>
          ) : (
            " - [DELETED]"
          )}
        </div>
      );
    case 19:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          assigned the form to{" "}
          {event.meta ? (
            <span className="target-user">
              {ProfileStore.getUserFromHpUsers(Number(event.meta))}
            </span>
          ) : (
            " - "
          )}
        </div>
      );
    case 20:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          {event.meta ? (
            <span> {getGroupStatus(Number(event.meta))} </span>
          ) : null}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          in <span className="channel-name">{event.groupName}</span> {channel} .
        </div>
      );
    case 21:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          assigned the form to{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}
        </div>
      );
    case 22:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          removed assign from{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}
        </div>
      );
    case 23:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          changed
          {Object.keys(event.metaObj).length
            ? Object.keys(event.metaObj).map((item, index) => {
              return item === "isApproved" ? (
                <span key={index}>
                  <span className="target-field">
                    {" "}
                    [Approved]
                    {Object.keys(event.metaObj).length > 1 ? ", " : " "}
                  </span>
                </span>
              ) : (
                <span key={index}>
                  <span className="target-field"> [{item}] </span>
                  <span>
                    from{" "}
                    <span className="old-field">
                      {" "}
                      {Object.values(event.metaObj)[index].old
                        ? Object.values(event.metaObj)[index].old
                        : " - "}
                    </span>{" "}
                    to{" "}
                    <span className="new-field">
                      {Object.values(event.metaObj)[index].new}
                      {Object.keys(event.metaObj).length > 1 ? ", " : " "}
                    </span>
                  </span>
                </span>
              );
            })
            : null}
          for{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}
        </div>
      );
    case 24:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          assigned{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username} [NEW ACCOUNT]
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          to{" "}
          {event.meta ? (
            <span> {HistoryStore.getAssignedUsername(event.meta)}</span>
          ) : null}
          .
        </div>
      );
    case 25:
      return (
        <div className="activity-line">
          {event.sourceUserUsername && event.sourceUserUsername.username ? (
            <span className="source-user">
              {event.sourceUserUsername.username}
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          removed assigned{" "}
          {event.targetUserUsername && event.targetUserUsername.username ? (
            <span className="target-user">
              {event.targetUserUsername.username} [NEW ACCOUNT]
            </span>
          ) : (
            <span> - </span>
          )}{" "}
          from{" "}
          {event.meta ? (
            <span> {HistoryStore.getAssignedUsername(event.meta)}</span>
          ) : null}
          .
        </div>
      );
    default:
      return null;
  }
};

const getUserOutreachData = (event) => {
  const { sourceUserDetails, targetUserDetails, isSmsOutreach, outreachValue } = event;
  return (
    <div className="activity-line">
      {sourceUserDetails && sourceUserDetails.username ?
        (
          <span className="source-user">
            {sourceUserDetails.username}
          </span>
        ) : (<span> - </span>)}{" "}
      changed the {isSmsOutreach ? 'sms outreach' : 'call outreach'}{" "}
      to {outreachValue ? outreachValue : ""} for
      {targetUserDetails && targetUserDetails.username ? (
        <span className="target-user">
          {" "} {targetUserDetails.username}
        </span>
      ) : (
        <span> - </span>
      )}{" "}
      .
    </div>
  );
}

export const CustomModal = (props) => {
  const {
    title,
    onOk,
    onCancel,
    visible,
    data,
    loadMore,
    hasMore,
    loading,
    isUserOutreachHistory = false
  } = props;
  return (
    <Modal
      width={"900px"}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      visible={visible}
    >
      {data.length ? (
        <div
          className="history-of-activities"
          style={{ height: "350px", overflow: "auto" }}
        >
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            useWindow={false}
          >
            {data.map((event) => (
              <div key={event.id}>
                <div className="activity">
                  <span className="date">{getTime(event.createdAt)}</span>
                  <span>{isUserOutreachHistory ? getUserOutreachData(event) : getEvent(event)}</span>
                </div>
              </div>
            ))}
            {loading ? (
              <div className="form-loading">
                <Spin />
              </div>
            ) : null}
          </InfiniteScroll>
        </div>
      ) : loading ? (
        <div className="form-loading">
          <Spin />
        </div>
      ) : (
        <div className="no-alerts">No history found !</div>
      )}
    </Modal>
  );
};
