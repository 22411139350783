import moment from "moment";
import _ from "lodash";
import { toJS } from "mobx";
import { types } from "mobx-state-tree";

import FormAnswer from "./FormAnswer";
import FormQuestion from "./FormQuestion";
import Form from "./Form";
import Option from "./Option";
import {
  FORM_NAME_APP_ENCOUNTER_TEMPLATE, FORM_NAME_BARC10,
  FORM_NAME_BARC10_NOTE_TEMPLATE,
  FORM_NAME_ENCOUNTER_NOTE_V2, FORM_NAME_INTAKE, FORM_NAME_MENTAL_HEALTH_RECOVERY_MEASURE,
  FORM_NAME_ORIENTATION_NOTE_V2,
  FORM_NAME_OUTREACH_NOTE, FORM_NAME_SDOH_SCREEN,
  SUBMITTED_FROM_TYPE_ADMIN,
  SUBMITTED_FROM_TYPE_GROUP,
  FORM_NAME_ENROLLMENT_NOTE,
  FORM_NAME_APP_ENCOUNTER_TEMPLATE_V2,
  FORM_NAME_ENCOUNTER_NOTE_V3,
  FORM_NAME_BARC10_NOTE_TEMPLATE_V2,
} from "../constants/GlobalConstant";
import { ALIKE_USER } from "../constants/UserRolesConstant";
import AuthStore from "../stores/AuthStore";
import GroupStore from "../stores/Groups";
import DMStore from "../stores/DMs";
import ProfileStore from "../stores/ProfileStore";
import MessagesStore from "../stores/MessagesStore";
import MemberListStore from "../stores/MemberListStore";
import NotificationStore from "../stores/NotificationStore";
import StatsStore from "../stores/StatsStore";
import ApiService from "../utils/ApiService";
import {
  getPubnubInstanceByUserType,
  publishForm,
} from "../utils/PubnubMethods";
import { IS_ISLAND } from "../utils/getEnvironment";
import { getFormattedResponses } from "../utils/getFormattedResponses";
import * as GeneralData from "../utils/GeneralData";
import { userProfile } from "../en.json";

const FormMessage = types
  .model("FormMessage", {
    form: types.map(
      types.frozen({
        name: types.maybeNull(types.string),
        submitCount: types.maybeNull(types.number),
        sentCount: types.maybeNull(types.number),
        minutesNeededToComplete: types.maybeNull(types.number),
        isSubmitted: types.maybeNull(types.boolean),
        userFormId: types.maybeNull(types.number),
        hasError: false,
      })
    ),
    formLoading: false,
    formId: types.maybeNull(types.number),
    userFormId: types.maybeNull(types.number),
    formQuestions: types.array(FormQuestion),
    formAnswers: types.map(types.array(FormAnswer)),
    allForms: types.array(Form),
    formList: types.array(
      types.frozen({
        createdAt: types.string,
        id: types.number,
        minutesNeededToComplete: types.maybeNull(types.number),
        name: types.string,
        responseCount: types.maybeNull(types.number),
        updatedAt: types.maybeNull(types.string),
      })
    ),
    formsListData: types.array(
      types.frozen({
        createdAt: types.string,
        id: types.number,
        minutesNeededToComplete: types.maybeNull(types.number),
        name: types.string,
        responseCount: types.maybeNull(types.number),
        updatedAt: types.maybeNull(types.string),
      })
    ),
    formsVisible: false,
    userFormsVisible: false,
    isFormPendingWizardVisible: false,
    isFormPendingConfirmModalVisible: false,
    loading: false,
    selectedForm: types.frozen({
      name: types.maybeNull(types.string),
      id: types.maybeNull(types.number),
      timeToken: types.maybeNull(types.string),
    }),
    isAdminFillingFormForUser: false,
    isDocumentFormLoading: false,
    skipFormsInListing: 0,
    totalFormsInListing: 0,
    totalScore: 0,
    lowestScoringDomain: types.maybeNull(types.string),
    highestScoringDomain: types.maybeNull(types.string),
    touchpointDate: types.maybeNull(types.string),
    touchpointMedium: types.maybeNull(types.string),
    touchpointChannel: types.maybeNull(types.string),
    userGoalsAnswer: types.maybeNull(types.string),
    isSubmitLoading: false,
    userFormsStateCopy: types.maybeNull(types.frozen({})),
    userFormData: types.maybeNull(types.frozen({})),
    formOpenMethod: types.maybeNull(types.string),
    formStartTime: types.maybeNull(types.number)
  })
  .actions((self) => ({
    resetForms() {
      self.allForms = [];
    },
    resetFormsListData() {
      self.formsListData = [];
      self.skipFormsInListing = 0;
      self.totalFormsInListing = 0;
    },
    resetSelection() {
      self.formQuestions = [];
      self.formAnswers = [];
    },
    resetPrefilledAnswers() {
      self.totalScore = 0;
      self.lowestScoringDomain = "";
      self.highestScoringDomain = "";
    },
    resetTouchpointDataForDocument() {
      self.touchpointDate = "";
      self.touchpointMedium = "";
      self.touchpointChannel = "";
      self.userGoalsAnswer = "";
    },
    async getAllForms(messageId) {
      let skip = 0,
        total = 1;
      self.setLoading(true);
      while (skip < total) {
        const params = {
          messageId: messageId,
          include: true,
          $skip: skip,
        };
        const response = await ApiService.getRequest("user-forms", params);
        if (response.success) {
          total = response.meta.total;
          skip = skip + 10;
          self.addForms(response.data);
          self.changeformsVisible(true);
        }
      }
      self.setLoading(false);
    },
    async fetchAllForms() {
      const params = {
        include: true,
        $limit: 20
      };

      let formResponse = await GeneralData.get(
        GeneralData.KEYS.FORMS
      )

      if (!formResponse) {
        formResponse = await ApiService.getRequest("forms", params);
      }

      if (formResponse.success) {
        self.setFormList(_.cloneDeep(formResponse.data));
      }
    },
    async fetchAllFormsInListing(params = {}) {
      params = {
        ...params,
        include: true,
        $skip: self.skipFormsInListing
      };
      self.setLoading(true);
      const formResponse = await ApiService.getRequest("forms", params);
      if (formResponse.success) {
        self.updateFormListingMeta(_.cloneDeep(formResponse.meta));
        self.setFormListForListing(_.cloneDeep(formResponse.data));
      }
      self.setLoading(false);
    },
    setFormList(formList) {
      self.formList = self.getFormsInOrder(formList);
    },
    setFormListForListing(formsData) {
      let allFetchedForms = [...self.formsListData];

      if (formsData && formsData.length) {
        allFetchedForms = [...allFetchedForms, ...formsData];
      }

      self.formsListData = self.getFormsInOrder(allFetchedForms);
    },
    getFormsInOrder(forms) {
      const filteredFormsMap = {};

      forms.forEach(form => {
        if (!form.name.includes('Hidden') && !form.name.includes('Deprecated')) {
          filteredFormsMap[form.name] = form;
        }
      });

      const formNamesInOrder = [
        FORM_NAME_APP_ENCOUNTER_TEMPLATE,
        FORM_NAME_OUTREACH_NOTE,
        FORM_NAME_ENCOUNTER_NOTE_V2,
        FORM_NAME_ORIENTATION_NOTE_V2,
        FORM_NAME_BARC10_NOTE_TEMPLATE,
        FORM_NAME_INTAKE,
        FORM_NAME_SDOH_SCREEN,
        FORM_NAME_BARC10,
        FORM_NAME_MENTAL_HEALTH_RECOVERY_MEASURE,
        FORM_NAME_ENROLLMENT_NOTE,
        FORM_NAME_APP_ENCOUNTER_TEMPLATE_V2,
        FORM_NAME_ENCOUNTER_NOTE_V3,
        FORM_NAME_BARC10_NOTE_TEMPLATE_V2
      ];

      return formNamesInOrder.map(name => filteredFormsMap[name]).filter(v => !!v);
    },
    setIsAdminFillingTheForm(value) {
      self.isAdminFillingFormForUser = value;
    },
    setIsDocumentFormLoading(value) {
      self.isDocumentFormLoading = value;
    },
    setTouchpointDataForDocument(data) {
      const { touchpointDate, touchpointMedium, touchpointChannel } = data;
      self.touchpointDate = touchpointDate;
      self.touchpointMedium = touchpointMedium;
      self.touchpointChannel = touchpointChannel;
    },
    setUserGoalsAnswer(value) {
      self.userGoalsAnswer = value;
    },
    updateFormListingMeta(meta) {
      self.skipFormsInListing = self.skipFormsInListing + meta.limit;
      self.totalFormsInListing = meta.total;
    },
    async sendFormInGroup(formId) {
      const {
        selectedGroup: { channel, id },
      } = MessagesStore;
      const channelType = channel.split("_")[0];
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const channelId = id;
      const total = await self.checkIfSentAtSameDay(
        formId,
        channelId,
        channelType
      );
      if (!total) {
        publishForm(pubnub, formId);
      } else {
        NotificationStore.setNotification("error", userProfile.formSendError);
        return;
      }
    },

    async checkIfSentAtSameDay(formId, channelId, channelType) {
      const date = moment(new Date()).format("YYYY-MM-DD");
      const lowerEnd = date + "T00:00:00.000Z";
      const UpperEnd = date + "T23:59:59.999Z";
      const params = {
        formId,
        "createdAt[$lt]": UpperEnd,
        "createdAt[$gt]": lowerEnd,
        submittedFrom: channelType,
        submittedFromId: channelId,
      };
      const response = await ApiService.getRequest("user-forms", params);
      if (response.success) {
        return response.meta.total;
      }
    },
    saveUserFormsForDms(formId, channel, response) {
      let userFormPromises = [];
      const channelArr = channel.split("_");
      const channelType = channelArr[0];
      const channelId = channelArr[2];
      const selfId = AuthStore.userId;
      const { DmUsers } = DMStore;
      if (DmUsers.has(channelId)) {
        const users = DmUsers.get(channelId);
        users.forEach((user) => {
          if (selfId !== user.userId) {
            if (user.userType === "user") {
              userFormPromises.push(
                self.saveUserFormService({
                  userId: user.userId,
                  formId,
                  submittedFrom: channelType,
                  submittedFromId: channelId,
                  messageId: response.timetoken,
                })
              );
            }
          }
        });
      }

      return userFormPromises;
    },
    saveUserFormsForWaitingRoom(formId, channel, response) {
      let userFormPromises = [];
      const channelArr = channel.split("_");
      const channelType = channelArr[0];
      const userId = channelArr[2];
      userFormPromises.push(
        self.saveUserFormService({
          userId,
          formId,
          submittedFrom: channelType,
          submittedFromId: userId,
          messageId: response.timetoken,
        })
      );

      return userFormPromises;
    },
    saveUserFormsForGroup(formId, channel, response) {
      let userFormPromises = [];
      const { groupUsers } = GroupStore;
      const channelArr = channel.split("_");
      const channelType = channelArr[0];
      const channelId = channelArr[2];
      groupUsers.forEach((userInGroup) => {
        let isUser = false;
        if (userInGroup.userType === null || userInGroup.userType === "user") {
          isUser = true;
        }
        if (isUser) {
          userFormPromises.push(
            self.saveUserFormService({
              userId: userInGroup.userId,
              formId,
              submittedFrom: channelType,
              submittedFromId: channelId,
              messageId: response.timetoken,
            })
          );
        }
      });

      return userFormPromises;
    },
    async saveUserFormService(data) {
      const endPoint = `user-forms`;
      const params = { ...data };
      return ApiService.postRequest(endPoint, params);
    },
    getGroupType() {
      const {
        selectedGroup: { channel },
      } = MessagesStore;
      return _.startsWith(channel, "GROUP_CHAT")
        ? "GROUP"
        : _.startsWith(channel, "WAITING_ROOM")
          ? "WAITING"
          : "DIRECT";
    },
    async getUserForms(formIds) {
      const {
        selectedGroup: { id },
      } = MessagesStore;
      const { userId, type: userType } = AuthStore;
      const groupType = self.getGroupType();
      let promises = [];
      formIds.forEach((form) => {
        const formId = form.formId;
        const messageId = form.timetoken;

        const userFormParams = {
          formId,
          messageId,
          userId,
          submittedFromId: id,
          submittedFrom: groupType,
        };

        const endPoint = "user-forms";
        promises.push(ApiService.getRequest(endPoint, userFormParams));
      });

      Promise.all(promises).then((res) => {
        if (ALIKE_USER.includes(userType) && groupType === SUBMITTED_FROM_TYPE_GROUP) {
          formIds.forEach((item, index) => {
            const responseData = res[index].data;
            if (responseData && responseData.length) {
              const userFormObj = responseData.find((obj) => obj.userId === userId);
              if (userFormObj) {
                const { id: userFormId, isSubmitted } = userFormObj;
                self.setFormData("isSubmitted", item.timetoken, isSubmitted ? isSubmitted : false);
                self.setFormData("userFormId", item.timetoken, userFormId ? userFormId : null);
              } else {
                self.setFormData("isSubmitted", item.timetoken, false);
              }
            } else {
              self.setFormData("isSubmitted", item.timetoken, false);
            }
          });
        } else {
          formIds.forEach((item, index) => {
            if (res[index].data[0]) {
              self.setFormData(
                "isSubmitted",
                item.timetoken,
                res[index].data[0].isSubmitted
                  ? res[index].data[0].isSubmitted
                  : false
              );
              const userFormId =
                res[index].data && res[index].data[0]
                  ? res[index].data[0].id
                  : null;
              self.setFormData("userFormId", item.timetoken, userFormId);
            } else {
              self.setFormData("isSubmitted", item.timetoken, false);
            }
          });
        }
      });
    },
    changeformsVisible(value) {
      self.formsVisible = value;
    },
    setLoading(value) {
      self.loading = value;
    },
    setTotalScore(value) {
      self.totalScore = value;
    },
    setLowestScoringDomain(value) {
      self.lowestScoringDomain = value;
    },
    setHighestScoringDomain(value) {
      self.highestScoringDomain = value;
    },
    addForms(data) {
      self.allForms = [
        ...self.allForms,
        ...data.map((item) => {
          self.getFormAnswers(item.id);
          return Form.create(item);
        }),
      ];
    },
    async getFormAnswers(userFormId) {
      const params = {
        userFormId: userFormId,
        include: true,
      };
      self.setFormLoading(true);
      const response = await ApiService.getRequest(
        "form-question-responses",
        params
      );
      if (response.success && response.data.length) {
        self.setFormAnswers(userFormId, response.data);
        self.setFormLoading(false);
      }
    },
    async getFormQuestionsMod(formId) {
      self.setFormLoading(true);
      const path = `forms/${formId}`;
      const formQuestions = await ApiService.getRequest(path, {
        include: true,
        includeOnly: "form-questions",
      });
      if (formQuestions.success) {
        self.setFormQuestions(formQuestions.data.questions);
      }
      self.setFormLoading(false);
    },
    async getFormQuestions(formId, timetoken, name) {
      self.setLoading(true);
      const path = `forms/${formId}`;
      const formQuestions = await ApiService.getRequest(path, {
        include: true,
        includeOnly: "form-questions",
      });
      if (formQuestions.success) {
        self.setFormQuestions(formQuestions.data.questions);
        self.setSelectedForm(formId, timetoken, name);
        self.changeUserformsVisible(true);
      }
      self.setLoading(false);
    },
    isLoading(timetoken) {
      if (self.form.has(timetoken)) {
        const form = self.form.get(timetoken);
        return form.name &&
          String(form.submitCount) &&
          String(form.sentCount) &&
          typeof form.isSubmitted === "boolean"
          ? false
          : true;
      } else {
        return true;
      }
    },

    setSelectedForm(formId, timetoken, name) {
      self.selectedForm = {
        name: name,
        timeToken: timetoken,
        id: formId,
      };
    },
    changeUserformsVisible(value) {
      self.userFormsVisible = value;
    },
    setIsFormPendingWizardVisible(value) {
      self.isFormPendingWizardVisible = value;
    },
    setIsFormPendingConfirmModalVisible(value) {
      self.isFormPendingConfirmModalVisible = value;
    },
    setUserFormsStateCopy(data) {
      self.userFormsStateCopy = data;
    },
    setUserFormData(data) {
      self.userFormData = data;
    },
    setFormOpenMethod(value) {
      self.formOpenMethod = value;
    },
    setFormStartTime(value) {
      self.formStartTime = value;
    },

    setFormIdFormUserId(formId, userFormId) {
      self.formId = parseInt(formId);
      self.userFormId = parseInt(userFormId);
    },
    setUserFormId(userFormId) {
      self.userFormId = userFormId;
    },
    setFormQuestions(formQuestions) {
      self.formQuestions = formQuestions.map((item) => {
        FormQuestion.options = item.options.map((option) => {
          return Option.create(option);
        });
        return FormQuestion.create(item);
      });
    },
    setFormAnswers(userFormId, formAnswers) {
      if (self.formAnswers.has(userFormId)) {
        return;
      } else {
        const formAnswersArr = formAnswers.map((item) => {
          return FormAnswer.create(item);
        });
        self.formAnswers.set(userFormId, formAnswersArr);
      }
    },
    setFormLoading(value) {
      self.formLoading = value;
    },
    setIsSubmitLoading(value) {
      self.isSubmitLoading = value;
    },
    async getFormName(formIds) {
      let promises = [];

      formIds.forEach((form) => {
        const formNameParams = {
          "$select[1]": "name",
          "$select[2]": "minutesNeededToComplete",
        };
        const endPoint = `forms/${form.formId}`;
        promises.push(ApiService.getRequest(endPoint, formNameParams));
      });
      Promise.all(promises)
        .then((res) => {
          formIds.forEach((item, index) => {
            self.setFormData("name", item.timetoken, res[index].data.name);
            self.setFormData(
              "minutesNeededToComplete",
              item.timetoken,
              res[index].data.minutesNeededToComplete
            );
          });
        })
        .catch((err) => {
          formIds.forEach((item, index) => {
            self.setFormData("hasError", item.timetoken, true);
          });
        });
    },
    setFormData(key, timetoken, formDetails) {
      if (self.form.has(timetoken)) {
        const oldFormData = self.form.get(timetoken);
        self.form.set(timetoken, {
          ...oldFormData,
          [key]: formDetails,
        });
      } else {
        self.form.set(timetoken, {
          [key]: formDetails,
        });
      }
    },
    async getCountOfFormSubmittedByUserService(formIds) {
      let promises = [];

      formIds.forEach((form) => {
        const endPoint = `user-forms?messageId=${form.timetoken}&isSubmitted=true&$limit=0`;
        promises.push(ApiService.getRequest(endPoint));
      });
      Promise.all(promises)
        .then((res) => {
          formIds.forEach((item, index) => {
            self.setFormData(
              "submitCount",
              item.timetoken,
              res[index].meta.total
            );
          });
        })
        .catch((err) => {
          formIds.forEach((item, index) => {
            self.setFormData("hasError", item.timetoken, true);
          });
        });
    },
    async updateUserFormResponses(
      multipleChoices,
      multipleChoicesRadio,
      descriptive,
      datePicker
    ) {
      const {
        selectedGroup: { id },
      } = MessagesStore;
      const { isAdminFillingFormForUser, userFormId, form, selectedForm, userFormsStateCopy = {} } = self;
      const { id: formId, timeToken: messageId } = selectedForm;
      const { profileUserId = null } = userFormsStateCopy;
      // If admin fill form for user then we have to take the user profile id
      const userId = isAdminFillingFormForUser ? (ProfileStore.userId || profileUserId) : AuthStore.userId;
      // If admin fill form for user then group type will be "ADMIN"  
      const groupType = isAdminFillingFormForUser ? SUBMITTED_FROM_TYPE_ADMIN : self.getGroupType();
      // If admin fill form for user then channel id will be user id (Taking reference from cbrs referral screen)
      const channelId = isAdminFillingFormForUser ? AuthStore.userId : id;
      // Here if ADMIN fills the form then value will be taken from userFormId
      // If USER fills the form then value will be taken from form.get(messageId).userFormId
      const userFormIdForMeta = userFormId ? userFormId : form.get(messageId).userFormId;
      try {
        // Here we will create a meta object that contains all the common details related to the user forms
        const metaInfo = {
          channelId,
          formId,
          userId: Number(userId),
          userFormId: userFormIdForMeta,
          messageId,
          responseFrom: groupType,
        };
        // set is submit button loading true
        self.setIsSubmitLoading(true);
        // Here we are preparing a payload array that contains formQuestionId, formQuestionOptionId and answer 
        const payloadArr = getFormattedResponses(descriptive, multipleChoices, multipleChoicesRadio, datePicker);
        // Here we are submitting all question responses in a single api call
        const response = await ApiService.postRequest('create-response', { meta: metaInfo, payload: payloadArr });
        const { success } = response;
        if (success) {
          self.setFormLoading(true);
          self.setFormData("isSubmitted", messageId, true);
          self.changeUserformsVisible(false);
          NotificationStore.setNotification("success", userProfile.formSubmitSuccess);

          if (isAdminFillingFormForUser) {
            // After form is submitted, we need to fetch new filled form for user profile
            const member = await MemberListStore.getMemberDetails(userId);
            ProfileStore.setMemberData(member);
            // If admin fills the form from forms screen in stats page then we also need to fetch new filled forms
            await StatsStore.getFormsData();
          }
          // After form is submitted, we can change the admin flag to false 
          self.setIsAdminFillingTheForm(false);
          self.setFormLoading(false);
          // After form is submitted, we can change the userFormId to null
          self.setUserFormId(null);
          self.setIsSubmitLoading(false);
          return;
        }
      } catch (error) {
        self.setFormData("hasError", messageId, true);
        self.setIsSubmitLoading(false);
        NotificationStore.setNotification("error", userProfile.formSubmitError);
      }
    },
    async getCountOfFormSentToUsersService(formIds) {
      let promises = [];
      formIds.forEach((form) => {
        const endPoint = `user-forms?messageId=${form.timetoken}&$limit=0`;
        promises.push(ApiService.getRequest(endPoint));
      });
      Promise.all(promises)
        .then((res) => {
          formIds.forEach((item, index) => {
            self.setFormData(
              "sentCount",
              item.timetoken,
              res[index].meta.total
            );
          });
        })
        .catch((err) => {
          formIds.forEach((item, index) => {
            self.setFormData("hasError", item.timetoken, true);
          });
        });
    },
  }));
export default FormMessage;
