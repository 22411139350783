import React from "react";
import { Spin } from "antd";
import { inject, observer } from "mobx-react";
import { CBRS } from "../../en.json";

@inject("store")
@observer
class InviteAdmin extends React.Component {
  startMemberInvite = () => {
    const {
      store: {
        ReferralStore: { sendAdminInvite },
      },
    } = this.props;
    sendAdminInvite();
  };

  changeValue = (e) => {
    const {
      store: {
        ReferralStore: { setClientDetails },
      },
    } = this.props;
    setClientDetails(e.target.getAttribute("data-field"), e.target.value);
  };

  render() {
    const {
      store: {
        ReferralStore: {
          sendAdminInviteDisabled,
          invitedAdminName,
          invitedAdminEmail,
          sendingInvite,
        },
      },
    } = this.props;

    return (
      <div>
        <div className="referral-section">
          <h1>Invite Admin</h1>
          <div className="start-referral text-left">
            Put in the requested information, and the user will be sent an
            email.
          </div>
          <div className="referral-box">
            <div className="refer-input-container">
              <div className="invite-sms">{CBRS.name}</div>
              <input
                onChange={this.changeValue}
                placeholder="Full Name"
                value={invitedAdminName}
                className="referral-input"
                data-field="invitedAdminName"
              />
              <div className="invite-sms">{CBRS.email}</div>
              <input
                onChange={this.changeValue}
                placeholder="Email"
                value={invitedAdminEmail}
                className="referral-input"
                data-field="invitedAdminEmail"
              />
            </div>
          </div>
          {sendingInvite ? (
            <div className="form-loading">
              <Spin />
            </div>
          ) : null}
          <div className="send-referral">
            <button
              disabled={sendAdminInviteDisabled || sendingInvite}
              className="new-referral-button"
              onClick={() => this.startMemberInvite()}
            >
              Send Invite
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default InviteAdmin;
