import React, { useRef } from 'react';
import { Col, Modal, Row, Carousel, Button } from 'antd';
import "video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';
import { inject, observer } from 'mobx-react';

import { FEED_IMAGE_URL } from '../../constants/GlobalConstant';

export const ViewPostMediaModal = inject("store")(observer((props) => {
    const {
        isModalVisible,
        title,
        videoUrl,
        setIsModalVisible,
        modalImages,
        firstImageIndex,
    } = props.store.ForumFeedStore;
    const carouselRef = useRef();

    return <Modal
        width={"1280px"}
        title={title}
        onCancel={() => setIsModalVisible(false)}
        visible={isModalVisible}
    >
        <Row>
            <Col span={24}>
                {videoUrl ?
                    <Player
                        playsInline
                        // poster="/assets/poster.png"
                        src={videoUrl}
                    />
                : (modalImages?.length > 0) &&
                <Row id="modal-carousel-container">
                    {
                        modalImages.length > 1 && <Col span={2}>
                            <Button
                                className='carousel-btns'
                                shape='circle'
                                onClick={() => carouselRef.current.prev()}
                            >{"<"}</Button>
                        </Col>
                    }
                    <Col span={20}>
                        <Carousel 
                            id="modal-carousel"
                            ref={ref => {
                                carouselRef.current = ref;
                                if (carouselRef?.current) {
                                    carouselRef.current.goTo(firstImageIndex);
                                }
                            }}
                            style={{ marginTop: 15 }}
                            dots={false}
                        >
                            {modalImages?.map((item) => {
                                return (
                                    <div>  
                                        <img
                                            src={`${FEED_IMAGE_URL}${item?.url}`}
                                            alt={'Feed image'}
                                            style={{ 
                                                height: '70vh', 
                                                width: '100%', 
                                                objectFit: 'contain' 
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </Carousel >
                    </Col>
                    {
                        modalImages.length > 1 && <Col id="next-btn-container" span={2}>
                            <Button 
                                className='carousel-btns'
                                shape='circle'
                                onClick={() => carouselRef.current.next()}
                            >{">"}</Button>
                        </Col>
                    }
                </Row>
            }
            </Col>
        </Row>
    </Modal>
}));