import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FEED_IMAGE_URL, IMAGE_CONTENT_TYPE, MAX_UPLOAD_IMAGE_SIZE } from '../../constants/GlobalConstant';
import { Col, Row, notification } from 'antd';
import { DISCARD, UPLOAD_FEED_IMAGE } from '../../utils/ImageUtils';

const validateMimeType = (mimetype, arr, key) => {
    // validate image content type
    if (!arr.includes(mimetype)) {
        return true;
    }
    return false;
}

const validateMediaSize = (mediaSize, key) => {
    // validate image size should not be greater than 10mb
    if ((mediaSize > MAX_UPLOAD_IMAGE_SIZE)) {
        return true;
    }
    return false;
}

const DragAndDrop = (props) => {
    const { primaryText, uploadImages, onDiscard } = props;

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        // validate image type
        const notImages = acceptedFiles.filter(f => validateMimeType(f.type, IMAGE_CONTENT_TYPE, 'image'));
        if(notImages.length || rejectedFiles?.length) {
            notification.error({
                message: 'Error',
                description: `Invalid media type. We exclusively endorse PNG, JPEG, and GIF formats.`,
                placement: 'bottomRight',
            });
            return;
        }

        // validate image max size
        const largeImages = acceptedFiles.filter(f => validateMediaSize((f.size / 1048576).toFixed(2), 'image'));
        if(largeImages.length) {
            alert(`Image size must be less than ${MAX_UPLOAD_IMAGE_SIZE} mb`);
            return;
        }

        props.onDrop(acceptedFiles);
    }, [])
    const { getRootProps, getInputProps, isDragActive = true, } = useDropzone({
        onDrop,
        multiple: true,
        accept: {
            'image/*': [],
        }
    })

    return (
        <div {...getRootProps()} className="drag-and-drop" style={{ zIndex: 1, position: 'relative' }}>
            {(uploadImages?.length === 0 || isDragActive) && <p className={'drag-and-drop-placeholder'}>We exclusively endorse PNG, JPEG, and GIF formats.</p>}
            <input {...getInputProps()} />
            {isDragActive 
                ? 
                <>
                    <img
                        src={UPLOAD_FEED_IMAGE}
                        alt={'feed upload'}
                    />
                    <span className={'feed-drag-and-drop-text'}>Drop images here...</span>
                </>
                :
                ((uploadImages?.length > 0) ?
                    <>
                        <Row style={{ width: "100%" }}>
                            <Col span={24} style={{ display: 'flex', paddingLeft: 10 }}>
                                {uploadImages?.map((file, index) => {
                                    return (
                                        <>
                                            <div className="image-container-create-post">
                                                <img
                                                    style={{
                                                        position: "absolute",
                                                        top: -7, right: 0,
                                                        marginRight: -7,
                                                        cursor: "pointer",
                                                    }}
                                                    {...getRootProps({
                                                        onClick: event => {
                                                            event.stopPropagation();
                                                            onDiscard(index);
                                                        }
                                                    })}
                                                    src={DISCARD}
                                                    alt={'Feed'}
                                                    />
                                                <img
                                                    key={file?.url}
                                                    src={`${FEED_IMAGE_URL}${file?.url}`}
                                                    alt={'Feed'}
                                                    className='create-post-image'
                                                />
                                            </div>
                                        </>
                                    );
                                })}
                            </Col>
                        </Row>
                    </>
                :
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Row>
                            <Col span={24}>
                                <img
                                    src={UPLOAD_FEED_IMAGE}
                                    alt={'feed upload'}
                                />
                                <span className={'feed-drag-and-drop-text'}>{primaryText}</span>
                            </Col>
                        </Row>
                    </div>
                )    
            }
        </div>
    )
}

export default DragAndDrop;