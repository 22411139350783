import { Icon, Popover, Spin, Tabs } from "antd";
import { alertLeftSide, user } from "../../en.json";
import { inject, observer } from "mobx-react";

import ContentList from "./ContentList";
import OptionList from "./OptionList";
import InfiniteScroll from "react-infinite-scroller";
import SingleAlert from "./SingleAlert";
import history from "../../utils/history";
import React from "react";
import _ from "lodash";
import { IS_HP } from "../../utils/getEnvironment";
import moment from "moment";
import { CustomModal } from "../UI/CustomModal";
import info from "../../static/images/info.png";
import SiteStore from "../../stores/SiteStore";
import SingleForumAlert from "./SingleForumAlert";
import SingleForumDeletedItem from "./SingleForumDeletedItem";

const TabPane = Tabs.TabPane;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

@inject("store")
@observer
class AlertLeftSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  getAssignedUsernames = (id) => {
    const {
      store: {
        FlaggedMessagesStore: { assignedAlerts },
      },
    } = this.props;
    const usernames = assignedAlerts.get(id);
    let usernameString = "";
    if (usernames.length) {
      usernameString = usernames.join(", ");
    }
    return usernameString.length ? usernameString : null;
  };

  getAssignedAdmins = (id) => {
    const {
      store: {
        MemberListStore: { assignedUsers },
      },
    } = this.props;
    const usernames = assignedUsers.get(id);
    let usernameString = "";
    if (usernames.length) {
      usernameString = usernames.join(", ");
    }
    return usernameString.length ? usernameString : null;
  };

  changeModalVisible = (value) => {
    this.setState({
      modalVisible: value,
    });
  };

  fetchAlertHistory = (alertId, groupName) => {
    const {
      store: {
        HistoryStore: { fetchMessageHistory, resetHistory, historyLoading },
      },
    } = this.props;

    fetchMessageHistory(alertId, groupName);
    this.changeModalVisible(true);
  };

  loadMoreAlertHistory = () => {
    const {
      store: {
        HistoryStore: {
          selectedAlertId,
          selectedGroupName,
          fetchMessageHistory,
          historyLoading,
        },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchMessageHistory(selectedAlertId, selectedGroupName);
  };

  usernamePress = async (sender) => {
    const {
      store: {
        ProfileStore: {
          setProfileVisibility,
          setMemberData,
          toggleLabel,
          changeSelectedAction,
          userId,
          getHighPointUsers,
          getProviderModeratedGroups,
          getContactModeratedGroups,
          fetchGroupsFromForms,
          reset,
        },
        NotificationStore,
        DeletedMessagesStore: { setShowDeleted },
        MemberListStore: { getMemberDetails },
      },
    } = this.props;
    if (sender === userId) {
      changeSelectedAction("");
      return;
    }
    reset();
    setProfileVisibility(true);
    setShowDeleted(false);
    const member = await getMemberDetails(sender);
    if (member) {
      setMemberData(member);
      if (IS_HP) {
        getHighPointUsers();
        if (member.providerId) {
          getProviderModeratedGroups(member.providerId);
        }
        if (member.recommendedBy) {
          getContactModeratedGroups(member.recommendedBy);
        }
        fetchGroupsFromForms();
      }
      toggleLabel(false);
    } else {
      NotificationStore.setNotification("error", user.userDoesNotExist);
    }
  };

  viewProfilePress = async (sender) => {
    const {
      store: {
        ProfileStore: {
          setProfileVisibility,
          setMemberData,
          toggleEdit,
          toggleLabel,
          getHighPointUsers,
          getProviderModeratedGroups,
          getContactModeratedGroups,
          fetchGroupsFromForms,
          setPreviousData,
          changeSelectedAction,
          userId,
          reset,
        },
        MemberListStore: { getMemberDetails },
      },
    } = this.props;
    if (sender === userId) {
      changeSelectedAction("");
      return;
    }
    reset();
    setProfileVisibility(true);
    const member = await getMemberDetails(sender);
    setMemberData(member);
    setPreviousData();
    if (IS_HP) {
      getHighPointUsers();
      if (member.providerId) {
        getProviderModeratedGroups(member.providerId);
      }
      if (member.recommendedBy) {
        getContactModeratedGroups(member.recommendedBy);
      }
      fetchGroupsFromForms();
    }
    toggleEdit("editClinical", true);
    toggleEdit("editBio", true);
    toggleLabel(false);
  };

  loadMore = () => {
    const {
      store: {
        MemberListStore: {
          unApprovedMembers,
          fetchUnApprovedMembers,
          skipUnApproved,
          skipApproved,
          fetchApprovedMembers,
          unApprovedMembersTotal,
        },
        SiteStore: { selectedSiteId },
      },
    } = this.props;
    const siteId = selectedSiteId;

    if (unApprovedMembers.length >= unApprovedMembersTotal) {
      skipApproved();
      if (siteId !== 0 && siteId !== -1) {
        const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
        fetchApprovedMembers(siteDataObj);
      } else {
        fetchApprovedMembers();
      }
    } else {
      skipUnApproved();
      if (siteId !== 0 && siteId !== -1) {
        const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
        fetchUnApprovedMembers(siteDataObj);
      } else {
        fetchUnApprovedMembers();
      }
    }
  };

  loadMoreFlaggedMessages = () => {
    const {
      store: {
        FlaggedMessagesStore: {
          fetchFlaggedMessagesSummary,
          endOfUnresolvedFlaggedMessageList,
          loading,
          unresolvedFlaggedMessages
        },
        SiteStore: { selectedSiteId },
      },
    } = this.props;
    const siteId = selectedSiteId;

    // To prevent refetching of flagged alerts
    if (loading) return;
    if (endOfUnresolvedFlaggedMessageList) {
      if (siteId !== 0 && siteId !== -1) {
        const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
        fetchFlaggedMessagesSummary({ isResolved: true, ...siteDataObj }, true);
      } else {
        fetchFlaggedMessagesSummary({ isResolved: true }, true);
      }
    } else {
      if (siteId !== 0 && siteId !== -1) {
        const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
        fetchFlaggedMessagesSummary({ isResolved: false, ...siteDataObj }, true);
      } else {
        fetchFlaggedMessagesSummary({ isResolved: false }, true);
      }
    }
    // endOfUnresolvedFlaggedMessageList
    //   ? fetchFlaggedMessagesSummary({ isResolved: true })
    //   : fetchFlaggedMessagesSummary({ isResolved: false });
  };

  loadMoreDeletedItems = () => {
    const {
      store: {
        DeletedForumItemListStore: {
          loading,
          endOfDeletedForumItemsList,
          fetchDeletedForumItems,
        },
      }
    } = this.props;
      if (loading) return
      if (!endOfDeletedForumItemsList) fetchDeletedForumItems()
    }

  showUnApprovedMembers = () => {
    const {
      store: {
        MemberListStore: {
          unApprovedMembers,
          unApprovedMembersTotal,
          approvedMembersTotal,
          approvedMembers,
          loading,
          setSelectedUserId,
          assignedUsers,
        },
        BroadcastStore: { createDm },
        ProfileStore: { userId },
        SiteStore: { getSiteNameFromAllSites },
      },
    } = this.props;

    const options = ["View Profile", "Assign to"];
    return (
      <div style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
        <InfiniteScroll
          loadMore={this.loadMore}
          useWindow={false}
          hasMore={
            unApprovedMembers.length + approvedMembers.length <
              unApprovedMembersTotal + approvedMembersTotal && !loading
          }
        >
          {unApprovedMembers.map((member) => (
            <div key={member.id}>
              <div
                className="flag-container"
                style={
                  member.id === userId && this.isSelected()
                    ? { border: "5px solid #4168ca" }
                    : null
                }
              >
                <div className="flag-upper">
                  <div
                    className="flag-name-container"
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <span className="flag-name">
                        {alertLeftSide.newAccount}
                      </span>
                    </div>
                  </div>
                  <div className="flag-time">
                    {moment(member.createdAt).calendar()}
                  </div>
                </div>
                <div className="flag-middle">
                  <span className="heading">
                    {alertLeftSide.pleaseVerify}{" "}
                    <span
                      onClick={() => this.usernamePress(member.id)}
                      className="flag-user-name"
                    >
                      {member.username}
                    </span>
                    <span>
                      {member.siteId
                        ? ` ${
                            IS_HP
                              ? alertLeftSide.isPatientHighPoint
                              : alertLeftSide.isPatientCleanSlate
                          } 
                          ${getSiteNameFromAllSites(member.siteId)}.`
                        : null}
                    </span>
                  </span>
                  <br />
                  {assignedUsers.has(member.id) ? (
                    <div className="subHeading">
                      {`Assigned to ${this.getAssignedAdmins(member.id)}`}
                    </div>
                  ) : null}
                </div>
                <div className="flag-lower">
                  <div className="flag-buttons">
                    <button
                      className="flag-message-button"
                      type="button"
                      onClick={() => this.viewProfilePress(member.id)}
                    >
                      {alertLeftSide.viewProfile}
                    </button>
                    <button
                      className="flag-resolve-button"
                      onClick={() => createDm(member.username, member.id)}
                    >
                      {alertLeftSide.dm}
                    </button>
                  </div>
                  <div
                    className="flag-div"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedUserId(member.id);
                    }}
                  >
                    <Popover
                      content={this.renderOptions(options, member)}
                      placement="right"
                      trigger="click"
                    >
                      <Icon
                        className="flag-icon"
                        type="more"
                        style={{ fontSize: 30 }}
                      />
                    </Popover>
                  </div>
                </div>
              </div>
              <hr className="horizontal-tag" />
            </div>
          ))}
          {unApprovedMembers.length >= unApprovedMembersTotal ? (
            <div className="approved-users">
              {alertLeftSide.approvedMembers}
            </div>
          ) : null}
          {unApprovedMembers.length >= unApprovedMembersTotal
            ? approvedMembers.map((member) => (
                <div key={member.id}>
                  <div
                    className="flag-container"
                    style={
                      member.id === userId && this.isSelected()
                        ? { border: "5px solid #4168ca" }
                        : null
                    }
                  >
                    <div className="flag-upper">
                      <div
                        className="flag-name-container"
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <span className="flag-name">
                            {alertLeftSide.newAccount}
                          </span>
                        </div>
                      </div>
                      <div className="flag-time">
                        {moment(member.createdAt).calendar()}
                      </div>
                    </div>
                    <div className="flag-middle">
                      <span className="heading">
                        {alertLeftSide.pleaseVerify}{" "}
                        <span
                          onClick={() => this.usernamePress(member.id)}
                          className="flag-user-name"
                        >
                          {member.username}
                        </span>{" "}
                        {member.siteId
                          ? `${
                              IS_HP
                                ? alertLeftSide.isPatientHighPoint
                                : alertLeftSide.isPatientCleanSlate
                            } ${" "}  ${getSiteNameFromAllSites(
                              member.siteId
                            )} .`
                          : null}
                      </span>
                      <br />
                    </div>
                    <div className="flag-lower">
                      <div className="flag-buttons">
                        <button
                          className="flag-message-button"
                          type="button"
                          onClick={() => this.viewProfilePress(member.id)}
                        >
                          {alertLeftSide.viewProfile}
                        </button>
                        <button
                          className="flag-resolve-button"
                          onClick={() => createDm(member.username, member.id)}
                        >
                          {alertLeftSide.dm}
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr className="horizontal-tag" />
                </div>
              ))
            : null}
          {loading && (
            <div className="loading-alert-members">
              <Spin />
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
  };

  showDeletedForumItems = () => {
    const {
      store: {
        DeletedForumItemListStore: {
          deletedForumItems,
          loading,
          endOfDeletedForumItemsList,
        },
      }
    } = this.props;

    return (
      <div style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
        <InfiniteScroll loadMore={this.loadMoreDeletedItems} useWindow={false}
        hasMore={!endOfDeletedForumItemsList && !loading}>
          {deletedForumItems.map((item) => {
            return <SingleForumDeletedItem
            key={item.id}
            item={item}
          />
          })}
        </InfiniteScroll>
      </div>
    )
  }


  renderOptions(arr, user) {
    return arr.map((item) => (
      <OptionList
        key={item}
        name={item}
        usernamePress={() => this.usernamePress(user.id)}
        user={user}
      />
    ));
  }

  renderPopOverContent(arr, message) {
    return arr.map((item) => (
      <ContentList
        key={item}
        name={item}
        usernamePress={() => this.usernamePress(message.senderId)}
        message={message}
      />
    ));
  }

  toggleResolveStatus(message, flag) {
    const {
      store: {
        FlaggedMessagesStore: { changeResolveStatus },
      },
    } = this.props;
    changeResolveStatus(_.cloneDeep(message), flag);
  }

  changeChannel = (id, name, flag, timeToken) => {
    const {
      store: {
        MessagesStore: { loadChannelMessages, selectedGroup },
        GroupStore: { fetchGroupUsers },
      },
    } = this.props;
    const type = flag ? "group" : "dm";
    loadChannelMessages(id, name, type, timeToken);
    selectedGroup.setFlaggedMessageTimeToken(timeToken);
    flag && fetchGroupUsers(id);
  };

  handleClick = (message) => {
    const {
      store: {
        ProfileStore: { changeSelectedAction, setSelectedAlert },
        MessagesStore: { setScrollVisible, setScrollToView },
        FlaggedMessagesStore: {
          setSelectedMessage
        }
      },
    } = this.props;
    setSelectedMessage(null);
    changeSelectedAction("View in context");
    setSelectedAlert(message);
    const groupType = message.channel.split("_")[0];
    const groupId = message.channel.split("_")[2];
    const flag = groupType === "GROUP" ? 1 : 0;
    this.changeChannel(
      parseInt(groupId),
      message.name,
      flag,
      message.messageId
    );
    setScrollVisible(true);
  };

  isSelected = () => {
    const {
      store: {
        ProfileStore: { profileVisible },
      },
    } = this.props;
    return profileVisible;
  };

  showSingleAlert = () => {
    const {
      store: {
        FlaggedMessagesStore: { urlAlerlLoading },
      },
    } = this.props;
    return urlAlerlLoading !== null && !urlAlerlLoading ? (
      <SingleAlert />
    ) : (
      <div className="loading-alert-members">
        <Spin />
      </div>
    );
  };
  checkIsSuspended = (message) => {
    const {
      store: {
        FlaggedMessagesStore: { checkIsSuspended },
      },
    } = this.props;
    if (message) {
      const groupId = message.channel.split("_")[2];
      checkIsSuspended(message.id, message.senderId, groupId);
    }
  };

  showAllAlerts = () => {
    const {
      store: {
        ProfileStore: { selectedAlert, setSelectedAlert },
        FlaggedMessagesStore: {
          unresolvedFlaggedMessages,
          resolvedFlaggedMessages,
          endOfUnresolvedFlaggedMessageList,
          endOfResolvedFlaggedMessageList,
          selectedMessageId,
          resolveLoader,
          assignedAlerts,
          usernames,
        },
        AuthStore: { type },
        FlaggedMessagesStore,
      },
    } = this.props;
    const arr = [
      "View Profile",
      "Direct Message",
      "Assign to",
      "Suspend account",
    ];
    const isNA = type === "NOA" ? true : false;
    return (
      <div style={{ height: `calc(100vh - ${this.props.navHeight + 53}px)`, overflow: "auto"}}>
        <InfiniteScroll
          loadMore={this.loadMoreFlaggedMessages}
          useWindow={false}
          hasMore={
            (!endOfResolvedFlaggedMessageList ||
              !endOfUnresolvedFlaggedMessageList) &&
            !FlaggedMessagesStore.loading
          }
        >
          {unresolvedFlaggedMessages.map((message, index) => {
            if (message.isForumFlag) {
              return <SingleForumAlert
                  message={message}
                  usernamePress={this.usernamePress}
                  isResolvedMessage={false}
                />
            } else {
              return message.senderId && message.userId ? (
                <div key={message.id}>
                  <div
                    className="flag-container"
                    style={
                      selectedAlert.id === message.id
                        ? { border: "5px solid #4168ca" }
                        : null
                    }
                    onClick={() => this.handleClick(message)}
                  >
                    <div className="flag-upper">
                      <div
                        className="flag-name-container"
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <span className="flag-name">{message.sender}</span>
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            this.usernamePress(message.senderId);
                            setSelectedAlert(message);
                          }}
                          className="flag-profile-container"
                        >
                          <span className="flag-profile">
                            {alertLeftSide.profile}
                          </span>
                        </div>
                      </div>
                      <div className="flag-time">
                        {moment(message.createdAt).calendar()}
                      </div>
                    </div>
                    {message.name ? (
                      <div className="info-icon-container">
                        <div className="flag-group">
                          {`in ${
                            message.name.length > 30
                              ? `${message.name.slice(0, 30)}...`
                              : message.name
                          }`}
                        </div>
                        <img
                          src={info}
                          style={{ height: "20px", width: "20px" }}
                          alt="info"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAlert(message);
                            this.fetchAlertHistory(message.id, message.name);
                          }}
                        />
                      </div>
                    ) : null}
                    <div className="flag-middle">
                      {message.type === "text" ? (
                        <div className="flag-message-container">
                          <div className="heading">{message.text}</div>
                        </div>
                      ) : message.type === "img" || message.type === "gif" ? (
                        <img src={message.imgUrl} alt="" height="60" width="60" />
                      ) : message.type === "form" ? (
                        <span className="heading">{alertLeftSide.form}</span>
                      ) : (
                        <span className="heading"></span>
                      )}
                      <br />
                      <span className="subHeading">
                        {`Flagged by ${message.username}`}
                      </span>
                      {assignedAlerts.has(message.id) ? (
                        <div className="subHeading">
                          {`Assigned to ${this.getAssignedUsernames(message.id)}`}
                        </div>
                      ) : null}
                    </div>
                    <div className="flag-lower">
                      <div className="flag-buttons">
                        <button
                          className="flag-message-button"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleClick(message);
                          }}
                        >
                          {alertLeftSide.viewInContext}
                        </button>
                        {selectedMessageId === message.id && resolveLoader ? (
                          <div className="flag-resolve-button">
                            <Spin indicator={antIcon} />
                          </div>
                        ) : (
                          <button
                            className="flag-resolve-button"
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.toggleResolveStatus(message, true);
                            }}
                          >
                            {alertLeftSide.resolve}
                          </button>
                        )}
                      </div>
                      <div
                        className="flag-div"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.checkIsSuspended(message);
                          setSelectedAlert(message);
                        }}
                      >
                        <Popover
                          content={this.renderPopOverContent(arr, message)}
                          placement="right"
                          trigger="click"
                        >
                          <Icon
                            className="flag-icon"
                            type="more"
                            style={{ fontSize: 30 }}
                          />
                        </Popover>
                      </div>
                    </div>
                  </div>
                  <hr className="horizontal-tag" />
                </div>
              ) : null;
            }
          })}
          {FlaggedMessagesStore.loading && (
            <div className="loading-alert-members">
              <Spin />
            </div>
          )}
          {endOfUnresolvedFlaggedMessageList &&
          resolvedFlaggedMessages.length ? (
            <div className="approved-users">
              {alertLeftSide.resolvedFlaggedMessages}
            </div>
          ) : null}
          {endOfUnresolvedFlaggedMessageList &&
            resolvedFlaggedMessages.map((message) => {
              if (message.isForumFlag) {
                return <SingleForumAlert
                    message={message}
                    usernamePress={this.usernamePress}
                    isResolvedMessage={true}
                  />
              } else {
                return message.senderId && message.userId ? (
                  <div key={message.id}>
                    <div
                      className="flag-container"
                      style={
                        selectedAlert.id === message.id
                          ? { border: "5px solid #4168ca" }
                          : null
                      }
                      onClick={() => {
                        if (!isNA) {
                          this.handleClick(message);
                        }
                      }}
                    >
                      <div className="flag-upper">
                        <div
                          className="flag-name-container"
                          style={{ cursor: "pointer" }}
                        >
                          <div>
                            <span className="flag-name">{message.sender}</span>
                          </div>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              this.usernamePress(message.senderId);
                              setSelectedAlert(message);
                            }}
                            className="flag-profile-container"
                          >
                            <span className="flag-profile">
                              {alertLeftSide.profile}
                            </span>
                          </div>
                        </div>
                        <div className="flag-time">
                          {moment(message.createdAt).calendar()}
                        </div>
                      </div>
                      {message.name ? (
                        <div className="info-icon-container">
                          <div className="flag-group">
                            {`in ${
                              message.name.length > 30
                                ? `${message.name.slice(0, 30)}...`
                                : message.name
                            }`}
                          </div>

                          <img
                            src={info}
                            style={{ height: "20px", width: "20px" }}
                            alt="info"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedAlert(message);
                              this.fetchAlertHistory(message.id, message.name);
                            }}
                          />
                        </div>
                      ) : null}
                      <div className="flag-middle">
                        {message.type === "text" ? (
                          <span className="heading">{message.text}</span>
                        ) : message.type === "img" || message.type === "gif" ? (
                          <img
                            src={message.imgUrl}
                            alt=""
                            height="60"
                            width="60"
                          />
                        ) : message.type === "form" ? (
                          <span className="heading">{alertLeftSide.form}</span>
                        ) : (
                          <span className="heading"></span>
                        )}
                        <br />
                        <div className="subHeading">
                          {`Flagged by ${message.username}`}
                        </div>
                        {assignedAlerts.has(message.id) ? (
                          <div className="subHeading">
                            {`Assigned to ${this.getAssignedUsernames(
                              message.id
                            )}`}
                          </div>
                        ) : null}
                        {usernames.has(message.resolvedBy) ? (
                          <div className="subHeading">
                            {`Resolved by ${usernames.get(message.resolvedBy)}`}
                          </div>
                        ) : null}
                      </div>
                      {isNA ? null : (
                        <div className="flag-lower">
                          <div className="flag-buttons">
                            <button
                              className="flag-message-button"
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleClick(message);
                              }}
                            >
                              View In Context
                            </button>
                            {selectedMessageId === message.id && resolveLoader ? (
                              <div className="flag-resolve-button">
                                <Spin indicator={antIcon} />
                              </div>
                            ) : (
                              <button
                                className="flag-resolve-button"
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.toggleResolveStatus(message, false);
                                }}
                              >
                                {alertLeftSide.unresolve}
                              </button>
                            )}
                          </div>
                          <div
                            className="flag-div"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.checkIsSuspended(message.senderId);
                              setSelectedAlert(message);
                            }}
                          >
                            <Popover
                              content={this.renderPopOverContent(arr, message)}
                              placement="right"
                            >
                              <Icon
                                className="flag-icon"
                                type="more"
                                style={{ fontSize: 30 }}
                              />
                            </Popover>
                          </div>
                        </div>
                      )}
                    </div>
                    <hr className="horizontal-tag" />
                  </div>
                ) : null;
              }
            })}
          {unresolvedFlaggedMessages.length +
          resolvedFlaggedMessages.length ? null : (
            <div className="no-alerts">{alertLeftSide.noAlerts}</div>
          )}
        </InfiniteScroll>
      </div>
    );
  };
  tabClick = (currentTabIndex) => {
    const {
      store: {
        ProfileStore: { changeSelectedAction, setSelectedAlert },
        MemberListStore: { setSelectedUserId },
        FlaggedMessagesStore: { setUrlAlertId, setSelectedMessage, setSelectedTab },
      },
    } = this.props;
    if(currentTabIndex) setSelectedTab(currentTabIndex)
    setUrlAlertId(null);
    changeSelectedAction("");
    setSelectedUserId(null);
    setSelectedAlert({});
    setSelectedMessage(null);
    history.push("/alert");
  };

  render() {
    const {
      store: {
        ProfileStore: { changeSelectedAction },
        MemberListStore: { unApprovedMembersTotal },
        AuthStore: { type },
        DeletedForumItemListStore: {deletedForumItemsTotal},
        HistoryStore: {
          messageHistory,
          historyLoading,
          totalMessageHistory,
          skipInMessageHistory,
          resetHistory,
        },
        FlaggedMessagesStore: {
          urlAlerlLoading,
          numberOfUnResolvedFlags,
          urlAlertId,
        },
      },
    } = this.props;

    const isFrontDesk = type === "FD";

    return (
      <div className="group-chat" style={{
        height: "100%",
      }}>
        <CustomModal
          title="Message History"
          visible={this.state.modalVisible}
          onOk={this.handleOk}
          loadMore={this.loadMoreAlertHistory}
          hasMore={skipInMessageHistory < totalMessageHistory}
          loading={historyLoading}
          onCancel={() => {
            resetHistory();
            this.changeModalVisible(false);
          }}
          data={messageHistory ? messageHistory : []}
        />
        {urlAlertId && !urlAlerlLoading ? (
          <div className="single-alert" onClick={() => this.tabClick()}>
            {alertLeftSide.seeAllAlerts}
          </div>
        ) : null}
        <Tabs
          style={{
            maxHeight: "100%",
          }}
          defaultActiveKey={!isFrontDesk ? "1" : "2"}
          onChange={() => {
            changeSelectedAction("");
          }}
          onTabClick={(e) => {
            this.tabClick(e);
          }}
        >
          {!isFrontDesk ? (
            <TabPane
              tab={
                <div className="alert-tab">
                  Alerts{" "}
                  {numberOfUnResolvedFlags ? (
                    <span>({numberOfUnResolvedFlags})</span>
                  ) : null}
                </div>
              }
              key="1"
            >
              {urlAlertId ? this.showSingleAlert() : this.showAllAlerts()}
            </TabPane>
          ) : null}
          <TabPane
            tab={
              <div className="alert-tab" style={{paddingRight: 20}}>
                Deleted (Posts & Comments){" "}
                {deletedForumItemsTotal ? (
                  <span>({deletedForumItemsTotal})</span>
                ) : null}
              </div>
            }
            key="3"
          >
            {this.showDeletedForumItems()}
          </TabPane>
          <TabPane
            tab={
              <div className="alert-tab" hidden>
                New Accounts{" "}
                {unApprovedMembersTotal ? (
                  <span>({unApprovedMembersTotal}) </span>
                ) : null}
              </div>
            }
            key="2"
          >
            {this.showUnApprovedMembers()}
          </TabPane>
          
        </Tabs>
      </div>
    );
  }
}
export default AlertLeftSide;
