import React from "react";
import moment from "moment";
import { Icon, Spin, Tabs, Tag, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroller";

import UserBadges from "../UI/UserBadges";
import { chat, waitingLeftSide, user } from "../../en.json";
import { IS_HP } from "../../utils/getEnvironment";
import "../../styles/style.scss";
import SiteStore from "../../stores/SiteStore";

const TabPane = Tabs.TabPane;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

@inject("store")
@observer
class WaitingLeftSide extends React.Component {
  loadMoreUnResolvedMembers = () => {
    const {
      store: {
        MemberListStore: { fetchUnResolvedMembers, loadingUnResolvedMembers },
        SiteStore: { selectedSiteId },
      },
    } = this.props;
    const siteId = selectedSiteId;
    if (loadingUnResolvedMembers) {
      return;
    }
    if (siteId !== 0 && siteId !== -1) {
      const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
      fetchUnResolvedMembers(siteDataObj);
    } else {
      fetchUnResolvedMembers();
    }
  };

  loadMoreResolvedMembers = () => {
    const {
      store: {
        MemberListStore: { fetchResolvedMembers, loadingResolvedMembers },
        SiteStore: { selectedSiteId },
      },
    } = this.props;
    const siteId = selectedSiteId;
    if (loadingResolvedMembers) {
      return;
    }
    if (siteId !== 0 && siteId !== -1) {
      const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
      fetchResolvedMembers(siteDataObj);
    } else {
      fetchResolvedMembers();
    }
  };

  addToGroup = async (userId, username) => {
    const {
      store: {
        MemberListStore: { getMemberDetails },
        BroadcastStore: { startWaitingChat },
        ProfileStore,
      },
    } = this.props;
    ProfileStore.setSelectedWaiting(userId);
    const member = await getMemberDetails(userId);
    ProfileStore.setMemberData(member);
    startWaitingChat(username, userId);
    ProfileStore.setVisibility(true);
  };

  handleCloseProfile = () => {
    const {
      store: {
        ProfileStore: { reset, setProfileVisibility, toggleEdit },
      },
    } = this.props;
    reset();
    setProfileVisibility(false);
    toggleEdit("editClinical", false);
    toggleEdit("editBio", false);
  };

  resolveMember = (member, value) => {
    const {
      store: {
        MemberListStore: { updateResolveStatus },
        ProfileStore: { setSelectedWaiting },
      },
    } = this.props;
    updateResolveStatus(member.id, value);
    setSelectedWaiting(null);
  };

  isSelected = () => {
    const {
      store: {
        ProfileStore: { selectedMember },
      },
    } = this.props;
    return selectedMember;
  };

  usernamePress = async (sender) => {
    const {
      store: {
        ProfileStore: {
          setProfileVisibility,
          setMemberData,
          toggleLabel,
          getHighPointUsers,
          getProviderModeratedGroups,
          getContactModeratedGroups,
          fetchGroupsFromForms,
          userId,
          reset,
        },
        NotificationStore,
        MemberListStore: { getMemberDetails },
        MessagesStore: { setIsWaiting },
      },
    } = this.props;
    if (userId === sender) {
      return;
    }
    reset();
    setProfileVisibility(true);
    const member = await getMemberDetails(sender);
    if (member) {
      setMemberData(member);
      if (IS_HP) {
        getHighPointUsers();
        if (member.providerId) {
          getProviderModeratedGroups(member.providerId);
        }
        if (member.recommendedBy) {
          getContactModeratedGroups(member.recommendedBy);
        }
        fetchGroupsFromForms();
      }
      setIsWaiting(false);
      toggleLabel(false);
    } else {
      NotificationStore.setNotification("error", user.userDoesNotExist);
    }
  };

  showNewMembers = () => {
    const {
      store: {
        MemberListStore: {
          unResolvedMembers,
          resolvingId,
          resolveLoading,
          unResolvedMembersTotal,
          loadingUnResolvedMembers,
          unResolvedMembersSkip,
        },
      },
      openWaitingChat,
    } = this.props;

    return (
      <div style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
        <InfiniteScroll
          loadMore={this.loadMoreUnResolvedMembers}
          useWindow={false}
          hasMore={unResolvedMembersSkip < unResolvedMembersTotal}
        >
          {unResolvedMembers.map((member) => {
            const lastMessageDate = moment
              .unix(parseInt(member.lastMessageTime) / 10000000)
              .format("LL");
            const lastMessageTime = moment
              .unix(parseInt(member.lastMessageTime) / 10000000)
              .format("hh:mm");

            return (
              <div key={member.id}>
                <div
                  className="flag-container"
                  style={
                    member.id === this.isSelected()
                      ? { border: "5px solid #4168ca" }
                      : null
                  }
                  onClick={() => openWaitingChat(member)}
                >
                  <div className="flag-upper">
                    <div
                      className="flag-name-container"
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          this.usernamePress(member.id);
                        }}
                      >
                        <Tooltip placement="right" title={waitingLeftSide.openProfile}>
                          <span className="flag-name">{member.username}</span>
                        </Tooltip>
                      </div>
                      {member.badgeType ? <UserBadges badgeType={member.badgeType} /> : null}
                      <span className={member.unread ? "waiting-badge" : ""} />
                    </div>

                    <button
                      className="flag-resolve-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.resolveMember(member, true);
                      }}
                    >
                      {resolvingId === member.id && resolveLoading ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        waitingLeftSide.resolve
                      )}
                    </button>
                  </div>
                  {member.lastMessage ? (
                    <div className="flag-middle">
                      <span className="heading">
                        <span>{member.lastMessageSender}</span>{" "}
                        {waitingLeftSide.sentAMessage} {member.lastMessage}
                      </span>
                      <div style={{ paddingTop: "10px" }}>
                        {lastMessageTime ? lastMessageTime : null}
                        {lastMessageTime ? "/" : null}
                        {lastMessageDate ? lastMessageDate : null}
                      </div>
                      <br />
                    </div>
                  ) : (
                    <div className="flag-middle">
                      <div className="heading">No Message</div>
                    </div>
                  )}
                  <div className="waiting-queue-buttons">
                    <button
                      className="flag-message-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        openWaitingChat(member);
                      }}
                    >
                      {waitingLeftSide.seeMessages}
                    </button>
                    <button
                      className="see-messages-button"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.addToGroup(member.id, member.username);
                      }}
                    >
                      {waitingLeftSide.addToGroup}
                    </button>
                  </div>
                </div>
                <hr className="horizontal-tag" />
              </div>
            );
          })}
          {loadingUnResolvedMembers && (
            <div className="loading-alert-members">
              <Spin />
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
  };

  showResolvedMembers = () => {
    const {
      store: {
        MemberListStore: {
          resolvedMembers,
          resolvedMembersTotal,
          loadingResolvedMembers,
          resolvedMembersSkip,
        },
      },
      openWaitingChat,
    } = this.props;
    return (
      <div style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
        <InfiniteScroll
          loadMore={this.loadMoreResolvedMembers}
          useWindow={false}
          hasMore={resolvedMembersSkip < resolvedMembersTotal}
        >
          {resolvedMembers.map((member) => {
            const lastMessageDate = moment
              .unix(parseInt(member.lastMessageTime) / 10000000)
              .format("LL");
            const lastMessageTime = moment
              .unix(parseInt(member.lastMessageTime) / 10000000)
              .format("hh:mm");

            return (
              <div key={member.id}>
                <div
                  className="flag-container"
                  style={
                    member.id === this.isSelected()
                      ? { border: "5px solid #4168ca" }
                      : null
                  }
                  onClick={() => openWaitingChat(member)}
                >
                  <div className="flag-upper">
                    <div
                      className="flag-name-container"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.usernamePress(member.id);
                      }}
                    >
                      <Tooltip placement="right" title={waitingLeftSide.openProfile}>
                        <span className="flag-name">{member.username}</span>
                      </Tooltip>
                    </div>
                  </div>
                  {member.lastMessage ? (
                    <div className="flag-middle">
                      <span className="heading">
                        <span>{member.lastMessageSender}</span>{" "}
                        {waitingLeftSide.sentAMessage} {member.lastMessage}
                      </span>
                      <div style={{ paddingTop: "10px" }}>
                        {lastMessageTime ? lastMessageTime : null}
                        {lastMessageTime ? "/" : null}
                        {lastMessageDate ? lastMessageDate : null}
                      </div>
                      <br />
                    </div>
                  ) : (
                    <div className="flag-middle">
                      <div className="heading">No Message</div>
                    </div>
                  )}
                  <div className="waiting-queue-buttons">
                    <button
                      className="flag-message-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        openWaitingChat(member);
                      }}
                    >
                      {waitingLeftSide.seeMessages}
                    </button>
                    <button
                      className="see-messages-button"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.addToGroup(member.id, member.username);
                      }}
                    >
                      {waitingLeftSide.addToGroup}
                    </button>
                  </div>
                </div>
                <hr className="horizontal-tag" />
              </div>
            );
          })}
          {loadingResolvedMembers && (
            <div className="loading-alert-members">
              <Spin />
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
  };

  render() {
    const {
      store: {
        MemberListStore: { unreadWaitingMessages },
      },
    } = this.props;

    return (
      <div className="group-chat">
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <div
                className={
                  "alert-tab" +
                  (unreadWaitingMessages.length > 0 ? " unread-messages" : "")
                }
              >
                {waitingLeftSide.tab1}
              </div>
            }
            key="1"
          >
            {this.showNewMembers()}
          </TabPane>
          <TabPane
            tab={<div className="alert-tab">{waitingLeftSide.tab2}</div>}
            key="2"
          >
            {this.showResolvedMembers()}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default WaitingLeftSide;
