import { types } from "mobx-state-tree";

const HPUser = types.model("HPUser", {
  id: types.number,
  contactId: types.number,
  contactName: types.string,
  champId: types.number,
  champName: types.string
});
export default HPUser;
