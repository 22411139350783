import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";

import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import {
  IS_AMERI,
  IS_HP,
  IS_STAGING,
  IS_NIDA,
  IS_SANDBOX,
  IS_CS,
  IS_LOCAL,
  IS_ISLAND,
  IS_ILLINOIS
} from "./utils/getEnvironment";

var mixpanel = require("mixpanel-browser");
if (!IS_LOCAL) {
  if (IS_STAGING) {
    Sentry.init({
      dsn: "https://f7660c759dbc4699838c82a3cbe26c64@sentry.io/1815083",
    });
  } else {
    Sentry.init({
      dsn: "https://2a51053f251d46258a3d559946f2721c@sentry.io/1815135",
    });
  }
}

if (IS_STAGING) {
  mixpanel.init("4da63aa2c965dbc41ed9b43c88c0328b");
} else if (IS_NIDA) {
  mixpanel.init("86e9acebf8c6b1a81edc38ceb5c4fb65");
} else if (IS_CS) {
  mixpanel.init("05e451cf8a1221dbe2b93a421f2b8949");
} else if (IS_ISLAND) {
  mixpanel.init("f99827f5964a7869de0863af38ae507f");
} else if (IS_ILLINOIS) {
  mixpanel.init("774e8ab2dfc73785124b4e953947a0e0");
} else if (IS_SANDBOX) {
  mixpanel.init("f99827f5964a7869de0863af38ae507f");
} else if (IS_HP) {
  mixpanel.init("e2adc8bf3acd9500e10a98e3ce1f9b2a");
} else if (IS_AMERI) {
  mixpanel.init("3766d96a46a3b6b4935f55dcbb204368");
} else {
  mixpanel.init("4da63aa2c965dbc41ed9b43c88c0328b");
}

mixpanel.set_config({ secure_cookie: true });

ReactDOM.render(
  <div id="sisenseApp">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
