import { PROJECT_ID_ISLAND_HEALTH, PROJECT_ID_ILLINOIS, PROJECT_ID_HIGHPOINT, PROJECT_ID_AMERI_HEALTH } from '../constants/ProjectConstant';

export const STAGING = "Staging";
export const NIDA = "Nida";
export const CS = "CS";
export const HP = "HP";
export const SANDBOX = "Sandbox";
export const COMM = "Comm";
export const ISLAND = "Island";
export const ILLINOIS = "Illinois"
export const AMERI_HEALTH = "AmeriHealth"

export const AMERI_HEALTH_FEED_CHANNEL = "AMERIHEALTH_FEED_LIVE"
export const ISLAND_HEALTH_FEED_CHANNEL = "ISLAND_FEED"
export const CLEANSLATE_FEED_CHANNEL = "CLEANSLATE_FEED_LIVE"
export const ILLINOIS_FEED_CHANNEL = "ILLINOIS_FEED_LIVE"
export const HIGHPOINT_FEED_CHANNEL = "HIGHPOINT_FEED_LIVE"

const { REACT_APP_API_URL: API_URL } = process.env;
const { REACT_APP_ENVIRONMENT: env, } = process.env;


export const getEnvironment = () => {
  switch (API_URL) {
    case "https://apinida.marigold.health":
      return NIDA;
    case "https://app-22394.on-aptible.com":
    case "http://localhost:3131":
      return ISLAND;
    case "https://app-33079.on-aptible.com":
      return ILLINOIS;
    case "http://ded1.geekydev.com:3131":
      return STAGING;
    case "https://apisandbox.marigold.health":
      return SANDBOX;
    case "https://app-17503.on-aptible.com":
      return HP;
    case "https://api.marigold.health":
      return CS;
    case "https://app-19026.on-aptible.com":
      return COMM;
    case "https://app-22666.on-aptible.com":
    case "https://api.marigolddev.us":
      return AMERI_HEALTH;
    default:
      return;
  }
};


export const getCurrentSiteName = () => {
  switch (API_URL) {
    case "https://app-22666.on-aptible.com":
    case "https://api.marigolddev.us":
      return "amerihealth";
    case "https://apinida.marigold.health":
      return "nida";
    case "https://app-22394.on-aptible.com":
      return "islandhealth";
    case "http://localhost:3131":
      return "islandhealth";
    case "https://app-33079.on-aptible.com":
      return "illinois";
    case "http://ded1.geekydev.com:3131":
      return "staging";
    case "https://apisandbox.marigold.health":
      return "sandbox";
    case "https://app-17503.on-aptible.com":
      return "hp";
    case "https://api.marigold.health":
      return "cs";
    case "https://app-19026.on-aptible.com":
      return "comm";
    default:
      return "not-found";
  }
};

export const getFeedChannel = () => {
  if (IS_AMERI) {
    return AMERI_HEALTH_FEED_CHANNEL;
  } else if (IS_ISLAND) {
    return ISLAND_HEALTH_FEED_CHANNEL;
  } else if (IS_HP) {
    return HIGHPOINT_FEED_CHANNEL;
  } else if (IS_ILLINOIS) {
    return ILLINOIS_FEED_CHANNEL;
  } else {
    // default for COMMUNITY, SITE2, CLEANSLATE, NIDA
    return CLEANSLATE_FEED_CHANNEL;
  }
};

export const IS_LOCAL = env === "LOCAL_ENV" ? true : false;

export const ENVIRONMENT = getEnvironment();
export const currentSiteName = getCurrentSiteName();
export const IS_HP = ENVIRONMENT === HP;
export const IS_STAGING = ENVIRONMENT === STAGING;
export const IS_NIDA = ENVIRONMENT === NIDA;
export const IS_SANDBOX = ENVIRONMENT === SANDBOX;
export const IS_CS = ENVIRONMENT === CS;
export const IS_COMM = ENVIRONMENT === COMM;
export const IS_ISLAND = ENVIRONMENT === ISLAND;
export const IS_ILLINOIS = ENVIRONMENT === ILLINOIS;
export const IS_AMERI = ENVIRONMENT === AMERI_HEALTH;

export const getProjectIdByEnvironment = () => {
  if (IS_ISLAND) {
    return PROJECT_ID_ISLAND_HEALTH;
  } else if (IS_ILLINOIS) {
    return PROJECT_ID_ILLINOIS;
  } else if (IS_HP) {
    return PROJECT_ID_HIGHPOINT;
  } else if (IS_STAGING) {
    return PROJECT_ID_ISLAND_HEALTH;
  } else if (IS_SANDBOX) {
    return PROJECT_ID_ISLAND_HEALTH;
  } else if (IS_CS) {

  } else if (IS_COMM) {

  } else if (IS_NIDA) {

  } else {
    return PROJECT_ID_ISLAND_HEALTH;
  }
}