import { inject, observer } from "mobx-react";

import React from "react";
import {
  Dropdown,
  TimePicker,
  Select,
  Button,
  Checkbox,
  Menu,
  Spin,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { sortBy } from "lodash";
import moment from "moment";
import close from "../../static/images/close.png";
import _ from "lodash";
import timesArr from "../../utils/time";

const { Option } = Select;

@inject("store")
@observer
class NewEvent extends React.Component {
  changeFromTime = (value) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails("fromTime", value);
  };

  changeToTime = (value) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails("toTime", value);
  };

  changeValue = (e) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails(e.target.getAttribute("data-field"), e.target.value);
  };

  createNewEvent = () => {
    const {
      store: {
        EventStore: { creatingEvent, createEvent },
      },
    } = this.props;
    if (creatingEvent) {
      return;
    }
    createEvent();
  };

  getFromDisabledHours = () => {
    const {
      store: {
        EventStore: { eventDate },
      },
    } = this.props;
    const now = moment();
    const dateToday = now.format("YYYY-MM-DD");
    if (eventDate === dateToday) {
      var hours = [];
      for (var i = 0; i < now.hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  getFromDisabledMins = (selectedHour) => {
    const now = moment();
    var minutes = [];
    if (selectedHour === now.hour()) {
      for (var i = 0; i < now.minutes(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  handleCloseNewEvent = (value) => {
    const {
      store: {
        EventStore: { setNewEvent },
      },
    } = this.props;

    setNewEvent(false);
  };

  setEligibeSite = ({ key }) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails("eligibleId", Number(key));
  };

  setIsRecurring = (e) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    const recurring = e.target.checked;
    setNewEventDetails("recurring", recurring);
  };

  render() {
    const {
      store: {
        SiteStore: { allSites, getSiteNameFromAllSites },
        EventStore: {
          eligibleId,
          creatingEvent,
          eventDate,
          noDate,
          canCreateEvent,
        },
      },
    } = this.props;
    const siteMenu = (
      <div className="eligibe-site-container">
        <Menu onClick={this.setEligibeSite}>
          {sortBy([...allSites.values()], ["name"]).map((site) => (
            <Menu.Item key={site.id} icon={<UserOutlined />}>
              <div>{site.name}</div>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
    const dateToday = moment().format("YYYY-MM-DD");
    const isDateInValid = eventDate < dateToday;
    const format = "HH:mm";

    return (
      <div className="form-response">
        <div className="new-referral-header">
          <div>
            Create New Event
            <div className="new-referral-sub">
              Please ensure the IT ticket to start the group has been entered in
              Tier BEFORE adding the group's details on Marigold.
            </div>
          </div>
          <div className="flag-actions-container">
            <img
              src={close}
              onClick={() => this.handleCloseNewEvent(false)}
              alt="Close"
            />
          </div>
        </div>
        <div className="new-event-input-container">
          <div className="new-event-label">Group Name</div>
          <input
            placeholder="Type..."
            className="new-event-input"
            data-field="groupName"
            onChange={this.changeValue}
          />
          <div className="new-event-label">
            Group Description (Visible to members on Marigold and referring
            providers)
          </div>
          <input
            placeholder="Type..."
            className="new-event-input"
            data-field="groupDesc"
            onChange={this.changeValue}
          />
          <div className="new-event-label">Time</div>
          <div>
            <input
              onChange={this.changeValue}
              type="date"
              min={dateToday}
              className="date-input"
              data-field="eventDate"
            />
            <span className="new-event-label"> from </span>
            <Select
              style={{ width: 128 }}
              placeholder="select time"
              showArrow={false}
              disabled={noDate || isDateInValid}
              onChange={this.changeFromTime}
            >
              {timesArr.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
            {/* <TimePicker
              minuteStep={15}
              use12Hours={true}
              disabled={noDate || isDateInValid}
              format={format}
              disabledHours={this.getFromDisabledHours}
              disabledMinutes={this.getFromDisabledMins}
              hideDisabledOptions={true}
              onChange={this.changeFromTime}
            /> */}
            <span className="new-event-label"> to </span>
            <Select
              style={{ width: 128 }}
              placeholder="select time"
              showArrow={false}
              disabled={noDate || isDateInValid}
              onChange={this.changeToTime}
            >
              {timesArr.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
            {/* <TimePicker
              minuteStep={15}
              use12Hours={true}
              disabled={noDate || isDateInValid}
              disabledHours={this.getFromDisabledHours}
              disabledMinutes={this.getFromDisabledMins}
              hideDisabledOptions={true}
              format={format}
              onChange={this.changeToTime}
            /> */}
          </div>
          {eventDate && isDateInValid ? (
            <div className="warning">*Please Enter a Valid Date</div>
          ) : null}
          <div className="referral-checkbox">
            <Checkbox onChange={this.setIsRecurring}>
              <span>Recurring weekly</span>
            </Checkbox>
          </div>
          <div className="new-event-label">Outreach Plan</div>
          <input
            placeholder="send reminders 24hrs before"
            className="new-event-input"
            data-field="outReach"
            onChange={this.changeValue}
          />
          <div className="new-event-label">Zoom Info</div>
          <input
            data-field="zoomInfo"
            onChange={this.changeValue}
            placeholder="we'll email + text the zoom to all RSVPs at the start time."
            className="new-event-input"
          />
          <div className="zoom-info-label">
            Please send handouts to{" "}
            <span className="zoom-link">support@marigoldhealth.com</span>
          </div>
          <div className="new-event-label">Patients eligible</div>
          <div id="patients-eligible">
            <Dropdown
              getPopupContainer={() =>
                document.getElementById("patients-eligible")
              }
              trigger={["click"]}
              overlay={siteMenu}
            >
              <Button placement="bottomCenter">
                {eligibleId ? getSiteNameFromAllSites(eligibleId) : "select"}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>

        {creatingEvent ? (
          <div className="creating-event">
            <Spin />
          </div>
        ) : null}

        <div className="submit-container">
          <button
            className="new-referral-button"
            disabled={canCreateEvent || creatingEvent}
            onClick={this.createNewEvent}
          >
            Create Event
          </button>
        </div>
      </div>
    );
  }
}
export default NewEvent;
