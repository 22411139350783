import { inject, observer } from "mobx-react";

import ProfileGroups from "./ProfileGroups";
import React from "react";
import { Spin } from "antd";
import close from "../../static/images/close.png";

@inject("store")
@observer
class Profile extends React.Component {
  closePress = () => {
    const {
      store: {
        ProfileStore: { setVisibility },
      },
    } = this.props;
    setVisibility(false);
  };
  render() {
    const {
      store: {
        ProfileStore: { loading, username },
      },
      navHeight,
    } = this.props;

    return (
      <React.Fragment>
        <h1 className="user-name">
          {loading && <Spin />}
          {username}
          <img
            className="close-img"
            src={close}
            onClick={() => this.closePress()}
            alt="Close"
          />
        </h1>
        <ProfileGroups
          navHeight={navHeight}
        />
      </React.Fragment>
    );
  }
}
export default Profile;
