import { types } from "mobx-state-tree";

const Answer = types.model("Answer", {
  id: types.maybeNull(types.number),
  userId: types.maybe(types.integer),
  questionId: types.maybe(types.integer),
  answer: types.maybe(types.string),
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string)
});

export default Answer;
