import { types } from "mobx-state-tree";

const DeletedForumItem = types.model("DeletedForumItem", {
    id: types.maybe(types.number),
    type: types.maybe(types.string),
    resourceId: types.maybeNull(types.string),
    resource: types.maybeNull(types.frozen({})),
    deletedBy: types.maybeNull(types.string),
})

export default DeletedForumItem;