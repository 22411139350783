import ApiService from "./ApiService";
import SecureLocalStorage from "./SecureLS";
import { getFromSecureLS } from "./CommonUtils";

const LAST_STORED_AT = "GENERAL_DATA_LAST_STORED_AT";

export const KEYS = {
  GROUPS: "groups",
  SITES: "sites",
  PROFILE_QUESTIONS: "profile-questions",
  FORMS: "forms",
  MIXPANEL_REPORTS: "mixpanel-reports",
};

export async function load () {
  const { data = {} } = await ApiService.getRequest("general", { web: true });

  const generalData = Object.values(KEYS).reduce((general, key) => {
    if (data.hasOwnProperty(key)) {
      general[`generalData.${key}`] = data[key];
    }
    return general;
  }, {});

  SecureLocalStorage.setMultipleKeys(generalData);
  SecureLocalStorage.set(LAST_STORED_AT, Date.now());
}

export async function get (key) {
  if (!Object.values(KEYS).includes(key)) {
    throw new Error(`Unsupported key: ${key} for GeneralData`);
  }

  await refreshIfNeeded();
  const response = getFromSecureLS(`generalData.${key}`);

  if (response) {
    return {
      success: true,
      error: false,
      data: response.data ? response.data : response,
      meta: {
        total: response.total,
        limit: response.limit,
        skip: response.skip,
      },
    };
  }
}

export async function refreshIfNeeded () {
  const lastStoredAt = getFromSecureLS(LAST_STORED_AT);
  const now = Date.now();
  const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000;

  if (!lastStoredAt || (now - lastStoredAt > FIFTEEN_MINUTES_IN_MS)) {
    await load();
  }
}
