import { Icon, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import React from "react";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

@inject("store")
@observer
class Banner extends React.Component {
  navigateToChat = (id, name, userType, type) => {
    const {
      store: {
        MessagesStore: { changeChannel, resetSelectedGroup, setIsWaiting },
        ProfileStore: { setSelectedWaiting },
      },
    } = this.props;
    setSelectedWaiting(null);
    setIsWaiting(false);
    resetSelectedGroup();
    changeChannel(id, name, userType, false, type);
  };
  render() {
    const {
      store: {
        GroupStore: { banners, isBannerLoading },
        AuthStore: { isAlreadyAssigned, type },
      },
      groupId,
    } = this.props;
    let name,
      description,
      memberCount,
      hasError = true;
    if (banners.has(groupId)) {
      name = banners.get(groupId).name;
      description = banners.get(groupId).description;
      memberCount = banners.get(groupId).memberCount;
      hasError = banners.get(groupId).hasError;
    }
    const userType = type;
    return hasError ? null : isBannerLoading(groupId) ? (
      <div className="mod-form-container">
        <div className="mod-form-loading">
          <Spin indicator={antIcon} />
        </div>
      </div>
    ) : banners.has(groupId) && name && description && memberCount ? (
      <div className="mod-form-container" style={{ cursor: "pointer" }}>
        <div className="form-text">
          <div>{name}</div>
          <div className="form-desc">{description}</div>
          <div className="form-desc">{memberCount} members</div>
        </div>

        {isAlreadyAssigned(groupId) ? (
          <Link
            to="/members"
            onClick={() =>
              this.navigateToChat(groupId, name, userType, null, "group")
            }
          >
            <div className="form-go">
              <span className="form-go-text">GO</span>
            </div>
          </Link>
        ) : null}
      </div>
    ) : null;
  }
}
export default Banner;
