import Group from "./Group";
import { types } from "mobx-state-tree";

const Member = types.model("Member", {
  id: types.maybeNull(types.number),
  fullName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  siteId: types.maybeNull(types.integer),
  username: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  certification: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  isResolved: types.maybeNull(types.boolean),
  isApproved: types.maybeNull(types.boolean),
  resolvedAt: types.maybeNull(types.string),
  displayPictureUrl: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  handledBy: types.maybeNull(types.number),
  groups: types.maybe(types.array(Group)),
  dmChannels: types.array(types.string),
  notes: types.maybeNull(types.string),
  userConsentType: types.maybeNull(types.string),
  lastMessageTime: types.maybeNull(types.string),
  lastMessage: types.maybeNull(types.string),
  lastMessageSender: types.maybeNull(types.string),
  unread: false,
  color: types.maybeNull(types.string),
  smsOutreach: types.maybeNull(types.string),
  callOutreach: types.maybeNull(types.string),
  isUserSentMessageInGroup: types.maybeNull(types.boolean),
  latestSmsOutreachHistory: types.optional(types.frozen({})),
  latestCallOutreachHistory: types.optional(types.frozen({})),
  assignedPeersDetails: types.optional(types.frozen({})),
  sites: types.optional(types.frozen({
    name: types.maybeNull(types.string),
    provider: types.maybeNull(types.string),
    territory: types.maybeNull(types.string),
  })),
  badgeType: types.maybeNull(types.string)
});

export default Member;