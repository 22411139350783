import React from "react";
import close from "../../static/images/close.png";
import { Checkbox, Radio, Tooltip, Steps } from "antd";
import { inject, observer } from "mobx-react";
import { ErrorModal } from "../UI/ErrorModal";
import { LoadingOutlined } from "@ant-design/icons";
import { CBRS, toolTips } from "../../en.json";

const { Step } = Steps;

@inject("store")
@observer
class ReferrerInvite extends React.Component {
  handleCloseReferral = (value) => {
    const {
      store: {
        ReferralStore: { resetClientData, setNewReferral },
      },
    } = this.props;
    resetClientData();
    setNewReferral(value);
  };

  startReferral = (value) => {
    const {
      store: {
        ReferralStore: { createUser, setShowReferralForm },
      },
    } = this.props;
    createUser();
  };

  setHasPhone = (e) => {
    const {
      store: {
        ReferralStore: { setClientDetails },
      },
    } = this.props;
    const hasPhone = e.target.checked;
    setClientDetails("noPhone", hasPhone);
  };

  changeValue = (e) => {
    const {
      store: {
        ReferralStore: { setClientDetails },
      },
    } = this.props;
    setClientDetails(e.target.getAttribute("data-field"), e.target.value);
  };

  selectForm = (e) => {
    const {
      store: {
        ReferralStore: { setClientDetails },
      },
    } = this.props;
    const selectedForm = e.target.value;
    setClientDetails("clientSelectedForm", selectedForm);
  };

  render() {
    const {
      store: {
        ReferralStore: {
          isDisabled,
          clientPhone,
          clientDOB,
          inviteSMS,
          clientFullName,
          currentStep,
          creatingForm,
          creatingUser,
          makingEntry,
          isNotNumber,
          hasDuplicateError,
          setDuplicateError,
        },
      },
    } = this.props;

    return (
      <div>
        {hasDuplicateError ? (
          <ErrorModal onClose={() => setDuplicateError(false)} />
        ) : null}
        <div className="referral-section">
          <img
            src={close}
            className="close-invite"
            onClick={() => this.handleCloseReferral(false)}
            alt="Close"
          />
          {/* <h1>{CBRS.inviteHeading}</h1>
          <div className="start-referral text-left">
            {CBRS.inviteSub}
            <span className="bold"> {CBRS.inviteSubBold}</span>
          </div> */}
          <div className="referral-box">
            <div className="refer-input-container">
              <div className="invite-sms">{CBRS.name}</div>
              <input
                onChange={this.changeValue}
                placeholder="Full Name"
                value={clientFullName}
                className="referral-input"
                data-field="clientFullName"
              />
              <div className="invite-sms">{CBRS.dob}</div>
              <input
                type="date"
                value={clientDOB}
                className="referral-input"
                onChange={this.changeValue}
                data-field="clientDOB"
              />
              <div className="invite-sms">{CBRS.phone}</div>
              <input
                onChange={this.changeValue}
                placeholder={CBRS.phonePlaceHolder}
                value={clientPhone}
                className="referral-input"
                data-field="clientPhone"
                style={
                  clientPhone.length
                    ? isNotNumber
                      ? { borderBottom: "2px solid red" }
                      : null
                    : null
                }
              />
              <div>
                <Checkbox onChange={this.setHasPhone}>
                  <span>{CBRS.noPhone}</span>
                </Checkbox>
              </div>
              <div className="choose-form">
                <div className="invite-sms">{CBRS.choose}</div>
                <Radio.Group onChange={this.selectForm}>
                  <Radio value={"RSN"}>
                    <Tooltip title={toolTips.RSN} placement="rightBottom">
                      <span>{CBRS.rsn}</span>
                    </Tooltip>
                  </Radio>
                  <Radio value={"RC"}>
                    <Tooltip title={toolTips.RC} placement="rightBottom">
                      <span>{CBRS.rc}</span>
                    </Tooltip>
                  </Radio>
                  <Radio value={"RSN_RC"}>
                    <Tooltip title={toolTips.RSN_RC} placement="rightBottom">
                      <span>{CBRS.rsn_rc}</span>
                    </Tooltip>
                  </Radio>
                </Radio.Group>
              </div>
            </div>

            {/* <div>
              <div className="invite-sms">Invite SMS (sent to client)</div>
              <textarea
                onChange={this.changeValue}
                data-field="inviteSMS"
                className="refer-textbox"
                rows={10}
              />
              <div className="invite-sms">
                The below is automatically included:
              </div>
              <div className="refer-message">
                HPTC is now on Marigold Health app! You can use Marigold to keep
                in touch with HPTC staff, and join a 24/7 anonymous support
                group.
                <br />
                Android: LINK
                <br />
                iPhone:LINK
              </div>
            </div> */}
          </div>

          <div className="send-referral">
            {makingEntry || creatingForm || creatingUser ? (
              <Steps size="small" current={currentStep}>
                <Step
                  title="Signing up user"
                  icon={creatingUser ? <LoadingOutlined /> : null}
                />
                <Step
                  title="Making Entry of Form"
                  icon={creatingForm ? <LoadingOutlined /> : null}
                />
                <Step
                  title="Making entry in referral"
                  icon={makingEntry ? <LoadingOutlined /> : null}
                />
              </Steps>
            ) : (
              <button
                disabled={isDisabled}
                className="new-referral-button"
                onClick={() => this.startReferral(true)}
              >
                {CBRS.goToReferral}
              </button>
            )}
          </div>
          <div className="start-referral">
            <span className="bold">{CBRS.startReferral}</span>{" "}
            {CBRS.startReferralBold}
          </div>
          {/* <div className="start-referral text-left">
            {CBRS.inviteSub}
            <span className="bold"> {CBRS.inviteSubBold}</span>
          </div> */}
        </div>
      </div>
    );
  }
}

export default ReferrerInvite;
