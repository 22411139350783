const SisenseConfig = {
    host: "https://marigoldhealth.sisense.com",
    sisenseJs: [
        {
            dashboardId: "63f7b3d88423b10034e2ca0b",
            widgetIds: [
                "63f7b3d88423b10034e2ca0c"
            ]
        },
        {
            dashboardId: "63f7b3808423b10034e2ca03",
            widgetIds: [
                "63f7b3808423b10034e2ca04"
            ]
        },
        {
            dashboardId: "643effec3838d10033b0d801",
            widgetIds: [
                "643efffa3838d10033b0d803"
            ]
        },
        {
            dashboardId: "63f7b3fe8423b10034e2ca11",
            widgetIds: [
                "63f7b3fe8423b10034e2ca16",
                "63f7b3fe8423b10034e2ca14",
                "63f7b3fe8423b10034e2ca12"
            ]
        },
    ],
    path: "/js/sisense.v1.js"
}

module.exports = SisenseConfig
