import React, { useState } from 'react'
import { Button, Col, Row, DatePicker, Modal } from 'antd'
import moment from 'moment';

import { IMG_RANKING, IMG_TICK_CIRCLE_GREEN, IMG_TICK_CIRCLE_GREY } from '../../utils/ImageUtils';
import { HIDE_STEP_FORM_TYPE, REWARD_LABEL, REWARD_STATUS, REWARD_STATUS_LABEL, REWARD_TYPE, WEEKLY_FORM_TYPE } from '../../constants/GlobalConstant';
import { onBoardingStatus } from '../../en.json';
import edit from "../../static/images/edit.svg";
import SecureLocalStorage from "../../utils/SecureLS";
import { DEV, MODERATOR, SA } from '../../constants/UserRolesConstant';
import { getLocalDateTime } from '../../utils/CommonUtils';
const { confirm } = Modal;

const Reward = (props) => {
    const [showDatePicker, SetShowDatePicker] = useState(false);
    const [showExpiryDatePicker, setShowExpiryDatePicker] = useState(false);
    const {
        reward,
        key,
        onComplete,
        onboardingStatus,
        onDateChange,
        isExpired = false,
        onExpiryDateChange
    } = props;

    const NON_MESSAGE_BASED_REWARD_TYPES = [
      REWARD_TYPE.BARC10,
      REWARD_TYPE.SDOH,
      REWARD_TYPE.WELLNESS_JOURNEY,
      REWARD_TYPE.INTAKE,
      REWARD_TYPE.CUSTOM_REWARD,
    ];

    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const rewardStartedAt = reward?.startedAt && getLocalDateTime(reward?.startedAt)
    const rewardExpiredAt = reward?.expiredAt && getLocalDateTime(reward?.expiredAt)
    const weeklyFormType = WEEKLY_FORM_TYPE;
    const hasRewardStartedAtDatePassed = rewardStartedAt && (new Date(rewardStartedAt).getTime() <= todayDate.getTime());


    const isRewardCompleted = (reward.status === REWARD_STATUS.READY_TO_REDEEM || (reward.status === REWARD_STATUS.IN_PROGRESS && (rewardExpiredAt && rewardExpiredAt && (todayDate.getTime() >= new Date(rewardExpiredAt).getTime()))));
    const isRewardUpcoming = (!rewardStartedAt && !rewardExpiredAt);
    const excludedTypes = [REWARD_TYPE.BARC10, REWARD_TYPE.WELLNESS_JOURNEY, REWARD_TYPE.INTAKE, REWARD_TYPE.SDOH];
    const excludedTypesForEndDate = [REWARD_TYPE.BARC10, REWARD_TYPE.WELLNESS_JOURNEY, REWARD_TYPE.INTAKE, REWARD_TYPE.SDOH, REWARD_TYPE.FIRST_MESSAGE];
    const showExpiredAt = !excludedTypesForEndDate.includes(reward.rewardFormType);
    const showStatus = excludedTypes.includes(reward.rewardFormType);
    const containerBackgroundColor = isExpired ? '#FCEDEA' : (isRewardCompleted || isRewardUpcoming) ? 'rgba(0, 0, 0, 0.1)' : '#EBF7EE';
    const containerBorderColor = isExpired ? '2px solid #F5C5BB' : (isRewardCompleted || isRewardUpcoming) ? '2px solid #bfbfbf' : '2px solid #BEE5C8';

    const isNonMessageBasedReward = NON_MESSAGE_BASED_REWARD_TYPES.includes(reward.rewardFormType);
    const isRewardSubmitted = !!reward.isSubmitted;
    const isOnBoardingReward = reward.title === "Onboarding Reward";

    const shouldEnableCompleteButton = isRewardSubmitted || (isNonMessageBasedReward && hasRewardStartedAtDatePassed) || isOnBoardingReward;

    // render reward label for submitted is reviewed status
    const renderRewardLabel = (rewardType) => {
        if (rewardType === REWARD_TYPE.BARC10) {
            return REWARD_LABEL.BARC10;
        } else if (rewardType === REWARD_TYPE.WELLNESS_JOURNEY) {
            return REWARD_LABEL.WELLNESS_JOURNEY;
        } else if (rewardType === REWARD_TYPE.INTAKE) {
            return REWARD_LABEL.INTAKE;
        } else if (rewardType === REWARD_TYPE.SDOH) {
            return REWARD_LABEL.SDOH;
        }
    }

    let userType = SecureLocalStorage.get("USER_TYPE");
    const onChange = (date) => {
        confirm({
            title: "You are about to change the start date for this Reward. Are you sure you want to continue?",
            okText: "Yes, I am sure",
            cancelText: "Cancel",
            onOk() {
                onDateChange(reward.id, reward.userId, date.format('YYYY-MM-DD'));
            },
            onCancel() {
                SetShowDatePicker(false);
            }
        });
    }

    const onExpiryValueChange = (date) => {
        confirm({
            title: "You are about to change the end date for this Reward. Are you sure you want to continue?",
            okText: "Yes, I am sure",
            cancelText: "Cancel",
            onOk() {
                onExpiryDateChange(reward.id, reward.userId, date.format('YYYY-MM-DD'));
            },
            onCancel() {
                setShowExpiryDatePicker(false);
            }
        })
    }

    // view reward
    return (
        <div className='task-container' key={key} style={{ backgroundColor: containerBackgroundColor, border: containerBorderColor }}>
            <Row>
                <Col span={1}>
                    <img src={IMG_RANKING} height={30} />
                </Col>
                <Col span={18}>
                    <p className='reward-primary-label'>{reward.title}</p>
                </Col>
                <Col span={5} style={{ display: 'flex', justifyContent: 'flex-end', color: '#3FBF61' }}>
                    {reward.amount < 1 ?
                        <p className='reward-primary-label'>¢{reward.amount * 100}</p> :
                        <p className='reward-primary-label'>${reward.amount}</p>
                    }

                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Col span={1}></Col>
                <Col span={11}>
                    <Row>
                        <Col span={24}>
                            <p><span style={{ color: '#666666' }}>Criteria:</span> <span style={{ fontWeight: 'bold' }}>{reward.criteria}</span><span></span></p>
                        </Col>
                    </Row>
                    {<Row style={{ marginTop: 10 }}>
                        <Col span={24}>
                            <p>
                                <span style={{ color: "#666666" }}>Start Date: </span>
                                {
                                    !isRewardUpcoming &&
                                    <><span style={{ fontWeight: "bold" }}>{moment(rewardStartedAt).format("MM/DD/YYYY")}</span>&nbsp;</>
                                }
                                <span style={{ display: !isRewardCompleted && (userType === SA || userType === DEV) && !reward.isSubmitted ? "inline" : "none" }}>
                                    <img
                                      style={{
                                            display: reward.rewardFormType && HIDE_STEP_FORM_TYPE.includes(reward.rewardFormType) ? "none" : "inline",
                                            cursor: 'pointer',
                                        }}
                                        src={edit}
                                        className="edit-reward-icon"
                                        alt="edit"
                                        onClick={() => { setShowExpiryDatePicker(false)
                                            SetShowDatePicker(!showDatePicker)}}
                                    />
                                </span>
                                {
                                    (!isRewardUpcoming && showExpiredAt && !reward.isDefault) &&
                                    <>
                                        <span style={{ color: '#666666' }}>&nbsp;&nbsp;|&nbsp;&nbsp;End Date:</span>
                                        <span style={{ fontWeight: 'bold' }}>{rewardExpiredAt ? moment(rewardExpiredAt).format('MM/DD/YYYY') : 'None'}</span>
                                    </>
                                }
                                <span style={{ display: !isRewardCompleted && (userType === SA || userType === MODERATOR || userType === DEV) && !reward.isSubmitted && reward.rewardFormType === 'custom-reward' ? "inline" : "none" }}>
                                    <img
                                      style={{
                                            display: "inline",
                                            cursor: 'pointer',
                                        }}
                                        src={edit}
                                        className="edit-reward-icon"
                                        alt="edit"
                                        onClick={() => { setShowExpiryDatePicker(!showExpiryDatePicker)
                                            SetShowDatePicker(false)
                                        }}
                                    />
                                </span>
                            </p>
                        </Col>
                    </Row >}
                    <Row style={{ display:  !isRewardCompleted && (userType === SA || userType === MODERATOR || userType === DEV) && showDatePicker && !reward.isSubmitted ? "inline" : "none" }}>
                        <Col span={24}>
                            <DatePicker
                                onChange={onChange}
                                popupStyle={{position: 'relative'}}
                                getCalendarContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }}
                                defaultPickerValue={moment(rewardStartedAt)}
                                format={'MM/DD/YYYY'}
                                placeholder='Select Start Date'
                                disabledDate={(currentDate) => moment(currentDate) >= moment(rewardExpiredAt)}
                            />
                        </Col>
                    </Row>
                    <Row style={{ display:  !isRewardCompleted && (userType === SA || userType == MODERATOR || userType === DEV) && showExpiryDatePicker && !reward.isSubmitted ? "inline" : "none" }}>
                        <Col span={24}>
                            <DatePicker
                                onChange={onExpiryValueChange}
                                style={{left: 200}}
                                popupStyle={{position: 'relative'}}
                                getCalendarContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }}
                                defaultPickerValue={moment(rewardExpiredAt)}
                                format={'MM/DD/YYYY'}
                                placeholder='Select Expiry Date'
                                disabledDate={(currentDate) => moment(currentDate) <= moment(rewardStartedAt)}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <div>
                                <Row>
                                    <Col span={24} style={reward.rewardFormType ? HIDE_STEP_FORM_TYPE.includes(reward.rewardFormType) ? { display: "none" } : { display: "inline" } : { display: "inline" }}>
                                        <p><span style={{ color: '#666666' }}>Steps:</span></p>
                                    </Col>
                                </Row>
                                {
                                    reward.isDefault ? <Row>
                                        <Col span={24}>
                                            {onboardingStatus && Object.entries(onBoardingStatus).map(item => {
                                                return (
                                                    <p style={{ margin: 5 }}>
                                                        {onboardingStatus[item[0]] ? <img src={IMG_TICK_CIRCLE_GREEN} alt="tick green" height={20} /> :
                                                            <img src={IMG_TICK_CIRCLE_GREY} alt="tick green" height={20} />}
                                                        <span className='reward-status-label'>{item[1]}</span>
                                                    </p>
                                                )
                                            })}
                                        </Col>
                                    </Row> : (showStatus ? <Row>
                                        <Col span={24}>
                                            <p style={{ margin: 5 }}>
                                                {reward.isSubmitted ? <img src={IMG_TICK_CIRCLE_GREEN} alt="tick green" height={20} /> :
                                                    <img src={IMG_TICK_CIRCLE_GREY} alt="tick green" height={20} />}
                                                <span className='reward-status-label'>{renderRewardLabel(reward.rewardFormType)} {REWARD_STATUS_LABEL.SUBMITTED}</span>
                                            </p>
                                            <p style={{ margin: 5 }}>
                                                {reward.isReviewed ? <img src={IMG_TICK_CIRCLE_GREEN} alt="tick green" height={20} /> :
                                                    <img src={IMG_TICK_CIRCLE_GREY} alt="tick green" height={20} />}
                                                <span className='reward-status-label'>{renderRewardLabel(reward.rewardFormType)} {REWARD_STATUS_LABEL.REVIEWED}</span>
                                            </p>
                                        </Col>
                                    </Row> : (reward.rewardFormType == weeklyFormType[1] ? <Row>
                                        <Col span={24}>
                                            <p style={{ margin: 5 }}>
                                                {reward.task1 == true ? <img src={IMG_TICK_CIRCLE_GREEN} alt="tick green" height={20} /> :
                                                    <img src={IMG_TICK_CIRCLE_GREY} alt="tick green" height={20} />}
                                                <span className='reward-status-label'>{renderRewardLabel(reward.rewardFormType)} {REWARD_STATUS_LABEL.TASK1}</span>
                                            </p>
                                            <p style={{ margin: 5 }}>
                                                {reward.task2 == true ? <img src={IMG_TICK_CIRCLE_GREEN} alt="tick green" height={20} /> :
                                                    <img src={IMG_TICK_CIRCLE_GREY} alt="tick green" height={20} />}
                                                <span className='reward-status-label'>{renderRewardLabel(reward.rewardFormType)} {REWARD_STATUS_LABEL.TASK2}</span>
                                            </p>
                                            <p style={{ margin: 5 }}>
                                                {reward.task3 == true ? <img src={IMG_TICK_CIRCLE_GREEN} alt="tick green" height={20} /> :
                                                    <img src={IMG_TICK_CIRCLE_GREY} alt="tick green" height={20} />}
                                                <span className='reward-status-label'>{renderRewardLabel(reward.rewardFormType)} {REWARD_STATUS_LABEL.TASK3}</span>
                                            </p>
                                        </Col>
                                    </Row> : <></>))}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                {isRewardCompleted
                                    ?
                                    <p style={{ fontSize: 20 }}>{isRewardUpcoming ? 'Upcoming' : 'Completed'}</p>
                                    :
                                    <>
                                        {(!isRewardUpcoming) &&
                                            <Button
                                                className="download-button"
                                                type="primary"
                                                size="large"
                                                disabled={!shouldEnableCompleteButton}
                                                onClick={() => onComplete(reward.id)}
                                                style={reward.rewardFormType ? HIDE_STEP_FORM_TYPE.includes(reward.rewardFormType) ? { display: "none" } : { display: "inline" } : { display: "inline" }}
                                            >
                                                {isRewardCompleted ? 'Completed' : 'Complete'}
                                            </Button>
                                        }
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
                {(isRewardCompleted && reward.note && reward.completedByDetails) &&
                    <>
                        <Col span={1}></Col>
                        <Col span={23} className="reward-note">
                            <p><span style={{ color: '#666666' }}>{`${reward?.completedByDetails?.fullName} Note: `}</span>{reward.note}</p>
                        </Col>
                    </>
                }
            </Row>
        </div>
    )

}

export default Reward;
