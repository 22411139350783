import emojis from "./emoji.json";
import bannerEmoji from "./bannerEmoji.json";

export const getEmoji = (name) => {
  return emojis[name] ? emojis[name] : "";
};

export const getBannerEmoji = (name) => {
  return bannerEmoji[name] ? bannerEmoji[name] : "";
};
