import { inject, observer } from "mobx-react";

import React from "react";
import { Spin } from "antd";
import UserFlags from "./UserFlags";
import UserUnFlags from "./UserUnFlags";
import close from "../../static/images/close.png";
import undeleted from "../../static/images/undeleted.png";

@inject("store")
@observer
class UserFlagsContainer extends React.Component {
  fetchUnFlaggedMessages = () => {
    const {
      store: {
        ProfileStore: { userId },
        DeletedMessagesStore: {
          setShowUnFlagged,
          fetchUserUnFlaggedMessages,
          userUnFlaggedLoading
        }
      }
    } = this.props;
    if (userUnFlaggedLoading) {
      return;
    }
    fetchUserUnFlaggedMessages(userId);
    setShowUnFlagged(true);
  };

  render() {
    const {
      store: {
        ProfileStore: { username, toggleFlag },
        DeletedMessagesStore: { setShowUnFlagged, resetUnFlaggedMessages }
      },
      showFlag
    } = this.props;

    return showFlag ? (
      <React.Fragment>
        <div className="user-flags">
          <span className="username">Flags from {username}</span>
          <img src={close} onClick={() => toggleFlag(false)} alt="Close" />
        </div>
        <div
          className="show-undeleted-messages"
          onClick={() => this.fetchUnFlaggedMessages()}
        >
          <img
            src={undeleted}
            className="actions-container undeleted-icon"
            alt="UnDeleted"
          />
          <span>Show Unflagged Messages</span>
        </div>
        <UserFlags />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="user-flags">
          <span className="username">UnFlagged Messages of {username}</span>
          <img
            src={close}
            onClick={() => {
              resetUnFlaggedMessages();
              setShowUnFlagged(false);
            }}
            alt="Close"
          />
        </div>
        <UserUnFlags />
      </React.Fragment>
    );
  }
}
export default UserFlagsContainer;
