export const {
    REACT_APP_API_URL,
    REACT_APP_PUBKEY,
    REACT_APP_SUBKEY,
    REACT_APP_SYMMETRIC_KEY,
    REACT_APP_PUBNUB_REST_ENDPOINT,
    REACT_APP_SECRET_KEY,
    REACT_APP_SITE_SECRET_KEY,
    REACT_APP_ENVIRONMENT,
    REACT_APP_CREDENTIALS_KEY,
    REACT_APP_FROM_NUMBER,
    REACT_APP_BULK_SMS_FROM_NUMBER,
    REACT_APP_GET_STREAM_API_KEY,
    REACT_APP_GET_STREAM_APP_ID,
    REACT_APP_GET_FEED_GROUP,
    REACT_APP_GET_FEED_ID,
    REACT_APP_REACTION_FEED_GROUP_ID,
} = process.env;
