import React from 'react';

import { USER_BADGE_IMAGES } from '../../constants/BadgeConstant';

const UserBadges = (props) => {
    const { badgeType = '', className = 'user-badges-img' } = props;

    return (
        <>
            {badgeType ?
                (<img src={USER_BADGE_IMAGES[badgeType]} className={className} alt="user-badge" />)
                : null}
        </>
    );
};

export default UserBadges;