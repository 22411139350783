export const sub = (str1, str2) => {
  let result = "";
  let str1Length = str1.length;
  let str2Length = str2.length;
  if (str2Length > str1Length) {
    let temp = str2;
    str2 = str1;
    str1 = temp;
  }
  let borrow = 0;
  let a;
  let b;
  let temp;
  for (let i = 0; i < str1.length; i++) {
    a = parseInt(str1.charAt(str1.length - 1 - i)) - borrow;
    b = parseInt(str2.charAt(str2.length - 1 - i));
    b = b ? b : 0;
    a < b ? (borrow = 1) : (borrow = 0);
    temp = borrow ? (10 + a - b).toString() : (a - b).toString();
    result = temp + result;
  }
  return result;
};
