import { inject, observer } from "mobx-react";

import React from "react";
import { Spin } from "antd";
import { toJS } from "mobx";
import InfiniteScroll from "react-infinite-scroller";
import close from "../../static/images/close.png";

@inject("store")
@observer
class InAppSurvey extends React.Component {
  getAnswerToFormQuestion = (question) => {
    const {
      store: {
        ReferralStore: {
          inAppMultipleChoices,
          inAppMultipleChoicesRadio,
          inAppDescriptiveAnswer,
        },
      },
    } = this.props;

    const questionId = question.id;
    if (question.type === "multiple_choices") {
      const options = question.options;
      const answerObjArr = inAppMultipleChoices[questionId];
      if (answerObjArr && answerObjArr.length) {
        const optArr = answerObjArr;
        const ans = [];
        optArr.forEach((opt) => {
          const isOp = options.filter((options) => options.id === Number(opt));
          if (isOp.length) {
            ans.push(isOp[0].option);
          }
        });
        return ans.join(",");
      } else {
        return "not found";
      }
    } else if (question.type === "multiple_choices_radio") {
      const options = question.options;
      const answer = inAppMultipleChoicesRadio[questionId];
      if (answer) {
        const opt = options.filter((option) => option.id === answer);
        if (opt.length) {
          return opt[0].option;
        } else {
          return "not found";
        }
      } else {
        return "not found";
      }
    } else if (question.type === "descriptive") {
      if (inAppDescriptiveAnswer[questionId]) {
        return inAppDescriptiveAnswer[questionId];
      } else return "not found";
    }
  };

  render() {
    const {
      store: {
        ReferralStore: { inAppFormQuestions, fetchingReferralData },
      },
    } = this.props;

    return fetchingReferralData ? (
      <div className="saving-form">
        <Spin />
      </div>
    ) : (
      <div>
        {inAppFormQuestions.map((question, index) => {
          return (
            <div className="rsn-questions" key={question.id}>
              <span className="question-number">{index + 1}. </span>
              <span className="subHeading">{question.questionTitle}</span>
              <div className="form-answer">
                {this.getAnswerToFormQuestion(question)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default InAppSurvey;
