import React from 'react';
import { Button, Input, Spin } from 'antd';
import { useEffect } from 'react';
import { useRef } from 'react';
import { customRewards } from '../../en.json';
import moment from 'moment';
import AuthStore from '../../stores/AuthStore';
import { MAX_REWARD_AMOUNT } from '../../constants/GlobalConstant';
import { MODERATOR } from '../../constants/UserRolesConstant';
export const CustomRewardInput = (props) => {
    const {type} = AuthStore;

    const { expiredAt, startedAt, onChangeExpiredDate, onChangeStartedDate, onCancel, onSubmit, rewardCriteria, rewardAmount, onChangeRewardCriteria, onChangeAmount, rewardName, onChangeName, isSaveButtonDisabled, messageToShow, submitLoading } = props

    const addTask = true;
    const rewardAmountRef = useRef(rewardAmount);
    useEffect(() => {
        rewardAmountRef.current = rewardAmount;
    }, [rewardAmount]);
    const isRewardAmountZeroAfterInput = rewardAmountRef.current === 0 && rewardAmount !== 0;

    return (
        <>
            <div className="user-additional-details-container">
                <div className="user-details-wrapper mb-0 pb-0">
                    <div className="row-wrapper " style={{ padding: '21px' }} >
                        <div className="user-details-wrapper-inner">
                            <span>{customRewards.rewardAmount}</span><br />
                            <Input
                                style={{ width: '70%' }}
                                onChange={(event) => onChangeAmount(event)}
                                placeholder="$"
                                value={rewardAmount !== null ? rewardAmount : ""}
                                min={1}
                                max={type === MODERATOR ? MAX_REWARD_AMOUNT.MODERATOR : MAX_REWARD_AMOUNT.SA}
                                required={true}
                            />
                        </div>
                        <div className="user-details-wrapper-inner">
                            <span>{customRewards.rewardName}</span><br />
                            <Input
                                style={{ width: '70%' }}
                                onChange={(event) => onChangeName(event)}
                                placeholder="Reward name"
                                value={rewardName !== null ? rewardName : ""}
                                required={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="user-details-wrapper mb-0 " style={{ marginLeft: '33px' }}>
                    <div className="row-wrapper mt-20">
                        <div className="user-details-wrapper-inner">
                            <span className="ml-1">{customRewards.startDate}</span><br />
                            <input
                                type="date"
                                className="form-control"
                                value={startedAt ? startedAt : ""}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={onChangeStartedDate}
                                data-field="newDOB"
                                style={{ marginLeft: 10, width: '70%' }}
                                min={moment().format('YYYY-MM-DDTHH:mm:ss.SSS Z').split("T")[0]}
                            />
                        </div>
                        <div className="user-details-wrapper-inner">
                            <span >{customRewards.expiryDate}</span><br />
                            <input
                                type="date"
                                className="form-control"
                                value={expiredAt ? expiredAt : ""}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={onChangeExpiredDate}
                                data-field="newDOB"
                                style={{ width: '70%' }}
                                min={moment().format('YYYY-MM-DDTHH:mm:ss.SSS Z').split("T")[0]}
                            />
                        </div>
                    </div>
                </div>
                <div className="user-details-wrapper mb-0">
                    <div className="row-wrapper">
                        <div className="user-details-wrapper-inner d-flex align-items-center ml-2 justify-content-between mr-2">
                            <div className="user-details-wrapper-inner">

                                <span>{customRewards.rewardCriteria}</span><br />
                                <Input
                                    style={{ width: '50%', marginBottom: 20 }}
                                    onChange={(event) => onChangeRewardCriteria(event)}
                                    placeholder="Reward Criteria"
                                    required={true}
                                    value={rewardCriteria !== null ? rewardCriteria : ""}
                                />
                            </div>

                            <div>
                                <Button
                                    type="default"
                                    size="large"
                                    style={{ width: "145px", border: 'none' }}
                                    onClick={() => onCancel()}
                                >
                                    {customRewards.cancel}
                                </Button>
                                {submitLoading ? <Spin style={{ marginTop: '20px'}} /> : <Button
                                    className="download-button"
                                    type="primary"
                                    size="large"
                                    style={{ width: "145px" }}
                                    onClick={() => onSubmit()}
                                    disabled={isSaveButtonDisabled() || (rewardAmount === 0)}
                                >
                                    {customRewards.save}
                                </Button>}
                            </div>
                        </div>

                    </div>
                </div>
                {messageToShow && (
                    <div style={{ color: 'red', marginLeft: "44px", marginBottom: "10px" }}>{messageToShow}</div>
                )}
                {!messageToShow && isRewardAmountZeroAfterInput && (
                    <div style={{ color: 'red', marginLeft: "44px", marginBottom: "10px" }}>{customRewards.isRewardAmountZeroAfterInput}</div>
                )}
            </div>
        </>

    )
}