import { connect } from 'getstream';
import SecureLocalStorage from "../utils/SecureLS";
import { REACT_APP_GET_STREAM_API_KEY,REACT_APP_GET_STREAM_APP_ID } from '../configs/EnvConfig';
import AuthStore from '../stores/AuthStore';

export let streamClient;

export const setStreamInstance = async () => {
    let stream_token = SecureLocalStorage.get("STREAM_TOKEN");
    if (!stream_token) {
        await AuthStore.setGetStreamToken();
        stream_token = SecureLocalStorage.get("STREAM_TOKEN");
    }
    streamClient = await connect(REACT_APP_GET_STREAM_API_KEY, stream_token,REACT_APP_GET_STREAM_APP_ID );
    return streamClient;
};