import React from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';

// const COLORS = ['#6D53DC', '#5EB7E5', '#3BA340', '#3045E6'];
const COLORS = ['#5EB7E5', '#AB91C5', '#FD6A38', '#1544ED', '#7B10B0']

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, value, percent, index }) => {
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill={COLORS[index % COLORS.length]} fontSize="18px" fontWeight="bold" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${name} ${value}`}
        </text>
    );
};

const SimplePieChart = (props) => {
    const { data = [], dataKey = '' } = props;

    const CustomTooltip = ({ payload }) => {
        const [dataSetObj] = payload;
        return (
            <div>
                <div className="ant-popover-arrow" />
                <div className="">
                    <b>{dataSetObj?.name}</b>
                    <span className="">
                        {/* <p className="desc">
                            <small>{payload?.[0]?.payload?.value}</small>
                        </p> */}
                        <p>{dataSetObj?.value} </p>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart width={800} height={500}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    // label
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey={dataKey}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default SimplePieChart;