import React from "react";
import { Spin } from "antd";
import { inject, observer } from "mobx-react";

import { CBRS } from "../../en.json";

@inject("store")
@observer
class InviteMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInvitedMessageVisible: false,
    };
  }

  startMemberInvite = (value) => {
    const {
      store: {
        ReferralStore: { sendInvite },
      },
    } = this.props;
    sendInvite();
    this.setState({ isInvitedMessageVisible: false });
  };

  changeValue = (e) => {
    const {
      store: {
        ReferralStore: { setClientDetails },
      },
    } = this.props;
    const dataFieldName = e.target.getAttribute("data-field");
    setClientDetails(dataFieldName, e.target.value);
  };

  handleNextClick = () => {
    const {
      store: {
        ReferralStore: {
          invitedMemberName,
          setClientDetails
        },
      },
    } = this.props;
    setClientDetails('invitedMessageForMember', `Hello ${invitedMemberName} ${CBRS.inviteMessageForMember}`);
    this.setState({ isInvitedMessageVisible: true });
  };

  handleBackClick = () => {
    this.setState({ isInvitedMessageVisible: false });
  };

  render() {
    const {
      store: {
        ReferralStore: {
          sendInviteDisabled,
          invitedMemberName,
          invitedMemberPhone,
          invitedMessageForMember,
          sendingInvite,
          isNotaPhoneNumber,
        },
      },
    } = this.props;
    const { isInvitedMessageVisible } = this.state;

    return (
      <div>
        <div className="referral-section">
          <h1>Invite Member</h1>
          <div className="start-referral text-left">
            Put in the requested patient information, and the user will be sent
            sign up instructions by SMS.
          </div>
          <div className="referral-box">
            <div className="refer-input-container">
              {!isInvitedMessageVisible ? (<>
                <div className="invite-sms">{CBRS.name}</div>
                <input
                  onChange={this.changeValue}
                  placeholder="Full Name"
                  value={invitedMemberName}
                  className="referral-input"
                  data-field="invitedMemberName"
                />
                <div className="invite-sms">{CBRS.phone}</div>
                <input
                  onChange={this.changeValue}
                  placeholder={CBRS.phonePlaceHolder}
                  value={invitedMemberPhone}
                  className="referral-input"
                  data-field="invitedMemberPhone"
                  style={
                    invitedMemberPhone.length
                      ? isNotaPhoneNumber
                        ? { borderBottom: "2px solid red" }
                        : null
                      : null
                  }
                />
              </>) : (<>
                <div className="invite-sms">{CBRS.message}</div>
                <textarea
                  onChange={this.changeValue}
                  placeholder="Please write a message here to invite members..."
                  value={invitedMessageForMember}
                  className="referral-input message-input"
                  data-field="invitedMessageForMember"
                  style={invitedMessageForMember?.length ? null : { borderBottom: "2px solid red" }}
                />
              </>)}
            </div>
          </div>
          {sendingInvite ? (
            <div className="form-loading">
              <Spin />
            </div>
          ) : null}
          <div className="send-referral">
            {isInvitedMessageVisible ?
              (<>
                <button
                  className="new-referral-button-secondary"
                  onClick={this.handleBackClick}
                >Back</button>
                <button
                  disabled={!(invitedMessageForMember && invitedMessageForMember.length)}
                  className="new-referral-button"
                  onClick={() => this.startMemberInvite(true)}
                >Send Invite</button>
              </>)
              : <button
                disabled={sendInviteDisabled || sendingInvite}
                className="new-referral-button"
                onClick={this.handleNextClick}
              >Next</button>}
          </div>
        </div>
      </div>
    );
  }
}

export default InviteMember;