import { types } from "mobx-state-tree";
import ApiService from "../utils/ApiService";
import FormQuestion from "./FormQuestion";
import ReferralItem from "./ReferralItem";
import AuthStore from "../stores/AuthStore";
import NotificationStore from "../stores/NotificationStore";
import moment from "moment";
import FormAnswer from "./FormAnswer";
import _ from "lodash";
import Option from "./Option";
import { referral, invite, CBRS } from "../en.json";
import { toJS } from "mobx";
import Event from "./Event";
import { ulid } from "../utils/getRandomId";
import { getFormattedResponses } from "../utils/getFormattedResponses";
import { documentUpload } from "../utils/uploadFile";
import { hasIntake } from "../utils/hasIntake";
import { IS_STAGING } from "../utils/getEnvironment";
import { getNormalizedPhoneNumber } from "../utils/CommonUtils";
const mixpanel = require("mixpanel-browser");
const {
  REACT_APP_RSN_FORM: RSN_FORM,
  REACT_APP_RC_FORM: RC_FORM,
  REACT_APP_RSN_RC_FORM: RSN_RC_FORM,
  REACT_APP_INTAKE_SITE: INTAKE_SITE,
} = process.env;

const Referral = types
  .model("Referral", {
    newCBRSReferral: false,
    showReferralForm: false,
    newIntake: false,
    selfIntake: false,
    updatingStatus: false,
    questionsLoading: false,
    hasPreviousResponses: false,
    referralFormQuestions: types.array(FormQuestion),
    inAppFormQuestions: types.array(FormQuestion),
    selectedForm: types.frozen({
      name: types.maybeNull(types.string),
      id: types.maybeNull(types.number),
      timeToken: types.maybeNull(types.string),
    }),
    referrals: types.array(ReferralItem),
    referralsLoading: false,
    referralPagination: types.frozen({
      pageSize: 10,
      total: 0,
      current: 1,
    }),
    intakeFilter: 0,
    referralFilter: 0,
    intakePagination: types.frozen({
      pageSize: 10,
      total: 0,
      current: 1,
    }),
    intakes: types.array(ReferralItem),
    savingForm: false,
    userFormId: types.maybeNull(types.number),
    inAppUserFormId: types.maybeNull(types.number),
    inAppMessageId: types.maybeNull(types.number),
    referralTotal: 0,
    creatingUser: false,
    fetchingReferralData: false,
    creatingForm: false,
    makingEntry: false,
    intakeLoading: false,
    currentStep: 0,
    clientPhone: "",
    clientDOB: "",
    clientUsername: "",
    noPhone: false,
    clientFullName: "",
    inviteSMS: "",
    clientDisplayPhone: types.maybeNull(types.string),
    multipleChoices: types.maybe(types.frozen({})),
    multipleChoicesRadio: types.maybe(types.frozen({})),
    descriptiveAnswer: types.maybe(types.frozen({})),
    inAppMultipleChoices: types.maybe(types.frozen({})),
    inAppMultipleChoicesRadio: types.maybe(types.frozen({})),
    inAppDescriptiveAnswer: types.maybe(types.frozen({})),
    initialMultipleChoices: types.maybe(types.frozen({})),
    initialMultipleChoicesRadio: types.maybe(types.frozen({})),
    initialDescriptiveAnswer: types.maybe(types.frozen({})),
    formAnswers: types.array(FormAnswer),
    inAppFormAnswers: types.array(FormAnswer),
    clientSelectedForm: types.maybeNull(types.string),
    targetUserId: types.maybeNull(types.number),
    targetUsername: types.maybeNull(types.string),
    referralMessageId: types.maybeNull(types.string),
    currentReferral: types.frozen({
      id: types.number,
    }),
    hasDuplicateError: false,
    hasInAppSurvey: false,
    alreadyFinished: false,
    isDataSaved: false,
    currentStatus: types.maybeNull(types.number),
    historyLoading: false,
    exportHistoryVisible: false,
    statusHistoryVisible: false,
    assignHistoryVisible: false,
    skipAssignHistory: 0,
    totalAssignHistory: 0,
    assignHistory: types.array(Event),
    skipStatusHistory: 0,
    totalStatusHistory: 0,
    statusHistory: types.array(Event),
    skipExportHistory: 0,
    totalExportHistory: 0,
    exportHistory: types.array(Event),
    invitedMemberName: "",
    invitedAdminName: "",
    invitedAdminEmail: "",
    sendingInvite: false,
    creatingAdmins: false,
    invitedMemberPhone: "",
    invitedMessageForMember: "",
    referralModalVisible: false,
    intakeModalVisible: false,
    assignToIds: types.array(types.number),
    failedToCreate: types.array(
      types.frozen({
        username: types.string,
        errMsg: types.string,
      })
    ),
    createdAdmins: types.array(types.string),
  })
  .actions((self) => ({
    resetHistory() {
      self.skipExportHistory = 0;
      self.totalExportHistory = 0;
      self.exportHistory = [];
      self.assignHistory = [];
      self.skipAssignHistory = 0;
      self.totalAssignHistory = 0;
      self.skipStatusHistory = 0;
      self.totalStatusHistory = 0;
      self.statusHistory = [];
      self.historyLoading = false;
    },
    setReferralModalVisible(value) {
      self.referralModalVisible = value;
    },
    setIntakeModalVisible(value) {
      self.intakeModalVisible = value;
    },
    setSendingInvite(value) {
      self.sendingInvite = value;
    },
    setCreatingAdmins(value) {
      self.creatingAdmins = value;
    },
    async CreateAdmins(data) {
      self.setCreatingAdmins(true);
      data.forEach(async (user) => {
        const groups = user.groups ? user.groups.split(",") : [];
        const dateOfBirth = moment(user.dob, "YYYY-MM-DD").format("YYYY-MM-DD");
        const userParams = {
          username: user.username,
          email: user.email,
          password: user.password,
          siteId: Number(user.siteId),
          phoneNumber: getNormalizedPhoneNumber(user.phone),
          fullName: user.fullName,
          type: user.type,
          dob: dateOfBirth,
        };

        const userResponse = await ApiService.postRequest("users", userParams);
        if (userResponse.success) {
          const bulkParams = {
            name: user.fullName,
            password: user.password,
            username: user.username,
            email: user.email,
          };
          ApiService.postRequest("bulk-account-create", bulkParams);
          const userId = userResponse.data.id;
          self.addToCreated(user.username);
          groups.forEach(async (groupId) => {
            const groupParams = {
              userId,
              groupId: Number(groupId),
            };
            const groupResponse = await ApiService.postRequest(
              "user-groups",
              groupParams
            );
            mixpanel.track("Added to Group Chat", { from: "WEB", userId, channelId: Number(groupId) });
            if (groupResponse.success) {
              self.clearCreateAdmins();
              self.setCreatingAdmins(false);
            } else {
              self.clearCreateAdmins();
              self.setCreatingAdmins(false);
            }
          });
        } else {
          try {
            self.addToFailed(user.username, userResponse.errors[0].message);
          } catch {
            NotificationStore.setNotification(
              "error",
              "Please verify the format in the csv file"
            );
            self.setCreatingAdmins(false);
            return;
          }
          self.setCreatingAdmins(false);
        }
      });
    },
    addToCreated(username) {
      self.createdAdmins = [...self.createdAdmins, username];
    },
    addToFailed(username, message) {
      self.failedToCreate = [
        ...self.failedToCreate,
        { username: username, errMsg: message },
      ];
    },
    async deleteReferral() {
      self.updatingReferralStatus(true);
      const response = await ApiService.deleteRequest(
        "refer",
        self.currentReferral.id
      );
      if (response.success) {
        self.resetIntakePagination();
        self.fetchAllIntake();
        self.setSelfIntake(false);
        self.setShowReferralForm(false);
        self.updatingReferralStatus(false);
        NotificationStore.setNotification("success", CBRS.deleteRefSuccess);
      } else {
        self.updatingReferralStatus(false);
        NotificationStore.setNotification("error", CBRS.deleteRefError);
      }
    },
    async sendInvite() {
      try {
        self.setSendingInvite(true);
        const { invitedMemberName: name, invitedMemberPhone: number, invitedMessageForMember: message } = self;
        const params = {
          name,
          number,
          message,
          email: "",
        };
        const response = await ApiService.postRequest("send-message", params);
        if (response.success) {
          self.setSendingInvite(false);
          self.clearInvite();
          NotificationStore.setNotification("success", invite.sentSuccess);
        } else {
          NotificationStore.setNotification("error", invite.sentFailure);
          self.setSendingInvite(false);
          return;
        }
      } catch (error) {
        mixpanel.track('Member Invite Error', {
          from: 'WEB',
          error: error ? JSON.stringify(error) : 'Error in inviting members'
        });
      }
    },
    async sendAdminInvite() {
      self.setSendingInvite(true);
      let params = {
        name: self.invitedAdminName,
        email: self.invitedAdminEmail,
      };
      const response = await ApiService.postRequest("invite-admin", params);
      if (response.success) {
        self.setSendingInvite(false);
        self.clearAdminInvite();
        NotificationStore.setNotification("success", invite.sentSuccess);
      } else {
        NotificationStore.setNotification("error", invite.sentFailure);
        self.setSendingInvite(false);
        return;
      }
    },
    clearCreateAdmins() {
      self.failedToCreate = [];
      self.createdAdmins = [];
    },
    clearAdminInvite() {
      self.invitedAdminName = "";
      self.invitedAdminEmail = "";
    },
    clearInvite() {
      self.invitedMemberName = "";
      self.invitedMemberPhone = "";
      self.invitedMessageForMember = "";
    },
    setHasInAppSurvey(value) {
      self.hasInAppSurvey = value;
    },
    setExportHistoryVisible(value) {
      self.exportHistoryVisible = value;
    },
    setAssignHistoryVisible(value) {
      self.assignHistoryVisible = value;
    },
    setStatusHistoryVisible(value) {
      self.statusHistoryVisible = value;
    },
    resetAll() {
      self.resetIntakePagination();
      self.resetReferralPagination();
      self.resetClientData();
      self.resetResponses();
      self.newCBRSReferral = false;
      self.showReferralForm = false;
      self.newIntake = false;
      self.alreadyFinished = false;
      self.hasInAppSurvey = false;
      self.currentStatus = null;
      self.currentStep = 0;
      self.selfIntake = false;
      self.currentReferral = {};
    },
    async fetchExportHistory() {
      const endPoint = "history";
      const params = {
        eventType: 16,
        targetUserId: self.targetUserId,
        $skip: self.skipExportHistory,
      };
      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateExportHistoryMeta(_.cloneDeep(historyResponse.meta));
        self.setExportHistory(_.cloneDeep(historyResponse.data));
        self.setHistoryLoading(false);
      } else {
        self.setHistoryLoading(false);
      }
    },
    async fetchAssignHistory() {
      const endPoint = "history";
      const params = {
        "$or[0][eventType]": 19,
        "$or[1][eventType]": 21,
        "$or[2][eventType]": 22,
        "$sort[createdAt]": -1,
        meta: self.currentReferral.id.toString(),
        $skip: self.skipExportHistory,
      };
      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateAssignHistoryMeta(_.cloneDeep(historyResponse.meta));
        self.setAssignHistory(_.cloneDeep(historyResponse.data));
        self.setHistoryLoading(false);
      } else {
        self.setHistoryLoading(false);
      }
    },
    async fetchStatusHistory(targetUserId) {
      self.setTargetUserId(targetUserId);
      const endPoint = "history";
      const params = {
        eventType: 17,
        targetUserId,
        $skip: self.skipExportHistory,
      };
      self.setHistoryLoading(true);
      const historyResponse = await ApiService.getRequest(endPoint, params);
      if (historyResponse.success) {
        self.updateStatusHistoryMeta(_.cloneDeep(historyResponse.meta));
        self.setStatusHistory(_.cloneDeep(historyResponse.data));
        self.setHistoryLoading(false);
      } else {
        self.setHistoryLoading(false);
      }
    },
    async postExportData(url) {
      const adminId = AuthStore.userId;
      const endPoint = "history";
      const params = {
        eventType: 16,
        sourceUserId: adminId,
        targetUserId: self.targetUserId,
        meta: url,
      };
      const historyResponse = await ApiService.postRequest(endPoint, params);
      if (historyResponse.success) {
        self.setHistoryLoading(false);
      } else {
        self.setHistoryLoading(false);
      }
    },
    setHistoryLoading(value) {
      self.historyLoading = value;
    },
    setExportHistory(data) {
      const history = data.map((event) => {
        return Event.create(event);
      });
      self.exportHistory = [...self.exportHistory, ...history];
    },
    setAssignHistory(data) {
      const history = data.map((event) => {
        return Event.create(event);
      });
      self.assignHistory = [...self.assignHistory, ...history];
    },
    setStatusHistory(data) {
      const history = data.map((event) => {
        return Event.create(event);
      });
      self.statusHistory = [...self.statusHistory, ...history];
    },
    updateExportHistoryMeta(meta) {
      self.skipExportHistory = self.skipExportHistory + meta.limit;
      self.totalExportHistory = meta.total;
    },
    updateAssignHistoryMeta(meta) {
      self.skipAssignHistory = self.skipAssignHistory + meta.limit;
      self.totalAssignHistory = meta.total;
    },
    updateStatusHistoryMeta(meta) {
      self.skipStatusHistory = self.skipStatusHistory + meta.limit;
      self.totalStatusHistory = meta.total;
    },
    setDataSaved(value) {
      self.isDataSaved = value;
    },
    setNewReferral(value) {
      self.newCBRSReferral = value;
    },
    setReferralFilter(key) {
      self.referralFilter = key;
      self.resetReferralPagination();
      self.fetchAllReferrals();
    },
    setIntakeFilter(key) {
      self.intakeFilter = key;
      self.resetIntakePagination();
      self.fetchAllIntake();
    },
    setInitalMultipleChoices(id, answer) {
      self.initialMultipleChoices = {
        ...self.initialMultipleChoices,
        ...{ [id]: answer },
      };
    },
    setInitialMultipleChoicesRadio(id, answer) {
      self.initialMultipleChoicesRadio = {
        ...self.initialMultipleChoicesRadio,
        ...{ [id]: answer },
      };
    },
    setInitialDescriptive(id, answer) {
      self.initialDescriptiveAnswer = {
        ...self.initialDescriptiveAnswer,
        ...{ [id]: answer },
      };
    },
    setInAppMultipleChoices(id, checkedValue) {
      self.inAppMultipleChoices = {
        ...self.inAppMultipleChoices,
        ...{ [id]: checkedValue },
      };
    },
    setInAppMultipleChoicesRadio(id, value) {
      self.inAppMultipleChoicesRadio = {
        ...self.inAppMultipleChoicesRadio,
        ...{ [id]: value },
      };
    },
    setInAppDescriptive(id, value) {
      const text = value;
      self.inAppDescriptiveAnswer = {
        ...self.inAppDescriptiveAnswer,
        ...{ [id]: text },
      };
    },
    setMultipleChoices(id, checkedValue) {
      // if (!checkedValue.length) {
      //   self.multipleChoices = _.omit(self.multipleChoices, id);
      // }
      // else {
      self.multipleChoices = {
        ...self.multipleChoices,
        ...{ [id]: checkedValue },
        // };
      };
    },
    setMultipleChoicesRadio(id, value) {
      self.multipleChoicesRadio = {
        ...self.multipleChoicesRadio,
        ...{ [id]: value },
      };
    },
    setDescriptive(id, value) {
      const text = value;
      const afterTrim = text.trim();
      // if (!afterTrim) {
      //   self.descriptiveAnswer = _.omit(self.descriptiveAnswer, id);
      // }
      // else {
      self.descriptiveAnswer = {
        ...self.descriptiveAnswer,
        ...{ [id]: text },
        // };
      };
    },
    resetReferralPagination() {
      self.referralPagination = {
        pageSize: 10,
        current: 1,
        total: 0,
      };
    },
    resetIntakePagination() {
      self.intakePagination = {
        pageSize: 10,
        current: 1,
        total: 0,
      };
    },

    setReferralPagination(pagination) {
      self.referralPagination = pagination;
    },
    setIntakePagination(pagination) {
      self.intakePagination = pagination;
    },
    resetClientData() {
      self.clientPhone = "";
      self.clientDOB = "";
      self.noPhone = false;
      self.clientFullName = "";
      self.inviteSMS = "";
      self.clientSelectedForm = null;
    },
    parseMetaData(meta) {
      return {
        pageSize: meta.limit,
        current: meta.skip / meta.limit + 1,
        total: meta.total,
      };
    },
    async fetchAllReferrals(params = {}) {
      const adminSiteId = AuthStore.siteId;
      self.setReferralsLoading(true);
      params = {
        ...params,
        siteId: adminSiteId,
        $skip:
          (self.referralPagination.current - 1) *
          self.referralPagination.pageSize,
        "$sort[createdAt]": -1,
      };
      if (self.referralFilter) {
        if (self.referralFilter === 1) {
          params = {
            ...params,
            status: 100,
          };
        } else if (self.referralFilter === 2) {
          params = {
            ...params,
            "status[$gt]": 100,
          };
        }
      }
      const response = await ApiService.getRequest("refer", params);
      if (response.success) {
        self.setReferrals(_.cloneDeep(response.data));
        self.setReferralPagination(
          self.parseMetaData(_.cloneDeep(response.meta))
        );
        self.setReferralsLoading(false);
      } else {
        self.setReferralsLoading(false);
        return;
      }
    },
    async fetchAllIntake(params = {}) {
      const adminSiteId = AuthStore.siteId;
      self.setIntakeLoading(true);
      params = {
        ...params,
        $skip:
          (self.intakePagination.current - 1) * self.intakePagination.pageSize,
        "$sort[createdAt]": -1,
      };
      if (self.intakeFilter) {
        if (self.intakeFilter === 1) {
          params = {
            ...params,
            "status[$gt]": 100,
            "status[$lt]": 104,
          };
        } else if (self.intakeFilter === 2) {
          params = {
            ...params,
            "status[$gt]": 103,
            "status[$lt]": 107,
          };
        } else if (self.intakeFilter === 3) {
          params = {
            ...params,
            "status[$gt]": 106,
          };
        }
      } else {
        params = {
          ...params,
          "$or[0][status][$gt]": 100,
          "$or[1][$and][0][siteId][$eq]": Number(INTAKE_SITE),
          "$or[1][$and][1][status][$eq]": 100,
        };
      }

      const response = await ApiService.getRequest("refer", params);
      if (response.success) {
        self.setIntakes(_.cloneDeep(response.data));

        self.setIntakePagination(
          self.parseMetaData(_.cloneDeep(response.meta))
        );
        self.setIntakeLoading(false);
      } else {
        self.setIntakeLoading(false);
        return;
      }
    },
    setCurrentStatus(statusId) {
      self.currentStatus = statusId;
    },
    setHasAlreadyFinished(value) {
      self.alreadyFinished = value;
    },
    async getFinishedReferralResponse(referralData) {
      self.setCurrentReferral(referralData);
      self.setHasAlreadyFinished(true);
      self.setFetchingReferralData(true);
      const inAppParams = {
        messageId: referralData.formId,
        userId: referralData.targetUserId,
        submittedFrom: "REFER",
      };
      const formResponse = await ApiService.getRequest(
        "user-forms",
        inAppParams
      );
      if (formResponse.success) {
        await self.setUserFormData(formResponse.data[0]);
        const formId = formResponse.data[0].formId;
        const userFormId = formResponse.data[0].id;
        await self.getInAppFormQuestions(formId);
        await self.getInAppFormAnswers(userFormId);
        self.setFetchingReferralData(false);
      } else {
        self.setFetchingReferralData(false);
      }
    },
    async generateForm(referralData) {
      self.setFetchingReferralData(true);
      const formId =
        self.clientSelectedForm === "RSN"
          ? Number(RSN_FORM)
          : self.clientSelectedForm === "RC"
            ? Number(RC_FORM)
            : self.clientSelectedForm === "RSN_RC"
              ? Number(RSN_RC_FORM)
              : null;
      const params = {
        userId: referralData.targetUserId,
        formId,
        submittedFrom: "REFER",
        submittedFromId: referralData.targetUserId,
        messageId: referralData.formId,
      };
      const response = await ApiService.postRequest("user-forms", params);
      if (response.success) {
        await self.setUserFormData(response.data);
        await self.getFormQuestions(formId);
        await self.getFormAnswers();
        self.setSelfIntake(true);
        self.setFetchingReferralData(false);
      } else {
        self.setFetchingReferralData(false);
        return;
      }
    },
    async getSelfReferralResponse(referralData) {
      self.resetResponses();
      self.setFetchingReferralData(true);
      self.setDataSaved(false);
      self.setCurrentReferral(referralData);
      self.setCurrentStatus(referralData.status);
      self.setHasInAppSurvey(true);
      const params = {
        messageId: referralData.formId,
        userId: referralData.targetUserId,
        submittedFrom: "REFER",
      };
      const response = await ApiService.getRequest("user-forms", params);

      if (response.success) {
        if (response.data.length) {
          await self.setUserFormData(response.data[0]);
          await self.setTargetUserData(
            response.data[0].userId,
            referralData.targetUserInfo.username
          );
          const formId = response.data[0].formId;
          await self.getFormQuestions(formId);
          await self.getFormAnswers();
          self.setSelfIntake(true);
        } else {
          self.setNewIntake(true);
        }
        self.setFetchingReferralData(false);
      }
      const inAppParams = {
        messageId: referralData.formId,
        userId: referralData.targetUserId,
        "submittedFrom[$ne]": "REFER",
      };
      const formResponse = await ApiService.getRequest(
        "user-forms",
        inAppParams
      );

      if (formResponse.success) {
        const inAppFormId = formResponse.data[0].formId;
        const userFormId = formResponse.data[0].id;
        await self.getInAppFormQuestions(inAppFormId);
        await self.getInAppFormAnswers(userFormId);
        self.setFetchingReferralData(false);
      }
    },
    async getReferalResponse(referralData) {
      const adminSiteId = AuthStore.siteId;
      self.resetResponses();
      self.setFetchingReferralData(true);
      self.setDataSaved(false);
      self.setCurrentReferral(referralData);
      self.updateAssignToIds(referralData.alreadyAssigned);
      self.setCurrentStatus(referralData.status);
      const params = {
        messageId: referralData.formId,
        userId: referralData.targetUserId,
        submittedFrom: "REFER",
      };
      const response = await ApiService.getRequest("user-forms", params);

      if (response.success) {
        await self.setUserFormData(response.data[0]);
        await self.setTargetUserData(
          response.data[0].userId,
          referralData.targetUserInfo.username
        );
        const formId = response.data[0].formId;
        // if (referralData.sourceUserId === -1) {
        //   self.setHasInAppSurvey(true);
        //   const inAppParams = {
        //     messageId: referralData.formId,
        //     userId: referralData.targetUserId,
        //     "submittedFrom[$ne]": "REFER",
        //   };
        //   const formResponse = await ApiService.getRequest(
        //     "user-forms",
        //     inAppParams
        //   );

        //   if (formResponse.success) {
        //     const inAppFormId = formResponse.data[0].formId;
        //     const userFormId = formResponse.data[0].id;
        //     await self.getInAppFormQuestions(inAppFormId);
        //     await self.getInAppFormAnswers(userFormId);
        //   }
        // } else {
        //   self.setHasInAppSurvey(false);
        // }
        await self.getFormQuestions(formId);
        await self.getFormAnswers();
        if (hasIntake(adminSiteId)) {
          self.setSelfIntake(true);
        } else {
          self.setShowReferralForm(true);
        }
        self.setFetchingReferralData(false);
      } else {
        self.setFetchingReferralData(false);
        return;
      }
    },
    setFetchingReferralData(value) {
      self.fetchingReferralData = value;
    },
    async getFormAnswers() {
      const params = {
        userFormId: self.userFormId,
        $limit: 50,
      };
      const response = await ApiService.getRequest(
        "form-question-responses",
        params
      );
      if (response.success) {
        if (!response.data.length) {
          self.setHasPreviousResponse(false);
        } else {
          self.setHasPreviousResponse(true);
        }
        self.setFormAnswers(response.data);
      } else {
      }
    },
    async getInAppFormAnswers(userFormId) {
      const params = {
        userFormId,
        $limit: 50,
      };
      const response = await ApiService.getRequest(
        "form-question-responses",
        params
      );

      if (response.success) {
        self.setInAppFormAnswers(response.data);
      }
    },
    setHasPreviousResponse(value) {
      self.hasPreviousResponses = value;
    },
    setInAppFormAnswers(data) {
      self.inAppFormAnswers = data.map((answer) => {
        self.inAppFormQuestions.forEach((question) => {
          if (answer.formQuestionId === question.id) {
            const type = question.type;
            if (type === "multiple_choices") {
              if (answer.answer) {
                let answerArray = answer.answer.split(",");
                if (answerArray.length) {
                  answerArray = answerArray.map((item) => Number(item));
                  self.setInAppMultipleChoices(question.id, answerArray);
                }
              }
            } else if (type === "multiple_choices_radio") {
              const ans = answer.formQuestionOptionId;
              self.setInAppMultipleChoicesRadio(question.id, ans);
            } else if (type === "descriptive") {
              const ans = answer.answer;
              self.setInAppDescriptive(question.id, ans);
            }
          }
        });
        return FormAnswer.create(answer);
      });
    },
    setFormAnswers(data) {
      self.formAnswers = data.map((answer) => {
        self.referralFormQuestions.forEach((question) => {
          if (answer.formQuestionId === question.id) {
            const type = question.type;
            if (type === "multiple_choices") {
              if (answer.answer) {
                let answerArray = answer.answer.split(",");
                if (answerArray.length) {
                  answerArray = answerArray.map((item) => Number(item));
                  self.setMultipleChoices(question.id, answerArray);
                }
              }
              self.setInitalMultipleChoices(question.id, answer);
            } else if (type === "multiple_choices_radio") {
              const ans = answer.formQuestionOptionId;
              self.setMultipleChoicesRadio(question.id, ans);
              self.setInitialMultipleChoicesRadio(question.id, answer);
            } else if (type === "descriptive") {
              const ans = answer.answer;
              self.setDescriptive(question.id, ans);
              self.setInitialDescriptive(question.id, answer);
            }
          }
        });
        return FormAnswer.create(answer);
      });
    },
    setIntakes(data) {
      self.intakes = data.map((item) => {
        item.alreadyAssigned = item.referAssignedTo.reduce((arr, user) => {
          return [...arr, user.userId];
        }, []);
        return ReferralItem.create(item);
      });
    },
    setIntakeLoading(value) {
      self.intakeLoading = value;
    },
    setReferrals(data) {
      self.referrals = data.map((item) => {
        return ReferralItem.create(item);
      });
    },
    setReferralsLoading(value) {
      self.referralsLoading = value;
    },
    setClientDetails(type, value) {
      self[type] = value;
    },
    setCurrentStep(value) {
      self.currentStep = self.currentStep + value;
    },
    resetCurrentStep() {
      self.currentStep = 0;
    },
    setSelfIntake(value) {
      self.selfIntake = value;
    },
    setNewIntake(value) {
      self.newIntake = value;
    },
    async createUser() {
      self.creating(true);
      const dateOfBirth = moment(self.clientDOB, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      const clientUsername = "user_" + ulid();
      const clientEmail = ulid() + "@email.com";
      const clientPassword = "pass" + ulid();
      const formattedPhone = self.clientPhone
        .trim()
        .replace(/[^\x00-\x7F]/g, "")
        .replace(/\D/g, "");
      let params = {
        fullName: self.clientFullName,
        username: clientUsername,
        phoneNumber: formattedPhone,
        type: "user",
        email: clientEmail,
        dob: new Date(dateOfBirth).toISOString(),
        password: clientPassword,
      };
      const response = await ApiService.postRequest("users", params);
      if (response.success) {
        self.setCurrentStep(1);
        self.creating(false);
        self.setClientData(response.data);
        await self.setTargetUserData(response.data.id, response.data.username);
        self.sendUserForm();
      } else {
        self.setDuplicateError(true);
        // NotificationStore.setNotification(
        //   "error",
        //   "A referral for this member is already in progress."
        // );
        self.creating(false);
        return;
      }
    },
    setDuplicateError(value) {
      self.hasDuplicateError = value;
    },
    setTargetUserId(id) {
      self.targetUserId = id;
    },
    setTargetUserData(id, username) {
      self.targetUserId = id;
      self.targetUsername = username;
    },
    async sendUserForm() {
      const formId =
        self.clientSelectedForm === "RSN"
          ? Number(RSN_FORM)
          : self.clientSelectedForm === "RC"
            ? Number(RC_FORM)
            : self.clientSelectedForm === "RSN_RC"
              ? Number(RSN_RC_FORM)
              : null;
      const messageId = ulid();
      const params = {
        userId: self.targetUserId,
        formId,
        submittedFrom: "REFER",
        submittedFromId: self.targetUserId,
        messageId,
      };
      self.setCreatingForm(true);
      const response = await ApiService.postRequest("user-forms", params);
      if (response.success) {
        self.setCurrentStep(1);
        await self.setUserFormData(response.data);
        self.setCreatingForm(false);
        self.makeEntryInReferral();
        self.getFormAnswers();
      } else {
        NotificationStore.setNotification("error", referral.sendFormError);
        self.setCreatingForm(false);
        return;
      }
    },
    setUserFormData(data) {
      self.referralMessageId = data.messageId;
      self.userFormId = data.id;
      self.clientSelectedForm =
        data.formId === Number(RSN_FORM)
          ? "RSN"
          : data.formId === Number(RC_FORM)
            ? "RC"
            : data.formId === Number(RSN_RC_FORM)
              ? "RSN_RC"
              : null;
    },
    async makeEntryInReferral() {
      const formId =
        self.clientSelectedForm === "RSN"
          ? Number(RSN_FORM)
          : self.clientSelectedForm === "RC"
            ? Number(RC_FORM)
            : self.clientSelectedForm === "RSN_RC"
              ? Number(RSN_RC_FORM)
              : null;
      const sourceUserId = AuthStore.userId;
      const adminSiteId = AuthStore.siteId;
      self.setMakingEntry(true);
      const params = {
        sourceUserId,
        targetUserId: self.targetUserId,
        formId: self.referralMessageId,
        isUsingApp: true,
        hasPhone: !self.noPhone,
        siteId: adminSiteId,
        program: adminSiteId,
        status: 100,
      };

      const response = await ApiService.postRequest("refer", params);
      if (response.success) {
        if (!IS_STAGING) {
          const message = `Hi there! Your HPTC provider has started a referral to CBRS on your behalf.\n\nWhile they process the referral, you can download the Marigold Health app to get access to a support immediately by texting into an anonymous peer support group. HPTC also uses Marigold to share resources and announcements. Marigold will also text you when CBRS assigns an RC or RSN to you. They will reach out to you via the Marigold app or using your provided contact information.\n\nTo set up Marigold all you have to do is download the app, hit "login with SMS" and sign in by entering your date of birth and phone number.\n\nGet started by downloading the app here:\n>iPhone: https://tinyurl.com/iphonemarigold\n>Android: https://tinyurl.com/androidmarigold\n\nIf you have any questions or issues you can message me back.`;
          const msgParams = {
            message,
            number: self.clientPhone,
          };
          const sendSms = await ApiService.postRequest(
            "send-custom-message",
            msgParams
          );
          if (sendSms.success) {
          }
        }
        self.setCurrentReferral(_.cloneDeep(response.data));
        self.setCurrentStep(1);
        self.setDataSaved(false);
        self.setMakingEntry(false);
        self.getFormQuestions(formId); // get form questions according to the form seleted
        if (hasIntake(adminSiteId)) {
          const formattedPhone = self.clientDisplayPhone
            ? "(" +
            self.clientDisplayPhone.slice(0, 3) +
            ")" +
            " " +
            self.clientDisplayPhone.slice(3, 6) +
            "-" +
            self.clientDisplayPhone.slice(6)
            : null;
          const referralObj = {
            ...response.data,
            sourceUserInfo: {
              fullName: AuthStore.fullName,
              phoneNumber: AuthStore.phoneNumber,
            },
            targetUserInfo: {
              dob: self.clientDOB,
              fullName: self.clientFullName,
              phoneNumber: formattedPhone,
              username: self.clientUsername,
            },
          };
          self.setCurrentStatus(100);
          self.setCurrentReferral(referralObj);
          self.setSelfIntake(true);
        } else {
          self.setShowReferralForm(true);
        }
        self.setNewReferral(false);
        self.resetCurrentStep();
        // self.resetClientData();
      } else {
        NotificationStore.setNotification("error", referral.errorReferral);
        self.setMakingEntry(false);
        return;
      }
    },
    setClientData(data) {
      self.clientFullName = data.fullName;
      self.clientDOB = moment(data.dob, "YYYY-MM-DD").format("YYYY-MM-DD");
      self.clientDisplayPhone = data.phoneNumber;
      self.clientUsername = data.username;
    },
    setCurrentReferral(data) {
      self.currentReferral = data;
    },
    async updateStatusInReferral(params = {}) {
      const adminSiteId = AuthStore.siteId;
      params = {
        ...params,
      };
      self.updatingReferralStatus(true);

      const response = await ApiService.patchRequest(
        "refer",
        self.currentReferral.id,
        params
      );

      if (response.success) {
        self.setCurrentStatus(response.data.status);
        self.updatingReferralStatus(false);
        if (hasIntake(adminSiteId)) {
          self.resetIntakePagination();
          self.fetchAllIntake();
        } else {
          self.resetReferralPagination();
          self.fetchAllReferrals();
        }
      } else {
        self.updatingReferralStatus(false);
        return;
      }
    },
    async updateAssignToIds(assignToIdArray) {
      self.assignToIds = assignToIdArray;
    },
    updatingReferralStatus(value) {
      self.updatingStatus = value;
    },
    creating(value) {
      self.creatingUser = value;
    },
    setCreatingForm(value) {
      self.creatingForm = value;
    },
    setMakingEntry(value) {
      self.makingEntry = value;
    },
    checkDifference() {
      Object.keys(self.multipleChoices).forEach((key) => {
        if (self.initialMultipleChoices.hasOwnProperty(key)) {
          self.formAnswers.forEach((answer) => {
            if (answer.formQuestionId === Number(key)) {
              const answerId = answer.id;
            }
          });
        }
      });
      Object.keys(self.multipleChoicesRadio).forEach((key) => {
        if (self.initialMultipleChoicesRadio.hasOwnProperty(key)) {
          self.formAnswers.forEach((answer) => {
            if (answer.formQuestionId === Number(key)) {
              const answerId = answer.id;
            }
          });
        }
      });
      Object.keys(self.descriptiveAnswer).forEach((key) => {
        if (self.initialDescriptiveAnswer.hasOwnProperty(key)) {
          self.formAnswers.forEach((answer) => {
            if (answer.formQuestionId === Number(key)) {
              const answerId = answer.id;
            }
          });
        }
      });
    },
    setEmptyResponses() {
      self.referralFormQuestions.forEach((question) => {
        if (question.type === "multiple_choices") {
          if (!self.multipleChoices.hasOwnProperty(question.id)) {
            self.setMultipleChoices(question.id, "");
          }
        } else if (question.type === "descriptive") {
          if (!self.descriptiveAnswer.hasOwnProperty(question.id)) {
            self.setDescriptive(question.id, "");
          }
        }
      });
    },
    async patchNewResponses(referralParams, shouldClose = true) {
      const adminSiteId = AuthStore.siteId;
      self.setSavingForm(true);
      const userId = self.targetUserId;
      let promises = [];
      const formId =
        self.clientSelectedForm === "RSN"
          ? Number(RSN_FORM)
          : self.clientSelectedForm === "RC"
            ? Number(RC_FORM)
            : self.clientSelectedForm === "RSN_RC"
              ? Number(RSN_RC_FORM)
              : null;
      Object.keys(self.multipleChoices).forEach((key) => {
        const ansId = self.initialMultipleChoices[key]
          ? self.initialMultipleChoices[key].id
          : null;
        const responseArr = self.multipleChoices[key];
        const ansString = responseArr.join(",");
        if (!ansId) {
          const params = {
            formId,
            messageId: self.referralMessageId,
            userId: Number(userId),
            responseFrom: "REFER",
            channelId: Number(userId),
            formQuestionId: Number(key),
            answer: ansString,
          };
          promises.push(
            ApiService.postRequest("form-question-responses", params)
          );
        } else {
          const params = {
            answer: ansString,
          };
          promises.push(
            ApiService.patchRequest("form-question-responses", ansId, params)
          );
        }
      });
      Object.keys(self.descriptiveAnswer).forEach((key) => {
        const ansId = self.initialDescriptiveAnswer[key]
          ? self.initialDescriptiveAnswer[key].id
          : null;
        const responseArr = self.descriptiveAnswer[key];
        if (!ansId) {
          const params = {
            formId,
            messageId: self.referralMessageId,
            userId: Number(userId),
            responseFrom: "REFER",
            channelId: Number(userId),
            formQuestionId: Number(key),
            answer: responseArr,
          };
          promises.push(
            ApiService.postRequest("form-question-responses", params)
          );
        } else {
          const params = {
            answer: responseArr,
          };
          promises.push(
            ApiService.patchRequest("form-question-responses", ansId, params)
          );
        }
      });
      Object.keys(self.multipleChoicesRadio).forEach((key) => {
        const ansId = self.initialMultipleChoicesRadio[key]
          ? self.initialMultipleChoicesRadio[key].id
          : null;
        const responseArr = self.multipleChoicesRadio[key];
        if (!ansId) {
          const params = {
            formId,
            messageId: self.referralMessageId,
            userId: Number(userId),
            responseFrom: "REFER",
            channelId: Number(userId),
            formQuestionId: Number(key),
            formQuestionOptionId: Number(responseArr),
          };
          promises.push(
            ApiService.postRequest("form-question-responses", params)
          );
        } else {
          const params = {
            formQuestionOptionId: Number(responseArr),
          };
          promises.push(
            ApiService.patchRequest("form-question-responses", ansId, params)
          );
        }
      });
      Promise.all(promises)
        .then(async (res) => {
          await self.updateStatusInReferral(referralParams);
          self.setSavingForm(false);
          self.setDataSaved(true);
          if (shouldClose) {
            if (hasIntake(adminSiteId)) {
              self.setSelfIntake(false);
              self.setIntakeModalVisible(false);
              self.setShowReferralForm(false);
            } else {
              self.setShowReferralForm(false);
            }
          }
          return;
        })
        .catch((err) => {
          self.setSavingForm(false);
          return false;
        });
    },
    async postNewResponses(referralParams, shouldClose = true) {
      const adminSiteId = AuthStore.siteId;
      const formId =
        self.clientSelectedForm === "RSN"
          ? Number(RSN_FORM)
          : self.clientSelectedForm === "RC"
            ? Number(RC_FORM)
            : self.clientSelectedForm === "RSN_RC"
              ? Number(RSN_RC_FORM)
              : null;
      self.setSavingForm(true);
      const userId = self.targetUserId;
      const meta = {
        formId,
        messageId: self.referralMessageId,
        channelId: userId,
        userFormId: self.userFormId,
        userId,
        responseFrom: "REFER",
      };
      await self.setEmptyResponses();
      const payload = getFormattedResponses(
        self.descriptiveAnswer,
        self.multipleChoices,
        self.multipleChoicesRadio
      );

      // if (!payload.length) {
      //   self.setSavingForm(false);
      //   self.setShowReferralForm(false);
      //   return;
      // }

      const endPoint = "create-response";
      const params = { payload, meta };
      const response = await ApiService.postRequest(endPoint, params);
      if (response.success) {
        await self.updateStatusInReferral(referralParams);
        self.setDataSaved(true);
        self.setSavingForm(false);
        if (shouldClose) {
          if (hasIntake(adminSiteId)) {
            self.setSelfIntake(false);
            self.setShowReferralForm(false);
            self.setIntakeModalVisible(false);
          } else {
            self.setShowReferralForm(false);
          }
        }

        return;
      } else {
        self.setSavingForm(false);
        return;
      }
    },
    async saveForLater() {
      const params = {
        status: self.currentStatus,
      };
      if (self.hasPreviousResponses) {
        self.patchNewResponses(params);
      } else {
        self.postNewResponses(params);
      }
      const newIds = toJS(self.assignToIds);
      const oldIds = self.currentReferral.alreadyAssigned;
      const addedIds = _.difference(newIds, oldIds);
      const removedIds = _.difference(oldIds, newIds);

      if (addedIds.length) {
        addedIds.forEach(async (id) => {
          const params = {
            userId: id,
            referId: self.currentReferral.id,
          };
          await ApiService.postRequest("refer-assign", params);
        });
      }
      if (removedIds.length) {
        removedIds.forEach(async (id) => {
          const assignedUser = self.currentReferral.referAssignedTo.filter(
            (user) => {
              return user.userId === id;
            }
          );
          await ApiService.deleteRequest("refer-assign", assignedUser[0].id);
        });
      }
      self.updateAssignToIds([]);
    },
    async exportForm(file) {
      const params = { status: self.currentStatus };
      if (self.hasPreviousResponses) {
        self.patchNewResponses(params, false);
      } else {
        self.postNewResponses(params, false);
      }
      if (file) {
        const url = "History is now disabled."
        //await documentUpload(file);
        self.postExportData(url);
      }
    },
    async saveAsDraft() {
      const params = { status: 100 };
      if (self.hasPreviousResponses) {
        self.patchNewResponses(params);
      } else {
        self.postNewResponses(params);
      }
      // self.setSavingForm(true);
      // const userId = self.targetUserId;
      // const meta = {
      //   formId: 30,
      //   messageId: self.referralMessageId,
      //   channelId: userId,
      //   userFormId: self.userFormId,
      //   userId,
      //   responseFrom: "REFER",
      // };

      // await self.setEmptyResponses();
      // // this.checkDifference();

      // const payload = getFormattedResponses(
      //   self.descriptiveAnswer,
      //   self.multipleChoices,
      //   self.multipleChoicesRadio
      // );
      //
      // if (!payload.length) {
      //   self.setSavingForm(false);
      //   self.setShowReferralForm(false);
      //   return;
      // }

      // const endPoint = "create-response";
      // const params = { payload, meta };
      // const response = await ApiService.postRequest(endPoint, params);
      //
      // if (response.success) {
      //
      //   self.updateStatusInReferral({
      //     status: 100,
      //   });
      //   self.setSavingForm(false);
      //   self.setShowReferralForm(false);
      // } else {
      //   self.setSavingForm(false);
      // }

      //
      // let promises = [];

      // Object.keys(multipleChoices).forEach((key) => {
      //   const responseArr = multipleChoices[key];
      //   const ansString = responseArr.join(",");
      //   const params = {
      //     formId,
      //     messageId: self.referralMessageId,
      //     userId: Number(userId),
      //     submittedFrom: "REFER",
      //     formQuestionId: Number(key),
      //     answer: ansString,
      //   };
      //   promises.push(
      //     ApiService.postRequest("form-question-responses", params)
      //   );
      // });
      // Object.keys(descriptive).forEach((key) => {
      //   const responseArr = descriptive[key];
      //
      //   const params = {
      //     formId,
      //     messageId: self.referralMessageId,
      //     userId: Number(userId),
      //     submittedFrom: "REFER",
      //     formQuestionId: Number(key),
      //     answer: responseArr,
      //   };
      //   promises.push(
      //     ApiService.postRequest("form-question-responses", params)
      //   );
      // });
      // Object.keys(multipleChoicesRadio).forEach((key) => {
      //   const responseArr = multipleChoicesRadio[key];
      //   const params = {
      //     formId,
      //     messageId: self.referralMessageId,
      //     userId: Number(userId),
      //     submittedFrom: "REFER",
      //     formQuestionId: Number(key),
      //     formQuestionOptionId: Number(responseArr),
      //   };
      //   promises.push(
      //     ApiService.postRequest("form-question-responses", params)
      //   );
      // });
      // Promise.all(promises)
      //   .then((res) => {
      //     self.updateStatusInReferral({
      //       status: 100,
      //     });
      //     self.setSavingForm(false);
      //     self.setShowReferralForm(false);
      //     return;
      //   })
      //   .catch((err) => {
      //     // NotificationStore.setNotification(
      //     //   "error",
      //     //   userProfile.formSubmitError
      //     // );
      //     self.setSavingForm(false);
      //     return;
      //   });
    },
    setSavingForm(value) {
      self.savingForm = value;
    },
    async updateFormResponse() {
      const date = new Date();
      const params = {
        status: 101,
        sentAt: date.toISOString(),
      };
      if (self.hasPreviousResponses) {
        self.patchNewResponses(params);
      } else {
        self.postNewResponses(params);
      }
      // let promises = [];

      // const formId = 30;

      // Object.keys(multipleChoices).forEach((key) => {
      //   const responseArr = multipleChoices[key];
      //   const ansString = responseArr.join(",");
      //   const params = {
      //     formId,
      //     messageId: self.referralMessageId,
      //     userId: Number(userId),
      //     submittedFrom: "REFER",
      //     formQuestionId: Number(key),
      //     answer: ansString,
      //   };

      //   promises.push(
      //     ApiService.postRequest("form-question-responses", params)
      //   );
      // });
      // Object.keys(descriptive).forEach((key) => {
      //   const responseArr = descriptive[key];
      //
      //   const params = {
      //     formId,
      //     messageId: self.referralMessageId,
      //     userId: Number(userId),
      //     submittedFrom: "REFER",
      //     formQuestionId: Number(key),
      //     answer: responseArr,
      //   };

      //   promises.push(
      //     ApiService.postRequest("form-question-responses", params)
      //   );
      // });
      // Object.keys(multipleChoicesRadio).forEach((key) => {
      //   const responseArr = multipleChoicesRadio[key];
      //   const params = {
      //     formId,
      //     messageId: self.referralMessageId,
      //     userId: Number(userId),
      //     submittedFrom: "REFER",
      //     formQuestionId: Number(key),
      //     formQuestionOptionId: Number(responseArr),
      //   };

      //   promises.push(
      //     ApiService.postRequest("form-question-responses", params)
      //   );
      // });
      // Promise.all(promises)
      //   .then((res) => {
      //     const date = new Date();
      //     self.setSavingForm(false);
      //     self.updateStatusInReferral({
      //       status: 101,
      //       sentAt: date.toISOString(),
      //     });
      //     self.setShowReferralForm(false);
      //   })
      //   .catch((err) => {
      //     self.setSavingForm(false);
      //     // NotificationStore.setNotification(
      //     //   "error",
      //     //   userProfile.formSubmitError
      //     // );
      //     return;
      //   });
    },
    async getFormQuestions(formId) {
      self.setQuestionsLoading(true);
      const path = `forms/${formId}`;
      const formQuestionsResponse = await ApiService.getRequest(path, {
        include: true,
        includeOnly: "form-questions",
      });
      if (formQuestionsResponse.success) {
        self.setFormQuestions(formQuestionsResponse.data.questions);
      }
      self.setQuestionsLoading(false);
    },
    async getInAppFormQuestions(formId) {
      self.setQuestionsLoading(true);
      const path = `forms/${formId}`;
      const formQuestionsResponse = await ApiService.getRequest(path, {
        include: true,
        includeOnly: "form-questions",
      });

      if (formQuestionsResponse.success) {
        self.setInAppFormQuestions(formQuestionsResponse.data.questions);
      }
      self.setQuestionsLoading(false);
    },
    setInAppFormQuestions(formQuestions) {
      self.inAppFormQuestions = _.sortBy(formQuestions, "id").map((item) => {
        FormQuestion.options = item.options.map((option) => {
          return Option.create(option);
        });
        return FormQuestion.create(item);
      });
    },
    setFormQuestions(formQuestions) {
      self.referralFormQuestions = _.sortBy(formQuestions, "id").map((item) => {
        FormQuestion.options = item.options.map((option) => {
          return Option.create(option);
        });
        return FormQuestion.create(item);
      });
    },
    setQuestionsLoading(value) {
      self.questionsLoading = value;
    },
    resetResponses() {
      self.multipleChoices = {};
      self.initialMultipleChoices = {};
      self.initialMultipleChoicesRadio = {};
      self.initialDescriptiveAnswer = {};
      self.multipleChoicesRadio = {};
      self.descriptiveAnswer = {};
      self.setHasInAppSurvey(false);
    },
    setShowReferralForm(value) {
      if (!value) {
        self.resetResponses();
      }
      self.showReferralForm = value;
    },
  }))
  .views((self) => ({
    get canBeGenerated() {
      return !self.clientSelectedForm;
    },
    get canSendReferral() {
      const multipleChoicesKeys = Object.keys(self.multipleChoices);
      const multipleChoicesRadioKeys = Object.keys(self.multipleChoicesRadio);
      const descriptiveAnswerKeys = Object.keys(self.descriptiveAnswer);
      const err1 = multipleChoicesKeys.filter(
        (key) => !self.multipleChoices[key].length
      );
      const err2 = multipleChoicesRadioKeys.filter(
        (key) => !self.multipleChoicesRadio[key]
      );
      const err3 = descriptiveAnswerKeys.filter(
        (key) => !self.descriptiveAnswer[key].trim().length
      );
      return err1.length || err2.length || err3.length;
    },
    get responsesLength() {
      let length = 0;
      Object.keys(self.multipleChoices).forEach((key) => {
        if (self.multipleChoices[key].length) {
          length = length + 1;
        }
      });
      length = length + Object.keys(self.multipleChoicesRadio).length;
      Object.keys(self.descriptiveAnswer).forEach((key) => {
        if (self.descriptiveAnswer[key].length) {
          length = length + 1;
        }
      });
      return length;
    },
    get isDisabled() {
      const trimmedNumber = self.clientPhone
        .trim()
        .replace(/[^\x00-\x7F]/g, "")
        .replace(/\D/g, "").length;
      return !(
        self.clientDOB &&
        self.clientFullName &&
        ((self.clientPhone &&
          self.clientPhone.trim() &&
          trimmedNumber === 10) ||
          (self.noPhone && (trimmedNumber === 10 || trimmedNumber === 0))) &&
        self.clientSelectedForm
      );
    },
    get sendInviteDisabled() {
      const trimmedNumber = self.invitedMemberPhone
        .trim()
        .replace(/[^\x00-\x7F]/g, "")
        .replace(/\D/g, "");
      return !(
        self.invitedMemberName &&
        self.invitedMemberPhone &&
        trimmedNumber.length === 10
      );
    },
    get sendAdminInviteDisabled() {
      return !(self.invitedAdminName && self.invitedAdminEmail);
    },
    get isNotNumber() {
      const trimmedNumber = self.clientPhone
        .trim()
        .replace(/[^\x00-\x7F]/g, "")
        .replace(/\D/g, "");
      let regEx = /^\d+$/;
      return !regEx.test(trimmedNumber) || trimmedNumber.length !== 10;
    },
    get isNotaPhoneNumber() {
      const trimmedNumber = self.invitedMemberPhone
        .trim()
        .replace(/[^\x00-\x7F]/g, "")
        .replace(/\D/g, "");
      let regEx = /^\d+$/;
      return !regEx.test(trimmedNumber) || trimmedNumber.length !== 10;
    },
  }));

export default Referral;
