import React from "react";
import { observer, inject } from "mobx-react";
import { alertLeftSide, user } from "../../en.json";
import { Spin, Icon, Popover } from "antd";
import moment from "moment";
import ContentList from "./ContentList";
import _ from "lodash";
import { IS_HP } from "../../utils/getEnvironment";

const arr = ["View Profile", "Direct Message", "Assign to", "Suspend account"];
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

@inject("store")
@observer
class SingleAlert extends React.Component {
  changeChannel = (id, name, flag, timeToken) => {
    const {
      store: {
        MessagesStore: { loadChannelMessages, selectedGroup },
        GroupStore: { fetchGroupUsers },
      },
      messageList,
    } = this.props;
    const type = flag ? "group" : "dm";
    loadChannelMessages(
      id,
      name,
      type,
      timeToken,
      this.scrollToTop,
      messageList
    );
    selectedGroup.setFlaggedMessageTimeToken(timeToken);
    flag && fetchGroupUsers(id);
  };

  handleClick = (message) => {
    const {
      store: {
        ProfileStore: { changeSelectedAction, setSelectedAlert },
        MessagesStore: { setScrollVisible },
      },
    } = this.props;
    changeSelectedAction("View in context");
    setSelectedAlert(message);
    const groupType = message.channel.split("_")[0];
    const groupId = message.channel.split("_")[2];
    const flag = groupType === "GROUP" ? 1 : 0;
    this.changeChannel(
      parseInt(groupId),
      message.name,
      flag,
      message.messageId
    );
    setScrollVisible(true);
  };
  renderPopOverContent(arr, message) {
    const { messageList } = this.props;
    return arr.map((item) => (
      <ContentList
        name={item}
        usernamePress={() => this.usernamePress(message.senderId)}
        message={message}
        messageList={messageList}
      />
    ));
  }

  usernamePress = async (sender) => {
    const {
      store: {
        ProfileStore: {
          setProfileVisibility,
          setMemberData,
          toggleLabel,
          getHighPointUsers,
          getProviderModeratedGroups,
          getContactModeratedGroups,
          fetchGroupsFromForms,
          reset,
        },
        NotificationStore,
        MemberListStore: { getMemberDetails },
      },
    } = this.props;
    reset();
    setProfileVisibility(true);
    const member = await getMemberDetails(sender);
    if (member) {
      setMemberData(member);
      if (IS_HP) {
        getHighPointUsers();
        if (member.providerId) {
          getProviderModeratedGroups(member.providerId);
        }
        if (member.recommendedBy) {
          getContactModeratedGroups(member.recommendedBy);
        }
        fetchGroupsFromForms();
      }
      toggleLabel(false);
    } else {
      NotificationStore.setNotification("error", user.userDoesNotExist);
    }
  };

  toggleResolveStatus(message, flag) {
    const {
      store: {
        FlaggedMessagesStore: { changeResolveStatus },
      },
    } = this.props;
    changeResolveStatus(_.cloneDeep(message), flag);
  }

  render() {
    const {
      store: {
        FlaggedMessagesStore: {
          selectedUrlAlert,
          selectedMessageId,
          resolveLoader,
        },
        ProfileStore: { selectedAlert, setSelectedAlert },
      },
    } = this.props;

    return selectedUrlAlert ? (
      <div key={selectedUrlAlert.id}>
        <div
          className="flag-container"
          style={
            selectedAlert.id === selectedUrlAlert.id
              ? { border: "5px solid #4168ca" }
              : null
          }
          onClick={() => this.handleClick(selectedUrlAlert)}
        >
          <div className="flag-upper">
            <div className="flag-name-container" style={{ cursor: "pointer" }}>
              <div>
                <span className="flag-name">{selectedUrlAlert.sender}</span>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.usernamePress(selectedUrlAlert.senderId);
                  setSelectedAlert(selectedUrlAlert);
                }}
                className="flag-profile-container"
              >
                <span className="flag-profile">{alertLeftSide.profile}</span>
              </div>
            </div>
            <div className="flag-time">
              {moment(selectedUrlAlert.createdAt).calendar()}
            </div>
          </div>
          {selectedUrlAlert.name ? (
            <div className="flag-group">
              {`in ${_.toUpper(
                selectedUrlAlert.name.length > 30
                  ? `${selectedUrlAlert.name.slice(0, 30)}...`
                  : selectedUrlAlert.name
              )}`}
            </div>
          ) : null}
          <div className="flag-middle">
            {selectedUrlAlert.type === "text" ? (
              <div className="flag-message-container">
                <div className="heading">{selectedUrlAlert.text}</div>
              </div>
            ) : selectedUrlAlert.type === "img" ||
              selectedUrlAlert.type === "gif" ? (
              <img
                src={selectedUrlAlert.imgUrl}
                alt=""
                height="60"
                width="60"
              />
            ) : selectedUrlAlert.type === "form" ? (
              <span className="heading">{alertLeftSide.form}</span>
            ) : (
              <span className="heading"></span>
            )}
            <br />
            <span className="subHeading">
              {`Flagged by ${_.capitalize(selectedUrlAlert.username)}`}
            </span>
          </div>
          <div className="flag-lower">
            <div className="flag-buttons">
              <button
                className="flag-message-button"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleClick(selectedUrlAlert);
                }}
              >
                {alertLeftSide.viewInContext}
              </button>
              {selectedMessageId === selectedUrlAlert.id && resolveLoader ? (
                <div className="flag-resolve-button">
                  <Spin indicator={antIcon} />
                </div>
              ) : (
                <button
                  className="flag-resolve-button"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleResolveStatus(
                      selectedUrlAlert,
                      !selectedUrlAlert.isResolved
                    );
                  }}
                >
                  {selectedUrlAlert.isResolved
                    ? alertLeftSide.unresolve
                    : alertLeftSide.resolve}
                </button>
              )}
            </div>
            <div
              className="flag-div"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedAlert(selectedUrlAlert);
              }}
            >
              <Popover
                content={this.renderPopOverContent(arr, selectedUrlAlert)}
                placement="right"
                trigger="click"
              >
                <Icon
                  className="flag-icon"
                  type="more"
                  style={{ fontSize: 30 }}
                />
              </Popover>
            </div>
            ,
          </div>
        </div>
        <hr className="horizontal-tag" />
      </div>
    ) : (
      <div className="no-alerts">{alertLeftSide.noSuchAlert}</div>
    );
  }
}

export default SingleAlert;
