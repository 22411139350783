import React from "react";
import { Spin } from "antd";
import close from "../../static/images/close.png";

export const EventsModal = (props) => {
  const { onConfirm } = props;
  return (
    <div>
      <div>
        <div className="confirm-backdrop" onClick={onConfirm} />
        <div className="events-modal">
          <div className="modal-heading">
            <h1>Please Note</h1>
            <img src={close} onClick={onConfirm} alt="Close" />
          </div>
          <p>Patients will no longer be sent SMSs for future Zoom Groups</p>
          <div className="modal-buttons">
            <button className="confirm" onClick={onConfirm}>
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
