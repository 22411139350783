import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import MessageBubble from './MessageBubble';
import ModeratorTag from './ModeratorTag';
import { chat } from '../../en.json';
import { ALIKE_MODERATOR } from '../../constants/UserRolesConstant';
import close from '../../static/images/close.png';
import sendIcon from '../../static/images/send-icon.png';
import uploadImage from '../../static/images/uploadImage.png';
import {
  publish,
  getPubnubInstanceByUserType,
  history,
} from '../../utils/PubnubMethods';
import { CustomButton } from '../UI/CustomButton';

@inject("store")
@observer
class DmSection extends React.Component {
  constructor(props) {
    super(props);
    this.messageList = React.createRef();
    this.uploadRef = React.createRef();

    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.scrollToBottom(), 200);
  }

  appendData = () => {
    const {
      store: {
        MessagesStore,
        AuthStore: { type },
      },
    } = this.props;

    const message = MessagesStore.getMessage();

    const text = message.text;
    if (text.trim() !== "") {
      const pubnub = getPubnubInstanceByUserType(type);
      message.clearText();
      text.trim() !== "" && publish(pubnub, text);
      setTimeout(() => this.scrollToBottom(), 200);
    }
  };

  scrollToBottom = () => {
    if (this.messageList.current) {
      const scrollHeight = this.messageList.current.scrollHeight;
      const height = this.messageList.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  imageUploadClick = () => this.uploadRef.click();

  checkMessageType = (message) => {
    return (
      message.entry.type === "text" ||
      message.entry.type === "img" ||
      message.entry.type === "gif"
    );
  };

  getDmNames = (users) => {
    const {
      store: {
        AuthStore: { username },
      },
    } = this.props;

    return _.split(users, ",")
      .filter((user) => user !== username)
      .map((name, index) => (
        <React.Fragment key={index}>
          {/* warning:  we are using the index as a key, which will cause bugs when items in this list change */}
          {index !== 0 && ","}
          <span>{name}</span>
          <ModeratorTag username={name} />
        </React.Fragment>
      ));
  };

  handleChange = (e) => {
    const {
      store: { MessagesStore },
    } = this.props;

    const text = e.target.value;
    const message = MessagesStore.getMessage();

    message.setText(text);
  };

  handleClose = () => {
    const {
      store: {
        ProfileStore: { changeSelectedAction },
        MessagesStore: {
          changeChannel,
          previouslySelectedGroup: { id, name, userType, isPaused },
        },
      },
    } = this.props;
    changeChannel(id, name, userType, isPaused, "group");
    changeSelectedAction("");
  };

  handleKeyPress = (e, broadcastMessage = false) => {
    const {
      store: {
        BroadcastStore: { startDm },
      },
    } = this.props;

    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (broadcastMessage) {
        startDm();
      } else {
        this.appendData();
      }
    }
  };

  uploadImage = async (e) => {
    const {
      store: {
        MessagesStore: { uploadImageInChat },
      },
    } = this.props;
    const fileName = e.target.value.toLowerCase();
    if (
      !fileName.endsWith(".jpg") ||
      !fileName.endsWith(".jpeg") ||
      !fileName.endsWith(".gif") ||
      !fileName.endsWith(".png")
    ) {
      alert("Please upload file type image only.");
      return;
    }
    if (e.target.getAttribute("data-field") === "groupImage") {
      const files = e.target.files;
      if (!files.length) {
        return alert("Please choose a file to upload first.");
      } else {
        const file = files[0];
        await uploadImageInChat(file);
        this.uploadRef.value = "";
        this.scrollToBottom();
      }
    }
  };

  loadMore = async () => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { setLoading, loading, reactionsLoaded },
          viewportMessagesLoaded,
        },
        AuthStore: { type },
      },
    } = this.props;
    let prevScrollHeight = 1;
    if (!loading && !reactionsLoaded && viewportMessagesLoaded) {
      if (this.messageList.current && this.messageList.current.scrollHeight) {
        prevScrollHeight = this.messageList.current.scrollHeight;
      }
      const pubnub = getPubnubInstanceByUserType(type);
      setLoading(true);
      history(pubnub, this.scrollToTop, prevScrollHeight);
    }
  };

  // Here we need to resolve/unresolve the user DM after clicking on button
  resolveUserDM = () => {
    const {
      store: {
        AuthStore: {
          isResolvedDMLoading,
          resolveDMForSpecificUser
        },
        MessagesStore: {
          selectedGroup: { id },
        }
      }
    } = this.props;
    if (isResolvedDMLoading) return;
    // Here we will pass "isRefreshNeededForDMs" to false because we don't need to call the DM channels API
    resolveDMForSpecificUser({ dmChannelId: id, isRefreshNeededForDMs: false });
  };

  render() {
    const {
      store: {
        MessagesStore: {
          selectedGroup: {
            id,
            messages,
            loading,
            hasMore,
            name,
            channel,
            groupLastSeen,
          },
          text,
          imageUploading,
        },
        BroadcastStore: { dmLoading },
        AuthStore: {
          type: userType,
          isUserDMResolved,
          isResolvedDMLoading
        }
      },
    } = this.props;
    let prevDate = "";
    return (
      <div className="right-section">
        <div className="header-section p-1">
          <h1 className="dm-heading">
            <div>
              {_.startsWith(channel, "DIRECT_MESSAGE")
                ? this.getDmNames(name)
                : name}
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}>
              {
                _.startsWith(channel, 'DIRECT_MESSAGE') && ALIKE_MODERATOR.includes(userType) ?
                  (isResolvedDMLoading ? (<div className="resolve-dm my-btn"><Spin /></div>)
                    : (<CustomButton
                      className="resolve-dm my-btn"
                      caption={isUserDMResolved ? "Unresolve DM" : "Resolve DM"}
                      disabled={isResolvedDMLoading}
                      onClick={() => this.resolveUserDM()}
                    />)) : null
              }

              <img
                className="close-img"
                src={close}
                onClick={() => this.handleClose()}
                alt="Close"
              />
            </div>
          </h1>
        </div>
        {dmLoading || loading ? (
          <div className="dm-loading">
            <Spin />
          </div>
        ) : (
          <div className="d-flex">
            <div className="p-1" style={{ flex: "1", position: "relative" }}>
              <div className="d-flex flex-column h-100">
                {isUserDMResolved ? null : <div className="input-container">
                  <textarea
                    value={text}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress}
                    placeholder="Type here to send a direct message..."
                  />
                  {imageUploading ? (
                    <div className="send-img-img active">
                      <Spin />
                    </div>
                  ) : (
                    <img
                      src={uploadImage}
                      className="send-img-img active"
                      alt="Send"
                      onClick={this.imageUploadClick}
                    />
                  )}
                  <input
                    type="file"
                    ref={(ref) => (this.uploadRef = ref)}
                    onChange={this.uploadImage}
                    accept="image/*"
                    data-field="groupImage"
                    className="chat-image-upload"
                  />
                  <img
                    src={sendIcon}
                    onClick={this.appendData}
                    value="Append"
                    className="send-text-img ml-auto"
                    style={
                      text.trim() !== "" ? { opacity: 1 } : { opacity: 0.4 }
                    }
                    alt="Send"
                  />
                </div>}
                {loading ? (
                  <Spin />
                ) : (
                  <div id="display-data-Container" ref={this.messageList}>
                    <InfiniteScroll
                      loadMore={this.loadMore}
                      hasMore={hasMore}
                      isReverse
                      useWindow={false}
                    >
                      {_.uniqBy(messages, "timetoken").map((message, index) => {
                        const currDate = moment
                          .unix(parseInt(message.timetoken) / 10000000)
                          .format("LL");
                        const messageBox = (
                          <React.Fragment key={index}>
                            {/* warning:  we are using the index as a key, which will cause bugs when items in this list change */}
                            {currDate !== prevDate &&
                              this.checkMessageType(message) && (
                                <div className="message-date">
                                  <span>{currDate}</span>
                                </div>
                              )}
                            <MessageBubble
                              message={message}
                              groupChat={_.startsWith(channel, "GROUP_CHAT")}
                              groupId={
                                _.startsWith(channel, "GROUP_CHAT") ? id : null
                              }
                              groupLastSeen={groupLastSeen}
                              scrollToBottom={this.scrollToBottom}
                            />
                          </React.Fragment>
                        );

                        prevDate = currDate;
                        return messageBox;
                      })}
                      {!messages.length ? (
                        <div className="no-messages">{chat.noMessages}</div>
                      ) : null}
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default DmSection;
