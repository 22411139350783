import { types } from "mobx-state-tree";
import DeletedForumItem from "./DeletedForumItem";
import _ from "lodash";
import { setStreamInstance, streamClient } from "../utils/streamInstance";
import { decryptData } from "../utils/CommonUtils";
import { getActivitiesByIds } from "../utils/forumFeedService";
import NotificationStore from "../stores/NotificationStore";
import { flagMessages } from "../en.json";
import { COMMENT, FEED_IMAGE_URL, GIF_TYPE } from "../constants/GlobalConstant";
import ApiService from "../utils/ApiService";

const DeletedForumItemList = types.model("DeletedForumItemList", {
    deletedForumItems: types.array(DeletedForumItem),
    deletedForumItemsTotal: types.maybe(types.number),
    endOfDeletedForumItemsList: false,
    selectedForumItem: types.maybe(DeletedForumItem),
    selectedForumItemId: types.maybe(types.number),
    loading: false
})
    .actions((self) => ({
        reset() {
            self.deletedForumItemsTotal = 0;
            self.deletedForumItems = [];
            self.endOfDeletedForumItemsList = false;
        },
        setLoading(value) {
            self.loading = value;
        },
        setDeletedForumItems(value) {
            self.deletedForumItems = _.concat(
                self.deletedForumItems,
                value
            )
        },
        setDeletedForumItemsTotal(value) {
            self.deletedForumItemsTotal = value;
        },
        setEndOfDeletedForumItemsList(value) {
            self.endOfDeletedForumItemsList = value;
        },
        setSelectedForumItem(value) {
            self.selectedForumItem = _.cloneDeep(value);
            self.selectedForumItemId = value?.id;
        },
        async fetchDeletedForumItems(params = {}) {
            const limit = 10;
            params = {
                ...params,
                $skip: self.deletedForumItems.length,
                $limit: limit,
            };

            self.setLoading(true);
            const response = await ApiService.getRequest('forum-deleted-items', params);
            if (!_.get(response, "data.length", 0)) {
                self.setEndOfDeletedForumItemsList(true)
            }

            let promises = [];
            if (response.success) {
                if (response.meta.skip === 0) self.setDeletedForumItemsTotal(response.meta.total)
                if (!streamClient) {
                    await setStreamInstance();
                }

                response.data.forEach((item) => {
                    if (item.type === COMMENT && streamClient) {
                        const promiseElement = streamClient.reactions.get(item.getStreamId)
                        promises.push(promiseElement);
                    } else {
                        const promiseElement = getActivitiesByIds([item.getStreamId])
                        promises.push(promiseElement);
                    }
                });

                const res = await Promise.all(promises)
                for (let index = 0; index < res.length; index++) {
                    const item = res[index];
                    let deletedResource;
                    if (item && item?.kind === COMMENT) {
                        item.activity = (await getActivitiesByIds([item?.activity_id]))[0]
                        item.data = decryptData(item?.data?.data)
                        item.user = {
                            ...item?.user,
                            data: item?.user?.data?.encryptedData
                                ? decryptData(item?.user?.data?.encryptedData)
                                : item?.user?.data,
                            }

                        const commentAttachment = _.get(item, 'data.attachments.0');
                        const isGif = _.get(item, 'data.attachments.0.type') === GIF_TYPE;
                        
                        if(commentAttachment){
                            item.imgUrl = (isGif ? '' : FEED_IMAGE_URL) + commentAttachment?.url;
                        }                        

                        deletedResource = {
                            id: response.data[index].id,
                            type: item.kind,
                            resourceId: item.id,
                            resource: item,
                            deletedBy: response.data[index].deletedByUser.username
                        }

                    }
                    else if (item && item?.length && item[0].verb === 'post') {
                        deletedResource = {
                            id: response.data[index].id,
                            type: item[0].verb,
                            resourceId: item[0].id,
                            resource: item[0],
                            deletedBy: response.data[index].deletedByUser.username
                        }
                    }
                    self.setDeletedForumItems(deletedResource);
                }
                if (self.deletedForumItems.length == self.deletedForumItemsTotal) self.setEndOfDeletedForumItemsList(true)
                self.setLoading(false);
            }
            else {
                NotificationStore.setNotification("error", flagMessages.flagFetchError);
                self.setLoading(false);
                return;
            }

        },
    }))



export default DeletedForumItemList;