import "../../styles/global.scss";

import { Tabs } from "antd";
import { inject, observer } from "mobx-react";

import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import InviteMember from "./InviteMember";
import InviteAdmin from "./InviteAdmin";
import CreateAdmins from "./CreateAdmins";

const TabPane = Tabs.TabPane;

@withRouter
@inject("store")
@observer
class Invite extends React.Component {
  render() {
    const {
      store: {
        AuthStore: { type },
      },
    } = this.props;
    return (
      <div>
        <Tabs
          size="small"
          tabBarStyle={{ border: 0 }}
          onChange={this.setFilter}
        >
          <TabPane className="my-tabs-ink-bar" tab="Invite Member" key="1">
            <InviteMember />
          </TabPane>
          <TabPane className="my-tabs-ink-bar" tab="Invite Admin" key="2">
            <InviteAdmin />
          </TabPane>
          {type !== "NOA" ? (
            <TabPane
              className="my-tabs-ink-bar"
              tab="Create Multiple Admins"
              key="3"
            >
              <CreateAdmins />
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

export default Invite;
