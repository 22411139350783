const { REACT_APP_PUBLIC_KEY_FOR_ENCRYPTION }  = process.env;

let rsaPublicKey;

export async function encryptValue(unencryptedValue) {
  const enc = new TextEncoder();
  const encoded = enc.encode(unencryptedValue);
  const key = await getRsaPublicKey();

  const result = await window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    key,
    encoded,
  );

  const uint8Array = new Uint8Array(result);

  // Convert Uint8Array to binary string
  let binaryString = '';
  uint8Array.forEach(byte => {
    binaryString += String.fromCharCode(byte);
  });

  // Convert binary string to Base64
  return btoa(binaryString);
}

async function getRsaPublicKey() {
  if (rsaPublicKey) {
    return rsaPublicKey;
  }

  const pem = window.atob(REACT_APP_PUBLIC_KEY_FOR_ENCRYPTION);

  // fetch the part of the PEM string between header and footer
  const pemHeader = "-----BEGIN PUBLIC KEY-----";
  const pemFooter = "-----END PUBLIC KEY-----";
  const pemContents = pem.substring(
    pemHeader.length,
    pem.length - pemFooter.length - 1,
  );

  // base64 decode the string to get the binary data
  const binaryDerString = window.atob(pemContents);

  // convert from a binary string to an ArrayBuffer
  const binaryDer = str2ab(binaryDerString);

  rsaPublicKey = await window.crypto.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["encrypt"],
  );

  return rsaPublicKey;
}

// from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

