import { types } from "mobx-state-tree";

const UserGroups = types.model("UserGroups", {
  userId: types.maybeNull(types.number),
  groupId: types.maybe(types.integer),
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string),
  color: types.maybeNull(types.string),
  isIceBreakerMessageSent: types.maybeNull(types.boolean),
  type: types.maybeNull(types.string),
  status: types.number,
  isPaused: types.maybeNull(types.boolean),
  addedBy: types.maybeNull(types.number),
});

export default UserGroups;
