// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== "undefined";

// Safari 3.0+ "[object HTMLElementConstructor]"

// Internet Explorer 6-11
export const isIE = !!window.MSInputMethodContext && !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79

export const isChrome =
  /Chrome/.test(navigator.userAgent) &&
  /Google Inc/.test(navigator.vendor) &&
  !/OPR/.test(navigator.userAgent);

// Edge (based on chromium) detection
export const isEdgeChromium =
  isChrome && navigator.userAgent.indexOf("Edg") != -1;

// Blink engine detection
