export const RC_HP_ARRAY = [
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  67,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
];

export const RSN_HP_ARRAY = [
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  68,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
];

export const RC_STAGING_ARRAY = [
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  92,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
];

export const RSN_STAGING_ARRAY = [
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  93,
  101,
  102,
  103,
  104,
  105,
  106,
  107,
  108,
];
