import React from "react";
import { inject, observer } from "mobx-react";
import { Switch } from "antd";
import { toJS } from "mobx";
import { CustomButton } from "../UI/CustomButton";
import IceBreakerScreen from "./IceBreakerScreen";

@inject("store")
@observer
class IceBreaker extends React.Component {
  resetIceBreakerForAll = () => {
    const {
      store: {
        GroupListStore: { resetIceBreakerForMembers, selectedGroup },
      },
    } = this.props;
    resetIceBreakerForMembers(selectedGroup.id);
  };

  setFormInput = (e, screen) => {
    const {
      store: {
        GroupListStore: { setIceBreakerScreensInfo },
      },
    } = this.props;

    const fieldToSet = e.target.getAttribute("data-field");
    setIceBreakerScreensInfo(fieldToSet, screen, e.target.value);
  };

  updateIceBreakerStatus = (value) => {
    const {
      store: {
        GroupListStore: { setIceBreakerStatus },
      },
    } = this.props;
    setIceBreakerStatus(value);
  };

  updateNewMemberBannerStatus = (value) => {
    const {
      store: {
        GroupListStore: { setNewMemberBanner },
      },
    } = this.props;
    setNewMemberBanner(value);
  };

  updateGroupIceBreaker = () => {
    const {
      store: {
        GroupListStore: { updateGroupIceBreaker, selectedGroup },
      },
    } = this.props;
    updateGroupIceBreaker(selectedGroup.id);
  };

  isAllFieldsEmpty = () => {
    const {
      store: {
        GroupListStore: {
          checklist,
        },
      },
    } = this.props;
    const contentData = toJS(checklist);
    if (!contentData.length) {
      return true;
    }

    let isAllFieldsEmpty = true;
    for (const icebreakerObj of contentData) {
      // Calculating total length of keys in icebreaker object
      const originalLengthOfObj = Object.keys(icebreakerObj).length;
      // Cloning the original icebreaker object
      const copyIcebreakerObj = { ...icebreakerObj };
      // We will find out how many empty strings are there
      let totalEmptyStringsFound = 0;
      if (originalLengthOfObj) {
        // Iterate over icebreaker object
        for (let key in copyIcebreakerObj) {
          // We will take value of every key and trim that
          const value = copyIcebreakerObj[key].trim();
          if (!value) {
            // If there are empty values found then we are increasing the count of "totalEmptyStrings"
            totalEmptyStringsFound++;
          }
        }
      }
      // If count of total empty strings and length of keys in object are not equal then we will push the icebreaker object
      // otherwise we will skip that whole object 
      if (totalEmptyStringsFound !== originalLengthOfObj) {
        isAllFieldsEmpty = false;
        break;
      }
    }
    return isAllFieldsEmpty;
  }

  render() {
    const {
      store: {
        GroupListStore: {
          hasIceBreaker,
          checklist,
          newMemberBanner,
          updatingIceBreaker,
        },
      },
    } = this.props;

    return (
      <div className="iceBreaker-container">
        <IceBreakerScreen
          checklist={checklist}
          screen={1}
          setFormInput={this.setFormInput}
        />
        <IceBreakerScreen
          screen={2}
          checklist={checklist}
          setFormInput={this.setFormInput}
        />
        <IceBreakerScreen
          screen={3}
          checklist={checklist}
          setFormInput={this.setFormInput}
        />
        <IceBreakerScreen
          screen={4}
          checklist={checklist}
          setFormInput={this.setFormInput}
        />
        <IceBreakerScreen
          screen={5}
          checklist={checklist}
          setFormInput={this.setFormInput}
        />
        <div className="label-container">
          <span className="iceBreaker-label">IceBreaker On/Off</span>
          <Switch
            checked={hasIceBreaker}
            className="switch"
            onChange={this.updateIceBreakerStatus}
          />
        </div>
        <div className="button-container">
          <CustomButton
            type="primary"
            disabled={updatingIceBreaker}
            // disabled={updatingIceBreaker || this.isAllFieldsEmpty()}
            onClick={this.updateGroupIceBreaker}
            caption="Save"
            size="large"
          />
          <CustomButton
            type="primary"
            onClick={this.resetIceBreakerForAll}
            caption="Reset IceBreaker for all Members In Group"
            size="large"
          />
        </div>
      </div>
    );
  }
}

export default IceBreaker;
