import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroller";
import { Input, Spin } from "antd";
import adminSVG from "../../static/images/admin.svg";
import _ from "lodash";
import { toJS } from "mobx";
@inject("store")
@observer
class AssignTo extends Component {
  assignAdmins = (admin) => {
    const {
      store: {
        MemberListStore: { assignAdmins },
      },
    } = this.props;
    assignAdmins(admin);
  };

  fetchMods = (e) => {
    const {
      store: {
        MemberListStore: {
          fetchSearchedMods,
          clearSearchedMods,
          updateModSearchQuery,
          loading,
        },
      },
    } = this.props;
    updateModSearchQuery(e.target.value);
    if (!loading) {
      clearSearchedMods();
      fetchSearchedMods({
        $skip: 0,
      });
    } else return;
  };

  getAdminsBySiteId = (siteId) => {
    const {
      store: {
        MemberListStore: { moderators, assignedAdmins },
      },
    } = this.props;
    return _.differenceBy(moderators, assignedAdmins, "id").filter(
      (item) => item.siteId == siteId
    );
  };

  getFilteredAdminsBySiteId = (siteId) => {
    const {
      store: {
        MemberListStore: { searchedMods, assignedAdmins },
      },
    } = this.props;
    return _.differenceBy(searchedMods, assignedAdmins, "id").filter(
      (item) => item.siteId == siteId
    );
  };

  loadMore = () => {
    const {
      store: { MemberListStore },
    } = this.props;
    if (MemberListStore.modSearchQuery && !MemberListStore.loading) {
      MemberListStore.fetchSearchedMods({
        $skip: MemberListStore.searchedMods.length,
      });
    }
  };

  loadMoreModerators = () => {
    const {
      store: {
        MemberListStore: { moderatorsLoading, fetchModerators },
      },
    } = this.props;
    if (moderatorsLoading) {
      return;
    }
    fetchModerators();
  };

  render() {
    const {
      store: {
        MemberListStore: {
          moderatorsLoading,
          searchedMods,
          searchedModSemiModTotal,
          modSearchQuery,
          moderatorsTotal,
          moderatorsSkip,
        },
        SiteStore: { allSites },
      },
    } = this.props;
    return (
      <div className="assign-container">
        <div className="assign-input-container">
          <Input
            placeholder="Type a moderator or admin username"
            className="assign-input"
            onPressEnter={this.fetchMods}
          />
        </div>
        {modSearchQuery ? (
          <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
            <InfiniteScroll
              loadMore={this.loadMore}
              useWindow={false}
              hasMore={searchedModSemiModTotal > searchedMods.length}
            >
              {Object.keys(toJS(allSites)).map((siteId) => {
                return (
                  <div>
                    <div className="sitename-container">
                      {this.getFilteredAdminsBySiteId(siteId).length ? (
                        <div className="sitename">
                          {toJS(allSites)[siteId].name}
                        </div>
                      ) : null}
                    </div>
                    {this.getFilteredAdminsBySiteId(siteId).length ? (
                      <ul className="admin-name-container">
                        {this.getFilteredAdminsBySiteId(siteId).map((admin) => (
                          <li
                            className="admin-name-item"
                            key={admin.id}
                            onClick={() => this.assignAdmins(admin)}
                          >
                            {admin.username}

                            <img
                              src={adminSVG}
                              alt="ADMIN"
                              className="modSemiMod-img"
                            />
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                );
              })}
              <div className="empty-container"></div>
              <ul className="admin-name-container">
                {this.getFilteredAdminsBySiteId(null).map((admin) => (
                  <li
                    className="admin-name-item"
                    key={admin.id}
                    onClick={() => this.assignAdmins(admin)}
                  >
                    {admin.username}

                    <img
                      src={adminSVG}
                      alt="ADMIN"
                      className="modSemiMod-img"
                    />
                  </li>
                ))}
              </ul>
              {searchedModSemiModTotal > searchedMods.length ? (
                <div className="loading-alert-members">
                  <Spin />
                </div>
              ) : null}
            </InfiniteScroll>
          </div>
        ) : (
          <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
            <InfiniteScroll
              loadMore={this.loadMoreModerators}
              useWindow={false}
              hasMore={moderatorsSkip < moderatorsTotal}
            >
              {Object.keys(toJS(allSites)).map((siteId, index) => {
                return (
                  <div key={index}>
                    <div className="sitename-container">
                      {this.getAdminsBySiteId(siteId).length ? (
                        <div className="sitename">
                          {toJS(allSites)[siteId].name}
                        </div>
                      ) : null}
                    </div>
                    {this.getAdminsBySiteId(siteId).length ? (
                      <ul className="admin-name-container">
                        {this.getAdminsBySiteId(siteId).map((admin) => (
                          <li
                            className="admin-name-item"
                            key={admin.id}
                            onClick={() => this.assignAdmins(admin)}
                          >
                            {admin.username}
                            <img
                              src={adminSVG}
                              alt="ADMIN"
                              className="modSemiMod-img"
                            />
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                );
              })}
              <div className="empty-container"></div>
              <ul className="admin-name-container">
                {this.getAdminsBySiteId(null).map((admin) => (
                  <li
                    className="admin-name-item"
                    key={admin.id}
                    onClick={() => this.assignAdmins(admin)}
                  >
                    {admin.username}
                    <img
                      src={adminSVG}
                      alt="ADMIN"
                      className="modSemiMod-img"
                    />
                  </li>
                ))}
              </ul>
              {moderatorsLoading ? (
                <div className="loading-alert-members">
                  <Spin />
                </div>
              ) : null}
            </InfiniteScroll>
          </div>
        )}
      </div>
    );
  }
}
export default AssignTo;
