import { types } from "mobx-state-tree";

const FlaggedMessage = types
  .model("FlaggedMessage", {
    id: types.maybeNull(types.number),
    messageId: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    channel: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    username: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    sender: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    isResolved: types.maybeNull(types.boolean),
    type: types.maybeNull(types.string),
    imgUrl: types.maybeNull(types.string),
    senderId: types.maybeNull(types.number),
    flaggedBy: types.maybeNull(types.string),
    resolvedBy: types.maybeNull(types.number),
    resolvedByUsername: types.maybeNull(types.string),
    activity: types.maybeNull(types.frozen({})),
    comment: types.maybeNull(types.frozen({})),
    activityId: types.maybeNull(types.string),
    commentId: types.maybeNull(types.string),
    isForumFlag: types.maybeNull(types.boolean),
  })
  .actions(self => ({}));

export default FlaggedMessage;
