import { types } from "mobx-state-tree";

import ApiService from "../utils/ApiService";
import AuthStore from "../stores/AuthStore";
import FlagNeeds from "./FlagNeeds";
import { getFlagTypes } from "../utils/getFlagTypes";
import _ from "lodash";
import {
  getMessageInfo,
  getPubnubInstanceByUserType,
} from "../utils/PubnubMethods";
import moment from "moment";
import Message from "./Message";
import { setStreamInstance, streamClient } from "../utils/streamInstance";
import { getActivitiesByIds } from "../utils/forumFeedService";
import { decryptData } from "../utils/CommonUtils";
var mixpanel = require("mixpanel-browser");

const flagTypes = getFlagTypes();

const FlaggedMessagesList = types
  .model("FlagNeedsList", {
    flagNeeds: types.frozen(types.array(FlagNeeds)),
    loading: false,
    activeTab: types.maybe(types.string),
    pagination: types.frozen({
      pageSize: 100,
      total: 0,
      current: 1,
    }),
  })
  .actions((self) => ({
    resetAll() {
      self.flagNeeds = [];
      self.activeTab = "";
    },
    setPaginationData(pagination) {
      self.pagination = pagination;
    },
    parseMetaData(meta) {
      return {
        pageSize: meta.limit,
        current: meta.skip / meta.limit + 1,
        total: meta.total,
      };
    },
    setLoading(value) {
      self.loading = value;
    },
    setActiveTab(tab) {
      self.activeTab = tab;
    },
    async fetchFlagNeeds(filterBy) {
      let params = {};
      const limit = 100;
      params = {
        ...params,
        filterBy: filterBy,
        $skip: (self.pagination.current - 1) * self.pagination.pageSize,
        $limit: limit,
      };

      try {
        self.setLoading(true);
        const result = await ApiService.getRequest(
          "flag-needs",
          params
        );

        if(result && result.success && result.data){
          self.getFlagNeedsData(result.data);
          self.setPaginationData(self.parseMetaData(result.meta));
        } else {
          self.setLoading(false);
        }
      } catch (error) {
        self.setLoading(false);
      }
    },
    async getFlagNeedsData(data) {
      try {
        self.setLoading(true);
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        if(!streamClient) {
          await setStreamInstance();
        }
        let promises = [];
        data.map(item => {
          const { isForumFlag, commentId, activityId } = item;
          if(isForumFlag) {
            if(commentId) {
              promises.push(streamClient.reactions.get(commentId));
            } else {
              promises.push(getActivitiesByIds([activityId]));
            }
          } else {
            promises.push(
              getMessageInfo(pubnub, {
                channel: item.channel,
                messageId: item.messageId,
              })
            );
          }
        });

        Promise.all(promises).then((messageInfo) => {
          let result = messageInfo.map((message, index) => {
            if (message && message?.messages?.length) {
              const msg = message.messages[0];
              const typeOfNeed = data[index].typeOfNeed?.split(",");
              const typeOfNeedLabel = typeOfNeed && typeOfNeed.length && flagTypes.filter(item => item.value === parseInt(typeOfNeed[0])) ? flagTypes.find(item => item.value === parseInt(typeOfNeed[0])).label : "";

              let obj = {
                id: data[index].id,
                message: msg,
                userId: data[index].userId,
                username: data[index].username,
                fullName: data[index].fullName,
                flaggedby: data[index].flaggedby,
                flaggedbyname: data[index].flaggedbyname,
                groupname: data[index].groupname,
                messageId: data[index].messageId,
                reactionId: data[index].reactionId,
                description: data[index].description,
                typeOfNeedColor: data[index].reactionId === 7 ? "red" : "blue",
                typeOfNeedLabel: typeOfNeedLabel,
                isAnonymity: typeOfNeed && typeOfNeed.length > 1 && parseInt(typeOfNeed[1]) === 101 ? true : false,
                messageDate: moment(data[index].createdAt).format("MM/DD/YYYY"),
                createdAt: data[index].createdAt,
                isForumFlag: false,
              };
              return FlagNeeds.create(obj);
            } else if (message && message?.kind === 'comment') {
              const typeOfNeed = data[index].typeOfNeed;
              const typeOfNeedLabel = typeOfNeed && typeOfNeed.length && flagTypes.filter(item => item.value === parseInt(typeOfNeed[0])) ? flagTypes.find(item => item.value === parseInt(typeOfNeed[0])).label : "";
              const comment = {
                ...message, 
                  data: decryptData(message?.data?.data),
                  user: {
                      ...message?.user,
                      data: message?.user?.data?.encryptedData 
                              ? decryptData(message?.user?.data?.encryptedData) 
                              : message?.user?.data,
                  }
              };

              const obj = {
                id: data[index].id,
                userId: data[index].userId,
                username: comment?.user?.data?.username,
                fullName: comment?.user?.data?.username,
                flaggedby: data[index].flaggedby,
                flaggedbyname: data[index].flaggedbyname,
                description: data[index].description,
                typeOfNeedColor: "orange",
                typeOfNeedLabel: typeOfNeedLabel,
                isAnonymity: data[index]?.isAnonimityCompromised || false,
                messageDate: moment(data[index].createdAt).format("MM/DD/YYYY"),
                createdAt: data[index].createdAt,
                isForumFlag: true,
                comment,
              };

              return FlagNeeds.create(obj);
            } else if (message && message?.length && message[0]?.verb === 'post') {
              const typeOfNeed = data[index].typeOfNeed;
              const typeOfNeedLabel = typeOfNeed && typeOfNeed.length && flagTypes.filter(item => item.value === parseInt(typeOfNeed[0])) ? flagTypes.find(item => item.value === parseInt(typeOfNeed[0])).label : "";
              const activity = message[0];

              const obj = {
                id: data[index].id,
                userId: data[index].userId,
                username: activity?.actor?.data?.username,
                fullName: activity?.actor?.data?.username,
                flaggedby: data[index].flaggedby,
                flaggedbyname: data[index].flaggedbyname,
                description: data[index].description,
                typeOfNeedColor: "orange",
                typeOfNeedLabel: typeOfNeedLabel,
                isAnonymity: data[index]?.isAnonimityCompromised || false,
                messageDate: moment(data[index].createdAt).format("MM/DD/YYYY"),
                createdAt: data[index].createdAt,
                isForumFlag: true,
                activity,
              };

              return FlagNeeds.create(obj);
            }
          });

          this.setFlagNeeds(result);
          self.setLoading(false);
      });
      } catch (error) {
        self.setLoading(false);
      }
    } ,
    setFlagNeeds(data) {
      self.flagNeeds = _.compact(data);
    }
  }));

export default FlaggedMessagesList;
