import cleanslate from "../cleanslate.json";
import highpoint from "../highpoint.json";
import nida from "../nida.json";
import { IS_STAGING, IS_NIDA, IS_CS, IS_HP } from "./getEnvironment";

const template =
  IS_STAGING || IS_CS
    ? cleanslate
    : IS_HP
    ? highpoint
    : IS_NIDA
    ? nida
    : cleanslate;

export default template;
