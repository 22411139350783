import React from "react";
import { Modal, Row, Spin, Col, Button } from "antd";

const RewardNoteModal = (props) => {
    const {
        onOk,
        onCancel,
        visible,
        rewardNote,
        onChangeRewardNote,
        onComplete,
        completeRewardsLoading
    } = props;

    return (
        <Modal
            width={"800px"}
            title={'Are you sure?'}
            onOk={onOk}
            onCancel={onCancel}
            visible={visible}
        >
            <Row>
                <Col span={24}>
                    <p className="reward-modal-primary-label">Do you want to complete this reward?</p>
                    <textarea
                        rows={10}
                        className="reward-note-textarea"
                        required={true}
                        onChange={(event) => onChangeRewardNote(event)}
                        value={rewardNote}
                        placeholder="Add note"
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {completeRewardsLoading ?
                        <p>Loading ...</p>
                        :
                        <>
                            <Button
                                style={{ margin: 5 }}
                                onClick={() => onCancel()}
                            >
                                Never mind
                            </Button>
                            <Button
                                type="primary"
                                style={{ margin: 5 }}
                                onClick={() => onComplete()}
                            >
                                Yes, Complete
                            </Button>
                        </>}
                </Col>
            </Row>
        </Modal>
    );
};

export default RewardNoteModal;