import { inject, observer } from "mobx-react";

import React from "react";
import _ from "lodash";
import { Spin, Radio, Checkbox, Tooltip, Progress, Button } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import close from "../../static/images/close.png";
import { ConfirmModal } from "../UI/ConfirmModal";
import { toolTips, CBRS } from "../../en.json";
import { NewReferralModal } from "../UI/NewReferralModal";

@inject("store")
@observer
class ReferrerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleChoices: {},
      multipleChoicesRadio: {},
      descriptiveAnswer: {},
      rows: {},
      showWarnings: false,
      minRows: 1,
      maxRows: 10,
    };
  }

  handleCloseReferral = (value) => {
    const {
      store: {
        ReferralStore: {
          isDataSaved,
          setShowReferralForm,
          setSelfIntake,
          resetResponses,
          setReferralModalVisible,
        },
      },
    } = this.props;
    if (!isDataSaved) {
      setReferralModalVisible(true);
    } else {
      resetResponses();
      setShowReferralForm(false);
    }
  };

  saveDraft = () => {
    const {
      store: {
        ReferralStore: { saveAsDraft },
      },
    } = this.props;
    saveAsDraft();
  };

  setDescriptiveAnswer = (id, e) => {
    const {
      store: {
        ReferralStore: { setDescriptive },
      },
    } = this.props;

    // TODO: This is an unused component right now, but if needed in future, ExpandableTextArea can be used instead of logic below to avoid typing lag

    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = e.target.rows;
    e.target.rows = minRows;

    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }
    this.setState((prevState) => {
      return {
        rows: {
          ...prevState.rows,
          [id]: currentRows < maxRows ? currentRows : maxRows,
        },
      };
    });

    setDescriptive(id, e.target.value);
  };

  displayOptionBasedOnQuestionType = (id, type, options) => {
    const {
      store: {
        ReferralStore: {
          formAnswers,
          multipleChoices,
          multipleChoicesRadio,
          descriptiveAnswer,
          setMultipleChoicesRadio,
          setMultipleChoices,
          setDescriptive,
        },
      },
    } = this.props;
    const answer = formAnswers.find((item) => item.formQuestionId === id);
    if (type === "multiple_choices") {
      const myOptions = options.map((option) => {
        return { value: option.id, label: option.option };
      });

      let answerMultipleOptions = [];
      if (answer) {
        answerMultipleOptions = answer.answer
          .split(",")
          .map((ans) => Number(ans));
      }

      return (
        <Checkbox.Group
          name={id.toString()}
          options={myOptions}
          onChange={(e) => {
            setMultipleChoices(id, e);
          }}
          value={multipleChoices[id] ? multipleChoices[id] : []}
        />
      );
    } else if (type === "multiple_choices_radio") {
      // let answerRadioOptions = undefined;
      // if (answer) {
      //   answerRadioOptions = answer.formQuestionOptionId;
      // }

      return (
        <Radio.Group
          className="radio-group"
          onChange={(e) => {
            setMultipleChoicesRadio(id, e.target.value);
          }}
          value={multipleChoicesRadio[id]}
        >
          {options.map((option) => (
            <Radio key={option.id} value={option.id}>
              {option.option}
            </Radio>
          ))}
        </Radio.Group>
      );
    } else if (type === "descriptive") {
      // let answerDescriptive = "";
      // if (answer && answer.answer) {
      //   answerDescriptive = answer.answer;
      // }
      return (
        <div>
          <textarea
            rows={this.state.rows[id] ? this.state.rows[id] : 1}
            className="descriptive-textbox"
            value={this.state.descriptiveAnswer[id]}
            defaultValue={descriptiveAnswer[id]}
            onChange={(e) => this.setDescriptiveAnswer(id, e)}
            /* onBlur={() => {
              this.setState({ showWarnings: true });
            }} */
          />
          {/* {!this.state.descriptiveAnswer[id] && this.state.showWarnings ? (
            <div className="warning">* field cannot be empty</div>
          ) : null} */}
        </div>
      );
    }
  };

  exitModal = () => {
    const {
      store: {
        ReferralStore: {
          fetchAllReferrals,
          setDataSaved,
          resetResponses,
          setShowReferralForm,
          setReferralModalVisible,
        },
      },
    } = this.props;
    setReferralModalVisible(false);
    fetchAllReferrals();
    resetResponses();
    setShowReferralForm(false);
    setDataSaved(false);
  };

  submitForm = () => {
    const {
      store: {
        ReferralStore: { updateFormResponse },
      },
    } = this.props;
    updateFormResponse();
  };

  isDisabled = () => {
    const {
      store: {
        ReferralStore: {
          multipleChoices,
          multipleChoicesRadio,
          descriptiveAnswer,
        },
      },
    } = this.props;

    const multipleChoicesKeys = Object.keys(multipleChoices);
    const multipleChoicesRadioKeys = Object.keys(multipleChoicesRadio);
    const descriptiveAnswerKeys = Object.keys(descriptiveAnswer);
    const err1 = multipleChoicesKeys.filter(
      (key) => !multipleChoices[key].length
    );
    const err2 = multipleChoicesRadioKeys.filter(
      (key) => !multipleChoicesRadio[key]
    );
    const err3 = descriptiveAnswerKeys.filter(
      (key) => !descriptiveAnswer[key].trim().length
    );
    return err1.length || err2.length || err3.length;
  };

  render() {
    const {
      store: {
        ReferralStore: {
          responsesLength,
          savingForm,
          canSendReferral,
          referralFormQuestions,
          fetchingReferralData,
          targetUserId,
          targetUsername,
          clientSelectedForm,
          referralModalVisible,
          setReferralModalVisible,
        },
        BroadcastStore: { createDm, dmLoading },
      },
    } = this.props;

    // const {
    //   multipleChoices,
    //   multipleChoicesRadio,
    //   descriptiveAnswer,
    // } = this.state;
    // const totalLength =
    //   Object.keys(multipleChoices).length +
    //   Object.keys(multipleChoicesRadio).length +
    //   Object.keys(descriptiveAnswer).length;

    let disabled = true;
    if (referralFormQuestions.length !== responsesLength) {
      disabled = true;
    } else {
      disabled = canSendReferral;
      // this.isDisabled();
    }

    const isRSN = clientSelectedForm === "RSN";
    const isRC = clientSelectedForm === "RC";
    const isRSN_RC = clientSelectedForm === "RSN_RC";

    return (
      <div>
        <div className="referral-header">
          <Tooltip
            title={
              isRSN
                ? toolTips.RSN
                : isRC
                ? toolTips.RC
                : isRSN_RC
                ? toolTips.RSN_RC
                : null
            }
            placement="leftBottom"
          >
            <div>
              {clientSelectedForm === "RSN_RC"
                ? "RSN & RC"
                : clientSelectedForm}{" "}
              {CBRS.refForm}
              {/* <div className="referral-time">Last 10min ago</div> */}
            </div>
          </Tooltip>
          <div className="flag-actions-container">
            <button
              className="referral-form-button"
              disabled={dmLoading}
              onClick={() => createDm(targetUsername, targetUserId)}
            >
              {CBRS.msgClient}
            </button>
            <button
              className="referral-form-button"
              disabled={savingForm || fetchingReferralData}
              onClick={this.saveDraft}
            >
              {CBRS.saveDraft}
            </button>
            <button
              onClick={this.submitForm}
              disabled={disabled || savingForm || fetchingReferralData}
              className="new-referral-button"
            >
              {CBRS.sendRef}
            </button>
            <img
              src={close}
              onClick={() => this.handleCloseReferral(false)}
              alt="Close"
            />
          </div>
        </div>
        <div className="referral-scroll">
          <div className="referral-sub">
            <div>{CBRS.refFormSub}</div>
          </div>
          {fetchingReferralData || savingForm ? (
            <div className="saving-form">
              <Spin />
            </div>
          ) : (
            <div className="rsn-form-container">
              {referralModalVisible ? (
                <ConfirmModal
                  loading={savingForm}
                  onConfirm={() => this.saveDraft()}
                  onClose={() => setReferralModalVisible(false)}
                  onCancel={() => this.exitModal()}
                />
              ) : null}
              <div className="form-progress">
                <span>{CBRS.progress} </span>
                {/* <span className="referral-time">
                  Last 10min ago by <span> Sue Green </span>
                </span> */}
                <div className="form-progress-bar">
                  <Progress
                    percent={Math.trunc(
                      (responsesLength * 100) / referralFormQuestions.length
                    )}
                    format={(percent) => `${percent} %`}
                    strokeColor="#FB9048"
                    trailColor="#fff"
                  />
                </div>
                <span>{CBRS.queFilled}</span>
              </div>
              <div className="referral-forms-container">
                <div className="form-header"> {CBRS.clientInfo} </div>
                {referralFormQuestions.map((question, index) => {
                  return (
                    <div className="rsn-questions" key={question.id}>
                      <span className="question-number">{index + 1}. </span>
                      <span className="subHeading">
                        {question.questionTitle}
                      </span>
                      {this.displayOptionBasedOnQuestionType(
                        question.id,
                        question.type,
                        question.options
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default ReferrerForm;
