import "../../styles/global.scss";

import { Tabs, Table, Spin } from "antd";
import { inject, observer } from "mobx-react";

import React from "react";
import _ from "lodash";
import { toJS } from "mobx";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { getIntakeStatus } from "../../utils/getIntakeStatus";
import { CustomModal } from "../UI/CustomModal";
import info from "../../static/images/info.png";
import { CBRS } from "../../en.json";
import CbrsDiscontinueModal from "../UI/CbrsDiscontinueModal";
import { getFormattedPhoneNumber } from "../../utils/CommonUtils";

const TabPane = Tabs.TabPane;

@withRouter
@inject("store")
@observer
class IntakeView extends React.Component {
  async componentDidMount() {
    const {
      store: {
        ReferralStore: { fetchAllIntake },
        ProfileStore: { getHighPointUsers },
      },
    } = this.props;

    getHighPointUsers();
    fetchAllIntake();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const {
      store: {
        ReferralStore: { fetchAllIntake, setIntakePagination },
      },
    } = this.props;

    setIntakePagination(pagination);
    fetchAllIntake();
  };

  newReferral = (value) => {
    const {
      store: {
        ReferralStore: { resetClientData, setNewReferral },
      },
    } = this.props;
    resetClientData();
    setNewReferral(value);
  };

  setFilter = (key) => {
    const {
      store: {
        ReferralStore: { setIntakeFilter, intakeLoading },
      },
    } = this.props;
    if (intakeLoading) {
      return;
    }
    setIntakeFilter(Number(key));
  };

  loadMoreExportHistory = () => {
    const {
      store: {
        ReferralStore: { fetchExportHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchExportHistory();
  };

  loadMoreStatusHistory = () => {
    const {
      store: {
        ReferralStore: { fetchStatusHistory, targetUserId, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchStatusHistory(targetUserId);
  };

  fetchStatusHistory = (targetUserId) => {
    const {
      store: {
        ReferralStore: { fetchStatusHistory, setStatusHistoryVisible },
      },
    } = this.props;

    fetchStatusHistory(targetUserId);
    setStatusHistoryVisible(true);
  };

  render() {
    const {
      store: {
        ReferralStore: {
          skipExportHistory,
          totalExportHistory,
          exportHistoryVisible,
          setExportHistoryVisible,
          exportHistory,
          skipStatusHistory,
          totalStatusHistory,
          statusHistoryVisible,
          setStatusHistoryVisible,
          statusHistory,
          historyLoading,
          selfIntake,
          resetHistory,
          intakeLoading,
          intakes,
          intakePagination,
        },
        ProfileStore: { getUsersFromHpUsers, fetchingHpUsers },
        SiteStore: { getSiteNameFromAllSites },
      },
    } = this.props;

    const columns = [
      {
        title: "Name",
        dataIndex: "targetUserInfo",
        render: (targetUserInfo) => (
          <div className="link-text table-col">
            {targetUserInfo && targetUserInfo.fullName
              ? targetUserInfo.fullName
              : ""}
          </div>
        ),
      },
      {
        title: "Program",
        dataIndex: "program",
        render: (id) => (
          <div className="link-text table-col">
            {getSiteNameFromAllSites(id)}
          </div>
        ),
      },
      {
        title: "Referrer",
        dataIndex: "",
        render: (item) => (
          <div className="link-text table-col">
            {item.sourceUserInfo
              ? item.sourceUserInfo.fullName
              : "Self Referral"}
          </div>
        ),
      },
      {
        title: "Referrer Number",
        dataIndex: "",
        render: (item) => (
          <div className="link-text table-col">
            {getFormattedPhoneNumber(item?.sourceUserInfo?.phoneNumber)}
          </div>
        ),
      },
      {
        title: "Date Received",
        dataIndex: "sentAt",
        render: (text) => (
          <div className="link-text table-col">
            {text ? moment(text).format("MMMM Do YYYY, h:mm:ss a") : " - "}
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "",
        render: (item) => (
          <div className="link-text table-col">
            <span>{getIntakeStatus(item.status)}</span>
            <img
              src={info}
              className="info-icon"
              alt="info"
              onClick={(e) => {
                e.stopPropagation();
                this.fetchStatusHistory(item.targetUserId);
              }}
            />
          </div>
        ),
      },
      {
        title: "Assigned to",
        dataIndex: "",
        render: (item) => (
          <div className="link-text table-col">
            {!item.alreadyAssigned.length ? (
              " - "
            ) : fetchingHpUsers ? (
              <Spin />
            ) : (
              getUsersFromHpUsers(item.alreadyAssigned)
            )}
          </div>
        ),
      },
    ];

    const rowEvents = (record, rowIndex) => {
      const {
        store: {
          ReferralStore: {
            getReferalResponse,
            selfIntake,
            isDataSaved,
            getSelfReferralResponse,
            setIntakeModalVisible,
          },
        },
      } = this.props;
      return {
        onClick: () => {
          if (selfIntake && !isDataSaved) {
            setIntakeModalVisible(true);
            return;
          } else {
            if (record.sourceUserId === -1) {
              getSelfReferralResponse(record);
            } else {
              getReferalResponse(record);
            }
          }
        },
      };
    };

    return (
      <div className="member-table">
        <CbrsDiscontinueModal />
        <CustomModal
          title="Export History"
          visible={exportHistoryVisible}
          loading={historyLoading}
          loadMore={this.loadMoreExportHistory}
          hasMore={skipExportHistory < totalExportHistory}
          onCancel={() => {
            resetHistory();
            setExportHistoryVisible(false);
          }}
          data={exportHistory ? exportHistory : []}
        />
        <CustomModal
          title="Status History"
          visible={statusHistoryVisible}
          loading={historyLoading}
          loadMore={this.loadMoreStatusHistory}
          hasMore={skipStatusHistory < totalStatusHistory}
          onCancel={() => {
            resetHistory();
            setStatusHistoryVisible(false);
          }}
          data={statusHistory ? statusHistory : []}
        />
        <div className="search-member-container">
          <Tabs tabBarStyle={{ border: 0 }} onChange={this.setFilter}>
            <TabPane className="my-tabs-ink-bar" tab="All" key="0"></TabPane>
            <TabPane
              className="my-tabs-ink-bar"
              tab="Open Referrals"
              key="1"
            ></TabPane>
            <TabPane
              className="my-tabs-ink-bar"
              tab="Archived"
              key="2"
            ></TabPane>
            <TabPane
              className="my-tabs-ink-bar"
              tab="Completed"
              key="3"
            ></TabPane>
          </Tabs>
          <div>
            <button
              className="new-referral-button"
              onClick={() => this.newReferral(true)}
            >
              {CBRS.newCBRS}
            </button>
          </div>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={toJS(intakes)}
          onChange={this.handleTableChange}
          pagination={intakePagination}
          loading={intakeLoading}
          onRow={rowEvents}
        />
      </div>
    );
  }
}

export default IntakeView;
