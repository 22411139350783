import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from "lodash";

import { alertLeftSide } from "../../en.json";
import { COMMENT } from '../../constants/GlobalConstant';
import { getActivitiesByIds } from '../../utils/forumFeedService';

const SingleForumDeletedItem = inject("store")(observer((props) => {
    const {
        item,
        store: {
            ProfileStore: { setSelectedAlert },
            DeletedForumItemListStore: {
                selectedForumItem,
            },
            FlaggedMessagesStore: {
                selectedMessage
            }
        }
    } = props;

    const handleClick = async () => {
        const {
            ForumFeedStore: {
                setNotificationCommentId,
                setCurrentFeedId,
                tags,
                fetchTags,
                setComments,
            },
            ProfileStore: {
                changeSelectedAction
            },
            FlaggedMessagesStore: {
                selectedMessage,
                setSelectedMessage
            }
        } = props.store;

        if ((selectedMessage?.commentId || selectedMessage?.activityId) === item?.resourceId) return
        
        let activity, activityId, comment, commentId; 
        if (item.type === COMMENT) {
            activity = (await getActivitiesByIds([item.resource.activity_id]))[0];
            comment =  item.resource;
            commentId = item.resourceId;
            activityId = item.resource.activity_id;
        } else {
            activity = {...item.resource};
            comment =  null;
            commentId = null;
            activityId = item.resourceId;
        }
        
        const message = {
            ...item,
            isForumFlag: true,
            activity,
            comment,
            activityId,
            commentId
        }

        changeSelectedAction("View in context");
        if (selectedForumItem !== item.id) {
            setSelectedAlert({});
            setSelectedMessage(message);
            if (commentId) {
                setComments([]);
                setNotificationCommentId(commentId);
            } else if (!tags.length) {
                await fetchTags();
            }
            setCurrentFeedId(activityId);
        }
    }

    let tag, title, subTitle, postedBy, createdAt;
    if (item.type === COMMENT) {
        tag = 'Comment';
        title = item?.resource?.data?.comment;
        subTitle = item?.resource?.activity?.meta?.title;
        postedBy = item?.resource?.user?.data?.username;
        createdAt = item.resource?.data?.deleted_at;
    } else {
        tag = 'Post';
        title = item?.resource?.meta?.title;
        subTitle = null;
        postedBy = item?.resource?.actor?.data?.username;
        createdAt = item?.resource?.deleted_at;
    }

    return <React.Fragment>
        <div key={item?.id} style={{border : (selectedMessage?.commentId || selectedMessage?.activityId) === item?.resourceId ? '2px solid red' : null}}>
            <div
                className="flag-container"
                style={
                    selectedForumItem?.itemId === item.id
                        ? { border: "5px solid #4168ca" }
                        : null
                }
            >
                <div className="flag-upper">
                    <div
                        className="flag-name-container"
                        style={{ cursor: "pointer" }}
                    >
                        <div>
                            {title && (
                                <span className="flag-name word-wrap">{title}</span>
                            )}
                            {item?.resource?.imgUrl && (
                                <img src={item?.resource?.imgUrl} alt="" height="60" width="60" />
                            )}
                        </div>
                        <div className="flag-profile-container">
                            <span className="flag-profile">
                                {tag}
                            </span>
                        </div>
                    </div>
                    <div className="flag-time">
                        {moment(createdAt).calendar()}
                    </div>
                </div>
                {(
                    <div className="info-icon-container">
                        <div className="flag-group">
                            {subTitle && `in ${subTitle.length > 30
                                ? `${subTitle.slice(0, 30)}...`
                                : subTitle
                                }`}
                        </div>
                    </div>
                )}
                <div className="flag-middle">
                    <span className="subHeading">
                        {`Posted By: ${postedBy}`}
                    </span>
                </div>
                <div className="flag-middle">
                    <span style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 300, fontSize: 14, color: '#2a2a2a' }}>
                        {`Deleted By: ${item.deletedBy}`}
                    </span>
                </div>
                <div className="flag-lower">
                    <div className="flag-buttons">
                        <button
                            className="flag-message-button"
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClick();
                            }}
                        >
                            {alertLeftSide.viewInContext}
                        </button>
                    </div>
                </div>
            </div>
            <hr className="horizontal-tag" />
        </div>
    </React.Fragment>
}));

export default SingleForumDeletedItem;
