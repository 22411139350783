import { types } from "mobx-state-tree";
import MixpanelQueryResult from "./MixpanelQueryResult";
const MixpanelTrack = types
  .model("MixpanelTrack", {
    eventsData: types.array(types.string),
    propertyData: types.array(types.string),
    queryResult: types.array(MixpanelQueryResult),
    graphLabel: ""
  })
  .actions(self => ({
    saveEventsData(data) {
      self.eventsData = data;
    },
    saveProperties(data) {
      self.propertyData = data;
    },
    saveQueryResult(data) {
      self.queryResult = data;
    },
    clearData() {
      self.eventsData = [];
      self.propertyData = [];
      self.queryResult = [];
      self.graphLabel = "";
    },
    setGraphLabel(label) {
      self.graphLabel = label;
    }
  }));

export default MixpanelTrack;
