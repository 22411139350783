import { Icon } from "antd";
import { inject, observer } from "mobx-react";

import AlertLeftSide from "./AlertLeftSide";
import DmSection from "./DmSection";
import WaitingSection from "./WaitingSection";
import ModeratorTag from "./ModeratorTag";
import React from "react";
import SVG from "react-inlinesvg";
import UserDetails from "./UserDetails";
import _ from "lodash";
import { alert, deletedForumContent } from "../../en.json";
import close from "../../static/images/close.png";
import flag from "../../static/images/flag.svg";
import deleted from "../../static/images/deleted.png";
import flagFilled from "../../static/images/flagFilled.svg";
import {
  publish,
  getPubnubInstanceByUserType,
} from "../../utils/PubnubMethods";
import { ResetPwdModal } from "../UI/ResetPwdModal";
import history from "../../utils/history";
import { DEV } from "../../constants/UserRolesConstant";
import { SELECT_FEED } from '../../utils/ImageUtils';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

@inject("store")
@observer
class Alert extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const {
      store: {
        MemberListStore,
        AuthStore,
        ProfileStore,
        SiteStore,
        FlaggedMessagesStore,
      },
    } = this.props;

    ProfileStore.getGroupDetails();
    ProfileStore.changeSelectedAction("");
    ProfileStore.getProfileQuestions();
    let siteId = SiteStore.selectedSiteId;
    const userType = AuthStore.type;
    const isFrontDesk = userType === "FD" ? true : false;
    if (siteId !== 0 && siteId !== -1) {
      const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
      MemberListStore.fetchUnApprovedMembers(siteDataObj);
      MemberListStore.fetchApprovedMembers(siteDataObj);
    } else {
      MemberListStore.fetchUnApprovedMembers();
      MemberListStore.fetchApprovedMembers();
    }
    FlaggedMessagesStore.clear();
    if (FlaggedMessagesStore.urlAlertId) {
      FlaggedMessagesStore.fetchUrlAlert(FlaggedMessagesStore.urlAlertId);
    }
    if (!isFrontDesk) {
      if (siteId !== 0 && siteId !== -1) {
        const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
        FlaggedMessagesStore.fetchFlaggedMessagesSummary({
          isResolved: false,
          ...siteDataObj,
        });
      } else {
        FlaggedMessagesStore.fetchFlaggedMessagesSummary({
          isResolved: false,
        });
      }
    }
    if (!SiteStore.allSites.length) {
      SiteStore.fetchAllSites();
    }
  }

  appendData = () => {
    const {
      store: {
        MessagesStore,
        AuthStore: { type },
      },
    } = this.props;

    const message = MessagesStore.getMessage();
    const text = message.text;
    if (text.trim() !== "") {
      const pubnub = getPubnubInstanceByUserType(type);
      message.clearText();
      text.trim() !== "" && publish(pubnub, text);
    }
  };

  assignAdmins = () => {
    const {
      store: {
        MemberListStore: {
          sendMailToAdmins,
          selectedUserId,
          assignEmailLoading,
          assignNewUsers,
        },
      },
    } = this.props;
    if (assignEmailLoading) {
      return;
    } else {
      if (selectedUserId) {
        assignNewUsers();
      } else {
        sendMailToAdmins();
      }
    }
  };

  changeFlagValue = (value) => {
    const {
      store: {
        ProfileStore: { toggleFlag, userId },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    toggleFlag(value);
  };

  checkMessageType = (message) => {
    return (
      message.entry.type === "text" ||
      message.entry.type === "img" ||
      message.entry.type === "gif"
    );
  };

  fetchDeletedMessages = () => {
    const {
      store: {
        ProfileStore: { userId },
        DeletedMessagesStore: {
          setShowDeleted,
          fetchUserDeletedMessages,
          userDeletedLoading,
        },
      },
    } = this.props;
    if (userDeletedLoading || !userId) {
      return;
    }
    fetchUserDeletedMessages(userId);
    setShowDeleted(true);
  };

  getDmNames = (users) => {
    const {
      store: {
        AuthStore: { username },
      },
    } = this.props;

    return _.split(users, ",")
      .filter((user) => user !== username)
      .map((name, index) => (
        <React.Fragment key={index}>
          {index !== 0 && ","}
          <span>{name}</span>
          <ModeratorTag username={name} />
        </React.Fragment>
      ));
  };

  handleClose = () => {
    const {
      store: {
        ProfileStore: { reset },
        DeletedMessagesStore: {
          setShowDeleted,
          setShowGroupUnDeleted,
          setShowUnFlagged,
        },
      },
    } = this.props;
    reset();
    setShowDeleted(false);
    setShowGroupUnDeleted(false);
    setShowUnFlagged(false);
  };

  openWaitingChat = (username, userId) => {
    const {
      store: {
        BroadcastStore: { startWaitingChat },
        ProfileStore: { setSelectedWaiting },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    setSelectedWaiting(userId);
    startWaitingChat(username, userId);
  };

  removeAssign = (id) => {
    const {
      store: { MemberListStore },
    } = this.props;
    MemberListStore.removeAssignedAdmin(id);
  };

  onOkResetPwdModal = async () => {
    const {
      store: {
        AuthStore: {closeResetPwdModal},

      },
    } = this.props;
    await closeResetPwdModal();
    history.push("forgot-password");
  }

  render() {
    const {
      store: {
        ProfileStore: {
          profileVisible,
          username,
          type,
          userId,
          selectedAction,
          showFlag,
          setProfileVisibility,
        },
        MessagesStore: { isWaiting },
        AuthStore: { type: userType, userId: ownId, showResetPwdPopup, closeResetPwdModal },
        BroadcastStore: { createDm },
        MemberListStore: { assignedAdmins, detailsLoading, assignEmailLoading },
        FlaggedMessagesStore: {
          selectedTab
        }
      },
      navHeight,
    } = this.props;
    const isFrontDesk = userType === "FD" ? true : false;
    return (
      <div className="display-flex">
        <div className="left-alert-sidebar" style={{
          height: `calc(100vh - ${navHeight}px)`,
          overflowY: "auto",
        }}>
          <AlertLeftSide navHeight={navHeight}/>
        </div>
        {selectedTab === '3' && selectedAction === '' && (
          <div className="select-group-message">
            <img id="select-feed-img" src={SELECT_FEED} alt={deletedForumContent.selectContentToExpand} />
            <h3>{deletedForumContent.selectContentToExpand}</h3>
          </div>
        )}
        {profileVisible && selectedAction === "" ? (
          <div className="right-section center">
            {detailsLoading ? null : (
              <div className="header-section assign-padding">
                <div className="profile-name">
                  <span>
                    {alert.profileOf}{" "}
                    {username.length > 30
                      ? `${username.slice(0, 30)}...`
                      : username}
                  </span>
                  <button
                    className="flag-resolve-button"
                    onClick={() => {
                      if (!userId) {
                        return;
                      }
                      createDm(username, userId);
                      setProfileVisibility(true);
                    }}
                  >
                    {alert.dm}
                  </button>
                  {userType !== "NOA" &&
                  type !== "moderator" &&
                  type !== "SA" &&
                  userId !== ownId ? (
                    <button
                      className="flag-resolve-button"
                      onClick={() => this.openWaitingChat(username, userId)}
                    >
                      {alert.queue}
                    </button>
                  ) : null}
                </div>
                <div className="flag-actions-container">
                  {!isFrontDesk ? (
                    <>
                      <div
                        className="actions-container"
                        onClick={() => this.fetchDeletedMessages()}
                      >
                        <img
                          src={deleted}
                          alt="Deleted"
                          className="undeleted-icon"
                        />
                        {alert.showDeleted}
                      </div>
                      {showFlag ? (
                        <div
                          className="actions-container"
                          onClick={() => this.changeFlagValue(false)}
                        >
                          <SVG src={flagFilled}></SVG>
                          {alert.hideFlags}
                        </div>
                      ) : (
                        <div
                          className="actions-container"
                          onClick={() => this.changeFlagValue(true)}
                        >
                          <SVG src={flag}></SVG>
                          {alert.showFlags}
                        </div>
                      )}
                    </>
                  ) : null}
                  <img
                    src={close}
                    onClick={() => this.handleClose()}
                    alt="Close"
                  />
                </div>
              </div>
            )}

            <div className="d-flex">
              <div style={{ flex: "1", position: "relative" }}>
                <div className="d-flex flex-column h-100">
                  <div id="alert-display-container">
                    <UserDetails allMembers={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {selectedAction === "Dm creation" ? <DmSection /> : null}
        {isWaiting ? <WaitingSection /> : null}
        {showResetPwdPopup &&
            <div className="left-sidebar2">
              <ResetPwdModal
                onOk={this.onOkResetPwdModal}
                visible={showResetPwdPopup}
                onClose={closeResetPwdModal}
                />
            </div>
          }
      </div>
    );
  }
}
export default Alert;
