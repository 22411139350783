import React from "react";
import LinkifyMatchDecorator from "../../utils/LinkifyMatchDecorator";
import { openUserProfile } from "../../utils/openUserProfile";

const LinkifiedUsername = ({ text = "", usernameToUserIdMap }) => {
  const matches = LinkifyMatchDecorator(text, usernameToUserIdMap);

  if (!matches) {
    return <p>{text}</p>;
  }

  const elements = [];
  let lastIndex = 0;

  matches.forEach((match, index) => {
    const { index: startIndex, lastIndex: endIndex, text: matchedText, url } = match;

    if (lastIndex < startIndex) {
      elements.push(text.substring(lastIndex, startIndex));
    }

    if (matchedText.startsWith("@")) {
      elements.push(
        <span
          key={index}
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => openUserProfile({
            username: matchedText.slice(1),
          })}
        >
          {matchedText}
        </span>
      );
    } else if (matchedText.startsWith("#")) {
      elements.push(
        <span
          key={index}
          style={{ color: "black", fontWeight: "bold" }}
        >
          {matchedText}
        </span>
      );
    } else {
      elements.push(
        <span
          key={index}
        >
          <a href={url} target="_blank" rel="noopener noreferrer">{matchedText}</a>
        </span>
      );
    }

    lastIndex = endIndex;
  });

  if (lastIndex < text.length) {
    elements.push(text.substring(lastIndex));
  }

  return <p>{elements}</p>;
};

export default LinkifiedUsername;
