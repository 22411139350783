import React from "react";
import { Spin } from "antd";
import { inject, observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import MessageBubble from "./MessageBubble";
import ExpandableTextArea from "../UI/ExpandableTextArea";
import _ from "lodash";
import sendIcon from "../../static/images/send-icon.png";
import close from "../../static/images/close.png";
import {
  publish,
  getPubnubInstanceByUserType,
  history,
  fetchBottomMessages,
} from "../../utils/PubnubMethods";
import FeedComments from "./FeedsComment";
import SingleFeed from "../UI/SingleFeed";
@inject("store")
@observer
class ViewInContext extends React.Component {
  constructor(props) {
    super(props);
    this.expandableTextAreaRef = React.createRef();
    this.state = {
      value: "",
    };
    this.messageList = React.createRef();
  }
  appendData = async () => {
    const {
      store: {
        MessagesStore: { getMessage },
        AuthStore: { type },
      },
    } = this.props;
    const message = getMessage();
    const text = message.text;
    if (text.trim() !== "") {
      const pubnub = await getPubnubInstanceByUserType(type);
      message.clearText();
      text.trim() !== "" && publish(pubnub, text);

      if (this.expandableTextAreaRef && this.expandableTextAreaRef.current) {
        this.expandableTextAreaRef.current.contractTextArea();
      }
    }
  };

  handleScroll = async (e) => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { messages, hasBottomMessages },
        },
        AuthStore: { type },
      },
    } = this.props;
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      const pubnub = await getPubnubInstanceByUserType(type);
      messages.length && hasBottomMessages && fetchBottomMessages(pubnub);
    }
  };

  scrollToTop = (prevScrollHeight) => {
    this.props.messageList.current.scrollTop =
      this.props.messageList.current.scrollHeight -
      prevScrollHeight +
      this.props.messageList.current.scrollTop;
  };

  loadMore = async () => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { setLoading, loading, reactionsLoaded },
          viewportMessagesLoaded,
          setViewportMessagesLoaded,
        },
        AuthStore: { type },
      },
    } = this.props;
    if (!loading && !reactionsLoaded && viewportMessagesLoaded) {
      const prevScrollHeight = this.props.messageList
        ? this.props.messageList.current.scrollHeight
        : null;
      setLoading(true);
      setViewportMessagesLoaded(false);
      const pubnub = await getPubnubInstanceByUserType(type);
      history(pubnub, this.scrollToTop, prevScrollHeight);
    }
  };
  handleChange = (e) => {
    const {
      store: { MessagesStore },
    } = this.props;

    const text = e.target.value;
    const message = MessagesStore.getMessage();

    this.setState({
      value: e.target.value,
    });

    message.setText(text);
  };

  handleKeyPress = (e, broadcastMessage = false) => {
    const {
      store: {
        BroadcastStore: { startDm },
      },
    } = this.props;

    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (broadcastMessage) {
        startDm();
      } else {
        this.appendData();
      }
    }
  };

  checkMessageType = (message) => {
    return (
      message.entry.type === "text" ||
      message.entry.type === "img" ||
      message.entry.type === "gif"
    );
  };
  scrollToBottomPress = async () => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { id, name, userType, clearMessages, isPaused, isIntersiteGroup },
          changeChannel,
          setScrollVisible,
        },
        GroupStore: { fetchGroupUsers },
      },
    } = this.props;
    const type = "group";
    clearMessages();
    await changeChannel(id, name, userType, isPaused, type, isIntersiteGroup, () =>
      this.scrollToBottom()
    );
    fetchGroupUsers(id);
    this.scrollToBottom();
    setScrollVisible(false);
  };
  scrollToBottom = () => {
    if (this.messageList.current) {
      const scrollHeight = this.messageList.current.scrollHeight;
      const height = this.messageList.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };
  handleClose = () => {
    const {
      store: {
        ProfileStore: { changeSelectedAction, setSelectedAlert },
        FlaggedMessagesStore: { setSelectedMessage },
      },
    } = this.props;
    changeSelectedAction("");
    setSelectedAlert({});
    setSelectedMessage(null);
  };

  render() {
    const {
      store: {
        MessagesStore: {
          selectedGroup: {
            id,
            messages,
            loading,
            hasMore,
            channel,
            name,
            groupLastSeen,
          },
          scrollVisible,
          text,
        },
        FlaggedMessagesStore: {
          selectedMessage
        }
      },
      navHeight,
    } = this.props;
    let prevDate = "";
    return (
      selectedMessage?.isForumFlag
      ? <React.Fragment>
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}>
            <div className="header-section p-1">
              <h1 className="dm-heading">
                <img src={close} onClick={() => this.handleClose()} alt="Close" />
              </h1>
            </div>
            {
              selectedMessage?.commentId !== "" && selectedMessage?.commentId !== null
                ?
                <FeedComments
                  isAlertScreen={true}
                  isDeleteOnly={true}
                  isFeedDeleted={selectedMessage?.activity?.deleted_at !== null}
                  onDeletionComplete={this.handleClose}
                />
                :
                <div style={{ overflowY: "auto" }}>
                  <div style={{ width: "746px" }}>
                    <SingleFeed
                      feed={selectedMessage?.activity}
                      isDeleteOnly={true}
                      isReadOnly={selectedMessage?.activity?.deleted_at !== null}
                      onDeletionComplete={this.handleClose}
                    />
                  </div>
                </div>
            }
          </div>
        </React.Fragment>
        : <React.Fragment>
          <div className="right-section">
            <div className="header-section p-1">
              <h1 className="dm-heading">
                {name}
                <img src={close} onClick={() => this.handleClose()} alt="Close"/>
              </h1>
            </div>
            <div className="d-flex">
              <div className="p-1" style={{ flex: "1", position: "relative" }}>
              <div className="d-flex flex-column h-100">
                <div
                  className={scrollVisible ? "scroll-to-bottom" : "hide-scroll"}
                  onClick={() => this.scrollToBottomPress()}
                >
                  Scroll To Bottom
                </div>
                <div className="input-container">
                  <ExpandableTextArea
                    defaultTextAreaRows={2}
                    expandIconClassName={"expand-textarea-img view-in-context"}
                    ref={this.expandableTextAreaRef}
                    value={text}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress}
                    placeholder="Type here to send a message..."
                  />
                  <img
                    src={sendIcon}
                    onClick={this.appendData}
                    value="Append"
                    className="send-text-img ml-auto"
                    style={text.trim() !== "" ? { opacity: 1 } : { opacity: 0.4 }}
                    alt="Send"
                  />
                </div>
                {loading && <Spin />}
                <div
                  id="display-data-Container"
                  ref={this.messageList}
                  onScroll={(e) => this.handleScroll(e)}
                  style={{
                    height: `calc(100vh - ${navHeight + 169}px)`
                  }}
                >
                  <InfiniteScroll
                    loadMore={this.loadMore}
                    hasMore={hasMore}
                    isReverse
                    useWindow={false}
                  >
                    {_.uniqBy(messages, "timetoken").map((message, index) => {
                      const currDate = moment
                        .unix(parseInt(message.timetoken) / 10000000)
                        .format("LL");
                      const messageBox = (
                        <React.Fragment key={index}>
                          {currDate !== prevDate &&
                            this.checkMessageType(message) && (
                              <div className="message-date">
                                <span>{currDate}</span>
                              </div>
                            )}
                          <MessageBubble
                            message={message}
                            groupChat={_.startsWith(channel, "GROUP_CHAT")}
                            groupId={
                              _.startsWith(channel, "GROUP_CHAT") ? id : null
                            }
                            groupLastSeen={groupLastSeen}
                          />
                        </React.Fragment>
                      );

                      prevDate = currDate;
                      return messageBox;
                    })}
                  </InfiniteScroll>
                  <div
                    style={{ float: "left", clear: "both" }}
                    ref={(el) => {
                      this.messagesEnd = el;
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ViewInContext;
