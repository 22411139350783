import React from 'react';
import { inject, observer } from "mobx-react";
import { Row, Col, Select, Tooltip } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import IconTint from 'react-icon-tint';

import { DEV, MODERATOR, NOA, SA } from '../../constants/UserRolesConstant';
import {
    getRankingForSelectedTimeFilter,
    subscribeFeedNotifications,
    unsubscribeFeed
} from "../../utils/forumFeedService";
import { REACT_APP_GET_FEED_GROUP, REACT_APP_GET_FEED_ID } from '../../configs/EnvConfig';
import { TIME_FILTERS } from '../../constants/GlobalConstant';
import { CaretDownFilled } from '@ant-design/icons';
import mixpanel from "mixpanel-browser";

const { Option } = Select;

const FeedTabHeader = inject("store")(observer((props) => {
    const {
        AuthStore: {
            type
        },
        ForumFeedStore: {
            categories,
            selectedCategoryFilter,
            selectedTimeFilter,
        },
    } = props.store;

    const handleCategoryFilterChange = async (feedGroupId) => {
        const {
            setSelectedCategoryFilter,
            setFeeds,
            fetchFeeds,
            selectedCategoryFilter,
        } = props.store.ForumFeedStore;
        if (selectedCategoryFilter !== feedGroupId) {
            await unsubscribeFeed(REACT_APP_GET_FEED_GROUP, selectedCategoryFilter);
            setSelectedCategoryFilter(feedGroupId);
            setFeeds([]);
            await fetchFeeds();
            await subscribeFeedNotifications();

            const filterDetail = {
                from: "WEB",
                ranking: getRankingForSelectedTimeFilter(selectedTimeFilter),
                feedGroupId: feedGroupId,
                slug: REACT_APP_GET_FEED_GROUP,
            };
            mixpanel.track("Forum Filter Applied", filterDetail);
        }
    };

    const categoryContainer = (feedGroupId, color) => {
        const style = {};
        if (selectedCategoryFilter === feedGroupId) {
            style['backgroundColor'] = color;
            style['color'] = 'white';
        } else {
            style['color'] = color;
            style['borderColor'] = color;
        }
        return style;
    };

    const doCreatePost = () => {
        const { setVisible } = props.store.ForumFeedStore;
        setVisible(true);
    }

    const doApplyFilter = async (value) => {
        const {
            fetchFeeds,
            setSelectedTimeFilter,
            reset,
            fetchFlaggedFeeds,
            setFlaggedFeedPage,
            setFeeds,
            selectedTimeFilter,
        } = props.store.ForumFeedStore;

        mixpanel.track(`Forum Total Time For ${selectedTimeFilter}`, { from: "WEB" });
        mixpanel.time_event(`Forum Total Time For ${value}`);
        setSelectedTimeFilter(value);
        reset();
        setFeeds([]);
        if (value === TIME_FILTERS.FLAGGED) {
            setFlaggedFeedPage(1);
            fetchFlaggedFeeds(false);

            const filterDetail = {
                from: "WEB",
                ranking: value,
                feedGroupId: selectedCategoryFilter,
                slug: REACT_APP_GET_FEED_GROUP,
            };
            mixpanel.track("Forum Filter Applied", filterDetail);
        } else {
            fetchFeeds();
        }
    }

    const canEditFeed = (type === SA || type === DEV || type === MODERATOR || type === NOA);
    const filters = canEditFeed ? Object.values(TIME_FILTERS) : Object.values(TIME_FILTERS).slice(0, 3);
    return (
        <>
            <Row>
                <Col span={12} style={{ display: 'flex', fontWeight: "bold" }}>
                    {/* Filter starts */}
                    {/* Dropdown to select feed type filter */}
                    <Select
                        className='forum-select-container'
                        style={{ width: "100%", marginLeft: 10, marginTop: 10, marginRight: 10 }}
                        value={selectedTimeFilter}
                        onChange={(value) => doApplyFilter(value)}
                        suffixIcon={<CaretDownFilled style={{ margin: "-2px 0px 0px -20px" }} />}
                    >
                        {filters.map((value, index) => (
                            <Option key={index} value={value} >
                                <Tooltip title={value} placement="topRight">
                                    <b> {value}</b>
                                </Tooltip>
                            </Option>
                        ))}
                    </Select>
                    {/* Filter ends */}
                </Col>
                <Col span={4}></Col>
                <Col span={8}>
                    {/** create post button */}
                    {<Row>
                        <Col
                            span={24}
                            style={{
                                display: 'flex',
                                padding: '0px 15px 0px 15px',
                                marginTop: 10,
                                justifyContent: 'end'
                            }}
                        >
                            <p
                                className="create-post"
                                onClick={() => doCreatePost()}
                            >
                                Create
                            </p>
                        </Col>
                    </Row>}
                    {/** create post button ends */}

                </Col>
            </Row>


            {/* category filter start */}
            <div style={{ marginLeft: 18 }}>
                <CheckableTag
                    checked={selectedCategoryFilter === REACT_APP_GET_FEED_ID}
                    onChange={() => handleCategoryFilterChange(REACT_APP_GET_FEED_ID)}
                    className="left-side-tag-filter"
                    style={{
                        cursor: 'pointer',
                        ...(selectedCategoryFilter === REACT_APP_GET_FEED_ID
                            ? { color: 'white', backgroundColor: '#56CCF2' }
                            : { color: '#56CCF2', borderColor: '#56CCF2' }),
                        borderRadius: "73.33px",
                        padding: "4.89px 11px",
                        lineHeight: "18px",
                    }}
                >
                    All
                </CheckableTag>
                {categories.map(({ feedGroupId, category, color, icon }) => {
                    const isChecked = selectedCategoryFilter === feedGroupId;
                    return (<>
                        <strong>
                            <CheckableTag
                                checked={isChecked}
                                onChange={() => handleCategoryFilterChange(feedGroupId)}
                                className="left-side-tag-filter"
                                style={categoryContainer(feedGroupId, color)} >
                                <Row id="content">
                                    <Col style={{ height: '22px' }}>
                                        {
                                            (isChecked)
                                            ? <IconTint src={icon} id="icon" color="white" style={{ height: '22px' }} />
                                            : <img src={icon} style={{ height: '22px' }} />
                                        }
                                    </Col>
                                    <Col>
                                        {category}
                                    </Col>
                                </Row>
                            </CheckableTag>
                        </strong>
                    </>)
                })}
            </div>
            {/* category filter end */}
        </>
    )
}));

export default FeedTabHeader;
