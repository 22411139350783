import React from "react";

const CbrsDiscontinueModal = (props) => {
    const { onConfirm } = props;
    return (
        <div>
            <div className="confirm-backdrop" onClick={onConfirm} />
            <div className="cbrs-discontinue-modal">
                <div className="modal-heading">
                    <h1>Please Note</h1>
                </div>
                <p>Hello, the Marigold dashboard has been deactivated as of Jan 31, 2022. </p>
                <p>To submit a new CBRS referral fill out the form at the following link: <a>https://www.hptc.org/cbrs-referral </a>.</p>
                <p>If you would like an update on an existing referral, email cbrs@hptc.org to reach CBRS admin.
                    HPTC Clients can still use the Marigold app to access 24/7 text support groups moderated by a Recovery Coach.</p>
                <p>
                    Clients can download the app by searching “Marigold Health” on the app store and entering <strong>PEER30</strong> as a pin when creating an account.</p>
            </div>
        </div >
    );
};

export default CbrsDiscontinueModal;