import { Col, Row } from "antd";
import React from "react";
import { DISCARD } from "../../utils/ImageUtils";
import _ from "lodash";

const LinkPreview = (props) => {
    const { url, removeURLFromList, index, previewData } = props;
    const openLink = (url) => {
        if (!(_.startsWith(url, 'https://') || _.startsWith(url, 'http://'))) {
            window.open('https://' + url, '_blank');
        } else {
            window.open(url, '_blank');
        }
    }
    if (!previewData) {
        return (
            <div className="link-url-container">
                <Row>
                    <Col span={24}>   
                        {removeURLFromList && 
                            <img src={DISCARD}
                                onClick={e => removeURLFromList(index)}
                                style={{ position: "absolute", cursor:"pointer", right: "-21px", top: "-20px" }}
                            />
                        }
                        <p
                          className="link-url-text"
                          onClick={(e) => {
                            e.stopPropagation();
                            openLink(url);
                          }}
                        >
                            {url}
                        </p>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div className="link-preview-data-container">
            <Row>
                <Col span={4}>
                    {previewData.image && <img src={previewData.image} alt="Link Preview"
                        style={{ borderRadius: 5, height: 70, width: "100%" }}
                    />}
                </Col>
                <Col span={20}>
                    <div style={{
                        marginLeft: 15,
                        height: 63,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block"

                    }}>
                        <div
                            style={!removeURLFromList ? { display: "none" } : { display: "inline" }}
                        >
                            <img src={DISCARD}
                                onClick={e => removeURLFromList(index)}
                                style={{ position: "absolute", top: -5, right: 0, marginRight: -5, cursor:"pointer" }}
                            />
                        </div>
                        <div
                            onClick={e => {
                                e.stopPropagation();
                                window.open(url, '_blank');
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <p style={{ fontWeight: "bold", marginTop: 5 }}>{previewData.title}</p>
                            <p>{previewData.description}</p>
                        </div>

                    </div>
                </Col>
            </Row>
        </div >
    );
}

export default LinkPreview;
