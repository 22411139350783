import React from "react";
import { Modal, Spin } from "antd";

export const IceBreakerModal = (props) => {
  const { title, onOk, loading, onCancel, visible } = props;
  return (
    <Modal
      width={"900px"}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      visible={visible}
    >
      {loading ? (
        <div className="form-loading">
          <Spin />
        </div>
      ) : (
        props.children
      )}
    </Modal>
  );
};
