import SiteStore from "../stores/SiteStore";

export const getReferralStatus = (statusId, siteId) => {
  const siteName = SiteStore.getSiteNameFromAllSites(siteId);

  switch (statusId) {
    case 100:
      return "Incomplete";
    case 101:
      return `Sent to CBRS`;
    case 102:
      return "Under Review";
    case 103:
      return "Under Review";
    case 104:
      return "Archived - Unable to contact";
    case 105:
      return "Archived - Pt denied";
    case 106:
      return "Archived - Other";
    case 107:
      return "Completed";
    case 108:
      return "Completed";
    default:
      return "";
  }
};
