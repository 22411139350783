import { inject, observer } from "mobx-react";

import AppStore from "../../static/images/app_store.png";
import { Icon } from "antd";
import Launcher from "../../static/images/ic_launcher.png";
import PlayStore from "../../static/images/play_store.png";
import React from "react";
import { userSignUpSuccess } from "../../en.json";

@inject("store")
@observer
class UserSignUpSuccess extends React.Component {
  state = {
    showPassword: false,
    showPageNotFound: false
  };
  toggleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showPageNotFound: true
      });
    }, 1200000);
  }
  getOS = () => {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }
    return os;
  };
  render() {
    const {
      store: {
        UserSignUpStore: { username, fireMixpanelEventForIconClick, password }
      }
    } = this.props;
    const { showPassword, showPageNotFound } = this.state;
    const os = this.getOS();
    return showPageNotFound ? (
      <React.Fragment>
        <h1>{userSignUpSuccess.pageNotFound}</h1>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="congrats">
          {userSignUpSuccess.congratsCreatingAccount}
        </div>
        <div className="d-flex relative-position">
          <div className="info">{userSignUpSuccess.clickTheApp}</div>
          <div className="triangle-shape"></div>
        </div>
        <div className="download-text">{userSignUpSuccess.clickBelow}</div>
        <div className="userSignUpImagesContainer">
          {os !== "Android" && (
            <div
              className="osspecific-container"
              onClick={() => {
                fireMixpanelEventForIconClick("AppStore");
              }}
            >
              <a
                className="osspecific-container"
                href="https://apps.apple.com/us/app/marigold-health/id1462393166?ls=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Launcher}
                  alt="Launcher"
                  className="launcherIosImage"
                />

                <img
                  src={AppStore}
                  alt="AppStore"
                  className="userSignUpImages"
                />
              </a>
            </div>
          )}
          {os !== "iOS" && (
            <div
              className="osspecific-container"
              onClick={() => {
                fireMixpanelEventForIconClick("PlayStore");
              }}
            >
              <a
                className="osspecific-container"
                href=" https://play.google.com/store/apps/details?id=com.marigoldapp&hl=en"
                target="_blank"
              >
                <img
                  src={Launcher}
                  alt="Launcher"
                  className="launcherAndroidImage"
                />
                <img
                  src={PlayStore}
                  alt="PlayStore"
                  className="userSignUpImages"
                />
              </a>
            </div>
          )}
        </div>
        <div className="d-flex relative-position">
          <div className="info">{userSignUpSuccess.openTheApp}</div>
          <div className="triangle-shape"></div>
        </div>
        <div className="username-signup">
          {userSignUpSuccess.yourUsername}
          <span className="username-value">{username}</span>
        </div>
        <div className="password-signup">
          <div>
            {userSignUpSuccess.password}{" "}
            <span
              style={{
                paddingLeft: 10,
                fontSize: 14
              }}
            >
              {showPassword ? password : "*************"}
            </span>
          </div>
          <div
            className="input-group-addon eye-container"
            onClick={() => {
              this.toggleShowPassword();
            }}
          >
            <i
              className={"fa" + (showPassword ? " fa-eye" : " fa-eye-slash")}
            ></i>
          </div>
        </div>
        <div className="forgotten-password">
          {userSignUpSuccess.ifForgotten}
        </div>
        <div className="issues">
          <Icon type="question-circle" className="help-icon" />
          <div className="having-issues">
            {userSignUpSuccess.haivingIssues}{" "}
            <a
              href="mailto:support@marigoldhealth.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <span className="support-link">
                {userSignUpSuccess.supportLink}
              </span>
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default UserSignUpSuccess;
