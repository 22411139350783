import React from "react";
import { Bar } from "react-chartjs-2";

export const BarGraph = props => {
  return (
    <Bar
      data={props.data}
      height={300}
      options={{
        scales: { yAxes: [{ ticks: { stepSize: 1 } }] },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Messages sent in the last 7 days",
          fontSize: 20
        },
        legend: {
          display: false
        }
      }}
    />
  );
};
