import { types } from "mobx-state-tree";
import ApiService from "../utils/ApiService";
import AuthStore from "../stores/AuthStore";
import EventItem from "./EventItem";
import EventUser from "./EventUser";
import NotificationStore from "../stores/NotificationStore";
import { RSVP } from "../en.json";
import moment from "moment";
import _ from "lodash";
import { toJS } from "mobx";

const Events = types
  .model("Events", {
    newEvent: false,
    fetchingEvents: false,
    events: types.array(EventItem),
    eligibleId: types.maybeNull(types.number),
    zoomInfo: "",
    toTime: "",
    fromTime: "",
    outReach: "",
    eventDate: "",
    groupDesc: "",
    groupName: "",
    newGroupDesc: "",
    newOutReach: "",
    newZoomInfo: "",
    eventsFilter: 0,
    newEligibleId: types.maybeNull(types.number),
    newToTime: types.maybeNull(types.string),
    newFromTime: types.maybeNull(types.string),
    newEventDate: types.maybeNull(types.string),
    recurring: false,
    editReferral: false,
    newPatient: false,
    creatingEvent: false,
    skipEvents: 0,
    totalEvents: 0,
    eventUsers: types.array(EventUser),
    eventUsersPagination: types.frozen({
      pageSize: 10,
      total: 0,
      current: 1,
    }),
    newRecurring: types.maybeNull(types.boolean),
    newPatientName: "",
    fetchingEventUsers: false,
    showDetails: false,
    newPatientEmail: "",
    newPatientPhone: "",
    creatingReferral: false,
    selectedEventId: types.maybeNull(types.number),
    updatingEvent: false,
    showEventsModal: false,
    userNotes: types.map(types.frozen({ notes: types.string })),
    rsvp: types.map(
      types.frozen({
        names: types.string,
        total: types.number,
        confirmed: types.number,
      })
    ),
  })
  .actions((self) => ({
    setNewPatient(value) {
      self.newPatient = value;
    },
    setEventsModal(value) {
      self.showEventsModal = value;
    },
    resetAllEvents() {
      self.resetEvents();
      self.newEvent = false;
      self.editReferral = false;
      self.newPatient = false;
      self.showDetails = false;
      self.selectedEventId = null;
      self.eventsFilter = 0;
      self.resetUserNotes();
    },
    setShowDetails(value) {
      self.showDetails = value;
    },
    setEventsFilter(key) {
      self.eventsFilter = key;
      self.resetEvents();
      self.fetchAllEvents();
    },
    resetNewPatient() {
      self.newPatientName = "";
      self.newPatientEmail = "";
      self.newPatientPhone = "";
    },
    setSelectedEventId(eventId) {
      self.selectedEventId = eventId;
    },
    parseMetaData(meta) {
      return {
        pageSize: meta.limit,
        current: meta.skip / meta.limit + 1,
        total: meta.total,
      };
    },
    resetEventUsersPagination() {
      self.eventUsersPagination = {
        pageSize: 10,
        current: 1,
        total: 0,
      };
    },
    resetEventUsers() {
      self.eventUsers = [];
    },
    setEventUsersPagination(pagination) {
      self.eventUsersPagination = pagination;
    },
    setCreatingEvent(value) {
      self.creatingEvent = value;
    },
    async createEvent() {
      const dateArr = self.eventDate.split("-");
      const year = Number(dateArr[0]);
      const month = Number(dateArr[1]) - 1;
      const day = Number(dateArr[2]);
      const startTimeArr = self.fromTime.split(":");
      let startHour = startTimeArr[0];
      const startMinsArr = startTimeArr[1].split(" ");
      const startMin = startMinsArr[0];
      const startMeridian = startMinsArr[1];
      if (startMeridian === "PM") {
        startHour = Number(startHour) + 12;
      }
      const endTimeArr = self.toTime.split(":");
      let endHour = endTimeArr[0];
      const endMinsArr = endTimeArr[1].split(" ");
      const endMin = endMinsArr[0];
      const endMeridian = endMinsArr[1];
      if (endMeridian === "PM") {
        endHour = Number(endHour) + 12;
      }
      let startDate = new Date(year, month, day);
      let endDate = new Date(year, month, day);
      startDate.setHours(startHour);
      endDate.setHours(endHour);
      startDate.setMinutes(startMin);
      endDate.setMinutes(endMin);
      const adminId = AuthStore.userId;
      const recurringType = self.recurring ? 2 : 1;
      self.setCreatingEvent(true);
      const params = {
        name: self.groupName,
        description: self.groupDesc,
        zoomInfo: self.zoomInfo,
        facilitatorId: adminId,
        siteId: self.eligibleId,
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString(),
        outreach: self.outReach,
        recurringType,
      };
      const response = await ApiService.postRequest("events", params);
      if (response.success) {
        self.resetEvents();
        self.fetchAllEvents();
        self.setNewEvent(false);
        self.setCreatingEvent(false);
      } else {
        self.setCreatingEvent(false);
        return;
      }
    },
    async fetchAllEvents() {
      self.setFetchingEvents(true);
      let params = {
        $skip: self.skipEvents,
      };
      const lowerLimit = moment().toISOString();
      const upperLimit = moment().add(7, "day").toISOString();
      if (self.eventsFilter) {
        if (self.eventsFilter === 1) {
          params = {
            ...params,
            "startTime[$gte]": lowerLimit,
            "$sort[startTime]": 1,
          };
        } else if (self.eventsFilter === 2) {
          params = {
            ...params,
            "endTime[$lt]": lowerLimit,
            "$sort[endTime]": -1,
          };
        }
      } else {
        params = {
          ...params,
          "startTime[$gte]": lowerLimit,
          "endTime[$lt]": upperLimit,
          "$sort[startTime]": 1,
        };
      }
      const response = await ApiService.getRequest("events", params);
      if (response.success) {
        self.setEvents(_.cloneDeep(response.data));
        self.setEventsMeta(_.cloneDeep(response.meta));
        self.setFetchingEvents(false);
      } else {
        self.setFetchingEvents(false);
        return;
      }
    },
    resetUserNotes() {
      self.userNotes = {};
    },
    addUserNote(eventUserId, value) {
      self.userNotes.set(eventUserId, value);
    },
    async updateUserStatus(eventUserId, statusId) {
      const params = {
        status: Number(statusId),
      };
      const response = await ApiService.patchRequest(
        "event-participants",
        eventUserId,
        params
      );
      if (response.success) {
        self.updateUserStatusById(response.data);
      }
    },
    updateUserStatusById(eventUser) {
      const newEventUsers = _.cloneDeep(self.eventUsers);
      const filteredEventUserIndex = newEventUsers.findIndex(
        (user) => eventUser.id === user.id
      );
      const modifiedEventUser = {
        ...newEventUsers[filteredEventUserIndex],
        ...eventUser,
      };
      newEventUsers[filteredEventUserIndex] = modifiedEventUser;
      self.eventUsers = newEventUsers;
    },
    getPatientStatus(statusId) {
      switch (statusId) {
        case 1:
          return "Not a Fit";
        case 2:
          return "UnConfirmed";
        case 3:
          return "Confirmed";
        default:
          return "";
      }
    },
    setCreatingReferral(value) {
      self.creatingReferral = value;
    },
    async createNewReferral() {
      if (!self.selectedEventId) {
        return;
      }
      self.setCreatingReferral(true);
      const adminId = AuthStore.userId;
      const params = {
        fullName: self.newPatientName,
        phoneNumber: self.newPatientPhone,
        email: self.newPatientEmail.toLowerCase(),
        referrerId: adminId,
      };
      const response = await ApiService.postRequest("event-users", params);
      if (response.success) {
        const eventUserId = response.data.id;
        const participationParams = {
          eventUserId,
          status: 2,
          notes: "",
          eventId: self.selectedEventId,
        };
        const participationResponse = await ApiService.postRequest(
          "event-participants",
          participationParams
        );
        if (participationResponse.success) {
          self.resetEventUsersPagination();
          self.updateRsvp(
            participationResponse.data.eventId,
            response.data.fullName
          );
          self.fetchEventUsers();
          self.setNewPatient(false);
        }
        self.setCreatingReferral(false);
      } else {
        self.setCreatingReferral(false);
        return;
      }
    },
    setFetchingEventUsers(value) {
      self.fetchingEventUsers = value;
    },
    async fetchEventUsers() {
      if (!self.selectedEventId) {
        return;
      }
      self.setFetchingEventUsers(true);
      const params = {
        eventId: self.selectedEventId,
        $limit: 50,
        $skip:
          (self.eventUsersPagination.current - 1) *
          self.eventUsersPagination.pageSize,
        "$sort[createdAt]": -1,
      };
      const response = await ApiService.getRequest(
        "event-participants",
        params
      );
      if (response.success) {
        self.setEventUsers(_.cloneDeep(response.data));
        self.setEventUsersPagination(
          self.parseMetaData(_.cloneDeep(response.meta))
        );
        self.setFetchingEventUsers(false);
      }
      self.setFetchingEventUsers(false);
    },
    setEventUsers(data) {
      self.eventUsers = data.map((item) => {
        item.phoneNumber = item.phoneNumber
          ? "(" +
            item.phoneNumber.slice(0, 3) +
            ")" +
            " " +
            item.phoneNumber.slice(3, 6) +
            "-" +
            item.phoneNumber.slice(6)
          : null;
        return EventUser.create(item);
      });
    },
    setEventsMeta(meta) {
      self.skipEvents = self.skipEvents + meta.limit;
      self.totalEvents = meta.total;
    },
    resetEvents() {
      self.events = [];
      self.skipEvents = 0;
      self.totalEvents = 0;
    },
    async fetchEventMembers(eventId) {
      const params = {
        eventId,
      };
      const response = await ApiService.getRequest(
        "event-participants",
        params
      );
      if (response.success) {
        self.setEventRSVP(eventId, response.data);
      }
    },
    setEventRSVP(eventId, userData) {
      let totalCount = 0;
      let confirmedCount = 0;
      let names = "";
      userData.forEach((user) => {
        names = names + user.eventUserInfo.fullName + ", ";
        totalCount = totalCount + 1;
        if (user.status === 3) {
          confirmedCount = confirmedCount + 1;
        }
      });
      self.rsvp.set(eventId, {
        names,
        total: totalCount,
        confirmed: confirmedCount,
      });
    },
    updateRsvp(eventId, fullName) {
      if (self.rsvp.has(eventId)) {
        const oldTotal = self.rsvp.get(eventId).total;
        const oldNames = self.rsvp.get(eventId).names;

        const getOldRsvp = self.rsvp.get(eventId);
        self.rsvp.set(eventId, {
          ...getOldRsvp,
          total: oldTotal + 1,
          names: oldNames + fullName + ", ",
        });
      }
    },
    setEvents(data) {
      const events = data.map((item) => {
        self.fetchEventMembers(item.id);
        item.eventDate = moment(
          item.startTime,
          "YYYY-MM-DDTHH:mm:ss.sssZ"
        ).format("dddd, MMM DD, YYYY ");
        item.startHour = new Date(item.startTime).getHours();
        item.startMins = new Date(item.startTime).getMinutes();
        item.endHour = new Date(item.endTime).getHours();
        item.endMins = new Date(item.endTime).getMinutes();
        return EventItem.create(item);
      });
      self.events = [...self.events, ...events];
    },
    setFetchingEvents(value) {
      self.fetchingEvents = value;
    },
    setNewEvent(value) {
      self.newEvent = value;
    },
    setPreviousValues(event) {
      self.newGroupDesc = event.description;
      self.newOutReach = event.outreach;
      self.newZoomInfo = event.zoomInfo;
      self.newEligibleId = event.siteId;
      self.newRecurring = event.recurringType === 2 ? true : false;
      const startTime = new Date(event.startTime);
      const endTime = new Date(event.endTime);
      const startHours = startTime.getHours();
      const endTimeHours = endTime.getHours();
      const startMinutes = startTime.getMinutes();
      const endTimeMinutes = endTime.getMinutes();

      self.newFromTime = `${startHours}:${startMinutes}`;
      self.newToTime = `${endTimeHours}:${endTimeMinutes}`;
      self.newEventDate = moment(
        event.startTime,
        "YYYY-MM-DDTHH:mm:ss.sssZ"
      ).format("YYYY-MM-DD");
      self.eventUsers.forEach((user) => {
        self.addUserNote(user.id, user.notes);
      });
    },
    async deleteEvent() {
      const response = await ApiService.deleteRequest(
        "events",
        self.selectedEventId
      );
      if (response.success) {
        self.setEditReferral(null, false);
        self.deleteEventById(self.selectedEventId);
        NotificationStore.setNotification("success", RSVP.deleteEventSuccess);
      } else {
        NotificationStore.setNotification("error", RSVP.deleteEventError);
      }
    },
    async updateEvent() {
      if (self.selectedEventId) {
        self.setUpdatingEvent(true);
        const dateArr = self.newEventDate.split("-");
        const year = Number(dateArr[0]);
        const month = Number(dateArr[1]) - 1;
        const day = Number(dateArr[2]);
        const startTimeArr = self.newFromTime.split(":");
        let startHour = startTimeArr[0];
        const startMinsArr = startTimeArr[1].split(" ");
        const startMin = startMinsArr[0];
        const startMeridian = startMinsArr[1];
        if (startMeridian === "PM") {
          startHour = Number(startHour) + 12;
        }
        const endTimeArr = self.newToTime.split(":");
        let endHour = endTimeArr[0];
        const endMinsArr = endTimeArr[1].split(" ");
        const endMin = endMinsArr[0];
        const endMeridian = endMinsArr[1];
        if (endMeridian === "PM") {
          endHour = Number(endHour) + 12;
        }
        let startDate = new Date(year, month, day);
        let endDate = new Date(year, month, day);
        startDate.setHours(startHour);
        endDate.setHours(endHour);
        startDate.setMinutes(startMin);
        endDate.setMinutes(endMin);
        const recurringType = self.newRecurring ? 2 : 1;
        let params = {
          description: self.newGroupDesc,
          outreach: self.newOutReach,
          zoomInfo: self.newZoomInfo,
          siteId: self.newEligibleId,
          startTime: startDate.toISOString(),
          endTime: endDate.toISOString(),
          recurringType,
        };

        const response = await ApiService.patchRequest(
          "events",
          self.selectedEventId,
          params
        );
        if (response.success) {
          const userNotes = toJS(self.userNotes);
          if (Object.keys(userNotes).length) {
            let promises = [];
            Object.keys(userNotes).forEach((key) => {
              const value = userNotes[key];
              promises.push(
                ApiService.patchRequest("event-participants", Number(key), {
                  notes: value,
                })
              );
            });

            Promise.all(promises).then((res) => {
              res.forEach((item) => {
                self.updateUserStatusById(item.data);
              });
            });
          }
          self.updateEventById(response.data);
          self.fetchEventMembers(self.selectedEventId);
          self.setEditReferral(null, false);
          self.setUpdatingEvent(false);
          NotificationStore.setNotification("success", RSVP.updateEventSuccess);
        } else {
          self.setUpdatingEvent(false);
          NotificationStore.setNotification("success", RSVP.updateEventError);
          return;
        }
      }
    },
    setUpdatingEvent(value) {
      self.updatingEvent = value;
    },
    updateEventById(event) {
      const newEvents = _.cloneDeep(self.events);
      const filteredEventIndex = newEvents.findIndex((e) => event.id === e.id);
      const modifiedEvent = { ...newEvents[filteredEventIndex], ...event };
      modifiedEvent.startHour = new Date(event.startTime).getHours();
      modifiedEvent.eventDate = moment(
        event.startTime,
        "YYYY-MM-DDTHH:mm:ss.sssZ"
      ).format("dddd, MMM DD, YYYY ");
      modifiedEvent.startMins = new Date(event.startTime).getMinutes();
      modifiedEvent.endHour = new Date(event.endTime).getHours();
      modifiedEvent.endMins = new Date(event.endTime).getMinutes();
      newEvents[filteredEventIndex] = modifiedEvent;
      self.events = newEvents;
    },
    deleteEventById(id) {
      self.events = self.events.filter((event) => event.id !== id);
    },
    setEditReferral(eventId, value) {
      if (eventId) {
        const event = self.events.filter((event) => event.id === eventId);
        if (event.length) {
          self.setPreviousValues(event[0]);
        }
      }
      self.editReferral = value;
    },
    setNewEventDetails(type, value) {
      self[type] = value;
    },
  }))
  .views((self) => ({
    get noDate() {
      return !self.eventDate;
    },
    get canCreateEvent() {
      return !(
        self.groupName &&
        self.eventDate &&
        self.toTime &&
        self.fromTime &&
        self.groupDesc &&
        self.eligibleId
      );
    },
    get hasMoreEvents() {
      return self.skipEvents < self.totalEvents;
    },
    get isNotNumber() {
      const trimmedNumber = self.newPatientPhone
        .trim()
        .replace(/[^\x00-\x7F]/g, "");
      let regEx = /^\d+$/;
      return !regEx.test(trimmedNumber) || trimmedNumber.length !== 10;
    },
    get canReferAPatient() {
      const trimmedNumber = self.newPatientPhone
        .trim()
        .replace(/[^\x00-\x7F]/g, "");
      return !(
        self.newPatientEmail &&
        self.newPatientName &&
        self.newPatientPhone &&
        trimmedNumber.length === 10
      );
    },
  }));

export default Events;
