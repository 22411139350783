import "../../styles/global.scss";

import { Tabs, Timeline, Spin } from "antd";
import { inject, observer } from "mobx-react";

import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { withRouter } from "react-router-dom";
import EventItem from "./EventItem";
import { RSVP } from "../../en.json";
import { EventsModal } from "../UI/EventsModal";
import NewReferralModal from "../UI/NewReferralModal";

const TabPane = Tabs.TabPane;

@withRouter
@inject("store")
@observer
class EventList extends React.Component {
  async componentDidMount() {
    const {
      store: {
        EventStore: { fetchAllEvents, setEventsModal },
      },
    } = this.props;
    setEventsModal(true);
    fetchAllEvents();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const {
      store: {
        ReferralStore: { fetchAllIntake, resetIntakePagination },
      },
    } = this.props;

    resetIntakePagination(pagination);
    fetchAllIntake();
  };

  loadMoreEvents = () => {
    const {
      store: {
        EventStore: { fetchingEvents, fetchAllEvents },
      },
    } = this.props;
    if (fetchingEvents) {
      return;
    }
    fetchAllEvents();
  };

  newEvent = (value) => {
    const {
      store: {
        EventStore: { setNewEvent, setShowDetails, setEditReferral },
      },
    } = this.props;
    setShowDetails(false);
    setEditReferral(null, false);
    setNewEvent(value);
  };

  showEvents = () => {
    const {
      store: {
        EventStore: { events, fetchingEvents, hasMoreEvents },
      },
    } = this.props;

    return (
      <div className="event-container">
        <InfiniteScroll
          loadMore={this.loadMoreEvents}
          hasMore={hasMoreEvents}
          useWindow={false}
        >
          {events.map((event) => {
            return (
              <Timeline key={event.id}>
                <Timeline.Item>
                  <div>
                    <div className="group-name">{event.eventDate}</div>
                    <EventItem event={event} />
                  </div>
                </Timeline.Item>
              </Timeline>
            );
          })}
          {fetchingEvents ? (
            <div className="form-loading">
              <Spin />
            </div>
          ) : null}
        </InfiniteScroll>
      </div>
    );
  };

  setFilter = (key) => {
    const {
      store: {
        EventStore: {
          setEventsFilter,
          setEditReferral,
          setSelectedEventId,
          setShowDetails,
          fetchingEvents,
        },
      },
    } = this.props;
    if (fetchingEvents) {
      return;
    }
    setSelectedEventId(null);
    setEditReferral(null, false);
    setShowDetails(false);
    setEventsFilter(Number(key));
  };

  render() {
    const {
      store: {
        EventStore: {
          fetchingEvents,
          resetNewPatient,
          setNewPatient,
          createNewReferral,
          newPatient,
          events,
          setEventsModal,
          showEventsModal,
        },
      },
    } = this.props;
    return (
      <div>
        {showEventsModal ? (
          <EventsModal onConfirm={() => setEventsModal(false)} />
        ) : null}
        {newPatient ? (
          <NewReferralModal
            onCancel={() => {
              resetNewPatient();
              setNewPatient(false);
            }}
            onConfirm={() => {
              createNewReferral();
            }}
          />
        ) : null}
        <div className="search-member-container">
          <Tabs tabBarStyle={{ border: 0 }} onChange={this.setFilter}>
            <TabPane
              className="my-tabs-ink-bar"
              tab="This Week"
              key="0"
            ></TabPane>
            <TabPane
              className="my-tabs-ink-bar"
              tab="Upcoming"
              key="1"
            ></TabPane>
            <TabPane className="my-tabs-ink-bar" tab="Past" key="2"></TabPane>
          </Tabs>
          <div>
            <button
              className="referral-form-button"
              onClick={() => this.newEvent(true)}
            >
              Create New Event
            </button>
          </div>
        </div>
        {!events.length ? (
          fetchingEvents ? (
            <div className="empty-CBRS">
              <div className="form-loading">
                <Spin />
              </div>
            </div>
          ) : (
            <div className="empty-CBRS">
              <div>{RSVP.noEvents1}</div>
              <div>{RSVP.noEvents2}</div>
            </div>
          )
        ) : (
          this.showEvents()
        )}
      </div>
    );
  }
}
export default EventList;
