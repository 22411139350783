import { types } from "mobx-state-tree";

const DeletedMessage = types.model("DeletedMessage", {
  id: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  sender: types.string,
  userId: types.maybeNull(types.number),
  userType: types.maybeNull(types.string),
  messageId: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  deletedBy: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  channelId: types.maybeNull(types.number),
  channelType: types.maybeNull(types.string),
  messageReactionId: types.maybeNull(types.number),
  imgUrl: types.maybeNull(types.string)
});

export default DeletedMessage;
