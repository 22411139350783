import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    paddingLeft: 10,
    paddingRight: 5,
    width: "100vw",
  },
  previewContainer: {
    height: "100vh",
    width: "100%",
  },
  rsnQuestions: {
    display: "flex",
    paddingRight: 10,
  },

  answer: {
    marginLeft: 20,
    marginRight: 10,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    paddingBottom: 3,
    fontSize: 14,
    marginBottom: 10,
  },
  container: {
    border: 1,
    borderColor: "#000",
    width: "100vw",
    padding: 10,
    margin: 10,
  },
  header: {
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 10,
  },
  questionTitle: {
    color: "#515659",
    textAlign: "justify",
    fontSize: 14,
  },
  siteName: {},
  program: {},
  questionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  questionNumber: {
    marginRight: 5,
    fontSize: 13,
    color: "#515659",
  },
});

// Create Document Component

class MyDocument extends React.Component {
  getAnswerToFormQuestion = (question) => {
    const {
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer,
    } = this.props;

    if (question.type === "multiple_choices") {
      const questionId = question.id;
      const options = question.options;
      const answerObjArr = multipleChoices[questionId];
      if (answerObjArr && answerObjArr.length) {
        const optArr = answerObjArr;
        const ans = [];
        optArr.forEach((opt) => {
          const isOp = options.filter((options) => options.id === Number(opt));
          if (isOp.length) {
            ans.push(isOp[0].option);
          }
        });
        return ans.join(",");
      } else {
        return "not found";
      }
    } else if (question.type === "multiple_choices_radio") {
      const questionId = question.id;
      const options = question.options;
      const answer = multipleChoicesRadio[questionId];
      if (answer) {
        const opt = options.filter((option) => option.id === answer);
        if (opt.length) {
          return opt[0].option;
        } else {
          return "not found";
        }
      } else {
        return "not found";
      }
    } else if (question.type === "descriptive") {
      const questionId = question.id;
      if (descriptiveAnswer[questionId]) {
        return descriptiveAnswer[questionId];
      } else return "not found";
    }
  };

  render() {
    const { referralFormQuestions, site } = this.props;
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.container}>
            <View style={styles.header}>
              <View style={styles.siteName}>
                <Text>{site}</Text>
              </View>
              <View style={styles.program}>
                <Text>COMMUNITY BASED RECOVERY SERVICES</Text>
              </View>
            </View>
            <View style={styles.rsnQuestions}>
              {referralFormQuestions.map((question, index) => {
                return (
                  <View key={question.id}>
                    <View style={styles.questionContainer}>
                      <Text style={styles.questionNumber}>{index + 1}.</Text>
                      <Text style={styles.questionTitle}>
                        {question.questionTitle}
                      </Text>
                    </View>
                    <View style={styles.answer}>
                      <Text>{this.getAnswerToFormQuestion(question)}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        </Page>
      </Document>
    );
  }
}

export default MyDocument;
