import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from "mobx-react";

import { ViewPostMediaModal } from './ViewPostMediaModal';
import SharePost from './SharePost';
import SingleFeed from './SingleFeed';
import { Col, Row, Spin } from 'antd';
import FeedTabHeader from './FeedTabHeader';
import { fetchNearbyFeeds, subscribeFeedNotifications, subscribeReactionNotification, unsubscribeFeed } from '../../utils/forumFeedService';
import { TIME_FILTERS } from '../../constants/GlobalConstant';
import { REACT_APP_GET_FEED_GROUP, REACT_APP_REACTION_FEED_GROUP_ID } from '../../configs/EnvConfig';
import { IMG_NO_POSTS } from "../../utils/ImageUtils";
import mixpanel from "mixpanel-browser";
import SecureLocalStorage from "../../utils/SecureLS";

const Feeds = inject("store")(observer((props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [feedList, setFeedList] = useState([]);
    const {
        ForumFeedStore: {
            fetchFeeds,
            feeds,
            loading,
            hasMoreFeeds,
            selectedCategoryFilter,
            reset,
            notificationFeedId,
        },
    } = props.store;

    const containerRef = useRef(null);
    const onComponentMount = async () => {
        const { 
            notificationFeedId,
            setNotificationFeedId,
            setLoading, 
            notificationCommentId,
            setIsFeedCommentVisible,
            setCurrentFeedId,
            selectedTimeFilter,
        } = props.store.ForumFeedStore;
        setLoading(true);

        SecureLocalStorage.set("firstForumLoad", true);
        mixpanel.time_event("Forum Loading Time");

        mixpanel.time_event(`Forum Total Time For ${selectedTimeFilter}`);

        await subscribeFeedNotifications();
        await subscribeReactionNotification();
        
        if(notificationFeedId) {
            const feeds = await fetchNearbyFeeds(notificationFeedId);
            const notificationFeedIndex = feeds.findIndex(feed => feed.id === notificationFeedId);
            if (notificationFeedIndex != -1) {
                const reactedFeed = containerRef.current.children[notificationFeedIndex];
                reactedFeed.scrollIntoView({ behavior: 'smooth' });
                if (notificationCommentId) {
                    setIsFeedCommentVisible(true);
                    setCurrentFeedId(notificationFeedId);
                }
            }
        } else {
            await fetchFeeds();
        }
        setLoading(false);

        if (SecureLocalStorage.get("firstForumLoad")) {
            mixpanel.track("Forum Loading Time", { from: "WEB" });
            SecureLocalStorage.remove("firstForumLoad");
        }
    }

    useEffect(() => {
        (async() => {
            await onComponentMount();
        })();
        
        return () => {
            unsubscribeFeed(REACT_APP_GET_FEED_GROUP, selectedCategoryFilter);
            unsubscribeFeed(REACT_APP_GET_FEED_GROUP, REACT_APP_REACTION_FEED_GROUP_ID);
            reset();
        }
    },[]);

    useEffect(() => {
        setFeedList(feeds);
    }, [feeds])

    const onShareClick = () => {
        setIsModalOpen(true);
    }

    const onClickLoadMore = () => {
        const { 
            fetchFeeds,
            selectedTimeFilter,
            flaggedFeedPage,
            setFlaggedFeedPage,
            fetchFlaggedFeeds,
        } = props.store.ForumFeedStore;
        if (selectedTimeFilter === TIME_FILTERS.FLAGGED) {
            setFlaggedFeedPage(flaggedFeedPage + 1);
            fetchFlaggedFeeds(true);
        } else {
            fetchFeeds();
        }
    }

    return (
        <>
            {/** Feed header starts */}
            <div>
                <FeedTabHeader />
            </div>
            {/** Feed header ends */}
            {/** Feed list starts */}
            <div style={{ width: '100%' }} ref={containerRef}>
                {
                    (loading || feedList.length) ?
                        feedList.map((feed, index) => {
                            return <SingleFeed
                                feed={feed}
                                feedIndex={index}
                                onShareClick={onShareClick}
                                isDeleteOnly={false}
                                isReadOnly={false}
                            />
                        }) :
                      <div style={{
                          display: "flex",
                          justifyContent: "center",
                      }}>
                          <img
                            style={{
                                width: "78%",
                                marginTop: "22%",
                            }}
                            src={IMG_NO_POSTS}
                            alt="No posts"
                          />
                      </div>
                }
            </div>
            {/** Feed list ends */}
            
            {/** Load more button & spinner starts */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                { 
                    loading 
                        ?   <Spin style={{ marginTop: '20px'}} /> 
                        :   (feedList.length > 0 && hasMoreFeeds) && 
                            <Row>
                                <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p className='load-more' onClick={() => onClickLoadMore()}>Load more</p>
                                </Col>
                            </Row>
                }
            </div>
            {/** Load more button & spinner ends */}

            <ViewPostMediaModal />
            <SharePost
                isModalVisible={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
        </>
    );
}));

export default Feeds;
