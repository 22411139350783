import React from "react";
import { Dropdown, Icon, Menu, Spin } from "antd";
import _ from "lodash";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { inject, observer } from "mobx-react";

const periodData = [
  {
    title: "Today",
    day: 0
  },
  {
    title: "Yesterday",
    day: 1
  },
  {
    title: "Past week",
    day: 7
  },
  {
    title: "Past 30 days",
    day: 30
  }
];

@inject("store")
@observer
class MixpanelAnalysis extends React.Component {
  constructor() {
    super();
    this.state = {
      eventsOverlayVisible: false,
      propertyOverlayVisible: false,
      periodOverlayVisible: false,
      eventsFetching: true,
      propertyFetching: false,
      selectedEvent: null,
      selectedProperty: null,
      selectedPeriod: null,
      loading: false
    };
  }

  setSelectedEvent = ({ key }) => {
    const {
      store: {
        MixpanelStore: { saveProperties, saveQueryResult, setGraphLabel }
      }
    } = this.props;
    this.setState(
      {
        selectedEvent: key,
        eventsOverlayVisible: false,
        selectedProperty: null,
        loading: true,
        propertyFetching: true
      },
      () => {
        const topPropertiesParams = {
          limit: 100
        };
        let params = null;
        if (this.state.selectedPeriod) {
          const periodSelect = periodData.filter(
            item => item.title === this.state.selectedPeriod
          );
          params = {
            from: moment().subtract(periodSelect[0].day, "days"),
            to: moment(),
            unit: "day"
          };
        }
        window.MP.api
          .topProperties(this.state.selectedEvent, topPropertiesParams)
          .done(results => {
            let propertyData = Object.keys(results.json);
            saveProperties(propertyData);
            this.setState({
              propertyFetching: false
            });
          });
        window.MP.api
          .segment(
            this.state.selectedEvent,
            this.state.selectedProperty,
            params
          )
          .done(queryResults => {
            let extractedData = [];
            Object.keys(queryResults.json).map(item => {
              const sum = _.sum(_.values(queryResults.json[item]));
              extractedData.push({
                label: this.state.selectedEvent,
                y: sum,
                color: "#8adfb6"
              });
              return item;
            });
            saveQueryResult(extractedData);
            setGraphLabel(key);
            this.setState({
              loading: false
            });
          });
      }
    );
  };

  setSelectedPeriod = ({ key }) => {
    const {
      store: {
        MixpanelStore: { saveQueryResult }
      }
    } = this.props;
    this.setState(
      {
        periodOverlayVisible: false,
        selectedPeriod: key,
        loading: true
      },
      () => {
        const periodSelect = periodData.filter(
          item => item.title === this.state.selectedPeriod
        );
        if (periodSelect.length) {
          const params = {
            from: moment().subtract(periodSelect[0].day, "days"),
            to: moment(),
            unit: "day"
          };
          window.MP.api
            .segment(
              this.state.selectedEvent,
              this.state.selectedProperty,
              params
            )
            .done(queryResults => {
              let extractedData = [];
              Object.keys(queryResults.json).map(item => {
                const sum = _.sum(_.values(queryResults.json[item]));
                extractedData.push({
                  label: this.state.selectedProperty
                    ? item
                    : this.state.selectedEvent,
                  y: sum,
                  color: "#8adfb6"
                });
                return item;
              });
              saveQueryResult(extractedData);
              this.setState({
                loading: false
              });
            });
        } else {
          this.setState({
            loading: false
          });
        }
      }
    );
  };

  setSelectedProperty = ({ key }) => {
    const {
      store: {
        MixpanelStore: { saveQueryResult, setGraphLabel }
      }
    } = this.props;
    this.setState(
      {
        propertyOverlayVisible: false,
        selectedProperty: key,
        loading: true
      },
      () => {
        let params = null;
        if (this.state.selectedPeriod) {
          const periodSelect = periodData.filter(
            item => item.title === this.state.selectedPeriod
          );
          params = {
            from: moment().subtract(periodSelect[0].day, "days"),
            to: moment(),
            unit: "day"
          };
        }
        window.MP.api
          .segment(
            this.state.selectedEvent,
            this.state.selectedProperty,
            params
          )
          .done(queryResults => {
            let extractedData = [];
            Object.keys(queryResults.json).map(item => {
              const sum = _.sum(_.values(queryResults.json[item]));
              extractedData.push({
                label: item,
                y: sum,
                color: "#8adfb6"
              });
              return item;
            });
            saveQueryResult(extractedData);
            setGraphLabel(key);
            this.setState({
              loading: false
            });
          });
      }
    );
  };

  renderEventsSelectorMenu = () => {
    const {
      store: {
        MixpanelStore: { eventsData }
      }
    } = this.props;
    return (
      <div className="selection-container">
        <Menu onClick={this.setSelectedEvent} className="menu">
          {eventsData.map(item => {
            return (
              <Menu.Item key={item} className="menu-item">
                {item.length > 23 ? `${item.slice(0, 20)}...` : item}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  };

  renderPeriodOverlayMenu = () => {
    return (
      <div className="selection-container">
        <Menu onClick={this.setSelectedPeriod} className="menu">
          {periodData.map(item => {
            return (
              <Menu.Item key={item.title} className="menu-item">
                {item.title.length > 23
                  ? `${item.title.slice(0, 20)}...`
                  : item.title}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  };

  renderPropertySelectorMenu = () => {
    const {
      store: {
        MixpanelStore: { propertyData }
      }
    } = this.props;
    return (
      <div className="selection-container">
        <Menu onClick={this.setSelectedProperty} className="menu">
          {propertyData.map(item => {
            return (
              <Menu.Item key={item} className="menu-item">
                {item.length > 23 ? `${item.slice(0, 20)}...` : item}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  };

  componentDidMount() {
    const {
      store: {
        MixpanelStore: { saveEventsData, clearData }
      }
    } = this.props;
    clearData();
    const topEventsParams = {
      type: "general"
    };
    window.MP.api.topEvents(topEventsParams).done(results => {
      this.setState({
        eventsFetching: false
      });
      saveEventsData(results.json);
    });
  }
  getChartData = (labels, graphLabel, data, backgroundColor) => {
    const chartData = {
      labels,
      datasets: [
        {
          label: graphLabel.replace("$", ""),
          data,
          backgroundColor
        }
      ]
    };
    return chartData;
  };

  render() {
    const {
      eventsOverlayVisible,
      propertyOverlayVisible,
      periodOverlayVisible,
      eventsFetching,
      propertyFetching,
      selectedEvent,
      selectedProperty,
      selectedPeriod,
      loading
    } = this.state;
    const {
      store: {
        MixpanelStore: { queryResult, graphLabel }
      }
    } = this.props;
    let labels = [],
      data = [],
      backgroundColor = [];

    queryResult.map(item => {
      labels.push(item.label.replace("$", ""));
      data.push(item.y);
      backgroundColor.push(item.color);
      return item;
    });
    const chartData = this.getChartData(
      labels,
      graphLabel,
      data,
      backgroundColor
    );
    return (
      <div>
        <div
          className="d-flex mt-2 ml-3 mr-3 align-items-center"
          style={{ justifyContent: "space-between" }}
        >
          <div className="d-flex align-items-center">
            <Dropdown
              overlay={this.renderEventsSelectorMenu()}
              trigger={["click"]}
              disabled={eventsFetching || propertyFetching || loading}
              placement="bottomCenter"
              onVisibleChange={visible => {
                this.setState({
                  eventsOverlayVisible: visible
                });
              }}
            >
              <div
                className="site-dropdown nav-item"
                style={
                  eventsOverlayVisible
                    ? {
                        border: "2px solid #c6e0ff",
                        alignItems: "center"
                      }
                    : { alignItems: "center" }
                }
              >
                <div style={{ fontSize: 16, fontWeight: 600 }}>
                  {eventsFetching
                    ? "Loading..."
                    : selectedEvent
                    ? selectedEvent.length > 23
                      ? `${selectedEvent.replace("$", "").slice(0, 20)}...`
                      : selectedEvent.replace("$", "")
                    : "-- Select an Event --"}
                </div>
                {!eventsFetching && (
                  <Icon type="down" style={{ fontSize: 14, paddingLeft: 10 }} />
                )}
              </div>
            </Dropdown>
            {selectedEvent && <div className="mr-1 ml-1 filter-by">by</div>}
            {selectedEvent && (
              <Dropdown
                overlay={this.renderPropertySelectorMenu()}
                trigger={["click"]}
                disabled={eventsFetching || propertyFetching || loading}
                placement="bottomCenter"
                onVisibleChange={visible => {
                  this.setState({
                    propertyOverlayVisible: visible
                  });
                }}
              >
                <div
                  className="site-dropdown nav-item"
                  style={
                    propertyOverlayVisible
                      ? {
                          border: "2px solid #c6e0ff",
                          alignItems: "center"
                        }
                      : { alignItems: "center" }
                  }
                >
                  <div style={{ fontSize: 16, fontWeight: 600 }}>
                    {propertyFetching
                      ? "Loading..."
                      : selectedProperty
                      ? selectedProperty.length > 23
                        ? `${selectedProperty.replace("$", "").slice(0, 20)}...`
                        : selectedProperty.replace("$", "")
                      : "-- Select a Property --"}
                  </div>
                  {!propertyFetching && (
                    <Icon
                      type="down"
                      style={{ fontSize: 14, paddingLeft: 10 }}
                    />
                  )}
                </div>
              </Dropdown>
            )}
          </div>
          {selectedEvent && (
            <div>
              <Dropdown
                overlay={this.renderPeriodOverlayMenu()}
                trigger={["click"]}
                disabled={eventsFetching || propertyFetching || loading}
                placement="bottomCenter"
                onVisibleChange={visible => {
                  this.setState({
                    periodOverlayVisible: visible
                  });
                }}
              >
                <div
                  className="site-dropdown nav-item"
                  style={
                    periodOverlayVisible
                      ? {
                          border: "2px solid #c6e0ff",
                          alignItems: "center"
                        }
                      : { alignItems: "center" }
                  }
                >
                  <div style={{ fontSize: 16, fontWeight: 600 }}>
                    {selectedPeriod ? selectedPeriod : "-- Select a Period --"}
                  </div>
                  <Icon type="down" style={{ fontSize: 14, paddingLeft: 10 }} />
                </div>
              </Dropdown>
            </div>
          )}
        </div>
        {selectedEvent && (
          <div className="d-flex mt-2 ml-3 mr-3 graph-container">
            <Bar
              data={chartData}
              height={500}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  position: "bottom",
                  onClick: () => {}
                },
                layout: {
                  padding: {
                    top: 20,
                    left: 20,
                    right: 20,
                    bottom: 20
                  }
                }
              }}
            />
            {loading && (
              <div style={{ position: "absolute" }}>
                <Spin />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default MixpanelAnalysis;
