import React from "react";
import UserDetails from "./UserDetails";
import AssignTo from "./AssignTo";
import close from "../../static/images/close.png";
import { Spin, Icon, Button } from "antd";
import { inject, observer } from "mobx-react";
import { alert } from "../../en.json";
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

@inject("store")
@observer
class AssignToContainer extends React.Component {
  assignAdmins = () => {
    const {
      store: {
        MemberListStore: {
          sendMailToAdmins,
          selectedUserId,
          assignEmailLoading,
          assignNewUsers,
        },
      },
    } = this.props;
    if (assignEmailLoading) {
      return;
    } else {
      if (selectedUserId) {
        assignNewUsers();
      } else {
        sendMailToAdmins();
      }
    }
  };

  removeAssign = (id) => {
    const {
      store: { MemberListStore },
    } = this.props;
    MemberListStore.removeAssignedAdmin(id);
  };

  render() {
    const {
      store: {
        ProfileStore: { selectedAction, changeSelectedAction },
        MemberListStore: { assignedAdmins, assignEmailLoading },
      },
      navHeight,
    } = this.props;

    return (
      <div className="d-flex">
        <div style={{ flex: "1", position: "relative" }}>
          <div className="d-flex flex-column h-100">
            <div id="alert-display-container" ref={this.messageList} style={{
              height: `calc(100vh - ${navHeight + 113}px)`
            }}>
              {selectedAction === "Assign to" ? (
                <div className="right-section">
                  <div className="header-section assign-padding">
                    <div className="assign-header">
                      <div className="left-assign-container">
                        <h1 className="assign-text">{alert.assignFlagTo}</h1>
                        <div className="assigned-admins">
                          {assignedAdmins.map((admins) => (
                            <div className="selected-admins" key={admins.id}>
                              {admins.username}{" "}
                              <img
                                className="close-icon invert-color"
                                height="10"
                                width="10"
                                onClick={() => this.removeAssign(admins.id)}
                                src={close}
                                alt="Close"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      {assignedAdmins.length ? (
                        <div className="assign-img">
                          <Button
                            type="primary"
                            className="download-button"
                            disabled={assignEmailLoading}
                            size="large"
                            onClick={() => this.assignAdmins()}
                          >
                            {!assignEmailLoading ? (
                              `${alert.assign}`
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Button>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <img
                        className="close-icon "
                        height="20"
                        width="20"
                        onClick={() => changeSelectedAction("")}
                        src={close}
                        alt="Close"
                      />
                    </div>
                  </div>
                  <div style={{ flex: "1", position: "relative" }}>
                    <div>
                      <AssignTo />
                    </div>
                  </div>
                </div>
              ) : (
                <UserDetails allMembers={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AssignToContainer;
