import { types } from "mobx-state-tree";
import ApiService from "../utils/ApiService";
import { doLoadPosts, getActivitiesByIds, getReactions } from "../utils/forumFeedService";
import { GET_FEED_LIMIT, GET_COMMENT_LIMIT, REACTION_TYPES, TIME_FILTERS, GET_FLAGGED_FEED_LIMIT } from "../constants/GlobalConstant";
import { REACT_APP_GET_FEED_ID } from "../configs/EnvConfig";
import AuthStore from "../stores/AuthStore";

const ForumFeed = types
  .model("ForumFeed", {
    // feed listing
    visible: false,
    feeds: types.optional(types.frozen([])),
    addPostLoading: false,
    loading: false,
    hasMoreFeeds: false,
    // feed comments
    isFeedCommentVisible: false,
    fetchCommentLoading: false,
    selectedFeedTimetoken: "",
    comments: types.optional(types.frozen([])),
    hasMoreComments: false,
    currentFeedId: types.maybeNull(types.string),
    // feed tags & categories
    tags: types.optional(types.frozen([])),
    categories: types.optional(types.frozen([])),
    filteredTags: types.optional(types.frozen([])),
    // media modal properties
    title: types.maybeNull(types.string),
    imgUrl: types.maybeNull(types.string),
    modalImages: types.maybeNull(types.frozen([])),
    firstImageIndex: types.maybeNull(types.integer),
    videoUrl: types.maybeNull(types.string),
    isModalVisible: false,
    // feed filters
    selectedCategoryFilter: REACT_APP_GET_FEED_ID,
    allCategoryFilters: types.maybeNull(types.frozen([])),
    selectedTimeFilter: TIME_FILTERS.HOT_POSTS,
    isCommentFlagged: types.maybeNull(types.frozen({})),
    flaggedCommentCount: types.maybeNull(types.frozen({})),
    flaggedFeedPage: 1,
    // create feed
    uploadImages: types.optional(types.frozen([])),
    // notification related 
    notificationFeedId: types.maybeNull(types.string),
    notificationCommentId: types.maybeNull(types.string),
    mentionedUserName: "",
    loadingMentionOptions: false,
  })
  .actions((self) => ({
    reset() {
      self.visible = false;
      self.feeds = [];
      self.hasMore = false;
      self.comments = [];
      self.addPostLoading = false;
      self.fetchCommentLoading = false;
      self.isFeedCommentVisible = false;
      self.notificationFeedId = null;
      self.notificationCommentId = null;
      self.loading = false;
      self.loadingMentionOptions = false;
    },
    resetPostMeta() {
      self.isFeedCommentVisible = false;
    },
    setVisible(value) {
      self.visible = value;
    },
    setAddPostLoading(value) {
      self.addPostLoading = value;
    },
    setIsFeedCommentVisible(value) {
      self.isFeedCommentVisible = value;
    },
    setSelectedFeedTimetoken(value) {
      self.selectedFeedTimetoken = value;
    },
    setFetchCommentLoading(value) {
      self.fetchCommentLoading = value;
    },
    setComments(value) {
      self.comments = value;
    },
    setHasMoreComments(value) {
      self.hasMoreComments = value;
    },
    setLoadMoreLoading(value) {
      self.loadMoreLoading = value;
    },
    setFeedTags(value) {
      self.tags = value;
    },
    setFilteredTags(value) {
      self.filteredTags = value;
    },
    setFeeds(value) {
      self.feeds = value;
    },
    setLoading(value) {
      self.loading = value;
    },
    setHasMoreFeeds(value) {
      self.hasMoreFeeds = value;
    },
    setCategories(value) {
      self.categories = value;
    },
    setCurrentFeedId(value) {
      self.currentFeedId = value;
    },
    setTitle(value) {
      self.title = value;
    },
    setImgUrl(value) {
      self.imgUrl = value;
    },
    setVideoUrl(value) {
      self.videoUrl = value;
    },
    setIsModalVisible(value) {
      self.isModalVisible = value;
    },
    setSelectedCategoryFilter(value) {
      self.selectedCategoryFilter = value;
    },
    setAllCategoryFilters(value) {
      self.allCategoryFilters = value;
    },
    setUploadImages(value) {
      self.uploadImages = value;
    },
    setSelectedTimeFilter(value) {
      self.selectedTimeFilter = value;
    },
    setIsCommentFlagged(value) {
      self.isCommentFlagged = value;
    },
    setFlaggedFeedPage(value) {
      self.flaggedFeedPage = value;
    },
    setFlaggedCommentCount(value) {
      self.flaggedCommentCount = value;
    },
    setNotificationFeedId(value) {
      self.notificationFeedId=value;
    },
    setNotificationCommentId(value) {
      self.notificationCommentId = value;
    },
    setModalImages(value) {
      self.modalImages = value;
    } ,
    setFirstImageIndex(value) {
      self.firstImageIndex = value;
    },
    setDeleteCommentLoading(index, value) {
      self.comments[index]['isLoading'] = value;
    },
    setMentionedUserName(value) {
      self.mentionedUserName = value;
    },
    setLoadingMentionOptions(value) {
      self.loadingMentionOptions = value;
    },
    async uploadMedia(url, payload) {
      try {
        const response = await ApiService.postFormDataRequest(url, payload);
        return response.data;
      } catch (e) {
        throw e
      }
    },
    async fetchTags() {
      try {
        const path = `feedTag`;
        const response = await ApiService.getRequest(path)
        if (response.success) {
          self.setFeedTags([...response.data.tags]);
          self.setFilteredTags([...response.data.tags]);
          self.setCategories([...response.data.categories]);
          self.setAllCategoryFilters(self.categories.map(ele => ele.feedGroupId));
        }
      } catch (error) {
        throw error;
      }
    },
    async fetchFeeds() {
      try {
        self.setLoading(true);
        if (self.selectedTimeFilter === TIME_FILTERS.FLAGGED) {
          await this.fetchFlaggedFeeds(false);
          return ;
        }
        const feedsResponse = await doLoadPosts();
        self.setFeeds([...self.feeds, ...feedsResponse]);
        self.setHasMoreFeeds(feedsResponse.length >= GET_FEED_LIMIT);
        self.setLoading(false);
      } catch (err) {
        throw err;
      }
    },
    async fetchComments() {
      try {
        self.setFetchCommentLoading(true);
        const response = await getReactions(self.currentFeedId, REACTION_TYPES.comment);
        self.setComments(response);
        self.setHasMoreComments(response.length >= GET_COMMENT_LIMIT);
        self.setFetchCommentLoading(false);
      } catch (error) {
        throw error;
      }
    },
    async loadMoreComments(lastCommentId) {
      try {
        self.setFetchCommentLoading(true);
        const response = await getReactions(self.currentFeedId, REACTION_TYPES.comment, lastCommentId);
        self.setComments([...self.comments, ...response]);
        self.setHasMoreComments(response.length >= GET_COMMENT_LIMIT);
        self.setFetchCommentLoading(false);
      } catch (err) {
        throw err;
      }
    },
    async fetchFlaggedFeeds(isLoadMore) {
      try {
        self.setLoading(true);
        // backend api call GET /feeds
        const { data, meta: { total } } = await ApiService.getRequest(
          "feeds",
          {
            page: self.flaggedFeedPage,
            limit: GET_FLAGGED_FEED_LIMIT
          }
        );
        
        const isCommentFlagged = {};
        const flaggedCommentCount = {};
        // bulk retrieve
        const activityIds = data?.map((ele) => {
          isCommentFlagged[ele?.activityId] = ele?.isCommentFlagged;
          flaggedCommentCount[ele?.activityId] = ele?.flaggedCommentCount;
          return ele?.activityId;
        });
        if (activityIds.length) {
          const feedsResponse = (await getActivitiesByIds(activityIds)).filter((activity) => !activity?.deleted_at);
  
          // store actions
          self.setIsCommentFlagged(isCommentFlagged);
          self.setFlaggedCommentCount(flaggedCommentCount);
          self.setHasMoreFeeds(self.feeds?.length + data?.length < total);
          isLoadMore ? self.setFeeds([...self.feeds, ...feedsResponse]) : self.setFeeds(feedsResponse);
        }
        self.setLoading(false);
      } catch(err) {
        self.setLoading(false);
        throw err;
      }
    },
    async fetchMentionOptions(searchValue = "") {
      const params = {
        $skip: 0,
        $limit: 50,
        "$select[1]": "username",
        "username[$iLike]": `%25${searchValue.trim()}%25`,
        "id[$ne]": AuthStore.userId,
        "$sort[username]": 1,
      };
      this.setLoadingMentionOptions(true);
      const response = await ApiService.getRequest("users", params);
      this.setLoadingMentionOptions(false);
      return response.data.map(({username}) => username);
    },
  }))
  .views((self) => ({
  }));

export default ForumFeed;
