import { inject, observer } from "mobx-react";

import React from "react";

@inject("store")
@observer
class FormModerator extends React.Component {
  openForms = () => {
    const {
      store: {
        FormMessageStore: { getAllForms, getFormQuestionsMod, resetForms },
      },
      timetoken,
      formId,
    } = this.props;
    resetForms();
    getAllForms(timetoken);
    getFormQuestionsMod(formId);
  };
  render() {
    const {
      store: {
        FormMessageStore: { form, loading },
      },
      timetoken,
    } = this.props;
    let name, submitCount, sentCount;
    if (form.has(timetoken)) {
      name = form.get(timetoken).name;
      submitCount = form.get(timetoken).submitCount;
      sentCount = form.get(timetoken).sentCount;
    }
    return form.has(timetoken) &&
      submitCount !== undefined &&
      name &&
      sentCount !== undefined ? (
      <div
        className="mod-form-container"
        style={{ cursor: "pointer" }}
        onClick={loading ? null : () => this.openForms()}
      >
        <div className="form-text">{name}</div>
        <div>
          <div className="form-count">
            {submitCount}/{sentCount}
          </div>
          <div className="form-completed">Completed</div>
        </div>
      </div>
    ) : null;
  }
}
export default FormModerator;
