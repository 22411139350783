import { Col, Row } from 'antd';
import React from 'react';
import moment from 'moment';

const Notification = (props) => {
    const { data, index } = props;
    return (
        <div 
            key={index} 
            style={{ cursor: 'pointer' }} 
            onClick={() => props.navigate({ data })}
        >
            <Row className='notification-container'>
                <Col span={24}>
                    <Row className='notification-subcontainer'>
                        <Col span={18}>
                            <div className='notification-text-primary text-trim'>{data.title}</div>
                        </Col>
                        <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span className='notification-text-datetime'>{moment(data.createdAt).format('hh:mm a')}</span>           
                        </Col>
                    </Row>
                    <Row className='notification-subcontainer' style={{ paddingBottom: 10 }}>
                        <Col span={24}>
                            <span className='notification-text-secondary wrap-text'>{data.body}</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Notification