import { types } from "mobx-state-tree";

const UserOutreachHistory = types.model("UserOutreachHistory", {
    id: types.number,
    isSmsOutreach: types.maybe(types.boolean),
    outreachValue: types.maybe(types.string),
    sourceUserId: types.number,
    targetUserId: types.number,
    createdAt: types.string,
    updatedAt: types.string,
    sourceUserDetails: types.map(types.frozen({
        fullName: types.maybeNull(types.string),
        username: types.maybeNull(types.string)
    })),
    targetUserDetails: types.map(types.frozen({
        fullName: types.maybeNull(types.string),
        username: types.maybeNull(types.string)
    })),
});

export default UserOutreachHistory;
