import { types } from "mobx-state-tree";

const Site = types.model("Site", {
  id: types.identifierNumber,
  name: types.string,
  sitePin: types.maybe(types.string),
  adminSignUpCode: types.maybeNull(types.string),
  territory: types.maybeNull(types.string),
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string)
});

export default Site;
