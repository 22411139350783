import React from 'react'
import { Button, Col, Row, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment/moment';

import { IMG_EDIT_OUTLINED, IMG_TICK_CIRCLE_BLUE, IMG_TICK_CIRCLE_GREEN, IMG_TICK_CIRCLE_ORANGE } from '../../utils/ImageUtils'
import { tasks } from '../../en.json';
import { TASK_DATE_FORMAT, TASK_STATUS } from '../../constants/GlobalConstant';
import { TaskInput } from './TaskInput';
import { getFormattedDate } from '../../utils/CommonUtils';

const { confirm } = Modal;

const SingleTask = (props) => {

    const {
        task,
        key,
        onComplete,
        confirmLoading,
        editTaskId,
        onChangeEditTask,
        onChangeTaskDescription,
        adminUsers,
        assignedPeerId,
        onChangeDate,
        updateTaskLoading,
        onCancel,
        onUpdate,
        taskDescription,
        dueAt,
        onSelectAssignedPeer,
        openUserProfile,
        hideUserInfo = false
    } = props;
    let tickIcon = IMG_TICK_CIRCLE_BLUE;

    // check task due date
    let isDue = false;
    if (task.dueAt) {
        const curDate = moment().startOf()
        const dueDate = moment(task.dueAt)
        if (dueDate <= curDate) {
            isDue = true;
            tickIcon = IMG_TICK_CIRCLE_ORANGE;
        }
    }

    // check if task completed
    if (task.status === TASK_STATUS.COMPLETED) {
        isDue = false;
        tickIcon = IMG_TICK_CIRCLE_GREEN;
    }

    const showConfirm = () => {
        confirm({
            title: tasks.taskCompletePrimary,
            icon: <ExclamationCircleOutlined />,
            content: tasks.taskCompleteSecondary,
            okText: tasks.taskCompleteOk,
            cancelText: tasks.taskCompleteCancel,
            confirmLoading: confirmLoading,
            onOk() {
                onComplete(task);
            },
        });
    };

    if (editTaskId === task.id) {
        // edit task
        return (
            <TaskInput
                taskDescription={taskDescription}
                onChangeTaskDescription={(event) => onChangeTaskDescription(event)}
                adminUsers={adminUsers}
                assignedPeerId={assignedPeerId}
                onSelectAssignedPeer={(value) => onSelectAssignedPeer(value)}
                onChangeDate={onChangeDate}
                dueAt={dueAt}
                updateTaskLoading={updateTaskLoading}
                onCancel={() => onCancel()}
                onSubmit={() => onUpdate(task)}
                addTask={false}
            />
        )
    }

    // view task
    return (
        <div className='task-container' key={key}>
            <Row>
                <Col span={1}>
                    <img src={tickIcon} alt="tick" className="task-icon" />
                </Col>
                <Col span={22}>
                    {task.description}
                </Col>
                <Col span={1} className="edit-icon-container">
                    {task.status == TASK_STATUS.IN_PROGRESS &&
                        <img
                            onClick={() => onChangeEditTask(task)}
                            src={IMG_EDIT_OUTLINED}
                            style={{ cursor: 'pointer' }}
                            alt="edit"
                            className="task-icon"
                        />
                    }
                </Col>
            </Row>
            <Row className='task-subcontainer'>
                <Col span={20}>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={hideUserInfo ? 8 : 6} style={{ marginLeft: 10 }}><span className='task-primary-text'>Created by:</span> <b>{task.createdByUserDetails.fullName}</b></Col>
                        <Col span={hideUserInfo ? 8 : 6}><span className='task-primary-text'>Assigned to:</span> <b>{task.assignedToUserDetails ? task.assignedToUserDetails.fullName : "-"}</b></Col>
                        <Col span={hideUserInfo ? 6 : 5}><span className='task-primary-text'>Due date:</span> <span className={isDue ? 'task-primary-text-error' : 'task-primary-text'}><b>{task.dueAt ? getFormattedDate(task.dueAt) : "-"}</b></span></Col>
                        {!hideUserInfo && <Col span={5}><span className='task-primary-text'>User:&nbsp;</span>
                            <b><span 
                                style={{ color: '#10A4F6', cursor: 'pointer' }}
                                onClick={() => openUserProfile(task.userDetails.id)}
                            >
                                {task.userDetails ? task.userDetails.username : "-"}
                            </span></b>
                        </Col>}
                        <Col span={1} className="edit-icon-container"></Col>
                    </Row>
                </Col>
                <Col span={4} className="edit-icon-container">
                    {task.status == TASK_STATUS.IN_PROGRESS ? <Button
                        className="download-button"
                        type="primary"
                        size="large"
                        style={{ width: "145px" }}
                        onClick={showConfirm}
                    >
                        {tasks.complete}
                    </Button> : <span style={{ color: 'green' }}>{`Completed by ${task.completedByUserDetails?.fullName} ${getFormattedDate(task.completedAt)}`}</span>}
                </Col>
            </Row>
        </div>
    )

}

export default SingleTask;