import _ from "lodash";
import { types } from "mobx-state-tree";
import ApiService from "../utils/ApiService";

const UserBadges = types
  .model("UserBadges", {
    userBadges: types.optional(types.frozen([])),
  })
  .actions((self) => ({
    reset() {
      self.userBadges = [];
    },
    async fetchUserBadges() {
      try {
        const path = `user-badges`;
        let response = await ApiService.getRequest(path);
        const { data, success } = response;
        if (success) {
          self.setTasks(data);
        }
        return data;
      } catch (error) {
        throw error;
      }
    },
    setTasks(value) {
      self.userBadges = value;
    }
  }))
  .views((self) => ({
  }));

export default UserBadges;
