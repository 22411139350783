import React, { useState } from "react";
import { Button, Modal, Input, Col, Checkbox, Row } from "antd";

const SharePost = (props) => {
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [userListOrignal, setUserListOrignal] = useState(
        [{
            userId: 1,
            username: "Marigold 1"
        },
        {
            userId: 1,
            username: "test2"
        },
        {
            userId: 1,
            username: "unicorn"
        },
        {
            userId: 1,
            username: "Qa Test"
        }]
    );

    const [userData, setUserData] = useState([{
        userId: 1,
        username: "Marigold 1"
    },
    {
        userId: 1,
        username: "test2"
    },
    {
        userId: 1,
        username: "unicorn"
    },
    {
        userId: 1,
        username: "Qa Test"
    }]);
    const { isModalVisible, setIsModalOpen } = props;

    const filterTagsByCharacter = async (character) => {
        character = character.toLowerCase();
        const filteredUserList = [];
        for (const item of userListOrignal) {
            const filteredValues = item.username.toLowerCase().includes(character);
            if (filteredValues) {
                filteredUserList.push(item);
            }
        }
        return filteredUserList || userListOrignal;
    }

    const onchangeSearchChange = async (e) => {
        const filteredTags = await filterTagsByCharacter(e.target.value);
        setUserData(filteredTags);
    }

    const onChecked = (e, username) => {
        if (e.target.checked) {
            const updatedUserList = [...selectedUserList, username];
            setSelectedUserList(updatedUserList);
        } else {
            const index = selectedUserList.indexOf(username);
            if (index !== -1) {
                const updatedUserList = [...selectedUserList];
                updatedUserList.splice(index, 1);
                setSelectedUserList(updatedUserList);
            }
        }
    }

    return (
        <Modal
            // style={{textAlign:"center"}}
            title="Share"
            centered
            width={"400px"}
            visible={isModalVisible}
            onCancel={(e) => {
                setIsModalOpen(false);
            }}
        >
            <Input type='search'
                allowClear
                placeholder="Search"
                onChange={(e) => { onchangeSearchChange(e) }}
                style={{ marginBottom: 20 }}
            />
            {
                userData.length > 0 ?
                    userData.map((item, index) => {
                        return (
                            <>
                                <Row
                                    key={index}
                                    style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold' }}>
                                    <Col span={3}>
                                        <div id="profileImage">{item.username.charAt(0).toUpperCase()}</div>

                                    </Col>
                                    <Col span={20}>{item.username}</Col>
                                    <Col span={1} >
                                        <Checkbox
                                            key={item.userId}
                                            style={{ height: 30 }}
                                            checked={selectedUserList.includes(item.username)}
                                            onChange={e => { onChecked(e, item.username) }} />
                                    </Col>
                                </Row>
                            </>
                        );
                    }) :
                    <>
                        <p>User not found </p>
                    </>
            }
            {
                userData.length > 0 ?
                    <Row key={userData.le}>
                        <Col span={2}></Col>
                        <Col span={20}>
                            <Button
                                style={{
                                    backgroundColor: "orange",
                                    color: "white",
                                    marginTop: 20,
                                    width: "100%", borderRadius: 10
                                }}
                                disabled={selectedUserList.length === 0}
                            >Share</Button>
                        </Col>
                        <Col span={2}></Col>

                    </Row>
                    : null
            }
        </Modal>
    );
};

export default SharePost;