import React from "react";
import { Checkbox, Radio } from "antd";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import _ from "lodash";
import { DatePicker } from 'antd';
import moment from "moment";

import { userProfile } from "../../en.json";
import {
    FORM_QUESTION_TYPE_DESCRIPTIVE,
    FORM_QUESTION_TYPE_MULTIPLE_CHOICES,
    FORM_QUESTION_TYPE_MULTIPLE_CHOICES_RADIO,
    FORM_QUESTION_CATEGORY_TYPE_EDITABLE,
    FORM_QUESTION_TYPE_DATEPICKER,
    FORM_QUESTION_CATEGORY_TYPE_EDITABLE_TOUCHPOINT_DATE,
    FORM_QUESTION_CATEGORY_TYPE_TOUCHPOINT_MEDIUM
} from "../../constants/GlobalConstant";
import { SA, MODERATOR } from "../../constants/UserRolesConstant";
import { QUESTION_STATUS_FLAGS } from "../../constants/GlobalConstant";
import { formateDate } from "../../utils/CommonUtils";

const { HIDDEN, OPTIONAL } = QUESTION_STATUS_FLAGS;

@inject("store")
@observer
class EditUserForm extends React.Component {
    componentDidMount() {
        const {
            store: {
                ProfileStore: {
                    formAnswers,
                    setAnswersForEditForm,
                    setOriginalAnswersForEditForm,
                    setMultipleChoiceQuestionIds,
                    setMultipleChoiceRadioQuestionIds,
                    setMetaInfoForUpdateUserForm,
                    setOptionalQuestionIds,
                    setHiddenQuestionIds
                },
                AuthStore: { type: userType }
            },
            formQuestions
        } = this.props;

        const isSuperAdmin = userType === SA;

        const [formAnswerObj] = formAnswers;
        // Here we are taking first object from formAnswers array
        if (formAnswerObj) {
            const { formId, userId, userFormId } = formAnswerObj;
            // Here we are setting meta information for user form
            setMetaInfoForUpdateUserForm({ formId, userId, userFormId });
        }
        // Preparing object for multiple choice & multiple choice radio questions
        const multipleChoiceQuestionIds = {};
        const multipleChoiceRadioQuestionIds = {};
        // Preparing array for optional & hidden type questions
        const optionalQuestionIds = [];
        const hiddenQuestionIds = [];
        // Preparing object for all original question ids & answers
        const questionAnswersData = {};
        for (const questionObj of formQuestions) {
            const { id, type, options = [], questionStatus } = questionObj;
            const questionAnswerObj = formAnswers.find(obj => obj.formQuestionId === id);
            let finalAnswer = '';
            // If question status is HIDDEN then we will push to hiddenQuestionIds Array
            if (questionStatus === HIDDEN) {
                hiddenQuestionIds.push(id);
            } else if (questionStatus === OPTIONAL) {
                // If question status is OPTIONAL then we will push to optionalQuestionIds Array
                optionalQuestionIds.push(id);
            }
            // Here if form question response exists in our database then we are getting a object otherwise it is undefined
            if (questionAnswerObj) {
                const { answer, formQuestionOptionId } = questionAnswerObj;
                finalAnswer = formQuestionOptionId > 0 ? formQuestionOptionId : answer;
            }

            if (isSuperAdmin && type === FORM_QUESTION_TYPE_MULTIPLE_CHOICES) {
                questionAnswersData[id] = finalAnswer;
                multipleChoiceQuestionIds[id] = [...options];
            } else if (isSuperAdmin && type === FORM_QUESTION_TYPE_MULTIPLE_CHOICES_RADIO) {
                multipleChoiceRadioQuestionIds[id] = [...options];
                questionAnswersData[id] = finalAnswer;
            } else if (isSuperAdmin && ((type === FORM_QUESTION_TYPE_DESCRIPTIVE) || (type === FORM_QUESTION_TYPE_DATEPICKER))) {
                questionAnswersData[id] = finalAnswer;
            }
        }
        // Setting optional & hidden question ids to mobx state
        setOptionalQuestionIds(optionalQuestionIds);
        setHiddenQuestionIds(hiddenQuestionIds);
        // Setting question ids and its answers in mobx state
        setMultipleChoiceQuestionIds(multipleChoiceQuestionIds);
        setMultipleChoiceRadioQuestionIds(multipleChoiceRadioQuestionIds);
        // Setting all original answers in mobx state
        setAnswersForEditForm(questionAnswersData);
        setOriginalAnswersForEditForm(questionAnswersData);
    };

    // This function is used to change the answer of multiple choice radio question
    getMultipleChoicesRadio = (id, e) => {
        const enteredvalue = e.target.value;
        const {
            store: {
                ProfileStore: {
                    answersForEditForm,
                    setAnswersForEditForm,
                },
            }
        } = this.props;
        setAnswersForEditForm({
            ...answersForEditForm,
            [id]: enteredvalue
        });
    };

    // This function is used to change the answer of multiple choices question
    getMultipleChoices = (id, checkedValueArr) => {
        const {
            store: {
                ProfileStore: {
                    answersForEditForm,
                    setAnswersForEditForm,
                },
            }
        } = this.props;
        setAnswersForEditForm({
            ...answersForEditForm,
            [id]: checkedValueArr.join(',')
        });
    };

    // This function is used to change the answer of descriptive question
    handleDescriptiveAnswerChange = (id, event) => {
        const {
            store: {
                ProfileStore: {
                    answersForEditForm,
                    setAnswersForEditForm,
                },
            }
        } = this.props;
        setAnswersForEditForm({
            ...answersForEditForm,
            [id]: event.target.value
        });
    };

    // This function is used to change the answer of datepicker question
    handleDatepickerAnswerChange = (id, date, dateString) => {
        const {
            store: {
                ProfileStore: {
                    answersForEditForm,
                    setAnswersForEditForm,
                },
            }
        } = this.props;
        const enteredvalue = dateString || null
        if (enteredvalue) {
            setAnswersForEditForm({
                ...answersForEditForm,
                [id]: enteredvalue
            });
        }
    };

    renderOptionsByQuestionType = (question) => {
        const {
            store: {
                ProfileStore: { formAnswers, answersForEditForm },
                AuthStore: { type }
            },
            getAnswerToFormQuestion
        } = this.props;
        const { id, type: questionType, options, category } = question;
        const answerObj = formAnswers.find((formAnswer) => formAnswer.formQuestionId === id);
        const isQuestionDisabled = !(
          [
              FORM_QUESTION_CATEGORY_TYPE_EDITABLE,
              FORM_QUESTION_CATEGORY_TYPE_EDITABLE_TOUCHPOINT_DATE,
              FORM_QUESTION_CATEGORY_TYPE_TOUCHPOINT_MEDIUM
          ].includes(category) &&
          [SA].includes(type)
        );

        if (questionType === FORM_QUESTION_TYPE_DESCRIPTIVE) {
            return (
                <textarea
                    className={`descriptive-textbox ${isQuestionDisabled ? 'inactive' : ''}`}
                    defaultValue={getAnswerToFormQuestion(question)}
                    onChange={(e) => this.handleDescriptiveAnswerChange(id, e)}
                    disabled={isQuestionDisabled} />
            );
        } else if (questionType === FORM_QUESTION_TYPE_MULTIPLE_CHOICES) {
            const myOptions = options.map((option) => {
                return { value: option.id, label: option.option };
            });
            const selectedOptionsArr = answerObj ? answerObj.answer.split(',').map((value) => +value) : null;
            return (
                <Checkbox.Group
                    options={myOptions}
                    defaultValue={selectedOptionsArr}
                    onChange={(e) => this.getMultipleChoices(id, e)}
                    disabled={isQuestionDisabled}
                />
            );
        } else if (questionType === FORM_QUESTION_TYPE_MULTIPLE_CHOICES_RADIO) {
            const selectedAnswer = answerObj ? answerObj.formQuestionOptionId : null;
            return (
                <div className="radio-section-wrapper">
                    <Radio.Group
                        className="radio-group"
                        onChange={(e) => this.getMultipleChoicesRadio(id, e)}
                        defaultValue={selectedAnswer}
                        disabled={isQuestionDisabled}
                    >
                        {options.map((option, index) => (
                            <Radio key={index} value={option.id}>{option.option}</Radio>
                        ))}
                    </Radio.Group>
                </div>
            );
        } else if (questionType === FORM_QUESTION_TYPE_DATEPICKER) {
            let tempAnswerDate = getAnswerToFormQuestion(question, true);
            if (answersForEditForm && answersForEditForm[id]) {
                tempAnswerDate = answersForEditForm[id];
            }
            return (
                <DatePicker
                    className={`${isQuestionDisabled ? 'inactive' : ''}`}
                    onChange={(date, dateString) => this.handleDatepickerAnswerChange(id, date, dateString)}
                    value={tempAnswerDate ? moment(tempAnswerDate) : tempAnswerDate}
                    picker="month"
                    format={'MM/DD/YYYY'}
                    disabledDate={(current) => current > moment()}
                    disabled={isQuestionDisabled}
                />
            );
        }
    };

    render() {
        const { formQuestions } = this.props;

        return (<>
            <div className="edit-form-warning">
                {userProfile.editFormWarning}
            </div>
            {formQuestions.map(
                (question, index) => {
                    return (
                        <li className="form-qa-container" key={index}>
                            <div className="form-question">
                                {question.questionTitle}
                            </div>
                            <div className="form-answer">
                                {this.renderOptionsByQuestionType(question)}
                            </div>
                        </li>
                    );
                }
            )}
        </>);
    };
};

export default EditUserForm;
