import React from "react";
import { Col, Row, Pagination, Spin, notification, Icon } from "antd";
import { inject, observer } from "mobx-react";
import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';

import { DEFAULT_PAGE_SIZE, TASK_STATUS } from "../../constants/GlobalConstant";
import SingleTask from "../UI/SingleTask";
import { tasks as tasksLabel } from '../../en.json';
import { IMG_DOWN_ARROW, IMG_UP_ARROW } from '../../utils/ImageUtils';

@inject("store")
@observer
class MyTasks extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isWellnessPlanEdit: false,
      taskDescription: '',
      dueAt: null,
      assignedPeerId: null,
      oldAssignedPeerId: null,
      editTaskId: null,
      showCompletedSection: false,
      currentPage: 1
    }
  }

  onPageChange = async (status, page) => {
    const {
      store: {
        TaskStore,
        AuthStore
      }
    } = this.props;

    status === TASK_STATUS.IN_PROGRESS ? TaskStore.setInProgressTasksPage(page) : TaskStore.setCompletedTasksPage(page);
    await TaskStore.fetchAllTasks({
      userId: AuthStore.userId,
      status,
      page: page
    });
    this.setState({ currentPage: page })
  }

  onClickRefresh = async () => {
    const {
      store: {
        TaskStore,
        AuthStore
      }
    } = this.props;
    const { currentPage } = this.state;
    await TaskStore.fetchAllTasks({
      userId: AuthStore.userId,
      status: TASK_STATUS.IN_PROGRESS,
      page: currentPage
    });
  }

  onSelectAssignedPeer = (value) => {
    this.setState({ assignedPeerId: value })
  }

  onChangeTaskDescription = (event) => {
    this.setState({ taskDescription: event.target.value })
  }

  onChangeDate = (e) => {
    this.setState({ dueAt: e.target.value })
  }

  onComplete = async (taskId) => {
    const {
      store: {
        TaskStore: { updateMyTask },
      },
    } = this.props;

    const dataToUpdate = {
      taskId,
      status: TASK_STATUS.COMPLETED,
    }
    await updateMyTask(dataToUpdate)
    this.openNotification(tasksLabel.taskCompleted)
  }

  openNotification = (description) => {
    notification.open({
      message: 'Success',
      description,
      icon: <CheckOutlined style={{ color: 'green' }} />,
    });
  };

  resetState = () => {
    this.setState({
      assignedPeerId: null,
      taskDescription: '',
      dueAt: null,
      editTaskId: null
    })
  }

  onChangeEditTask = (task) => {
    this.setState({
      editTaskId: task.id,
      taskDescription: task.description,
      dueAt: task.dueAt ? moment(task.dueAt).format('YYYY-MM-DD') : null,
      assignedPeerId: task.assignedToUserId,
      oldAssignedPeerId: task.assignedToUserId
    })
  }

  onUpdate = async (taskId) => {
    const { store: {
      TaskStore: { updateMyTask },
    } } = this.props;
    const { assignedPeerId, dueAt, taskDescription, oldAssignedPeerId } = this.state

    if (!taskDescription) {
      alert(tasksLabel.descriptionRequired);
      return
    }

    const dueDate = dueAt ? moment(dueAt).format('YYYY-MM-DD') : null
    const dataToUpdate = {
      taskId,
      description: taskDescription,
      assignedToUserId: assignedPeerId,
      dueAt: dueDate,
      isAssignedPeerChanged: oldAssignedPeerId !== assignedPeerId,
    }
    await updateMyTask(dataToUpdate)
    this.openNotification(tasksLabel.taskUpdated)
    this.resetState()
  }

  openUserProfile = async (userID) => {
    const {
      store: {
        TaskStore: { openUserProfile }
      },
    } = this.props;
    await openUserProfile(userID);
    this.resetState();
  };

  render() {
    const {
      store: {
        MemberListStore: { adminUsers },
        TaskStore: {
          fetchAllInProgressTasksLoading,
          fetchAllCompletedTasksLoading,
          allInProgressTasks,
          allCompletedTasks,
          totalInProgressTasks,
          totalCompletedTasks,
          completedTasksPage,
          inProgressTasksPage
        }
      },
    } = this.props;

    const { editTaskId, assignedPeerId, taskDescription, dueAt, showCompletedSection } = this.state;

    return (
      <div>
        <Row>
          <Col span={4} />
          <Col
            span={16}
          >
            {/** In progress */}
            <div style={{ marginTop: 30 }}>
              <Row>
                <Col span={12}>
                  <label className="task-label">{tasksLabel.openTasks}</label>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <div
                    onClick={() => this.onClickRefresh()}
                    style={{ cursor: 'pointer', fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <span style={{ paddingTop: 3 }}>Reload</span>
                    <Icon
                      type="reload"
                      spin={fetchAllInProgressTasksLoading}
                      style={{ fontSize: 20, marginLeft: 5 }}
                    />
                  </div>
                </Col>
              </Row>
              {!fetchAllInProgressTasksLoading ? (allInProgressTasks.length ?
                allInProgressTasks.map((t, k) => {
                  return <SingleTask
                    task={t}
                    key={k}
                    editTaskId={editTaskId}
                    assignedPeerId={assignedPeerId}
                    adminUsers={adminUsers}
                    taskDescription={taskDescription}
                    dueAt={dueAt}
                    onChangeTaskDescription={(event) => this.onChangeTaskDescription(event)}
                    onChangeDate={this.onChangeDate}
                    onComplete={(task) => this.onComplete(task.id)}
                    onChangeEditTask={(task) => this.onChangeEditTask(task)}
                    onUpdate={(task) => this.onUpdate(task.id)}
                    onCancel={() => this.resetState()}
                    onSelectAssignedPeer={(value) => this.onSelectAssignedPeer(value)}
                    openUserProfile={(userId) => this.openUserProfile(userId)}
                  />
                }) :
                <Row>
                  <Col span={24} className={"no-tasks"}>
                    <p>{tasksLabel.noOpenTasks}</p>
                  </Col>
                </Row>
              ) : <div className="tasks-loader">
                <Spin />
              </div>}
              {totalInProgressTasks > 0 && <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    defaultCurrent={inProgressTasksPage}
                    total={totalInProgressTasks}
                    onChange={(page, pageSize) => this.onPageChange(TASK_STATUS.IN_PROGRESS, page)}
                  />
                </Col>
              </Row>}
            </div>

            {/** Completed */}
            <div className="completed-tasks-container">
              <Row>
                <Col span={12}>
                  <label className="task-label">{tasksLabel.completedTasks}</label>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.setState({ showCompletedSection: !showCompletedSection })}
                  >
                    <img
                      src={showCompletedSection ? IMG_UP_ARROW : IMG_DOWN_ARROW}
                      className="dropdown-arrow"
                      alt="arrow"
                    />
                  </span>
                </Col>
              </Row>
              {!fetchAllCompletedTasksLoading ? (showCompletedSection && (allCompletedTasks.length ?
                allCompletedTasks.map((t, k) => {
                  return <SingleTask
                    task={t}
                    key={k}
                    openUserProfile={(userId) => this.openUserProfile(userId)}
                  />
                }) :
                <Row>
                  <Col span={24} className={"no-tasks"}>
                    <p>{tasksLabel.noCompletedTasks}</p>
                  </Col>
                </Row>)
              ) : <div className="tasks-loader">
                <Spin />
              </div>}
              {showCompletedSection && totalCompletedTasks > 0 && <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    defaultCurrent={completedTasksPage}
                    total={totalCompletedTasks}
                    onChange={(page, pageSize) => this.onPageChange(TASK_STATUS.COMPLETED, page)}
                  />
                </Col>
              </Row>}
            </div>
          </Col>
          <Col span={4} />
        </Row>
      </div>
    );
  }
}

export default MyTasks;
