import ApiService from "../utils/ApiService";
import { types } from "mobx-state-tree";

import SecureLocalStorage from "../utils/SecureLS";

const Gif = types
    .model("Gif", {
        loading: false,
        giphyKey: types.maybe(types.string),
        gifsData: types.array(types.frozen({
            images: types.frozen({
                original: types.frozen({
                    url: types.maybe(types.string),
                }),
                preview_gif: types.frozen({
                    url: types.maybe(types.string)
                })
            })
        })),
        pagination: types.frozen({
            pageSize: 12,
            total: 0,
            current: 1,
        }),
    })
    .actions((self) => ({
        async getTrendingGifsService() {
            try {
                self.setLoading(true);
                const giphyKey = self.giphyKey;
                const { pageSize, current } = self.pagination;
                if (giphyKey) {
                    const url = 'https://api.giphy.com/v1/gifs';
                    const params = {
                        api_key: giphyKey,
                        limit: pageSize,
                        offset: (current - 1) * pageSize
                    };
                    const response = await ApiService.getRequest('trending', params, null, true, url);
                    const { success, data } = response;
                    if (success) {
                        self.setGifsData([...data]);
                    }
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async getGifsBySearchService(search = '') {
            try {
                self.setLoading(true);
                const giphyKey = self.giphyKey;
                const { pageSize, current } = self.pagination;
                if (giphyKey) {
                    const url = 'https://api.giphy.com/v1/gifs';
                    const params = {
                        q: search,
                        api_key: giphyKey,
                        limit: pageSize,
                        offset: (current - 1) * pageSize
                    };
                    const response = await ApiService.getRequest('search', params, null, true, url);
                    const { success, data } = response;
                    if (success) {
                        self.setGifsData([...data]);
                    }
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },

        fetchGiphyKeyFromLocalStorage() {
            const giphyKey = SecureLocalStorage.get('GIPHY');
            if (giphyKey) {
                self.setGiphyKey(giphyKey);
            }
        },
        resetPagination() {
            self.pagination = {
                pageSize: 12,
                total: 0,
                current: 1,
            };
        },
        setPaginationData(pagination) {
            self.pagination = pagination;
        },
        setGiphyKey(giphyKey) {
            self.giphyKey = giphyKey;
        },
        setGifsData(arr) {
            self.gifsData = arr;
        },
        setLoading(value) {
            self.loading = value;
        },
    }))
    .views((self) => ({
    }));

export default Gif;