import React, { Component } from 'react'
import { notification } from "antd";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class AlertNotifications extends Component {

    componentDidUpdate() {
        const {
            store: {
                NotificationStore: {
                    resetNotificationMeta
                }
            }
        } = this.props;

        const { showAlertNotification } = this.props;
        if (showAlertNotification) {
            this.showNotificationForWindows();
            resetNotificationMeta();
        }
    }

    showNotificationForWindows = () => {
        const { resetNotificationMeta, notificationTitle, notificationDescription } = this.props;
        notification.info({
            message: notificationTitle.substring(0, 25),
            description: notificationDescription.substring(0, 120),
            placement: 'bottomLeft',
            onClose: () => resetNotificationMeta()
        });
    }

    render() {
        return null;
    }
}

export default AlertNotifications;