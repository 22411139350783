import { Select, Table } from 'antd';
import React, { memo } from 'react';

import { USER_BADGE_IMAGES } from '../../constants/BadgeConstant';

const { Option } = Select;

const monthDropdownStyles = {
    width: 180,
    marginLeft: 30,
    marginBottom: 10
};

const UserBadgesQTData = (props) => {
    const {
        badgeType = null,
        monthsArrForQTDropdown,
        selectedMonthForQT,
        handleChange,
        columnsForQTTableByUserBadges,
        usersQTData,
        loading,
        rowEvents
    } = props;

    return (
        <>
            <h4 className="territory-header">
                {badgeType ? <img src={USER_BADGE_IMAGES[badgeType]} /> : 'Members'}
            </h4>
            {
                // If we have data for months array then we will show dropdown list in QT table
                monthsArrForQTDropdown && monthsArrForQTDropdown.length ?
                    (
                        <Select
                            value={selectedMonthForQT}
                            style={monthDropdownStyles}
                            onChange={(value) => handleChange(value, badgeType)}
                        >
                            {monthsArrForQTDropdown.map((value, index) =>
                                <Option key={index} value={value}>{value}</Option>
                            )}
                        </Select>
                    ) :
                    null
            }

            <Table
                rowKey="key"
                columns={columnsForQTTableByUserBadges}
                dataSource={usersQTData}
                pagination={false}
                loading={loading}
                onRow={rowEvents}
            />
        </>
    );
};

export default memo(UserBadgesQTData);