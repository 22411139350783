import { inject, observer } from "mobx-react";

import React from "react";
import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import close from "../../static/images/close.png";

@inject("store")
@observer
class AllForms extends React.Component {
  getAnswerToFormQuestion = (userFormId, question) => {
    const {
      store: {
        FormMessageStore: { formAnswers },
      },
    } = this.props;
    const formAnswersArr = formAnswers.get(userFormId);
    if (question.type === "multiple_choices") {
      const questionId = question.id;
      const options = question.options;

      const answerObjArr = formAnswersArr.filter(
        (formAnswer) => formAnswer.formQuestionId === questionId
      );

      if (answerObjArr.length) {
        const questionAnswer = answerObjArr[0].answer;
        if (questionAnswer) {
          const optArr = questionAnswer.split(",");
          const ans = [];
          optArr.forEach((opt) => {
            const isOp = options.filter((options) => options.id === Number(opt));
            if (isOp.length) {
              ans.push(isOp[0].option);
            }
          });
          return ans.join(",");
        }
        return "not found";
      } else {
        return "not found";
      }
    } else if (question.type === "multiple_choices_radio") {
      const questionId = question.id;
      const options = question.options;
      const answer = formAnswersArr.filter(
        (formAnswer) => formAnswer.formQuestionId === questionId
      );
      if (answer.length) {
        const opt = options.filter(
          (option) => option.id === answer[0].formQuestionOptionId
        );
        if (opt.length) {
          return opt[0].option;
        } else {
          return "not found";
        }
      } else {
        return "not found";
      }
    } else if (question.type === "descriptive") {
      for (let i = 0; i < formAnswersArr.length; i++) {
        if (question.id === formAnswersArr[i].formQuestionId) {
          return formAnswersArr[i].answer;
        }
      }
    }
  };

  render() {
    const {
      store: {
        FormMessageStore: {
          allForms,
          loading,
          changeformsVisible,
          formQuestions,
          formLoading,
          formAnswers,
        },
      },
    } = this.props;

    return (
      <div className="form-response">
        <h1 className="form-heading">
          {loading && <Spin />}
          Form Responses
          <img
            src={close}
            onClick={() => changeformsVisible(false)}
            alt="Close"
          />
        </h1>
        <div
          style={{
            height: "calc(100vh - 150px)",
            overflow: "auto",
          }}
        >
          {allForms.map((item) => {
            return formAnswers.has(item.id) ? (
              <div>
                <div className="form-username">{item.user.username}</div>
                {!formLoading ? (
                  <ol className="form-qa-container">
                    {formQuestions.length ? (
                      formQuestions.map((question, index) => {
                        return (
                          <li className="form-qa-container">
                            <div className="form-question">
                              {index + 1}. {question.questionTitle}
                            </div>
                            <div className="form-answer">
                              {this.getAnswerToFormQuestion(item.id, question)}
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <div>No Responses Yet</div>
                    )}
                  </ol>
                ) : (
                  <div className="form-loading">
                    <Spin />
                  </div>
                )}
              </div>
            ) : null;
          })}
        </div>
      </div>
    );
  }
}
export default AllForms;
