import { types } from "mobx-state-tree";

const MessageInput = types
  .model("MessageInput", {
    text: "",
    groupId: types.number
  })
  .actions(self => ({
    clearText() {
      self.text = "";
    },
    setText(text) {
      self.text = text;
    }
  }));

export default MessageInput;
