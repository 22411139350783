import ChangePassordForm from "../components/public/ChangePassordForm";
import ForgotPassordForm from "../components/public/ForgotPassordForm";
import Layout from "../components/Layout";
import LoginForm from "../components/public/LoginForm";
import React from "react";
import SignupForm from "../components/public/SignupForm";
import UserSignUpForm from "../components/public/UserSignUpForm";
import UserSignUpSuccess from "../components/public/UserSignUpSuccess";
import { inject } from "mobx-react";
import { layout } from "../en.json";
import logo from "../static/images/marigold.png";
import highpointLogo from "../static/images/highpoint_logo.png";
import { IS_HP, IS_STAGING, IS_CS } from "../utils/getEnvironment";
// import sm_logo from "../static/images/cleanslate_logo.png";
import { withRouter } from "react-router-dom";
@withRouter
@inject("store")
class Public extends React.PureComponent {
  componentDidMount() {
    const {
      store: {
        AuthStore: { resetFormInputs },
      },
    } = this.props;
    resetFormInputs();
  }

  renderForm() {
    const {
      location: { pathname },
      match: { params },
    } = this.props;

    switch (pathname) {
      case "/login":
        return <LoginForm />;
      case "/sign-up":
        return <SignupForm />;
      case "/forgot-password":
        return <ForgotPassordForm />;
      case "/change-password":
        return <ChangePassordForm />;
      case `/csm/${params.data}`:
        return <UserSignUpForm url={params.data} />;
      case "/signup-success":
        return <UserSignUpSuccess />;
      default:
        return null;
    }
  }

  render() {
    const {
      location: { pathname },
    } = this.props;
    return (
      <Layout title="login" classname="login-page">
        <div className="d-flex align-items-center flex-column justify-content-center h-100 form-wrapper">
          <div className="logo">
            <div className="d-flex align-items-center flex-column justify-content-center ">
              <div>
                <img src={logo} alt="Marigold" />
                <span>
                  <small>{layout.tm}</small>
                </span>{" "}
                <br />
              </div>
              {pathname.startsWith("/csm/") ? (
                <div className="d-flex align-items-center flex-column justify-content-center">
                  <div className="csm-title">{layout.peerSupportApp}</div>
                  <div className="get-connected">{layout.getConnected}</div>
                </div>
              ) : pathname === "/signup-success" ? null : (
                <div className="page-title">{layout.dashboard}</div>
              )}
            </div>
          </div>
          <div
            className={
              IS_CS || IS_STAGING || IS_HP
                ? "card login-container mt-2"
                : "card noLogo mt-2"
            }
          >
            <div
              className={pathname.startsWith("/csm/") ? "" : "card-body pt-1"}
            >
              <div className="row align-items-center">
                {IS_CS || IS_STAGING || IS_HP
                  ? pathname !== "/signup-success" && (
                      <div className="col-sm-5 pl-0 pr-0">
                        <div
                          className={
                            "left-conatiner " +
                            (pathname.startsWith("/csm/")
                              ? "pb-1 pt-1 pl-1 background-color"
                              : "")
                          }
                        >
                          {pathname.startsWith("/csm/") ? (
                            <span className="sub-title">
                              {layout.inPartnership}
                            </span>
                          ) : null}
                          <img
                            style={{ width: "160px" }}
                            src={
                              IS_CS || IS_STAGING
                                ? layout.imageLink
                                : IS_HP
                                ? highpointLogo
                                : null
                            }
                            alt="Logo"
                          />
                        </div>
                      </div>
                    )
                  : null}
                {IS_CS || IS_STAGING || IS_HP ? (
                  <div
                    className={
                      pathname === "/signup-success"
                        ? "col-sm-12"
                        : "col-sm-7 pl-0 pr-0"
                    }
                  >
                    {this.renderForm()}
                  </div>
                ) : (
                  <div className="col-sm-12">{this.renderForm()}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Public;
