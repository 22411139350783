import { types } from "mobx-state-tree";

const FormAnswer = types.model("FormAnswer", {
  id: types.maybe(types.number),
  answer: types.maybeNull(types.string),
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string),
  formId: types.maybe(types.number),
  questionTitle: types.maybe(types.string),
  type: types.maybe(types.string),
  channelId: types.maybe(types.number),
  formQuestionId: types.maybe(types.number),
  formQuestionOptionId: types.maybeNull(types.number),
  messageId: types.maybe(types.string),
  responseFrom: types.maybe(types.string),
  userFormId: types.maybe(types.number),
  userId: types.maybe(types.number)
});

export default FormAnswer;
