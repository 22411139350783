import React from "react";
import downArrow from "../../static/images/downArrow.png";
import upArrow from "../../static/images/upArrow.png";
import { inject, observer } from "mobx-react";
import { Button, Table, Spin, Select, Menu, Dropdown, Checkbox } from "antd";
import { sortBy } from "lodash";
import moment from "moment";
import { RSVP } from "../../en.json";
import { toJS } from "mobx";
import timesArr from "../../utils/time";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { getFormattedPhoneNumber } from "../../utils/CommonUtils";

const { Option } = Select;

@inject("store")
@observer
class EventItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { copySuccess: "" };
    this.input = React.createRef();
  }

  changeValue = (e) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails(e.target.getAttribute("data-field"), e.target.value);
  };

  changeNewFromTime = (value) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails("newFromTime", value);
  };

  changeNewToTime = (value) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails("newToTime", value);
  };

  expandEvent = (value) => {
    const {
      store: {
        EventStore: {
          setSelectedEventId,
          resetEventUsersPagination,
          resetEventUsers,
          fetchEventUsers,
          setShowDetails,
          selectedEventId,
          showDetails,
          resetUserNotes,
        },
      },
    } = this.props;
    if (value === selectedEventId && showDetails) {
      return;
    }
    if (!value) {
      this.setState({ copySuccess: "" });
    } else {
      resetEventUsers();
      resetUserNotes();
      this.editEventDetails(null, false);
      resetEventUsersPagination();
      setShowDetails(true);
      setSelectedEventId(value);
      fetchEventUsers();
    }
  };

  referPatient = (eventId, value) => {
    const {
      store: {
        EventStore: { setNewPatient, setSelectedEventId },
      },
    } = this.props;
    setSelectedEventId(eventId);
    setNewPatient(value);
  };

  editEventDetails = (eventId, value) => {
    const {
      store: {
        EventStore: { setEditReferral },
      },
    } = this.props;
    setEditReferral(eventId, value);
  };

  copyToClipboard = (e) => {
    this.input.select();
    document.execCommand("copy");
    e.target.focus();
    this.setState({ copySuccess: "Copied!" });
  };

  getTime = (hour, mins) => {
    if (!mins) {
      mins = "00";
    }
    if (hour === 12) {
      return `${hour}:${mins} PM`;
    }
    if (hour === 24) {
      return `${hour / 2}:${mins} AM`;
    } else if (hour > 12) {
      return `${hour % 12}:${mins} PM`;
    } else {
      return `${hour}:${mins} AM`;
    }
  };
  handleTableChange = (pagination, filters, sorter) => {
    const {
      store: {
        EventStore: { fetchEventUsers, resetEventUsersPagination },
      },
    } = this.props;

    resetEventUsersPagination(pagination);
    fetchEventUsers();
  };

  getFromDisabledHours = () => {
    const {
      store: {
        EventStore: { newEventDate },
      },
    } = this.props;
    const now = moment();
    const dateToday = now.format("YYYY-MM-DD");
    var hours = [];
    if (newEventDate === dateToday) {
      for (var i = 0; i < now.hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  getFromDisabledMins = (selectedHour) => {
    const now = moment();
    var minutes = [];
    if (selectedHour === now.hour()) {
      for (var i = 0; i < now.minutes(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  setNewEligibeSite = ({ key }) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    setNewEventDetails("newEligibleId", Number(key));
  };

  setNewRecurring = (e) => {
    const {
      store: {
        EventStore: { setNewEventDetails },
      },
    } = this.props;
    const newRecurring = e.target.checked;
    setNewEventDetails("newRecurring", newRecurring);
  };

  setUserStatus = (eventUserId, key) => {
    const {
      store: {
        EventStore: { updateUserStatus },
      },
    } = this.props;
    updateUserStatus(eventUserId, key);
  };

  getFromDefault = () => {
    const {
      store: {
        EventStore: { newFromTime },
      },
    } = this.props;

    const selectedTime = moment(newFromTime, "HH:mm");
    let selectedHours = selectedTime.hours();
    const selectedMins = selectedTime.minutes();
    if (selectedHours > 12) {
      selectedHours = selectedHours - 12;
      if (selectedHours < 10) {
        return `0${selectedHours}:${selectedMins} PM`;
      } else {
        return `${selectedHours}:${selectedMins} PM`;
      }
    } else {
      if (selectedHours < 10) {
        return `0${selectedHours}:${selectedMins} AM`;
      } else {
        return `${selectedHours}:${selectedMins} AM`;
      }
    }
  };
  getToDefault = () => {
    const {
      store: {
        EventStore: { newToTime },
      },
    } = this.props;

    const selectedTime = moment(newToTime, "HH:mm");
    let selectedHours = selectedTime.hours();
    const selectedMins = selectedTime.minutes();
    if (selectedHours > 12) {
      selectedHours = selectedHours - 12;
      if (selectedHours < 10) {
        return `0${selectedHours}:${selectedMins} PM`;
      } else {
        return `${selectedHours}:${selectedMins} PM`;
      }
    } else {
      if (selectedHours < 10) {
        return `0${selectedHours}:${selectedMins} AM`;
      } else {
        return `${selectedHours}:${selectedMins} AM`;
      }
    }
  };

  addUserNotes = (eventUserId, value) => {
    const {
      store: {
        EventStore: { addUserNote },
      },
    } = this.props;
    addUserNote(eventUserId, value);
  };

  render() {
    const { event } = this.props;
    const {
      store: {
        EventStore: {
          editReferral,
          setSelectedEventId,
          newToTime,
          newFromTime,
          selectedEventId,
          newEligibleId,
          newRecurring,
          newEventDate,
          setShowDetails,
          eventUsers,
          fetchingEventUsers,
          eventUsersPagination,
          getPatientStatus,
          showDetails,
          deleteEvent,
          newGroupDesc,
          newOutReach,
          newZoomInfo,
          updatingEvent,
          updateEvent,
          rsvp,
          userNotes,
        },
        AuthStore: { userId: adminId },
        NotificationStore: { setConfirm },
        SiteStore: { allSites, getSiteNameFromAllSites },
      },
    } = this.props;

    const shouldExpand = event.id === selectedEventId && showDetails;
    const startHour = this.getTime(event.startHour, event.startMins);
    const endHour = this.getTime(event.endHour, event.endMins);
    const showEdited = event.id === selectedEventId && editReferral;

    const memberStatus = [
      { id: 1, name: "Not a Fit" },
      { id: 2, name: "UnConfirmed" },
      { id: 3, name: "Confirmed" },
    ];

    const statusMenu = (eventUserId) => {
      return (
        <div className="status-container">
          <Menu onClick={({ key }) => this.setUserStatus(eventUserId, key)}>
            {memberStatus.map((status) => (
              <Menu.Item key={status.id} icon={<UserOutlined />}>
                <div>{status.name}</div>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      );
    };

    const siteMenu = (
      <div className="eligibe-site-container">
        <Menu onClick={this.setNewEligibeSite}>
          {sortBy([...allSites.values()], ["name"]).map((site) => (
            <Menu.Item key={site.id} icon={<UserOutlined />}>
              <div>{site.name}</div>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );

    const columns = [
      {
        title: "Patient Name",
        dataIndex: "",
        render: (item) => (
          <div className="table-col">{item.eventUserInfo.fullName}</div>
        ),
      },
      {
        title: "Phone Number",
        dataIndex: "",
        render: (item) => (
          <div className="table-col">{getFormattedPhoneNumber(item.eventUserInfo.phoneNumber)}</div>
        ),
      },
      {
        title: "Email",
        dataIndex: "",
        render: (item) => (
          <div className="table-col">{item.eventUserInfo.email}</div>
        ),
      },
      {
        title: "Referral Source",
        dataIndex: "",
        render: (item) => (
          <div className="table-col">
            {item.eventUserInfo.referrerInfo.fullName}
          </div>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (status, item) => (
          <div id="member-status">
            <Dropdown
              getPopupContainer={() => document.getElementById("member-status")}
              disabled={!showEdited}
              trigger={["click"]}
              overlay={statusMenu(item.id)}
            >
              <Button>
                {getPatientStatus(status)}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        ),
      },
      {
        title: "Notes",
        key: "notes",
        dataIndex: "notes",
        render: (notes, item) =>
          showEdited ? (
            <div>
              <input
                placeholder="Type Reason"
                className="new-event-table-input"
                data-field="notes"
                defaultValue={toJS(userNotes)[item.id]}
                onChange={(e) => this.addUserNotes(item.id, e.target.value)}
              />
            </div>
          ) : (
            <div className="table-col">{notes ? notes : " - "}</div>
          ),
      },
    ];
    const dateToday = moment().format("YYYY-MM-DD");
    const isDateInValid = newEventDate < dateToday;
    const format = "HH:mm";
    const eventRSVP = rsvp.get(event.id);
    const rsvpNames = eventRSVP ? rsvp.get(event.id).names : null;
    const rsvpTotal = eventRSVP ? rsvp.get(event.id).total : null;
    const rsvpConfirmed = eventRSVP ? rsvp.get(event.id).confirmed : null;
    return (
      <div className="event" onClick={() => this.expandEvent(event.id)}>
        {updatingEvent ? (
          <div className="updating-event">
            <Spin />
          </div>
        ) : null}
        <div className="event-top">
          <div className="event-heading">
            <div className="group-name">{event.name}</div>
            {showEdited ? null : (
              <div className="group-name">
                {startHour} <span className="to">to</span> {endHour}
                {shouldExpand &&
                (event.facilitatorId === adminId || adminId === 11) ? (
                  <div className="edit-event">
                    <button
                      className="referral-form-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.editEventDetails(event.id, true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {showEdited ? (
            <div>
              <div className="disp-flex">
                <div className="label">Time</div>
                <div className="label-value">
                  <input
                    onChange={this.changeValue}
                    type="date"
                    value={newEventDate}
                    className="date-input"
                    data-field="newEventDate"
                  />
                  <span className="new-event-label"> from </span>
                  <Select
                    style={{ width: 128 }}
                    placeholder="select time"
                    showArrow={false}
                    defaultValue={this.getFromDefault()}
                    disabled={!newEventDate || isDateInValid}
                    onChange={this.changeNewFromTime}
                  >
                    {timesArr.map((item) => (
                      <Option key={item}>{item}</Option>
                    ))}
                  </Select>
                  {/* <TimePicker
                    format={format}
                    minuteStep={15}
                    use12Hours={true}
                    disabled={!newEventDate || isDateInValid}
                    defaultValue={moment(newFromTime, "HH:mm")}
                    disabledHours={this.getFromDisabledHours}
                    disabledMinutes={this.getFromDisabledMins}
                    hideDisabledOptions={true}
                    onChange={this.changeNewFromTime}
                  /> */}
                  <span className="new-event-label"> to </span>
                  <Select
                    style={{ width: 128 }}
                    placeholder="select time"
                    showArrow={false}
                    defaultValue={this.getToDefault()}
                    disabled={!newEventDate || isDateInValid}
                    onChange={this.changeNewToTime}
                  >
                    {timesArr.map((item) => (
                      <Option key={item}>{item}</Option>
                    ))}
                  </Select>
                  {/* <TimePicker
                    format={format}
                    use12Hours={true}
                    disabled={!newEventDate || isDateInValid}
                    defaultValue={moment(newToTime, "HH:mm")}
                    disabledHours={this.getFromDisabledHours}
                    disabledMinutes={this.getFromDisabledMins}
                    hideDisabledOptions={true}
                    minuteStep={15}
                    onChange={this.changeNewToTime}
                  /> */}
                </div>
              </div>
              {newEventDate && isDateInValid ? (
                <div className="disp-flex">
                  <div className="label"></div>
                  <div className="label-value">
                    <div className="warning">*Please Enter a Valid Date</div>
                  </div>
                </div>
              ) : null}
              <div className="disp-flex">
                <div className="label"></div>
                <div className="label-value">
                  <Checkbox
                    checked={newRecurring}
                    onChange={this.setNewRecurring}
                  >
                    <span>Recurring</span>
                  </Checkbox>
                </div>
              </div>
            </div>
          ) : null}
          <div className="disp-flex">
            <div className="label">Facilitator</div>
            <div className="label-value">{event.facilitatorInfo.fullName}</div>
          </div>
          {showEdited ? (
            <div className="disp-flex">
              <div className="label">Patients eligible</div>
              <div className="label-value">
                <div id="patients-eligible-in-event">
                  <Dropdown
                    getPopupContainer={() =>
                      document.getElementById("patients-eligible-in-event")
                    }
                    trigger={["click"]}
                    overlay={siteMenu}
                  >
                    <Button>
                      {newEligibleId
                        ? getSiteNameFromAllSites(newEligibleId)
                        : "select"}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
          ) : (
            <div className="disp-flex">
              <div className="label">Patients eligible</div>
              <div className="label-value">
                {getSiteNameFromAllSites(event.siteId)}
              </div>
            </div>
          )}
          <div className="disp-flex">
            <div className="label">Description</div>
            <div className="label-value">
              {showEdited ? (
                <input
                  placeholder="Description"
                  className="new-event-input"
                  value={newGroupDesc}
                  data-field="newGroupDesc"
                  onChange={this.changeValue}
                />
              ) : (
                event.description
              )}
            </div>
          </div>
          {shouldExpand ? (
            <div>
              <div className="disp-flex">
                <div className="label">Outreach Plan</div>
                <div className="label-value">
                  {showEdited ? (
                    <input
                      placeholder="send reminders 24hrs before"
                      value={newOutReach}
                      className="new-event-input"
                      data-field="newOutReach"
                      onChange={this.changeValue}
                    />
                  ) : event.outreach ? (
                    event.outreach
                  ) : (
                    " - "
                  )}
                </div>
              </div>

              <div className="disp-flex">
                <div className="label">Zoom Info</div>
                <div className="label-value">
                  {showEdited ? (
                    <input
                      data-field="newZoomInfo"
                      value={newZoomInfo}
                      onChange={this.changeValue}
                      placeholder="we'll email + text the zoom to all RSVPs at the start time."
                      className="new-event-input"
                    />
                  ) : (
                    <input
                      className="zoom-link"
                      readOnly
                      ref={(input) => (this.input = input)}
                      value={event.zoomInfo}
                    />
                  )}
                </div>
              </div>
              {showEdited ? null : (
                <div className="disp-flex">
                  <div className="label"></div>
                  <div className="label-value">
                    {/* <button className="referral-form-button">Details</button> */}
                    {document.queryCommandSupported("copy") && (
                      <span>
                        <button
                          className="referral-form-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.copyToClipboard(e);
                          }}
                        >
                          Copy
                        </button>
                        {this.state.copySuccess}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="disp-flex">
                <div className="label"></div>
                <div className="label-value">
                  Please send handouts to{" "}
                  <span className="zoom-link">support@marigoldhealth.com</span>
                </div>
              </div>
            </div>
          ) : null}
          {shouldExpand ? null : (
            <div className="disp-flex">
              <div className="label">RSVP</div>
              <div className="label-value">{rsvpNames ? rsvpNames : " - "}</div>
            </div>
          )}
        </div>
        <div className="event-bottom">
          <div className="count">
            <div>
              RSVP{" "}
              <span className="rsvp-count">
                {" "}
                {rsvpTotal ? rsvpTotal : " 0 "}
              </span>
            </div>
            <div className="count-right">
              Confirmed{" "}
              <span className="rsvp-confirm">
                {rsvpConfirmed ? rsvpConfirmed : " 0 "}
              </span>
            </div>
          </div>
          <div>
            <button
              className="new-referral-button"
              onClick={(e) => {
                e.stopPropagation();
                this.referPatient(event.id, true);
              }}
            >
              Refer a Patient
            </button>
          </div>
        </div>
        {shouldExpand ? null : (
          <div className="expand">
            <img
              className="expand-img"
              src={downArrow}
              onClick={(e) => {
                e.stopPropagation();
                this.expandEvent(event.id);
              }}
              alt="Close"
            />
          </div>
        )}
        {shouldExpand ? (
          eventUsers.length || fetchingEventUsers ? (
            <Table
              bordered
              rowKey="id"
              onChange={this.handleTableChange}
              dataSource={toJS(eventUsers)}
              pagination={false}
              loading={fetchingEventUsers}
              columns={columns}
            />
          ) : (
            <div className="no-event-members">
              There are no members in the group currently.
            </div>
          )
        ) : null}
        {shouldExpand ? (
          <div className="expand">
            <img
              className="expand-img"
              src={upArrow}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedEventId(null);
                this.editEventDetails(null, false);
                setShowDetails(false);
              }}
              alt="Close"
            />
          </div>
        ) : null}
        {shouldExpand && showEdited ? (
          <div className="event-edit-button">
            <div>
              <button
                className="confirm"
                onClick={(e) => {
                  e.stopPropagation();
                  updateEvent();
                }}
              >
                Save
              </button>
              <button
                className="exit"
                onClick={(e) => {
                  e.stopPropagation();
                  this.editEventDetails(null, false);
                }}
              >
                Cancel
              </button>
            </div>
            <button
              className="delete"
              onClick={() =>
                setConfirm(() => deleteEvent(), RSVP.deleteEventNotification)
              }
            >
              Delete Event
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default EventItem;
