import { cloneDeep } from "lodash";

const { REACT_APP_SYMMETRIC_KEY: SYMMETRIC_KEY } = process.env;

export const encryptData = (pubnub, data) => {
  return pubnub.encrypt(JSON.stringify(data), SYMMETRIC_KEY);
};
export const decryptData = (pubnub, data) => {
  let decryptedData = null;

  try {
    decryptedData = pubnub.decrypt(`${data}`, SYMMETRIC_KEY);
  } catch (e) {}

  return decryptedData !== null ? decryptedData : data;
};
const BLACKLIST = {
  pn_apns: true,
  pn_fcm: true,
};
const KEYS_TO_ENCRYPT = {
  text: true,
  sender: true,
  userId: true,
  userType: true,
  message: true,
  title: true,
  body: true,
  messageType: true,
  formId: true,
  groupName: true,
  waitingRoomName: true,
  data: true,
  type: true,
  channelName: true,
  actionType: true,
  messageId: true,
  entry: true,
  notificationType: true,
};
// OTHER KEYS PRESENT
// * storeInHistory
//
/**
 * Converts an object either to or from how it is stored in pubnub (eg. decrypt/encrypt)
 * 
 * @param {*} pubnub - the initialized pubnub object (used for it's encryption method)
 * @param {*} obj - the object that is to be processed for pubnub
 * @param {*} encrypt - a boolean.  if true, encrypt.  if false, decrypt.
 */
const traverseObject = (pubnub, obj, encrypt) => {
  Object.keys(obj).forEach((key) => {
    if (!BLACKLIST.hasOwnProperty(key)) {
      if (obj[key] && obj[key].constructor === Object) {
        traverseObject(pubnub, obj[key], encrypt);
      } else {
        if (KEYS_TO_ENCRYPT.hasOwnProperty(key)) {
          obj[key] = encrypt
            ? encryptData(pubnub, obj[key])
            : decryptData(pubnub, obj[key]);
        }
      }
    }
  });
};
export const encryptedPublish = (pubnub, data) => {
  const dataClone = cloneDeep(data);
  traverseObject(pubnub, dataClone, true);
  return dataClone;
};
export const decryptMessage = (pubnub, data) => {
  const dataClone = cloneDeep(data);
  traverseObject(pubnub, dataClone, false);
  return dataClone;
};
