import ApiService from "../utils/ApiService";
import NotificationStore from "../stores/NotificationStore";
import { reactions } from "../en.json";
import { types } from "mobx-state-tree";

const Reactions = types
  .model("Reactions", {
    map: types.map(types.number),
  })
  .actions((self) => ({
    async fetchAllReactions() {
      const limit = 50;
      let skip = 0;
      let params = {
        $skip: skip,
        $limit: limit,
      };
      let count = 0;
      do {
        count = await self.fetchReactions(params);
        skip += count;
        params = {
          $skip: skip,
          $limit: limit,
        };
      } while (count > 0 && count >= limit);
    },
    async fetchReactions(params) {
      let count = 0;
      const response = await ApiService.getRequest("reactions", params);

      if (response.success && response.data.length) {
        response.data.forEach((reaction) =>
          self.saveReaction(reaction.reaction, parseInt(reaction.id))
        );
        count = response.data.length;
      }
      return count;
    },
    saveReaction(name, id) {
      self.map.set(name, id);
    },
  }));

export default Reactions;
