import React, { useState } from "react";
import close from "../../static/images/close.png";
import { Select, Input, Radio, Spin } from "antd";
import { getFlagTypes } from "../../utils/getFlagTypes";
import NotificationStore from "../../stores/NotificationStore";

const Option = Select.Option;
const { TextArea } = Input;

export const FeedFlagModal = (props) => {
    const { onOk, onClose } = props;
    const [typeOfNeed, setTypeOfNeed] = useState(1);
    const [description, setDescription] = useState("")
    const [isAnonimityCompromised, setIsAnonimityCompromised] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const flagTypes = getFlagTypes();

    const onChangeAnonymity = () => {
        setIsAnonimityCompromised(!isAnonimityCompromised)
    };

    const onChaneTypeOfNeed = e => {
        setTypeOfNeed(e);
    };

    const onChangeDescription = e => {
        setDescription(e.target.value)
    };

    const onDone = async (e) => {
        if (description.length > 300){
            NotificationStore.setNotification("error", "Description cannot be more than 300 characters.");
            return;
        }
        setIsLoading(true);
        if (e.detail === 1) {
            await onOk({
                typeOfNeed,
                isAnonimityCompromised,
                description,
            });
        }
        setIsLoading(false);
    }

    return (
        <div>
        <div>
            <div className="confirm-backdrop" style={{ background: "rgba(0, 0, 0, 0.78)" }} onClick={onClose} />
            <div className="confirm-modal" style={{ left: "39%" }}>
            <span className="closeIcon" >
                <img src={close} onClick={onClose} alt="Close" />
            </span>
            <div className="modal-block">
                <span className="form-answer">Types of Need</span>
                <Select
                    className="flag-option-select"
                    placeholder="Select flag type"
                    value={typeOfNeed}
                    onChange={onChaneTypeOfNeed}
                >
                    {flagTypes.map((item) => (
                        <Option key={item} value={item.value}>{item.label}</Option>
                    ))}
                </Select>
            </div>
            <div className="modal-block">
                <span className="form-answer">Anonymity Compromised</span>
                <Radio.Group defaultValue={true} className="radio-block-flag" onChange={onChangeAnonymity}>
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
            </div>
            <div className="modal-block">
                <TextArea
                    placeholder="Description of the need identified."
                    onChange={onChangeDescription}
                />
            </div>
            <div className="modal-buttons">
                {
                    isLoading ?
                    <Spin />
                    : <button
                        className="confirm"
                        onClick={onDone}
                    >
                        Done
                    </button>
                }
            </div>
            </div>
        </div>
        </div>
    );
};
