import { types } from "mobx-state-tree";

const Question = types.model("Question", {
  id: types.identifierNumber,
  question: types.maybe(types.string),
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string)
});

export default Question;
