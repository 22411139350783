import { inject, observer } from "mobx-react";

import React from "react";
import { forgotPasswordForm } from "../../en.json";
import { Link } from "react-router-dom";

@inject("store")
@observer
class ForgotPassordForm extends React.Component {
  render() {
    const {
      store: {
        AuthStore: {
          setEmailInput,
          isEmailValid,
          loading,
          sendPasswordResetLink,
          userId
        }
      }
    } = this.props;

    return (
      <React.Fragment>
        <h3 className="pt-2">{forgotPasswordForm.enterEmail}</h3>
        <form
          onSubmit={e => {
            e.preventDefault();
            sendPasswordResetLink();
          }}
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="email"
              onChange={setEmailInput}
            />
          </div>
          <div>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={!isEmailValid || loading}
              style={{ position: "relative" }}
            >
              {loading && (
                <div
                  className="spinner-grow"
                  style={{
                    width: "16px",
                    height: "16px",
                    position: "absolute",
                    left: "35%",
                    top: "8px"
                  }}
                  role="status"
                >
                  <span className="sr-only">{forgotPasswordForm.loading}</span>
                </div>
              )}
              {forgotPasswordForm.sendPasswordLink}
            </button>
          </div>
          {!userId && 
            <Link to="/login">
              <span>{forgotPasswordForm.backToLogin}</span>
            </Link>
          }
        </form>
      </React.Fragment>
    );
  }
}
export default ForgotPassordForm;
