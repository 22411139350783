import LinkifyIt from "linkify-it";

const chatLinkify = new LinkifyIt();
const forumsLinkify = new LinkifyIt();

const hashValidatorForLinkify = {
  validate: function (text, pos) {
    const tail = text.slice(pos);
    const hashRegex = /^[A-Za-z0-9]+/;
    const match = tail.match(hashRegex);
    return match && match.length ? match[0].length : 0;
  },
};

chatLinkify.add("#", hashValidatorForLinkify);

forumsLinkify.add("@", {
  validate: function (text, pos, self) {
    const tail = text.slice(pos);
 
    if (!self.re.twitter) {
      self.re.twitter =  new RegExp(
        '^([a-zA-Z0-9_]){1,15}(?!_)(?=$|' + self.re.src_ZPCc + ')'
      );
    }
    if (self.re.twitter.test(tail)) {
      if (pos >= 2 && tail[pos - 2] === '@') {
        return false;
      }
      return tail.match(self.re.twitter)[0].length;
    }
    return 0;
  },
  normalize: function (match) {
    match.url = match.url.replace(/^@/, "");
  },
});

const getLinkifyMatchDecorator = (usernameToUserIdMap) => {
  if (!usernameToUserIdMap) {
    return forumsLinkify;
  }

  chatLinkify.add('@', {
    validate: function (text, pos) {
      const tail = text.slice(pos);
      const username = tail.split('@')[0].trim();

      return usernameToUserIdMap.has(username)
        ? username.length
        : usernameToUserIdMap.has(tail.split(' ')[0].trim())
          ? tail.split(' ')[0].trim().length
          : 0;
    },
    normalize: function (match) {
      match.url = match.url.replace(/^@/, '');
    }
  });

  return chatLinkify;
}

export default (text, usernameToUserIdMap) => {
  const linkifyMatchDecorator = getLinkifyMatchDecorator(usernameToUserIdMap);
  return linkifyMatchDecorator.match(text);
};
