import { types } from "mobx-state-tree";

const Form = types
  .model("Form", {
    completedAt: types.maybeNull(types.string),
    createdAt: types.maybe(types.string),
    form: types.frozen({
      id: types.maybe(types.number),
      name: types.maybe(types.string),
      minutesNeededToComplete: types.maybe(types.number),
      createdAt: types.maybe(types.string),
      updatedAt: types.maybe(types.string)
    }),
    formId: types.maybe(types.number),
    id: types.maybeNull(types.number),
    isSubmitted: types.maybeNull(types.boolean),
    messageId: types.maybe(types.string),
    submittedFrom: types.maybe(types.string),
    submittedFromId: types.maybe(types.number),
    updatedAt: types.maybe(types.string),
    userId: types.maybe(types.number),
    user: types.frozen({
      id: types.maybe(types.number),
      username: types.maybe(types.string),
      fullName: types.maybeNull(types.string),
    }),
    totalScore: types.maybeNull(types.number),
    reviewedAt: types.maybeNull(types.string),
    reviewedBy: types.maybeNull(types.number),
    submittedBy: types.maybeNull(types.number),
    reviewedUserDetails: types.optional(types.frozen({
      id: types.maybeNull(types.string),
      username: types.maybeNull(types.string),
      fullName: types.maybeNull(types.string),
    })),
    submittedFromDetails: types.optional(types.frozen({
      id: types.maybeNull(types.string),
      username: types.maybeNull(types.string),
      fullName: types.maybeNull(types.string),
    })),
    isUserFormEdited: types.maybeNull(types.boolean),
    lastEditedBy: types.maybeNull(types.string),
    lastEditedAt: types.maybeNull(types.string),
  })
  .volatile(self => ({
    status: null
  }));

export default Form;
