import { Icon, Popover, Spin } from "antd";
import { alertLeftSide, user } from "../../en.json";
import { inject, observer } from "mobx-react";

import ContentList from "./ContentList";
import InfiniteScroll from "react-infinite-scroller";
import { IS_HP } from "../../utils/getEnvironment";
import React from "react";
import { CustomModal } from "../UI/CustomModal";
import _ from "lodash";
import moment from "moment";
import info from "../../static/images/info.png";

@inject("store")
@observer
class UserFlags extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
    };
  }
  componentDidMount() {
    const {
      store: {
        FlaggedMessagesStore: { fetchUserFlags, clearUserFlags },
      },
    } = this.props;
    clearUserFlags();
    fetchUserFlags({ isResolved: false });
  }

  changeModalVisible = (value) => {
    this.setState({
      visible: value,
    });
  };

  getAssignedUsernames = (id) => {
    const {
      store: {
        FlaggedMessagesStore: { assignedAlerts },
      },
    } = this.props;
    const usernames = assignedAlerts.get(id);
    let usernameString = "";
    if (usernames.length) {
      usernameString = usernames.join(", ");
    }
    return usernameString.length ? usernameString : null;
  };

  usernamePress = async (sender) => {
    const {
      store: {
        ProfileStore: {
          setProfileVisibility,
          setMemberData,
          toggleLabel,
          getHighPointUsers,
          getProviderModeratedGroups,
          getContactModeratedGroups,
          fetchGroupsFromForms,
          reset,
        },
        NotificationStore,
        MemberListStore: { getMemberDetails },
      },
    } = this.props;
    reset();
    setProfileVisibility(true);
    const member = await getMemberDetails(sender);
    if (member) {
      setMemberData(member);
      if (IS_HP) {
        getHighPointUsers();
        if (member.providerId) {
          getProviderModeratedGroups(member.providerId);
        }
        if (member.recommendedBy) {
          getContactModeratedGroups(member.recommendedBy);
        }
        fetchGroupsFromForms();
      }
      toggleLabel(false);
    } else {
      NotificationStore.setNotification("error", user.userDoesNotExist);
    }
  };

  fetchAlertHistory = (alertId, groupName) => {
    const {
      store: {
        HistoryStore: { fetchMessageHistory },
      },
    } = this.props;

    fetchMessageHistory(alertId, groupName);
    this.changeModalVisible(true);
  };

  loadMoreUserFlaggedMessages = () => {
    const {
      store: {
        FlaggedMessagesStore: {
          fetchUserFlags,
          endOfUserUnresolvedFlaggedMessageList,
        },
      },
    } = this.props;

    endOfUserUnresolvedFlaggedMessageList
      ? fetchUserFlags({ isResolved: true })
      : fetchUserFlags({ isResolved: false });
  };

  renderPopOverContent(arr, message) {
    const { messageList } = this.props;
    return arr.map((item, index) => (
      <ContentList
        key={index}
        name={item}
        usernamePress={() => this.usernamePress(message.senderId)}
        message={message}
        messageList={messageList}
      />
    ));
  }

  toggleResolveStatus(message, flag) {
    const {
      store: {
        FlaggedMessagesStore: { changeUserFlagResolveStatus },
      },
    } = this.props;
    changeUserFlagResolveStatus(_.cloneDeep(message), flag);
  }

  changeChannel = (id, name, flag, timeToken) => {
    const {
      store: {
        MessagesStore: { loadChannelMessages, selectedGroup },
        GroupStore: { fetchGroupUsers },
      },
      messageList,
    } = this.props;
    const type = flag ? "group" : "dm";
    loadChannelMessages(
      id,
      name,
      type,
      timeToken,
      this.scrollToTop,
      messageList
    );
    selectedGroup.setFlaggedMessageTimeToken(timeToken);
    flag && fetchGroupUsers(id);
  };

  scrollToTop = (prevScrollHeight) => {
    const { messageList } = this.props;
    messageList.current.scrollTop =
      messageList.current.scrollHeight -
      prevScrollHeight +
      messageList.current.scrollTop;
  };

  handleClick = (message) => {
    const {
      store: {
        ProfileStore: {
          changeSelectedAction,
          setMemberProfileVisibility,
          toggleEdit,
          toggleFlag,
        },
      },
    } = this.props;
    changeSelectedAction("View in context");
    const groupType = message.channel.split("_")[0];
    const groupId = message.channel.split("_")[2];
    const flag = groupType === "GROUP" ? 1 : 0;
    this.changeChannel(
      parseInt(groupId),
      message.name,
      flag,
      message.messageId
    );
    setMemberProfileVisibility(false);
    toggleEdit("editClinical", false);
    toggleEdit("editBio", false);
    toggleFlag(false);
  };

  loadMoreAlertHistory = () => {
    const {
      store: {
        HistoryStore: {
          selectedAlertId,
          selectedGroupName,
          fetchMessageHistory,
          historyLoading,
        },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchMessageHistory(selectedAlertId, selectedGroupName);
  };

  render() {
    const {
      store: {
        FlaggedMessagesStore: {
          userUnresolvedFlaggedMessages,
          endOfUserUnresolvedFlaggedMessageList,
          selectedMessageId,
          resolveLoader,
          userResolvedFlaggedMessages,
          endOfUserResolvedFlaggedMessageList,
          userFlagsLoading,
          userUnResolvedFlagedMessagesTotal,
          usernames,
          assignedAlerts,
          userResolvedFlagedMessagesTotal,
        },
        HistoryStore: {
          messageHistory,
          historyLoading,
          totalMessageHistory,
          skipInMessageHistory,
          resetHistory,
        },
        ProfileStore: { setSelectedAlert },
      },
    } = this.props;
    const arr = ["Assign to", "Suspend account"];
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    return (
      <div className="group-chat">
        <CustomModal
          title="Message History"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={() => {
            resetHistory();
            this.changeModalVisible(false);
          }}
          loadMore={this.loadMoreAlertHistory}
          hasMore={skipInMessageHistory < totalMessageHistory}
          loading={historyLoading}
          data={messageHistory ? messageHistory : []}
        />
        <div style={{ height: "calc(100vh - 290px)", overflow: "auto" }}>
          <InfiniteScroll
            loadMore={this.loadMoreUserFlaggedMessages}
            useWindow={false}
            hasMore={
              !userFlagsLoading &&
              !endOfUserResolvedFlaggedMessageList &&
              !userUnresolvedFlaggedMessages.length +
                userResolvedFlaggedMessages.length <=
                userUnResolvedFlagedMessagesTotal +
                  userResolvedFlagedMessagesTotal
            }
          >
            {userUnresolvedFlaggedMessages.map((message, index) => {
              return message.senderId && message.userId ? (
                <div key={message.id}>
                  <div className="flag-container">
                    <div className="flag-upper">
                      <div className="flag-name-container">
                        <div>
                          <span className="flag-name">{message.sender}</span>
                        </div>
                        {/* <div
                          onClick={() => this.usernamePress(message.senderId)}
                          className="flag-profile-container"
                        >
                          <span className="flag-profile">
                            {alertLeftSide.profile}
                          </span>
                        </div> */}
                      </div>
                      <div className="flag-time">
                        {moment(message.createdAt).calendar()}
                      </div>
                    </div>
                    {message.name ? (
                      <div className="info-icon-container">
                        <div className="flag-group">
                          {`in ${
                            message.name.length > 30
                              ? `${message.name.slice(0, 30)}...`
                              : message.name
                          }`}
                        </div>

                        <img
                          src={info}
                          style={{ height: "20px", width: "20px" }}
                          alt="info"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAlert(message);
                            this.fetchAlertHistory(message.id, message.name);
                          }}
                        />
                      </div>
                    ) : null}
                    <div className="flag-middle">
                      {message.type === "text" ? (
                        <div className="flag-message-container">
                          <div className="heading">{message.text}</div>
                        </div>
                      ) : message.type === "img" || message.type === "gif" ? (
                        <img
                          src={message.imgUrl}
                          alt=""
                          height="60"
                          width="60"
                        />
                      ) : message.type === "form" ? (
                        <span className="heading">{alertLeftSide.form}</span>
                      ) : (
                        <span className="heading"></span>
                      )}
                      <br />
                      {usernames.has(message.userId) ? (
                        <span className="subHeading">
                          {`Flagged by ${usernames.get(message.userId)}`}
                        </span>
                      ) : null}
                      {assignedAlerts.has(message.id) ? (
                        <div className="subHeading">
                          {`Assigned to ${this.getAssignedUsernames(
                            message.id
                          )}`}
                        </div>
                      ) : null}
                    </div>
                    <div className="flag-lower">
                      <div className="flag-buttons">
                        <button
                          className="flag-message-button"
                          type="button"
                          onClick={() => this.handleClick(message)}
                        >
                          View In Context
                        </button>
                        {selectedMessageId === message.id && resolveLoader ? (
                          <div className="flag-resolve-button">
                            <Spin indicator={antIcon} />
                          </div>
                        ) : (
                          <button
                            className="flag-resolve-button"
                            type="button"
                            onClick={() =>
                              this.toggleResolveStatus(message, true)
                            }
                          >
                            {alertLeftSide.resolve}
                          </button>
                        )}
                      </div>
                      <div
                        className="flag-div"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedAlert(message);
                        }}
                      >
                        <Popover
                          content={this.renderPopOverContent(arr, message)}
                          placement="right"
                          trigger="click"
                        >
                          <Icon
                            className="flag-icon"
                            type="more"
                            style={{ fontSize: 30 }}
                          />
                        </Popover>
                      </div>
                    </div>
                  </div>
                  <hr className="horizontal-tag" />
                </div>
              ) : null;
            })}
            {userFlagsLoading && (
              <div className="loading-alert-members">
                <Spin />
              </div>
            )}
            {endOfUserUnresolvedFlaggedMessageList &&
              userResolvedFlagedMessagesTotal > 0 && (
                <div className="approved-users">
                  {alertLeftSide.resolvedFlaggedMessages}
                </div>
              )}

            {userResolvedFlaggedMessages.map((message) => {
              return message.senderId && message.userId ? (
                <div key={message.id}>
                  <div className="flag-container">
                    <div className="flag-upper">
                      <div className="flag-name-container">
                        <div>
                          <span className="flag-name">{message.sender}</span>
                        </div>
                        {/* <div>
                        <span className="flag-profile">Profile</span>
                      </div> */}
                      </div>
                      <div className="flag-time">
                        {moment(message.createdAt).calendar()}
                      </div>
                    </div>
                    {message.name ? (
                      <div className="info-icon-container">
                        <div className="flag-group">
                          {`in ${
                            message.name.length > 30
                              ? `${message.name.slice(0, 30)}...`
                              : message.name
                          }`}
                        </div>

                        <img
                          src={info}
                          style={{ height: "20px", width: "20px" }}
                          alt="info"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAlert(message);
                            this.fetchAlertHistory(message.id, message.name);
                          }}
                        />
                      </div>
                    ) : null}
                    <div className="flag-middle">
                      {message.type === "text" ? (
                        <span className="heading">{message.text}</span>
                      ) : message.type === "img" || message.type === "gif" ? (
                        <img
                          src={message.imgUrl}
                          alt=""
                          height="60"
                          width="60"
                        />
                      ) : message.type === "form" ? (
                        <span className="heading">{alertLeftSide.form}</span>
                      ) : (
                        <span className="heading"></span>
                      )}
                      <br />
                      {usernames.has(message.userId) ? (
                        <span className="subHeading">
                          {`Flagged by ${usernames.get(message.userId)}`}
                        </span>
                      ) : null}
                      {assignedAlerts.has(message.id) ? (
                        <div className="subHeading">
                          {`Assigned to ${this.getAssignedUsernames(
                            message.id
                          )}`}
                        </div>
                      ) : null}
                      {usernames.has(message.resolvedBy) ? (
                        <div className="subHeading">
                          {`Resolved by ${usernames.get(message.resolvedBy)}`}
                        </div>
                      ) : null}
                    </div>
                    <div className="flag-lower">
                      <div className="flag-buttons">
                        <button
                          className="flag-message-button"
                          type="button"
                          onClick={() => this.handleClick(message)}
                        >
                          View In Context
                        </button>
                        {selectedMessageId === message.id && resolveLoader ? (
                          <div className="flag-resolve-button">
                            <Spin indicator={antIcon} />
                          </div>
                        ) : (
                          <button
                            className="flag-resolve-button"
                            type="button"
                            onClick={() =>
                              this.toggleResolveStatus(message, false)
                            }
                          >
                            {alertLeftSide.unresolve}
                          </button>
                        )}
                      </div>
                      <div className="flag-div">
                        <Popover
                          content={this.renderPopOverContent(arr, message)}
                          placement="right"
                        >
                          <Icon
                            className="flag-icon"
                            type="more"
                            style={{ fontSize: 30 }}
                          />
                        </Popover>
                      </div>
                      ,
                    </div>
                  </div>
                  <hr className="horizontal-tag" />
                </div>
              ) : null;
            })}
            {/* {userUnResolvedFlagedMessagesTotal +
              userResolvedFlagedMessagesTotal ==
            0 ? (
              <div className="no-user-flags">No Flagged Messages</div>
            ) : null} */}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}
export default UserFlags;
