import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Tooltip } from "antd";
import { IMG_MAXIMIZE_AREA, IMG_MINIMIZE_AREA } from "../../utils/ImageUtils";
import { toolTips } from "../../en.json";
import { MAX_ROWS_FOR_TEXT_AREA } from "../../constants/GlobalConstant";

const ExpandableTextArea = ({
  expandIconClassName = "",
  defaultTextAreaRows = 1,
  maxTextAreaRows = MAX_ROWS_FOR_TEXT_AREA,
  textAreaRef,
  ...textAreaProps
}, ref) => {
  const [rowsForTextArea, setRowsForTextArea] = useState(defaultTextAreaRows);
  const [isTextAreaExpanded, setIsTextAreaExpanded] = useState(false);

  const expandTextArea = () => {
    setRowsForTextArea(maxTextAreaRows);
    setIsTextAreaExpanded(true);
  };

  const contractTextArea = () => {
    setRowsForTextArea(defaultTextAreaRows);
    setIsTextAreaExpanded(false);
  };

  useImperativeHandle(ref, () => ({
    contractTextArea
  }));

  const onKeyDown = (e, ...args) => {
    if (e.shiftKey && e.key === "Enter") {
      if (rowsForTextArea >= defaultTextAreaRows && rowsForTextArea < maxTextAreaRows) {
        const updatedRowsForTextArea = rowsForTextArea + 1;

        setRowsForTextArea(updatedRowsForTextArea);
        setIsTextAreaExpanded(updatedRowsForTextArea === maxTextAreaRows);
      }
    }

    if (textAreaProps.onKeyDown) {
      textAreaProps.onKeyDown(e, ...args);
    }
  };

  return (
    <>
      <textarea
        {...textAreaProps}
        rows={rowsForTextArea}
        onKeyDown={onKeyDown}
        ref={textAreaRef}
      />

      <Tooltip
        title={isTextAreaExpanded ? toolTips.minimizeChatArea : toolTips.maximizeChatArea}
        placement="top">
        <img
          src={isTextAreaExpanded ? IMG_MINIMIZE_AREA : IMG_MAXIMIZE_AREA}
          className={expandIconClassName}
          style={{
            cursor: "pointer",
            height: "25px",
            width: "25px",
          }}
          alt="chat-expand"
          onClick={isTextAreaExpanded ? contractTextArea : expandTextArea}
        />
      </Tooltip>
    </>
  );
};

export default forwardRef(ExpandableTextArea);
