import { Dropdown, Menu, Spin, Tooltip } from "antd";
import Select, { components } from "react-select";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import React from "react";
import { sortBy } from "lodash";
import logo from "../static/images/logo.png";
import sites from "../static/sites.json";
import template from "../utils/templates";
import { IS_COMM, IS_HP, IS_STAGING } from "../utils/getEnvironment";
import { toolTips } from "../en.json";
import {
  IMG_CHATS,
  IMG_DASHBOARD,
  IMG_DROPDOWN_ARROW,
  IMG_FLAG_DISABLED,
  IMG_GROUPS,
  IMG_INBOX,
  IMG_MEMBERS,
  IMG_NOTIFICATION,
  IMG_QUEUE,
  IMG_SEARCH,
} from "../utils/ImageUtils";

@withRouter
@inject("store")
@observer
class Header extends React.Component {
  closeAll = () => {
    const {
      match: {
        params: { view },
      },
      store: {
        ProfileStore: { reset },
        MessagesStore: {
          resetSelectedGroup,
          setSession,
          selectedGroup: { setName },
        },

        ReferralStore: { resetAll },
        EventStore: { resetAllEvents, setNewEvent },
        GroupListStore: {
          resetNewGroup,
          showFlags,
          resetSelectedGroup: resetGroup,
        },
        FormMessageStore: { changeformsVisible },
        DeletedMessagesStore: {
          setShowUnDeleted,
          setShowUnFlagged,
          setShowDeleted,
          setShowGroupDeleted,
          setShowGroupUnDeleted,
        },
        DeletedForumItemListStore: { reset: resetDeletedForumItemListStore }
      },
    } = this.props;
    reset();
    setName("");
    resetGroup();
    setSession(false);
    resetAll();
    setShowUnDeleted(false);
    resetNewGroup(false);
    setNewEvent(false);
    setShowDeleted(false);
    setShowUnFlagged(false);
    changeformsVisible(false);
    setShowGroupDeleted(false);
    setShowGroupUnDeleted(false);
    showFlags(false);
    resetDeletedForumItemListStore()
    if (view !== "events") {
      resetAllEvents();
    }
    resetSelectedGroup();
  };

  getSiteById = async (key) => {
    const {
      match: {
        params: { view },
      },
      store: {
        SiteStore: { getSiteById },
      },
    } = this.props;
    const siteId = Number(key);
    getSiteById(siteId, view);
  };

  fetchAllHistory = () => {
    const {
      store: {
        HistoryStore: { fetchAllHistory, setShowAllActivities },
      },
    } = this.props;
    fetchAllHistory();
    setShowAllActivities(true);
  };

  fetchAllNotifications = () => {
    const {
      store: {
        HistoryStore: { setShowAllNotifications },
        MessagesStore: { resetMessageRedirectionData }
      },
    } = this.props;
    resetMessageRedirectionData();
    setShowAllNotifications(true);
  };

  switchChat = () => {
    const {
      store: {
        MessagesStore: { resetSelectedGroup },
      },
    } = this.props;

    resetSelectedGroup();
  };

  render() {
    const {
      match: {
        params: { view },
      },
      store: {
        AuthStore: { username, type, logout },
        SiteStore: {
          allSites,
          selectedSiteId,
          selectedSiteName,
          loading,
          getTerritoryNames,
          getSiteIdForTerritory,
          getHeaderDropdownNameForTerritory,
        },
        MessagesStore: {
          unreadMessages,
          setIsWaiting,
          selectedGroup: { name },
        },
        MemberListStore: { unreadWaitingMessages, newAccountsTotal },
        FlaggedMessagesStore: { unresolvedTotal },
        ReferralStore: {
          isDataSaved,
          setIntakeModalVisible,
          setReferralModalVisible,
          selfIntake,
          showReferralForm,
        },
        NotificationStore: {
          dmNotifications,
          groupNotifications,
          otherNotifications,
        }
      },
    } = this.props;

    const territories = getTerritoryNames();

    const territoryOptions = territories.map(territory => ({
      id: getSiteIdForTerritory(territory),
      name: getHeaderDropdownNameForTerritory(territory)
    }));

    const userType = type;
    const isAdmin = userType === "moderator";
    const isNA = userType === "NOA";
    const isSuperAdmin = userType === "SA";
    const isFrontDesk = userType === "FD";
    const defaultSiteOptions = [
      {
        id: -1,
        name: template.header.allTerritories,
      },
      ...territoryOptions,
      {
        id: 0,
        name: template.header.myGroups,
      },
      ...sortBy([...allSites.values()], ["name"])
    ];

    const options = defaultSiteOptions.map(option => ({
      label: option.name,
      value: option.id.toString()
    }))
    const defaultValue = (selectedSiteId && options.find(o => o.value === selectedSiteId?.toString())) || options[0];

    const ValueContainer = ({ children, ...props }) => {
      return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            <div className="d-flex align-items-center">
              {!!children && (
                  <img src={IMG_SEARCH} style={{ width: 24, height: 24, margin: "0 5px 7px 0" }} alt="search"/>
              )}
              {children}
            </div>
          </components.ValueContainer>
        )
      );
    };

    const Control = ({ children, ...props }) => {
      return (
        components.Control && (
          <components.Control {...props}>
            <div style={{ display: "flex" }}>
              {children}
            </div>
          </components.Control>
        )
      );
    };

    const IndicatorSeparator = () => null;

    const DropdownIndicator = ({ children, ...props }) => {
      return (
          components.DropdownIndicator && (
              <components.DropdownIndicator {...props}>
                <img src={IMG_DROPDOWN_ARROW} style={{ width: 14, height: 7 }} alt="dropdown-arrow"/>
              </components.DropdownIndicator>
          )
      );
    }

    const menu = (
      loading ? (
        <div>
          <Spin />
        </div>
      ) : (
        <Select
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral80: "#0b0b0b"
            }
          })}
          components={{ ValueContainer, Control, DropdownIndicator, IndicatorSeparator }}
          styles={{
            control: base => ({
              ...base,
              minWidth: "100%",
              border: 0,
              boxShadow: "none",
              maxWidth: "200px",
              width: "max-content"
            }),
            menu: base => ({
              ...base,
              width: "max-content",
              minWidth: "100%"
            }),
            valueContainer: base => ({
              ...base,
              paddingLeft: 0,
              paddingRight: 0
            }),
            dropdownIndicator: base => ({
              ...base,
              padding: "12px 0px",
            }),
            indicatorsContainer: base => ({
              ...base,
              height: "max-content",
              paddingTop: 5.5,
            }),
          }}
          className="nav-item-select"
          options={options}
          isOptionDisabled={option => option.value === selectedSiteId?.toString()}
          defaultValue={defaultValue}
          onChange={e => this.getSiteById(e.value)}
        >
        </Select>
      )
    );
    const notificationCount = dmNotifications.length + groupNotifications.length + otherNotifications.length;
    const alertNumber =
      (isNaN(unresolvedTotal) || isFrontDesk)
        ? null
        : unresolvedTotal;
    const cantSwitchReferral = showReferralForm && !isDataSaved;
    const cantSwitchIntake = selfIntake && !isDataSaved;
    return (
      <div ref={this.props.navBarRef}>
        <header>
          <div className="my-navbar">
            <button className="my-nav-btn left" onClick={() => logout()}>
              Logout
            </button>
            <div className="innerlogo">
              <div className="logo-container">
                {" "}
                {isAdmin || isSuperAdmin || isNA ? (
                  <Link
                    to={
                      (cantSwitchReferral || cantSwitchIntake) ? "/CBRS" : "/members"
                    }
                    className="navbar-brand"
                    style={{ margin: 0, padding: 0 }}
                  >
                    <img src={logo} alt="Logo" />
                  </Link>
                ) : (
                  <div>
                    <img src={logo} alt="Logo" />
                  </div>
                )}
                {isAdmin || isSuperAdmin ? (
                  <Tooltip title={toolTips.sites} placement="leftBottom">
                    <div>{menu}</div>
                  </Tooltip>
                ) : isNA ? (
                  <div className="nav-item">{selectedSiteName}</div>
                ) : null}
              </div>
            </div>
            <button
              className="my-nav-btn right"
              disabled={!name}
              onClick={this.switchChat}
            >
              Switch Chat
            </button>

            <div className="d-flex">
              <ul className="navbar-nav flex-row">

                {!isFrontDesk ? (
                    <Link
                        to={
                          (cantSwitchReferral || cantSwitchIntake) ? "/CBRS" : "/chat"
                        }
                        className="hide-content"
                        onClick={() => {
                          if (cantSwitchReferral) {
                            setReferralModalVisible(true);
                          } else if (cantSwitchIntake) {
                            setIntakeModalVisible(true);
                          } else {
                            this.closeAll();
                            setIsWaiting(false);
                          }
                        }}
                    >
                      <li
                          className={
                              "nav-item " +
                              (view === "chat" ? "nav-item-active " : "") +
                              (unreadMessages.length > 0 ? " unread-messages" : "")
                          }
                      >
                        <Tooltip title={`${toolTips.chats} ${selectedSiteName}`} className="d-flex align-items-center">
                          <IMG_CHATS className="flag-needs-icon" />
                          {template.header.chats}
                        </Tooltip> {/*chats*/}
                      </li>
                    </Link>
                ) : null}

                {isAdmin || isSuperAdmin ? (
                    <Link
                        to={
                          (cantSwitchReferral || cantSwitchIntake)
                              ? "/CBRS"
                              : "/waiting"
                        }
                        onClick={() => {
                          if (cantSwitchReferral) {
                            setReferralModalVisible(true);
                          } else if (cantSwitchIntake) {
                            setIntakeModalVisible(true);
                          } else {
                            this.closeAll();
                            setIsWaiting(false);
                          }
                        }}
                    >
                      <li
                          className={
                              "nav-item " +
                              (view === "waiting" ? "nav-item-active " : "") +
                              (unreadWaitingMessages.length > 0
                                  ? " unread-messages"
                                  : "")
                          }
                      >
                        <Tooltip
                            title={toolTips.queue}
                            placement="bottomRight"
                            className="d-flex align-items-center"
                        >
                          <IMG_QUEUE className="flag-needs-icon" />
                          {template.header.queue}
                        </Tooltip>
                      </li>
                    </Link>
                ) : null}

                {(isAdmin || isSuperAdmin) ? (
                  <Link
                    to="flagNeeds"
                  >
                    <li
                      className={
                        "nav-item " + (view === "flagNeeds" ? "nav-item-active " : "")
                      }
                      style={{ minWidth: "99px" }}
                    >
                      <Tooltip title={`${toolTips.flagNeeds}`} className="d-flex align-items-center">
                        <IMG_FLAG_DISABLED className="flag-needs-icon" />
                        {template.header.adminFlags}
                      </Tooltip>
                    </li>
                  </Link>
                ) : null}

                {isAdmin || isSuperAdmin || isFrontDesk ? (
                    <li>
                      <div className={"alert-container " + (view === "alert" ? "nav-item-active " : "")}>
                        <Link
                            to={
                              (cantSwitchReferral || cantSwitchIntake) ? "/CBRS" : "/alert"
                            }
                            onClick={() => {
                              if (cantSwitchReferral) {
                                setReferralModalVisible(true);
                              } else if (cantSwitchIntake) {
                                setIntakeModalVisible(true);
                              } else {
                                this.closeAll();
                                setIsWaiting(false);
                              }
                            }}
                        >
                          <Tooltip title={toolTips.alert} className="d-flex align-items-center">
                            <div className={
                                "nav-item " + (view === "alert" ? "nav-item-active " : "")
                            } style={{ minWidth: "83px" }}>
                              <IMG_FLAG_DISABLED className="flag-needs-icon" />
                              {template.header.memberFlags}
                            </div>
                          </Tooltip>
                        </Link>
                        <small className={alertNumber ? "alert-count" : ""}>
                          {alertNumber ? alertNumber : null}
                        </small>
                      </div>
                    </li>
                ) : null}

                {isAdmin || isSuperAdmin ? (
                    <Link to="/stats">
                      <li className={"nav-item " + (view === "stats" ? "nav-item-active " : "")}>
                        <Tooltip title={`${toolTips.dashboard}`} className="d-flex align-items-center">
                          <IMG_DASHBOARD className="flag-needs-icon" />
                          {template.header.stats} {/* dashboard */}
                        </Tooltip>
                      </li>
                    </Link>
                ) : null}

                {(isAdmin || isSuperAdmin) ? (
                    <Link to="/inbox" className="d-flex align-items-center">
                      <li className={"nav-item " + (view === "inbox" ? "nav-item-active " : "")}>
                        <Tooltip title={`${toolTips.inbox}`} className="d-flex align-items-center">
                          <IMG_INBOX className="flag-needs-icon" />
                          {template.header.inbox}
                        </Tooltip>
                      </li>
                    </Link>
                ) : null}

                {isAdmin || isSuperAdmin || isNA ? (
                  <Link
                    to={
                      (cantSwitchReferral || cantSwitchIntake)
                        ? "/CBRS"
                        : "/members"
                    }
                    onClick={() => {
                      if (cantSwitchReferral) {
                        setReferralModalVisible(true);
                      } else if (cantSwitchIntake) {
                        setIntakeModalVisible(true);
                      } else {
                        this.closeAll();
                        setIsWaiting(false);
                      }
                    }}
                  >
                    <li
                      className={
                        "nav-item " + (view === "members" ? "nav-item-active " : "")
                      }
                    >
                      <Tooltip title={`${toolTips.members} ${selectedSiteName}`} className="d-flex align-items-center">
                        <IMG_MEMBERS className="flag-needs-icon" />
                        <span id="member-link">{template.header.members}</span>
                      </Tooltip>
                    </li>
                  </Link>
                ) : null}

                {(IS_HP || IS_STAGING) && (isAdmin || isNA || isSuperAdmin) ? (
                  <Link
                    className="d-flex align-items-center"
                    to={
                      (cantSwitchReferral || cantSwitchIntake) ? "/CBRS" : "/events"
                    }
                    onClick={() => {
                      if (cantSwitchReferral) {
                        setReferralModalVisible(true);
                      } else if (cantSwitchIntake) {
                        setIntakeModalVisible(true);
                      } else {
                        this.closeAll();
                        setIsWaiting(false);
                      }
                    }}
                  >
                    <IMG_GROUPS className="flag-needs-icon" />
                    <li
                      className={
                        "nav-item " + (view === "events" ? "nav-item-active " : "")
                      }
                    >
                      Zoom Groups
                    </li>
                  </Link>
                ) : null}

                {(IS_HP || IS_STAGING) && (isAdmin || isNA || isSuperAdmin) ? (
                  <Link
                    to="/CBRS"
                    onClick={() => {
                      if (cantSwitchReferral) {
                        setReferralModalVisible(true);
                      } else if (cantSwitchIntake) {
                        setIntakeModalVisible(true);
                      } else {
                        this.closeAll();
                        setIsWaiting(false);
                      }
                    }}
                  >
                    <li
                      className={"nav-item " + (view === "CBRS" ? "nav-item-active " : "")}
                    >
                      CBRS Referral
                    </li>
                  </Link>
                ) : null}

                {isAdmin || isSuperAdmin ? (
                  <Link
                    className="d-flex align-items-center"
                    to={
                      (cantSwitchReferral || cantSwitchIntake) ? "/CBRS" : "/groups"
                    }
                    onClick={() => {
                      if (cantSwitchReferral) {
                        setReferralModalVisible(true);
                      } else if (cantSwitchIntake) {
                        setIntakeModalVisible(true);
                      } else {
                        this.closeAll();
                        setIsWaiting(false);
                      }
                    }}
                  >
                    <li
                      className={
                        "nav-item " + (view === "groups" ? "nav-item-active " : "")
                      }
                    >
                      <Tooltip title={`${toolTips.groups} ${selectedSiteName}`} className="d-flex align-items-center">
                        <IMG_GROUPS className="flag-needs-icon" />
                        {template.header.groups}
                      </Tooltip>
                    </li>
                  </Link> // groups
                ) : null}

                {isAdmin || isSuperAdmin
                  ? sites.map((site) =>
                    site.name === selectedSiteName ? (
                      <div
                        style={{ display: "flex", flexDirection: "row" }}
                        key={site.name}
                      >
                        <li className="nav-item">
                          <Tooltip
                            title={
                              IS_HP
                                ? toolTips.onboardingHighpoint
                                : toolTips.onboardingCleanslate
                            }
                          >
                            <a
                              href={site.onboarding}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="header-link"
                            >
                              {template.header.onboarding}
                            </a>
                          </Tooltip>
                        </li>
                        <li className="nav-item">
                          <Tooltip title={toolTips.schedule}>
                            <a
                              href={site.schedule}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="header-link"
                            >
                              {template.header.schedule}
                            </a>
                          </Tooltip>
                        </li>
                      </div>
                    ) : null
                  )
                  : null}
              </ul>
            </div>
            <div className="right-nav">
              <ul className="navbar-nav flex-row align-items-center">
                <li>
                  <Dropdown
                    overlay={
                      <div id="right-nav-dropdown">
                        <Menu className="menu shadow-lg right-nav-menu">
                          {isAdmin || isSuperAdmin ? (
                            <Menu.Item className="menu-item nav-menu-item">
                              <Link
                                to={
                                  (cantSwitchReferral || cantSwitchIntake) ? "/CBRS" : "/invite"
                                }
                                onClick={() => {
                                  if (cantSwitchReferral) {
                                    setReferralModalVisible(true);
                                  } else if (cantSwitchIntake) {
                                    setIntakeModalVisible(true);
                                  } else {
                                    this.closeAll();
                                    setIsWaiting(false);
                                  }
                                }}
                              >
                                <span>{template.header.invite}</span>
                              </Link>
                            </Menu.Item>
                          ) : null}
                          {(isAdmin || isSuperAdmin) ? (
                            <Menu.Item className="menu-item nav-menu-item">
                              <Link to="/my-tasks">
                                <span>{template.header.tasks}</span>
                              </Link>
                            </Menu.Item>
                          ) : null}
                          {(!IS_COMM && isAdmin) || isSuperAdmin ? (
                            <Menu.Item className="menu-item nav-menu-item" onClick={() => this.fetchAllHistory()}>
                              <span>{template.header.activities}</span>
                            </Menu.Item>
                          ) : null}
                          <Menu.Item className="menu-item logout-menu-item" onClick={() => logout(view)}>
                            <span>{template.header.logout}</span>
                          </Menu.Item>
                        </Menu>
                      </div>
                    }
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <div className="nav-item" style={{ cursor: "pointer", color: "#0b0b0b" }}>
                      {username}
                      <img src={IMG_DROPDOWN_ARROW} style={{ marginLeft: 10, width: 14, height: 7 }} alt="dropdown-arrow"/>
                    </div>
                  </Dropdown>
                </li>

                {(!IS_COMM && isAdmin) || isSuperAdmin ? (
                  <li
                    className={
                      "nav-item " + (view === "activities" ? "active " : "")
                    }
                    style={{ position: 'relative', marginLeft: 38 }}
                    onClick={() => this.fetchAllNotifications()}
                  >
                    {notificationCount > 0 && <span className="notification-count">{notificationCount}</span>}
                    <Tooltip
                      title={toolTips.allNotifications}
                      placement="bottomRight"
                    >
                      <img
                        src={IMG_NOTIFICATION}
                        alt="Notification"
                      />
                    </Tooltip>
                  </li>
                ) : null}

              </ul>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
