import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Row, Col } from "antd";
import { inject, observer } from "mobx-react";

import SisenseConfig from '../../configs/sisense.config';
import EmbeddedWidget from '../UI/EmbeddedWidget';

const StatsOverview = inject("store")(observer((props) => {
    let tempApp = null;
    const [widgetsToEmbedd, setWidgetsToEmbedd] = useState([]);
    const [sisenseApp, setSisenseApp] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    let isScriptLoaded = useRef(false);

    const widgetMap = useMemo(() => {
        const tempMap = new Map()
        SisenseConfig.sisenseJs.forEach((dashboardToEmbedd) => {
            dashboardToEmbedd.widgetIds.forEach((widget) => {
                tempMap.set(widget, dashboardToEmbedd.dashboardId)
            })
        })
        return tempMap
    }, [SisenseConfig.sisenseJs])

    useEffect(() => {
        if (!isScriptLoaded.current) {
            document.addEventListener('sisenseJsLoaded', (_e) => {
                isScriptLoaded.current = true
                const Sisense = window.Sisense;

                Sisense.connect(SisenseConfig.host, false).then(connected.bind(this));
                async function connected(app) {
                    //  Save a reference to the sisense app
                    setSisenseApp(app);
                    tempApp = app
                    //  Load the dashboard
                    const widgetsArray = await getEmbeddedWidgetsProps(widgetMap);
                    setWidgetsToEmbedd(widgetsArray);
                    setIsLoading(false);
                }
            })
        }

        const {
            store: {
                AuthStore: { retrieveSSOToken },
            },
        } = props;

        async function fetchSisenseSSOToken() {
            let token = await retrieveSSOToken();
            loadScript(token);
        }

        fetchSisenseSSOToken();
    }, []);

    const loadScript = (jwt) => {
        if (document.querySelector(`script[src="${SisenseConfig.host}${SisenseConfig.path}"]`)) {
            document.dispatchEvent(new Event('sisenseJsLoaded'))
            return
        }
        const loginScriptElem = document.createElement('script')
        loginScriptElem.src = `${SisenseConfig.host}/jwt?jwt=${jwt}&return_to=/js/common.js`
        loginScriptElem.async = true

        loginScriptElem.onload = () => {
            const scriptElem = document.createElement('script')
            scriptElem.src = `${SisenseConfig.host}${SisenseConfig.path}`
            scriptElem.type = "text/javascript"
            scriptElem.onload = () => document.dispatchEvent(new Event('sisenseJsLoaded'))
            document.body.appendChild(scriptElem)
        }
        document.body.appendChild(loginScriptElem)
    }

    const getEmbeddedWidgetsProps = async (widgetList) => {
        const embeddedWidgetProps = [];
        for (let [widgetId, dashboardId] of widgetList.entries()) {
            const dashboard = await getDashboard(dashboardId);
            const widget = dashboard.widgets.get(widgetId);
            embeddedWidgetProps.push(getEmbeddedWidgetProp(dashboard, widget))
        }
        return embeddedWidgetProps;
    }

    const getEmbeddedWidgetProp = (dashboard, widget) => {
        return {
            dashboard: dashboard,
            widget: widget,
            sisenseHost: SisenseConfig.host
        }
    }

    const getDashboard = (dashboardId) => {
        return tempApp?.dashboards?.load(dashboardId).then((dash) => {
            return dash
        })
    }

    return (
        <div className="stats-overview-container">
            <Row>
                {isLoading ?
                    <Col span={24}>
                        <div className='iframe-loading-container'>
                            <h1 className='iframe-loading-text'>Loading ...</h1>
                        </div>
                    </Col>
                    :
                    <Col span={24}>
                        <div className="sisense-js">
                            {widgetsToEmbedd.map((widget, index) => (
                                <EmbeddedWidget
                                    key={`widget-${widget.widget.id}-${index}`}
                                    widget={widget.widget}
                                    dashboard={widget.dashboard}
                                    sisenseHost={widget.sisenseHost} />
                            ))}
                        </div>
                    </Col>
                }
            </Row>
        </div>
    )
}));

export default StatsOverview;