import React, { useEffect } from 'react';
import { Carousel, Col, Popover, Row, Tag, notification } from "antd";
import { useState } from "react";
import IconTint from 'react-icon-tint';
import { useDebouncedCallback } from 'use-debounce';
import mixpanel from "mixpanel-browser";

import { convertDateAndTime } from "../../utils/CommonUtils";
import { 
    COMMENT_POST, 
    DELETE_COMMENT_DROPDOWN, 
    FLAG, 
    FLAG_FILLED, 
    LIKE_POST, 
    LIKE_POST_LINED,
    MORE_OPTION, 
    COMMENT_FLAGGED } from "../../utils/ImageUtils";
import LinkPreview from "./LinkPreview";
import { inject, observer } from 'mobx-react';
import { addReaction, deleteReaction } from '../../utils/forumFeedService';
import { FEED_IMAGE_URL, REACTION_TYPES, TIME_FILTERS } from '../../constants/GlobalConstant';
import _ from 'lodash';
import DeleteRestoreConfirmationModal from './DeleteRestoreConfirmationModal';
import ApiService from '../../utils/ApiService';
import { ALIKE_MODERATOR, DEV, MODERATOR, SA } from '../../constants/UserRolesConstant';
import { FeedFlagModal } from '../protected/FeedFlagModal';
import UserAvatar from '../protected/UserAvatar';
import PopoverAction from "./PopoverAction";

const SingleFeed = inject("store")(observer((props) => {
    const [loadMoreDescription, setLoadMoreDescription] = useState({});
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [toDelete, setToDelete] = useState(true);
    const [isShowFlagOptionModal, setIsShowFlagOptionModal] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [isFlagged, setIsFlagged] = useState(false);
    const [flagCount, setFlagCount] = useState(0);
    const { 
        feed,
        feedIndex,
        isDeleteOnly,
        isReadOnly,
        onDeletionComplete,
        store: {
            ForumFeedStore: {
                tags,
                categories,
                isCommentFlagged,
                selectedTimeFilter,
                isFeedCommentVisible,
                currentFeedId,
                loading,
            },
            AuthStore: {
                userId: loggedInUserId,
                type: userType,
            }
        }
    } = props;

    useEffect(() => {
        if (feed) {
            setIsLiked(feed?.own_reactions?.like?.length > 0);
            setLikeCount(feed?.reaction_counts?.like || 0);
            setIsFlagged(feed?.own_reactions?.flag?.length > 0);
            setFlagCount(feed?.reaction_counts?.flag || 0);
        } else {
            setIsLiked(false);
            setLikeCount(0);
            setIsFlagged(false);
            setFlagCount(0);
        }
    }, [feed]);

    const viewReaction = () => {
        return (
            <div style={{ margin: 10, borderRadius: 5 }}>
                {
                    (+loggedInUserId === +feed?.actor?.id || ALIKE_MODERATOR.includes(userType)) &&
                    <>
                        {/* <p className='feed-comment-action-dropdown'><img src={EDIT_FEED} style={{ height: 20, width: "30%" }} /> <span style={{ marginTop: 5 }}>Edit</span></p> */}
                        <p
                          className='feed-comment-action-dropdown'
                          onClick={(e) => {
                            if (loading) return;
                            e.stopPropagation();
                            onDeleteViewReaction();
                          }}
                        >
                            <PopoverAction
                                actionName="Delete" 
                                icon={DELETE_COMMENT_DROPDOWN}
                                isDisabled={loading}
                            />
                        </p>
                    </>
                }
                {/* <p className='feed-comment-action-dropdown'><img src={PIN_FEED} style={{ height: 20, width: "30%" }} /> <span style={{ marginTop: 5 }}>Pin</span></p> */}

            </div>
        );
    };

    const toggleDescriptionLoadMore = (timetoken) => {
        let obj = {};
        if (loadMoreDescription.hasOwnProperty(timetoken)) {
            obj[timetoken] = !loadMoreDescription[timetoken]
        } else {
            obj[timetoken] = true;
        }
        setLoadMoreDescription(obj);
    }

    const onClickReaction = async (reactionType, isReacted) => {
        if(!isReadOnly && !isDeleteOnly) {
            const { feeds, setFeeds } = props.store.ForumFeedStore;
            const feedsClone = _.cloneDeep(feeds);
            try {
                let reaction, addReactionResponse;

                if (isReacted) {
                    /** update store */
                    const oldCount = _.get(feedsClone, `${[feedIndex]}.reaction_counts.${reactionType}`, 1);
                    _.set(feedsClone, `${[feedIndex]}.reaction_counts.${reactionType}`, oldCount - 1);
                    _.unset(feedsClone, `${[feedIndex]}.own_reactions.${reactionType}`);
                    setFeeds([...feedsClone]);
        
                    /** delete in getstream */
                    const reactionId = _.get(feed, `own_reactions.${reactionType}.0.id`);
                    await deleteReaction(reactionId);
                    
                    /** Add unlike reaction to update through subscriptions */
                    const { AuthStore } = props.store;
                    reaction = `un${reactionType}`;
                    addReactionResponse = await addReaction(reaction, feed.id, { reactionId }, `${AuthStore.userId}`);
                    // const ownChildren = _.get(feedsClone, `${[feedIndex]}.own_reactions.un${reactionType}`, []);
                    // ownChildren.unshift(added);
                    // _.set(feedsClone, `${[feedIndex]}.own_reactions.un${reactionType}`, ownChildren);
                    // setFeeds([...feedsClone]);
                } else {
                    /** update store */
                    const oldCount = _.get(feedsClone, `${[feedIndex]}.reaction_counts.${reactionType}`, 0);
                    _.set(feedsClone, `${[feedIndex]}.reaction_counts.${reactionType}`, oldCount + 1);
        
                    /** update as dummy data to reflect in UI fast */
                    const ownChildren = _.get(feedsClone, `${[feedIndex]}.own_reactions.${reactionType}`, []);
                    ownChildren.unshift({});
                    _.set(feedsClone, `${[feedIndex]}.own_reactions.${reactionType}`, ownChildren);
                    setFeeds([...feedsClone]);
        
                    /** add reaction */
                    const { userId } = props.store.AuthStore;
                    reaction = REACTION_TYPES[`${reactionType}`];
                    addReactionResponse = await addReaction(reaction, feed.id, {}, `${userId}`);
                    
                    /** update orginal data */
                    ownChildren[0] = addReactionResponse;
                    _.set(feedsClone, `${[feedIndex]}.own_reactions.${reactionType}`, ownChildren);
                    setFeeds([...feedsClone]);
    
                    // const toDelete = _.get(feedsClone, `${[feedIndex]}.own_reactions.un${reactionType}`, []);
                    // if (toDelete.length) {
                    //     await deleteReaction(toDelete[0]?.id);
                    //     toDelete.shift();
                    //     _.set(feedsClone, `${[feedIndex]}.own_reactions.un${reactionType}`, toDelete);
                    //     setFeeds([...feedsClone]);
                    // }
                }

                mixpanel.track(`Forum post ${reaction}`, {
                    from: "WEB",
                    type: reaction,
                    activity_id: addReactionResponse.activity_id,
                    id: addReactionResponse.id,
                    data: JSON.stringify(addReactionResponse),
                });
            } catch(error) {
                throw error;
            }
        }
    }

    const debouncedOnClickReaction = useDebouncedCallback(
        (reactionType) => {
            const previousLikeState = feed?.own_reactions?.like?.length > 0;
            const previousFlagState = feed?.own_reactions?.flag?.length > 0;
            if (previousLikeState !== isLiked || previousFlagState !== isFlagged) {
                onClickReaction(reactionType, reactionType === REACTION_TYPES.flag ? previousFlagState : previousLikeState);
            }
        },
        1000
    );

    const handleClickReaction = (reactionType) => {
        if (reactionType === REACTION_TYPES.like) {
            setIsLiked(!isLiked);
            setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);
        } else if (reactionType === REACTION_TYPES.flag) {
            setIsFlagged(!isFlagged);
            setFlagCount(isFlagged ? flagCount - 1 : flagCount + 1);
        }
        debouncedOnClickReaction(reactionType);
    }

    const onClickComment = async () => {
        if (!isReadOnly && !isDeleteOnly) {
            const {
                setIsFeedCommentVisible,
                isFeedCommentVisible,
                setCurrentFeedId,
                currentFeedId,
                fetchComments,
                setComments,
            } = props.store.ForumFeedStore;
    
            // only hide for same feed
            const isVisible = currentFeedId === feed.id ? !isFeedCommentVisible : true;
            
            // change store
            setIsFeedCommentVisible(isVisible);
            setCurrentFeedId(feed.id);
            
            // to reset comments when toggles off or feed changes
            if (currentFeedId != feed.id || !isVisible) {
                setComments([]);
            } 
            if(isVisible) {
                // get latest comments
                fetchComments();
            }

            mixpanel.track("Forum Open Post Details", {
                from: "WEB",
                data: JSON.stringify(feed),
                activity_id: feed?.id,
            });
        }
    }

    const tagContainer = (color) => {
        return {
            borderColor: color,
            backgroundColor: color,
        };
    }

    const openMediaModal = (index) => {
        const {
            setIsModalVisible,
            setModalImages,
            setFirstImageIndex,
        } = props.store.ForumFeedStore;
        setFirstImageIndex(index);
        setModalImages(images);
        setIsModalVisible(true);
    }

    const onCancelConformation = () => {
        setIsConfirmationModalOpen(false)
    }

    const onDeleteViewReaction = () => {
        setIsConfirmationModalOpen(true);
        setToDelete(true)
    }

    const doDeletePost = async (activityId, feedIndex) => {
        const { 
            feeds,
            setFeeds,
            setLoading,
            isFeedCommentVisible,
            currentFeedId,
            setIsFeedCommentVisible,
        } = props.store.ForumFeedStore;
        try {
            setIsConfirmationModalOpen(false);
            if (isFeedCommentVisible && activityId === currentFeedId) {
                setIsFeedCommentVisible(false);
            }
            setLoading(true);
            
            // call api
            const path = `feeds?activityId=${activityId}`;
            await ApiService.deleteRequest(path);
            if (onDeletionComplete) {
                onDeletionComplete();
            }

            mixpanel.track("Forum post deleted", {
                from: "WEB",
                activity_id: activityId,
            })

            if (feedIndex !== null && feedIndex !== undefined) {
                // change in store variables
                const feedsClone = _.cloneDeep(feeds);
                setFeeds([]);
                feedsClone.splice(feedIndex, 1);
                setFeeds([...feedsClone]);
            }

            setLoading(false);
            notification.success({
                message: 'Success',
                description: 'Activity deleted successfully',
                placement: 'bottomRight',
            });
        } catch(error) {
            notification.error({
                message: 'Error',
                description: error?.message,
                placement: 'bottomRight',
            });
            throw error;
        }
    }

    const flagClickHandler = async (isFlagged) => {
        if (!isReadOnly && !isDeleteOnly) {
            const { type: userType } = props.store.AuthStore;
            if ([MODERATOR, SA, DEV].includes(userType) && !isFlagged) {
                setIsShowFlagOptionModal(true);
            } else {
                handleClickReaction(REACTION_TYPES.flag)
            }
        }
    }

    const onCloseFlagReactionModal = () => {
        setIsShowFlagOptionModal(false);
    };

    const onSubmitFlagReaction = async (data) => {
        handleClickReaction(REACTION_TYPES.flag)

        const { typeOfNeed, isAnonimityCompromised, description } = data;
        const path = 'forum-flags';
        const payload = {
            activityId: feed?.id,
            typeOfNeed: `${typeOfNeed}`,
            isAnonimityCompromised,
            description,
        };
        await ApiService.postRequest(path, payload);

        notification.success({
            message: 'Success',
            description: 'Flagged successful',
            placement: 'bottomRight',
        });

        setIsShowFlagOptionModal(false);
    };

    const closeUserProfile = () => {
        const { setMemberProfileVisibility } = props.store.ProfileStore;
        setMemberProfileVisibility(false);
    }

    const onClickFeed = async () => {
        const { memberProfileVisible } = props.store.ProfileStore;
        await onClickComment();
        if (memberProfileVisible) {
            closeUserProfile();
        }
    }

    const images = feed?.meta?.images;
    const feedCategory = categories.find(ele => ele?.id === feed?.meta?.categories[0]);
    const commentCount = (feed?.reaction_counts?.comment || 0) - (feed?.meta?.deleted_comment_count || 0);
    const shouldHighlightFeed = feed.id === currentFeedId && isFeedCommentVisible;

    return (
        <>
            <Row
                className='feed-container'
                  style={{backgroundColor: feed?.deleted_at ?  '#FFE6E8' : 'none'}}
            >
                <Col
                  span={24}
                  className={"white-border" + (shouldHighlightFeed ? " lightblue-background" : "")}
                  onClick={() => onClickFeed()}
                >
                    
                    {/** Headline starts */}
                    <Row>
                        <Col 
                            span={17}
                            style={{
                                marginLeft: 20,
                                marginTop: 20
                            }}
                        >
                            <UserAvatar
                                userData={feed?.actor?.data}
                                showBadge={true}
                            />
                        </Col>
                        {<Col span={5}
                            style={{
                                marginTop: 20,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }} >
                            {feed?.deleted_at && feed?.deleted_at !== null &&
                                <p style={{ color: 'red', marginRight: '14px' }} >Post Deleted</p>
                            }
                            <img
                                src={
                                    (selectedTimeFilter === TIME_FILTERS.FLAGGED && flagCount > 0) || isReadOnly || isDeleteOnly || isFlagged 
                                        ? FLAG_FILLED 
                                        : FLAG
                                }
                                alt="flag"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    selectedTimeFilter !== TIME_FILTERS.FLAGGED && flagClickHandler(isFlagged);
                                }}
                                className='feed-top-right-action'
                                style={{ cursor: (selectedTimeFilter === TIME_FILTERS.FLAGGED || isReadOnly || isDeleteOnly) ? 'default' : 'pointer' }}
                            />
                            {
                                <span style={{ marginLeft: 5 }}><strong>{flagCount || 0}</strong></span>
                            }
                            &nbsp;
                            {!isReadOnly && (+loggedInUserId === +feed?.actor?.id || ALIKE_MODERATOR.includes(userType)) &&
                                <Popover
                                    content={viewReaction()}
                                    trigger="hover"
                                >
                                    <img
                                        src={MORE_OPTION}
                                        alt="more"
                                        onClick={(e) => { e.stopPropagation(); }}
                                        className='feed-top-right-action' />
                                </Popover>
                            }
                        </Col>}
                    </Row>
                    <Row
                        className='feed-headline' >
                        <Col span={20} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <span >
                                <strong>
                                    {feed?.meta?.title || ""}
                                </strong>
                            </span>
                        </Col>
                        <Col span={4}></Col>
                    </Row>
                    <Row style={{ marginTop: -20 }}>
                        <Col span={15}>
                            <span className='feed-time'>
                                {feed?.time ? convertDateAndTime(`${feed?.time}Z`) : ""}
                            </span>

                        </Col>

                    </Row>
                    {/** Headline ends */}

                    {/*Description part */}
                    < p
                        onClick={() => toggleDescriptionLoadMore(1)}
                        style={{
                            padding: '0px 12px 0px 13px',
                            color: 'black',
                            fontSize: 16,
                            whiteSpace: 'pre-wrap',
                            marginTop: 10,
                            margin: 10
                        }}
                        className={
                            loadMoreDescription.hasOwnProperty(1) ?
                                (!loadMoreDescription[1] && "feed-latest-comment") :
                                "feed-latest-comment"
                        }
                    >
                        <span style={{ textDecoration: false ? 'line-through' : 'none' }}>{feed?.meta?.description || ""}</span>
                    </p>
                    {/* end Description part */}

                    {/* start Carousel part */}
                    {
                        (images && images?.length > 0) &&
                        <Carousel style={{ marginTop: 15 }}>
                            {images?.map((item, index) => {
                                return (
                                    <div>  
                                        <img
                                            src={`${FEED_IMAGE_URL}${item?.url}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openMediaModal(index);
                                            }}
                                            alt={'Feed image'}
                                            style={{    
                                                width: '100%',
                                                height: '300px',
                                                objectFit: 'contain',
                                                alignContent: 'center'
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </Carousel >
                    }
                    {/**ens  Image part */}
                    
                    {/** Link preview starts */}
                    {feed?.meta?.links?.map((item) => {
                        return (
                            <LinkPreview
                                url={item?.url || (typeof item === 'string' ? item : '')}
                                {
                                    ...(
                                        item?.title && item?.description
                                            ? { previewData: { title: item?.title, description: item?.description, image: item?.image } } 
                                            : {}
                                    )
                                }
                            />
                        )
                    })}
                    {/** Link preview ends */}

                    {/*Added Tag  */}
                    <div id="feed-tags-container">
                        <Tag
                            className='feed-capsule-label'
                            style={tagContainer(feedCategory?.color)} >
                            <Row id="feed-category">
                                <Col>
                                    <IconTint
                                        src={feedCategory?.icon}
                                        id="icon"
                                        color="white"
                                    />
                                </Col>
                                <Col>
                                    {feedCategory?.category}
                                </Col>
                            </Row>
                        </Tag>
                        {feed?.meta?.tags?.map(item => {
                            const matchedTag = tags.find(ele => +ele.id == +item);
                            return (
                                matchedTag && 
                                <Tag 
                                    className="feed-capsule-label"
                                    style={tagContainer(matchedTag.color)} >
                                    <Row id="feed-tag">
                                        <Col>
                                            {matchedTag.tag}
                                        </Col>
                                    </Row>
                                </Tag>
                            )
                        })}
                    </div>
                    {/* end tag */}

                    {/** Actions starts */}
                    <Row style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', padding: 20, }}>
                        <Col span={3} className="feed-center">
                            <img
                                src={isLiked || isReadOnly || isDeleteOnly ? LIKE_POST : LIKE_POST_LINED}
                                alt='Heart image'
                                className='feed-like-actions'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(!isReadOnly && !isDeleteOnly)
                                    handleClickReaction(REACTION_TYPES.like);
                                }}
                                style={{ cursor: isDeleteOnly || isReadOnly ? 'default' : 'pointer' }}
                            />
                            <p className='feed-action-count'><strong>{likeCount || 0}</strong></p>
                        </Col>
                        <Col span={3} className="feed-center">
                            <img
                                src={selectedTimeFilter === TIME_FILTERS.FLAGGED && isCommentFlagged[feed?.id] ? COMMENT_FLAGGED : COMMENT_POST}
                                alt={'comment image'}
                                className={'feed-actions'}
                                onClick={() => {}}
                                style={{ cursor: isDeleteOnly|| isReadOnly ? 'default' : 'pointer' }}
                            />
                            <p className='feed-action-count'><strong>{commentCount}</strong></p>
                        </Col>
                        {/* <Col span={4} className="feed-center">
                            <img
                                src={SHARE_POST}
                                onClick={(e) => { onShareClick() }}
                                alt={'share image'}
                                className={'feed-actions'}
                            />
                            <p className='feed-action-count'><strong>12</strong></p>
                        </Col>
                        <Col span={11} className="feed-center"></Col>
                        <Col span={1} className="feed-center">
                            <img
                                src={SAVE_POST}
                                alt={'save image'}
                                className={'feed-actions'}
                            />
                        </Col> */}

                    </Row>
                    {/** Actions ends */}

                </Col>
            </Row>
            <DeleteRestoreConfirmationModal
                isConfirmationModalOpen={isConfirmationModalOpen}
                handleOk={() => doDeletePost(feed?.id, feedIndex)}
                handleCancel={() => onCancelConformation()}
                toDelete={toDelete}
                type={'Post'}
            />
            {/** Flag options modal starts */}
            {isShowFlagOptionModal &&
                <FeedFlagModal
                    onOk={onSubmitFlagReaction}
                    visible={isShowFlagOptionModal}
                    flagReactionId={7}
                    messageObj={{}}
                    onClose={onCloseFlagReactionModal}
                />
            }
            {/** Flag options modal ends */}
        </>
    )
}));

export default SingleFeed;
