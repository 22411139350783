import { inject, observer } from "mobx-react";

import { Checkbox } from "antd";
import React from "react";
import { Spin } from "antd";
import { broadcast } from "../../en.json";

@inject("store")
@observer
class UserSelection extends React.Component {
  getUserList = () => {
    const {
      store: {
        BroadcastStore: { filteredOptions, isUserSelected, toggleUserSelection }
      }
    } = this.props;

    let count = 0;
    const userList = [];
    const keys = filteredOptions.keys();
    const values = filteredOptions.values();
    while (count < filteredOptions.size) {
      const nextKey = keys.next().value;
      const nextValue = values.next().value;
      userList.push(
        <li
          key={nextKey}
          className="list-group-item"
          onClick={() => toggleUserSelection(nextKey, nextValue.username)}
        >
          <Checkbox checked={isUserSelected(nextKey)} />
          <h3>{nextValue.username}</h3>
        </li>
      );
      count += 1;
    }
    return userList;
  };

  render() {
    const {
      store: {
        BroadcastStore: { loading, modFilter, toggleModFilter }
      }
    } = this.props;

    return (
      <div className="group-chat">
        <h3 className="text-center">{broadcast.members}</h3>

        <Checkbox
          checked={modFilter}
          onClick={toggleModFilter}
          className="show-mem-checkbox"
        >
          {broadcast.showOnlyMods}
        </Checkbox>
        <br />
        <div className="member-selection-list">
          <ul>{this.getUserList()}</ul>
          {loading && <Spin />}
        </div>
      </div>
    );
  }
}

export default UserSelection;
