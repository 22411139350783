import { Spin } from "antd";
import { inject, observer } from "mobx-react";

import InfiniteScroll from "react-infinite-scroller";
import React from "react";
import _ from "lodash";
import moment from "moment";

@inject("store")
@observer
class UserUnFlags extends React.Component {
  loadMoreUnFlaggedMessages = () => {
    const {
      store: {
        DeletedMessagesStore: {
          userUnFlaggedLoading,
          fetchUserUnFlaggedMessages,
        },
        ProfileStore: { userId },
      },
    } = this.props;
    if (userUnFlaggedLoading) {
      return;
    }
    fetchUserUnFlaggedMessages(userId);
  };

  render() {
    const {
      store: {
        DeletedMessagesStore: {
          userUnFlaggedMessages,
          userUnFlaggedLoading,
          groupNames,
          dmNames,
          userUnFlaggedSkip,
          userUnFlaggedTotal,
        },
      },
    } = this.props;
    return userUnFlaggedMessages.length ? (
      <div className="group-chat">
        <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
          <InfiniteScroll
            loadMore={this.loadMoreUnFlaggedMessages}
            useWindow={false}
            hasMore={userUnFlaggedSkip < userUnFlaggedTotal}
          >
            {userUnFlaggedMessages.map((message, index) => {
              return message.userId ? (
                <div key={message.id}>
                  <div className="flag-container">
                    <div className="flag-upper">
                      <div className="flag-name-container">
                        <div>
                          <span className="flag-name">{message.sender}</span>
                        </div>
                      </div>
                      <div className="flag-time">
                        {moment(message.createdAt).calendar()}
                      </div>
                    </div>
                    {message.channelType === "GROUP" ? (
                      <div className="info-icon-container">
                        <div className="flag-group">
                          {`in ${groupNames.get(message.channelId)}`}
                        </div>
                      </div>
                    ) : null}
                    {message.channelType === "DIRECT" ? (
                      <div className="info-icon-container">
                        <div className="flag-group">
                          {`in ${dmNames.get(message.channelId)}`}
                        </div>
                      </div>
                    ) : null}
                    <div className="flag-middle">
                      {message.type === "text" ? (
                        <div className="flag-message-container">
                          <div className="heading">{message.text}</div>
                        </div>
                      ) : message.type === "img" || message.type === "gif" ? (
                        <img
                          src={message.imgUrl}
                          alt=""
                          height="60"
                          width="60"
                        />
                      ) : (
                        <span className="heading"></span>
                      )}
                      <br />

                      <span className="subHeading">
                        {`UnFlagged by ${message.deletedBy}`}
                      </span>
                    </div>
                  </div>
                  <hr className="horizontal-tag" />
                </div>
              ) : null;
            })}
            {userUnFlaggedLoading && (
              <div className="loading-alert-members">
                <Spin />
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    ) : userUnFlaggedLoading ? (
      <div className="loading-alert-members">
        <Spin />
      </div>
    ) : null;
  }
}
export default UserUnFlags;
