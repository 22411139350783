import { types } from "mobx-state-tree";

const Event = types.model("Event", {
  id: types.number,
  eventType: types.number,
  sourceUserId: types.number,
  targetUserId: types.number,
  channelType: types.maybeNull(types.string),
  channelId: types.maybeNull(types.number),
  messageId: types.maybeNull(types.string),
  reactionId: types.maybeNull(types.number),
  messageReactionId: types.maybeNull(types.number),
  eventName: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.string,
  groupName: types.maybeNull(types.string),
  meta: types.maybeNull(types.string),
  metaObj: types.frozen({}),
  sourceUserUsername: types.frozen({
    username: types.string,
    fullName: types.string,
  }),
  targetUserUsername: types.frozen({
    username: types.string,
    fullName: types.string,
  }),
});

export default Event;
