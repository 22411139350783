import React from "react";
import _ from "lodash";
import { Spin, Radio, Checkbox, Progress, DatePicker, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";

import close from "../../static/images/close.png";
import {
  BARC10_NOTE_TEMPLATE_CATEGORY_FLAGS,
  FORM_QUESTION_TYPE_DATEPICKER,
  FORM_QUESTION_TYPE_DESCRIPTIVE,
  FORM_QUESTION_TYPE_MULTIPLE_CHOICES,
  FORM_QUESTION_TYPE_MULTIPLE_CHOICES_RADIO,
  QUESTION_STATUS_FLAGS,
  FORM_QUESTION_CATEGORY_TYPE_EDITABLE_TOUCHPOINT_DATE,
  FORM_QUESTION_CATEGORY_TYPE_TOUCHPOINT_MEDIUM,
  FORM_QUESTION_CATEGORY_TYPE_TOUCHPOINT_CHANNEL,
  FORM_QUESTION_CATEGORY_TYPE_USER_GOALS
} from "../../constants/GlobalConstant";
import { userProfile } from "../../en.json";
import { formateDate } from "../../utils/CommonUtils";
import { IMG_MINIMIZE_WINDOW } from "../../utils/ImageUtils";
import { ALIKE_MODERATOR } from "../../constants/UserRolesConstant";

const { OPTIONAL, REQUIRED, HIDDEN } = QUESTION_STATUS_FLAGS;

const mixpanel = require("mixpanel-browser");

@inject("store")
@observer
class UserForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleChoices: {},
      datePicker: {},
      multipleChoicesRadio: {},
      descriptiveAnswer: {},
      optionalQuestionIds: [],
      hiddenQuestionIds: [],
      totalRequiredQuestionsCount: 0,
      showWarnings: false,
      profileUsername: '',
      profileFullname: '',
      profileSelectedFormName: ''
    };
  }

  componentDidMount() {
    const {
      store: {
        FormMessageStore: {
          userFormsStateCopy,
          isFormPendingWizardVisible
        }
      }
    } = this.props;
    // Here we're checking if form pending wizard is opened and we have a data in userFormStateCopy
    // Then we'll set the userFormStateCopy data to local state
    if (!isFormPendingWizardVisible && userFormsStateCopy && Object.keys(userFormsStateCopy).length) {
      this.setState({
        ...this.state,
        ...userFormsStateCopy
      });
    } else {
      this.preFilledTextboxValues();
    }
  }

  preFilledTextboxValues = () => {
    const {
      store: {
        FormMessageStore: {
          totalScore,
          lowestScoringDomain,
          highestScoringDomain,
          formQuestions,
          touchpointDate,
          touchpointChannel,
          touchpointMedium,
          userGoalsAnswer
        }
      }
    } = this.props;
    const { BARC10_NOTE, HIGHEST_SCORING_DOMAINS, LOWEST_SCORING_DOMAINS } = BARC10_NOTE_TEMPLATE_CATEGORY_FLAGS;

    // Here we need to take question ids of optional question type & hidden question type
    const optionalQuestionIds = [];
    const hiddenQuestionIds = [];
    // Store descriptive answers related to the touchpoint
    const touchpointDescriptiveAnswersObj = {};
    // Store datepicker answers related to the touchpoint
    const touchpointDatepickerAnswersObj = {};
    // Store radio answers related to the touchpoint
    const touchpointRadioAnswersObj = {};
    // Here we are managing the count for only required questions 
    let requiredQuestionsCount = 0;
    // We are iterating over formQuestions array using for of loop
    for (const formQuestion of formQuestions) {
      const { questionStatus, id, category, options } = formQuestion;
      // If question status is OPTIONAL then we are pushing that question id to optionalQuestionIds array
      if (questionStatus === OPTIONAL) {
        optionalQuestionIds.push(id);
      } else if (questionStatus === REQUIRED) {
        // If question status is REQUIRED then we are adding +1 to the count
        requiredQuestionsCount++;
      } else {
        // If question status is HIDDEN then we are pushing that question id to hiddenQuestionIds array
        hiddenQuestionIds.push(id);
      }

      // If question category is "editable-touchpoint-date" then we are storing touchpoint date to descriptive answers object 
      if (category === FORM_QUESTION_CATEGORY_TYPE_EDITABLE_TOUCHPOINT_DATE && touchpointDate) {
        touchpointDatepickerAnswersObj[id] = touchpointDate;
      } else if (category === FORM_QUESTION_CATEGORY_TYPE_TOUCHPOINT_CHANNEL && touchpointChannel) {
        // If question category is "touchpoint-channel" then we are storing touchpoint channel to descriptive answers object
        touchpointDescriptiveAnswersObj[id] = touchpointChannel;
      } else if (category === FORM_QUESTION_CATEGORY_TYPE_TOUCHPOINT_MEDIUM && touchpointMedium && options && options.length) {
        const touchpointMethod = touchpointMedium.toLowerCase();
        // Here we are matching the touchpoint medium with the options array and find out its id
        const touchpointMediumObj = options.find((obj) => obj.option.toLowerCase() === touchpointMethod);
        // If question category is "touchpoint-medium" then we are storing touchpoint medium's id to radio answers object
        touchpointRadioAnswersObj[id] = touchpointMediumObj.id;
      } else if (category === FORM_QUESTION_CATEGORY_TYPE_USER_GOALS) {
        // If question category is "user-goals" then we are storing user goals id to descriptive answers object
        touchpointDescriptiveAnswersObj[id] = userGoalsAnswer?.trim() || userProfile.defaultAnswerForUserGoals;
      }
    }
    if (totalScore && highestScoringDomain && lowestScoringDomain) {
      let barc10ScoreArr = formQuestions.filter(fq => fq.category === BARC10_NOTE);
      let lowestScoringDomainArr = formQuestions.filter(fq => fq.category === LOWEST_SCORING_DOMAINS);
      let highestScoringDomainArr = formQuestions.filter(fq => fq.category === HIGHEST_SCORING_DOMAINS);
      let obj = {}
      if (barc10ScoreArr.length) {
        obj = { ...obj, ...{ [barc10ScoreArr[0].id]: totalScore.toString() } }
      }
      if (lowestScoringDomainArr.length) {
        obj = { ...obj, ...{ [lowestScoringDomainArr[0].id]: lowestScoringDomain } }
      }
      if (highestScoringDomainArr.length) {
        obj = { ...obj, ...{ [highestScoringDomainArr[0].id]: highestScoringDomain } }
      }
      this.setState((prevState) => {
        return {
          descriptiveAnswer: {
            ...prevState.descriptiveAnswer,
            ...obj,
          },
        };
      });
    }
    // After all process is done, we will set the data to local state
    this.setState((prevState) => ({
      optionalQuestionIds,
      totalRequiredQuestionsCount: requiredQuestionsCount,
      hiddenQuestionIds,
      descriptiveAnswer: {
        ...prevState.descriptiveAnswer,
        ...touchpointDescriptiveAnswersObj
      },
      multipleChoicesRadio: {
        ...prevState.multipleChoicesRadio,
        ...touchpointRadioAnswersObj
      },
      datePicker: {
        ...prevState.datepicker,
        ...touchpointDatepickerAnswersObj
      }
    }));
  }

  getMultipleChoices = (id, checkedValue) => {
    if (!checkedValue.length) {
      this.setState({
        multipleChoices: _.omit(this.state.multipleChoices, id),
      });
    } else {
      this.setState((prevState) => {
        return {
          multipleChoices: {
            ...prevState.multipleChoices,
            ...{ [id]: checkedValue },
          },
        };
      });
    }
  };
  getMultipleChoicesRadio = (id, e) => {
    const enteredvalue = e.target.value;
    this.setState((prevState) => {
      return {
        multipleChoicesRadio: {
          ...prevState.multipleChoicesRadio,
          ...{ [id]: enteredvalue },
        },
      };
    });
  };
  getDatePicker = (id, e) => {
    const enteredvalue = formateDate(e.target.value, true);
    this.setState((prevState) => {
      return {
        datePicker: {
          ...prevState.datePicker,
          ...{ [id]: enteredvalue },
        },
      };
    });
  };

  onChange = (id, date, dateString) => {
    const enteredvalue = dateString || null
    if (enteredvalue) {
      this.setState((prevState) => {
        return {
          datePicker: {
            ...prevState.datePicker,
            ...{ [id]: enteredvalue },
          },
        };
      });
    } else {
      this.setState({
        datePicker: _.omit(this.state.datePicker, id),
      });
    }
  }

  getDescriptive = (id, e) => {
    const text = e.target.value;

    const afterTrim = text.trim();

    if (!afterTrim) {
      this.setState({
        descriptiveAnswer: _.omit(this.state.descriptiveAnswer, id),
      });
    } else {
      this.setState((prevState) => {
        return {
          descriptiveAnswer: {
            ...prevState.descriptiveAnswer,
            ...{ [id]: text },
          },
        };
      });
    }
  };

  displayOptionBasedOnQuestionType = (questionData = {}) => {
    const { id, type, options, category, questionStatus } = questionData;
    const {
      store: {
        FormMessageStore: {
          totalScore,
          lowestScoringDomain,
          highestScoringDomain
        }
      }
    } = this.props;
    const { multipleChoicesRadio, multipleChoices, datePicker } = this.state;
    const { BARC10_NOTE, HIGHEST_SCORING_DOMAINS, LOWEST_SCORING_DOMAINS } = BARC10_NOTE_TEMPLATE_CATEGORY_FLAGS;
    const isBARC10ScoreFilled = (category === BARC10_NOTE && totalScore);
    const isHighestScoringDomainFilled = (category === HIGHEST_SCORING_DOMAINS && highestScoringDomain);
    const isLowestScoringDomainFilled = (category === LOWEST_SCORING_DOMAINS && lowestScoringDomain);
    const datePickerValue = datePicker.hasOwnProperty(id) ? datePicker[id] : null;
    if (type === FORM_QUESTION_TYPE_MULTIPLE_CHOICES) {
      const myOptions = options.map((option) => {
        return { value: option.id, label: option.option };
      });
      const selectedOptionsArr = multipleChoices[id] ? multipleChoices[id] : null;
      return (
        <Checkbox.Group
          name={`${id}`}
          options={myOptions}
          value={selectedOptionsArr}
          onChange={(e) => {
            this.getMultipleChoices(id, e);
          }}
        />
      );
    } else if (type === FORM_QUESTION_TYPE_MULTIPLE_CHOICES_RADIO) {
      return (
        <Radio.Group
          className="radio-group"
          onChange={(e) => {
            this.getMultipleChoicesRadio(id, e);
          }}
          value={multipleChoicesRadio[id]}
        >
          {options.map((option) => (
            <Radio key={option.id} value={option.id}>{option.option}</Radio>
          ))}
        </Radio.Group>
      );
    } else if (type === FORM_QUESTION_TYPE_DESCRIPTIVE) {
      return (
        <div>
          <textarea
            className="descriptive-textbox"
            value={this.state.descriptiveAnswer[id]}
            onChange={(e) => this.getDescriptive(id, e)}
            onBlur={() => {
              this.setState({ showWarnings: true });
            }}
            style={{
              height: (isHighestScoringDomainFilled || isLowestScoringDomainFilled) && 100
            }}
          />
          {/* Here if question status is OPTIONAL then we don't need to show error of "*field cannot be empty" */}
          {questionStatus !== OPTIONAL && !(isBARC10ScoreFilled || isHighestScoringDomainFilled || isLowestScoringDomainFilled) && !this.state.descriptiveAnswer[id] && this.state.showWarnings ? (
            <div className="warning">* field cannot be empty</div>
          ) : null}
        </div>
      );
    } else if (type === FORM_QUESTION_TYPE_DATEPICKER) {
      return (
        <div>
          <DatePicker
            onChange={(date, dateString) => this.onChange(id, date, dateString)}
            value={datePickerValue ? moment(datePickerValue, 'MM/DD/YYYY') : null}
            picker="month"
            format={'MM/DD/YYYY'}
            disabledDate={(current) => current > moment()}
          />
        </div>
      )
    }
  };

  updateUserFormResponses = () => {
    const {
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer,
      datePicker
    } = this.state;
    const {
      store: {
        AuthStore: { username: adminUsername },
        FormMessageStore: {
          updateUserFormResponses,
          resetTouchpointDataForDocument,
          setUserFormsStateCopy,
          setUserFormData,
          setFormOpenMethod,
          setFormStartTime,
          formStartTime,
          userFormsStateCopy,
          selectedForm
        },
        ProfileStore: { username, fullname, },
      },
    } = this.props;
    resetTouchpointDataForDocument();
    updateUserFormResponses(
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer,
      datePicker
    );
    const formEndTime = Date.now();
    if (formStartTime && formEndTime) {
      const timeTakenToFillForm = moment.duration(formEndTime - formStartTime);
      const { profileUsername = username, profileFullname = fullname, profileSelectedFormName = selectedForm.name } = userFormsStateCopy;
      // Add mixpanel event to track the time taken by user to fill this form
      mixpanel.track("User Form Completed", {
        timeTaken: `${timeTakenToFillForm.hours()} hour ${timeTakenToFillForm.minutes()} minutes ${timeTakenToFillForm.seconds()} seconds`,
        username: profileUsername,
        fullname: profileFullname,
        selectedFormName: profileSelectedFormName,
        adminUsername
      });
    }
    // Here after submitting user form, we will reset both userFormsStateCopy & userFormData from MobX
    setUserFormsStateCopy({});
    // We'll reset the user form data
    setUserFormData({});
    // We'll set the form open method value to null;
    setFormOpenMethod(null);
    // We'll set the form start time value to null;
    setFormStartTime(null);
  };

  isDisabled = () => {
    const {
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer,
      datePicker
    } = this.state;

    const multipleChoicesKeys = Object.keys(multipleChoices);
    const multipleChoicesRadioKeys = Object.keys(multipleChoicesRadio);
    const descriptiveAnswerKeys = Object.keys(descriptiveAnswer);
    const datePickerKeys = Object.keys(datePicker);
    const err1 = multipleChoicesKeys.filter(
      (key) => !multipleChoices[key].length
    );
    const err2 = multipleChoicesRadioKeys.filter(
      (key) => !multipleChoicesRadio[key]
    );
    const err3 = descriptiveAnswerKeys.filter(
      (key) => !descriptiveAnswer[key].trim().length
    );
    const err4 = datePickerKeys.filter(
      (key) => !datePicker[key].length
    );
    return err1.length || err2.length || err3.length || err4.length;
  };

  getQuestionsCountByQuestionStatus = () => {
    const {
      multipleChoices,
      multipleChoicesRadio,
      descriptiveAnswer,
      optionalQuestionIds,
      hiddenQuestionIds,
      datePicker
    } = this.state;
    // Here we will merge all answered questions to one single object i.e. { 23:'', 24:[250,251], 25:252 }
    const totalQuestionsObj = { ...multipleChoices, ...multipleChoicesRadio, ...descriptiveAnswer, ...datePicker };
    // Here we will create array of keys (question ids) i.e. [23,24,25]
    const totalQuestionKeys = Object.keys(totalQuestionsObj);
    // Variable to store total count of answered questions
    let totalFilledQuestionsCount = 0;
    if (totalQuestionKeys.length) {
      // Here we are checking if question id is exists in either optionalQuestionIds array or hiddenQuestionIds array
      // Then we are considering only REQUIRED question status so we have a count of all answered questions which have REQUIRED question status
      totalFilledQuestionsCount = totalQuestionKeys.filter((id) => optionalQuestionIds.indexOf(+id) === -1 && hiddenQuestionIds.indexOf(+id) === -1).length;
    }
    return totalFilledQuestionsCount;
  };

  // Here we are rendering form questions
  renderFormQuestions = ({ formQuestions }) => {
    // Here we are using index variable because if questionStatus is HIDDEN then question count is not proper
    // If we are using index inside array.map function
    let index = 0;
    return formQuestions.map((question) => {
      if (question.questionStatus === HIDDEN) {
        return null;
      }
      index++;
      return (
        <div className="form-responses" key={question.id}>
          <span className="question-number">{index}. </span>
          <span className="subHeading">{question.questionTitle}</span>
          {this.displayOptionBasedOnQuestionType(question)}
        </div>
      );
    })
  };

  // Here this function is used to minimize user forms
  minimizeUserForm = () => {
    const {
      store: {
        FormMessageStore: {
          selectedForm,
          changeUserformsVisible,
          setIsFormPendingWizardVisible,
          userFormsStateCopy,
          setUserFormsStateCopy,
        },
        ProfileStore: { username, fullname, userId },
      },
    } = this.props;
    const { totalRequiredQuestionsCount } = this.state;
    // Here we're taking "totalFilledQuestionsCount" and "totalRequiredQuestionsCount" to show the progress in form pending wizard
    const totalFilledQuestionsCount = this.getQuestionsCountByQuestionStatus();
    // Here we're taking the username, fullname and selected form name from selected profile's user form when form is about to minimize
    let usernameToDisplay = username;
    let fullNameToDisplay = fullname;
    let selectedFormName = selectedForm.name;
    let selectedUserId = userId;
    // If user's profile is not opened but form pending wizard is opened then we have to store user's information in MobX state when user try to minimize again
    // so for that we we will simply use the "userFormsStateCopy" and take all the user information and save it again in the MobX state  
    if (userFormsStateCopy && Object.keys(userFormsStateCopy).length) {
      const { profileUsername, profileFullname, profileSelectedFormName, profileUserId } = userFormsStateCopy;
      usernameToDisplay = profileUsername;
      fullNameToDisplay = profileFullname;
      selectedFormName = profileSelectedFormName;
      selectedUserId = profileUserId;
    }
    // here we're setting the local state data + user info data + selected form name data with current progress to the MobX state
    // The purpose of this state is to show the information about pending form everywhere in the app
    setUserFormsStateCopy({
      ...this.state,
      profileUsername: usernameToDisplay,
      profileFullname: fullNameToDisplay,
      profileSelectedFormName: selectedFormName,
      profileUserId: selectedUserId,
      progress: Math.trunc((totalFilledQuestionsCount * 100) / totalRequiredQuestionsCount)
    });
    // Here we will close the user forms modal
    changeUserformsVisible(false);
    // Now the form pending wizard will be visible
    setIsFormPendingWizardVisible(true);
    mixpanel.track("User Form Minimized", {
      from: "WEB",
      username: usernameToDisplay,
      fullname: fullNameToDisplay,
      selectedFormName: selectedFormName
    });
  };

  // Here this function is used to close user form when clicking on close icon
  closeUserForm = () => {
    const {
      store: {
        FormMessageStore: {
          changeUserformsVisible,
          setUserFormsStateCopy,
          resetPrefilledAnswers,
          resetTouchpointDataForDocument,
          setUserFormData,
          setFormOpenMethod
        },
      },
    } = this.props;
    // Here we will close the user forms modal
    changeUserformsVisible(false);
    // Here we will reset prefilled answers
    resetPrefilledAnswers();
    // Here we will reset touchpoint data for document
    resetTouchpointDataForDocument();
    // Here we will reset the user forms state copy 
    setUserFormsStateCopy({});
    // We'll reset the value of form open method
    setFormOpenMethod(null);
    // Here we will reset the data of user forms
    setUserFormData({});
  };

  render() {
    const {
      store: {
        FormMessageStore: {
          selectedForm,
          loading,
          formQuestions,
          isSubmitLoading
        },
        ProfileStore: { username, fullname },
        AuthStore: { type }
      },
    } = this.props;
    const { totalRequiredQuestionsCount, profileUsername, profileFullname, profileSelectedFormName } = this.state;
    const totalFilledQuestionsCount = this.getQuestionsCountByQuestionStatus();

    let disabled = true;
    if (totalFilledQuestionsCount < totalRequiredQuestionsCount) {
      disabled = true;
    } else {
      disabled = this.isDisabled();
    }
    return (
      <div className="form-response">
        <h1 className="form-heading">
          {loading && <Spin />}
          <div>
            <span className="fa fa-user fa-usericon" />
            <span className="username-label">{profileUsername || username}&nbsp;({profileFullname || fullname}):</span>
            <span>&nbsp;{profileSelectedFormName || selectedForm.name}</span>
          </div>
          <div className="user-form-progress-bar">
            <div className="form-progress-title">Progress</div>
            <Progress
              percent={Math.trunc((totalFilledQuestionsCount * 100) / totalRequiredQuestionsCount)}
              strokeColor="#FB9048"
              trailColor="#fff"
            />
          </div>
          <div className="user-form-action-buttons">
            {ALIKE_MODERATOR.includes(type) ? <Tooltip title="Minimize Form" placement="bottom">
              <img
                className="form-heading-minimize-img"
                src={IMG_MINIMIZE_WINDOW}
                onClick={this.minimizeUserForm}
                alt="Close"
              />
            </Tooltip> : null}
            <Tooltip title="Close Form" placement="bottom">
              <img
                className="form-heading-close-img"
                src={close}
                onClick={this.closeUserForm}
                alt="Close"
              />
            </Tooltip>
          </div>
        </h1>
        <div className="forms-container">
          {this.renderFormQuestions({ formQuestions })}
          <div className="submit-container">
            {isSubmitLoading ? <Spin /> : <button
              className="new-referral-button"
              onClick={this.updateUserFormResponses}
              disabled={disabled}
              style={{ marginBottom: 150 }}
            >
              Submit
            </button>}
          </div>
        </div>
      </div>
    );
  }
};

export default UserForms;
