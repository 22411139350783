import { inject, observer } from "mobx-react";

import React from "react";
import { Spin } from "antd";
import UserDeleted from "./UserDeleted";
import UserUnDeleted from "./UserUnDeleted";
import undeleted from "../../static/images/undeleted.png";
import close from "../../static/images/close.png";

@inject("store")
@observer
class UserMessagesContainer extends React.Component {
  fetchUnDeletedMessages = () => {
    const {
      store: {
        ProfileStore: { userId },
        DeletedMessagesStore: {
          setShowUnDeleted,
          fetchUserUnDeletedMessages,
          userUnDeletedLoading
        }
      }
    } = this.props;
    if (userUnDeletedLoading) {
      return;
    }
    fetchUserUnDeletedMessages(userId);
    setShowUnDeleted(true);
  };

  render() {
    const {
      store: {
        ProfileStore: { loading, username },
        DeletedMessagesStore: {
          setShowDeleted,
          setShowUnDeleted,
          resetDeletedMessages,
          resetUnDeletedMessages
        }
      },
      showdeleted
    } = this.props;

    return showdeleted ? (
      <React.Fragment>
        <div className="user-flags">
          <span className="username">Deleted Messages of {username}</span>
          <img
            src={close}
            onClick={() => {
              resetDeletedMessages();
              setShowDeleted(false);
            }}
            alt="Close"
          />
        </div>
        <div
          className="show-undeleted-messages"
          onClick={() => this.fetchUnDeletedMessages()}
        >
          <img
            src={undeleted}
            className="actions-container undeleted-icon"
            alt="UnDeleted"
          />
          <span>Show UnDeleted Messages</span>
        </div>
        <UserDeleted />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="user-flags">
          <span className="username">UnDeleted Messages of {username}</span>
          <img
            src={close}
            onClick={() => {
              resetUnDeletedMessages();
              setShowUnDeleted(false);
            }}
            alt="Close"
          />
        </div>
        <UserUnDeleted />
      </React.Fragment>
    );
  }
}
export default UserMessagesContainer;
