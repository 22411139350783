import React, { useState } from 'react'
import { Col, Modal, Row, Button, Spin, Icon, notification, Input, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { inject, observer } from "mobx-react";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import imageCompression from 'browser-image-compression';
import IconTint from 'react-icon-tint';
import mixpanel from "mixpanel-browser";

import DragAndDrop from './DragAndDrop';
import { decryptData, encryptData, isUrlValid } from '../../utils/CommonUtils';
import LinkPreview from './LinkPreview';
import { addForumFeedPost, getLinkPreviewData } from '../../utils/forumFeedService';
import { REACT_APP_GET_FEED_GROUP } from '../../configs/EnvConfig';
import UserAvatar from '../protected/UserAvatar';
import { DISCARD_ALL_IMAGE } from '../../utils/ImageUtils';
import { TIME_FILTERS } from '../../constants/GlobalConstant';

const { TextArea } = Input;
const { CheckableTag } = Tag;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const CreateForumPost = inject("store")(observer((props) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [shareURL, setShareURL] = useState('');
    const [showAttachments, setShowAttachments] = useState(false);
    const [showTag, setShowTag] = useState(false);
    const [links, setLinks] = useState([]);
    const [selectedSite, setSelectedSite] = useState([]);
    const [selectedBadges, setSelectedBadges] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isLinkPreviewLoading, setIsLinkPreviewLoading] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);

    const {
        ForumFeedStore: {
            visible,
            addPostLoading,
            tags,
            filteredTags,
            setFilteredTags,
            categories,
            uploadImages,
            setUploadImages,
        },
        AuthStore: {
            type,
            username,
            id,
        }
    } = props.store;

    const resetData = async () => {
        setTitle('');
        setDescription('');
        setShareURL('');
        setShowAttachments(false);
        setShowTag(false);
        setLinks([]);
        setSelectedSite([]);
        setSelectedBadges([]);
        setSelectedTags([]);
        setSelectedCategories([]);
        setUploadImages([]);
        setIsImageUploading(false);
    }

    const onDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const onCancel = async () => {
        const {
            store: {
                ForumFeedStore: {
                    setVisible
                }
            }
        } = props;
        setVisible(false);
        resetData();
    }

    const showNotification = () => {
        notification.success({
            message: "Success",
            description: "A new post successfully created!",
            placement: 'bottomRight',
        });
    }

    const getImageSize = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                resolve({ width: this.width, height: this.height });
            };
            img.onerror = function () {
                reject(new Error("Could not load image"));
            };
            img.src = URL.createObjectURL(file);
        });
    }

    const doUploadMedia = async (files) => {
        const { uploadMedia } = props.store.ForumFeedStore;
        const images = [];
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        await Promise.all(
            files.map(async (file) => {
                const compressedFile = await imageCompression(file, options);
                const response = await uploadMedia("feed", { 'image': [compressedFile, file?.name] });
                if (response && response?.data?.imgUrl) {
                    images.push({
                        size: compressedFile?.size,
                        type: file?.type,
                        url: `/${response.data.imgUrl.split("/").pop()}`,
                        ...(await getImageSize(compressedFile)),
                    });
                }
            }));
        return images;
    }

    const validateCategory = () => {
        if (selectedCategories.length == 0) {
            notification.destroy();
            notification.error({
                message: "Error",
                description: "Please select category",
                placement: "bottomRight",
            });
        }
    }

    const doAddPost = async () => {
        const {
            setAddPostLoading,
            setVisible,
            categories,
            uploadImages,
            feeds,
            setFeeds,
            fetchFeeds,
            setSelectedTimeFilter,
            selectedTimeFilter,
        } = props.store.ForumFeedStore;
        try {
            validateCategory();
            setAddPostLoading(true);
            const { feedGroupId: categoryGroupId } = categories.find(category => category.id === selectedCategories[0]);

            const originalData = {
                title,
                censoredText: {
                    title: [],
                    description: []
                },
                description,
                images: uploadImages,
                links,
                tags: selectedTags,
                post_audience: {
                    sites: selectedSite,
                    badges: selectedBadges
                },
                categories: selectedCategories,
            };
            const encrypted = encryptData(originalData);

            // prepare payload for add activity
            const activityData = {
                verb: "post",
                meta: encrypted,
                to: [`${REACT_APP_GET_FEED_GROUP}:${categoryGroupId}`],
                foreign_id: new Date().getTime(),
                deleted_at: null,
                time: new Date(),
                object: "forum",
            };

            if (selectedTimeFilter !== TIME_FILTERS.NEW_POSTS) {
                mixpanel.track(`Forum Total Time For ${selectedTimeFilter}`, { from: "WEB" });
                mixpanel.time_event(`Forum Total Time For ${TIME_FILTERS.NEW_POSTS}`);
            }
            setSelectedTimeFilter(TIME_FILTERS.NEW_POSTS);

            // add activity
            const result = await addForumFeedPost(activityData);
            result.meta = decryptData(result?.meta);

            let extraMixpanelData = {
                hasImages: originalData.images.length > 0,
                imagesCount: originalData.images.length,
                hasLinks: links.length > 0,
                tags: tags.filter(availableTag => selectedTags?.some?.(tag => availableTag?.id === tag)).map(tag => tag.tag),
                selectedCategory: categories?.filter(category => selectedCategories?.[0] === category.id)?.[0]?.category ?? selectedCategories,
            };
            mixpanel.track("Forum Post Created", {
                from: "WEB",
                data: JSON.stringify(result),
                activity_id: result?.id,
                ...extraMixpanelData
            });

            setFeeds([]);
            await fetchFeeds();

            // show success toast
            showNotification();

            // clean-up
            setAddPostLoading(false);
            setVisible(false);
            await resetData();
        } catch (error) {
            setAddPostLoading(false);
            Sentry.captureException(
                new Error(`Error on add post :: ${error.message}`)
            );
        }
    }

    const onDropImage = async (files) => {
        const {
            uploadImages,
            setUploadImages,
        } = props.store.ForumFeedStore;
        setShowAttachments(false);
        if (uploadImages.length + files.length > 5) {
            notification.destroy();
            notification.error({
                message: "Error",
                description: "Maximum 5 images allowed",
                placement: 'bottomRight',
            });
        } else {
            setIsImageUploading(true);

            const uploadedFilesData = await doUploadMedia(files);
            const uploadImagesClone = _.cloneDeep(uploadImages);
            uploadImagesClone.push(...uploadedFilesData);
            setUploadImages([...uploadImagesClone]);

            setIsImageUploading(false);
        }
    }

    const onTitleChange = (e) => {
        if (e.target.value.length > 80) {
            notification.destroy();
            notification.error({
                message: 'Error',
                description: 'Maximum allowed title length is 80',
                placement: 'bottomRight',
            });
        } else {
            setTitle(e.target.value);
        }
    }
    const onSearchChange = async (e) => {
        if (!e.target.value) {
            setFilteredTags([...tags]);
        } else {
            const searchedTags = await filterTagsByCharacter(e.target.value);
            setFilteredTags([...searchedTags]);
        }
    }

    const filterTagsByCharacter = async (character) => {
        character = character.toLowerCase();
        const allTags = _.cloneDeep(tags);
        return allTags.filter((tagEle) => {
            if (tagEle.tag.toLowerCase().includes(character))
                return tagEle;
        });
    }

    const checkShareURL = () => {
        if (shareURL) {
            if (!isUrlValid(shareURL)) {
                notification.error({
                    message: "Error",
                    description: "Please Enter Valid URL",
                    placement: 'bottomRight',
                });
            }
        }
    }

    const onShowAttachments = () => {
        setShowAttachments(!showAttachments)
    };

    const onShowTags = () => {
        setShowTag(!showTag);
    };

    const handleTagChange = (id, checked) => {
        if (checked) {
            if (selectedTags.length === 5) {
                notification.destroy();
                notification.error({
                    message: "Error",
                    description: "Maximum 5 tags allowed",
                    placement: 'bottomRight',
                });
            } else {
                setSelectedTags([...selectedTags, id]);
            }
        } else {
            const nextSelectedTags = selectedTags.filter((t) => t !== id);
            setSelectedTags(nextSelectedTags);
        }
    };

    const tagContainer = (id, color) => {
        const styleObject = {};

        if (!selectedTags.includes(id)) {
            styleObject['color'] = color;
            styleObject['borderColor'] = color
            styleObject['lineHeight'] = 2;
        } else {
            styleObject['backgroundColor'] = color;
            styleObject['color'] = 'white';
            styleObject['lineHeight'] = 2.2;
        }

        return styleObject;
    };

    const onDiscard = (index) => {
        const { uploadImages } = props.store.ForumFeedStore;
        const newImageFiles = _.cloneDeep(uploadImages);
        newImageFiles.splice(index, 1);
        setUploadImages([...newImageFiles]);
    };

    const removeURLFromList = (index) => {
        const newLinks = _.cloneDeep(links);
        newLinks.splice(index, 1);
        setLinks([...newLinks]);
    };

    const onChangeAddLinks = async (value) => {
        try {
            setIsLinkPreviewLoading(true);
            setShareURL('');
            if (links?.length === 5) {
                notification.destroy();
                notification.error({
                    message: "Error",
                    description: "Maximum 5 links allowed",
                    placement: 'bottomRight',
                });
                setIsLinkPreviewLoading(false);
                return;
            }
            if (isUrlValid(value)) {
                const {
                    title,
                    description,
                    images,
                    url,
                } = await getLinkPreviewData(value);

                setLinks([
                    ...links,
                    {
                        title,
                        description,
                        image: images?.[0]?.image || '',
                        url: url || value,
                    }
                ]);
            }
            setIsLinkPreviewLoading(false);
        } catch (error) {
            setIsLinkPreviewLoading(false);
            if (error?.error?.status_code === 400 && error?.error?.exception === "InputException") {
                setLinks([...links, { url: value }]);
            } else {
                notification.destroy();
                notification.error({ message: 'Error', description: error?.message, placement: 'bottomRight' });
            }
        }
    };

    const categoryContainer = (category, color) => {
        const styleObject = {
            borderRadius: '60px',
            marginTop: 7,
            cursor: 'pointer',
        };

        if (!selectedCategories.includes(category)) {
            styleObject['color'] = color;
            styleObject['borderColor'] = color;
            styleObject['lineHeight'] = 2;
        } else {
            styleObject['backgroundColor'] = color;
            styleObject['color'] = 'white';
            styleObject['lineHeight'] = 2.2;
        }
        return styleObject;
    };

    const handleCategoryChange = (id, checked) => {
        setSelectedCategories(checked ? [id] : []);
    };

    const onDiscardAllImages = () => {
        setUploadImages([]);
    }

    const isDisabled = () => {
        return (title == '' || description == '' || isImageUploading || !selectedCategories?.length);
    }

    return (
        <Modal
            width={"60%"}
            title={"Create Post"}
            onCancel={onCancel}
            visible={visible}
            maskClosable={false}
        >
            <UserAvatar
                style={{
                    marginLeft: 20,
                    marginTop: 20
                }}
                userData={{ username, type, id }}
                showBadge={false}
            />
            {/* title start */}
            <Row className='row-container'>
                <Col span={24}>
                    <span style={{ display: 'flex' }}>
                        <strong>Title <span style={{ color: "#EB4E2C" }}>*</span></strong>
                        <span style={{ marginLeft: 'auto' }}>{`(${title.length}/80)`}</span>
                    </span>
                    <Input
                        placeholder="Please Add Title"
                        value={title}
                        onChange={(e) => onTitleChange(e)} />
                </Col>
            </Row>
            {/* title end */}

            {/* description start */}
            <Row className='row-container'>
                <Col span={24}>
                    <span><strong>Description <span style={{ color: "#EB4E2C" }}>*</span></strong>
                    </span><br />
                    <TextArea
                        placeholder="Please Add Description"
                        value={description}
                        onChange={(event) => onDescriptionChange(event)} />
                </Col>
            </Row>

            {/* attachment start */}
            <Row className='row-container'>
                <Col span={24}>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={12}><span><strong>Add Images</strong></span></Col>
                        {(uploadImages?.length > 0) && <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span 
                                style={{ 
                                    cursor: 'pointer'
                                }} 
                                onClick={onDiscardAllImages}
                            >
                                <img src={DISCARD_ALL_IMAGE} alt="Discard All" style={{ marginBottom: '3px' }} />
                                <p style={{ textDecoration: 'underline', display: 'inline' }}>Discard All</p>
                            </span>
                        </Col>}
                    </Row>
                    <div
                        style={{ display: "inline" }}
                    // style={showAttachments ? { display: "inline" } : { display: "none" }}
                    >
                        {!isImageUploading ?
                            <DragAndDrop
                                primaryText={`Drag & drop to upload`}
                                type={'image'}
                                onDrop={(file) => onDropImage(file)}
                                uploadImages={uploadImages}
                                onDiscard={(index) => onDiscard(index)}
                            />
                            :
                            <Row>
                                <Col
                                    span={24}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '172px'
                                    }}
                                >
                                    <Spin 
                                        indicator={<LoadingOutlined />}
                                    /> &nbsp;Uploading...
                                </Col>
                            </Row>
                        }
                        {(uploadImages?.length > 0) && 
                            <>
                                <br />
                                <p id='upload-image-text'>I, hereby grant permission for the uploading of my image(s) on the Marigold health App</p>
                            </>
                        }
                    </div>
                </Col>
            </Row>
            {/* attachment end */}

            {/** links starts */}
            <Row className='row-container'>
                <Col span={24}>
                    <span><strong>Add Links</strong></span>
                    <br /><br />
                    <div style={{ display: "inline" }}>
                        <span><strong>Link</strong></span>
                        <Input
                            type="url"
                            placeholder="Add Link"
                            value={shareURL}
                            onChange={(e) => { setShareURL(e.target.value) }}
                            onBlur={(e) => checkShareURL(e)}
                        />

                        {shareURL && isUrlValid(shareURL) ?
                            <>
                                <Row>
                                    <Col span={11}></Col>
                                    <Col span={2}>
                                        <Button
                                            size='small'
                                            style={{
                                                backgroundColor: "rgb(249, 134, 84)",
                                                color: "white",
                                                marginTop: 10,
                                                borderRadius: 5
                                            }}
                                            onClick={e => { onChangeAddLinks(shareURL) }}
                                        >Add Link</Button>
                                    </Col>
                                    <Col span={11}></Col>

                                </Row>
                            </> : null
                        }

                    </div>
                </Col>
            </Row>
            {/* links end */}

            {/* Link preview starts */}
            <div style={links.length > 0 ? { display: "inline", marginTop: 10 } : { display: "none" }}>
                {links.map((item, index) => {
                    return (
                        <>
                            <LinkPreview
                                url={item?.url || (typeof item === 'string' ? item : '')}
                                removeURLFromList={removeURLFromList}
                                index={index}
                                {
                                ...(
                                    item?.title && item?.description
                                        ? { previewData: { title: item?.title, description: item?.description, image: item?.image } }
                                        : {}
                                )
                                }
                            />
                        </>)
                })}
            </div>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                {isLinkPreviewLoading && <Spin />}
            </div>
            {/* Link preview starts */}

            {/* Categories start */}
            <Row className='row-container'>
                <Col span={24}>
                    <span>
                        <strong>Categories & Tags <span style={{ color: "#EB4E2C" }}>*</span></strong>
                    </span>
                    <div style={{ marginTop: '10px' }}>
                        {categories.map(({ id, category, color, icon }) => {
                            const isChecked = selectedCategories.includes(id);
                            return (<>
                                <CheckableTag
                                    style={categoryContainer(id, color)}
                                    key={category}
                                    checked={isChecked}
                                    onChange={(checked) => handleCategoryChange(id, checked)}
                                >
                                    <Row id="create-post-feed-category">
                                        <Col style={{ height: '25px' }}>
                                            {
                                                (isChecked)
                                                ? <IconTint src={icon} id="icon" color="white" style={{ height: '22px' }} />
                                                : <img src={icon} style={{ height: '22px' }} />
                                            }
                                        </Col>
                                        <Col style={{ fontWeight: 500 }}>
                                            {category}
                                        </Col>
                                    </Row>
                                </CheckableTag>
                            </>)
                        })}
                        {/* Tags start */}
                        <div style={{ marginTop: 7, display: 'flex', flexWrap: 'wrap' }}>
                            {filteredTags.map(({ id, tag, color }) => {
                                return (<>
                                    <CheckableTag
                                        id="create-post-feed-tag"
                                        style={tagContainer(id, color)}
                                        key={tag}
                                        checked={selectedTags.includes(id)}
                                        onChange={(checked) => handleTagChange(id, checked)}
                                    ><strong>{tag}</strong>
                                    </CheckableTag>
                                </>)
                            })}
                        </div>
                        {/* Tags end */}
                    </div>
                </Col>
            </Row>
            {/* Categories end */}

            {/** add post button start */}
            <Row style={{ marginTop: 50 }}>
                <Col span={20}></Col>
                <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {addPostLoading ? <Spin indicator={antIcon} /> :
                        <Button
                            disabled={isDisabled()}
                            type="primary"
                            className='add-post-button'
                            onClick={() => doAddPost()}
                        >
                            Post
                        </Button>}
                </Col>
                <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        disabled={addPostLoading}
                        type="primary"
                        className='add-post-button'
                        style={{ 
                            backgroundColor: '#fff',
                            border: '1px solid #F98654',
                            color: '#F98654',
                            cursor: addPostLoading ? 'none' : 'pointer',
                        }}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
            {/** add post button end */}

        </Modal >
    )
}));

export default CreateForumPost;
