import { inject, observer } from "mobx-react";

import React from "react";
import history from "../utils/history";
import SecureLocalStorage from "../utils/SecureLS";
import { withRouter } from "react-router-dom";
import * as GeneralData from '../utils/GeneralData'

export const withAuth = (Component) =>
  @withRouter
  @inject("store")
  @observer
  class AuthComponent extends React.Component {
    async componentDidMount() {
      const {
        store: { AuthStore },
      } = this.props;
      let token = "";
      let userId = null;
      try {
        token = SecureLocalStorage.get("TOKEN");
        userId = SecureLocalStorage.get("USERID");
      } catch {
        token = localStorage.getItem("TOKEN");
        userId = localStorage.getItem("USERID");
      }
      if (!token || !userId) {
        history.push("/login");
      } else {
        AuthStore.setToken(token);
        AuthStore.setUserId(userId);
        await GeneralData.refreshIfNeeded()
      }

      return {};
    }

    render() {
      const {
        store: { AuthStore },
      } = this.props;

      return AuthStore.token ? <Component {...this.props} /> : null;
    }
  };
