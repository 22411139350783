import { types } from "mobx-state-tree";
import ApiService from "../utils/ApiService";
import DeletedMessage from "./DeletedMessage";
import Message from "./Message";
import { cloneDeep } from "lodash";
import {
  getMessageInfo,
  getPubnubInstanceByUserType,
} from "../utils/PubnubMethods";
import { toJS } from "mobx";
import AuthStore from "../stores/AuthStore";

const DeletedMessagesList = types
  .model("DeletedMessagesList", {
    userDeletedLoading: false,
    userUnDeletedLoading: false,
    userUnFlaggedLoading: false,
    groupDeletedLoading: false,
    groupUnDeletedLoading: false,
    showDeleted: false,
    showUnDeleted: false,
    showUnFlagged: false,
    showGroupDeleted: false,
    showGroupUnDeleted: false,
    userDeletedSkip: 0,
    userUnDeletedSkip: 0,
    userUnFlaggedSkip: 0,
    groupDeletedSkip: 0,
    groupUnDeletedSkip: 0,
    userDeletedTotal: 0,
    userUnDeletedTotal: 0,
    userUnFlaggedTotal: 0,
    groupDeletedTotal: 0,
    groupUnDeletedTotal: 0,
    groupNames: types.map(types.frozen({ name: types.string })),
    dmNames: types.map(types.frozen({ name: types.string })),
    userDeletedMessages: types.array(DeletedMessage),
    userUnDeletedMessages: types.array(DeletedMessage),
    userUnFlaggedMessages: types.array(DeletedMessage),
    groupDeletedMessages: types.array(DeletedMessage),
    groupUnDeletedMessages: types.array(DeletedMessage),
  })
  .actions((self) => ({
    resetDeletedMessages() {
      self.userDeletedSkip = 0;
      self.userDeletedTotal = 0;
      self.userDeletedMessages = [];
    },
    resetGroupUnDeletedMessages() {
      self.groupUnDeletedMessages = [];
      self.groupUnDeletedSkip = 0;
      self.groupUnDeletedTotal = 0;
    },
    resetGroupDeletedMessages() {
      self.groupDeletedMessages = [];
      self.groupDeletedSkip = 0;
      self.groupDeletedTotal = 0;
    },
    resetUnDeletedMessages() {
      self.userUnDeletedMessages = [];
      self.userUnDeletedSkip = 0;
      self.userUnDeletedTotal = 0;
    },
    resetUnFlaggedMessages() {
      self.userUnFlaggedMessages = [];
      self.userUnFlaggedSkip = 0;
      self.userUnFlaggedTotal = 0;
    },
    async getGroupNameFromIds(groupIds) {
      if (groupIds.length) {
        const query = groupIds.reduce((obj, id, index) => {
          const key = `id[${index}]`;
          if (!obj.hasOwnProperty(key)) {
            obj[key] = id;
          }
          return obj;
        }, {});
        const params = {
          "$select[1]": "id",
          "$select[2]": "name",
          ...query,
        };
        const response = await ApiService.getRequest("groups", params);
        return response.data;
      }
    },
    async getDmNameFromIds(dmIds) {
      if (dmIds.length) {
        const query = dmIds.reduce((obj, id, index) => {
          const key = `id[${index}]`;
          if (!obj.hasOwnProperty(key)) {
            obj[key] = id;
          }
          return obj;
        }, {});
        const params = {
          "$select[1]": "id",
          "$select[2]": "name",
          ...query,
        };
        const response = await ApiService.getRequest("dm-channels", params);
        return response.data;
      }
    },
    async fetchUserDeletedMessages(targetUserId) {
      const endPoint = "history";
      const params = {
        targetUserId,
        eventType: 7,
        reactionId: 3,
        $skip: self.userDeletedSkip,
      };
      self.setUserDeletedLoading(true);
      const deletedMessagesResponse = await ApiService.getRequest(
        endPoint,
        params
      );
      let promises = [];
      let groupIds = [];
      let dmIds = [];
      if (
        deletedMessagesResponse.success &&
        deletedMessagesResponse.data.length
      ) {
        self.setUserDeletedMeta(cloneDeep(deletedMessagesResponse.meta));
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        deletedMessagesResponse.data.forEach((item) => {
          const channelType = item.channelType;
          let channel = "";
          if (channelType === "GROUP") {
            groupIds.push(item.channelId);
            channel = `GROUP_CHAT_${item.channelId}`;
          } else if (channelType === "DIRECT") {
            dmIds.push(item.channelId);
            channel = `DIRECT_MESSAGE_${item.channelId}`;
          }

          promises.push(
            getMessageInfo(pubnub, {
              channel: channel,
              messageId: item.messageId,
            })
          );
        });
        const groupResponse = await self.getGroupNameFromIds(groupIds);
        if (groupResponse && groupResponse.length) {
          self.setGroupNames(groupResponse);
        }
        const dmResponse = await self.getDmNameFromIds(dmIds);
        if (dmResponse && dmResponse.length) {
          self.setDmNames(dmResponse);
        }

        Promise.all(promises).then((messageInfo) => {
          const deletedMessages = messageInfo.map((message, index) => {
            if (message && message.messages.length) {
              const deletedMessage = message.messages[0].entry;
              let obj = {
                type: deletedMessage.type,
                sender:
                  deletedMessagesResponse.data[index].targetUserUsername
                    .username,

                userId: deletedMessage.userId,
                userType: deletedMessage.userType,
                channelType: deletedMessagesResponse.data[index].channelType,
                deletedBy:
                  deletedMessagesResponse.data[index].sourceUserUsername
                    .username,
                channelId: deletedMessagesResponse.data[index].channelId,
                createdAt: deletedMessagesResponse.data[index].createdAt,
                text: deletedMessage.text,
                imgUrl: deletedMessage.imgUrl,
                senderType: deletedMessagesResponse.data[index].userType,
                messageId: message.messages[0].timetoken,
                messageReactionId:
                  deletedMessagesResponse.data[index].messageReactionId,
              };

              return DeletedMessage.create(obj);
            }
          });
          self.setUserDeletedMessages(deletedMessages);
          self.setUserDeletedLoading(false);
        });
      } else {
        self.setUserDeletedLoading(false);
      }
    },
    async fetchUserUnFlaggedMessages(targetUserId) {
      const endPoint = "history";
      const params = {
        targetUserId,
        eventType: 8,
        reactionId: 2,
        $skip: self.userUnFlaggedSkip,
      };
      self.setUserUnFlaggedLoading(true);
      const unFlaggedMessagesResponse = await ApiService.getRequest(
        endPoint,
        params
      );
      let promises = [];
      let groupIds = [];
      let dmIds = [];

      if (
        unFlaggedMessagesResponse.success &&
        unFlaggedMessagesResponse.data.length
      ) {
        self.setUserUnFlaggedMeta(cloneDeep(unFlaggedMessagesResponse.meta));
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        unFlaggedMessagesResponse.data.forEach((item) => {
          const channelType = item.channelType;
          let channel = "";
          if (channelType === "GROUP") {
            groupIds.push(item.channelId);
            channel = `GROUP_CHAT_${item.channelId}`;
          } else if (channelType === "DIRECT") {
            dmIds.push(item.channelId);
            channel = `DIRECT_MESSAGE_${item.channelId}`;
          }

          promises.push(
            getMessageInfo(pubnub, {
              channel: channel,
              messageId: item.messageId,
            })
          );
        });
        const groupResponse = await self.getGroupNameFromIds(groupIds);
        if (groupResponse && groupResponse.length) {
          self.setGroupNames(groupResponse);
        }
        const dmResponse = await self.getDmNameFromIds(dmIds);
        if (dmResponse && dmResponse.length) {
          self.setDmNames(dmResponse);
        }

        Promise.all(promises).then((messageInfo) => {
          const unFlaggedMessages = messageInfo.map((message, index) => {
            if (message && message.messages.length) {
              const unFlaggedMessage = message.messages[0].entry;
              let obj = {
                type: unFlaggedMessage.type,
                sender:
                  unFlaggedMessagesResponse.data[index].targetUserUsername
                    .username,
                id: unFlaggedMessagesResponse.data[index].id,
                userId: unFlaggedMessage.userId,
                userType: unFlaggedMessage.userType,
                channelType: unFlaggedMessagesResponse.data[index].channelType,
                deletedBy:
                  unFlaggedMessagesResponse.data[index].sourceUserUsername
                    .username,
                channelId: unFlaggedMessagesResponse.data[index].channelId,
                createdAt: unFlaggedMessagesResponse.data[index].createdAt,
                text: unFlaggedMessage.text,
                imgUrl: unFlaggedMessage.imgUrl,
                senderType: unFlaggedMessagesResponse.data[index].userType,
                messageId: message.messages[0].timetoken,
                messageReactionId:
                  unFlaggedMessagesResponse.data[index].messageReactionId,
              };

              return DeletedMessage.create(obj);
            }
          });
          self.setUserUnFlaggedMessages(unFlaggedMessages);
          self.setUserUnFlaggedLoading(false);
        });
      } else {
        self.setUserUnFlaggedLoading(false);
      }
    },
    setUserUnFlaggedLoading(value) {
      self.userUnFlaggedLoading = value;
    },
    async fetchUserUnDeletedMessages(targetUserId) {
      const endPoint = "history";
      const params = {
        targetUserId,
        eventType: 8,
        reactionId: 3,
        $skip: self.userUnDeletedSkip,
      };
      self.setUserUnDeletedLoading(true);
      const unDeletedMessagesResponse = await ApiService.getRequest(
        endPoint,
        params
      );
      let promises = [];
      let groupIds = [];
      let dmIds = [];
      if (
        unDeletedMessagesResponse.success &&
        unDeletedMessagesResponse.data.length
      ) {
        self.setUserUnDeletedMeta(cloneDeep(unDeletedMessagesResponse.meta));
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        unDeletedMessagesResponse.data.forEach((item) => {
          const channelType = item.channelType;
          let channel = "";
          if (channelType === "GROUP") {
            groupIds.push(item.channelId);
            channel = `GROUP_CHAT_${item.channelId}`;
          } else if (channelType === "DIRECT") {
            dmIds.push(item.channelId);
            channel = `DIRECT_MESSAGE_${item.channelId}`;
          }

          promises.push(
            getMessageInfo(pubnub, {
              channel: channel,
              messageId: item.messageId,
            })
          );
        });
        const groupResponse = await self.getGroupNameFromIds(groupIds);
        if (groupResponse && groupResponse.length) {
          self.setGroupNames(groupResponse);
        }
        const dmResponse = await self.getDmNameFromIds(dmIds);
        if (dmResponse && dmResponse.length) {
          self.setDmNames(dmResponse);
        }

        Promise.all(promises).then((messageInfo) => {
          const unDeletedMessages = messageInfo.map((message, index) => {
            if (message && message.messages.length) {
              const unDeletedMessage = message.messages[0].entry;
              let obj = {
                type: unDeletedMessage.type,
                sender:
                  unDeletedMessagesResponse.data[index].targetUserUsername
                    .username,

                userId: unDeletedMessage.userId,
                userType: unDeletedMessage.userType,
                channelType: unDeletedMessagesResponse.data[index].channelType,
                deletedBy:
                  unDeletedMessagesResponse.data[index].sourceUserUsername
                    .username,
                channelId: unDeletedMessagesResponse.data[index].channelId,
                createdAt: unDeletedMessagesResponse.data[index].createdAt,
                text: unDeletedMessage.text,
                imgUrl: unDeletedMessage.imgUrl,
                senderType: unDeletedMessagesResponse.data[index].userType,
                messageId: message.messages[0].timetoken,
                messageReactionId:
                  unDeletedMessagesResponse.data[index].messageReactionId,
              };

              return DeletedMessage.create(obj);
            }
          });
          self.setUserUnDeletedMessages(unDeletedMessages);
          self.setUserUnDeletedLoading(false);
        });
      } else {
        self.setUserUnDeletedLoading(false);
      }
    },

    async fetchGroupUnDeletedMessages(channelId) {
      const endPoint = "history";
      const params = {
        channelId,
        channelType: "GROUP",
        eventType: 8,
        reactionId: 3,
        $skip: self.groupUnDeletedSkip,
      };
      self.setGroupUnDeletedLoading(true);
      const groupUnDeletedMessagesResponse = await ApiService.getRequest(
        endPoint,
        params
      );
      let promises = [];
      let groupIds = [];
      let dmIds = [];
      if (
        groupUnDeletedMessagesResponse.success &&
        groupUnDeletedMessagesResponse.data.length
      ) {
        self.setGroupUnDeletedMeta(
          cloneDeep(groupUnDeletedMessagesResponse.meta)
        );
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        groupUnDeletedMessagesResponse.data.forEach((item) => {
          const channelType = item.channelType;
          let channel = "";
          if (channelType === "GROUP") {
            groupIds.push(item.channelId);
            channel = `GROUP_CHAT_${item.channelId}`;
          } else if (channelType === "DIRECT") {
            dmIds.push(item.channelId);
            channel = `DIRECT_MESSAGE_${item.channelId}`;
          }

          promises.push(
            getMessageInfo(pubnub, {
              channel: channel,
              messageId: item.messageId,
            })
          );
        });
        const groupResponse = await self.getGroupNameFromIds(groupIds);
        if (groupResponse && groupResponse.length) {
          self.setGroupNames(groupResponse);
        }
        const dmResponse = await self.getDmNameFromIds(dmIds);
        if (dmResponse && dmResponse.length) {
          self.setDmNames(dmResponse);
        }

        Promise.all(promises).then((messageInfo) => {
          const unDeletedMessages = messageInfo.map((message, index) => {
            if (message && message.messages.length) {
              const unDeletedMessage = message.messages[0].entry;
              let obj = {
                type: unDeletedMessage.type,
                sender:
                  groupUnDeletedMessagesResponse.data[index].targetUserUsername
                    .username,

                userId: unDeletedMessage.userId,
                userType: unDeletedMessage.userType,
                channelType:
                  groupUnDeletedMessagesResponse.data[index].channelType,
                deletedBy:
                  groupUnDeletedMessagesResponse.data[index].sourceUserUsername
                    .username,
                channelId: groupUnDeletedMessagesResponse.data[index].channelId,
                createdAt: groupUnDeletedMessagesResponse.data[index].createdAt,
                text: unDeletedMessage.text,
                imgUrl: unDeletedMessage.imgUrl,
                senderType: groupUnDeletedMessagesResponse.data[index].userType,
                messageId: message.messages[0].timetoken,
                messageReactionId:
                  groupUnDeletedMessagesResponse.data[index].messageReactionId,
              };

              return DeletedMessage.create(obj);
            }
          });
          self.setGroupUnDeletedMessages(unDeletedMessages);
          self.setGroupUnDeletedLoading(false);
        });
      } else {
        self.setGroupUnDeletedLoading(false);
      }
    },
    async fetchGroupDeletedMessages(channelId) {
      const endPoint = "history";
      const params = {
        channelId,
        channelType: "GROUP",
        eventType: 7,
        reactionId: 3,
        $skip: self.groupDeletedSkip,
      };
      self.setGroupDeletedLoading(true);
      const groupDeletedMessagesResponse = await ApiService.getRequest(
        endPoint,
        params
      );
      let promises = [];
      let groupIds = [];
      let dmIds = [];
      if (
        groupDeletedMessagesResponse.success &&
        groupDeletedMessagesResponse.data.length
      ) {
        self.setGroupDeletedMeta(cloneDeep(groupDeletedMessagesResponse.meta));
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        groupDeletedMessagesResponse.data.forEach((item) => {
          const channelType = item.channelType;
          let channel = "";
          if (channelType === "GROUP") {
            groupIds.push(item.channelId);
            channel = `GROUP_CHAT_${item.channelId}`;
          } else if (channelType === "DIRECT") {
            dmIds.push(item.channelId);
            channel = `DIRECT_MESSAGE_${item.channelId}`;
          }

          promises.push(
            getMessageInfo(pubnub, {
              channel: channel,
              messageId: item.messageId,
            })
          );
        });
        const groupResponse = await self.getGroupNameFromIds(groupIds);
        if (groupResponse && groupResponse.length) {
          self.setGroupNames(groupResponse);
        }
        const dmResponse = await self.getDmNameFromIds(dmIds);
        if (dmResponse && dmResponse.length) {
          self.setDmNames(dmResponse);
        }

        Promise.all(promises).then((messageInfo) => {
          const deletedMessages = messageInfo.map((message, index) => {
            if (message && message.messages.length) {
              const unDeletedMessage = message.messages[0].entry;
              let obj = {
                type: unDeletedMessage.type,
                sender:
                  groupDeletedMessagesResponse.data[index].targetUserUsername
                    .username,

                userId: unDeletedMessage.userId,
                userType: unDeletedMessage.userType,
                channelType:
                  groupDeletedMessagesResponse.data[index].channelType,
                deletedBy:
                  groupDeletedMessagesResponse.data[index].sourceUserUsername
                    .username,
                channelId: groupDeletedMessagesResponse.data[index].channelId,
                createdAt: groupDeletedMessagesResponse.data[index].createdAt,
                text: unDeletedMessage.text,
                imgUrl: unDeletedMessage.imgUrl,
                senderType: groupDeletedMessagesResponse.data[index].userType,
                messageId: message.messages[0].timetoken,
                messageReactionId:
                  groupDeletedMessagesResponse.data[index].messageReactionId,
              };

              return DeletedMessage.create(obj);
            }
          });
          self.setGroupDeletedMessages(deletedMessages);
          self.setGroupDeletedLoading(false);
        });
      } else {
        self.setGroupDeletedLoading(false);
      }
    },
    setGroupNames(data) {
      data.forEach((group) => {
        if (!self.groupNames.has(group.id)) {
          self.groupNames.set(group.id, group.name);
        }
      });
    },
    setDmNames(data) {
      data.forEach((dm) => {
        if (!self.dmNames.has(dm.id)) {
          self.dmNames.set(dm.id, dm.name);
        }
      });
    },
    setUserUnDeletedMessages(data) {
      self.userUnDeletedMessages = [...self.userUnDeletedMessages, ...data];
    },
    setUserUnFlaggedMessages(data) {
      self.userUnFlaggedMessages = [...self.userUnFlaggedMessages, ...data];
    },
    setGroupUnDeletedMessages(data) {
      self.groupUnDeletedMessages = [...self.groupUnDeletedMessages, ...data];
    },
    setUserDeletedMessages(data) {
      self.userDeletedMessages = [...self.userDeletedMessages, ...data];
    },
    setGroupDeletedMessages(data) {
      self.groupDeletedMessages = [...self.groupDeletedMessages, ...data];
    },
    setUserUnDeletedMeta(meta) {
      self.userUnDeletedSkip = self.userUnDeletedSkip + meta.limit;
      self.userUnDeletedTotal = meta.total;
    },

    setUserUnFlaggedMeta(meta) {
      self.userUnFlaggedSkip = self.userUnFlaggedSkip + meta.limit;
      self.userUnFlaggedTotal = meta.total;
    },

    setGroupUnDeletedMeta(meta) {
      self.groupUnDeletedSkip = self.groupUnDeletedSkip + meta.limit;
      self.groupUnDeletedTotal = meta.total;
    },
    setUserDeletedMeta(meta) {
      self.userDeletedSkip = self.userDeletedSkip + meta.limit;
      self.userDeletedTotal = meta.total;
    },
    setGroupDeletedMeta(meta) {
      self.groupDeletedSkip = self.groupDeletedSkip + meta.limit;
      self.groupDeletedTotal = meta.total;
    },
    setGroupDeletedLoading(value) {
      self.groupDeletedLoading = value;
    },
    setShowUnDeleted(value) {
      self.showUnDeleted = value;
    },
    setShowUnFlagged(value) {
      self.showUnFlagged = value;
    },
    setShowDeleted(value) {
      self.showDeleted = value;
    },
    setShowGroupDeleted(value) {
      self.showGroupDeleted = value;
    },
    setShowGroupUnDeleted(value) {
      self.showGroupUnDeleted = value;
    },
    setUserUnDeletedLoading(value) {
      self.userUnDeletedLoading = value;
    },
    setGroupUnDeletedLoading(value) {
      self.groupUnDeletedLoading = value;
    },
    setUserDeletedLoading(value) {
      self.userDeletedLoading = value;
    },
  }));

export default DeletedMessagesList;
