import S3 from "aws-s3";
import AuthStore from "../stores/AuthStore";
import SecureLocalStorage from "./SecureLS";

let ACCESS_KEY = "";
let SECRET_KEY = "";
try {
  ACCESS_KEY = SecureLocalStorage.get("ACCESS_KEY");
  SECRET_KEY = SecureLocalStorage.get("SECRET_KEY");
} catch {
  ACCESS_KEY = localStorage.getItem("ACCESS_KEY");
  SECRET_KEY = localStorage.getItem("SECRET_KEY");
}

const config = {
  bucketName: "cleanslate-marigold",
  region: "us-east-2",
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
  key: "",
};
const S3Client = new S3(config);

const initializeS3Instance = () => {
  let ACCESS_KEY = "";
  let SECRET_KEY = "";
  try {
    ACCESS_KEY = SecureLocalStorage.get("ACCESS_KEY");
    SECRET_KEY = SecureLocalStorage.get("SECRET_KEY");
  } catch {
    ACCESS_KEY = localStorage.getItem("ACCESS_KEY");
    SECRET_KEY = localStorage.getItem("SECRET_KEY");
  }
  const config = {
    bucketName: "cleanslate-marigold",
    region: "us-east-2",
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
    key: "",
  };
  return new S3(config);
};

export function ImageUpload(fileName) {
  const S3Client = initializeS3Instance();
  return new Promise((resolve, reject) => {
    S3Client.uploadFile(fileName)
      .then((data) => {
        resolve(data.location);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function documentUpload(fileName) {
  const S3Client = initializeS3Instance();
  return new Promise((resolve, reject) => {
    S3Client.uploadFile(fileName)
      .then((data) => {
        resolve(data.location);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
