import React from "react";
import { Modal, Spin } from "antd";

const CustomImageModal = (props) => {
    const {
        title,
        onOk,
        onCancel,
        visible,
        imageLink
    } = props;

    return (
        <Modal
            width={"900px"}
            title={title}
            onOk={onOk}
            onCancel={onCancel}
            visible={visible}
        >
            <div style={{
                border: '2px solid #FF4D00',
                borderRadius: 4,
                height: 350,
                overflow: "auto"
            }}>
                <img src={imageLink} style={{ width: '100%', height: '100%', }} />
            </div>
        </Modal>
    );
};

export default CustomImageModal;