import {
    IMG_ENROLLED_DELAWARE,
    IMG_ELIGIBLE_DELAWARE,
    IMG_ENROLLED_HP,
    IMG_ELIGIBLE_HP,
    IMG_ENROLLED_IHP,
    IMG_ELIGIBLE_IHP,
    IMG_ENROLLED_STUDY,
    IMG_ELIGIBLE_STUDY,
    IMG_ENROLLED_WOODRIVER,
    IMG_ELIGIBLE_WOODRIVER,
    IMG_ENROLLED_WOODRIVER_IHP,
    IMG_ELIGIBLE_WOODRIVER_IHP,
    IMG_ENROLLED_ACDC,
    IMG_ELIGIBLE_ACDC,
    IMG_ENROLLED_CTC,
    IMG_ELIGIBLE_CTC
} from '../utils/ImageUtils';

export const BADGE_TYPE_ELIGIBLE_COLOR = '#ffb224';
export const BADGE_TYPE_ENROLLED_COLOR = '#108ee9';
export const BADGE_TYPE_REMOVE = 'REMOVE_BADGE';

export const USER_BADGES = {
    ENROLLED_DELAWARE: 'Confirmed ACDE Enrollment',
    ENROLLED_HP: 'Confirmed HP Enrollment',
    ENROLLED_IHP: 'Confirmed IHP Enrollment',
    ENROLLED_STUDY: 'Confirmed CCA Enrollment',
    ENROLLED_WOODRIVER: 'Confirmed Woodriver Enrollment',
    ENROLLED_WOODRIVER_IHP: 'Confirmed Woodriver + IHP Enrollment',
    ENROLLED_ACDC: 'Confirmed ACDC Enrollment',
    ENROLLED_CTC: 'Confirmed CTC Enrollment',
    ELIGIBLE_DELAWARE: 'Assumed/Eligible ACDE',
    ELIGIBLE_HP: 'Assumed/Eligible HP',
    ELIGIBLE_IHP: 'Assumed/Eligible IHP',
    ELIGIBLE_STUDY: 'Assumed/Eligible CCA',
    ELIGIBLE_WOODRIVER: 'Assumed/Eligible Woodriver',
    ELIGIBLE_WOODRIVER_IHP: 'Assumed/Eligible Woodriver + IHP',
    ELIGIBLE_ACDC: 'Assumed/Eligible ACDC',
    ELIGIBLE_CTC: 'Assumed/Eligible CTC',
};

export const USER_BADGE_IMAGES = {
    ENROLLED_DELAWARE: IMG_ENROLLED_DELAWARE,
    ENROLLED_HP: IMG_ENROLLED_HP,
    ENROLLED_IHP: IMG_ENROLLED_IHP,
    ENROLLED_STUDY: IMG_ENROLLED_STUDY,
    ENROLLED_WOODRIVER: IMG_ENROLLED_WOODRIVER,
    ENROLLED_WOODRIVER_IHP: IMG_ENROLLED_WOODRIVER_IHP,
    ENROLLED_ACDC: IMG_ENROLLED_ACDC,
    ENROLLED_CTC: IMG_ENROLLED_CTC,
    ELIGIBLE_DELAWARE: IMG_ELIGIBLE_DELAWARE,
    ELIGIBLE_HP: IMG_ELIGIBLE_HP,
    ELIGIBLE_IHP: IMG_ELIGIBLE_IHP,
    ELIGIBLE_STUDY: IMG_ELIGIBLE_STUDY,
    ELIGIBLE_WOODRIVER: IMG_ELIGIBLE_WOODRIVER,
    ELIGIBLE_WOODRIVER_IHP: IMG_ELIGIBLE_WOODRIVER_IHP,
    ELIGIBLE_ACDC: IMG_ELIGIBLE_ACDC,
    ELIGIBLE_CTC: IMG_ELIGIBLE_CTC,
};

export const USER_BADGES_ENROLLED_FLAGS = {
    ENROLLED_DELAWARE: 'ENROLLED_DELAWARE',
    ENROLLED_HP: 'ENROLLED_HP',
    ENROLLED_IHP: 'ENROLLED_IHP',
    ENROLLED_STUDY: 'ENROLLED_STUDY',
    ENROLLED_WOODRIVER: 'ENROLLED_WOODRIVER',
    ENROLLED_WOODRIVER_IHP: 'ENROLLED_WOODRIVER_IHP',
    ENROLLED_ACDC: 'ENROLLED_ACDC',
    ENROLLED_CTC: 'ENROLLED_CTC',
};

export const USER_BADGES_ELIGIBLE_FLAGS = {
    ELIGIBLE_DELAWARE: 'ELIGIBLE_DELAWARE',
    ELIGIBLE_HP: 'ELIGIBLE_HP',
    ELIGIBLE_IHP: 'ELIGIBLE_IHP',
    ELIGIBLE_STUDY: 'ELIGIBLE_STUDY',
    ELIGIBLE_WOODRIVER: 'ELIGIBLE_WOODRIVER',
    ELIGIBLE_WOODRIVER_IHP: 'ELIGIBLE_WOODRIVER_IHP',
    ELIGIBLE_ACDC: 'ELIGIBLE_ACDC',
    ELIGIBLE_CTC: 'ELIGIBLE_CTC',
};

export const USER_BADGES_FLAGS = {
    ...USER_BADGES_ENROLLED_FLAGS,
    ...USER_BADGES_ELIGIBLE_FLAGS
};