import { getFromSecureLS } from "./CommonUtils";
import ApiService from "./ApiService";
import SecureLocalStorage from "./SecureLS";
import { getPubnubInstanceByUserType } from "./PubnubMethods";
import AuthStore from "../stores/AuthStore";
const mixpanel = require("mixpanel-browser");
const EventEmitter2 = require('eventemitter2').EventEmitter2;
export const emitter = new EventEmitter2();

const PUBNUB_AUTH_TOKEN = "PUBNUB_AUTH_TOKEN";

export async function pubNubCallWithRetry (pubNubFunction) {
  const currentPubNubToken = await getCurrentPubNubToken();
  try {
    return await pubNubFunction();
  } catch (e) {
    if (e.status.statusCode === 403) {
      mixpanel.track("Token expired", {
        status: JSON.stringify(e?.status),
        expiredToken: currentPubNubToken,
        from: "WEB",
      });
      await refreshPubNubAuthToken(currentPubNubToken);
      return await pubNubFunction();
    }
  }
}

export async function getCurrentPubNubToken () {
  const currentToken = getFromSecureLS(PUBNUB_AUTH_TOKEN);

  if (!currentToken) {
    return refreshPubNubAuthToken();
  }

  return currentToken;
}

export async function refreshPubNubAuthToken (oldPubNubToken = "", fromAddListener = false) {
  try {
    const response = await ApiService.postRequest("pubnub-auth", {
      oldPubNubToken,
    });

    const newPubNubToken = response.data.pubNubToken;
    SecureLocalStorage.set(PUBNUB_AUTH_TOKEN, newPubNubToken);
    const pubnub = getPubnubInstanceByUserType(AuthStore.type);

    if (pubnub) {
      pubnub.setToken(newPubNubToken);
    }

    mixpanel.track("Token reissued", {
      oldPubNubToken,
      newPubNubToken,
      from: "WEB",
    });

    if (fromAddListener) {
      emitter.emit("REFRESH_PUBNUB_LISTENER")
    }

    return newPubNubToken;
  } catch (error) {
    mixpanel.track("Token reissue failed", {
      oldPubNubToken,
      error,
      from: "WEB",
    });
  }
}

export async function sendNotificationWithPubNubAuth (channel, message) {
  return ApiService.postRequest("pubnub-notification", {
    channel,
    message,
  });
}
