import { notification } from "antd";
import { REACT_APP_REACTION_FEED_GROUP_ID, REACT_APP_GET_FEED_GROUP ,REACT_APP_GET_FEED_ID} from "../configs/EnvConfig";
import { GET_FEED_LIMIT, GET_COMMENT_LIMIT, FEED_SUBSCRIPTION_ACTION, TIME_FILTERS, REACTION_TYPES } from "../constants/GlobalConstant";
import ForumFeedStore from "../stores/ForumFeedStore";
import AuthStore from "../stores/AuthStore";

import { setStreamInstance, streamClient } from "./streamInstance";
import _ from "lodash";
import { decryptData, encryptData } from "./CommonUtils";
import mixpanel from "mixpanel-browser";

const getStreamChanel = async (ch = REACT_APP_GET_FEED_GROUP, id = REACT_APP_GET_FEED_ID) => {
    try {
        if (!streamClient) {
            await setStreamInstance();
        }
        return streamClient.feed(ch, id);
    } catch (err) {
        throw err;
    }
};

export const addForumFeedPost = async (payload) => {
    try {
        let chanel = await getStreamChanel(REACT_APP_GET_FEED_GROUP, REACT_APP_GET_FEED_ID);
        return await chanel.addActivity(payload);
    } catch (err) {
        throw err;
    }
};

const decryptedFeed = (feed) => {
    const actor = typeof feed?.actor === 'string' ? JSON.parse(feed?.actor) : feed?.actor;
    return {
        ...feed, 
        meta: decryptData(feed.meta),
        actor: {
            ...actor,
            data: actor?.data?.encryptedData 
                    ? decryptData(actor?.data?.encryptedData) 
                    : actor?.data,
        }
    }
}

const decryptedComment = (comment) => {
    return {
        ...comment, 
        data: decryptData(comment?.data?.data),
        user: {
            ...comment?.user,
            data: comment?.user?.data?.encryptedData 
                    ? decryptData(comment?.user?.data?.encryptedData) 
                    : comment?.user?.data,
        }
    };
}

export const getRankingForSelectedTimeFilter = (selectedTimeFilter) => {
    if (selectedTimeFilter === TIME_FILTERS.HOT_POSTS) {
        return 'hot_posts';
    }
    if (selectedTimeFilter === TIME_FILTERS.TOP_POSTS_ALL_TIME) {
        return  'top_most_all_time';
    }
    if (selectedTimeFilter === TIME_FILTERS.RECENTLY_COMMENTED) {
        return 'recent_commented_posts';
    }
}

export const doLoadPosts = async () => {
    try {
        const { 
            selectedCategoryFilter, 
            selectedTimeFilter,
            feeds,
        } = ForumFeedStore;
        const params = {
            limit: GET_FEED_LIMIT,
            withOwnReactions: true,
            withReactionCounts: true,
            offset: feeds?.length || 0,
        };

        const chanel = await getStreamChanel(REACT_APP_GET_FEED_GROUP, selectedCategoryFilter);

        params['ranking'] = getRankingForSelectedTimeFilter(selectedTimeFilter);
        const result = await chanel.get(params);

        if (params.ranking) {
            const filterDetail = {
                from: "WEB",
                ranking: params.ranking,
                feedGroupId: selectedCategoryFilter,
                slug: REACT_APP_GET_FEED_GROUP,
            };
            mixpanel.track("Forum Filter Applied", filterDetail);
        }

        return result.results.map((feed) => {
            let actor = feed?.actor;
            if (typeof feed?.actor === 'string') {
                actor = JSON.parse(actor);
            }
            return {
                ...feed,
                meta: decryptData(feed.meta),
                actor: {
                    ...actor,
                    data: actor?.data?.encryptedData
                            ? decryptData(actor?.data?.encryptedData)
                            : actor?.data,
                }
            };
        });
    } catch (err) {
        throw err;
    }
};

export const getReactions = async (activityId, type, lastReactionId) => {
    const params = {
        activity_id: activityId,
        kind: type,
        limit: GET_COMMENT_LIMIT,
        with_own_children: true,
    };
    if (lastReactionId) {
        params['id_lt'] = lastReactionId;
    }
    const response = await streamClient?.reactions?.filter(params);
    return response?.results?.map(comment => ({
        ...comment,
        data: decryptData(comment?.data?.data),
        user: {
            ...comment?.user,
            data: comment?.user?.data?.encryptedData
                    ? decryptData(comment?.user?.data?.encryptedData)
                    : comment?.user?.data,
        }
    }));
}

/** this works for both reaction & child-reactions */
export const deleteReaction = (reactionId) => {
    return streamClient?.reactions.delete(reactionId);
};

export const addReaction = (reactionType, activityId, data, userId) => {
    return streamClient?.reactions.add(
        reactionType, 
        activityId, 
        { data: encryptData(data) },
        { 
            targetFeeds: [`${REACT_APP_GET_FEED_GROUP}:${REACT_APP_REACTION_FEED_GROUP_ID}`],
            userId
        }, 
    );
};

export const addChildReaction = (type, commentId, targetFeedsExtraData) => {
    return streamClient?.reactions.addChild(
        type, 
        commentId,
        undefined,
        {
            targetFeeds: [`${REACT_APP_GET_FEED_GROUP}:${REACT_APP_REACTION_FEED_GROUP_ID}`],
            targetFeedsExtraData,
        }
    );
};

export const getLinkPreviewData = (link) => {
    return streamClient?.og(link);
};

export const subscribeFeedNotifications = async () => {
    try {
        const { selectedCategoryFilter } = ForumFeedStore;
        const notificationFeed = await getStreamChanel(REACT_APP_GET_FEED_GROUP, selectedCategoryFilter);

        const callback = async (data) => {
            const { feeds, visible, setFeeds, selectedTimeFilter } = ForumFeedStore; 
            const feedsClone = _.cloneDeep(feeds);

            let action;
            if (data?.new?.length > 0 && data?.new[0]?.verb === "post") {
                action = FEED_SUBSCRIPTION_ACTION.CREATED;
            } else if (data?.deleted?.length) {
                action = FEED_SUBSCRIPTION_ACTION.DELETED;
            }

            switch (action) {
                case FEED_SUBSCRIPTION_ACTION.CREATED:
                    if (selectedTimeFilter !== TIME_FILTERS.NEW_POSTS) break;
                    const {userId: loggedInUserId} = AuthStore;
                    const activityUserId = +data?.new[0]?.actor?.id;
                    if(visible && activityUserId === +loggedInUserId) break;
                    const activityId = data?.new[0]?.id;
                    const index = feedsClone.findIndex(ele => ele?.id === activityId);
                    const results = await getActivitiesByIds([ activityId ]);
                    const newActivity = results[0];
                    if (index !== -1) {
                        feedsClone[index] = newActivity;
                    } else {
                        feedsClone.unshift(newActivity);
                    }
                    setFeeds([...feedsClone]);
                    break;

                case FEED_SUBSCRIPTION_ACTION.DELETED:
                    const { isFeedCommentVisible, setIsFeedCommentVisible, currentFeedId } = ForumFeedStore;
                    if (isFeedCommentVisible && data?.deleted[0] === currentFeedId) {
                        setIsFeedCommentVisible(false);
                        notification.error({
                            message: "Error",
                            description: "Post deleted",
                            placement: 'bottomRight',
                        })
                    }
                    const newFeeds = feedsClone.filter(feed => !data?.deleted?.includes(feed?.id));
                    setFeeds([...newFeeds]);
                    break;
            
                default:
                    break;
            }
        };

        await notificationFeed.subscribe(callback);
    } catch(error) {
        throw error;
    }
}

export const unsubscribeFeed = async (feedGroup, feedGroupId) => {
    const channel = await getStreamChanel(feedGroup, feedGroupId);
    await channel.unsubscribe();
}

export const subscribeReactionNotification = async () => {
    try {
        const reactionChannel = await getStreamChanel(REACT_APP_GET_FEED_GROUP, REACT_APP_REACTION_FEED_GROUP_ID);

        const callback = async (data) => {
            const { feeds, setFeeds } = ForumFeedStore;
            const feedsClone = _.cloneDeep(feeds);

            if (data?.new?.length) {
                const newReaction = _.get(data, `new.0.reaction`);
                const activityId = newReaction?.activity_id;
                const feedIndex = feedsClone.findIndex(feed => feed.id === activityId);
                const channel = await getStreamChanel(REACT_APP_GET_FEED_GROUP, REACT_APP_GET_FEED_ID);
                
                if (feedIndex !== -1) {
                    const reactionType = newReaction?.kind;
                    const allowedReactions = Object.values(REACTION_TYPES);
                    if (allowedReactions.includes(reactionType)) {
                        // if new reaction is child reaction
                        const commentId = _.get(data, `new.0.commentId`);
                        if (commentId) {
                            const { 
                                isFeedCommentVisible,
                                currentFeedId,
                                comments,
                                setComments,
                            } = ForumFeedStore;
                            const index = comments.findIndex(ele => ele?.id === commentId);
                            if (isFeedCommentVisible && index !== -1 && activityId === currentFeedId) {
                                const reactions = await streamClient?.reactions.filter({
                                    with_own_children: true,
                                    activity_id: activityId,
                                    kind: REACTION_TYPES.comment,
                                    limit: 1,
                                    id_lte: commentId,
                                });
                    
                                const commentsClone = _.cloneDeep(comments);
                                commentsClone[index] = decryptedComment(reactions?.results[0]);
                                setComments([...commentsClone]);
                            }

                        } else {
                            /**
                             * if same user && not added yet then make changes
                             * if different user than increament count only
                            */
                            const feedIndex = feedsClone.findIndex(feed => feed.id === activityId);
                            if (feedIndex != -1) {
                                const { results } = await channel?.get({
                                    limit: 1,
                                    withOwnReactions: true,
                                    withReactionCounts: true,
                                    id_gte: activityId
                                });
                                feedsClone[feedIndex] = decryptedFeed(results[0]);
                                setFeeds([...feedsClone]);
                            }

                            // if comment screen open & new comment added 
                            if (newReaction?.kind === REACTION_TYPES.comment) {
                                const { 
                                    isFeedCommentVisible, 
                                    currentFeedId,
                                    comments,
                                    setComments,
                                } = ForumFeedStore;
                                if (isFeedCommentVisible && activityId === currentFeedId) {
                                    const index = comments.findIndex(ele => ele?.id === newReaction?.id);
                                    const commentsClone = _.cloneDeep(comments);
                                    const decryptedReaction = decryptedComment(newReaction);
                                    if (index === -1) {
                                        commentsClone.unshift(decryptedReaction);
                                    } else {
                                        commentsClone[index] = decryptedReaction;
                                    }
                                    setComments([...commentsClone]);
                                }
                            }

                            // if comment deleted
                            if (reactionType === REACTION_TYPES.uncomment) {
                                const reactionId = decryptData(newReaction?.data?.data).reactionId;
                                const { 
                                    isFeedCommentVisible, 
                                    currentFeedId,
                                    comments,
                                    setComments,
                                } = ForumFeedStore;
                                
                                // if same comment's screen was open
                                if (isFeedCommentVisible && activityId === currentFeedId) {
                                    const index = comments.findIndex(ele => ele?.id === reactionId);
                                    if (index !== -1) {
                                        const commentsClone = _.cloneDeep(comments);
                                        commentsClone.splice(index, 1);
                                        setComments([...commentsClone]);
                                    }
                                }
                            }
                        }
                    }
                }                 
            }
        };

        await reactionChannel.subscribe(callback);
    } catch(error) {
        throw error;
    }
}

export const getActivitiesByIds = async (ids) => {
    const { results } = await streamClient?.getActivities({ 
        ids,
        withOwnReactions: true,
        withReactionCounts: true,
    });
    return results.map((feed) => {
        if (typeof feed?.actor === 'string') {
            feed.actor = JSON.parse(feed?.actor);
        }
        return {
            ...feed,
            meta: decryptData(feed.meta),
            actor: {
                ...feed?.actor,
                data: feed?.actor?.data?.encryptedData
                        ? decryptData(feed?.actor?.data?.encryptedData)
                        : feed?.actor?.data,
            }
        }
    });
}

export const fetchNearbyFeeds = async (id) => {
    try {
        const { setFeeds, setLoading } = ForumFeedStore;
        setLoading(true);

        // get previous & next feeds
        const channel = await getStreamChanel(REACT_APP_GET_FEED_GROUP, REACT_APP_GET_FEED_ID);
        const params = {
            withOwnReactions: true,
            withReactionCounts: true,
            id_gte: id,
            limit: 10,
        };
        const previousFeeds = await channel.get(params);
        delete params.id_gte;
        params["id_lt"] = id;
        const nextFeeds = await channel.get(params);

        const feeds = [
            ...previousFeeds.results.map((feed) => ({
                ...feed,
                meta: decryptData(feed.meta),
                actor: {
                    ...feed?.actor,
                    data: feed?.actor?.data?.encryptedData
                            ? decryptData(feed?.actor?.data?.encryptedData)
                            : feed?.actor?.data,
                }
            })),
            ...nextFeeds.results.map((feed) => ({
                ...feed,
                meta: decryptData(feed.meta),
                actor: {
                    ...feed?.actor,
                    data: feed?.actor?.data?.encryptedData
                            ? decryptData(feed?.actor?.data?.encryptedData)
                            : feed?.actor?.data,
                }
            }))
        ]

        setFeeds([...feeds]);
        setLoading(false);
        return feeds;
    } catch(error) {
        throw error;
    }
}

export const fetchNearbyComments = async (activityId, commentId) => {
    try {
        const { setComments, setFetchCommentLoading } = ForumFeedStore;
        setFetchCommentLoading(true);
        const params = {
            activity_id: activityId,
            kind: REACTION_TYPES.comment,
            limit: 10,
            with_own_children: true,
            id_gte: commentId,
        };
        const previousComments = await streamClient?.reactions?.filter(params);

        delete params.id_gte;
        params["id_lt"] = commentId;
        const nextComments = await streamClient?.reactions?.filter(params);

        const comments = [
            ...previousComments?.results?.reverse().map(comment => ({
                ...comment,
                data: decryptData(comment?.data?.data),
                user: {
                    ...comment?.user,
                    data: comment?.user?.data?.encryptedData
                            ? decryptData(comment?.user?.data?.encryptedData)
                            : comment?.user?.data,
                }
            })),
            ...nextComments?.results?.map(comment => ({
                ...comment,
                data: decryptData(comment?.data?.data),
                user: {
                    ...comment?.user,
                    data: comment?.user?.data?.encryptedData
                            ? decryptData(comment?.user?.data?.encryptedData)
                            : comment?.user?.data,
                }
            }))
        ];
        setComments([...comments]);
        setFetchCommentLoading(false);
        return comments;
    } catch(error) {
        throw error;
    }
}
