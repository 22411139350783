import _ from "lodash";
import { types } from "mobx-state-tree";
import ApiService from "../utils/ApiService";

const Feed = types
  .model("Feed", {
    // feed listing
    visible: false,
    feeds: types.optional(types.frozen([])),
    hasMore: false,
    channel: "",
    loading: false,
    start: types.maybeNull(types.string),
    end: types.maybeNull(types.string),
    addPostLoading: false,
    postCreatedBy: types.maybeNull(types.string),
    postCreatedAt: types.maybeNull(types.number),
    // feed comments
    isFeedCommentVisible: false,
    fetchCommentLoading: false,
    selectedFeedTimetoken: "",
    selectedFeedData: types.optional(types.frozen({})),
    comments: types.optional(types.frozen([])),
    hasMoreComments: false,
    startComment: types.maybeNull(types.string),
    endComment: types.maybeNull(types.string),
    loadMoreLoading: false
  })
  .actions((self) => ({
    reset() {
      self.visible = false;
      self.feeds = [];
      self.hasMore = true;
      self.channel = "";
      self.loading = false;
      self.comments = [];
      self.start = "";
      self.end = "";
      self.addPostLoading = false;
      self.fetchCommentLoading = false;
      self.selectedFeedData = {};
    },
    resetFeedCommentMeta() {
      self.comments = [];
      self.hasMoreComments = false;
      self.startComment = null;
      self.endComment = null;
      self.loadMoreLoading = false;
    },
    resetPostMeta() {
      self.isFeedCommentVisible = false;
      self.postCreatedBy = "";
      self.postCreatedAt = null;
    },
    setVisible(value) {
      self.visible = value;
    },
    setFeeds(value) {
      self.feeds = value;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setLoading(value) {
      self.loading = value;
    },
    setAddPostLoading(value) {
      self.addPostLoading = value;
    },
    setStart(value) {
      self.start = value;
    },
    setIsFeedCommentVisible(value) {
      self.isFeedCommentVisible = value;
    },
    setPostMeta(value) {
      self.postCreatedBy = value.postCreatedBy;
      self.postCreatedAt = value.postCreatedAt;
    },
    setSelectedFeedData(data) {
      self.selectedFeedData = data;
    },
    setSelectedFeedTimetoken(value) {
      self.selectedFeedTimetoken = value;
    },
    setFetchCommentLoading(value) {
      self.fetchCommentLoading = value;
    },
    setComments(value) {
      self.comments = value;
    },
    setHasMoreComments(value) {
      self.hasMoreComments = value;
    },
    setStartComment(value) {
      self.startComment = value;
    },
    setLoadMoreLoading(value) {
      self.loadMoreLoading = value;
    },
    async uploadMedia(url, payload) {
      try {
        const response = await ApiService.postFormDataRequest(url, payload);
        return response.data;
      } catch (e) {
        throw e
      }
    },
  }))
  .views((self) => ({
  }));

export default Feed;