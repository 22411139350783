import React from "react";
import { inject, observer } from "mobx-react";

// Components
import Banner from "./Banner";

// Local Imports
import { MODERATOR, SA, NOA } from "../../constants/UserRolesConstant";
import { user, } from "../../en.json";
import "../../styles/style.scss";
import { IS_CS } from "../../utils/getEnvironment";
import close from "../../static/images/close.png";
import { isValidReplyingToMessage } from "../../utils/CommonUtils";
import ReplyContainer from "./ReplyContainer";
import LinkifiedUsername from "../UI/LinkifiedUsername";

@inject("store")
@observer
class RepliedMessageBox extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  openProfile = async (userId) => {
    const {
      store: {
        ProfileStore,
        MessagesStore: { setIsWaiting },
        MemberListStore,
        NotificationStore,
      },
    } = this.props;

    ProfileStore.reset();
    ProfileStore.setLoading(true);
    const member = await MemberListStore.getMemberDetails(userId);
    if (member) {
      ProfileStore.setMemberData(member);
      setIsWaiting(false);
      ProfileStore.changeSelectedAction("");
      ProfileStore.setMemberProfileVisibility(true);
      ProfileStore.toggleLabel(false);
    } else {
      NotificationStore.setNotification("error", user.userDoesNotExist);
    }
    ProfileStore.setLoading(false);
  };

  renderMessage = (message) => {
    const { type } = message;
    const typeAdd = type === "add";
    const {
      store: {
        GroupStore: { usernameToUserIdMap },
      }
    } = this.props;

    let messageContent;
    let isImg = false;

    if (type === "text") {
      messageContent = (
        <LinkifiedUsername
          text={message.text}
          usernameToUserIdMap={usernameToUserIdMap}
        />
      );
    } else if (typeAdd) {
      return message.groupId ? (
        <Banner groupId={message.groupId}/>
      ) : null;
    } else {
      isImg = true;
      messageContent = (
        <img className="img-message" src={message.imgUrl} alt="" />
      );
    }

    return (
      <div className={"msgcontainer" + (isImg ? " image-container" : "")}>
        {messageContent}
      </div>
    );
  };

  getSenderUsername = (message) => {
    const {
      store: {
        GroupStore: { userIdToUsernameMap },
      },
    } = this.props;

    const { sender: senderInMessage, userId } = message;
    return userIdToUsernameMap?.get(userId) ?? senderInMessage;
  }

  render () {
    let {
      groupId,
      message,
      clearReplyingToMessage,
      store: {
        GroupStore: { getColor },
        AuthStore: { type: userType },
      },
    } = this.props;

    if (!isValidReplyingToMessage(message)) {
      return null;
    }

    message = message.entry ?? message;

    const sender = this.getSenderUsername(message);
    const shouldOpenProfile = [MODERATOR, SA, NOA].includes(userType)
    const userColor = getColor(groupId, sender, message.userId);
    const showCloseButton = !!clearReplyingToMessage;

    return <div id="reply-message-box">
      <ReplyContainer userColor={userColor}>
        <div
          id="display-data"
          className="d-flex"
        >
          <div className="d-flex align-items-center reply-username-close-button-container">
            <span className="chat-username">
              {groupId === 105 && IS_CS ? null : (
                <small
                  style={{
                    color: userColor,
                  }}
                  className="username"
                  onClick={
                    () => shouldOpenProfile && this.openProfile(message.userId)
                  }
                >
                  {sender}
                </small>
              )}
            </span>
            {showCloseButton && <img
              id="reply-close-button"
              src={close}
              onClick={() => clearReplyingToMessage()}
              alt="Close"
            />}
          </div>

          <div className="d-flex chatText">
            {this.renderMessage(message)}
          </div>
        </div>
      </ReplyContainer>
    </div>
  }
}

export default RepliedMessageBox;
