import { inject, observer } from "mobx-react";

import React from "react";
import { changePasswordForm } from "../../en.json";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

@withRouter
@inject("store")
@observer
class ChangePassordForm extends React.Component {
  render() {
    const {
      store: {
        AuthStore: {
          setNewPasswordInput,
          setConfirmPasswordInput,
          isPasswordFilled,
          changePassword,
          loading
        }
      },
      location: { search }
    } = this.props;

    const { token } = queryString.parse(search);

    return (
      <React.Fragment>
        <h3 className="pt-2">{changePasswordForm.enterNewPass}</h3>
        <form
          onSubmit={e => {
            e.preventDefault();
            changePassword(token);
          }}
        >
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder={changePasswordForm.newPassPlaceholder}
              onChange={setNewPasswordInput}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder={changePasswordForm.confirmPassPlaceholder}
              onChange={setConfirmPasswordInput}
            />
          </div>
          <div>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={!isPasswordFilled || loading}
              style={{ position: "relative" }}
            >
              {loading && (
                <div
                  className="spinner-grow"
                  style={{
                    width: "16px",
                    height: "16px",
                    position: "absolute",
                    left: "35%",
                    top: "8px"
                  }}
                  role="status"
                >
                  <span className="sr-only">{changePasswordForm.loading}</span>
                </div>
              )}
              {changePasswordForm.changePass}
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
export default ChangePassordForm;
