import React from 'react';
import { BarChart, Bar, Label, LabelList, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SimpleBarChart = (props) => {
    const { data = [], isBothColumnRequired = false, primaryColor = '#F78F6A', secondaryColor = '#E54D24', firstDataKey = "", firstDataKeyLabel = "", secondDataKey = "", secondDataKeyLabel = "" } = props;

    return (
        <>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    width={600}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={firstDataKey} name={firstDataKeyLabel} fill={primaryColor}  >
                        <LabelList dataKey={firstDataKey} fontWeight="600" position="top" />
                    </Bar>
                    {isBothColumnRequired && <Bar dataKey={secondDataKey} name={secondDataKeyLabel} fill={secondaryColor}  >
                        <LabelList dataKey={secondDataKey} fontWeight="600" position="top" />
                    </Bar>}
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default SimpleBarChart;