import React from "react";
import moment from "moment";
import { toJS } from "mobx";
import { Button, Spin, Icon, Popover, Tag } from "antd";
import { inject, observer } from "mobx-react";

// Components
import ModeratorTag from "./ModeratorTag";
import FormModerator from "./FormModerator";
import Banner from "./Banner";
import FormUser from "./FormUser";
import UserBadges from "../UI/UserBadges";
import CustomFormModal from "../UI/CustomFormModal";
import { CustomBanner } from "../UI/CustomBanner";

// Local Imports
import {
  SUBMITTED_FROM_TYPE_ADMIN,
  CHAT_ACTIVE_TAB_GROUP,
  CHAT_ACTIVE_TAB_DM,
  CHAT_ACTIVE_TAB_QUEUE,
  FORM_OPEN_METHOD_DOCUMENT,
  FORM_NAME_APP_ENCOUNTER_TEMPLATE_V2
} from "../../constants/GlobalConstant";
import { ALIKE_MODERATOR, MODERATOR, SA } from "../../constants/UserRolesConstant";
import { chat, user, reactions, } from "../../en.json";
import flag from "../../static/images/flag.png";
import enrolled from "../../static/images/enrolled.png";
import heart from "../../static/images/heart.png";
import hug from "../../static/images/hug.png";
import lol from "../../static/images/lol.png";
import bulb from "../../static/images/bulb.png";
import "../../styles/style.scss";
import orangeFlag from "../../static/images/orangeFlag.png";
import redFlag from "../../static/images/redFlag.png";
import blueFlag from "../../static/images/blueFlag.png";
import ApiService from "../../utils/ApiService";
import { generateTouchpointChannelStringForDocument, isValidReplyingToMessage } from "../../utils/CommonUtils";
import { getEmoji } from "../../utils/getEmoji";
import { IS_CS } from "../../utils/getEnvironment";
import { ulid } from '../../utils/getRandomId';
import { IMG_MENTION } from "../../utils/ImageUtils";
import RepliedMessageBox from "./RepliedMessageBox";
import LinkifiedUsername from "../UI/LinkifiedUsername";

@inject("store")
@observer
class MessageBubble extends React.Component {
  constructor() {
    super();
    this.state = {
      formListVisible: false
    };
  }

  openProfile = async () => {
    const {
      message: {
        entry: { userId },
      },
      store: {
        ProfileStore,
        MessagesStore: { setIsWaiting },
        MemberListStore,
        NotificationStore,
      },
    } = this.props;

    ProfileStore.reset();
    ProfileStore.setLoading(true);
    await MemberListStore.fetchAdminUsers();
    const member = await MemberListStore.getMemberDetails(userId);
    if (member) {
      ProfileStore.setMemberData(member);
      setIsWaiting(false);
      ProfileStore.changeSelectedAction("");
      ProfileStore.setMemberProfileVisibility(true);
      ProfileStore.toggleLabel(false);
    } else {
      NotificationStore.setNotification("error", user.userDoesNotExist);
    }
    ProfileStore.setLoading(false);
  };

  reactToMessage = (reactionId, isUnflag) => {
    const { message } = this.props;
    const {
      store: {
        MessagesStore: { showFlagOptionPopup },
      },
    } = this.props;

    if (!isUnflag && (reactionId === 7 || reactionId === 8)) {
      showFlagOptionPopup(message, reactionId);
    }
    else {
      message.saveReaction(reactionId);
    }
  };

  // Here we're checking if form pending wizard is open or not
  checkValidationOfFormPendingWizard = () => {
    const {
      store: {
        FormMessageStore: {
          isFormPendingWizardVisible,
          setIsFormPendingConfirmModalVisible,
          setFormOpenMethod,
          setUserFormData
        },
      }
    } = this.props;
    // If there is already a form in progress then we will show a confirmation popup to user 
    if (isFormPendingWizardVisible) {
      // Here we'll show the confirm modal to user if another form is in progress
      setIsFormPendingConfirmModalVisible(true);
      // We'll set one flag in MobX state as we have clicked on "Document" button
      setFormOpenMethod(FORM_OPEN_METHOD_DOCUMENT);
      // Here we'll pass the whole "fillDocument" function to the "userFormsData"
      // as we have to call that function if user confirms it
      setUserFormData({ fillDocument: this.fillDocument });
      return;
    }
    // If form pending wizard is not visible then we will simple that form for user
    this.fillDocument();
  };

  fillDocument = async () => {
    const {
      isWaiting,
      message: { entry, timetoken },
      store: {
        AuthStore: { userId: adminUserId },
        FormMessageStore: {
          formList,
          setUserFormId,
          getFormQuestionsMod,
          setSelectedForm,
          setIsAdminFillingTheForm,
          changeUserformsVisible,
          setTouchpointDataForDocument,
          setUserGoalsAnswer,
          setIsDocumentFormLoading,
          isDocumentFormLoading,
          setFormStartTime
        },
        MessagesStore: {
          selectedGroup,
          activeTab,
        },
        NotificationStore,
        ProfileStore: {
          fetchFullnameByUsername,
          fetchWellnessPlanFormByUser,
          setUserBasicDetails
        }
      }
    } = this.props;

    // If there is an already API call then we are returning from function 
    if (isDocumentFormLoading) return;
    try {
      const { userId } = entry;
      const sender = this.getSenderUsername();
      // If user id is not available in the chat message payload (i.e. in Queue Message) then we are showing error to the user.
      if (!userId) {
        NotificationStore.setNotification("error", chat.documentErrorForBotMessages);
        return;
      }
      // Here we are finding that "App Encounter Template" is available or not in formList array
      const formObj = formList.find((obj) => obj.name === FORM_NAME_APP_ENCOUNTER_TEMPLATE_V2);
      if (formObj) {
        // We are changing state of isDocumentFormLoading to true as we need to show loading spinner in "Document" button
        setIsDocumentFormLoading(true);
        // Here we are making an API call to fetch fullName by using username
        const usersData = await fetchFullnameByUsername(sender, userId);
        // If we are getting false value or array with 0 length then we are showing error to user that "User does not exist"
        if (!usersData || !usersData.length) {
          // We are changing state of isDocumentFormLoading to false as we need to hide loading spinner in "Document" button
          setIsDocumentFormLoading(false);
          NotificationStore.setNotification("error", chat.documentErrorForDeletedUser);
          return;
        }
        const [userDetailsObj] = usersData;
        // If we have a value in userDetailObj then we will take fullName otherwise we will consider username (default value) as fullName 
        const fullName = userDetailsObj ? userDetailsObj.fullName : sender;
        const username = userDetailsObj ? userDetailsObj.username : sender;
        // We are taking date from message timestamp to MM/DD/YYYY format
        const touchpointDate = moment(+timetoken / 10000).format('MM/DD/YYYY');
        // By default, touchpoint medium and touchpoint channel data is related to group
        let touchpointMedium = CHAT_ACTIVE_TAB_GROUP;
        let touchpointChannel = selectedGroup.name;
        // Here if we are getting click event in Queue Message then we are assigning values for touchpoint medium & touchpoint channel
        if (isWaiting) {
          touchpointMedium = CHAT_ACTIVE_TAB_QUEUE;
          touchpointChannel = generateTouchpointChannelStringForDocument({
            initialStr: 'Queue message',
            usernamesArr: [selectedGroup.name, 'marigold team']
          });
        } else if (activeTab === CHAT_ACTIVE_TAB_DM) {
          // Here if we are getting click event in DM then we are assigning values for touchpoint medium & touchpoint channel
          touchpointMedium = CHAT_ACTIVE_TAB_DM;
          touchpointChannel = generateTouchpointChannelStringForDocument({
            initialStr: 'Direct message',
            usernamesArr: selectedGroup.name.split(',')
          });
        }

        const { id: formId, name } = formObj;
        const messageId = `${ulid() * 10000}`;
        const params = {
          userId,
          formId,
          submittedFrom: SUBMITTED_FROM_TYPE_ADMIN,
          submittedFromId: adminUserId,
          messageId,
        };
        // Here we are fetching latest wellness plan form filled by user/admin
        const wellnessFormDataForUser = await fetchWellnessPlanFormByUser({ userId, isOnlyQuestionsDataRequired: true });
        let answer = '';
        // If we are getting the wellness form details then we need the answer for first question from goalsSequence
        if (wellnessFormDataForUser) {
          const { goalsSequence, questions } = wellnessFormDataForUser;
          const [firstQuestionId] = goalsSequence;
          const questionResponse = questions.find((question) => question.id === firstQuestionId);
          if (questionResponse && questionResponse.responses && questionResponse.responses.length) {
            answer = questionResponse.responses[0].answer;
          }
        }
        // Setting the answer in mobx state for user goals answer
        setUserGoalsAnswer(answer);
        // Setting the touchpoint data for document
        setTouchpointDataForDocument({ touchpointDate, touchpointMedium, touchpointChannel });
        // We are calling the user-forms API to create a entry for the user with respective form
        const userFormsResponse = await ApiService.postRequest("user-forms", params);
        const { success, data } = userFormsResponse;
        if (success) {
          // Setting the user form id and user basic details to the mobx state
          setUserFormId(data.id);
          setUserBasicDetails(username, userId, fullName);
          // Fetching the form questions by using form id
          await getFormQuestionsMod(formId);
          setSelectedForm(formId, messageId, name);
          setIsAdminFillingTheForm(true);
          changeUserformsVisible(true);
          setFormStartTime(Date.now());
        }
        // We are changing state of isDocumentFormLoading to false as we need to hide loading spinner in "Document" button
        setIsDocumentFormLoading(false);
      } else {
        // If we are not getting any forms then we are showing error to user i.e. "Form is not available"
        NotificationStore.setNotification("error", chat.formNotAvailable);
      }
    } catch (error) {
      // If we are getting any error then we need to show error i.e. "Unable to fill document for that user"
      setIsDocumentFormLoading(false);
      NotificationStore.setNotification("error", chat.documentFillError);
    }
  };

  getFormsList = async (e) => {
    try {
      const {
        message,
        store: {
          FormMessageStore: { fetchAllFormsInListing },
          ProfileStore: { setUserBasicDetails, fetchFullnameByUsername }
        }
      } = this.props;
      const { userId } = message.entry;
      const sender = this.getSenderUsername();
      fetchAllFormsInListing();
      let data = await fetchFullnameByUsername(sender, userId);
      let fullname = sender;
      let username = sender;

      if (data.length) {
        fullname = data[0].fullName;
        username = data[0].username;
      }
      setUserBasicDetails(username, userId, fullname);
      this.changeFormsListVisible(true);
    } catch (e) {
      alert(e)
    }
  };

  loadMoreFormsListing = () => {
    const {
      store: {
        FormMessageStore: { loading, fetchAllFormsInListing }
      },
    } = this.props;
    if (loading) return;
    fetchAllFormsInListing();
  };

  changeFormsListVisible = (value) => {
    this.setState({
      formListVisible: value,
    });
  };

  renderMessage = (message, isDeleted) => {
    const {
      store: {
        AuthStore: { type: userType },
        GroupStore: { usernameToUserIdMap },
      },
    } = this.props;
    const { type } = message.entry;
    const typeAdd = type === "add";
    const typeCustomBanner = type === "customBanner";
    const typeForm = type === "form";
    const { scrollToBottom } = this.props;

    let messageContent;
    if (type === "text") {
      messageContent = (
        <LinkifiedUsername
          text={message.entry.text}
          usernameToUserIdMap={usernameToUserIdMap}
        />
      );
    } else if (typeAdd) {
      return message.entry.groupId ? (
        <Banner groupId={message.entry.groupId} />
      ) : null;
    } else if (typeCustomBanner) {
      return (
        <CustomBanner
          emojiName={message.entry.emojiName}
          messageText={message.entry.message.trim()}
        />
      );
    } else if (typeForm) {
      if (ALIKE_MODERATOR.includes(userType)) {
        return (
          <FormModerator
            timetoken={message.timetoken}
            formId={message.entry.formId}
          />
        );
      }
      return (
        <FormUser
          timetoken={message.timetoken}
          formId={message.entry.formId}
        />
      );

    } else {
      messageContent = (
        <img className="img-message" src={message.entry.imgUrl} alt="" onLoad={() => !!scrollToBottom && scrollToBottom(true, message.timetoken)} />
      );
    }

    return (
      <div className={"msgcontainer" + (isDeleted ? " delete" : "")}>
        {messageContent}
      </div>
    );
  };

  renderDocumentButton = () => {
    const {
      store: {
        AuthStore: { type },
        FormMessageStore: { isDocumentFormLoading },
        NotificationStore,
      },
    } = this.props;
    const isAdmin = type === MODERATOR;
    const isSuperAdmin = type === SA;

    return (<>
      {isAdmin || isSuperAdmin ? (
        <Button
          type="secondary"
          className="document-button"
          disabled={NotificationStore.active}
          onClick={this.checkValidationOfFormPendingWizard}
        >
          {isDocumentFormLoading ? <Spin /> : chat.document}
        </Button>
      ) : null}
    </>
    );
  };

  renderReplyButton = () => {
    const {
      onReply,
      message,
      store: {
        NotificationStore,
      },
    } = this.props;

    const showReplyButton = !!onReply && isValidReplyingToMessage(message);

    return (<>
        {showReplyButton ? (
          <Button
            type="secondary"
            className="reply-button"
            disabled={NotificationStore.active}
            onClick={() => onReply(message)}
          >
            {chat.reply}
          </Button>
        ) : null}
      </>
    );
  };

  reactions = (group) => {
    const {
      isWaiting,
      message,
      message: { hasReacted },
      store: {
        NotificationStore,
        AuthStore: { type },
      },
    } = this.props;
    const userType = type;
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    // show only flag & delete button if intersite group is available
    const isIntersiteGroup = group && group.isIntersiteGroup;
    // Here we're checking if message is sent by admin then we're hiding one option "Document" and we don't need to show that option to admin's messages. 
    const { userType: senderUserType } = message?.entry;
    const isMessageSentByAdmin = senderUserType && ALIKE_MODERATOR.includes(senderUserType);

    if (isWaiting) {
      return (<>
        <div className="reactions-container">
          {/* Button to add document/form fill from chat message */}
          {this.renderDocumentButton()}
        </div>
      </>);
    }

    return (
      <div className="reactions-container">
        {isIntersiteGroup ? null : (
          <>
            <div className="reaction-row">
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("like") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(1)}
              >
                <img src={heart} alt="likes" className="reaction" />
                <span className="reaction-name">Like</span>
              </div>
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("hug") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(4)}
              >
                <img src={hug} alt="Support" className="reaction" />
                <span className="reaction-name">Support</span>
              </div>
            </div>
            <div className="reaction-row">
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("lol") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(5)}
              >
                <img src={lol} alt="LOL" className="reaction" />
                <span className="reaction-name">LOL</span>
              </div>
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("bulb") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(6)}
              >
                <img src={bulb} alt="Inspiring" className="reaction" />
                <span className="reaction-name">Inspiring</span>
              </div>
            </div>
          </>
        )}
        <div className="flag-delete-container">
          {/* For admin flag and user flag */}
          {isAdmin || isSuperAdmin ? (
            <>
              <div className="reaction-row reaction-row-flag">
                <div
                  className={
                    "message-reactions clickable" +
                    (hasReacted("redFlag") ? " selected" : "")
                  }
                  onClick={() => this.reactToMessage(7, hasReacted("redFlag"))}
                >
                  <img src={redFlag} alt="flags" className="reaction" />
                  <span className="reaction-name">Flag</span>
                </div>
                <div
                  className={
                    "message-reactions clickable" +
                    (hasReacted("blueFlag") ? " selected" : "")
                  }
                  onClick={() => this.reactToMessage(8, hasReacted("blueFlag"))}
                >
                  <img src={blueFlag} alt="flags" className="reaction" />
                  <span className="reaction-name">Flag</span>
                </div>
              </div>
            </>
          ) :
            <div className="reaction-row reaction-row-flag">
              <div
                className={
                  "message-reactions clickable" +
                  (hasReacted("flag") ? " selected" : "")
                }
                onClick={() => this.reactToMessage(2, hasReacted("flag"))}
              >
                <img src={orangeFlag} alt="flags" className="reaction" />
                <span className="reaction-name">Flag</span>
              </div>
            </div>
          }
          {isAdmin || isSuperAdmin ? (
            <Button
              type="primary"
              className="fillform-button"
              disabled={NotificationStore.active}
              onClick={this.getFormsList}
            >
              {chat.fillForm}
            </Button>
          ) : null}
          {/* Button to add document/form fill from chat message */}
          {isMessageSentByAdmin ? null : this.renderDocumentButton()}
          {this.renderReplyButton()}
          {isAdmin || isSuperAdmin ? (
            <Button
              type="danger"
              disabled={NotificationStore.active}
              onClick={() =>
                NotificationStore.setConfirm(
                  () => this.reactToMessage(3),
                  message.isDeleted
                    ? reactions.restoreNotification
                    : reactions.deleteNotification
                )
              }
            >
              {message.isDeleted ? chat.restore : chat.delete}
            </Button>
          ) : null}
        </div>
      </div>
    );
  };
  getUserType = (username, userId) => {
    const {
      groupChat,
      groupId,
      store: { GroupStore },
      message,
    } = this.props;

    let type = null;
    if (groupChat) {
      type = GroupStore.getUserType(groupId, username, userId);
    } else {
      return message.entry.userType;
    }
    return type;
  };

  renderBadge(badgeType) {
    return (<>
      {badgeType ? <UserBadges className="chat-user-badge" badgeType={badgeType} /> : null}
    </>);
  };

  viewReaction = (group) => {
    const {
      isWaiting,
      message,
    } = this.props;
    if (!group) return null;

    const { userType: senderUserType } = message?.entry;
    const isMessageSentByAdmin = senderUserType && ALIKE_MODERATOR.includes(senderUserType);
    // Here we're checking if message is sent by admin then we're hiding more icon because as of now, 
    // there is only one option "Document" and we don't need to show that option to admin's messages. 
    if (isWaiting && isMessageSentByAdmin) return null;

    return (<Popover content={this.reactions(group)} trigger="hover">
      <Icon type="more" />
    </Popover>);
  };

  getSenderUsername = () => {
    const {
      message,
      store: {
        GroupStore: { userIdToUsernameMap },
       },
    } = this.props;

    const { sender: senderInMessage, userId } = message.entry;
    return userIdToUsernameMap?.get(userId) ?? senderInMessage;
  }

  render() {
    const {
      addUserMentionToChat,
      onReply,
      groupId,
      groupChat,
      scrollToBottom,
      message,
      isWaiting,
      isForFlagNeeds,
      store: {
        GroupStore: { getColor, getFlair, getEnrolledUserColor, getBadgeType, getBadgeColor },
        AuthStore: { type: userType },
        MessagesStore: { selectedGroup, isShowFlagOptionModal },
        FormMessageStore: { formsListData, loading, skipFormsInListing, totalFormsInListing },
        ProfileStore: { setUserBasicDetails }
      },
    } = this.props;

    const { type, userId } = message.entry;
    const sender = this.getSenderUsername();
    const enrolledColor = getEnrolledUserColor(userId);
    const userBadgeType = getBadgeType(userId);
    const badgeColor = getBadgeColor(userId)
    const likesCount = message.getReactionsCount("like");
    const flagsCount = message.getReactionsCount("flag");
    const redflagsCount = message.getReactionsCount("redFlag");
    const blueflagsCount = message.getReactionsCount("blueFlag");
    const supportCount = message.getReactionsCount("hug");
    const lolCount = message.getReactionsCount("lol");
    const inspireCount = message.getReactionsCount("bulb");
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    const isNA = userType === "NOA" ? true : false;
    const userFlair = getFlair(groupId, userId);
    const flair = getEmoji(userFlair);
    const badgeType = this.getUserType(sender, userId);
    const isImg = type === "img";
    const isGif = type === "gif";
    const isForm = type === "form";
    const isAudio = type === "audio";
    const isVideo = type === "video";
    const isDoc = type === "document";
    const isCustomBanner = type === "customBanner";
    const isAdd = type === "add";
    const validType = isImg || isGif || isForm || isCustomBanner || isAdd;
    const showMentionButton = !!addUserMentionToChat;

    return (type === "text" && message.entry.text !== "") || validType ? (
      <>
        <div
          id="display-data"
          className={
            selectedGroup.flaggedMessageTimetoken === message.timetoken
              ? "lightblue-background"
              : ""
          }
        >
          <div className="d-flex align-items-center">
            <span
              className="badge badge-pill user-badge"
              style={{
                backgroundColor: getColor(groupId, sender, userId),
              }}
            />
            <span className="chat-username">
              {groupId === 105 && IS_CS ? null : (
                <small
                  style={{
                    color: getColor(groupId, sender, userId),
                  }}
                  className="username"
                  onClick={
                    isAdmin || isSuperAdmin || isNA ? this.openProfile : null
                  }
                >
                  {sender}
                  {ALIKE_MODERATOR.includes(userType) ? this.renderBadge(userBadgeType) : ""}
                </small>
              )}
              <span className="user-flair">{flair}</span>
              <span className="chat-user-mod-tag">
                <ModeratorTag badgeType={badgeType} />
              </span>
            </span>
          </div>

          {!!onReply && message.entry.replyMessage && <RepliedMessageBox
            message={message.entry.replyMessage}
            groupChat={groupChat}
            groupId={groupId}
            scrollToBottom={scrollToBottom}
          />}
          <div className="d-flex chatText">
            {this.renderMessage(message, message.isDeleted)}
            <div className="d-flex reaction-container">
              {/* <div>
                {sender === AuthStore.username &&
                  (message.timetoken >= groupLastSeen ||
                  groupLastSeen === "99999999999999999" ? (
                    <Icon type="check" className="last-seen-icon" />
                  ) : (
                    <Icon type="eye" className="last-seen-icon" />
                  ))}
              </div> */}
              <div>
                {(isForm || isCustomBanner || isShowFlagOptionModal || isForFlagNeeds) ? null : (
                  <React.Fragment> {this.viewReaction(selectedGroup)}</React.Fragment>
                )}
                <br />
                <small>
                  {moment.unix(parseInt(message.timetoken) / 10000000).format("LT")}
                </small>
              </div>
              {showMentionButton && <Button
                onClick={() => {addUserMentionToChat(sender)}}
                shape="circle"
                size="small"
                className="chat-user-mention-button"
              >
                <img
                  style={{
                    height: '20px',
                    width: '20px'
                  }}
                  src={IMG_MENTION} className="send-form" alt="Form"
                />
              </Button>}
            </div>
          </div>
          {isWaiting || isForm || isCustomBanner ? null : (
            <div className="message-reactions-count">
              {likesCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img
                        src={heart}
                        alt="likes"
                        className="message-reactions"
                      />
                    </span>
                    <span>{likesCount} </span>
                  </div>
                </div>
              )}{" "}
              {flagsCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img src={orangeFlag} alt="flags" className="message-reactions" />
                    </span>
                    <span>{flagsCount} </span>
                  </div>
                </div>
              )}
              {redflagsCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img src={redFlag} alt="flags" className="message-reactions" />
                    </span>
                    <span>{redflagsCount} </span>
                  </div>
                </div>
              )}
              {blueflagsCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img src={blueFlag} alt="flags" className="message-reactions" />
                    </span>
                    <span>{blueflagsCount} </span>
                  </div>
                </div>
              )}
              {supportCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img
                        src={hug}
                        alt="Support"
                        className="message-reactions"
                      />
                    </span>
                    <span>{supportCount} </span>
                  </div>
                </div>
              )}
              {lolCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img src={lol} alt="LOL" className="message-reactions" />
                    </span>
                    <span>{lolCount} </span>
                  </div>
                </div>
              )}
              {inspireCount > 0 && (
                <div className="badge">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ height: "20px" }}
                  >
                    <span>
                      <img
                        src={bulb}
                        alt="Inspire"
                        className="message-reactions"
                      />
                    </span>
                    <span>{inspireCount} </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <CustomFormModal
          title="Forms List"
          visible={this.state.formListVisible}
          onOk={this.handleOk}
          loading={loading}
          loadMore={this.loadMoreFormsListing}
          hasMore={skipFormsInListing < totalFormsInListing}
          onCancel={() => this.changeFormsListVisible(false)}
          data={toJS(formsListData) ? toJS(formsListData) : []}
        />
      </>
    ) : isAudio || isVideo || isDoc ? (
      <>
        <div id="display-data">
          <div className="d-flex align-items-center">
            <span
              className="badge badge-pill user-badge"
              style={{
                backgroundColor: getColor(groupId, sender, userId),
              }}
            />
            <span className="chat-username">
              {groupId === 105 && IS_CS ? null : (
                <small
                  style={{
                    color: getColor(groupId, sender, userId),
                  }}
                  className="username"
                  onClick={isAdmin || isSuperAdmin ? this.openProfile : null}
                >
                  {sender} {ALIKE_MODERATOR.includes(userType) ? this.renderBadge(userBadgeType) : ""}
                </small>
              )}
              <span className="user-flair">{flair}</span>
              <span className="chat-user-mod-tag">
                <ModeratorTag badgeType={badgeType} />
              </span>
            </span>
          </div>
          <div className="d-flex chatText">
            <div className="msgcontainer not-supported">
              Message type not Supported Yet.
            </div>
            {showMentionButton && <Button
              onClick={() => {addUserMentionToChat(sender)}}
              shape="circle"
              size="small"
              className="chat-user-mention-button"
            >
              <img
                style={{
                  height: '20px',
                  width: '20px'
                }}
                src={IMG_MENTION} className="send-form" alt="Form"
              />
            </Button>}
          </div>
        </div>
        <CustomFormModal
          title="Forms List"
          visible={this.state.formListVisible}
          onOk={this.handleOk}
          loading={loading}
          loadMore={this.loadMoreFormsListing}
          hasMore={skipFormsInListing < totalFormsInListing}
          onCancel={() => this.changeFormsListVisible(false)}
          data={toJS(formsListData) ? toJS(formsListData) : []}
        />
      </>
    ) : null;

  }
}

export default MessageBubble;
