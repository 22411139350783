import React, { useState } from "react";
import close from "../../static/images/close.png";
import { Select, Input, Radio } from "antd";
import redFlag from "../../static/images/redFlag.png";
import blueFlag from "../../static/images/blueFlag.png";
import { getFlagTypes } from "../../utils/getFlagTypes";
const flagTypes = getFlagTypes();

const Option = Select.Option;
const { TextArea } = Input;

export const FlagOptionsModal = (props) => {
  const { onOk, onClose, flagReactionId, messageObj} = props;
  const [severity, setSeverity] = useState(flagReactionId);
  const [need, setNeed] = useState(1);
  const [description, setDescription] = useState("")
  const [anonymity, setAnonymity] = useState(101);

  const onChangeSeverity = e => {
    setSeverity(e.target.value)
  };

  const onChangeAnonymity = e => {
    setAnonymity(e.target.value)
  };

  const onChangeNeed = e => {
    setNeed(e)
  };

  const onChangeDescription = e => {
    setDescription(e.target.value)
  };

  const onDone = () => {
    const result = {
      reactionId: flagReactionId === 2 ? flagReactionId : severity,
      typeOfNeed: `${need},${anonymity}`,
      description: description,
      message: messageObj
    };

    onOk(result)
  }

  return (
    <div>
      <div>
        <div className="confirm-backdrop" onClick={onClose} />
        <div className="confirm-modal">
          <span className="closeIcon" >
            <img src={close} onClick={onClose} alt="Close" />
          </span>
          <div className="modal-block">
             <span className="form-answer">Types of Need</span>
             <Select
                className="flag-option-select"
                placeholder="Select flag type"
                value={need}
                onChange={onChangeNeed}
              >
                {flagTypes.map((item) => (
                  <Option key={item} value={item.value}>{item.label}</Option>
                ))}
              </Select>
          </div>
          <div className="modal-block">
              <span className="form-answer">Severity</span>
              <Radio.Group defaultValue={flagReactionId} className="radio-block-flag" onChange={onChangeSeverity}>
                <Radio.Button value={7}><img src={redFlag} width="25px" alt="redFlag" /> Red</Radio.Button>
                <Radio.Button value={8}><img src={blueFlag} width="25px" alt="blueFlag" /> Blue</Radio.Button>
              </Radio.Group>
          </div>
          <div className="modal-block">
              <span className="form-answer">Anonymity Compromised</span>
              <Radio.Group defaultValue={101} className="radio-block-flag" onChange={onChangeAnonymity}>
                <Radio.Button value={101}>Yes</Radio.Button>
                <Radio.Button value={102}>No</Radio.Button>
              </Radio.Group>
          </div>
          <div className="modal-block">
            <TextArea
                placeholder="Description of the need identified."
                onChange={onChangeDescription}
            />
          </div>
          <div className="modal-buttons">
            <button className="confirm" onClick={onDone}>
              Done 
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
