import { inject, observer } from "mobx-react";
import { Button, Spin } from "antd";
import GroupMembers from "./GroupMembers";
import React from "react";
import { userProfile } from "../../en.json";
import close from "../../static/images/close.png";
import flag from "../../static/images/flag.svg";
import flagFilled from "../../static/images/flagFilled.svg";
import edit from "../../static/images/edit.svg";
import SVG from "react-inlinesvg";
import info from "../../static/images/info.png";
import deleted from "../../static/images/deleted.png";
import configure from "../../static/images/configure.png";
import IceBreaker from "./IceBreaker";
import GroupSites from "./GroupSites";
import { CustomModal } from "../UI/CustomModal";
import { IceBreakerModal } from "../UI/IceBreakerModal";

@inject("store")
@observer
class GroupDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      modalVisible: false,
    };
  }

  changeValue = (e) => {
    const {
      store: {
        GroupListStore: { changePreviousValue },
      },
    } = this.props;
    if (e.target.getAttribute("data-field") === "groupImage") {
      const fileName = e.target.value.toLowerCase();
      if (
        !fileName.endsWith(".jpg") ||
        !fileName.endsWith(".jpeg") ||
        !fileName.endsWith(".gif") ||
        !fileName.endsWith(".png")
      ) {
        alert("Please upload file type image only.");
        return;
      }
      this.addPhoto(e.target.files);
    }
    changePreviousValue(e.target.getAttribute("data-field"), e.target.value);
  };

  changeGroupFlagValue = (value) => {
    const {
      store: {
        GroupListStore: { showFlags },
      },
    } = this.props;
    showFlags(value);
  };

  closePress = () => {
    const {
      store: {
        GroupListStore: {
          setgroupEditingLoading,
          setDetailsVisibility,
          editGroupDetails,
          resetSelectedGroup,
          showFlags,
        },
        DeletedMessagesStore: {
          setShowGroupDeleted,
          setShowGroupUnDeleted,
          resetGroupDeletedMessages,
          resetGroupUnDeletedMessages,
        },
      },
    } = this.props;
    showFlags(false);
    setShowGroupUnDeleted(false);
    setShowGroupDeleted(false);
    resetGroupDeletedMessages();
    resetGroupUnDeletedMessages();
    resetSelectedGroup();
    setDetailsVisibility(false);
    editGroupDetails(false);
    setgroupEditingLoading(false);
  };

  addPhoto(files) {
    if (!files.length) {
      return alert("Please choose a file to upload first.");
    }
    const file = files[0];
    this.setState({
      file,
    });
  }

  changeModalVisible = (value) => {
    this.setState({
      modalVisible: value,
    });
  };

  editPress = () => {
    const {
      store: {
        GroupListStore: { editGroupInfo },
      },
    } = this.props;
    editGroupInfo();
  };

  fetchDeletedMessages = () => {
    const {
      store: {
        GroupListStore: { selectedGroup },
        DeletedMessagesStore: {
          setShowGroupDeleted,
          fetchGroupDeletedMessages,
          groupDeletedLoading,
        },
      },
    } = this.props;
    if (groupDeletedLoading) {
      return;
    }
    fetchGroupDeletedMessages(selectedGroup.id);
    setShowGroupDeleted(true);
  };

  fetchGroupHistory = (groupId, groupName) => {
    const {
      store: {
        HistoryStore: { fetchGroupHistory, historyLoading },
      },
    } = this.props;
    fetchGroupHistory(groupId, groupName);
    this.changeModalVisible(true);
  };

  loadMoreGroupHistory = () => {
    const {
      store: {
        GroupListStore: { selectedGroup },
        HistoryStore: { fetchGroupHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchGroupHistory(selectedGroup.id, selectedGroup.name);
  };

  updateGroupDetails = () => {
    const {
      store: {
        GroupListStore: { updateGroupDetails },
      },
    } = this.props;
    updateGroupDetails(this.state.file);
  };

  showIceBreakerModal = (value) => {
    const {
      store: {
        GroupListStore: {
          selectedGroup,
          setShowIceBreaker,
          getGroupIceBreaker,
        },
      },
    } = this.props;
    if (value) {
      getGroupIceBreaker(selectedGroup.id);
    }
    setShowIceBreaker(value);
  };

  setShowGroupSitesModal = (value) => {
    const {
      store: {
        GroupListStore: {
          selectedGroup,
          setShowGroupSitesModal,
          getGroupSites,
        },
      },
    } = this.props;
    if (value) {
      getGroupSites(selectedGroup.id);
    }
    setShowGroupSitesModal(value);
  };

  render() {
    const {
      store: {
        GroupListStore,
        HistoryStore: {
          historyLoading,
          totalGroupHistory,
          skipGroupHistory,
          groupHistory,
          resetHistory,
        },
      },
    } = this.props;
    if (!GroupListStore.selectedGroup) {
      return (
        <div className="selected-group-loading">
          <Spin />
        </div>
      );
    }
    return (
      <React.Fragment>
        <CustomModal
          title="Group History"
          visible={this.state.modalVisible}
          onOk={this.handleOk}
          loading={historyLoading}
          loadMore={this.loadMoreGroupHistory}
          hasMore={skipGroupHistory < totalGroupHistory}
          onCancel={() => {
            resetHistory();
            this.changeModalVisible(false);
          }}
          data={groupHistory ? groupHistory : []}
        />
        <IceBreakerModal
          title="Configure IceBreaker for this Group"
          visible={GroupListStore.showIceBreaker}
          loading={GroupListStore.iceBreakerLoading}
          onCancel={() => {
            GroupListStore.resetIceBreakerInfo();
            this.showIceBreakerModal(false);
          }}
        >
          <IceBreaker />
        </IceBreakerModal>
        <IceBreakerModal
          title="Configure Sites for this Group"
          visible={GroupListStore.showGroupSitesModal}
          loading={GroupListStore.siteGroupsLoading}
          onCancel={() => {
            this.setShowGroupSitesModal(false);
          }}
        >
          <GroupSites />
        </IceBreakerModal>
        <div className="group-name-container">
          {GroupListStore.groupEdit ? null : (
            <img
              src={edit}
              className="profile-edit-image"
              onClick={() => this.editPress()}
              alt="Edit"
              edit="editGroup"
            />
          )}
          {GroupListStore.groupEdit ? (
            <input
              onChange={this.changeValue}
              placeholder="Group Name"
              className="group-edit-input"
              data-field="groupName"
              value={GroupListStore.groupName}
            />
          ) : (
            <h1 className="group-name ">
              {GroupListStore.selectedGroup.name}
              <img
                src={info}
                className="info-icon"
                alt="info"
                onClick={() => {
                  this.fetchGroupHistory(
                    GroupListStore.selectedGroup.id,
                    GroupListStore.selectedGroup.name
                  );
                }}
              />
            </h1>
          )}
          <img
            src={close}
            onClick={() => this.closePress()}
            alt="Close"
            className="close-img"
          />
        </div>
        {GroupListStore.showGroupFlags ? (
          <div
            className="show-flags-groups"
            onClick={() => this.changeGroupFlagValue(false)}
          >
            <SVG src={flagFilled}></SVG>
            <span>Hide Flags</span>
          </div>
        ) : (
          <div
            className="show-flags-groups"
            onClick={() => this.changeGroupFlagValue(true)}
          >
            <SVG src={flag}></SVG>
            <span>Show Flags</span>
          </div>
        )}
        <div
          className="show-flags-groups"
          onClick={() => this.showIceBreakerModal(true)}
        >
          <img src={configure} alt="Configure" className="undeleted-icon" />
          <span>Configure IceBreaker</span>
        </div>
        <div
          className="show-flags-groups"
          onClick={() => this.setShowGroupSitesModal(true)}
        >
          <img src={configure} alt="Configure" className="undeleted-icon" />
          <span>Configure Group Sites</span>
        </div>
        <div
          className="show-flags-groups"
          onClick={() => this.fetchDeletedMessages()}
        >
          <img src={deleted} alt="Deleted" className="undeleted-icon" />
          <span>Show Deleted Messages</span>
        </div>
        {GroupListStore.groupEdit ? (
          <div className="edit-buttons" style={{ marginBottom: "15px" }}>
            <div
              className="cancel-button"
              edit="editClinical"
              onClick={() => GroupListStore.editGroupDetails(false)}
            >
              {userProfile.cancel}
            </div>
            <Button
              type="primary"
              className="submit-button"
              size="large"
              edit="editClinical"
              onClick={() => this.updateGroupDetails()}
              disabled={GroupListStore.groupEditingLoading}
            >
              {userProfile.submit}
            </Button>
          </div>
        ) : null}
        <GroupMembers changeValue={this.changeValue} />
      </React.Fragment>
    );
  }
}
export default GroupDetails;
