import React from "react";
import { Tooltip } from 'antd';

import { IMG_CHECKERED_FLAG, IMG_MARIO_RUN_GIF } from "../../utils/ImageUtils";

const containerStyles = {
    height: 38,
    width: '100%',
    border: '1px solid #FB9048',
    backgroundColor: "#ffffff",
    borderRadius: 5,
    display: 'flex'
    // marginLeft: '30%'
};

const labelStyles = {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'

};

const ProgressBarDual = (props) => {
    const { completed, label, secondaryCompleted, secondaryLabel, goal, goalLabel, flagPercent, flagLabel, toolTipLabel, secondaryToolTipLabel } = props;
    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: '#FB9048',
        borderRadius: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'width 1s ease-in-out',
    };

    const secondaryFillerStyles = {
        height: '100%',
        width: `${secondaryCompleted}%`,
        backgroundColor: '#FFBD91',
        borderRadius: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'width 1s ease-in-out',
    };

    return (
        <>
            <div className="progress-bar-wrapper-dual" style={{ marginLeft: 0 }}>
                {completed > 0 && <em className="progress-bar-image-inner" style={{ width: `${completed}%` }}>
                    <img src={IMG_MARIO_RUN_GIF} style={{ width: 23, height: 23 }} />
                </em>}
                <em className="progress-bar-image-inner" style={{ width: `${flagPercent}%` }}>
                    <span>{flagLabel}</span>
                    <img src={IMG_CHECKERED_FLAG} style={{ width: 23, height: 23 }} />
                </em>
                <em className="progress-bar-image-inner" style={{ width: `${100}%` }}>
                    <span>{goalLabel}</span>
                </em>
                <div style={containerStyles}>
                    <Tooltip placement="top" title={toolTipLabel}>
                        <div style={fillerStyles}>
                            <span style={labelStyles}>{label}</span>
                        </div>
                    </Tooltip>
                    <Tooltip placement="top" title={secondaryToolTipLabel}>
                        <div style={secondaryFillerStyles}>
                            <span style={labelStyles}>{secondaryLabel}</span>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </>
    );
};

export default ProgressBarDual;