import { types } from "mobx-state-tree";

const EventUser = types.model("EventUser", {
  id: types.identifierNumber,
  eventId: types.number,
  eventUserId: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  status: types.maybeNull(types.number),
  notes: types.maybeNull(types.string),
  eventUserInfo: types.frozen({
    id: types.maybeNull(types.number),
    email: types.maybeNull(types.string),
    fullName: types.maybeNull(types.string),
    dob: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    referrerId: types.maybeNull(types.number),
    referrerInfo: types.frozen({
      fullName: types.maybeNull(types.string),
      username: types.maybeNull(types.string),
    }),
  }),
});

export default EventUser;
