import _ from "lodash";
import { types } from "mobx-state-tree";
import { REWARD_STATUS } from "../constants/GlobalConstant";
import NotificationStore from "../stores/NotificationStore";
import { getLocalDateTime } from '../utils/CommonUtils';

import ApiService from "../utils/ApiService";

const Rewards = types
  .model("Reward", {
    reward: types.optional(types.frozen([])),
    completedRewards: types.optional(types.frozen([])),
    expiredRewards: types.optional(types.frozen([])),
    onboardingStatus: types.optional(types.frozen({})),
    totalUnRedeemed: 0,
    fetchRewardsLoading: false,
    completeRewardsLoading: false,
  })
  .actions((self) => ({
    reset() {
      self.reward = [];
      self.onboardingStatus = {};
    },
    async updateRewardStartDate(payload) {
      const { startedAt, userId, rewardId } = payload
      try {
        const obj = { userId, startedAt };
        await ApiService.patchRequest(`reward`, rewardId, obj);
        await self.fetchRewards(userId);
      } catch (error) {
        throw error;
      }
    },
    async updateRewardEndDate(payload) {
      const { expiredAt, userId, rewardId } = payload
      try {
        const obj = { userId, expiredAt };
        await ApiService.patchRequest(`reward`, rewardId, obj);
        await self.fetchRewards(userId);
      } catch (error) {
        throw error;
      }
    },
    async addReward(dataToSave) {
      try {
        const response = await ApiService.postRequest("rewards", dataToSave);
        if (response.success) {
          this.fetchRewards(dataToSave?.userId);
          NotificationStore.setNotification('success', 'Custom reward created');
        }
      } catch (error) {
        NotificationStore.setNotification('error', 'while creating custom reward');
      }
    },
    async fetchRewards(userId) {
      try {
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        self.setFetchRewardsLoading(true);
        const path = `rewards/${userId}?timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
        let response = await ApiService.getRequest(path);
        if (response.success) {
          const rewards = response?.data?.rewards || [];
          const completedRewards = rewards.filter(item => (item.status === REWARD_STATUS.READY_TO_REDEEM));
          const expiredRewardsList = rewards.filter(item => (item.status === REWARD_STATUS.EXPIRED || (item.status === REWARD_STATUS.IN_PROGRESS && (item.expiredAt && (new Date(getLocalDateTime(item.expiredAt)).setHours(0, 0, 0, 0) <= todayDate.getTime())))));
          // sorted by updateAt for completed Reword
          const sortedCompletedReword = [...completedRewards].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          const sortedexpiredRewardsList = [...expiredRewardsList].sort((a, b) => new Date(b.expiredAt) - new Date(a.expiredAt));
          self.setExpiredRewards(sortedexpiredRewardsList);
          self.setCompletedRewards(sortedCompletedReword);
          const activeRewards = rewards.filter(item => item.status === REWARD_STATUS.IN_PROGRESS && (item.expiredAt ? (new Date(getLocalDateTime(item.expiredAt)).setHours(0, 0, 0, 0) > todayDate) : true));
          self.setRewards([...activeRewards]);
          self.setOnboardingStatus(response?.data?.onBoardingStatus);
          self.setTotalUnRedeemed(response?.data?.availableBalance);
        }
        self.setFetchRewardsLoading(false);
      } catch (error) {
        self.setFetchRewardsLoading(false);
      }
    },
    async completeReward(payload) {
      const { rewardId, userId, note } = payload
      try {
        const obj = { userId, note };
        self.setCompleteRewardsLoading(true);
        await ApiService.patchRequest(`rewards`, rewardId, obj);

        // fetch updated reward
        await self.fetchRewards(userId);
        self.setCompleteRewardsLoading(false);
      } catch (error) {
        self.setCompleteRewardsLoading(false);
      }
    },
    setFetchRewardsLoading(value) {
      self.fetchRewardsLoading = value;
    },
    setRewards(value) {
      self.rewards = value;
    },
    setCompletedRewards(value) {
      self.completedRewards = value;
    },
    setExpiredRewards(value) {
      self.expiredRewards = value;
    },
    setOnboardingStatus(value) {
      self.onboardingStatus = value;
    },
    setTotalUnRedeemed(value) {
      self.totalUnRedeemed = value;
    },
    setCompleteRewardsLoading(value) {
      self.completeRewardsLoading = value;
    },
  }))
  .views((self) => ({
  }));

export default Rewards;
