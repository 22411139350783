import React from "react";
import { Button, Modal } from "antd";

const FormConfirmModal = (props) => {
    const { onConfirm, onCancel, isModalVisible } = props;
    return (
        <Modal
            title="Are you sure?"
            centered
            width={"400px"}
            visible={isModalVisible}
            onOk={onConfirm}
            onCancel={onCancel}
        >
            <p>You already have a form in progress. Opening a new form will close your currently minimized form. Are you sure you want to continue?</p>
            <div className="form-confirm-modal">
                <Button key="back" style={{ marginRight: 10 }} onClick={onCancel}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" onClick={onConfirm}>
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

export default FormConfirmModal;