import { inject, observer } from "mobx-react";

import React from "react";
import { Spin } from "antd";
import GroupDeleted from "./GroupDeleted";
import GroupUnDeleted from "./GroupUnDeleted";
import undeleted from "../../static/images/undeleted.png";
import close from "../../static/images/close.png";

@inject("store")
@observer
class GroupMessagesContainer extends React.Component {
  fetchUnDeletedMessages = () => {
    const {
      store: {
        GroupListStore: { selectedGroup },
        DeletedMessagesStore: {
          setShowGroupUnDeleted,
          fetchGroupUnDeletedMessages,
          groupUnDeletedLoading
        }
      }
    } = this.props;
    if (groupUnDeletedLoading) {
      return;
    }
    fetchGroupUnDeletedMessages(selectedGroup.id);
    setShowGroupUnDeleted(true);
  };

  render() {
    const {
      store: {
        GroupListStore: { selectedGroup },
        DeletedMessagesStore: {
          setShowGroupDeleted,
          setShowGroupUnDeleted,
          resetGroupDeletedMessages,
          resetGroupUnDeletedMessages
        }
      },
      showdeleted
    } = this.props;

    return showdeleted ? (
      <React.Fragment>
        <div className="user-flags">
          <span className="username">
            Deleted Messages of {selectedGroup.name}
          </span>
          <img
            src={close}
            onClick={() => {
              resetGroupDeletedMessages();
              setShowGroupDeleted(false);
            }}
            alt="Close"
          />
        </div>
        <div
          className="show-undeleted-messages"
          onClick={() => this.fetchUnDeletedMessages()}
        >
          <img
            src={undeleted}
            className="actions-container undeleted-icon"
            alt="UnDeleted"
          />
          <span>Show UnDeleted Messages</span>
        </div>
        <GroupDeleted />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="user-flags">
          <span className="username">
            UnDeleted Messages of {selectedGroup.name}
          </span>
          <img
            src={close}
            onClick={() => {
              resetGroupUnDeletedMessages();
              setShowGroupUnDeleted(false);
            }}
            alt="Close"
          />
        </div>
        <GroupUnDeleted />
      </React.Fragment>
    );
  }
}
export default GroupMessagesContainer;
