import { Button, notification } from "antd";

import React from "react";
import { notificationMessage } from "../en.json";
import { types } from "mobx-state-tree";
import { NOTIFICATION_TYPE } from "../constants/GlobalConstant";

const Notification = types
  .model("Notification", {
    active: false,
    data: types.array(types.frozen({})),
    dmNotifications: types.array(types.frozen({})),
    groupNotifications: types.array(types.frozen({})),
    otherNotifications: types.array(types.frozen({})),
    groupedDMNotifications: types.array(types.frozen({})),
    groupedGroupNotifications: types.array(types.frozen({})),
    groupedOtherNotifications: types.array(types.frozen({})),
    showAlertNotification: false,
    notificationTitle: types.maybeNull(types.string),
    notificationDescription: types.maybeNull(types.string),
  })
  .actions(self => ({
    resetData() {
      self.data = [];
      self.dmNotifications = [];
      self.groupNotifications = [];
      self.otherNotifications = [];
      self.groupedDMNotifications = [];
      self.groupedGroupNotifications = [];
      self.groupedOtherNotifications = [];
    },
    resetDMNotification() {
      self.dmNotifications = [];
      self.groupedDMNotifications = [];
    },
    resetGroupNotifications() {
      self.groupNotifications = [];
      self.groupedGroupNotifications = [];
    },
    resetOtherNotifications() {
      self.otherNotifications = [];
      self.groupedOtherNotifications = [];
    },
    resetNotificationMeta() {
      self.notificationTitle = "";
      self.notificationDescription = "";
      self.showAlertNotification = false;
    },
    setData(value) {
      self.data = value;
    },
    setDMNotifications(value) {
      self.dmNotifications = value;

      // group notification
      const groupedNotifications = self.doGroupNotifications(value);
      self.groupedDMNotifications = groupedNotifications;
    },
    setGroupNotifications(value) {
      self.groupNotifications = value;

      // group notification
      const groupedNotifications = self.doGroupNotifications(value);
      self.groupedGroupNotifications = groupedNotifications;
    },
    setOtherNotifications(value) {
      self.otherNotifications = value;

      // group notification
      const groupedNotifications = self.doGroupNotifications(value);
      self.groupedOtherNotifications = groupedNotifications;
    },
    setGroupedDMNotifications(value) {
      self.dmNotifications = value;
    },
    setGroupedGroupNotifications(value) {
      self.groupNotifications = value;
    },
    setGroupedOtherNotifications(value) {
      self.otherNotifications = value;
    },
    setShowAlertNotification(value) {
      self.showAlertNotification = value;
    },
    setNotificationMeta(value) {
      self.notificationTitle = value.title;
      self.notificationDescription = value.message;
    },
    saveNotification(payload) {
      if (payload.notificationType === NOTIFICATION_TYPE.GROUP_MESSAGE) {
        const arr = self.groupNotifications;
        self.setGroupNotifications([payload, ...arr]);
      } else if (payload.notificationType === NOTIFICATION_TYPE.DIRECT_MESSAGE) {
        const arr = self.dmNotifications;
        self.setDMNotifications([payload, ...arr]);
      } else {
        const arr = self.otherNotifications;
        self.setOtherNotifications([payload, ...arr]);
      }
    },
    doGroupNotifications(data) {
      // this gives an object with dates as keys
      const groups = data.reduce((groups, notification) => {
        const date = notification.createdAt.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(notification);
        return groups;
      }, {});

      return Object.keys(groups).map((date) => {
        return {
          date,
          notifications: groups[date]
        };
      });
    },
    setNotification(type, message, description = "", duration = 4.5) {
      notification.config({
        placement: "bottomLeft"
      });
      notification[type]({
        message,
        description,
        duration
      });
    },
    setActive(value) {
      self.active = value;
    },
    setConfirm(
      onConfirm,
      description,
      message = notificationMessage.confirmMessage
    ) {
      const key = `open${Date.now()}`;
      self.setActive(true);
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            onConfirm();
            self.setActive(false);
            notification.close(key);
          }}
        >
          Confirm
        </Button>
      );

      notification.config({
        placement: "bottomLeft"
      });
      notification.open({
        message,
        description,
        btn,
        key,
        onClose: () => self.setActive(false),
        duration: 0
      });
    }
  }));

export default Notification;
