import ProfileStore from "../stores/ProfileStore";
import NotificationStore from "../stores/NotificationStore";
import MemberListStore from "../stores/MemberListStore";
import ApiService from "./ApiService";
import { user } from "../en.json";

export const openUserProfile = async ({ userId, username }) => {
  if (!userId && username) {
    userId = await getUserIdByUsername(username);

    if (!userId) {
      return NotificationStore.setNotification("error", user.userDoesNotExist);
    }
  }

  return usernamePress(userId);
};

const getUserIdByUsername = async (username) => {
  const params = {
    username,
    "$select[1]": "id"
  };
  const response = await ApiService.getRequest("users", params);
  return response?.data?.[0]?.id;
};

export const usernamePress = async (userId) => {
  const {
    reset,
    setMemberData,
    changeSelectedAction,
    setMemberProfileVisibility,
    toggleLabel,
  } = ProfileStore;

  const { getMemberDetails, fetchAdminUsers } = MemberListStore;

  reset();
  setMemberProfileVisibility(true);
  fetchAdminUsers();

  const member = await getMemberDetails(userId);

  if (!member) {
    return NotificationStore.setNotification("error", user.userDoesNotExist);
  }

  setMemberData(member);
  toggleLabel(false);
  changeSelectedAction("");
};
