import { inject, observer } from "mobx-react";

import { Link } from "react-router-dom";
import React from "react";
import { signUpForm } from "../../en.json";

var mixpanel = require("mixpanel-browser");
@inject("store")
@observer
class LoginForm extends React.Component {
  componentDidMount() {
    mixpanel.track("Signup Page Loaded", { from: "WEB" });
  }
  setFormInput = (e) => {
    const {
      store: {
        SignupStore: { setAttribute },
      },
    } = this.props;

    setAttribute(e.target.getAttribute("data-field"), e.target.value);
  };
  _onFocus = (e) => {
    e.currentTarget.type = "date";
  };
  _onBlur = (e) => {
    if (!e.target.value) {
      e.currentTarget.type = "text";
      e.currentTarget.placeholder = "Date Of Birth";
    }
  };
  render() {
    const {
      store: {
        SignupStore: { isValid, createUser, loading, isNotNumber, phone },
      },
    } = this.props;

    return (
      <React.Fragment>
        <h3 className="pt-2">{signUpForm.moderatorSignup}</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createUser();
          }}
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Full Name (Private)"
              onChange={this.setFormInput}
              data-field="fullName"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Username (Public)"
              onChange={this.setFormInput}
              data-field="username"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Phone #"
              onChange={this.setFormInput}
              data-field="phone"
              style={
                phone.length
                  ? isNotNumber
                    ? { border: "2px solid red" }
                    : null
                  : null
              }
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Title"
              onChange={this.setFormInput}
              data-field="certification"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Date Of Birth"
              onChange={this.setFormInput}
              data-field="DOB"
              onFocus={this._onFocus}
              onBlur={this._onBlur}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Admin Sign Up Code"
              onChange={this.setFormInput}
              data-field="adminSignUp"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              onChange={this.setFormInput}
              data-field="email"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              onChange={this.setFormInput}
              data-field="password"
            />
          </div>
          <div>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={!isValid || loading}
              style={{ position: "relative" }}
            >
              {loading && (
                <div
                  className="spinner-grow"
                  style={{
                    width: "16px",
                    height: "16px",
                    position: "absolute",
                    left: "35%",
                    top: "8px",
                  }}
                  role="status"
                >
                  <span className="sr-only">{signUpForm.loading}</span>
                </div>
              )}
              {signUpForm.signup}
            </button>
          </div>
          <Link to="/login">
            <small>{signUpForm.login}</small>
          </Link>
        </form>
      </React.Fragment>
    );
  }
}
export default LoginForm;
