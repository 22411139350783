import ApiService from "../utils/ApiService";
import { toJS } from "mobx";
import { types } from "mobx-state-tree";

import { getProjectIdByEnvironment } from '../utils/getEnvironment';

const Insights = types
    .model("Insights", {
        loading: false,
        usersData: types.map(types.frozen({
            totalSignupUsers: types.maybe(types.number),
            totalSignupUsersByNotes: types.maybe(types.number),
            userIds: types.array(types.number),
        })),
        availableGroupsData: types.array(types.frozen({
            key: types.maybe(types.number),
            id: types.maybe(types.number),
            groupName: types.maybe(types.string),
            groupDescription: types.maybe(types.string)
        })),
        providersData: types.array(types.frozen({
            key: types.maybe(types.number),
            id: types.maybe(types.number),
            color: types.maybe(types.string),
            provider: types.maybe(types.string)
        })),
        mostActiveGroupsData: types.array(types.frozen({
            key: types.maybe(types.number),
            id: types.maybe(types.number),
            groupName: types.maybe(types.string),
            description: types.maybe(types.string),
            imageUrl: types.maybe(types.string),
            stats1: types.maybe(types.string),
            stats2: types.maybe(types.string),
        })),
        userMessagesCountByNotes: types.maybe(types.number)
    })
    .actions((self) => ({
        async getUserInsightReport() {
            try {
                self.setLoading(true);
                const response = await ApiService.getRequest("insight-report");
                const { success, data } = response;
                if (success) {
                    self.setUsersData({ ...data });
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async getUserMessagesCount() {
            try {
                self.setLoading(true);
                let userIds = [];
                const usersData = toJS(self.usersData);
                if (usersData && Object.keys(usersData).length) {
                    const siteName = 'IslandHealth';
                    userIds = usersData.userIds.map(value => `${value}-${siteName}`);
                }
                const dateParams = {
                    from_date: '2022-01-01',
                    to_date: '2022-02-28'
                };

                const options = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: 'Basic Yzc1M2ViMzk2M2RmOGQ0YWViMzNlZjU4MTc0M2E0ZDU6'
                    },
                    body: new URLSearchParams({
                        script: `function main() {   return Events(${JSON.stringify(dateParams)}).filter((event)=>{  const username=${JSON.stringify(userIds)};           return event.name==\'Message Publish Success\' && username.includes(event.distinct_id)   })   .groupBy(["name"], mixpanel.reducer.count()); }`,
                        params: '{\n  "scriptParam": "paramValue"\n}\n'
                    })
                };
                const projectId = getProjectIdByEnvironment();
                const response = await fetch(`https://mixpanel.com/api/2.0/jql?project_id=${projectId}`, options);
                if (response.ok) {
                    const [messageCountData] = await response.json();
                    if (messageCountData) {
                        self.setUserMessagesCountByNotes(messageCountData.value);
                    }
                }

                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async getMostActiveGroup() {
            try {
                self.setLoading(true);
                const url = 'https://api.sheety.co/275eb6c47b45afabfd5f49e98bb0ea71/groupInfo';
                const response = await ApiService.getRequest("mostActiveG", {}, null, true, url);
                const { success, data } = response;
                if (success) {
                    const mostActiveGroupsArr = data['mostActiveG'];
                    const cloneArr = mostActiveGroupsArr.map((obj, index) => {
                        return { ...obj, key: index }
                    });
                    self.setMostActiveGroupsData([...cloneArr]);
                }

                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async getGroupsAvailableOnPlatform() {
            try {
                self.setLoading(true);
                const url = 'https://api.sheety.co/275eb6c47b45afabfd5f49e98bb0ea71/groupInfo'
                const response = await ApiService.getRequest("groups123", {}, null, true, url);
                const { success, data } = response;
                if (success) {
                    const groupsData = data['groups123'];
                    const cloneArr = groupsData.map((obj, index) => {
                        return { ...obj, key: index }
                    });
                    self.setAvailableGroupsData([...cloneArr]);
                }

                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async getProvidersList() {
            try {
                self.setLoading(true);
                const url = 'https://api.sheety.co/275eb6c47b45afabfd5f49e98bb0ea71/groupInfo'
                const response = await ApiService.getRequest("provider", {}, null, true, url);
                const { success, data } = response;
                if (success) {
                    const providersData = data['provider'];
                    const cloneArr = providersData.map((obj, index) => {
                        return { ...obj, color: obj.color.trim(), key: index }
                    });
                    self.setProvidersData([...cloneArr]);
                }

                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        reset() {
            self.usersData = [];
            // self.resetPagination();
        },
        setUserMessagesCountByNotes(value) {
            self.userMessagesCountByNotes = value;
        },
        setMostActiveGroupsData(data) {
            self.mostActiveGroupsData = data;
        },
        setProvidersData(data) {
            self.providersData = data;
        },
        setAvailableGroupsData(data) {
            self.availableGroupsData = data;
        },
        setUsersData(data) {
            self.usersData = data;
        },
        setLoading(value) {
            self.loading = value;
        },
    }))
    .views((self) => ({
    }));

export default Insights;