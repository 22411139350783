import React from "react";
import { Form, Switch } from "antd";

export const FormSwitch = ({ item, key, changeVisibleState }) => {

  const { title, isVisible, dataIndex } = item;

  return <Form.Item key={key} label={title}>
    <Switch checked={isVisible} onChange={() => changeVisibleState(dataIndex, !isVisible)} />
  </Form.Item>
};
