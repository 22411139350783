import React from "react";
import close from "../../static/images/close.png";
import { ConfirmModal } from "../UI/ConfirmModal";
import {
  Checkbox,
  Tabs,
  Spin,
  Radio,
  Progress,
  Menu,
  Select,
  Tooltip,
  Dropdown,
  Button,
} from "antd";
import { get, startsWith } from "lodash";
import { inject, observer } from "mobx-react";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import { getIntakeStatus } from "../../utils/getIntakeStatus";
import { hpUsers } from "../../utils/Hpusers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./Pdf";
import InAppSurvey from "./InAppSurvey";
import { CustomModal } from "../UI/CustomModal";
import { toJS } from "mobx";
import info from "../../static/images/info.png";
import { toolTips, CBRS } from "../../en.json";
import { RC_HP_ARRAY, RSN_HP_ARRAY } from "../../utils/getFormQuestions";
import { getFormattedPhoneNumber } from "../../utils/CommonUtils";
const TabPane = Tabs.TabPane;
const Option = Select.Option;

@inject("store")
@observer
class SelfIntakeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleChoices: {},
      multipleChoicesRadio: {},
      descriptiveAnswer: {},
      rows: {},
      showWarnings: false,
      minRows: 1,
      maxRows: 10,
    };
    this.file = null;
  }

  displayOptionBasedOnQuestionType = (id, type, options) => {
    const {
      store: {
        ReferralStore: {
          formAnswers,
          multipleChoices,
          multipleChoicesRadio,
          descriptiveAnswer,
          setMultipleChoicesRadio,
          setMultipleChoices,
          setDescriptive,
        },
      },
    } = this.props;
    const answer = formAnswers.find((item) => item.formQuestionId === id);
    if (type === "multiple_choices") {
      const myOptions = options.map((option) => {
        return { value: option.id, label: option.option };
      });

      let answerMultipleOptions = [];
      if (answer) {
        answerMultipleOptions = answer.answer
          .split(",")
          .map((ans) => Number(ans));
      }

      return (
        <Checkbox.Group
          name={id.toString()}
          options={myOptions}
          onChange={(e) => {
            setMultipleChoices(id, e);
          }}
          value={multipleChoices[id]}
        />
      );
    } else if (type === "multiple_choices_radio") {
      // let answerRadioOptions = undefined;
      // if (answer) {
      //   answerRadioOptions = answer.formQuestionOptionId;
      // }

      return (
        <Radio.Group
          className="radio-group"
          onChange={(e) => {
            setMultipleChoicesRadio(id, e.target.value);
          }}
          value={multipleChoicesRadio[id]}
        >
          {options.map((option) => (
            <Radio key={option.id} value={option.id}>
              {option.option}
            </Radio>
          ))}
        </Radio.Group>
      );
    } else if (type === "descriptive") {
      // let answerDescriptive = "";
      // if (answer && answer.answer) {
      //   answerDescriptive = answer.answer;
      // }
      return (
        <div>
          <textarea
            rows={this.state.rows[id] ? this.state.rows[id] : 1}
            className="descriptive-textbox"
            /* value={descriptiveAnswer[id]} */
            defaultValue={descriptiveAnswer[id]}
            onChange={(e) => this.setDescriptiveAnswer(id, e)}
            /* onBlur={() => {
              this.setState({ showWarnings: true });
            }} */
          />
          {/* {!this.state.descriptiveAnswer[id] && this.state.showWarnings ? (
            <div className="warning">* field cannot be empty</div>
          ) : null} */}
        </div>
      );
    }
  };

  saveDraft = () => {
    const {
      store: {
        ReferralStore: { saveForLater },
      },
    } = this.props;
    saveForLater();
  };

  setDescriptiveAnswer = (id, e) => {
    const {
      store: {
        ReferralStore: { setDescriptive },
      },
    } = this.props;

    // TODO: This is an unused component right now, but if needed in future, ExpandableTextArea can be used instead of logic below to avoid typing lag

    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = e.target.rows;
    e.target.rows = minRows;

    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }
    this.setState((prevState) => {
      return {
        rows: {
          ...prevState.rows,
          [id]: currentRows < maxRows ? currentRows : maxRows,
        },
      };
    });

    setDescriptive(id, e.target.value);
  };

  exitModal = () => {
    const {
      store: {
        ReferralStore: {
          fetchAllIntake,
          setDataSaved,
          setSelfIntake,
          resetResponses,
          setIntakeModalVisible,
        },
      },
    } = this.props;
    setIntakeModalVisible(false);
    fetchAllIntake();
    resetResponses();
    setSelfIntake(false);
    setDataSaved(false);
  };

  handleCloseIntakeForm = () => {
    const {
      store: {
        ReferralStore: {
          isDataSaved,
          setIntakeModalVisible,
          setSelfIntake,
          resetResponses,
        },
      },
    } = this.props;
    if (!isDataSaved) {
      setIntakeModalVisible(true);
    } else {
      resetResponses();
      setSelfIntake(false);
    }
  };

  updateReferralStatus = ({ key }) => {
    const {
      store: {
        ReferralStore: { setCurrentStatus },
      },
    } = this.props;
    setCurrentStatus(Number(key));
  };

  uploadPdf = async () => {
    const {
      store: {
        ReferralStore: { exportForm, postExportData, saveForLater },
      },
    } = this.props;
    let success = false;
    exportForm(this.file);
  };

  fetchAssignHistory = () => {
    const {
      store: {
        ReferralStore: { fetchAssignHistory, setAssignHistoryVisible },
      },
    } = this.props;

    fetchAssignHistory();
    setAssignHistoryVisible(true);
  };

  loadMoreAssignHistory = () => {
    const {
      store: {
        ReferralStore: { fetchAssignHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchAssignHistory();
  };

  deleteForm = () => {
    const {
      store: {
        ReferralStore: { deleteReferral },
      },
    } = this.props;
    deleteReferral();
  };

  updateAssignTo = async (key) => {
    const {
      store: {
        ReferralStore: { updateAssignToIds },
      },
    } = this.props;
    updateAssignToIds(key);
  };

  fetchExportHistory = () => {
    const {
      store: {
        ReferralStore: { fetchExportHistory, setExportHistoryVisible },
      },
    } = this.props;

    fetchExportHistory();
    setExportHistoryVisible(true);
  };

  render() {
    const {
      store: {
        ReferralStore: {
          responsesLength,
          savingForm,
          updatingStatus,
          currentReferral,
          currentStatus,
          hasInAppSurvey,
          referralFormQuestions,
          fetchingReferralData,
          targetUserId,
          targetUsername,
          multipleChoices,
          multipleChoicesRadio,
          clientSelectedForm,
          descriptiveAnswer,
          skipAssignHistory,
          totalAssignHistory,
          historyLoading,
          resetHistory,
          assignHistoryVisible,
          setAssignHistoryVisible,
          assignHistory,
          setIntakeModalVisible,
          intakeModalVisible,
          isDataSaved,
        },
        NotificationStore,
        ProfileStore: { HpUsers, getUserFromHpUsers },
        BroadcastStore: { createDm, dmLoading },
        SiteStore: { getSiteNameFromAllSites },
      },
    } = this.props;

    const statusMenu = [
      {
        status: "New Referral",
        key: 101,
      },
      {
        status: "Form Reviewed",
        key: 102,
      },
      {
        status: "Submitted, Pending Auth",
        key: 103,
      },
      {
        status: "Archive - Unable to contact",
        key: 104,
      },
      {
        status: "Archive - Pt denied",
        key: 105,
      },
      {
        status: "Archive - Other",
        key: 106,
      },
      {
        status: "Assigned",
        key: 107,
      },
      {
        status: "Approved",
        key: 108,
      },
    ];
    const referralStatusMenu = (
      <div>
        <Menu onClick={this.updateReferralStatus}>
          {statusMenu.map((item) => {
            return (
              <Menu.Item
                disabled={updatingStatus || item.key === currentStatus}
                key={item.key}
                icon={<UserOutlined />}
              >
                {item.status}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );

    const isRSN = clientSelectedForm === "RSN";
    const isRC = clientSelectedForm === "RC";
    const isRSN_RC = clientSelectedForm === "RSN_RC";
    const formattedPhone = getFormattedPhoneNumber(currentReferral?.targetUserInfo?.phoneNumber);
    const rcQuestions = referralFormQuestions.reduce((arr, question) => {
      if (RC_HP_ARRAY.includes(question.id)) {
        return [...arr, toJS(question)];
      }
      return arr;
    }, []);
    const rsnQuestions = referralFormQuestions.reduce((arr, question) => {
      if (RSN_HP_ARRAY.includes(question.id)) {
        return [...arr, toJS(question)];
      }
      return arr;
    }, []);

    return (
      <div>
        <CustomModal
          title="Assign History"
          visible={assignHistoryVisible}
          loading={historyLoading}
          loadMore={this.loadMoreAssignHistory}
          hasMore={skipAssignHistory < totalAssignHistory}
          onCancel={() => {
            resetHistory();
            setAssignHistoryVisible(false);
          }}
          data={assignHistory ? assignHistory : []}
        />
        <div className="referral-header">
          <Tooltip
            title={
              isRSN
                ? toolTips.RSN
                : isRC
                ? toolTips.RC
                : isRSN_RC
                ? toolTips.RSN_RC
                : null
            }
            placement="leftBottom"
          >
            <div>
              {clientSelectedForm === "RSN_RC"
                ? "RSN & RC"
                : clientSelectedForm}{" "}
              {CBRS.refForm}
              {/* <div className="referral-time">Last 10min ago</div> */}
            </div>
          </Tooltip>
          <div className="flag-actions-container">
            <button
              className="referral-form-button"
              disabled={updatingStatus}
              onClick={() =>
                NotificationStore.setConfirm(() => {
                  this.deleteForm();
                }, CBRS.deleteNotification)
              }
            >
              {CBRS.deleteForm}
            </button>
            <button
              className="referral-form-button"
              disabled={dmLoading}
              onClick={() => createDm(targetUsername, targetUserId)}
            >
              {CBRS.msgClient}
            </button>
            <div className="export-form">
              {clientSelectedForm === "RSN_RC" ? (
                <div className="export-pdf-container">
                  <div
                    className="referral-form-button"
                    onClick={this.uploadPdf}
                  >
                    <PDFDownloadLink
                      document={
                        <MyDocument
                          multipleChoices={multipleChoices}
                          multipleChoicesRadio={multipleChoicesRadio}
                          descriptiveAnswer={descriptiveAnswer}
                          site={getSiteNameFromAllSites(
                            currentReferral.program
                          )}
                          referralFormQuestions={rsnQuestions}
                        />
                      }
                      fileName="intake-rsn.pdf"
                    >
                      {({ blob, url, loading, error }) => {
                        this.file = blob;
                        return loading ? "Exporting..." : "Export RSN Form";
                      }}
                    </PDFDownloadLink>
                  </div>
                  <div
                    className="referral-form-button"
                    onClick={this.uploadPdf}
                  >
                    <PDFDownloadLink
                      document={
                        <MyDocument
                          multipleChoices={multipleChoices}
                          multipleChoicesRadio={multipleChoicesRadio}
                          descriptiveAnswer={descriptiveAnswer}
                          site={getSiteNameFromAllSites(
                            currentReferral.program
                          )}
                          referralFormQuestions={rcQuestions}
                        />
                      }
                      fileName="intake-rc.pdf"
                    >
                      {({ blob, url, loading, error }) => {
                        this.file = blob;
                        return loading ? "Exporting..." : "Export RC Form";
                      }}
                    </PDFDownloadLink>
                  </div>
                </div>
              ) : (
                <div className="referral-form-button" onClick={this.uploadPdf}>
                  <PDFDownloadLink
                    document={
                      <MyDocument
                        multipleChoices={multipleChoices}
                        multipleChoicesRadio={multipleChoicesRadio}
                        descriptiveAnswer={descriptiveAnswer}
                        site={getSiteNameFromAllSites(currentReferral.program)}
                        referralFormQuestions={referralFormQuestions}
                      />
                    }
                    fileName="intake.pdf"
                  >
                    {({ blob, url, loading, error }) => {
                      this.file = blob;
                      return loading ? "Exporting..." : "Export Form";
                    }}
                  </PDFDownloadLink>
                </div>
              )}
              <div
                className="show-export-history"
                onClick={() => {
                  this.fetchExportHistory();
                }}
              >
                {CBRS.showExportHistory}
              </div>
            </div>

            <button
              className="referral-form-button"
              disabled={savingForm || fetchingReferralData}
              onClick={this.saveDraft}
            >
              {CBRS.saveForLater}
            </button>
            <img
              src={close}
              onClick={() => this.handleCloseIntakeForm()}
              alt="Close"
            />
          </div>
        </div>
        {/* <div className="user-profile-sidebar-2">
          <MyDocument
            site={getSiteNameFromAllSites(selectedSiteId)}
            formAnswers={formAnswers}
            referralFormQuestions={referralFormQuestions}
          />
        </div> */}
        <div className="referral-scroll">
          <div className="referral-sub">
            {/* <div>
              <span className="sub">{CBRS.client}</span>{" "}
              <span className="main">
                {currentReferral.targetUserInfo.fullName}
              </span>
              <span className="sub"> {CBRS.nowActive}</span>
            </div> */}
            <div className="referral-info">
              <div>
                <div>
                  <span className="sub">{CBRS.site} </span>
                  <span className="main">
                    {getSiteNameFromAllSites(currentReferral.siteId)}
                  </span>
                </div>
                <div>
                  <span className="sub">{CBRS.username} </span>
                  <span className="main">
                    {currentReferral.targetUserInfo.username}
                  </span>
                </div>
                <div>
                  <span className="sub">{CBRS.dobForm} </span>
                  <span className="main">
                    {moment(
                      currentReferral.targetUserInfo.dob,
                      "YYYY-MM-DD"
                    ).format("MMMM Do YYYY")}
                  </span>
                </div>
                <div>
                  <span className="sub">{CBRS.phoneForm} </span>
                  <span className="main">{formattedPhone}</span>
                </div>
                <div>
                  <span className="sub">
                    <Checkbox checked={!currentReferral.hasPhone}>
                      <span>{CBRS.noPhone}</span>
                    </Checkbox>
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <span className="sub">{CBRS.referrer} </span>
                  <span className="main">
                    {currentReferral.sourceUserInfo
                      ? currentReferral.sourceUserInfo.fullName
                      : "Self Referral"}
                  </span>
                </div>
                <div>
                  <span className="sub">{CBRS.startedOn} </span>
                  <span className="main">
                    {moment(currentReferral.createdAt).format("MMMM Do YYYY")}
                  </span>
                </div>
                <div>
                  <span className="sub">{CBRS.phoneForm} </span>
                  <span className="main">
                    {currentReferral.sourceUserInfo
                      ? currentReferral.sourceUserInfo.phoneNumber
                      : " - "}
                  </span>
                </div>
              </div>
            </div>
            <div className="referral-status">
              <span className="refer-status">
                <div
                  id="referral-status-dropdown"
                  style={{ position: "relative" }}
                >
                  {CBRS.refStatus}
                  <Dropdown
                    getPopupContainer={() =>
                      document.getElementById("referral-status-dropdown")
                    }
                    trigger={["click"]}
                    overlay={referralStatusMenu}
                  >
                    <Button>
                      {getIntakeStatus(currentStatus)} <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </span>
              <span className="assign-status">
                <div id="assign-dropdown" style={{ position: "relative" }}>
                  {CBRS.assignTo}
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ width: 200 }}
                    defaultValue={currentReferral.alreadyAssigned}
                    placeholder="Select HighPoint Contact"
                    optionFilterProp="children"
                    onChange={this.updateAssignTo}
                    filterOption={(input, option) =>
                      get(option, "props.children.props.children", [])
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={() =>
                      document.getElementById("assign-dropdown")
                    }
                  >
                    {HpUsers.length
                      ? HpUsers.map((user) => (
                          <Option
                            key={user.id}
                            value={user.contactId}
                            className="menu-item"
                          >
                            <div>{user.contactName}</div>
                          </Option>
                        ))
                      : hpUsers.map((user) => (
                          <Option
                            key={user.id}
                            value={user.contactId}
                            className="menu-item"
                          >
                            <div>{user.contactName}</div>
                          </Option>
                        ))}
                  </Select>
                  <img
                    src={info}
                    className="info-icon"
                    alt="info"
                    onClick={(e) => {
                      this.fetchAssignHistory();
                    }}
                  />
                </div>
              </span>
            </div>
            <div className="update-status">
              <div className="bold">{CBRS.updateStatusBold}</div>
              <div>{CBRS.updateStatus}</div>
            </div>
          </div>
          <div className="start-new-intake">
            {intakeModalVisible && !isDataSaved ? (
              <ConfirmModal
                loading={savingForm}
                onConfirm={() => this.saveDraft()}
                onClose={() => setIntakeModalVisible(false)}
                onCancel={() => this.exitModal()}
              />
            ) : null}
            <Tabs
              defaultActiveKey="1"
              tabBarStyle={{ border: 0 }}
              className="my-tabs-nav"
              onChange={() => {}}
            >
              <TabPane className="my-tabs-ink-bar" tab="Referral Form" key="1">
                {fetchingReferralData || savingForm ? (
                  <div className="saving-form">
                    <Spin />
                  </div>
                ) : (
                  <div className="rsn-form-container">
                    <div className="form-progress">
                      <span>{CBRS.progress} </span>
                      {/* <span className="referral-time">
                        Last 10min ago by <span> Sue Green </span>
                      </span> */}
                      <div className="form-progress-bar">
                        <Progress
                          percent={Math.trunc(
                            (responsesLength * 100) /
                              referralFormQuestions.length
                          )}
                          format={(percent) => `${percent}%`}
                          strokeColor="#FB9048"
                          trailColor="#fff"
                        />
                      </div>
                      <span>{CBRS.queFilled}</span>
                    </div>
                    <div className="referral-forms-container">
                      <div className="form-header"> {CBRS.clientInfo} </div>
                      {referralFormQuestions.map((question, index) => {
                        return (
                          <div className="rsn-questions" key={question.id}>
                            <span className="question-number">
                              {index + 1}.{" "}
                            </span>
                            <span className="subHeading">
                              {question.questionTitle}
                            </span>
                            {this.displayOptionBasedOnQuestionType(
                              question.id,
                              question.type,
                              question.options
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </TabPane>
              <TabPane
                className="my-tabs-ink-bar"
                tab="(In-app) Client Survey"
                key="2"
              >
                {hasInAppSurvey ? (
                  <div className="rsn-form-container">
                    <div className="referral-forms-container">
                      <div className="form-header"> {CBRS.clientInfo} </div>
                      <InAppSurvey />
                    </div>
                  </div>
                ) : null}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default SelfIntakeForm;
