import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Spin, Mentions, Row, Col, Dropdown, Icon, Menu, Modal, notification } from 'antd';
import imageCompression from 'browser-image-compression';

import { COMMENT_POST, IMG_GIF, IMG_NO_COMMENT, IMG_SEND_MESSAGE_ICON, IMG_UPLOAD_IMAGE } from '../../utils/ImageUtils';
import { addReaction, fetchNearbyComments } from '../../utils/forumFeedService';
import { GIF_TYPE, REACTION_TYPES, TIME_FILTERS } from '../../constants/GlobalConstant';
import SingleComment from './SingleComment';
import { IS_AMERI, IS_COMM, IS_ISLAND } from '../../utils/getEnvironment';
import { decryptData } from '../../utils/CommonUtils';
import MentionComponent from "./MentionChat";
import mixpanel from "mixpanel-browser";

const FeedComments = inject("store")(observer((props) => {
    const [comment, setComment] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isPostingComments, setIsPostingComments] = useState(false);
    const [isGifModalVisible, setIsGifModalVisible] = useState(false);
    const [isFetchingGifs, setIsFetchingGifs] = useState(false);
    const [search, setSearch] = useState('');
    const [gifs, setGifs] = useState([]);
    const fileInputRef = useRef(null);
    const {
        store: {
            ForumFeedStore: {
                fetchCommentLoading,
                hasMoreComments,
                feeds,
                comments,
                loadMoreComments,
                currentFeedId,
                flaggedCommentCount,
                selectedTimeFilter,
                notificationCommentId,
                mentionedUserName,
                setMentionedUserName,
                fetchMentionOptions,
            },
            MessagesStore: {
                activeTab,
            },
        },
        isDeleteOnly,
        onDeletionComplete,
        isAlertScreen,
        isFeedDeleted
    } = props;
    const feedIndex = feeds.findIndex(ele => ele?.id === currentFeedId);

    const containerRef = useRef(null);
    const onComponentMount = async () => {
        const { 
            notificationCommentId, 
            setNotificationCommentId,
            currentFeedId,
            setComments,
        } = props.store.ForumFeedStore;
        if(notificationCommentId) {
            const comments = await fetchNearbyComments(currentFeedId, notificationCommentId);
            setComments(comments);
            const notificationCommentIndex = comments.findIndex(comment => comment.id === notificationCommentId);
            if (notificationCommentIndex !== -1) {
                const reactedComment = containerRef?.current?.children[notificationCommentIndex];
                if(reactedComment) {
                    reactedComment.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }

        return () => {
            setNotificationCommentId(null);
        }
    }
    useEffect(() => {
        (async() => {
            await onComponentMount();
        })();
    },[notificationCommentId]);

    useEffect(() => {
        (async() => {
            await onComponentMount();
        })();
    },[]);

    const onChangeComment = (value) => {
        setComment(value);
        setIsDisabled(value.trim() === "" ? true : false)
    };

    const extractTags = (text) => {
        const regex = /@(\w+)/g;
        const matches = [...text.matchAll(regex)];
        return matches.map(match => match[0]);
    };

    const doPostComment = async (data) => {
        const { 
            ForumFeedStore: {
                currentFeedId,
                comments,
                setComments,
                feeds,
                setFeeds,
            },
            AuthStore: {
                userId
            }
        } = props.store;
        try {
            setIsPostingComments(true);

            // clean up
            setComment('');
            setIsDisabled(true);

            // add comment
            const newComment = await addReaction(REACTION_TYPES.comment, currentFeedId, data, `${userId}`);
            const decryptedNewComment = {
                ...newComment,
                data: decryptData(newComment?.data?.data),
                user: {
                    ...newComment?.user,
                    data: newComment?.user?.data?.encryptedData
                      ? decryptData(newComment?.user?.data?.encryptedData)
                      : newComment?.user?.data,
                }
            };

            mixpanel.track("Forum Post Comment", {
                from: "WEB",
                data: JSON.stringify(decryptedNewComment),
                activity_id: newComment?.activity_id,
                comment_id: newComment.id
            });

            // get latest comments & feed reaction counts
            // fetchComments();
            const commentsClone = _.cloneDeep(comments);
            commentsClone.unshift(decryptedNewComment);
            setComments([...commentsClone]);
            /** update reaction count */
            const feedsClone = _.cloneDeep(feeds);
            const oldCount = _.get(feedsClone, `${[feedIndex]}.reaction_counts.comment`, 0);
            _.set(feedsClone, `${[feedIndex]}.reaction_counts.comment`, oldCount + 1);
            
            /** update store data */
            const ownChildren = _.get(feedsClone, `${[feedIndex]}.own_reactions.comment`, []);
            ownChildren.unshift(newComment);
            _.set(feedsClone, `${[feedIndex]}.own_reactions.comment`, ownChildren);
            setFeeds([...feedsClone]);

            if (data.comment) {
                const allTags = extractTags(data.comment);

                if (allTags.length) {
                    mixpanel.track("Forum tags", {
                        from: "WEB",
                        data: JSON.stringify(allTags),
                        activity_id: currentFeedId,
                        comment_id: newComment.id
                    });
                }
            }

            setIsPostingComments(false);
        } catch(error) {
            setIsPostingComments(false);
            notification.error({
                message: 'Error',
                description: error?.message,
                placement: 'bottomRight',
            });
        }
    }

    const getImageSize = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function() {
                resolve({ width: this.width, height: this.height });
            };
            img.onerror = function() {
                reject(new Error("Could not load image"));
            };
            img.src = URL.createObjectURL(file);
        });
    }

    const doUploadImage = async (file) => {
        try {
            setIsPostingComments(true);
            const { uploadMedia } = props.store.ForumFeedStore;
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            const compressedFile = await imageCompression(file, options);
            const response = await uploadMedia("feed", { 'image': [compressedFile, file?.name] });
            if (response && response?.data?.imgUrl) {
                doPostComment(
                    {
                        attachments: [{
                            size: compressedFile?.size,
                            type: file?.type,
                            url: `/${response.data.imgUrl.split("/").pop()}`,
                            ...(await getImageSize(compressedFile)),
                        }]
                    }
                );
            }
            if (isPostingComments) {
                setIsPostingComments(false);
            }
        } catch(error) {
            setIsPostingComments(false);
            notification.error({
                message: 'Error',
                description: error?.message,
                placement: 'bottomRight',
            });
        }
    }

    const handleLoadMore = () => {
        const { pagination, setPaginationData } = props.store.GifStore;
        const searchValue = search;
        setPaginationData({
            ...pagination,
            current: pagination.current + 1
        });
        setIsFetchingGifs(true);
        if (searchValue) {
            fetchGifsBySearchData(searchValue, true);
        } else {
            fetchTrendingGifData(true);
        }
    };

    const fetchGifsBySearchData = async (searchValue, isLoadmore) => {
        const { getGifsBySearchService, gifsData } = props.store.GifStore;
        await getGifsBySearchService(searchValue);
        setIsFetchingGifs(false);
        const gifsDataClone = _.cloneDeep(gifsData);
        if(isLoadmore) {
            const gifsClone = _.cloneDeep(gifs);
            setGifs([...gifsClone, ...gifsDataClone]);
        } else {
            setGifs([...gifsDataClone]);
        }
    };

    const handleGifSearch = async (event) => {
        const searchValue = event.target.value;
        setIsFetchingGifs(true);
        setSearch(searchValue);
        if (searchValue) {
            fetchGifsBySearchData(searchValue);
        } else {
            fetchTrendingGifData(false);
        }
    };

    const doUploadGif = (url) => {
        closeModal();
        doPostComment({ 
            attachments: [{ 
                size: 0, 
                type: GIF_TYPE, 
                url 
            }]
        });
    };

    const fetchTrendingGifData = async (isLoadmore) => {
        const { getTrendingGifsService, gifsData } = props.store.GifStore;
        await getTrendingGifsService();
        setIsFetchingGifs(false);
        const gifsDataClone = _.cloneDeep(gifsData);
        if(isLoadmore) {
            const gifsClone = _.cloneDeep(gifs);
            setGifs([...gifsClone, ...gifsDataClone]);
        } else {
            setGifs([...gifsDataClone]);
        }
    };    

    const closeModal = () => {
        props.store.GifStore.resetPagination();
        setIsGifModalVisible(false);
    };

    const openModal = () => {
        fetchTrendingGifData(false);
        setIsGifModalVisible(true);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.shiftKey) {
            event.preventDefault();
            setComment(comment + '\n');
        }
        if (event.key === 'Enter' && !event.shiftKey && !isDisabled) {
            event.preventDefault();
            doPostComment({ comment });
        }
    }

    const optionsList = (
        <div>
            <Menu className="menu" style={{ border: '1px solid #FF4D00', borderRadius: 5 }}>
                <Menu.Item key={1} onClick={() => {fileInputRef.current.click()}}>
                    {isPostingComments
                        ? <Spin /> 
                        : <img src={IMG_UPLOAD_IMAGE} className="image-upload-img" alt="Send"/> }
                    Upload Image
                </Menu.Item>
    
                {(IS_ISLAND || IS_COMM || IS_AMERI) && <Menu.Item key={2} onClick={() => { openModal() }}>
                    {false
                        ? <Spin /> 
                        : <img src={IMG_GIF} className="gif-upload-img" alt="Send GIF" onClick={() => { openModal() }} />}
                    Upload GIF
                </Menu.Item>}
            </Menu>
        </div>
    );

    const feed = feeds[feedIndex];
    const commentCount = (feed?.reaction_counts?.comment || 0) - (feed?.meta?.deleted_comment_count || 0);
    const showComments = comments?.length > 0 && (isAlertScreen || comments?.some((ele) => !ele?.data?.deleted_at));
    
    
    const renderComments = () => {
        const { selectedMessage } = props.store.FlaggedMessagesStore;
        return <div id="comment-list-container" ref={containerRef}>
            {comments.map((comment, index) => {
                return (isAlertScreen || !comment?.data?.deleted_at) &&
                <React.Fragment>
                    <div
                        style={{
                            backgroundColor: (
                              isAlertScreen &&
                              (comment?.data?.deleted_at || isFeedDeleted) &&
                              comment.id === selectedMessage.commentId
                            ) ? '#FFE6E8' : 'none'
                        }}
                    >
                        <SingleComment
                            key={`feed-comment-${comment.id}`}
                            isSelected={notificationCommentId === comment?.id}
                            comment={comment}
                            commentIndex={index}
                            isDeleteOnly={isDeleteOnly}
                            isReadOnly={isAlertScreen && selectedMessage?.comment?.id !== comment?.id}
                            onDeletionComplete={onDeletionComplete}
                            isAlertScreen={isAlertScreen}
                            isFeedDeleted={isFeedDeleted}
                            addUserMentionToComment={addUserMentionToComment}
                        />
                    </div>
                </React.Fragment>
            })}
        </div>
    }

    const renderAddCommentLoader = () => {
        return <React.Fragment>
            {/** Loader for add comment */}
            {
                isPostingComments &&
                <div className="form-loading">
                    <Spin style={{ marginTop: '20px' }} />
                </div>
            }
            {/** Loader for add comment ends*/}
        </React.Fragment>
    }

    const clearMentionedUserName  = () => {
        setMentionedUserName("");
    }

    const addUserMentionToComment  = (mentionedUserName) => {
        setMentionedUserName(mentionedUserName);
    }

    const renderGifData = () => {
        return (
          <div className="gif-data-wrapper">
              <input className="gif-search-bar" placeholder="Search GIF..." value={search} onChange={handleGifSearch}/>
              {gifs?.length ?
                (<>
                    <div className="gif-img-wrapper">
                        {gifs?.map((obj, index) => (
                          <div className="gif-img-inner" key={index}>
                              <em>
                                  <img src={obj.images.preview_gif.url} value={obj.images.original.url}
                                       onClick={() => doUploadGif(obj.images.original.url)}/>
                              </em>
                          </div>
                        ))}
                    </div>
                    {isFetchingGifs ? <Spin/> : <button className="load-more-btn" onClick={handleLoadMore}>Load
                        More...</button>}
                </>) : null}
          </div>
        )
    }

    const setUploadRef = (ref) => (fileInputRef.current = ref)

    const onMention = (mentionedUserName) => {
        const updatedComment = `${comment ?? ""}@${mentionedUserName} `;
        setComment(updatedComment);
        setIsDisabled(updatedComment.trim() === "");
    }

    return (
      // Header
      <>
          <div className="right-section" style={{ width: "71vw" }}>
              {/** Show comment start */}
              {
                  (showComments || fetchCommentLoading || isPostingComments)
                    ? (
                      <div style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                      }}>
                          {/** comment counts starts */}

                          {
                            !isDeleteOnly &&
                            <div id="comment-counts-container">
                                {selectedTimeFilter === TIME_FILTERS.FLAGGED
                                  ? <span>{flaggedCommentCount[currentFeedId] || 0} flagged</span>
                                  : <div>
                                  <img src={COMMENT_POST} style={{ margin: '0px 5px 5px 0px' }}/>
                                        <b>Comments</b>
                                    </div>
                                  }
                                  <span>{commentCount} comments</span>
                              </div>
                            }
                            {/** comment counts ends */}
                            {/** comments or loader starts */}
                            <div id="display-feed-data-Container">
                                <div style={{
                                    position: "absolute",
                                    inset: 0,
                                    height: "calc(100% - 66px)",
                                    overflowY: "scroll",
                                }}>
                                    {renderAddCommentLoader()}
                                    {renderComments()}
                                    <div>
                                        {
                                            fetchCommentLoading ?
                                              <div className="form-loading">
                                                  <Spin style={{ marginTop: '20px' }} />
                                              </div>
                                              :
                                              (comments.length !== 0 && hasMoreComments) &&
                                              <Row>
                                                  <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                      <p
                                                        className='load-more'
                                                        onClick={() => loadMoreComments(comments[comments.length - 1]?.id)}
                                                      >Load more</p>
                                                  </Col>
                                              </Row>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/** comments or loader ends */}
                        </div>
                    ) : 
                    (
                        <>
                            {/** comments not found starts */}
                            <Row>
                                <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh', flexDirection: 'column' }}>
                                    <img
                                        src={IMG_NO_COMMENT}
                                        alt="No comment"
                                    />
                                    <span style={{ marginTop: 20 }}>
                                        No comment(s) found ...
                                    </span>
                                </Col>
                            </Row>
                            {/** comments not found ends */}
                        </>
                    )
                }
                {/** Show comment end */}

                {/** comment input box starts */}
                { !isAlertScreen &&
                    <div style={{ position: "relative" }}>
                        <div> 
                            <div 
                                className="input-container"
                            >
                                <MentionComponent
                                  activeTab={activeTab}
                                  mentionedUserName={mentionedUserName}
                                  clearMentionedUserName={clearMentionedUserName}
                                  isGifModalVisible={isGifModalVisible}
                                  closeModal={closeModal}
                                  renderGifData={renderGifData}
                                  setUploadRef={setUploadRef}
                                  uploadImage={(e) => { doUploadImage(e.target.files[0]) }}
                                  optionsList={optionsList}
                                  sendButtonIsDisabled={isDisabled}
                                  onSend={() => isDisabled ? {} : doPostComment({ comment })}
                                  onKeyDown={(e) => handleKeyPress(e)}
                                  onMention={onMention}
                                  textAreaOnChange={onChangeComment}
                                  textAreaValue={comment}
                                  fetchMentionOptions={fetchMentionOptions}
                                />
                            </div>
                        </div>
                    </div>
                }
                {/** comment input box ends */}

            </div >
        </>
    );
}));

export default FeedComments;
