import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { toJS } from 'mobx';

import "../../styles/global.scss";

import SimpleBarChart from "../UI/SimpleBarChart";
// import SimpleLineChart from "../UI/SimpleLineChart";
import SimplePieChart from "../UI/SimplePieChart";

import { IMG_RED_CIRCLE, IMG_YELLOW_CIRCLE, IMG_GREEN_CIRCLE, IMG_GROUP_LOGO } from '../../utils/ImageUtils';

const GREEN_COLOR_CIRCLE = 'Green';
const YELLOW_COLOR_CIRCLE = 'Yellow';
const RED_COLOR_CIRCLE = 'Red';

const membersReachedData = {
    "Overall_reach_ACDE": {
        "AllTime": {
            "name": "Since Inception",
            "numberOfMembersOutreached": 4500,
            // Anyone we called for over 30 sec, or sent an SMS or called us
            "numberOfMembersTouched": 800,
            // Sent us an SMS, Called us or we called them for over 60 seconds
            "numberOfMembersWithFirstTouchpointIn3Months": 1
            //
        },
        "Month1": {
            "name": "February",
            "numberOfMembersOutreached": 215,
            "numberOfMembersTouched": 164,
            "numberOfMembersWithFirstTouchpointIn3Months": 1
        },
        "Month2": {
            "name": "January",
            "numberOfMembersOutreached": 230,
            "numberOfMembersTouched": 250,
            "numberOfMembersWithFirstTouchpointIn3Months": 1
        },
        "Month3": {
            "name": "December",
            "numberOfMembersOutreached": 222,
            "numberOfMembersTouched": 54,
            "numberOfMembersWithFirstTouchpointIn3Months": 1
        },
        "Month4": {
            "name": "November",
            "numberOfMembersOutreached": 200,
            "numberOfMembersTouched": 126,
            "numberOfMembersWithFirstTouchpointIn3Months": 1
        },
        "Month5": {
            "name": "October",
            "numberOfMembersOutreached": 222,
            "numberOfMembersTouched": 57,
            "numberOfMembersWithFirstTouchpointIn3Months": 1
        },
        "Month6": {
            "name": "September",
            "numberOfMembersOutreached": 200,
            "numberOfMembersTouched": 46,
            "numberOfMembersWithFirstTouchpointIn3Months": 1
        }
    },
    "App_Touchpoints": {
        "October": 12,
        "November": 12,
        "December": 12,
        "January": 12,
        "February": 12
    }
};

const data = [
    { name: 'Cals Made', value: 2800 },
    { name: 'PT SMSs us', value: 1500 },
    { name: 'Pt called us', value: 300 },
    { name: 'SMS Sent', value: 13000 },
];

const dataForLastQuarter = [
    {
        name: "Outbound Calls",
        outreachAttempts: 535
    },
    {
        name: "Outbound SMS",
        outreachAttempts: 1850
    },
    {
        name: "Inbound Calls",
        outreachAttempts: 86
    },
    {
        name: "Inbound SMS",
        outreachAttempts: 551
    },
    {
        name: "App Outreach",
        outreachAttempts: 300
    }
];

const dataForAllTime = [
    {
        name: "Outbound Calls",
        outreachAttempts: 2970
    },
    {
        name: "Outbound SMS",
        outreachAttempts: 13250
    },
    {
        name: "Inbound Calls",
        outreachAttempts: 385
    },
    {
        name: "Inbound SMS",
        outreachAttempts: 1580
    },
    {
        name: "App Outreach",
        outreachAttempts: 1000
    }
];

const dataForSignups = [
    {
        name: 'Jul',
        newSignups: 29,
        acdeSignups: 10
    },
    {
        name: 'Aug',
        newSignups: 132,
        acdeSignups: 40
    },
    {
        name: 'Sep',
        newSignups: 61,
        acdeSignups: 14
    },
    {
        name: 'Oct',
        newSignups: 37,
        acdeSignups: 4
    },
    {
        name: 'Nov',
        newSignups: 31,
        acdeSignups: 6
    },
    {
        name: 'Dec',
        newSignups: 27,
        acdeSignups: 10
    },
    {
        name: 'Jan',
        newSignups: 23,
        acdeSignups: 3
    },
    {
        name: 'Feb (to date)',
        newSignups: 9,
        acdeSignups: 6
    },
];

const dataForPosters = [
    {
        name: 'Q3 2021',
        appPosters: 60,
        acdePosters: 9
    },
    {
        name: 'Q4 2021',
        appPosters: 85,
        acdePosters: 8
    },
    {
        name: 'Q1 2022 (In progress)',
        appPosters: 30,
        acdePosters: 5
    },
];

const dataForMembersTouched = [
    {
        name: "Apr",
        numberOfMembersTouched: 74
    },
    {
        name: "May",
        numberOfMembersTouched: 242
    },
    {
        name: "Jun",
        numberOfMembersTouched: 154
    },
    {
        name: "Jul",
        numberOfMembersTouched: 147
    },
    {
        name: "Aug",
        numberOfMembersTouched: 168
    },
    {
        name: "Sep",
        numberOfMembersTouched: 46
    },
    {
        name: "Oct",
        numberOfMembersTouched: 57
    },
    {
        name: "Nov",
        numberOfMembersTouched: 126
    },
    {
        name: "Dec",
        numberOfMembersTouched: 54
    },
    {
        name: "Jan",
        numberOfMembersTouched: 250
    },
    {
        name: "Feb",
        numberOfMembersTouched: 164
    }
];

@withRouter
@inject("store")
@observer
class Insights extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usersData: {},
            userMessagesCountByNotes: 0,
            dataForMembersReached: [],
            // dataForTotalMembers: {},
            numberOfMembersOutreached: 0,
            numberOfMembersTouched: 0,
            dataForMembersSignup: [],
            dataForAvailableGroups: [],
            dataForProviders: [],
            dataForMostActiveGroups: []
        };
    }

    componentDidMount() {
        this.fetchInsightReportData();
        this.executeSchedulerForMixpanelApi();
    }

    // componentWillUnmount() {
    //     this.fetchInsightReportData();
    //     // setStatsTabKeyForUnmount(1);
    //     // setStatsData([]);
    // };

    executeSchedulerForMixpanelApi() {
        // const currentTime = new Date().getTime();  //current unix timestamp
        // const execTime = new Date().setHours(10, 23, 0, 0);  //API call time = today at 20:00
        // console.log('EXEC TIME', execTime);
        // let timeLeft;
        // if (currentTime < execTime) {
        //     //it's currently earlier than 20:00
        //     timeLeft = execTime - currentTime;
        // } else {
        //     //it's currently later than 20:00, schedule for tomorrow at 20:00
        //     timeLeft = execTime + 86400000 - currentTime
        // }
        // setTimeout(function () {
        //     setInterval(function () {
        //         console.log('FUCTION EXECUTED')
        //         //your code
        //     }, 86400000);  //repeat every 24h
        // }, timeLeft);  //wait until 20:00 as calculated above
    }

    getBarChartDataForMembersReached(membersReachedData = {}) {
        const membersReachedObj = {};
        if (membersReachedData && Object.keys(membersReachedData).length) {
            // const dataArr = [];
            // const compareArrForMonths = ['Month1', 'Month2', 'Month3', 'Month4', 'Month5', 'Month6'];
            const { Overall_reach_ACDE: overallReachAcde } = membersReachedData;
            if (overallReachAcde) {
                let id = 1;
                for (let key in overallReachAcde) {
                    const valueObj = overallReachAcde[key];
                    if (key === 'AllTime' && valueObj) {
                        membersReachedObj['allTimeData'] = valueObj
                    }

                    // if (compareArrForMonths.indexOf(key) > -1 && valueObj) {
                    //     dataArr.push({ ...valueObj, id });
                    //     id++;
                    // }
                }
            }
            // membersReachedObj['monthsData'] = dataArr.sort((a, b) => b.id - a.id);
            membersReachedObj['monthsData'] = dataForMembersTouched;
        }
        return membersReachedObj;
    };

    getBarChartDataForMembersSignup(membersSignupData = []) {
        return membersSignupData;
    };

    setGroupsDataToState() {
        const {
            store: { InsightsStore: { availableGroupsData, providersData, mostActiveGroupsData } },
        } = this.props;

        const availableGroupsDataArr = toJS(availableGroupsData);
        const providersDataArr = toJS(providersData);
        const mostActiveGroupsDataArr = toJS(mostActiveGroupsData);
        const objToSet = {};

        if (availableGroupsDataArr && availableGroupsDataArr.length) {
            objToSet['dataForAvailableGroups'] = availableGroupsDataArr;
        }
        if (providersDataArr && providersDataArr.length) {
            objToSet['dataForProviders'] = providersDataArr;
        }
        if (mostActiveGroupsDataArr && mostActiveGroupsDataArr.length) {
            objToSet['dataForMostActiveGroups'] = mostActiveGroupsDataArr;
        }

        this.setState({
            ...this.state,
            ...objToSet
        });
    };

    async fetchInsightReportData() {
        const {
            store: {
                InsightsStore,
                InsightsStore: { usersData }
            },
        } = this.props;
        try {
            InsightsStore.setLoading(true);
            // await InsightsStore.getUserInsightReport();
            // await InsightsStore.getUserMessagesCount();
            await InsightsStore.getMostActiveGroup();
            await InsightsStore.getGroupsAvailableOnPlatform();
            await InsightsStore.getProvidersList();
            const userDataObj = toJS(usersData);
            if (userDataObj && Object.keys(userDataObj).length) {
                this.setState({
                    ...this.state,
                    usersData: userDataObj,
                    userMessagesCountByNotes: InsightsStore.userMessagesCountByNotes || 0
                })
            }
            const { allTimeData, monthsData } = this.getBarChartDataForMembersReached(membersReachedData);
            const membersSignupData = this.getBarChartDataForMembersSignup(dataForSignups);
            if (membersSignupData && membersSignupData.length) {
                this.setState({
                    ...this.state,
                    dataForMembersSignup: membersSignupData
                });
            }
            if (allTimeData || monthsData) {
                const numberOfMembersOutreached = allTimeData.numberOfMembersOutreached || 0;
                const numberOfMembersTouched = allTimeData.numberOfMembersTouched || 0;

                this.setState({
                    ...this.state,
                    numberOfMembersOutreached,
                    numberOfMembersTouched,
                    dataForMembersReached: monthsData || []
                });
            }
            this.setGroupsDataToState();
            InsightsStore.setLoading(false);
        } catch (error) {
            InsightsStore.setLoading(false);
        }
    };

    render() {
        const { usersData, userMessagesCountByNotes, dataForMostActiveGroups, dataForProviders, dataForAvailableGroups, dataForMembersSignup, dataForMembersReached, numberOfMembersOutreached, numberOfMembersTouched } = this.state;
        return (
            <>
                <div className="insights-report-wrapper">
                    <h2 className="program-summary-header">Program Summary from Launch 7/25/21</h2>

                    <div className="count-data-wrapper">
                        <div className="count-data-inner"></div>
                        <div className="count-data-inner">
                            <h3>Since Inception</h3>
                        </div>
                        <div className="count-data-inner">
                            <h3>Q4 2021</h3>
                        </div>
                        <div className="count-data-inner">
                            <h3 className="count-header">Members receiving 1-1 outreach</h3>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{4979}</h2>
                            </div>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{1618}</h2>
                            </div>
                        </div>
                        <div className="count-data-inner">
                            <h3 className="count-header">Members with at least 1 peer touchpoint</h3>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{1041}</h2>
                            </div>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{444}</h2>
                            </div>
                        </div>
                        <div className="count-data-inner">
                            <h3 className="count-header">Total peer touchpoints (all members)</h3>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{3559}</h2>
                            </div>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{1665}</h2>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h2 className="app-usage-header">App Usage by Delawareans getting SUD treatment</h2>
                    <div className="count-data-wrapper">
                        <div className="count-data-inner"></div>
                        <div className="count-data-inner">
                            <h3>Since Inception</h3>
                        </div>
                        <div className="count-data-inner">
                            <h3>Q4 2021</h3>
                        </div>
                        <div className="count-data-inner">
                            <h3 className="count-header">Total messages sent in groups</h3>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{11446}</h2>
                                <span><strong>4801 from ACDE </strong></span>
                            </div>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{7124}</h2>
                                <span> <strong>2502 from ACDE</strong></span>
                            </div>
                        </div>
                        <div className="count-data-inner">
                            <h3 className="count-header">Number of App Accounts Created </h3>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{349}</h2>
                                <span><strong>93 from ACDE</strong></span>
                            </div>
                        </div>
                        <div className="count-data-inner">
                            <div className="main-count-wrapper">
                                <h2>{32}</h2>
                                <span ><strong>9 from ACDE</strong></span>
                            </div>
                        </div>
                    </div>

                    <br />
                    <br />
                    <h2 className="pie-chart-header">Individual Outreach Attempts and Modality</h2>
                    <div className="pie-chart-wrapper">
                        <div className="pie-chart-inner">
                            <span>Since Inception</span>
                            <SimplePieChart
                                data={dataForAllTime.sort((a, b) => b.outreachAttempts - a.outreachAttempts)}
                                dataKey="outreachAttempts"
                            />
                        </div>
                        <div className="pie-chart-inner">
                            <span>Q4 2021</span>
                            <SimplePieChart
                                data={dataForLastQuarter.sort((a, b) => b.outreachAttempts - a.outreachAttempts)}
                                dataKey="outreachAttempts"
                            />
                        </div>
                    </div>

                    <br />

                    <div className="bar-chart-wrapper">
                        <h3 className="bar-chart-span">Unique Members Engaging Marigold by Month</h3>
                        <SimpleBarChart
                            data={dataForMembersReached}
                            firstDataKey="numberOfMembersTouched"
                            firstDataKeyLabel="Total Number Of Unique Members Touched"
                        />
                    </div>

                    <br />

                    <div className="bar-chart-wrapper">
                        <h3 className="bar-chart-span">Marigold App Accounts Created by Month</h3>
                        <SimpleBarChart
                            data={dataForMembersSignup}
                            firstDataKey="newSignups"
                            firstDataKeyLabel="Total New Signups"
                            secondDataKey="acdeSignups"
                            secondDataKeyLabel="ACDE Signups"
                            isBothColumnRequired
                        />
                    </div>

                    <h3 className="group-summary-header">Summary of Group Activity</h3>

                    <h4 className="groups-list-header">Most Active Groups</h4>
                    {dataForMostActiveGroups && dataForMostActiveGroups.length ?
                        dataForMostActiveGroups.map(({ key, groupName, description, stats1, stats2, imageUrl }) => (
                            <div className="active-groups-wrapper" key={key}>
                                <div className="active-groups-inner">
                                    <h4 >{groupName}</h4>
                                    <p className="members-count">{stats1}</p>
                                    <p className="members-messages-count">{stats2}</p>
                                    <p className="group-description">{description}</p>
                                </div>
                                <div className="active-groups-inner">
                                    <img src={imageUrl ? imageUrl : IMG_GROUP_LOGO} alt="GroupImage" className="active-groups-image"></img>
                                </div>
                            </div>
                        )) : null}

                    <h4 className="groups-list-header">Groups available on the platform</h4>
                    <div className="groups-list-wrapper">
                        {dataForAvailableGroups && dataForAvailableGroups.length ? dataForAvailableGroups.map(({ key, groupName, groupDescription }) => (
                            <div className="groups-list-inner" key={key}>
                                <div className="group-information">
                                    <h2>{groupName}</h2>
                                    <span >{groupDescription}</span>
                                </div>
                            </div>
                        )) : null}
                    </div>

                    <h4 className="providers-header">Providers sending us referrals</h4>
                    <div className="providers-list-wrapper">
                        {dataForProviders && dataForProviders.length ? dataForProviders.map(({ key, color, provider }) => (
                            <div className="providers-list-inner" key={key}>
                                <div className="provider-information">
                                    <img src={color === GREEN_COLOR_CIRCLE ? IMG_GREEN_CIRCLE :
                                        color === RED_COLOR_CIRCLE ? IMG_RED_CIRCLE : IMG_YELLOW_CIRCLE} />
                                    <span >{provider}</span>
                                </div>
                            </div>
                        )) : null}
                    </div>
                </div>

            </>
        );
    }
};

export default Insights;