import SecureLS from "secure-ls";
import AuthStore from "../stores/AuthStore";

class SecureLocalStorage {
  ls;
  constructor() {
    this.ls = new SecureLS({ encodingType: 'aes' });
  }
  setMultipleKeys(keys) {
    if (keys) {
      Object.keys(keys).forEach((key) => {
        this.set(key, keys[key]);
      });
    }
  }
  set(key, value) {
    this.ls.set(key, { value });
  }
  get(key) {
    if (this.ls.getAllKeys().length && this.ls.get(key)) {
      return this.ls.get(key).value;
    }
  }
  remove(key) {
    this.ls.remove(key);
  }
  removeAll() {
    try {
      this.ls.removeAll();
    } catch { }
  }
  clear() {
    this.ls.clear();
  }
  getAllKeys() {
    this.ls.getAllKeys();
  }
}
export default new SecureLocalStorage();
