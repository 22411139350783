import ApiService from "./ApiService";
const endPoint = `slack-message`;

export const slackNotification = async (message) => {
  const params = {
    ...message,
  };
  const response = await ApiService.postRequest(endPoint, params);
  if (response.success) {
  } else {
  }
};
