import React from 'react';
import moment from 'moment';
import * as xlsx from 'xlsx';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import { Spin, Icon, Select, Input, Tooltip } from 'antd';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import '../../styles/global.scss';

import { TERRITORIES_TYPE_ALL_TERRITORIES, USER_STATUS_ALL_STATUS, USER_BADGE_TYPES_ARR, SENDER_PHONE_NUMBER_LIST, SELECT_SENDER_PHONE_NUMBER } from '../../constants/GlobalConstant';
import { SA } from '../../constants/UserRolesConstant';
import UserBadges from '../UI/UserBadges';
import { chat } from '../../en.json';
import { getAllTerritoriesBySites, getFormattedTime } from '../../utils/CommonUtils';
import { IMG_SAMPLE_BULK_SEND_SMS, IMG_SEND, IMG_UPLOAD } from '../../utils/ImageUtils';
import CustomImageModal from '../UI/CustomImageModal';

const { Option } = Select;

@withRouter
@inject("store")
@observer
class SmsInbox extends React.Component {
    constructor() {
        super();
        this.messagesEndRef = React.createRef();
        this.inputFileRef = React.createRef();
        this.state = {
            selectedUserDetails: null,
            messageText: '',
            searchText: '',
            isSampleModalVisible: false
        };
    }

    componentDidMount() {
        // We need to fetch users list on component mount
        this.fetchInboxUsersList();
    };

    componentWillUnmount() {
        const {
            store: { SmsInboxStore }
        } = this.props;
        // We need to reset sms inbox when component will unmount
        SmsInboxStore.reset();
    };

    fetchInboxUsersList = () => {
        const {
            store: {
                SmsInboxStore,
                SmsInboxStore: { allTerritories, setAllTerritories, selectedSenderPhoneNumber },
                SiteStore: { allSites }
            }
        } = this.props;
        // This will make an api call to fetch users list
        SmsInboxStore.getInboxUsersList();
        // On mounting, we will set the territory to "All Territory" in dropdown
        SmsInboxStore.setSelectedTerritoryForSmsInbox(TERRITORIES_TYPE_ALL_TERRITORIES);
        // On mounting, we will set the senderPhoneNumber value in dropdown
        SmsInboxStore.setSelectedSenderPhoneNumber(selectedSenderPhoneNumber || SELECT_SENDER_PHONE_NUMBER);
        // On mounting, we will set the user status to "All Status" in dropdown
        SmsInboxStore.setSelectedUserStatusForSmsInbox(USER_STATUS_ALL_STATUS);

        if (toJS(allSites) && !toJS(allTerritories).length) {
            const territoriesData = getAllTerritoriesBySites(toJS(allSites));
            // fetch territories by sites if admin comes to sms inbox 
            setAllTerritories(territoriesData);
        }
    };

    handleUsernameSelect = async (userDetails) => {
        try {
            const { store: {
                ProfileStore,
                SmsInboxStore,
                MessagesStore: {
                    isSmsSectionVisible,
                    setIsSmsSectionVisible,
                }
            },
            } = this.props;
            const { smsSenderPhoneNumber } = userDetails;
            const { selectedUserDetails } = this.state;
            if (selectedUserDetails && selectedUserDetails.smsSenderPhoneNumber === smsSenderPhoneNumber) {
                return;
            }
            // While opening the chat history for particular user, we will hide the user profile modal
            ProfileStore.setMemberProfileVisibility(false);
            if (isSmsSectionVisible) {
                // While opening the chat history for particular user, we will hide the sms section if it is open
                setIsSmsSectionVisible(false);
            }
            // We are updating selected user details in local state
            this.setState({
                ...this.state,
                selectedUserDetails: { ...userDetails }
            });

            // WARNING: because we are initiating a fetch and writing into a global singleton state, 
            // this can cause a race condition bug where the userDetails state doesn't match up with the sms 
            // history that is currently loaded

            // While selecting username in left section then we are fetching respective sms messages
            await SmsInboxStore.fetchUserSmsHistory({ userPhoneNumber: smsSenderPhoneNumber });
            // This function is used to move scrollbar to bottom when we get data
            setTimeout(() => this.scrollToBottom(), 1000);
        } catch (error) { }
    };

    handleChange = (e) => {
        const messageText = e.target.value;
        // Here we are setting the data in state if admin types in input box
        this.setState({
            ...this.state,
            messageText
        });
    };

    handleKeyPress = async (e) => {
        try {
            const {
                store: {
                    SmsInboxStore: {
                        sendUserSms,
                        getInboxUsersList,
                        resetUsersListData,
                        selectedSenderPhoneNumber,
                    }
                },
            } = this.props;
            const { messageText, selectedUserDetails } = this.state;
            // If admin press only enter then we need to initiate call for send sms api
            if (!e.shiftKey && e.key === "Enter") {
                e.preventDefault();
                if (messageText.trim() && selectedUserDetails) {
                    const { smsSenderId, smsSenderPhoneNumber } = selectedUserDetails;
                    const payload = { text: messageText };
                    if (smsSenderPhoneNumber) {
                        payload['receiverPhoneNumber'] = smsSenderPhoneNumber;
                    } else {
                        payload['receiverId'] = smsSenderId;
                    }
                    payload.senderPhoneNumber = selectedSenderPhoneNumber !== SELECT_SENDER_PHONE_NUMBER ?
                      `+1${selectedSenderPhoneNumber}` : SENDER_PHONE_NUMBER_LIST[0].split("-")[0];
                    // Here we are making API call to send message to user's number
                    await sendUserSms(payload);
                    // Empty the textbox value after sending message text in API call
                    this.setState({
                        ...this.state,
                        messageText: ''
                    });
                    // we will reset existing users data & its pagination related data
                    resetUsersListData();
                    // Call the inbox users list api to update users with latest message in it
                    getInboxUsersList();
                }
            }
        } catch (error) { }
    };

    handleChangeOnSearch = (event) => {
        const searchText = event.target.value;
        this.setState({
            ...this.state,
            searchText
        });
    };

    // This function is used to fetch users based on search value entered
    fetchSearchedUsersOnInbox = () => {
        const {
            store: {
                SmsInboxStore: {
                    setSearchString,
                    getInboxUsersList,
                    resetUsersListData
                }
            },
        } = this.props;
        const { searchText } = this.state;
        // we will reset existing users data & its pagination related data
        resetUsersListData();
        // Here we will set the search value to the mobx state
        setSearchString(searchText.trim());
        // After setting the state, we will make an api call to fetch the inbox users by search value
        getInboxUsersList();
    };

    // This function is used to clear search value entered in search box
    clearSearchString = () => {
        const {
            store: {
                SmsInboxStore: {
                    resetUsersListData,
                    setSearchString,
                    getInboxUsersList
                }
            },
        } = this.props;
        this.setState({
            ...this.state,
            searchText: ''
        });
        // we will reset existing users data & its pagination related data
        resetUsersListData();
        // Here we will set the search value to the mobx state
        setSearchString('');
        // After setting the state, we will make an api call to fetch the inbox users by search value
        getInboxUsersList();
    };

    appendData = async () => {
        try {
            const {
                store: {
                    SmsInboxStore: {
                        sendUserSms,
                        getInboxUsersList,
                        resetUsersListData,
                        selectedSenderPhoneNumber,
                    }
                },
            } = this.props;
            const { messageText, selectedUserDetails } = this.state;
            // Here we are checking if message text contains any value or not
            if (messageText.trim() && selectedUserDetails) {
                const { smsSenderId, smsSenderPhoneNumber } = selectedUserDetails;
                const payload = { text: messageText };
                if (smsSenderPhoneNumber) {
                    payload['receiverPhoneNumber'] = smsSenderPhoneNumber;
                } else {
                    payload['receiverId'] = smsSenderId;
                }
                payload.senderPhoneNumber = selectedSenderPhoneNumber !== SELECT_SENDER_PHONE_NUMBER ?
                  `+1${selectedSenderPhoneNumber}` : SENDER_PHONE_NUMBER_LIST[0].split("-")[0];
                // If there is any message text available then we are sending message to the user's number in API call
                await sendUserSms(payload);
                // Empty the textbox value after sending message text in API call
                this.setState({
                    ...this.state,
                    messageText: ''
                });
                // we will reset existing users data & its pagination related data
                resetUsersListData();
                // Call the inbox users list api to update users with latest message in it
                getInboxUsersList();
                // Here we are setting timeout for scrolling to bottom after fetching the data from API call
                setTimeout(() => this.scrollToBottom(), 1000);
            }
        } catch (error) { }
    };

    scrollToBottom = () => {
        if (this.messagesEndRef.current) {
            this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    reloadSmsHistory = async () => {
        try {
            const {
                store: {
                    SmsInboxStore,
                },
            } = this.props;
            const { selectedUserDetails } = this.state;
            // Here we are fetching user sms messages history when user clicks on reload icon
            if (selectedUserDetails) {
                await SmsInboxStore.fetchUserSmsHistory({ userPhoneNumber: selectedUserDetails.smsSenderPhoneNumber });
                setTimeout(() => this.scrollToBottom(), 1000);
            }
        } catch (error) { }
    };

    reloadUsersList = () => {
        const {
            store: {
                SmsInboxStore
            },
        } = this.props;
        // Reset users list data
        SmsInboxStore.resetUsersListData();
        // Reset filter active selection
        SmsInboxStore.setIsRepliedFilterSelected(false);
        // Reset All Non Accounts filter active selection
        SmsInboxStore.setIsAllNonAccountsFilterSelected(false);
        // Then we need to reset territory to "All Territories"
        SmsInboxStore.setSelectedTerritoryForSmsInbox(TERRITORIES_TYPE_ALL_TERRITORIES);
        // Then we need to reset user status to "All Status"
        SmsInboxStore.setSelectedUserStatusForSmsInbox(USER_STATUS_ALL_STATUS);
        // Then we need to reset senderPhoneNumber
        // Here we are fetching user list when user clicks on reload icon
        this.fetchInboxUsersList();
    };

    loadMoreUsersInUsersList = () => {
        const {
            store: {
                SmsInboxStore,
                SmsInboxStore: { isUsersDataOnListLoading }
            },
        } = this.props;
        if (isUsersDataOnListLoading) return;
        SmsInboxStore.getInboxUsersList(false);
    };

    loadMore = async () => {
        const {
            store: {
                SmsInboxStore: { isSmsHistoryLoading },
                ProfileStore: { hasMore },
            },
        } = this.props;
        let prevScrollHeight = 1;
        if (!isSmsHistoryLoading && hasMore) {
            if (this.messagesEndRef.current && this.messagesEndRef.current.scrollHeight) {
                prevScrollHeight = this.messagesEndRef.current.scrollHeight;
            }
        }
    };

    // This function is used to fetch user details of that particular user
    handleSenderUsernameClick = async (sender) => {
        try {
            const {
                store: {
                    ProfileStore: {
                        setMemberProfileVisibility,
                        setMemberData,
                        setAssignedPeerDetails,
                        toggleLabel,
                        reset,
                        userId,
                        changeSelectedAction,
                        setIsResetNeededForUserSmsData
                    },
                    DeletedMessagesStore: { setShowDeleted },
                    MemberListStore: { getMemberDetails, fetchAdminUsers },
                    MessagesStore: { setIsSmsSectionVisible },
                },
            } = this.props;
            if (sender === userId) {
                return;
            }
            setIsResetNeededForUserSmsData(false);
            reset();
            setIsSmsSectionVisible(false);
            setShowDeleted(false);
            setMemberProfileVisibility(true);
            setAssignedPeerDetails({});
            fetchAdminUsers();
            const member = await getMemberDetails(sender);
            if (member) {
                setMemberData(member);
            }
            toggleLabel(false);
            changeSelectedAction("");
        } catch (error) { }
    };

    // This function is used to create territory change from dropdown
    handleTerritoryChange = (value) => {
        const {
            store: {
                SmsInboxStore: {
                    isAllNonAccountsFilterSelected,
                    resetUsersListData,
                    setSelectedTerritoryForSmsInbox,
                    setIsAllNonAccountsFilterSelected,
                    getInboxUsersList
                },
            }
        } = this.props;
        // We will reset existing users data & its pagination data when territory change in the dropdown
        resetUsersListData();
        // If "All Non Accounts" is selected and if we will select territory then we need to remove selection from All Non Accounts
        if (isAllNonAccountsFilterSelected) {
            setIsAllNonAccountsFilterSelected(false);
        }
        // We will set territory in mobx state
        setSelectedTerritoryForSmsInbox(value);
        // After setting the territory in mobx state, we will fetch inbox users list
        getInboxUsersList();
    };
    // This function is used to create territory change from dropdown
    handlePhoneNumberChange = (value) => {
        const {
            store: {
                SmsInboxStore: {
                    isAllNonAccountsFilterSelected,
                    resetUsersListData,
                    setIsAllNonAccountsFilterSelected,
                    setSelectedSenderPhoneNumber,
                    getInboxUsersList,
                    setDefaultPhoneNumber
                },
            }
        } = this.props;
        // We    reset existing users data & its pagination data when territory change in the dropdown
        resetUsersListData();
        // If "All Non Accounts" is selected and if we will select territory then we need to remove selection from All Non Accounts
        if (isAllNonAccountsFilterSelected) {
            setIsAllNonAccountsFilterSelected(false);
        }
        // We will set territory in mobx state
        let newValue = value.slice(2);
        let number = newValue.split("-");
        setDefaultPhoneNumber(number[1])
        setSelectedSenderPhoneNumber(number[0]);
        // After setting the territory in mobx state, we will fetch inbox users list
        getInboxUsersList();
    };

    // This function is used to create user status change from dropdown 
    handleUserStatus = (value) => {
        const {
            store: {
                SmsInboxStore: {
                    isAllNonAccountsFilterSelected,
                    resetUsersListData,
                    setSelectedUserStatusForSmsInbox,
                    setIsAllNonAccountsFilterSelected,
                    getInboxUsersList
                },
            }
        } = this.props;
        // We will reset existing users data & its pagination data when user status change in the dropdown
        resetUsersListData();
        if (isAllNonAccountsFilterSelected) {
            setIsAllNonAccountsFilterSelected(false);
        }
        // We will set user status in mobx state
        setSelectedUserStatusForSmsInbox(value);
        // After setting the user status in mobx state, we will fetch inbox users list
        getInboxUsersList();
    };

    renderLoadingSpinner = () => {
        // Create reusable function for loading spinner
        return (<div className='loading-spinner'>
            <Spin />
        </div>);
    };

    renderSmsBubble = (message) => {
        const { selectedUserDetails } = this.state;
        const { smsSenderPhoneNumber, usersData = [] } = selectedUserDetails;
        const { body, direction, from, senderUserDetails, dateCreated } = message;
        const messageSendDate = moment(dateCreated).format('LT');
        const isDirectionInbound = direction === 'inbound';
        // Here by default we are taking "🤖 MarigoldBot" as a sender username
        // If sender user details are present in api call then we are taking username from that response
        let senderUsername = `${senderUserDetails?.username ?? `🤖 MarigoldBot`} (${from})`;
        if (isDirectionInbound) {
            const smsSenderUsername = usersData && usersData.length ? usersData[0].username : '';
            // If direction is inbound then we need to set receiver's username or user's username with his phone number
            senderUsername = `${smsSenderUsername ? smsSenderUsername : ''} (${smsSenderPhoneNumber})`;
        }

        return (<>
            <li className={isDirectionInbound ? "left-side" : "clearfix"}>
                <div className={`message-data ${isDirectionInbound ? '' : 'align-right'}`}>
                    {isDirectionInbound ? (
                        <>
                            <span className="message-data-name">{senderUsername}</span>
                            <span className="message-data-time">{messageSendDate}</span>
                        </>)
                        : (<>
                            <span className="message-data-time" >{messageSendDate}</span> &nbsp; &nbsp;
                            <span className="message-data-name" >{senderUsername}</span>
                        </>)}

                </div>
                <div className={`message ${isDirectionInbound ? 'my-message' : 'other-message float-right'}`}>
                    {body.trim()}
                </div>
            </li>
        </>)

    };

    // This function is used to render user names list on left section/sidebar
    renderUserNamesList = (userInfo = {}) => {
        const { smsSenderPhoneNumber, usersData = [] } = userInfo;
        const usersDataWithNotNullValues = [...new Set(Object.values(...usersData))];
        if (usersDataWithNotNullValues.length === 1 && !usersDataWithNotNullValues[0] && smsSenderPhoneNumber) {
            return (<span>+1{smsSenderPhoneNumber}</span>);
        }
        if (usersDataWithNotNullValues.length) {
            return <div className="name">
                {usersData.map(({ username, badgeType }, index) => (
                    <React.Fragment key={index}>
                        {/* warning:  we are using the index as a key, which will cause bugs when items in this list change */}
                        <span>
                            {username}
                            {badgeType ? <UserBadges className="chat-user-badge" badgeType={badgeType} /> : null}
                            {index === usersData.length - 1 ? '' : ', '}
                        </span>
                    </React.Fragment>
                ))}
            </div>
        }
        return null;
    };

    // This function is used to render all sections list by date
    renderUsersSectionList = (usersSectionListData = []) => {
        const {
            store: {
                SmsInboxStore: {
                    skipUsersInListing,
                    totalUsersInListing,
                    isUsersDataOnListLoading
                }
            },

        } = this.props;
        const { selectedUserDetails } = this.state;
        return (<div id="inbox-users-list-container">
            <InfiniteScroll
                loadMore={this.loadMoreUsersInUsersList}
                useWindow={false}
                hasMore={skipUsersInListing < totalUsersInListing}
            >
                {usersSectionListData.map(({ title, data: usersData }, index) => (
                    <React.Fragment key={index}>
                        {/* warning:  we are using the index as a key, which will cause bugs when items in this list change */}
                        {usersData && usersData.length ? (
                            <div className="users-list-date-section">
                                <span className="date-title">{title}</span>
                                <ul>
                                    {usersData.map((user, userIndex) => {
                                        return (<li key={userIndex}
                                            className={selectedUserDetails && user.smsSenderPhoneNumber === selectedUserDetails.smsSenderPhoneNumber ? "selected-user-wrapper" : ''}
                                            onClick={() => this.handleUsernameSelect(user)}
                                        >
                                            {/* warning:  ^^^ we are using the index as a key, which will cause bugs when items in this list change */}
                                            <div className="about">
                                                {this.renderUserNamesList(user)}
                                                <div className="last-message">{moment(user.createdAt).format('ddd, hh:mm A')} : {user.text}</div>
                                            </div>
                                        </li>)

                                    }
                                    )}
                                </ul>
                            </div>
                        )
                            : null}
                    </React.Fragment>
                ))}
            </InfiniteScroll>
            {isUsersDataOnListLoading ? this.renderLoadingSpinner() : null}
        </div>);
    };

    // renders multiple usernames if any on chat header
    renderSenderUsername = (selectedUserDetails = {}) => {
        const { smsSenderPhoneNumber, usersData = [] } = selectedUserDetails;
        const usersDataWithNotNullValues = [...new Set(Object.values(...usersData))];
        if (usersDataWithNotNullValues.length === 1 && !usersDataWithNotNullValues[0] && smsSenderPhoneNumber) {
            return (<Tooltip title="No account found for that number" placement="right">
                <span className="sender-username">+1{smsSenderPhoneNumber}</span>
            </Tooltip>);
        }
        if (usersData && usersData.length) {
            return usersData.map(({ id, username, badgeType }, index) => (
                <span key={index}>
                    {/* warning:  we are using the index as a key, which will cause bugs when items in this list change */}
                    <span className="sender-username" onClick={() => this.handleSenderUsernameClick(id)}>{username}</span>
                    {badgeType ? <UserBadges badgeType={badgeType} /> : null}{index === usersData.length - 1 ? '' : ', '}
                </span>
            ));
        }
        return null;
    };

    // fetches all data by resetting every filter that is applied
    handleAllUsersClick = () => {
        const {
            store: {
                SmsInboxStore: {
                    setSelectedTerritoryForSmsInbox,
                    setSelectedUserStatusForSmsInbox,
                    setIsRepliedFilterSelected,
                    setIsAllNonAccountsFilterSelected,
                    setIsAllAccountsFilterSelected,
                    setIsMyCaseloadSelected,
                    setSearchString,
                    getInboxUsersList,
                    resetUsersListData
                },
            }
        } = this.props;
        // If "Everyone" is already selected then we don't need to fetch users list again
        if (this.checkValidationForEveryoneButton()) {
            return;
        }
        // If any filter is applied and then admin clicks on "Everyone" then we need to perform some operations
        // First we need to reset local search state
        this.setState({
            ...this.state,
            searchText: '',
        });
        // We will reset existing users data & its pagination related data when admin clicks on "Everyone" button
        resetUsersListData();
        // Then we need to reset search in mobx state
        setSearchString('');
        // We need to reset isMyCaseloadFilter to false
        setIsMyCaseloadSelected(false);
        // We need to reset isRepliedFilter to false
        setIsRepliedFilterSelected(false);
        // We need to reset isAllNonAccountsFilter to false
        setIsAllNonAccountsFilterSelected(false);
        // We need to reset isAllAccountsFilter to false
        setIsAllAccountsFilterSelected(false);
        // Then we need to reset territory to "All Territories"
        setSelectedTerritoryForSmsInbox(TERRITORIES_TYPE_ALL_TERRITORIES);
        // Then we need to reset user status to "All Status"
        setSelectedUserStatusForSmsInbox(USER_STATUS_ALL_STATUS);
        // After setting the state, we will make an api call to fetch the inbox users list
        getInboxUsersList();
    };

    // This function is used to check validation whenever filter changes 
    // then according to this we will apply selected border on "Everyone" button
    checkValidationForEveryoneButton = () => {
        const {
            store: {
                SmsInboxStore: {
                    selectedTerritoryForSmsInbox,
                    selectedUserStatusForSmsInbox,
                    searchString,
                    isRepliedFilterSelected,
                    isAllNonAccountsFilterSelected,
                    isAllAccountsFilterSelected,
                    isMyCaseloadSelected
                },
            }
        } = this.props;
        let isAllUsersSelected = true;

        // If any territory is selected then we will return false
        // If any user status is selected then we will return false
        // If any string is available in search state of mobx then we will return false
        // If Replied filter is selected then we will return false
        // If All Non Accounts filter is selected then we will return false
        const isConditionValidate = selectedTerritoryForSmsInbox !== TERRITORIES_TYPE_ALL_TERRITORIES ||
            selectedUserStatusForSmsInbox !== USER_STATUS_ALL_STATUS ||
            searchString && searchString.trim() ||
            isRepliedFilterSelected ||
            isAllNonAccountsFilterSelected ||
            isAllAccountsFilterSelected ||
            isMyCaseloadSelected;

        if (isConditionValidate) {
            isAllUsersSelected = false;
        }

        return isAllUsersSelected;
    };

    handleRepliedActionClick = () => {
        const {
            store: {
                SmsInboxStore: {
                    getInboxUsersList,
                    resetUsersListData,
                    setIsRepliedFilterSelected,
                    isRepliedFilterSelected
                },
            }
        } = this.props;
        // We will reset existing users data & its pagination related data when admin clicks on "Replied" button
        resetUsersListData();
        // We will toggle value of "isRepliedFilterSelected" and set it in mobx state
        setIsRepliedFilterSelected(!isRepliedFilterSelected);
        // After setting the state, we will make an api call to fetch the inbox users list
        getInboxUsersList();
    };

    handleAllNonAccountsClick = () => {
        const {
            store: {
                SmsInboxStore: {
                    getInboxUsersList,
                    resetUsersListData,
                    isAllNonAccountsFilterSelected,
                    selectedTerritoryForSmsInbox,
                    selectedUserStatusForSmsInbox,
                    setIsAllAccountsFilterSelected,
                    setIsAllNonAccountsFilterSelected,
                    setSelectedTerritoryForSmsInbox,
                    setSelectedUserStatusForSmsInbox,
                    setIsMyCaseloadSelected
                },
            }
        } = this.props;
        // We will reset existing users data & its pagination related data when admin clicks on "All Non Accounts" button
        resetUsersListData();
        // We will change the value of "isAllAccountsFilterSelected" to false and set it in mobx state
        setIsAllAccountsFilterSelected(false);
        // We will change the value of "isMyCaseloadSelected" to false and set it in mobx state
        setIsMyCaseloadSelected(false);
        // We will toggle value of "isAllNonAccountsFilterSelected" and set it in mobx state
        setIsAllNonAccountsFilterSelected(!isAllNonAccountsFilterSelected);
        if (selectedTerritoryForSmsInbox !== TERRITORIES_TYPE_ALL_TERRITORIES) {
            // We need to reset territory to "All Territories"
            setSelectedTerritoryForSmsInbox(TERRITORIES_TYPE_ALL_TERRITORIES);
        }
        if (selectedUserStatusForSmsInbox !== USER_STATUS_ALL_STATUS) {
            // We need to reset user status to "All Status"
            setSelectedUserStatusForSmsInbox(USER_STATUS_ALL_STATUS);
        }
        // After setting the state, we will make an api call to fetch the inbox users list
        getInboxUsersList();
    };

    handleAllAccountsClick = () => {
        const {
            store: {
                SmsInboxStore: {
                    getInboxUsersList,
                    resetUsersListData,
                    isAllAccountsFilterSelected,
                    setIsAllNonAccountsFilterSelected,
                    setIsAllAccountsFilterSelected,
                    setIsMyCaseloadSelected
                },
            }
        } = this.props;
        // We will reset existing users data & its pagination related data when admin clicks on "All Accounts" button
        resetUsersListData();
        // We will change the value of "isAllNonAccountsFilterSelected" to false and set it in mobx state
        setIsAllNonAccountsFilterSelected(false);
        // We will change the value of "isMyCaseloadSelected" to false and set it in mobx state
        setIsMyCaseloadSelected(false);
        // We will toggle the value of "isAllAccountsFilterSelected" and set it in mobx state
        setIsAllAccountsFilterSelected(!isAllAccountsFilterSelected);
        // After setting the state, we will make an api call to fetch the inbox users list
        getInboxUsersList();
    };

    handleMyCaseloadClick = () => {
        const {
            store: {
                SmsInboxStore: {
                    getInboxUsersList,
                    resetUsersListData,
                    isMyCaseloadSelected,
                    setIsAllNonAccountsFilterSelected,
                    setIsAllAccountsFilterSelected,
                    setIsMyCaseloadSelected
                },
            }
        } = this.props;
        // We will reset existing users data & its pagination related data when admin clicks on "My Caseload" button
        resetUsersListData();
        // We will change the value of "isAllNonAccountsFilterSelected" to false and set it in mobx state
        setIsAllNonAccountsFilterSelected(false);
        // We will chaneg the value of "isAllAccountsFilterSelected" to false and set it in mobx state
        setIsAllAccountsFilterSelected(false);
        // We will toggle the value of "isMyCaseloadSelected" and set it in mobx state
        setIsMyCaseloadSelected(!isMyCaseloadSelected);
        // After setting the state, we will make an api call to fetch the inbox users list
        getInboxUsersList();
    };

    onReaderLoad = (targetFile) => {
        return (event) => {
            const {
                store: {
                    NotificationStore,
                    SmsInboxStore: { sendBulkSms, selectedSenderPhoneNumber }
                }
            } = this.props;
            try {
                const data = event.target.result;
                // Read excel file 
                const workbook = xlsx.read(data, { type: 'array' });
                /* Get first worksheet */
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                /* Convert array of arrays */
                const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

                const finalData = JSON.parse(JSON.stringify(jsonData))
                const finalDataLength = finalData.length - 1;
                if (finalDataLength < 1) {
                    NotificationStore.setNotification('error', 'Please check if the excel file is empty or has a invalid format.');
                    return;
                }

                const message = `You are trying to send ${finalDataLength} messages. This will go out over ${finalDataLength} mins or ${getFormattedTime(finalDataLength)} hours from now.
                Are you sure you want to continue ?`
                let senderPhoneNumber = selectedSenderPhoneNumber != SELECT_SENDER_PHONE_NUMBER ?
                    `+1${selectedSenderPhoneNumber}` : SENDER_PHONE_NUMBER_LIST[0].split("-")[0]
                NotificationStore.setConfirm(() => {
                    // If xlsx or xls file is uploaded then we are preparing form data for API call
                    const formData = new FormData();
                    formData.append('file', targetFile);
                    formData.append('senderPhoneNumber', senderPhoneNumber);
                    // Here we are passing form data to the API call
                    sendBulkSms(formData);
                }, message);
            } catch (error) {
                this.inputFileRef.current.value = '';
                NotificationStore.setNotification('error', 'Please upload excel file in proper format.');
            }
        }
    };

    handleFileChange = (event) => {
        const {
            store: {
                NotificationStore,

            }
        } = this.props;
        const targetFile = event.target.files[0];
        if (targetFile) {
            const { name } = targetFile;
            const fileName = name.toLowerCase();
            // Apart from xlsx and xls file, if are trying to upload other files then we need to show error
            if (!(fileName.endsWith('xlsx') || fileName.endsWith('xls'))) {
                NotificationStore.setNotification("error", 'Please upload file types of .xls, .xlsx only.');
                return;
            }
            const reader = new FileReader();
            reader.onload = this.onReaderLoad(targetFile);
            reader.readAsArrayBuffer(targetFile);

            // After API call we will reset the value of file input
            event.target.value = null;
        }
    };

    handleBulkSMSClick = () => {
        const {
            store: {
                SmsInboxStore: { isBulkSmsSending }
            }
        } = this.props;
        // If API call is in progress then we will not do any further action
        if (isBulkSmsSending) return;
        // We will click to select files from local storage 
        this.inputFileRef.current.click();
    };

    handleSampleFormatClick = () => {
        this.toggleSampleFormatModal(true);
    };

    toggleSampleFormatModal = (value) => {
        this.setState({
            ...this.state,
            isSampleModalVisible: value
        });
    };

    render() {
        const {
            store: {
                SmsInboxStore: {
                    usersListData,
                    userSmsData,
                    isSmsHistoryLoading,
                    isSmsSending,
                    isBulkSmsSending,
                    isUsersListLoading,
                    inboxUsersListUpdatedTime,
                    smsHistoryUpdatedTime,
                    selectedTerritoryForSmsInbox,
                    selectedUserStatusForSmsInbox,
                    selectedSenderPhoneNumber,
                    allTerritories,
                    isRepliedFilterSelected,
                    isAllNonAccountsFilterSelected,
                    isAllAccountsFilterSelected,
                    isMyCaseloadSelected,
                    defaultPhoneNumber
                },
                AuthStore: { type }
            },
        } = this.props;
        const { selectedUserDetails, searchText, messageText, isSampleModalVisible } = this.state;

        let prevDate = "";
        const isSuperAdmin = type === SA;

        return (
            <React.Fragment>
                <CustomImageModal
                    visible={isSampleModalVisible}
                    title="Sample Format"
                    onOk={() => this.toggleSampleFormatModal(false)}
                    onCancel={() => this.toggleSampleFormatModal(false)}
                    imageLink={IMG_SAMPLE_BULK_SEND_SMS}
                />
                <div className="sms-inbox-container">
                    {/* Container for users list */}
                    <div className="people-list" id="people-list">
                        {/* Search box wrapper */}
                        <div className="search">
                            <Input
                                prefix={
                                    <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
                                }
                                suffix={
                                    searchText.trim() ?
                                        <Icon
                                            type="close"
                                            style={{ color: "rgba(0,0,0,.45)" }}
                                            onClick={() => this.clearSearchString()}
                                        /> : null
                                }
                                value={searchText}
                                onChange={this.handleChangeOnSearch}
                                placeholder="Search by phone number or username..."
                                className='inbox-input'
                                onPressEnter={this.fetchSearchedUsersOnInbox}
                            />
                        </div>

                        <input type='file'
                            ref={this.inputFileRef}
                            onChange={this.handleFileChange}
                            style={{ display: 'none' }}
                        />
                        {/* Button to upload file to send bulk SMS to users */}
                        {isSuperAdmin ? (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <span className="bulk-send-sms-label main"
                                    onClick={this.handleBulkSMSClick}
                                >
                                    {isBulkSmsSending ? (<Spin />) : (
                                        <>
                                            <img src={IMG_UPLOAD} style={{ width: 20, height: 20, marginRight: 10 }} />
                                            Bulk Send SMS
                                        </>
                                    )}
                                </span>
                                <span className="bulk-send-sms-label inner"
                                    onClick={this.handleSampleFormatClick}
                                >
                                    Sample Format
                                </span>
                            </div>
                        ) : null}

                        {/* Wrapper for first row of filters */}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {/* Option to select Everyone */}
                            <span
                                className={`everyone-label ${this.checkValidationForEveryoneButton() ? 'selected' : ''}`}
                                onClick={this.handleAllUsersClick}>
                                Everyone
                            </span>

                            {/* Dropdown to select territory */}
                            <Select
                                className={TERRITORIES_TYPE_ALL_TERRITORIES === selectedTerritoryForSmsInbox ? '' : 'selected-territory-wrapper'}
                                value={selectedTerritoryForSmsInbox}
                                style={{ width: 150, marginLeft: 10 }}
                                onChange={this.handleTerritoryChange}
                            >
                                <Option value={TERRITORIES_TYPE_ALL_TERRITORIES} disabled={TERRITORIES_TYPE_ALL_TERRITORIES === selectedTerritoryForSmsInbox}>
                                    All Territories
                                </Option>
                                {allTerritories.map((territory, index) => (
                                    <Option key={index} value={territory} disabled={territory === selectedTerritoryForSmsInbox}>
                                        {territory}
                                    </Option>
                                ))}
                            </Select>

                            {/* Dropdown to select status */}
                            <Select
                                className={USER_STATUS_ALL_STATUS === selectedUserStatusForSmsInbox ? '' : 'selected-user-status-wrapper'}
                                value={selectedUserStatusForSmsInbox}
                                style={{ width: 150, marginLeft: 10 }}
                                onChange={this.handleUserStatus}
                            >
                                <Option value={USER_STATUS_ALL_STATUS} disabled={USER_STATUS_ALL_STATUS === selectedUserStatusForSmsInbox}>
                                    All Status
                                </Option>
                                {USER_BADGE_TYPES_ARR.map(({ label, value }, index) => (
                                    <Option key={index} value={value} disabled={value === selectedUserStatusForSmsInbox}>
                                        {label}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        {/* Wrapper for second row of filters */}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                        }}>
                            {/* Option to select Replied or not filter */}
                            <span
                                className={`filter-label ${isRepliedFilterSelected ? 'selected' : ''}`}
                                onClick={this.handleRepliedActionClick}>
                                Not Replied Yet
                            </span>

                            {/* Option to select All Non Accounts filters */}
                            <span
                                className={`filter-label ${isAllNonAccountsFilterSelected ? 'selected' : ''}`}
                                onClick={this.handleAllNonAccountsClick}>
                                All Non Accounts
                            </span>

                            {/* Option to select All Accounts filters */}
                            <span
                                className={`filter-label ${isAllAccountsFilterSelected ? 'selected' : ''}`}
                                onClick={this.handleAllAccountsClick}>
                                All Accounts
                            </span>

                            {/* Option to select My Caseload filters */}
                            <span
                                className={`filter-label ${isMyCaseloadSelected ? 'selected' : ''}`}
                                onClick={this.handleMyCaseloadClick}>
                                My Caseload
                            </span>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            {/* Dropdown to select Sender Phone Number */}
                            <Select
                                className='selected-user-status-wrapper'
                                value={defaultPhoneNumber}
                                style={{ width: 250, marginLeft: 10, }}
                                onChange={this.handlePhoneNumberChange}
                            >
                                {SENDER_PHONE_NUMBER_LIST.map((number, index) => (
                                    <Option key={index} value={number}>
                                        {number.split('-')[1]}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        {inboxUsersListUpdatedTime ?
                            (<div className="inbox-update-time">
                                Last updated at {moment(inboxUsersListUpdatedTime).format('LTS')}

                                <Icon
                                    type="reload"
                                    className="reload-icon"
                                    onClick={() => this.reloadUsersList()}
                                    spin={isUsersListLoading}
                                />
                            </div>)
                            : null}
                        <div className="list" style={{
                            height: isSuperAdmin ? 'calc(100% - 285px)' : 'calc(100% - 220px)'
                        }}>
                            {isUsersListLoading ? this.renderLoadingSpinner()
                                : (
                                    usersListData && usersListData.length ? this.renderUsersSectionList(usersListData)
                                        : (<h5>No Users Found!</h5>)
                                )
                            }
                        </div>
                    </div>

                    <div className="sms-inbox-chat">
                        {selectedUserDetails ? (
                            <React.Fragment>
                                <div className="sms-inbox-header clearfix">
                                    <div className="chat-about">
                                        <div className="chat-with">Chat with {this.renderSenderUsername(selectedUserDetails)}</div>
                                        {/*  note: from bug reports, we can tell that the above line sometimes renders an incorrect username*/}
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {isSmsHistoryLoading ?
                                                (<div className="chat-num-messages">Fetching user messages count...</div>)
                                                :
                                                toJS(userSmsData).length ?
                                                    <div className="chat-num-messages">({toJS(userSmsData).length} messages sent and received )</div>
                                                    : <div className="chat-num-messages">has no messages</div>
                                            }
                                            {smsHistoryUpdatedTime ?
                                                (<div style={{ marginLeft: 20 }}>
                                                    Last updated at {moment(smsHistoryUpdatedTime).format('LTS')}

                                                    <Icon
                                                        type="reload"
                                                        className="reload-icon"
                                                        onClick={() => this.reloadSmsHistory()}
                                                        spin={isSmsHistoryLoading}
                                                    />
                                                </div>)
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end chat-header --> */}

                                <div className="sms-inbox-history">
                                    {isSmsHistoryLoading ?
                                        this.renderLoadingSpinner() :
                                        (<>
                                            <InfiniteScroll
                                                loadMore={this.loadMore}
                                                // hasMore={hasMore}
                                                isReverse
                                                useWindow={false}
                                            >
                                                <ul>
                                                    {_.uniqBy(userSmsData).map((message, index) => {
                                                        // WARNING!! SmsInbox component renders on each keypress, which means all of these items might end 
                                                        // up getting rerendered needlessly.  if the list is quite long, then this could cause frame drops.
                                                        // todo: when the time is right, refactor this code into a subcomponent which won't rerender unless 
                                                        // props change.
                                                        const currDate = moment(message.dateCreated).format('LL');
                                                        const messageBox = (
                                                            <React.Fragment key={index}>
                                                                {/* warning:  we are using the index as a key, which will cause bugs when items in this list change */}
                                                                {currDate !== prevDate &&
                                                                    (
                                                                        <div className="message-date">
                                                                            <span>{currDate}</span>
                                                                        </div>
                                                                    )}

                                                                {this.renderSmsBubble(message)}
                                                            </React.Fragment>
                                                        );

                                                        prevDate = currDate;
                                                        return messageBox;
                                                    })}
                                                    {!userSmsData.length ? (
                                                        <div className="no-messages">{chat.noMessages}</div>
                                                    ) : null}
                                                </ul>
                                            </InfiniteScroll>
                                            <div ref={this.messagesEndRef} />
                                        </>)
                                    }
                                </div>
                                {/* <!-- end chat-history --> */}

                                <div className="sms-inbox-message clearfix">
                                    <textarea
                                        value={messageText}
                                        onChange={this.handleChange}
                                        onKeyDown={this.handleKeyPress}
                                        name="message-to-send"
                                        id="message-to-send"
                                        placeholder="Type your message..."
                                        rows="3" />

                                    {isSmsSending ? <Spin className='send-text-img ml-auto' /> :
                                        <img
                                            src={IMG_SEND}
                                            onClick={this.appendData}
                                            value="Append"
                                            className="send-text-img ml-auto"
                                            style={
                                                messageText.trim() ? { opacity: 1 } : { opacity: 0.4 }
                                            }
                                            alt="Send"
                                        />
                                    }

                                </div>
                                {/* <!-- end chat-message --> */}
                            </React.Fragment>
                        ) :
                            (<h3 className="sms-inbox-no-data">
                                Please select any user to see the conversations
                            </h3>)}

                    </div>
                    {/* <!-- end chat --> */}

                </div >
            </React.Fragment>
        );
    }
};

export default SmsInbox;
