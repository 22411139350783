import React from "react";
import { Spin } from "antd";
import close from "../../static/images/close.png";

export const ErrorModal = (props) => {
  const { onClose } = props;
  return (
    <div>
      <div>
        <div className="confirm-backdrop" onClick={onClose} />
        <div className="confirm-modal">
          <h1>
            A referral for this member is already in progress.
            <img src={close} onClick={onClose} alt="Close" />
          </h1>
          <p>
            If this is a mistake, please contact Nicole, CBRS Intake
            Coordinator
          </p>
          <div className="error-modal-links">
            <div>
              e-mail: <span className="value">nlfinch@hptc.org</span>
            </div>
            <div>
              fax: <span className="value">774-213-8534</span> or call:
              <span className="value">774-213-8448</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
