export const getFlagTypes = () => {
    return [
        { label: 'CoC Violation', value: 1 },
        { label: 'DV/SI/HI', value: 2 },
        { label: 'Mental health distress', value: 3 },
        { label: 'SUD distress', value: 4 },
        { label: 'Medical/dental need', value: 5 },
        { label: 'SDOH', value: 6 },
        { label: 'Team Review', value: 7 }
    ]
}