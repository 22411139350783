import React from "react";
import SVG from "react-inlinesvg";
import { Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { Spin, Progress, Tooltip } from "antd";

// All components
import Alert from "../components/protected/Alert";
import Chat from "../components/protected/Chat";
import DmSection from "../components/protected/DmSection";
import WaitingSection from "../components/protected/WaitingSection";
import GroupDetails from "../components/protected/GroupDetails";
import SessionDetails from "../components/protected/SessionDetails";
import GroupList from "../components/protected/GroupList";
import Layout from "../components/Layout";
import Member from "../components/protected/Member";
import Stats from "../components/protected/Stats";
import Insights from "../components/protected/Insights";
import SmsInbox from "../components/protected/SmsInbox";
import Waiting from "../components/protected/Waiting";
import ReferrerView from "../components/protected/ReferrerView";
import IntakeView from "../components/protected/IntakeView";
import EventList from "../components/protected/EventList";
import NewEvent from "../components/protected/NewEvent";
import NewIntakeForm from "../components/protected/NewIntakeForm";
import SelfIntakeForm from "../components/protected/SelfIntakeForm";
import ReferrerForm from "../components/protected/ReferrerForm";
import ReferrerInvite from "../components/protected/ReferrerInvite";
import Invite from "../components/protected/Invite";
import MixpanelAnalysis from "../components/protected/MixpanelAnalysis";
import Profile from "../components/protected/Profile";
import AllForms from "../components/protected/AllForms";
import NewGroup from "../components/protected/NewGroup";
import UserForms from "../components/protected/UserForms";
import FinishedForm from "../components/protected/FinishedForm";
import UserFlagsContainer from "../components/protected/UserFlagsContainer";
import GroupFlagsContainer from "../components/protected/GroupFlagsContainer";
import UserDeletedContainer from "../components/protected/UserDeletedContainer";
import GroupDeletedContainer from "../components/protected/GroupDeletedContainer";
import { withAuth } from "../components/Authentication";
import ViewInContext from "../components/protected/ViewInContext";
import AssignToContainer from "../components/protected/AssignToContainer";
import { CustomModal } from "../components/UI/CustomModal";
import FlagNeeds from "../components/protected/FlagNeeds";
import UserSmsSection from "../components/protected/UserSmsSection";
import CustomFormModal, { fetchSelectedForm } from "../components/UI/CustomFormModal";
import { NoChromeModal } from "../components/UI/NoChrome";
import MyTasksRoot from "../components/protected/MyTasksRoot";
import CustomNotificationDrawer from "../components/UI/CustomNotificationDrawer";
import AlertNotifications from "../components/UI/AlertNotifications";
import FormConfirmModal from "../components/UI/FormConfirmationModal";

import { FORM_OPEN_METHOD_DOCUMENT, NOTIFICATION_TYPE } from "../constants/GlobalConstant";
import { alert } from "../en.json";
import close from "../static/images/close.png";
import flag from "../static/images/flag.svg";
import deleted from "../static/images/deleted.png";
import flagFilled from "../static/images/flagFilled.svg";
import { addListener, getPubnubInstanceByUserType, subscribeChannels, unsubscribeAll } from "../utils/PubnubMethods";
import { hasIntake } from "../utils/hasIntake";
import {
  isChrome,
  isIE,
  isEdge,
  isEdgeChromium,
  isFirefox,
} from "../utils/detectBrowser";
import SecureLocalStorage from "../utils/SecureLS";

@withAuth
@withRouter
@inject("store")
@observer
class Protected extends React.Component {
  constructor() {
    super();
    this.state = {
      formListVisible: false,
      navHeight: 102,
    };
    this.navBarRef = React.createRef();
    this.resizeObserver = null;
  }

  async componentDidMount() {
    let siteId = null;
    try {
      siteId = await Number(SecureLocalStorage.get("SITE_ID"));
    } catch {
      siteId = await Number(localStorage.getItem("SITE_ID"));
    }
    const {
      store: {
        MemberListStore,
        GroupStore,
        AuthStore,
        ReactionsStore,
        SiteStore,
        FormMessageStore: { fetchAllForms },
        FlaggedMessagesStore: { fetchNumberOfUnResolvedAlerts },
      },
    } = this.props;
    AuthStore.setShowChromeModal(true);
    const userType = AuthStore.type;
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    const isFrontDesk = userType === "FD" ? true : false;
    const isNA = userType === "NOA" ? true : false;
    await SiteStore.setSelectedSite(siteId);
    if (isAdmin || isSuperAdmin || isNA) {
      AuthStore.fetchGroupsAndDms();
      MemberListStore.fetchModerators();
      SiteStore.fetchAllSites();
      SiteStore.siteAlreadySelected(siteId);
      // ReactionsStore.fetchAllReactions();
      // GroupStore.fetchPendingUsers();
      fetchAllForms();
      if (siteId !== 0 && siteId !== -1) {
        GroupStore.fetchAllGroups(siteId);

        const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
        fetchNumberOfUnResolvedAlerts(siteDataObj);
        MemberListStore.fetchUnApprovedMembersCount(siteDataObj);
      } else {
        GroupStore.fetchAllGroups();
        fetchNumberOfUnResolvedAlerts();
        MemberListStore.fetchUnApprovedMembersCount();
      }
      if(AuthStore.type) {
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        if (pubnub) {
          const subscritptionChannel = `FORUM_PUSH_${AuthStore.userId}`;
          await subscribeChannels(pubnub, [subscritptionChannel]);
          await addListener(pubnub);
        }
      }
    }
    if (isFrontDesk) {
      MemberListStore.fetchUnApprovedMembersCount();
    }

    if (this?.navBarRef?.current) {
      this.resizeObserver = new ResizeObserver(() => {
        this.handleResize();
      });
      this.resizeObserver.observe(this.navBarRef.current);
    }
  }

  async componentWillUnmount() {
    const {
      store: {
        AuthStore: { changeUserOnlineStatus },
      },
    } = this.props;
    changeUserOnlineStatus();
    unsubscribeAll();

    if (this?.navBarRef?.current && this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  handleResize = () => {
    this.setState({
      ...this.state,
      navHeight: this.navBarRef?.current?.offsetHeight ?? 102,
    })
  };

  getComponent = (view, navHeightStyle) => {
    const {
      store: {
        GroupListStore: { showGroupDetails, showGroupFlags },
        AuthStore: { type, siteId: adminSiteId, loading },
        FlaggedMessagesStore: { setUrlAlertId },
      },
    } = this.props;
    const userType = type;
    const isAdmin = userType === "moderator" ? true : false;
    const isSuperAdmin = userType === "SA" ? true : false;
    switch (view) {
      case "members":
        return (
          <div className="member">
            <Member />
          </div>
        );
      case "flagNeeds":
        return (
          <div className="member">
            <FlagNeeds />
          </div>
        );
      case "waiting":
        return (
          <div className="member">
            <Waiting />
          </div>
        );
      case "chat":
        return (
          <div className="chat">
            <Chat navHeight={this.state.navHeight} />
          </div>
        );
      case "CBRS":
        return loading ? (
          <div className="center-loading">
            <Spin />
          </div>
        ) : !adminSiteId ? null : (
          <div className="chat">
            {hasIntake(adminSiteId) ? <IntakeView /> : <ReferrerView />}
          </div>
        );
      case "invite":
        return (
          <div className="chat">
            <Invite />
          </div>
        );
      case "events":
        return (
          <div className="chat">
            <EventList />
          </div>
        );
      case `alert/${view.split("/")[1]}`:
        const id = Number(view.split("/")[1]);
        setUrlAlertId(id);
        return (
          <div className="chat">
            <Alert navHeight={this.state.navHeight} />
          </div>
        );
      case "alert":
        return (
          <div className="chat">
            <Alert navHeight={this.state.navHeight} />
          </div>
        );
      case "mixpanel":
        return (
          <div className="chat">
            <MixpanelAnalysis />
          </div>
        );
      case "groups":
        return (
          <div className="chat">
            <GroupList />
            {showGroupDetails && (
              <div className="user-sidebar p-1" style={navHeightStyle}>
                <GroupDetails />
              </div>
            )}
            {showGroupFlags && (
              <div className="user-flag-sidebar" style={navHeightStyle}>
                <GroupFlagsContainer />
              </div>
            )}
          </div>
        );
      case "stats":
        return (
          <div className="chat">
            <Stats />
          </div>
        );
      case "inbox":
        return (
          <div className="chat">
            <SmsInbox />
          </div>
        );
      case "insights":
        return (
          <div className="chat">
            <Insights />
          </div>
        );
      case "my-tasks":
        return (
          <div className="chat">
            <MyTasksRoot />
          </div>
        );
      default:
        return isAdmin || isSuperAdmin ? (
          <Redirect to="/members" />
        ) : (
          <Redirect to="/members" />
        );
    }
  };

  fetchDeletedMessages = () => {
    const {
      store: {
        ProfileStore: { userId },
        DeletedMessagesStore: {
          setShowDeleted,
          fetchUserDeletedMessages,
          userDeletedLoading,
        },
      },
    } = this.props;
    if (userDeletedLoading || !userId) {
      return;
    }
    fetchUserDeletedMessages(userId);
    setShowDeleted(true);
  };

  handleClose = () => {
    const {
      match: {
        params: { view },
      },
      store: {
        ProfileStore: {
          reset,
          setIsResetNeededForUserSmsData
        },
        DeletedMessagesStore: {
          setShowDeleted,
          setShowGroupUnDeleted,
          setShowUnFlagged,
        },
      },
    } = this.props;
    if (view === 'inbox') {
      setIsResetNeededForUserSmsData(false);
    }
    reset();
    setShowDeleted(false);
    setShowGroupUnDeleted(false);
    setShowUnFlagged(false);
  };

  openWaitingChat = (username, userId) => {
    const {
      store: {
        BroadcastStore: { startWaitingChat },
        ProfileStore: { setSelectedWaiting },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    setSelectedWaiting(userId);
    startWaitingChat(username, userId);
  };

  changeFlagValue = (value) => {
    const {
      store: {
        ProfileStore: { toggleFlag, userId },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    toggleFlag(value);
  };

  loadMoreGroupHistory = () => {
    const {
      store: {
        HistoryStore: { fetchAllHistory, historyLoading },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    fetchAllHistory();
  };

  getFormsList = (e) => {
    const { store: { FormMessageStore: { fetchAllFormsInListing } } } = this.props;
    fetchAllFormsInListing();
    this.changeFormsListVisible(true);
  };

  loadMoreFormsListing = () => {
    const {
      store: {
        FormMessageStore: { loading, fetchAllFormsInListing }
      },
    } = this.props;
    if (loading) return;
    fetchAllFormsInListing();
  };

  changeFormsListVisible = (value) => {
    this.setState({
      formListVisible: value,
    });
  };

  // This function is used to close form pending wizard and maximize the form again in full screen
  maximizeUserForm = () => {
    const {
      store: {
        FormMessageStore: {
          setIsFormPendingWizardVisible,
          changeUserformsVisible,
        },
      },
    } = this.props;
    // Here we're hiding the form pending wizard by changing the state to false
    setIsFormPendingWizardVisible(false);
    // Here we're showing the whole user form with progressed values by changing the state to true
    changeUserformsVisible(true);
  };

  closeFormPendingWizardOnConfirm = () => {
    const {
      store: {
        FormMessageStore: {
          setIsFormPendingConfirmModalVisible,
          setIsFormPendingWizardVisible,
          setUserFormsStateCopy,
          userFormData,
          setUserFormData,
          formOpenMethod,
          setFormOpenMethod,
          setFormStartTime
        },
      }
    } = this.props;
    // Here we're hiding the form pending wizard by changing the state to false
    setIsFormPendingWizardVisible(false);
    // Here we're setting the the form pending wizard by changing the state to false
    setUserFormsStateCopy({});
    // Here we're hiding modal once user confirm to the modal
    setIsFormPendingConfirmModalVisible(false);
    // We'll reset the form start time for pending form
    setFormStartTime(null);
    // Here we need to check that if form is opened by "Document" in chat or not
    if (formOpenMethod === FORM_OPEN_METHOD_DOCUMENT && userFormData && Object.keys(userFormData).length) {
      // We'll call the "fillDocument" if user wants to open another form by "Document" button
      userFormData.fillDocument();
      // We'll set the form open method value to null;
      setFormOpenMethod(null);
    } else {
      // We'll fetch the selected form if we open the form using form listing modal
      fetchSelectedForm(userFormData);
    }
    // We'll reset the user form data
    setUserFormData({});
  };

  cancelFormConfirmModal = () => {
    const {
      store: {
        FormMessageStore: {
          setIsFormPendingConfirmModalVisible,
          setUserFormData,
          setFormOpenMethod
        },
      },
    } = this.props;
    // Here we're hiding modal once user confirm to the modal
    setIsFormPendingConfirmModalVisible(false);
    // We'll reset the user form data
    setUserFormData({});
    // We'll set the form open method value to null;
    setFormOpenMethod(null);
  };

  render() {
    const {
      match: {
        params: { view },
      },
      store: {
        ProfileStore: {
          visible,
          memberProfileVisible,
          username,
          type,
          userId,
          selectedAction,
          showFlag,
        },
        DeletedMessagesStore: {
          showDeleted,
          showGroupDeleted,
          showGroupUnDeleted,
          showUnDeleted,
          showUnFlagged,
        },
        HistoryStore: {
          historyLoading,
          totalAllHistory,
          skipAllHistory,
          allHistory,
          showAllActivities,
          showAllNotifications,
          resetHistory,
          setShowAllActivities,
          setShowAllNotifications
        },
        ReferralStore: {
          newCBRSReferral,
          newIntake,
          alreadyFinished,
          showReferralForm,
          selfIntake,
        },
        EventStore: { newEvent },
        AuthStore: {
          userId: ownId,
          type: userType,
          showNoChromeModal,
          setShowChromeModal,
        },
        MemberListStore: { detailsLoading },
        FormMessageStore: {
          formsVisible,
          formsListData,
          loading,
          skipFormsInListing,
          totalFormsInListing,
          userFormsVisible,
          isFormPendingWizardVisible,
          userFormsStateCopy,
          isFormPendingConfirmModalVisible,
          setIsFormPendingConfirmModalVisible,
        },
        GroupListStore: { newGroup },
        BroadcastStore: { createDm },
        MessagesStore: {
          isSmsSectionVisible,
          setIsSmsSectionVisible,
          isWaiting,
          sessionStarted
        },
        NotificationStore: {
          showAlertNotification,
          resetNotificationMeta,
          notificationTitle,
          notificationDescription
        }
      },
    } = this.props;

    const navHeightStyle = {
      top: this.state.navHeight,
    };

    return (
      <>
        <AlertNotifications
          showAlertNotification={showAlertNotification}
          notificationTitle={notificationTitle}
          notificationDescription={notificationDescription}
          resetNotificationMeta={() => resetNotificationMeta()}
        />
        <Layout title="dashboard" classname="dashboard" navBarRef={this.navBarRef}>
          {this.getComponent(view, navHeightStyle)}
          {visible && (
            <div className="user-sidebar p-2" style={navHeightStyle}>
              <Profile
                navHeight={this.state.navHeight}
              />
            </div>
          )}
          {selectedAction === "Dm creation" ? (
            <div className="user-profile-sidebar-2" style={navHeightStyle}>
              <DmSection />
            </div>
          ) : null}

          {(!isChrome || isFirefox || isIE || isEdge || isEdgeChromium) &&
            showNoChromeModal ? (
            <NoChromeModal onConfirm={() => setShowChromeModal(false)} />
          ) : null}

          <CustomModal
            title="All Activities"
            visible={showAllActivities}
            loading={historyLoading}
            loadMore={this.loadMoreGroupHistory}
            hasMore={skipAllHistory < totalAllHistory}
            onCancel={() => {
              resetHistory();
              setShowAllActivities(false);
            }}
            data={allHistory ? allHistory : []}
          />

          <CustomNotificationDrawer
            title="What Did I Miss"
            onCancel={() => setShowAllNotifications(false)}
            visible={showAllNotifications}
            loading={historyLoading}
            navigate={() => this.props.history.push('/chat')}
          />

          <CustomFormModal
            title="Forms List"
            visible={this.state.formListVisible}
            onOk={this.handleOk}
            loading={loading}
            loadMore={this.loadMoreFormsListing}
            hasMore={skipFormsInListing < totalFormsInListing}
            onCancel={() => this.changeFormsListVisible(false)}
            data={toJS(formsListData) ? toJS(formsListData) : []}
          />

          {formsVisible ? (
            <div className="user-profile-sidebar-2" style={navHeightStyle}>
              <AllForms />
            </div>
          ) : null}

          {newCBRSReferral ? (
            <div className="user-profile-sidebar" style={navHeightStyle}>
              <ReferrerInvite />
            </div>
          ) : null}

          {newEvent ? (
            <div className="user-profile-sidebar" style={navHeightStyle}>
              <NewEvent />
            </div>
          ) : null}

          {newGroup ? (
            <div className="user-profile-sidebar" style={navHeightStyle}>
              <NewGroup
                navHeight={this.state.navHeight}
              />
            </div>
          ) : null}

          {newIntake ? (
            <div className="user-profile-sidebar" style={navHeightStyle}>
              <NewIntakeForm />
            </div>
          ) : null}

          {selfIntake ? (
            <div className="user-profile-sidebar" style={navHeightStyle}>
              <SelfIntakeForm />
            </div>
          ) : null}

          {showReferralForm ? (
            <div className="user-profile-sidebar" style={navHeightStyle}>
              <ReferrerForm />
            </div>
          ) : null}

          {alreadyFinished ? (
            <div className="user-profile-sidebar" style={navHeightStyle}>
              <FinishedForm />
            </div>
          ) : null}

          {isWaiting ? (
            <div className="user-profile-sidebar-2" style={navHeightStyle}>
              <WaitingSection />
            </div>
          ) : null}

          {isSmsSectionVisible ? (
            <div className="user-profile-sidebar-2" style={navHeightStyle}>
              <UserSmsSection />
            </div>
          ) : null}

          {sessionStarted ? (
            <div className="session-sidebar p-1" style={navHeightStyle}>
              <SessionDetails />
            </div>
          ) : null}

          {userFormsVisible ? (
            <div className="user-profile-sidebar-2" style={navHeightStyle}>
              <UserForms />
            </div>
          ) : null}

          {showDeleted && (
            <div className="user-flag-sidebar" style={navHeightStyle}>
              <UserDeletedContainer showdeleted={true} />
            </div>
          )}
          {showUnDeleted && (
            <div className="user-flag-sidebar" style={navHeightStyle}>
              <UserDeletedContainer showdeleted={false} />
            </div>
          )}
          {showGroupDeleted && (
            <div className="user-flag-sidebar" style={navHeightStyle}>
              <GroupDeletedContainer showdeleted={true} />
            </div>
          )}
          {showGroupUnDeleted && (
            <div className="user-flag-sidebar" style={navHeightStyle}>
              <GroupDeletedContainer showdeleted={false} />
            </div>
          )}
          {showFlag && (
            <div className="user-flag-sidebar" style={navHeightStyle}>
              <UserFlagsContainer showFlag={true} />
            </div>
          )}
          {showUnFlagged && (
            <div className="user-flag-sidebar" style={navHeightStyle}>
              <UserFlagsContainer showFlag={false} />
            </div>
          )}
          {selectedAction === "View in context" ? (
            <div className="user-profile-sidebar-2" style={{
              ...navHeightStyle,
              height: `calc(100vh - ${this.state.navHeight}px)`
            }}>
              <ViewInContext navHeight={this.state.navHeight}/>
            </div>
          ) : null}
          {selectedAction === "Assign to" ? (
            <div className="user-profile-sidebar-2" style={navHeightStyle}>
              <AssignToContainer
                navHeight={this.state.navHeight}
              />
            </div>
          ) : null}
          {memberProfileVisible ? (
            <div className="user-profile-sidebar" style={navHeightStyle}>
              {detailsLoading ? null : (
                <div className="header-section">
                  <h1>
                    {alert.profileOf}{" "}
                    {username.length > 30
                      ? `${username.slice(0, 30)}...`
                      : username}
                    <button
                      className="flag-resolve-button"
                      onClick={() => createDm(username, userId)}
                    >
                      {alert.dm}
                    </button>
                    {userType !== "NOA" &&
                      type !== "moderator" &&
                      type !== "SA" &&
                      userId !== ownId ? (
                      <button
                        className="flag-resolve-button"
                        onClick={() => this.openWaitingChat(username, userId)}
                      >
                        {alert.queue}
                      </button>
                    ) : null}
                    <button
                      className="flag-resolve-button"
                      onClick={() => { setIsSmsSectionVisible(true) }}
                    >
                      {alert.sms}
                    </button>
                    <button
                      className="flag-resolve-button"
                      onClick={this.getFormsList}
                    >
                      {alert.fillForm}
                    </button>
                  </h1>
                  <div className="flag-actions-container">
                    <div
                      className="actions-container"
                      onClick={() => this.fetchDeletedMessages()}
                    >
                      <img
                        src={deleted}
                        alt="Deleted"
                        className="undeleted-icon"
                      />
                      <span style={{
                        textAlign: "center"
                      }}>Show Deleted Messages</span>
                    </div>
                    {showFlag ? (
                      <div
                        className="actions-container"
                        onClick={() => this.changeFlagValue(false)}
                      >
                        <SVG src={flagFilled}></SVG>
                        Hide Flags
                      </div>
                    ) : (
                      <div
                        className="actions-container"
                        onClick={() => this.changeFlagValue(true)}
                      >
                        <SVG src={flag}></SVG>
                        Show Flags
                      </div>
                    )}
                    <img
                      src={close}
                      onClick={() => this.handleClose()}
                      alt="Close"
                    />
                  </div>
                </div>
              )}

              <AssignToContainer
                navHeight={this.state.navHeight}
              />
            </div>
          ) : null}

          {
            isFormPendingConfirmModalVisible &&
            <FormConfirmModal isModalVisible={isFormPendingConfirmModalVisible}
              onConfirm={this.closeFormPendingWizardOnConfirm}
              onCancel={this.cancelFormConfirmModal}
            />
          }

          {isFormPendingWizardVisible && userFormsStateCopy && Object.keys(userFormsStateCopy).length &&
            <Tooltip title="Click to Maximize" placement="top">
              <div className="form-pending-wizard"
                onClick={this.maximizeUserForm}
              >
                <div className="user-info-inner">
                  <b>{userFormsStateCopy.profileUsername}</b> : <b>{userFormsStateCopy.profileSelectedFormName}</b> is in progress...
                </div>
                <Progress type="circle"
                  percent={userFormsStateCopy.progress}
                  strokeColor="#FF4D00"
                />
              </div>
            </Tooltip>
          }
        </Layout>
      </>
    );
  }
}

export default Protected;
