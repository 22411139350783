import React from "react";
import celebration from "../../static/images/celebration.png";
import { getBannerEmoji } from "../../utils/getEmoji";

export const CustomBanner = (props) => {
  const { emojiName, messageText } = props;
  return (
    <div className="custom-banner-container">
      <div
        className="custom-banner-image"
        style={{ backgroundImage: `url(${celebration})` }}
      >
        <div className="custom-banner-emoji">{getBannerEmoji(emojiName)}</div>
        <div className="custom-banner-message">
          <div>{messageText}</div>
        </div>
      </div>
    </div>
  );
};
