import React from 'react';
import { inject, observer } from 'mobx-react';
import { UserBadges } from '../../constants/GlobalConstant';
import { ALIKE_MODERATOR } from "../../constants/UserRolesConstant";
import { usernamePress } from "../../utils/openUserProfile"

const UserAvatar = inject("store")(observer((props) => {
    const { userData, showBadge } = props;
    const username = userData?.username || 'john_123';
    const userBadge = userData?.badge;
    const userId = userData?.id;

    const { type: loggedInUserType } = props.store.AuthStore;
    const shouldAllowOpenProfileAction = ALIKE_MODERATOR.includes(loggedInUserType);

    return (
        <React.Fragment>
            <div className="display-flex">
                <div
                  id="user-avatar-container"
                  onClick={(e) => {
                      e.stopPropagation();
                      shouldAllowOpenProfileAction && usernamePress(userId);
                  }}
                  style={{ cursor: shouldAllowOpenProfileAction ? "pointer" : "auto" }}
                >
                    <div id="profileImage">
                        <p>
                            {`${username}`.charAt(0).toUpperCase()}
                        </p>
                    </div>
                    <div id="username-container">
                        <p>
                            {`${username}`}
                        </p>
                        {
                          (showBadge && userBadge) &&
                          <img
                            className='feed-user-badge'
                            src={UserBadges[userBadge]}
                            alt="user-badge"
                          />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}));

export default UserAvatar;
