import { inject, observer } from "mobx-react";
import { Button, Spin } from "antd";
import React from "react";
import close from "../../static/images/close.png";

import info from "../../static/images/info.png";
import { CustomModal } from "../UI/CustomModal";
import SessionMembers from "./SessionMembers";

@inject("store")
@observer
class SessionDetails extends React.Component {
  closePress = () => {
    const {
      store: {
        MessagesStore: { setSession },
        GroupListStore: { resetSelectedGroup },
      },
    } = this.props;
    setSession(false);
    resetSelectedGroup();
  };

  render() {
    const {
      store: {
        GroupListStore: { selectedGroup },
      },
    } = this.props;
    if (!selectedGroup) {
      return (
        <div className="selected-group-loading">
          <Spin />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="group-name-container">
          <h1 className="group-name ">{selectedGroup.name}</h1>

          <img
            src={close}
            onClick={() => this.closePress()}
            alt="Close"
            className="close-img"
          />
        </div>
        <SessionMembers />
      </React.Fragment>
    );
  }
}
export default SessionDetails;
