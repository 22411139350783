import React from 'react';
import { Button, Col, Row, Select, Spin } from 'antd';
import { tasks as tasksLabel } from '../../en.json';
import { TASK_DATE_FORMAT } from '../../constants/GlobalConstant';

const Option = Select.Option;

export const TaskInput = (props) => {

    const {
        taskDescription,
        onChangeTaskDescription,
        adminUsers,
        assignedPeerId,
        onSelectAssignedPeer,
        onChangeDate,
        dueAt,
        addTaskLoading,
        updateTaskLoading,
        onCancel,
        onSubmit,
        addTask
    } = props

    return (
        <div className={addTask && `task-root-container`}>
            <div className='task-container'>
                <Row>
                    <Col span={24}>
                        <textarea
                            className="task-input"
                            data-field="newNotes"
                            required={true}
                            onChange={(event) => onChangeTaskDescription(event)}
                            value={taskDescription}
                            placeholder="Type here..."
                        />
                    </Col>
                </Row>
                <Row className='task-subcontainer'>
                    <Col span={18}>
                        <Row>
                            <Col span={12} className="task-items">
                                <span className='task-primary-text'>{tasksLabel.assignedTo}</span>
                                <div className="user-bio">
                                    <div id="assigned-peer-container" style={{ position: "relative", marginLeft: 10 }}>
                                        {adminUsers && adminUsers.length ? (
                                            <Select
                                                value={assignedPeerId}
                                                style={{ width: "180px" }}
                                                onChange={(value) => onSelectAssignedPeer(value)}
                                                getPopupContainer={() =>
                                                    document.getElementById("assigned-peer-container")
                                                }
                                            >
                                                {adminUsers.map(({ id, username, fullName }, index) => (
                                                    <Option value={id} key={index} >{fullName}</Option>
                                                ))}
                                            </Select>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>
                            <Col span={12} className="task-items">
                                <span className='task-primary-text'>{tasksLabel.DueDate}</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Task due date"
                                    value={dueAt}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={onChangeDate}
                                    data-field="newDOB"
                                    style={{ marginLeft: 10, width: 190 }}
                                    min={new Date().toISOString().split("T")[0]}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6} className="edit-icon-container" style={{ alignItems: 'center' }}>
                        <Button
                            type="default"
                            size="large"
                            style={{ width: "145px", border: 'none' }}
                            onClick={() => onCancel()}
                        >
                            {tasksLabel.cancel}
                        </Button>

                        {addTask ? (addTaskLoading ? <div className='add-task-spin-container'>
                            <Spin />
                        </div> :
                            <Button
                                className="download-button"
                                type="primary"
                                size="large"
                                style={{ width: "145px" }}
                                onClick={() => onSubmit()}
                            >
                                {tasksLabel.save}
                            </Button>
                        ) : (updateTaskLoading ? <div className='add-task-spin-container'>
                            <Spin />
                        </div> :
                            <Button
                                className="download-button"
                                type="primary"
                                size="large"
                                style={{ width: "145px" }}
                                onClick={() => onSubmit()}
                            >
                                {tasksLabel.update}
                            </Button>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    )
}