import { inject, observer } from "mobx-react";

import React from "react";
import { Spin } from "antd";
import Select from "react-select";
import { sortBy } from "lodash";
import close from "../../static/images/close.png";
import { CATEGORY_TYPES } from '../../constants/GlobalConstant';

@inject("store")
@observer
class NewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      selectedCategories: [],
    };
  }

  changeValue = (e) => {
    const {
      store: {
        GroupListStore: { setNewGroupDetails },
      },
    } = this.props;
    if (e.target.getAttribute("data-field") === "groupImage") {
      const fileName = e.target.value.toLowerCase();
      if (
        !fileName.endsWith(".jpg") ||
        !fileName.endsWith(".jpeg") ||
        !fileName.endsWith(".gif") ||
        !fileName.endsWith(".png")
      ) {
        alert("Please upload file type image only.");
        return;
      }
      this.addPhoto(e.target.files);
    }
    setNewGroupDetails(e.target.getAttribute("data-field"), e.target.value);
  };


  changeSelect = ({ value }) => {
    const {
      store: {
        GroupListStore: { setNewGroupDetails },
      },
    } = this.props;

    setNewGroupDetails("groupType", value);
  };
  changeSelectCategory = (selectedValues) => {
    this.setState({ selectedCategories: selectedValues });
    const {
      store: {
        GroupListStore: { setNewCategoryDetails },
      },
    } = this.props;
    setNewCategoryDetails("category", selectedValues.map(({ value }) => value).toString());
  }
  addPhoto(files) {
    if (!files.length) {
      return alert("Please choose a file to upload first.");
    }
    const file = files[0];
    this.setState({
      file,
    });
  }

  createNewGroup = () => {
    const {
      store: {
        GroupListStore: { creatingGroup, createNewGroup },
      },
    } = this.props;
    if (creatingGroup) {
      return;
    }
    createNewGroup(this.state.file);
  };

  handleCloseNewGroup = (value) => {
    const {
      store: {
        GroupListStore: { setNewGroup, resetNewGroup },
      },
    } = this.props;
    resetNewGroup();
  };

  render() {
    const {
      store: {
        SiteStore: { allSites },
        GroupListStore: { creatingGroup, setMultipleSites, canCreateGroup },
      },
    } = this.props;
    const siteOptions = sortBy([...allSites.values()], ["name"]).map((site) => {
      return { value: site.id, label: site.name };
    });

    return (
      <div className="form-response">
        <div className="new-referral-header">
          <div>
            Create New Group
            <div className="new-referral-sub"></div>
          </div>
          <div className="flag-actions-container">
            <img
              src={close}
              onClick={() => this.handleCloseNewGroup(false)}
              alt="Close"
            />
          </div>
        </div>
        <div className="new-event-input-container" style={{
          height: `calc(100vh - (${this.props.navHeight + 170}px))`
        }}>
          <div className="new-event-label">Group Image</div>
          <input
            type="file"
            onChange={this.changeValue}
            accept="image/*"
            data-field="groupImage"
            className="group-image-upload"
            placeholder="Group Description"
            id="groupImageoupload"
          />
          <div className="new-event-label">Group Name</div>
          <input
            placeholder="Type..."
            className="new-event-input"
            data-field="groupName"
            onChange={this.changeValue}
          />
          <div className="new-event-label">Group Description</div>
          <textarea
            rows={4}
            placeholder="Type..."
            className="new-event-input"
            data-field="groupDesc"
            onChange={this.changeValue}
          />
          <div className="new-event-label">Group Code</div>
          <input
            placeholder="Type..."
            className="new-event-input"
            data-field="groupCode"
            onChange={this.changeValue}
          />


          <div className="new-event-label">Group Type (Member's ability to view or join the group)</div>
          <Select styles={{
            control: base => ({
              ...base,
              width: "80%",
              marginBottom: "15px",
            }),
            menu: base => ({
              ...base,
              width: "80%",
            }),
          }}
            onChange={v => this.changeSelect(v)}
            options={[
              { label: "Active", value: "active" },
              { label: "Request", value: "request" },
              { label: "Disable", value: "disable" },
            ]}
          >
          </Select>

          {<>
            <div className="new-event-label">Category</div>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  width: "80%",
                  marginBottom: "15px",
                }),
                menu: base => ({
                  ...base,
                  width: "80%",
                }),
              }}
              onChange={this.changeSelectCategory}
              options={CATEGORY_TYPES.map((option) => ({
                label: option.title,
                value: option.value,
              }))}
              value={this.state.selectedCategories}
              isMulti
            >
            </Select>
          </>}

          {creatingGroup ? (
            <div className="creating-event">
              <Spin />
            </div>
          ) : null}

          <div className="submit-container">
            <button
              className="new-referral-button"
              disabled={canCreateGroup || creatingGroup}
              onClick={this.createNewGroup}
            >
              Create Group
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default NewGroup;
