import { Button } from "antd";
import React from "react";

export const CustomButton = (props) => {
  const { caption, className, disabled, type, size, onClick } = props;
  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
      size={size}
    >
      {caption}
    </Button>
  );
};
