let pubnubInstances = {
  personal: null,
  moderator: null,
};


/**
 *  Sets one or both singleton instaces of pubnub: personal or moderator
 * 
 *  question: What is the difference between the personal and moderator instances of pubnub?
 */
export const setPubnubInstance = (obj) => {
  if (!pubnubInstances.personal) {
    pubnubInstances.personal = obj.personal;
  }
  if (!pubnubInstances.moderator) {
    pubnubInstances.moderator = obj.moderator;
  }

  return pubnubInstances;
};

export default pubnubInstances;
