import "../../styles/global.scss";

import { inject, observer } from "mobx-react";

import { Checkbox } from "antd";
import React from "react";

@inject("store")
@observer
class ListItem extends React.Component {
  render() {
    const {
      name,
      handleClick,
      id,
      type,
      highlighted,
      isIntersiteGroup,
      unreadMessages,
      deletedMessages,
      showCheckbox,
      isPaused,
      isBot,
      store: {
        BroadcastStore: { isChannelSelected, loading },
        AuthStore: { isUserSuspended },
        NotificationStore: { setNotification },
      },
    } = this.props;
    return (
      <li
        style={{
          background: highlighted && "#D8D8D8",
          pointerEvents: loading && "none",
        }}
        onClick={() => {
          if (isUserSuspended(id)) {
            setNotification(
              "error",
              "You have been suspended from this group.",
              null,
              2.5
            );
          } else {
            handleClick(id, name, type, isPaused, isIntersiteGroup,isBot);
          }
        }}
        className="list-group-item d-flex justify-content-between align-items-center observe"
      >
        <div>
          {showCheckbox && <Checkbox checked={isChannelSelected(id)} />}{" "}
          <h3>{name}</h3>
          {/* Uncomment for last message */}
          {/* <small>Username1: I see what you...</small> */}
        </div>
        <div>
          {unreadMessages && (
            <span className="badge badge-primary badge-pill" />
          )}
          {deletedMessages && (
            <span className="badge badge-danger badge-pill" />
          )}
          {/* Uncomment for notification time */}
          {/* <small>3:15 PM</small> */}
        </div>
      </li>
    );
  }
}
export default ListItem;
